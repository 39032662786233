import { Dispatch, FC, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import useFetchData from '../../../../hooks/useFetchData';
import useRouteParams from '../../../../hooks/useRouteParams';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import {
  addAdditionalTitleBarComponents,
  toggleTitleBarLoading,
} from '../../../../redux/pages/actions';
import { RaptorTheme, greys } from '../../../../styling/theme';
import { Select } from '../../../../types/redux/pages/PagesStore';
import { APPEND_NEW_SELECT_TO_TITLEBAR } from '../../../../types/redux/pages/pageTypes';
import {
  addCommasToNumbers,
  addCommasToNumbersAndRound,
} from '../../../../utilities/numberFormatters';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import GridItem from '../../../layout/GridComponents/GridItem';
import GeneralSelect from '../../../selects/GeneralSelect';
import DiscountCashflowButton from './DiscountCashFlowButton';
import DiscountCashflowLineChart from './DiscountCashflowLineChart.component';
import DiscountCashflowTable from './DiscountCashflowTable.component';
import { Grid, Typography } from '@mui/material';

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  selectsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    // width: "50%",
    // marginLeft: "25%"
  },
  selectsRow: {
    display: 'flex-column',
    justifyContent: 'space-around',
    width: '75%',
    // marginLeft: "25%"
  },
  generalSelectContainer: {
    position: 'relative',
    // height: 500,
    width: '20rem',
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    // marginRight: '2rem',
    // marginLeft: '1.2rem',
    marginTop: '1.2rem',
    height: '5rem',
    width: '100%',
    marginBottom: '1.2rem',
  },
  selectRoot: {
    width: '17rem',
  },
  submitButton: {
    // marginLeft: '25%',
    // marginTop: '1rem',
    width: '100%',
    justifyContent: 'center',
  },
  scenarioSelect: {
    with: '25%',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
}));

const configurationLabels = [
  '-30%',
  '-25%',
  '-20%',
  '-15%',
  '-10%',
  '-5%',
  '0%',
  '5%',
  '10%',
  '15%',
  '20%',
  '25%',
  '30%',
  '35%',
  '40%',
  '45%',
  '50%',
];
const configurationValues = [
  '-0.3',
  '-0.25',
  '-0.2',
  '-0.15',
  '-0.1',
  '-0.05',
  '0',
  '0.05',
  '0.1',
  '0.15',
  '0.2',
  '0.25',
  '0.3',
  '0.35',
  '0.4',
  '0.45',
  '0.5',
];
const configurationOptions = [
  {
    label: '0%',
    value: 0,
  },
  {
    label: '5%',
    value: 0.05,
  },
  {
    label: '10%',
    value: 0.1,
  },
  {
    label: '15%',
    value: 0.15,
  },
  {
    label: '20%',
    value: 0.2,
  },
  {
    label: '25%',
    value: 0.25,
  },
  {
    label: '30%',
    value: 0.3,
  },
  {
    label: '35%',
    value: 0.35,
  },
  {
    label: '40%',
    value: 0.4,
  },
  {
    label: '45%',
    value: 0.45,
  },
  {
    label: '50%',
    value: 0.5,
  },
];

interface DiscountCashflowConfigurationProps {
  title: string;
  defaultSelection: any;
  scenariosData: any;
  updateFunction: Dispatch<any>;
}

const DiscountCashflowConfiguration: FC<DiscountCashflowConfigurationProps> = (
  props
) => {
  const { title, defaultSelection, updateFunction, scenariosData } = props;
  const classes = useStyles();
  const [ebit, setEbit] = useState<any>(defaultSelection.ebit);
  const [growth, setGrowth] = useState<any>(defaultSelection.growth);
  const [illiquidity, setIlliquidity] = useState<any>(
    defaultSelection.illiquidity
  );
  const [interest, setInterest] = useState<any>(defaultSelection.interest);
  const [wacc, setWacc] = useState<any>(defaultSelection.wacc);
  const [investment, setInvestment] = useState<any>(
    defaultSelection.investment
  );
  const [selectedScenario, setSelectedScenario] =
    useState<any>('none_selected');
  const scenarioNames =
    scenariosData && scenariosData.data.length
      ? scenariosData.data[0].scenario_names
      : [];
  const scenarioConfigs =
    scenariosData && scenariosData.data.length
      ? scenariosData.data[0].scenarios
      : [];

  const updateScenario = () => {
    if (selectedScenario !== 'none_selected') {
      const scenarioOptions = scenarioConfigs[selectedScenario];
      if (
        scenarioOptions.ebit_st !== ebit ||
        scenarioOptions.growth_st !== growth ||
        scenarioOptions.illiquidity !== illiquidity ||
        scenarioOptions.interest_st !== interest ||
        scenarioOptions.wacc_st !== wacc ||
        scenarioOptions.invest_st !== investment
      ) {
        setSelectedScenario('none_selected');
      }
    }
    updateFunction({
      ebit,
      growth,
      illiquidity,
      interest,
      wacc,
      investment,
    });
  };
  const updateSelectedScenario = (selection: any) => {
    const scenarioOptions = scenarioConfigs[selection];
    setEbit(scenarioOptions.ebit_st);
    setGrowth(scenarioOptions.growth_st);
    setIlliquidity(scenarioOptions.illiquidity);
    setInterest(scenarioOptions.interest_st);
    setWacc(scenarioOptions.wacc_st);
    setInvestment(scenarioOptions.invest_st);
    setSelectedScenario(selection);
  };
  return (
    <GridItem xs={8} card style={{ padding: 8 }} cardStyle={{ minHeight: 300 }}>
      <Typography variant="h2" style={{ fontWeight: 300, textAlign: 'center' }}>
        {props.title}
      </Typography>
      <div style={{ display: 'flex' }}>
        <div className={classes.scenarioSelect}>
          <GeneralSelect
            labels={scenarioNames}
            values={scenarioNames}
            selected={selectedScenario}
            setSelected={(selection: any) => {
              if (selection !== 'none_selected')
                updateSelectedScenario(selection);
            }}
            placeholderValue="None Selected"
            placeholderColor={greys.grey900}
            selectTitle={'Select Scenario'}
            customStyles={{
              pickerRoot: classes.pickerRoot,
              selectRoot: classes.selectRoot,
            }}
          />
        </div>
        <div className={classes.selectsRow}>
          <div className={classes.selectsContainer}>
            <div className={classes.generalSelectContainer}>
              <GeneralSelect
                labels={configurationLabels}
                values={configurationValues}
                selected={ebit}
                setSelected={(selection: any) => {
                  if (selection !== 'none_selected') setEbit(selection);
                }}
                placeholderValue=""
                placeholderColor={greys.grey900}
                selectTitle={'EBIT'}
                customStyles={{
                  pickerRoot: classes.pickerRoot,
                  selectRoot: classes.selectRoot,
                }}
              />
            </div>
            <div className={classes.generalSelectContainer}>
              <GeneralSelect
                labels={configurationLabels}
                values={configurationValues}
                selected={growth}
                setSelected={(selection: any) => {
                  if (selection !== 'none_selected') setGrowth(selection);
                }}
                placeholderValue=""
                placeholderColor={greys.grey900}
                selectTitle={'Growth'}
                customStyles={{
                  pickerRoot: classes.pickerRoot,
                  selectRoot: classes.selectRoot,
                }}
              />
            </div>
            <div className={classes.generalSelectContainer}>
              <GeneralSelect
                labels={configurationLabels}
                values={configurationValues}
                selected={illiquidity}
                setSelected={(selection: any) => {
                  if (selection !== 'none_selected') setIlliquidity(selection);
                }}
                placeholderValue=""
                placeholderColor={greys.grey900}
                selectTitle={'Illiquidity'}
                customStyles={{
                  pickerRoot: classes.pickerRoot,
                  selectRoot: classes.selectRoot,
                }}
              />
            </div>
          </div>
          <div className={classes.selectsContainer}>
            <div className={classes.generalSelectContainer}>
              <GeneralSelect
                labels={configurationLabels}
                values={configurationValues}
                selected={interest}
                setSelected={(selection: any) => {
                  if (selection !== 'none_selected') setInterest(selection);
                }}
                placeholderValue=""
                placeholderColor={greys.grey900}
                selectTitle={'Interest'}
                customStyles={{
                  pickerRoot: classes.pickerRoot,
                  selectRoot: classes.selectRoot,
                }}
              />
            </div>
            <div className={classes.generalSelectContainer}>
              <GeneralSelect
                labels={configurationLabels}
                values={configurationValues}
                selected={wacc}
                setSelected={(selection: any) => {
                  if (selection !== 'none_selected') setWacc(selection);
                }}
                placeholderValue=""
                placeholderColor={greys.grey900}
                selectTitle={'WACC'}
                customStyles={{
                  pickerRoot: classes.pickerRoot,
                  selectRoot: classes.selectRoot,
                }}
              />
            </div>
            <div className={classes.generalSelectContainer}>
              <GeneralSelect
                labels={configurationLabels}
                values={configurationValues}
                selected={investment}
                setSelected={(selection: any) => {
                  if (selection !== 'none_selected') setInvestment(selection);
                }}
                placeholderValue=""
                placeholderColor={greys.grey900}
                selectTitle={'Investment'}
                customStyles={{
                  pickerRoot: classes.pickerRoot,
                  selectRoot: classes.selectRoot,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.selectsContainer}>
        <DiscountCashflowButton handler={updateScenario} title="Execute" />
      </div>
    </GridItem>
  );
};

interface DiscountCashflowSummaryProps {
  data: any;
  selectedPosition: string | null;
}

function getPoistionSummaryData(data: any, selectedPosition: string | null) {
  if (!data) return null;
  if (!data.data.length) return null;
  if (!selectedPosition) return null;
  // if (selectedPosition === 'Portfolio') return null;
  const peDataPositions = data.data[0];
  const Filtered = peDataPositions.filter((obj: any) => {
    return obj.name === selectedPosition;
  });
  return Filtered[0].preferences;
}

const DiscountCashflowSummary: FC<DiscountCashflowSummaryProps> = (props) => {
  const { data, selectedPosition } = props;
  const parametersDetails = getPoistionSummaryData(data, selectedPosition);

  return (
    <GridItem xs={4} card style={{ padding: 8 }} cardStyle={{ minHeight: 300 }}>
      <Typography variant="h2" style={{ fontWeight: 300, textAlign: 'center' }}>
        {`${
          selectedPosition === 'Portfolio' ? 'Portfolio' : 'Position'
        } Details`}
      </Typography>
      {selectedPosition === 'Portfolio' ? (
        <>
          <div style={{ display: 'flex' }}>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
                color: greys.grey700,
              }}
            >
              {'Currency: '}
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
              }}
            >
              {parametersDetails
                ? parametersDetails['Currency']
                : selectedPosition}
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
                color: greys.grey700,
              }}
            >
              {'EBIT: '}
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
              }}
            >
              {parametersDetails
                ? addCommasToNumbersAndRound(parametersDetails['EBIT'])
                : selectedPosition}
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
                color: greys.grey700,
              }}
            >
              {'NAV: '}
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
              }}
            >
              {parametersDetails
                ? addCommasToNumbersAndRound(parametersDetails['NAV'])
                : selectedPosition}
            </Typography>
          </div>
        </>
      ) : (
        <>
          <div style={{ display: 'flex' }}>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
                color: greys.grey700,
              }}
            >
              {'Position Name: '}
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
              }}
            >
              {parametersDetails ? parametersDetails['Name'] : selectedPosition}
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
                color: greys.grey700,
              }}
            >
              {'Maturity Date: '}
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
              }}
            >
              {parametersDetails ? parametersDetails['Maturity Date'] : null}
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
                color: greys.grey700,
              }}
            >
              {'Country: '}
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
              }}
            >
              {parametersDetails ? parametersDetails['Country'] : null}
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
                color: greys.grey700,
              }}
            >
              {'Currency: '}
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
              }}
            >
              {parametersDetails ? parametersDetails['Currency'] : null}
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
                color: greys.grey700,
              }}
            >
              {'Industry: '}
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
              }}
            >
              {parametersDetails ? parametersDetails['Industry'] : null}
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
                color: greys.grey700,
              }}
            >
              {'EBIT: '}
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
              }}
            >
              {parametersDetails
                ? addCommasToNumbers(parametersDetails['EBIT'])
                : null}
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
                color: greys.grey700,
              }}
            >
              {'EBIT Growth: '}
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
              }}
            >
              {parametersDetails ? parametersDetails['EBIT Growth'] : null}
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
                color: greys.grey700,
              }}
            >
              {'EBIT Multiple: '}
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
              }}
            >
              {parametersDetails ? parametersDetails['EBIT Multiple'] : null}
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
                color: greys.grey700,
              }}
            >
              {'EV: '}
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
              }}
            >
              {parametersDetails ? parametersDetails['EV'] : null}
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
                color: greys.grey700,
              }}
            >
              {'WACC: '}
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontWeight: 300,
                textAlign: 'left',
                marginLeft: '1rem',
                fontSize: 22,
              }}
            >
              {parametersDetails ? parametersDetails['WACC'] : null}
            </Typography>
          </div>
        </>
      )}
    </GridItem>
  );
};

function buildDicountCashflowUrl(configurations: any) {
  // §'pe_example/0.05/0.05/0.05/0.05/0.05/0.05',
  // /pe_example/<ebit_st>/<growth_st>/<illiquidity>/<interest_st>/<wacc_st>/<invest_st>
  return `pe_example/${configurations.ebit}/${configurations.growth}/${configurations.illiquidity}/${configurations.interest}/${configurations.wacc}/${configurations.investment}`;
}

const DiscountCashflow: FC<FundInfoComponentProps> = (props) => {
  const { ebit } = useRouteParams('ebit');
  const { growth } = useRouteParams('growth');

  const defaultSelection = {
    ebit: ebit ? ebit : '0',
    growth: growth ? growth : '0',
    illiquidity: '0',
    interest: '0',
    wacc: '0',
    investment: '0',
  };
  // Create variables for editing the configurations.
  const [configuration, setConfiguration] = useState<any>({
    ebit: ebit ? ebit : '0',
    growth: growth ? growth : '0',
    illiquidity: '0',
    interest: '0',
    wacc: '0',
    investment: '0',
  });
  // Create variable for deciding what is shown in the bar chart.
  const [barChartFilter, setBarChartFilter] = useState<string>('FCF');
  // console.log("HERE IS CURRENT VALUE OF SCENARIO 1", scenario1Configuration);
  // const [scenario2Configuration, setScenraio2Configuration] = useState<any>({
  //     ebit: '0.05',
  //     growth: '0.05',
  //     illiquidity: '0.05',
  //     interest: '0.05',
  //     wacc: '0.05',
  //     investment: '0.05'
  // });

  const [dropdownOptions, setDropdownOptions] = useState<any>(null);
  // Will be used to toggle between dollar values and percent of NAV.
  const [percentValueOption, setPercentValueOption] =
    useState<string>('percent');
  // Hardcoding in teh NAV for now, in future will be taken from the route.
  const nav = 10000000;
  const url = buildDicountCashflowUrl(configuration);
  const peData = useFetchData({
    keyName: `pe_example_${configuration.ebit}_${configuration.growth}_${configuration.illiquidity}_${configuration.interest}_${configuration.wacc}_${configuration.invest}`,
    url: url,
    makeFetch: true,
  });
  const defaultScenarioPeData = useFetchData({
    keyName: `pe_example_0_0_0_0_0_0`,
    url: 'pe_example/0/0/0/0/0/0',
    makeFetch: true,
  });
  const dcfScenarioConfigurations = useFetchData({
    keyName: `dcf_scenarios`,
    url: 'dcf_scenarios',
    makeFetch: true,
  });
  // const scenario2Url = buildDicountCashflowUrl(scenario2Configuration);
  // const peDataScenario2 = useFetchData({
  //     keyName: `pe_example_${scenario2Configuration.ebit}_${scenario2Configuration.growth}_${scenario2Configuration.illiquidity}_${scenario2Configuration.interest}_${scenario2Configuration.wacc}_${scenario2Configuration.invest}`,
  //     url: scenario2Url,
  //     makeFetch: true
  // })

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleTitleBarLoading(true));
    if (peData && !peData.isFetching) {
      // Get the position Names
      const positions = peData.data[0];
      const positionNames = positions.map((item: any) => item.name);
      // Filter out Portfolio.
      const filteredPositionNames = positionNames.filter(
        (item: any) => item !== 'Portfolio'
      );
      // filteredPositionNames.sort((a: any, b: any) => parseInt(a.replace('pe_dummy', '')) > parseInt(b.replace('pe_dummy', '')));
      filteredPositionNames.sort(
        (a: any, b: any) =>
          a.replace('pe_dummy', '') - b.replace('pe_dummy', '')
      );
      // Add Portfolio to start
      filteredPositionNames.unshift('Portfolio');
      // only update if positions have changed.
      if (
        dropdownOptions &&
        filteredPositionNames.join(',') === dropdownOptions.join(',')
      ) {
        dispatch(toggleTitleBarLoading(false));
      } else {
        setDropdownOptions(filteredPositionNames);
      }
    }
  }, [peData]);

  // add a dropdown for the scenarios
  useEffect(() => {
    if (dropdownOptions) {
      const selectsToAdd: Select[] = [
        {
          titleBarTitle: 'Select Position',
          titleBarKey: 'selectPosition',
          values: dropdownOptions.map((item: any) => ({
            label: item,
            value: item,
          })),
          displayOnOverviewPage: false,
          aValueIsAlwaysSelected: true,
          currentValue: 'Portfolio',
        },
      ];
      dispatch(
        addAdditionalTitleBarComponents(
          selectsToAdd,
          APPEND_NEW_SELECT_TO_TITLEBAR
        )
      );
      dispatch(toggleTitleBarLoading(false));
    }
  }, [dropdownOptions]);

  const selectedPosition = useTitleBarSelect('selectPosition');

  const isDefaultScenario =
    configuration.ebit === '0' &&
    configuration.growth === '0' &&
    configuration.illiquidity === '0' &&
    configuration.interest === '0' &&
    configuration.wacc === '0' &&
    configuration.investment === '0';

  return (
    <Grid container>
      <DiscountCashflowSummary
        data={peData}
        selectedPosition={selectedPosition}
      />
      <DiscountCashflowConfiguration
        title="Configurations"
        defaultSelection={defaultSelection}
        updateFunction={setConfiguration}
        scenariosData={dcfScenarioConfigurations}
      />
      {/* <DiscountCashflowConfiguration title="Configuration Scenario 2" defaultSelection="0.05" updateFunction={setScenraio2Configuration} /> */}
      <GeneralComponentErrorShield
        dataObjects={[peData]}
        customLoadingMessages={['Loading Private Equity Data...']}
        customErrorMessage="No Data Available."
      >
        <DiscountCashflowTable
          data={peData}
          title="Private Equity Stress"
          selectedPosition={selectedPosition}
          setBarChartFilter={setBarChartFilter}
          barChartValue={barChartFilter}
          percentValueOption={percentValueOption}
          setPercentValueOption={setPercentValueOption}
          nav={nav}
          defaultData={defaultScenarioPeData}
          isDefaultScenario={isDefaultScenario}
        />
        <DiscountCashflowLineChart
          data={peData}
          selectedPosition={selectedPosition}
          linechartFilter={barChartFilter}
          percentValueOption={percentValueOption}
          nav={nav}
          defaultData={defaultScenarioPeData}
          isDefaultScenario={isDefaultScenario}
        />
        {/* <DiscountCashflowBarChart
                    data={peData}
                    selectedPosition={selectedPosition}
                    barchartFilter={barChartFilter}
                    percentValueOption={percentValueOption}
                    nav={nav}
                /> */}
      </GeneralComponentErrorShield>
    </Grid>
  );
};

export default DiscountCashflow;
