import { ContentState, Editor, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React, { FC, useEffect } from 'react';
import { Dispatch } from 'react';
import { greys, mainColors } from '../../../../../../styling/theme';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../../tables/CustomTable';
import Icon from '@mui/material/Icon';
import RaptorLogo from '../../../../../../images/Raptor_logo_new.png';
import { roundNumberToTwoDecimalPlaces } from '../../../../../../utilities/numberFormatters';
import { Button, Typography } from '@mui/material';

interface TableEditorTileProps {
  delegatedActsElement: string;
  delegatedActsData: any[];
  setDelegatedActsData: Dispatch<any>;
  setHaveChangesBeenMade: Dispatch<any>;
  index: number;
  risksystemData: any;
  tag: string;
}

function buildTableData(data: any, columnKeys: string[]): TableEditorData[] {
  const tableData: TableEditorData[] = [];
  // const columnKeys = Object.keys(data[0])
  data.forEach((value: any, index: number) => {
    const tableRow: any = {
      value0: '',
      value1: '',
      value2: '',
      value3: '',
      value4: '',
      value5: '',
      value6: '',
      value7: '',
      value8: '',
      value9: '',
      index: index,
    };
    for (let i = 0; i < columnKeys.length; i++) {
      let valueToAdd = value[columnKeys[i]];
      if (typeof valueToAdd === 'number') {
        valueToAdd = roundNumberToTwoDecimalPlaces(valueToAdd);
      }
      tableRow[`value${i}`] = valueToAdd;
    }
    tableData.push(tableRow);
  });

  return tableData;
}

// We allow a max of 10 columns in the table.
interface TableEditorData {
  value0: string;
  value1: string;
  value2: string;
  value3: string;
  value4: string;
  value5: string;
  value6: string;
  value7: string;
  value8: string;
  value9: string;
  index: number;
}

interface TableCellEditorBoxProps {
  initialText: string;
  onTextChange: (text: string) => void;
  setWidth?: string;
}

const TableCellEditorBox = React.memo(
  ({
    initialText,
    onTextChange,
    setWidth,
  }: TableCellEditorBoxProps): React.ReactElement => {
    const [editorState, setEditorState] = React.useState(
      EditorState.createEmpty()
    );

    useEffect(() => {
      setEditorState(
        EditorState.createWithContent(ContentState.createFromText(initialText))
      );
    }, []);

    const handleTextChange = (updatedEditorState: EditorState) => {
      setEditorState(updatedEditorState);
      let text = stateToHTML(updatedEditorState.getCurrentContent());

      text = text
        .replaceAll('<p>', '')
        .replaceAll('</p>', '')
        .replaceAll('<strong>', '<b>')
        .replaceAll('</strong>', '</b>')
        .replaceAll('<em>', '<i>')
        .replaceAll('</em>', '</i>');
      onTextChange(text);
    };

    return (
      <div
        style={{
          border: '0.5px solid',
          minHeight: '2rem',
          backgroundColor: 'white',
          width: setWidth ? setWidth : '18rem',
        }}
      >
        <Editor
          editorState={editorState}
          onChange={(updatedEditorState: EditorState) => {
            handleTextChange(updatedEditorState);
          }}
          stripPastedStyles={true}
        />
      </div>
    );
  }
);

function buildTableColumns(
  handleUpdateFunction: (
    rowIndex: number,
    columnName: string,
    text: string
  ) => void,
  tableColumns: string[],
  keys: string[],
  dataJson: any
): CustomColumn<TableEditorData>[] {
  const columns: CustomColumn<TableEditorData>[] = [
    {
      title: '#',
      field: 'index',
      cellStyle: {
        textAlign: 'center',
      },
      width: '1%',
      headerStyle: { textAlign: 'center' },
      render: (rowData: TableEditorData) => rowData.index + 1,
    },
  ];
  tableColumns.forEach((value: string, index: number) => {
    columns.push({
      title: value,
      field: `value${index}`,
      cellStyle: {
        textAlign: 'center',
      },
      width: '18%',
      headerStyle: { textAlign: 'center' },
      // render: (rowData: TableEditorData) => <TableCellEditorBox initialText={getInitialText(dataJson, keys[index], rowData.index)} onTextChange={(text: string) => handleUpdateFunction(rowData.index, keys[index], text)} setWidth={`${(72 / keys.length) - 0.3}rem`} />
      render: (rowData: TableEditorData) => (
        <TableCellEditorBox
          initialText={
            dataJson.value.length > rowData.index
              ? dataJson.value[rowData.index][keys[index]]
              : ''
          }
          onTextChange={(text: string) =>
            handleUpdateFunction(rowData.index, keys[index], text)
          }
          setWidth={`${72 / keys.length - 0.3}rem`}
        />
      ),
    });
  });
  return columns;
}

function getMaxNumRows(tableType: string): number {
  switch (tableType) {
    case 'a4_esg_scores_table':
      return 8;
    case 'a4_asset_allocation_table':
      return 5;
    case 'a4_sector_table':
      return 12;
    default:
      return 0;
  }
}

const TableEditorTile: FC<TableEditorTileProps> = (props) => {
  const {
    delegatedActsElement,
    delegatedActsData,
    setDelegatedActsData,
    setHaveChangesBeenMade,
    index,
    risksystemData,
    tag,
  } = props;

  // Convert the data into an object.
  const [dataJson, setDataJson] = React.useState(
    JSON.parse(delegatedActsElement)
  );

  // Create a table data object from the array.
  // const tableData = buildTableData(contentState);
  // Create an object for storing the state of each cell in the table.
  const [tableData, setTableData] = React.useState<TableEditorData[]>(
    buildTableData(dataJson.value, dataJson.keys)
  );

  const handleTextChange = (
    rowIndex: number,
    columnName: string,
    text: string
  ) => {
    // Now update the element in the overall data
    const allContent = delegatedActsData;
    // Check if the text has changes and if so indicate it
    const dataJsonCopy = dataJson;
    if (dataJsonCopy.value[rowIndex][columnName] !== text)
      setHaveChangesBeenMade(true);

    // Update the value at the correct index in the list
    dataJsonCopy.value[rowIndex][columnName] = text;
    setDataJson(dataJsonCopy);
    // Now replace in the overall array
    allContent[index].content = JSON.stringify(dataJsonCopy);

    // Update the overall data
    setDelegatedActsData(allContent);
  };

  const columns = buildTableColumns(
    handleTextChange,
    dataJson.headers,
    dataJson.keys,
    dataJson
  );

  const refreshFromRisksystem = () => {
    // Case where the esg scores table is being refreshed.
    if (tag === 'a4_esg_scores_table') {
      const data = risksystemData.sustainability_indicator_performance;
      // Now refresh the text with the data from risksystem.
      const allContent = delegatedActsData;
      const dataJsonCopy = dataJson;
      // Check if the text has changes and if so indicate it
      if (dataJsonCopy.value !== data) setHaveChangesBeenMade(true);
      dataJsonCopy.value = data;
      setDataJson(dataJsonCopy);
      allContent[index].content = JSON.stringify(dataJsonCopy);
      // Update the overall data
      setDelegatedActsData(allContent);
      // Update the editors in the table
      setTableData(buildTableData(dataJsonCopy.value, dataJsonCopy.keys));
    } else if (tag === 'a4_asset_allocation_table') {
      const data = risksystemData.asset_allocation;
      // Now refresh the text with the data from risksystem.
      const allContent = delegatedActsData;
      // Check if the text has changes and if so indicate it
      const dataJsonCopy = dataJson;
      if (dataJson.dataJsonCopy !== data) setHaveChangesBeenMade(true);
      dataJsonCopy.value = data;
      setDataJson(dataJsonCopy);
      allContent[index].content = JSON.stringify(dataJsonCopy);
      // Update the overall data
      setDelegatedActsData(allContent);
      // Update the editors in the table
      setTableData(buildTableData(dataJsonCopy.value, dataJsonCopy.keys));
    } else if (tag === 'a4_sector_table') {
      const data = risksystemData.sector_allocation;
      // Now refresh the text with the data from risksystem.
      const allContent = delegatedActsData;
      // Check if the text has changes and if so indicate it
      const dataJsonCopy = dataJson;
      if (dataJsonCopy.value !== data) setHaveChangesBeenMade(true);
      dataJsonCopy.value = data;
      setDataJson(dataJsonCopy);
      allContent[index].content = JSON.stringify(dataJsonCopy);

      // Update the overall data
      setDelegatedActsData(allContent);
      // Update the editors in the table
      setTableData(buildTableData(dataJsonCopy.value, dataJsonCopy.keys));
    } else {
      // Just show an alert if we don't have an implementation for this type of table yet.
      window.alert('No Data Available from Risksystem for this table.');
    }
  };

  const modifyTableRows = (addRemove: 'add' | 'remove') => {
    // Add another row to the table.
    if (addRemove === 'add') {
      // Get the keys that we need to populate in order to add a row.
      const dataKeys = dataJson.keys;
      // Create a new row to add to the table.
      const newRow: any = {};
      dataKeys.forEach((key: string) => {
        newRow[key] = '';
      });
      // Now Get the overvall data for the document
      const allContent = delegatedActsData;
      // Indicate that some data has been changed
      setHaveChangesBeenMade(true);
      const dataJsonCopy = dataJson;
      const dataValue = JSON.parse(JSON.stringify(dataJsonCopy.value));
      dataValue.push(newRow);
      // Add the new row to the array
      dataJsonCopy.value = dataValue;
      setDataJson(dataJsonCopy);
      // Now update the overall data
      allContent[index].content = JSON.stringify(dataJsonCopy);

      // Update the overall data
      setDelegatedActsData(allContent);
      // Rebuild the table
      setTableData(buildTableData(dataJsonCopy.value, dataJsonCopy.keys));
    } else if (addRemove === 'remove') {
      // Remove a row from the table
      // Now Get the overvall data for the document
      const allContent = delegatedActsData;
      // Indicate that some data has been changed
      setHaveChangesBeenMade(true);
      const dataJsonCopy = dataJson;
      // Remove the last element from the array
      dataJsonCopy.value.pop();
      setDataJson(dataJsonCopy);
      // Now update the overall data
      allContent[index].content = JSON.stringify(dataJsonCopy);

      // Update the overall data
      setDelegatedActsData(allContent);
      // Rebuild the table
      setTableData(buildTableData(dataJsonCopy.value, dataJsonCopy.keys));
    }
  };

  return (
    <GridItem xs={12} card style={{ marginBottom: '1rem' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h3"
          style={{ fontSize: '1.5rem', color: greys.grey400 }}
        >
          Annex 4
        </Typography>
        <Button
          // className={classes.button}
          variant="contained"
          onClick={refreshFromRisksystem}
          endIcon={
            <img
              style={{ height: '1.5rem', width: '1.5rem' }}
              src={RaptorLogo}
            />
          }
          style={{
            height: '3rem',
            marginTop: '0.5rem',
            marginRight: '1rem',
            marginBottom: '1rem',
            // width: '12rem',
            backgroundColor: mainColors.controlButtonBlue,
            color: 'white',
          }}
        >
          <div style={{ color: 'white' }}>Refresh from RiskSystem</div>
        </Button>
      </div>
      <CustomTable<TableEditorData>
        title={dataJson.question}
        showToolbar={true}
        id={`annex_investment_table_table_${index}`}
        data={tableData}
        options={{
          paging: false,
          search: false,
          exportButton: false,
          exportAllData: false,
          emptyRowsWhenPaging: false,
          sorting: false,
          draggable: false,
        }}
        columns={columns}
      />
      <div
        style={{
          marginTop: '1rem',
          marginLeft: '1rem',
          marginBottom: '1rem',
        }}
      >
        <Icon
          sx={{
            fontSize: 25,
            color:
              dataJson.value.length === 1 ? greys.grey500 : mainColors.Fail,
            opacity: 0.6,
            '&:hover': {
              cursor: 'pointer',
              opacity: 1,
            },
          }}
          onClick={
            dataJson.value.length === 1
              ? undefined
              : () => {
                  modifyTableRows('remove');
                }
          }
        >
          remove_circle
        </Icon>
        <Icon
          sx={{
            fontSize: 25,
            color:
              dataJson.value.length >= getMaxNumRows(tag)
                ? greys.grey500
                : mainColors.controlButtonBlue,
            opacity: 0.6,
            '&:hover': {
              cursor: 'pointer',
              opacity: 1,
            },
          }}
          onClick={
            dataJson.value.length >= getMaxNumRows(tag)
              ? undefined
              : () => {
                  modifyTableRows('add');
                }
          }
        >
          add_circle
        </Icon>
      </div>
    </GridItem>
  );
};

export default TableEditorTile;
