import React from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import { mapStatusToColor } from '../../../../utilities/colorUtilities';
import { specifyStatus } from '../../../../utilities/specfyStatus';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import {
  SifOverviewData,
  generateSifOverviewColumns,
  generateSifOverviewData,
} from './Overview.data';
import OverviewSecondLevelGraphs from '../../sharedComponents/overview/OverviewSecondLevelGraphs.component';
import { useSelector } from 'react-redux';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import { createSectionByIdSelector } from '../../../../redux/pages/selectors';

export const sifRaifOverviewCsvFields = [
  'index',
  'internalFundName',
  'fundName',
  'positionDate',
  'currency',
  'netAssetValue',
  'reports',
  'leverage',
  'cssfRiskSpreading',
  'prospectusRestrictions',
];

export const adepaSifRaifOverviewCsvFields = [
  'internalFundName',
  'fundName',
  'positionDate',
  'currency',
  'netAssetValue',
  'reports',
  'leverage',
  'cssfRiskSpreading',
  'prospectusRestrictions',
];

const SifRaifOverviewPure: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = (props) => {
  const sectionDetails = useSelector(createSectionByIdSelector(props.section));
  const fundTypes = sectionDetails?.fundTypes;
  const clientName = useSelector(clientNameSelector);

  const schemas = sectionDetails?.fundTypes.length
    ? `?schemas=${sectionDetails?.fundTypes}`
    : '';
  const reportsList = useFetchData({
    url: `reports_list` + schemas,
    keyName: `${fundTypes}_overview_reports_list`,
    makeFetch: true,
  });
  const overviewData = useFetchData({
    url: `schema_overview_monitor/${props.positionDate}` + schemas,
    keyName: `${fundTypes}_overview_data_${props.positionDate}`,
    makeFetch: props.positionDate ? true : false,
  });
  const subFundRiskReportsList = useFetchData({
    url: 'reports_list/sub_fund_risk_report' + schemas,
    keyName: `${fundTypes}_sub_fund_risk_report`,
    makeFetch: clientName?.includes('pure') || false,
  });

  const columns = generateSifOverviewColumns();

  const data = generateSifOverviewData(
    overviewData?.data[0],
    reportsList,
    subFundRiskReportsList
  );

  return overviewData ? (
    <GeneralComponentErrorShield dataObjects={[overviewData, reportsList]}>
      <GridItem card xs={12}>
        <CustomTable<SifOverviewData>
          data={data}
          title="SIF / RAIF Overview Data"
          columns={columns}
          csvFields={
            clientName === 'adepa'
              ? adepaSifRaifOverviewCsvFields
              : sifRaifOverviewCsvFields
          }
          id="sif-raif-overview-data"
          options={{
            search: true,
            exportButton: true,
            paging: false,
            raptorExport: true,
          }}
          detailPanel={[
            {
              disabled: false,
              render: (rowData: SifOverviewData) => {
                return (
                  <OverviewSecondLevelGraphs
                    fundName={rowData.internalFundName}
                    positionDate={rowData.positionDate}
                    varFund={false}
                    passValue={mapStatusToColor(
                      specifyStatus(rowData.historicalTimeSeriesStatus)
                    )}
                  />
                );
              },
            },
          ]}
          onRowClick={(event: any, rowData, togglePanel: any) => {
            return togglePanel!();
          }}
          showToolbar={true}
        />
      </GridItem>
    </GeneralComponentErrorShield>
  ) : null;
};

export default SifRaifOverviewPure;
