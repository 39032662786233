import React from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import MancoStaticExposureTable from './subComponents/MancoStaticExposureTable.component';
import MancoAssetGrossExposure from './subComponents/MancoAssetGrossExposure.component';
import MancoPositionsTable from './subComponents/newMancoPositionsTable/MancoPositionsTable.component';
import MancoExposureBarChart from './subComponents/MancoExposureBarChart.component';
import GridItem from '../../../layout/GridComponents/GridItem';
import MancoCountryExposureBarChart from './subComponents/MancoCountryExposureBarChart.component';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import { Grid } from '@mui/material';
import GeneralPositionsTable from '../../sharedComponents/exposureComponents/generalTable/GeneralPositionsTable';

const MancoExposure: React.FC<GeneralOverviewPageRenderedComponentProps> = ({
  positionDate,
}) => {
  const mancoExposureData = useFetchData({
    url: `manco_gem/${positionDate}`,
    keyName: `manco_exposure_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[mancoExposureData]}
      customLoadingMessages={[
        'Loading Exposure Data...',
        'This May Take Few Moments',
      ]}
      customErrorMessage="No positions available for this date"
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        <GridItem
          xs={5}
          style={{ display: 'flex', flexDirection: 'column', gap: 16 }}
        >
          <MancoAssetGrossExposure data={mancoExposureData} />
          <MancoStaticExposureTable dataForRender={mancoExposureData} />
        </GridItem>
        <MancoExposureBarChart data={mancoExposureData} />
        <MancoCountryExposureBarChart data={mancoExposureData} />

        {/* <MancoPositionsTable
          dataForRender={mancoExposureData}
          getRowCanExpand={() => true}
          fundId={'manco'}
          fundName={'manco'}
        /> */}
        <GeneralPositionsTable
          dataForRender={mancoExposureData}
          getRowCanExpand={() => true}
          exposureType={'generalExposure'}
          fundId={'manco'}
          fundName={'manco'}
        />
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default MancoExposure;
