import { FC, useEffect, useState } from 'react';
import { raidrClient } from '../../../../../utilities/requestClient';
import useRouteParams from '../../../../../hooks/useRouteParams';
import DelegatedActsEditorColumn from './EditorTiles/DelegatedActsEditorColumn.component';
import PdfViewer from './Pdfviewer/PdfViewer.component';
import { useDispatch } from 'react-redux';
import { updateSection } from '../../../../../redux/pages/actions';
import BackToOverviewButton from '../Buttons/BackToOverviewButton.component';
import { useHistory } from 'react-router-dom';
import GeneralComponentErrorShield from '../../../../general/GeneralComponentErrorShield';

const DelegatedActsEditor: FC = (props) => {
  // Need to add this here for the moment until the routing is improved
  const dispatch = useDispatch();
  dispatch(updateSection('raidr'));
  const history = useHistory();

  const requestClient = raidrClient();
  const [delegatedActsData, setDelegatedActsData] = useState<any[] | null>(
    null
  );
  // Will be used to dertmine if any of the content has been changed and if so then it can be saved.
  const [haveChangesBeenMade, setHaveChangesBeenMade] =
    useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const { fundId } = useRouteParams('fundId');
  const { fundCode } = useRouteParams('fundCode');
  const { fundName } = useRouteParams('fundName');
  const getDelegeatedActsData = async () => {
    requestClient
      .get(`/get_raidr_data/esg_delegated_acts/English/${fundId}`)
      .then((response) => {
        setDelegatedActsData(response.data);
        setIsPageLoading(false);
      });
  };
  useEffect(() => {
    getDelegeatedActsData();
  }, []);

  return (
    <GeneralComponentErrorShield
      dataObjects={[
        {
          data: delegatedActsData || [],
          isFetching: isPageLoading,
          error: null,
        },
      ]}
    >
      <BackToOverviewButton
        backToOverview={() => {
          history.push(`overview`);
        }}
      />
      <div style={{ width: '48%', marginTop: '3rem' }}>
        <DelegatedActsEditorColumn
          delegatedActsData={delegatedActsData || []}
          setDelegatedActsData={setDelegatedActsData}
          setHaveChangesBeenMade={setHaveChangesBeenMade}
        />
      </div>
      <div
        style={{ width: '48%', marginLeft: '2%', position: 'sticky', top: 60 }}
      >
        <PdfViewer
          delegatedActsData={delegatedActsData}
          haveChangesBeenMade={haveChangesBeenMade}
          setHaveChangesBeenMade={setHaveChangesBeenMade}
          fundId={fundCode}
          fundName={fundName}
        />
      </div>
    </GeneralComponentErrorShield>
  );
};

export default DelegatedActsEditor;
