import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToNdecialPlaces,
} from '../../../../../utilities/numberFormatters';

interface SubFundPerformanceOverviewProps {
  data: any;
}

interface SubFundPerformanceOverviewData {
  data: string;
  value: string | number;
}

const useStyles = makeStyles(() => ({
  tableCard: {
    padding: '1rem',
  },
}));

const handleFormatData = (rowData: SubFundPerformanceOverviewData): any => {
  if (
    rowData.data === 'Calculated Asset P/L' ||
    rowData.data === 'Calculated Currency P/L' ||
    rowData.data === 'Calculated FX P/L' ||
    rowData.data === 'Calculated Total P/L' ||
    rowData.data === 'NAV'
  ) {
    return addCommasToNumbersAndRound(rowData.value);
  } else if (
    rowData.data === 'Calculated Asset P/L (%)' ||
    rowData.data === 'Calculated Currency P/L (%)' ||
    rowData.data === 'Calculated FX P/L (%)' ||
    rowData.data === 'Calculated Total P/L (%)'
  ) {
    return percentageToNdecialPlaces((rowData.value as number) / 100, 2);
  } else {
    return rowData.value;
  }
};
const detailColumns: CustomColumn<SubFundPerformanceOverviewData>[] = [
  {
    title: 'Data',
    field: 'data',
    width: '65%',
    cellStyle: {
      textAlign: 'left',
      fontSize: '1.55rem',
      fontWeight: 500,
    },
    headerStyle: {
      textAlign: 'left',
    },
  },
  {
    title: 'Value',
    field: 'value',
    cellStyle: {
      textAlign: 'right',
      fontSize: '1.7rem',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SubFundPerformanceOverviewData) =>
      handleFormatData(rowData),
  },
];

const buildTableData = (inputData: any): SubFundPerformanceOverviewData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    let calculatedCurrencyPL = 0;
    let calculatedCurrencyPLPc = 0;
    let calculatedFXPL = 0;
    let calculatedFXPLPc = 0;

    Object.keys(inputData.data[0].position_level).map((asset) => {
      if (
        inputData.data[0].position_level[asset].asset_class ===
        'Foreign Exchange'
      ) {
        calculatedFXPL +=
          inputData.data[0].position_level[asset].aggregate_currency_pl;
        calculatedFXPLPc +=
          inputData.data[0].position_level[asset].aggregate_currency_pl_pct;
      } else {
        calculatedCurrencyPL +=
          inputData.data[0].position_level[asset].aggregate_currency_pl;
        calculatedCurrencyPLPc +=
          inputData.data[0].position_level[asset].aggregate_currency_pl_pct;
      }
    });

    const returnData: SubFundPerformanceOverviewData[] = [
      {
        data: 'NAV',
        value: inputData.data[0].fund_level.overview['NAV'],
      },
      {
        data: 'Base Currency',
        value: inputData.data[0].fund_level.overview['Base Currency'],
      },
      {
        data: 'Calculated Asset P/L',
        value: inputData.data[0].fund_level.overview['Asset P/L'],
      },
      {
        data: 'Calculated Asset P/L (%)',
        value: inputData.data[0].fund_level.overview['Asset P/L (%)'],
      },
      {
        data: 'Calculated Currency P/L',
        value: calculatedCurrencyPL,
      },
      {
        data: 'Calculated Currency P/L (%)',
        value: calculatedCurrencyPLPc,
      },
      {
        data: 'Calculated FX P/L',
        value: calculatedFXPL,
      },
      {
        data: 'Calculated FX P/L (%)',
        value: calculatedFXPLPc,
      },
      {
        data: 'Calculated Total P/L',
        value: inputData.data[0].fund_level.overview['Total P/L'],
      },
      {
        data: 'Calculated Total P/L (%)',
        value: inputData.data[0].fund_level.overview['Total P/L (%)'],
      },
    ];
    return returnData;
  }
};

const SubFundPerformanceOverview: React.FC<SubFundPerformanceOverviewProps> = (
  props
) => {
  const classes = useStyles();

  const builtTableData = buildTableData(props.data);

  return (
    <GridItem
      xs={3}
      card
      className={classes.tableCard}
      cardStyle={{ height: '100%' }}
    >
      <CustomTable<SubFundPerformanceOverviewData>
        columns={detailColumns}
        showToolbar
        data={builtTableData}
        title={'Overview Calculated Returns Data'}
        options={{
          paging: false,
          search: false,
          exportButton: true,
          header: false,
        }}
        tableRootStyles="width: 100%;"
      />
    </GridItem>
  );
};

export default SubFundPerformanceOverview;
