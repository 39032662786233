/// @ts-nocheck
import { OutputSelector, createSelector } from 'reselect';
import { AppState } from '../../types/redux/store';
import {
  ClientConfiguration,
  Fund,
  FundType,
  IconKey,
  Page,
  Section,
} from '../../types/server-data/ClientConfig';
import {
  BaseTitleBarComponentsKeys,
  TitleBarComponent,
} from '../../types/redux/pages/PagesStore';

export const getPageState = (state: AppState) => state.pages;

export const activeSectionSelector = createSelector(
  getPageState,
  (pageState) => pageState.activeSection
);
export const activePageSelector = createSelector(
  getPageState,
  (pageState) => pageState.activePage
);

export const allPagesSelector = createSelector(
  getPageState,
  (pageState) => pageState.clientConfiguration.pages
);

// this will only return the fundId
export const createActiveFundSelectorBySection = (
  sectionKey: string | null
) => {
  return createSelector(getPageState, (pageState) => {
    if (
      !pageState.clientConfiguration ||
      !pageState.clientConfiguration.sections
    )
      return null;
    const relevantSection: Section | undefined =
      pageState.clientConfiguration.sections.find(
        (section: Section) => section.sectionId === sectionKey
      );
    if (!relevantSection) return null;
    const fundType = relevantSection.fundTypes;
    const rsResource = relevantSection.rsResources;
    if (
      fundType &&
      rsResource &&
      pageState.funds &&
      `${fundType}_${rsResource}` in pageState.funds &&
      sectionKey
    ) {
      return pageState.funds[`${fundType}_${rsResource}`]?.fundData.find(
        (fund: Fund) => fund.id === pageState.activeFundsBySection[sectionKey]
      );
    }
    return null;
  });
};

export const clientConfigurationSelector = createSelector(
  getPageState,
  (pageState) => pageState.clientConfiguration
);

export const clientSectionsSelector = createSelector(
  getPageState,
  (pageState) => pageState.clientConfiguration.sections
);

export const activePageAreaSelector = createSelector(
  getPageState,
  (pageState) => pageState.activePageArea
);
// creates page info based on pageId and sectionId
export const createPageDetailsSelector = (
  // the page defined in the url
  requestedPageId: string | null,
  // section defined in the url
  requestedSectionId: string | null
) =>
  createSelector(getPageState, (pageState) => {
    if (!requestedPageId || !requestedSectionId) return null;
    let pageToReturn: Page | null = null;
    if (
      pageState.clientConfiguration &&
      pageState.clientConfiguration.sections
    ) {
      pageState.clientConfiguration.sections.forEach((section) => {
        if (section.sectionId === requestedSectionId) {
          section.pages.forEach((page) => {
            if (
              page === requestedPageId &&
              pageState.clientConfiguration.pages
            ) {
              pageToReturn = pageState.clientConfiguration.pages[page];
            }
          });
        }
      });
    }
    if (!pageToReturn) {
      pageToReturn = {
        pageTitle: 'Not Found',
        pageKey: 'not-found',
        pageArea: '-',
        pageId: 'notFound',
        pageRoute: '/404',
        titleBarComponents: {
          selects: [],
          inputs: [],
          datePickers: [],
          showStatus: false,
          loading: false,
          activeDate: '',
          activeDateRangeStart: '',
          activeDateRangeEnd: '',
          availableDates: [],
          infoToggles: [],
        },
        locked: false,
        iconKey: IconKey.EYE,
        allowFundPages: false,
        useFundPicker: false,
        usePositionDatePicker: false,
        usePositionDatePickerRange: false,
        exportable: false,
      };
    }
    return pageToReturn;
  }); //

// TODO: Fix type issues
export const createSectionByIdSelector = (
  requestedId: string
): OutputSelector<
  AppState,
  Section | null,
  (res: ClientConfiguration) => Section | null
> => {
  return createSelector(clientConfigurationSelector, (clientConfig) => {
    let sectionToReturn: Section | null = null;
    clientConfig.sections?.forEach((section: Section) => {
      if (section.sectionId === requestedId) {
        sectionToReturn = section;
      }
    });
    return sectionToReturn;
  });
};

export const activePageDisplayNameSelector = createSelector(
  getPageState,
  (pageState) => pageState.activePageDisplayName
);
export const createSectionFundsSelector = (requestedSection: string) =>
  createSelector(getPageState, (pageState) => {
    if (!pageState.clientConfiguration) return null;
    const relevantSection = pageState.clientConfiguration.sections?.find(
      (section) => section.sectionId === requestedSection
    );

    const funds =
      pageState.funds && relevantSection
        ? pageState.funds[
            `${relevantSection?.fundTypes}_${relevantSection?.rsResources}`
          ]
        : null;
    const filteredFunds =
      pageState.funds && relevantSection
        ? pageState.funds[
            `${relevantSection?.fundTypes}_${relevantSection?.rsResources}`
          ]?.filteredFunds
        : null;

    return funds && filteredFunds
      ? funds.fundData.filter((fund) =>
          filteredFunds.length > 0
            ? filteredFunds.includes(fund.id as never)
            : true
        )
      : null;
  });

export const overviewFundsSelector = createSelector(
  getPageState,
  (pageState) => pageState.funds
);

export const createOverviewDataSelectorByFundType = (
  fundType: FundType,
  rsResource: string
) => {
  return createSelector(getPageState, (pageState) =>
    pageState.funds
      ? pageState.funds[`${fundType}_${rsResource}`]?.requestedData
      : null
  );
};

export const createSectionFundIdsSelector = (requestedSection: string) =>
  createSelector(createSectionFundsSelector(requestedSection), (funds) => {
    return funds ? funds.map((fund: Fund) => fund.id) : null;
  });

export const createFundNameFromFundIdSelector = (fundId: string) =>
  createSelector(getPageState, (pageState) => {
    let name: string | null = null;
    if (pageState.funds) {
      Object.keys(pageState.funds).forEach((fundType: string) => {
        pageState.funds![fundType]?.fundData.forEach((fund: Fund) => {
          if (fund.id === fundId) {
            name = fund.name;
          }
        });
      });
    }
    return name;
  });

export const createSectionFundNamesSelector = (requestedSection: string) =>
  createSelector(createSectionFundsSelector(requestedSection), (funds) => {
    return funds ? funds.map((fund: Fund) => fund.name) : null;
  });

export const currentFundSearchStringSelector = createSelector(
  getPageState,
  (pageState) => pageState.currentFundSearchString
);
export const openingRouteSelector = createSelector(
  getPageState,
  (pageState) => pageState.clientConfiguration.openingPage
);
export const createSpecificFundNameAndIdSelectorByFundType = (
  fundType: string,
  fundId: string,
  rsResource: string
) => {
  return createSelector(getPageState, (pageState) => {
    return pageState.funds
      ? pageState.funds[`${fundType}_${rsResource}`]!.fundData.find(
          (fund: Fund) => fund.id === fundId
        )
      : null;
  });
};

export const activePageIdSelector = createSelector(
  getPageState,
  (pageState) => pageState.activePage
);

export const titleBarComponentsSelector = createSelector(
  getPageState,
  (pageState) => {
    return pageState.titleBarComponents;
  }
);

export const showStatusSelector = createSelector(
  titleBarComponentsSelector,
  (components) => {
    return components.showStatus;
  }
);
export const titleBarSelectsSelector = createSelector(
  getPageState,
  (pageState) => pageState.titleBarComponents.selects
);
export const titleBarInfoTogglesSelector = createSelector(
  getPageState,
  (pageState) => pageState.titleBarComponents.infoToggles
);
export const titleBarInputsSelector = createSelector(
  getPageState,
  (pageState) => pageState.titleBarComponents.inputs
);

export const titleBarDatePickersSelector = createSelector(
  getPageState,
  (pageState) => pageState.titleBarComponents.datePickers
);

export const createSpecificTitlebarInputSelector = (inputKey: string) =>
  createSelector(
    getPageState,
    (pageState) =>
      pageState.titleBarComponents.inputs.find(
        (input) => input.titleBarKey === inputKey
      )?.currentValue
  );

export const isTitlebarLoadingSelector = createSelector(
  titleBarComponentsSelector,
  (comps) => comps.loading
);

export const activeDateSelector = createSelector(
  titleBarComponentsSelector,
  (comps) => comps.activeDate
);

export const activeDateRangeStartSelector = createSelector(
  titleBarComponentsSelector,
  (comps) => comps.activeDateRangeStart
);

export const activeDateRangeEndSelector = createSelector(
  titleBarComponentsSelector,
  (comps) => comps.activeDateRangeEnd
);

export const availableDatesSelector = createSelector(
  titleBarComponentsSelector,
  (comps) => comps.availableDates
);

export const createSpecificTitleBarSelectSelector = (selectKey: string) =>
  createSelector(titleBarSelectsSelector, (selects) =>
    selects.filter((select) => select.titleBarKey === selectKey)
  );

export const createSpecificTitleBarComponentSelector = (selectKey: string) =>
  createSelector(getPageState, (pageState) => {
    let componentToReturn: TitleBarComponent | null = null;
    ['selects', 'inputs', 'datePickers'].forEach((keyName: string) => {
      pageState.titleBarComponents[
        keyName as BaseTitleBarComponentsKeys
      ].forEach((comp: TitleBarComponent) => {
        if (comp.titleBarKey === selectKey) {
          componentToReturn = comp;
        }
      });
    });
    return componentToReturn as TitleBarComponent | null;
  });

export const createSpecificTitleBarDatePickerSelector = (key: string) =>
  createSelector(titleBarDatePickersSelector, (datePickers) =>
    datePickers.filter((datePicker) => datePicker.titleBarKey === key)
  );

export const openingAnimationIsActiveSelector = createSelector(
  getPageState,
  (st) => st.openingAnimation
);
