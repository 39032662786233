import React, { FC } from 'react';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';

interface DiscountCashflowConfigurationSummaryTableProps {
  data: any;
  selectedPosition: string | null;
  // setBarChartFilter: Dispatch<any>;
  // barChartValue: string;
  // percentValueOption: string;
  // setPercentValueOption: Dispatch<string>;
  // nav: number;
}

interface IDCFConfigurationSummaryData {
  valuation: number;
  ev: number;
  currency: string;
  ebit: number;
  ebitMultiple: number;
  ebitVolatility: number;
  ebitGrowth: number;
}
interface IDCFConfigurationSummaryDataPortfolio {
  currency: string;
  ebit: number;
  nav: number;
}

const detailColumns: CustomColumn<IDCFConfigurationSummaryData>[] = [
  {
    title: 'Present Valuation',
    field: 'valuation',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    // customSort: (rowData: any) => 1,
    render: (rowData: IDCFConfigurationSummaryData) =>
      addCommasToNumbersAndRound(rowData.valuation),
  },
  {
    title: 'EV',
    field: 'ev',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    // customSort: (rowData: any) => 1,
    render: (rowData: IDCFConfigurationSummaryData) =>
      percentageToTwoDecimalPlaces(rowData.ev),
  },
  {
    title: 'Currency',
    field: 'currency',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    // customSort: (rowData: any) => 1,
  },
  {
    title: 'EBIT',
    field: 'ebit',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    // customSort: (rowData: any) => 1,
    render: (rowData: IDCFConfigurationSummaryData) =>
      percentageToTwoDecimalPlaces(rowData.ebit),
  },
  {
    title: 'EBIT Multiple',
    field: 'ebitMultiple',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    // customSort: (rowData: any) => 1,
    // render: (rowData: IDCFOverviewSummaryData) => percentageToTwoDecimalPlaces(rowData.ebitMultiple)
  },
  // {
  //     title: 'EBIT Volatility',
  //     field: 'ebitVolatility',
  //     cellStyle: { textAlign: 'center' },
  //     headerStyle: { textAlign: 'center' },
  //     // customSort: (rowData: any) => 1,
  // },
  {
    title: 'EBIT Growth',
    field: 'ebitGrowth',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    // customSort: (rowData: any) => 1,
    render: (rowData: IDCFConfigurationSummaryData) =>
      percentageToTwoDecimalPlaces(rowData.ebitGrowth),
  },
];
const detailColumnsPortfolio: CustomColumn<IDCFConfigurationSummaryDataPortfolio>[] =
  [
    {
      title: 'Currency',
      field: 'currency',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      // customSort: (rowData: any) => 1,
    },
    {
      title: 'EBIT',
      field: 'ebit',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      // customSort: (rowData: any) => 1,
      render: (rowData: IDCFConfigurationSummaryDataPortfolio) =>
        addCommasToNumbersAndRound(rowData.ebit),
    },
    {
      title: 'NAV',
      field: 'nav',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      // customSort: (rowData: any) => 1,
      render: (rowData: IDCFConfigurationSummaryDataPortfolio) =>
        addCommasToNumbersAndRound(rowData.nav),
    },
  ];

function buildDCFConfigurationSummaryData(
  data: any,
  selectedPosition: string | null
) {
  if (!data.data.length) return [];
  if (!selectedPosition) return [];
  const peDataPositions = data.data[0];
  const peDataFiltered = peDataPositions.filter((obj: any) => {
    return obj.name === selectedPosition;
  });
  const peData = peDataFiltered[0].preferences;
  const tableData = [
    {
      valuation: peDataFiltered[0]['P/L'],
      ev: peData['EV'],
      currency: peData['Currency'],
      ebit: peData['EBIT'],
      ebitMultiple: peData['EBIT Multiple'],
      ebitVolatility: peData['EBIT Volatility'],
      ebitGrowth: peData['EBIT Growth'],
    },
  ];
  return tableData;
}

function buildDCFConfigurationSummaryDataPortfolio(data: any) {
  if (!data.data.length) return [];
  const peDataPositions = data.data[0];
  const peDataFiltered = peDataPositions.filter((obj: any) => {
    return obj.name === 'Portfolio';
  });
  const peData = peDataFiltered[0].preferences;
  const tableData = [
    {
      currency: peData['Currency'],
      ebit: peData['EBIT'],
      nav: peData['NAV'],
    },
  ];
  return tableData;
}

const DiscountCashflowConfigurationSummaryTable: FC<
  DiscountCashflowConfigurationSummaryTableProps
> = (props) => {
  // const theme = useTheme();
  const { data, selectedPosition } = props;
  const tableData =
    selectedPosition === 'Portfolio'
      ? buildDCFConfigurationSummaryDataPortfolio(data)
      : buildDCFConfigurationSummaryData(data, selectedPosition);
  const { height, width } = useWindowSize();
  // Allow user to switch between scenrio, change and both
  // const [displayOption, setDisplayOption] = useState<string>('scenario');
  // const detailColumns = buildDetailColumns(props.setBarChartFilter, props.barChartValue, percentValueOption);
  // const detailColumnsDifference = buildDetailColumnsDifferenceData(props.setBarChartFilter, props.barChartValue, percentValueOption, displayOption)

  // console.log("HERE IS DEFAULT SCENARIO", isDefaultScenario);

  return (
    <GridItem xs={12} card style={{ padding: 8 }}>
      {/* <Typography variant='h2' style={{ marginLeft: '2rem', marginTop: '1rem', textAlign: 'center' }}>
                {"DCF Summary"}
            </Typography> */}
      {selectedPosition === 'Portfolio' ? (
        <CustomTable<IDCFConfigurationSummaryDataPortfolio>
          pdfTitle={'Portfolio Summary'}
          id={'dcf_summary_table'}
          columns={detailColumnsPortfolio}
          showToolbar
          title={'Portfolio Summary'}
          data={buildDCFConfigurationSummaryDataPortfolio(data)}
          // toolbarComponents={{
          //     toolbarTitle: formattedNames[tableType],
          // }}
          options={{
            paging: false,
            search: false,
            exportButton: true,
            sorting: false,
          }}
        />
      ) : (
        <CustomTable<IDCFConfigurationSummaryData>
          pdfTitle={`${selectedPosition} Summary`}
          id={`dcf_summary_table`}
          columns={detailColumns}
          showToolbar
          title={`${selectedPosition} Summary`}
          data={buildDCFConfigurationSummaryData(data, selectedPosition)}
          // toolbarComponents={{
          //     toolbarTitle: formattedNames[tableType],
          // }}
          options={{
            paging: false,
            search: false,
            exportButton: true,
            sorting: false,
          }}
        />
      )}
    </GridItem>
  );
};

export default DiscountCashflowConfigurationSummaryTable;
