import React, { ReactElement } from 'react';
import withChartWrapper from '../withChartWrapper';
import { buildEvolutionChart } from './portfolioLiquidation.data';
import { mainColors } from '../../../../../../styling/theme';
import { DataObject } from '../../../../../../types/redux/data/dataTypes';
import ExportButton from '../../../../../feedback/ExportButton';
import GenericLineChart from '../../../../../charts/GenericLineChart';
import { GridProps, Typography } from '@mui/material';

export interface Dimensions {
  dimensions: GridProps;
}

interface Props extends Dimensions {
  inputData: DataObject;
  height: number;
}

interface TitleAndExportContainerProps {
  children: React.ReactNode;
  title: string;
  chartData: any[];
  chartFields: string[];
}
export const TitleAndExportContainer: React.FC<TitleAndExportContainerProps> = (
  props
) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '1rem',
      }}
    >
      {props.children}
      <Typography variant="h3" style={{ fontSize: '2.4rem', fontWeight: 300 }}>
        {props.title}
      </Typography>
      <ExportButton exportData={props.chartData} fields={props.chartFields} />
    </div>
  );
};

function PortfolioLiquidation({
  inputData: { isFetching, data, error },
  height,
}: Props): ReactElement {
  const chartData = buildEvolutionChart(data, 'q1');
  return (
    <>
      <GenericLineChart
        error={error}
        export
        download
        loading={isFetching}
        width="90%"
        margin={{ top: 10, left: 0, right: 30, bottom: 10 }}
        height={height}
        title="Portfolio Liquidation Time"
        showTitle
        data={chartData}
        legendFormatter={(value: any, entry: any) => {
          const { color } = entry;
          let name;
          if (value === 'bau') {
            name = 'BAU';
          } else if (value === 'stressed') {
            name = 'Stressed';
          } else {
            name = 'Super Stressed';
          }

          return <span style={{ color }}>{name}</span>;
        }}
        yAxisLabel={{ value: 'Days', angle: -90, position: 'insideLeft' }}
        yAxisDomain={[0, (dataMax: number) => dataMax * 1.1]}
        showLegend
        lines={[
          {
            dataKey: 'bau',
            color: mainColors.mainBlue,
            yAxisId: 'left',
          },
          {
            dataKey: 'stressed',
            color: mainColors.Alert,
            yAxisId: 'left',
          },
          {
            dataKey: 'superStressed',
            color: mainColors.secondaryRed,
            yAxisId: 'left',
          },
        ]}
      />
    </>
  );
}

export default withChartWrapper(PortfolioLiquidation);
