import { FC } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  formatESGWithUnit,
  percentageToTwoDecimalPlaces,
  roundNumberToNDecimalPlaces,
  roundNumberToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { Tooltip } from '@mui/material';

interface PrincipleAdverseImpactSecondLevelTableProps {
  rules: { [index: string]: any };
  inScopeCodes: string[];
  inScopePositions: string[];
  positions: any[];
  nav: number;
}

function buildPrincipleAdverseImpactSecondLevelTaleData(
  inScopeCodes: string[],
  data: any,
  positions: any[],
  nav: number
): PrincipleAdverseImpactecondLevelTableData[] {
  if (!data.length) return [];
  // const articleData = data.positions;
  const tableData: PrincipleAdverseImpactecondLevelTableData[] = [];

  data.forEach((value: any) => {
    const position: any = positions.filter(
      (pos: any) => pos.position_id === value
    );
    const newObj: PrincipleAdverseImpactecondLevelTableData = {
      asset: position[0].gm_name,
      isin: position[0].isin,
      exposure: position[0].exposure * nav,
      grossExposure: position[0].gross_exposure * nav,
      grossExposurePc: position[0].gross_exposure,
      marketPrice: position[0].market_price,
      environmental: position[0].E_Score,
      social: position[0].S_Score,
      corporateGovernance: position[0].G_Score,
      esg: position[0].ESG_Score,
      currency: position[0].base_currency,
    };
    inScopeCodes.forEach((code: string) => {
      newObj[code] = position[0][code];
    });
    tableData.push(newObj);
  });

  return tableData;
}

interface PrincipleAdverseImpactecondLevelTableData {
  isin: string;
  asset: string;
  exposure: number;
  grossExposure: number;
  grossExposurePc: number;
  marketPrice: number;
  environmental: number;
  social: number;
  corporateGovernance: number;
  esg: number;
  currency: string;
  [key: string]: any;
}

const buildPrincipleAdverseImpactSecondLevelDetailColumns = (
  rules: { [index: string]: any },
  inScopeCodes: string[]
): CustomColumn<PrincipleAdverseImpactecondLevelTableData>[] => {
  const columns: CustomColumn<PrincipleAdverseImpactecondLevelTableData>[] = [
    {
      title: 'Asset',
      field: 'asset',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Isin',
      field: 'isin',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Exposure',
      field: 'exposure',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData: PrincipleAdverseImpactecondLevelTableData) =>
        addCommasToNumbersAndRound(rowData.exposure),
    },
    {
      title: 'Gross Exposure',
      field: 'grossExposure',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData: PrincipleAdverseImpactecondLevelTableData) =>
        addCommasToNumbersAndRound(rowData.grossExposure),
    },
    {
      title: 'Gross Exposure %',
      field: 'grossExposurePc',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData: PrincipleAdverseImpactecondLevelTableData) =>
        percentageToTwoDecimalPlaces(rowData.grossExposurePc),
    },
    {
      title: 'Market Price',
      field: 'marketPrice',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData: PrincipleAdverseImpactecondLevelTableData) =>
        roundNumberToNDecimalPlaces(rowData.marketPrice, 4),
    },
    {
      title: 'Currency',
      field: 'currency',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
  ];

  inScopeCodes.map((code: string) => {
    columns.push({
      title: (
        <Tooltip title={`${rules[code].indicator}`}>
          <div>
            {code}
          </div>
        </Tooltip>
      ),
      field: code,
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData: PrincipleAdverseImpactecondLevelTableData) =>
        formatESGWithUnit(rowData[code], rules[code].unit),
    });
  });

  return columns;
};

const PrincipleAdverseImpactSecondLevelTable: FC<
  PrincipleAdverseImpactSecondLevelTableProps
> = ({ rules, inScopeCodes, inScopePositions, positions, nav }) => {
  const tableData = buildPrincipleAdverseImpactSecondLevelTaleData(
    inScopeCodes,
    inScopePositions,
    positions,
    nav
  );

  const detailColumns = buildPrincipleAdverseImpactSecondLevelDetailColumns(
    rules,
    inScopeCodes
  );

  return (
    <GridItem xs={12} card>
      <CustomTable<PrincipleAdverseImpactecondLevelTableData>
        columns={detailColumns}
        showToolbar={true}
        data={tableData}
        options={{
          paging: false,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default PrincipleAdverseImpactSecondLevelTable;
