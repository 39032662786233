import React, { Dispatch, FC, ReactElement } from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import {
  CompositeDecorator,
  ContentState,
  DraftEditorCommand,
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
} from 'draft-js';
import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../../styling/theme';
import { stateToHTML } from 'draft-js-export-html';
import CancelIcon from '@mui/icons-material/Cancel';
import { connect } from 'react-redux';
import CommentEditorButton from '../Buttons/CommentEditorButton.component';
import { Typography } from '@mui/material';

interface EditorTileProps {
  tileType: 'text' | 'table' | 'srri';
  fundId: string | null;
  text?: string;
  testSpan?: 'column' | 'full';
  tableContents?: any;
  formatOptions?: string;
  index: number;
  setKiidsContent: Dispatch<any>;
  kiidsContent: any[];
  setChangesMade: Dispatch<any>;
  templateUpdates: any;
  setTemplateUpdates: Dispatch<any>;
  filtersApplied?: any;
  noFiltersApplied?: boolean;
  shareClass: string;
  commentId: string;
  hasUnresolvedComments: boolean;
}

interface formatButtonProps {
  text: string;
  inlineStyle: string;
  handler: (e: React.MouseEvent, inlineStyle: string) => void;
}

const useButtonStyles = makeStyles(() => ({
  button: {
    backgroundColor: mainColors.mainBlue_lighter,
    '&:hover, &:focus': {
      backgroundColor: mainColors.mainBlue,
      cursor: 'pointer',
    },
    border: 'none',
    marginRight: '0.5rem',
  },
}));

function mapToPastelColor(templateType: string) {
  switch (templateType) {
    case 'share_class':
      return mainColors.pastelRed;
    case 'manco':
      return mainColors.pastelGreen;
    case 'fund_manager':
      return mainColors.pastelBlue;
    case 'sub_fund':
      return mainColors.pastelYellow;
    default:
      return mainColors.pastelGreen;
  }
}
// const TemplateSection = (props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLSpanElement> & React.HTMLAttributes<HTMLSpanElement>) => {
const TemplateSection = (props: any) => {
  const text = props.decoratedText.replace('{{', '{').replace('}}', '}');
  const templateObject = JSON.parse(text);
  return (
    <span
      {...props}
      style={{
        background: mapToPastelColor(templateObject.type),
        color: 'white',
        marginLeft: '0.5rem',
        marginTop: '0.5rem',
        paddingLeft: '2px',
        paddingRight: '2px',
        borderRadius: '5px',
      }}
    >
      {/* {props.decoratedText.replace('{{', '').replace('}}', '')} */}
      {templateObject.text}
      <CancelIcon style={{ marginLeft: '0.5rem' }} />
    </span>
  );
};

const compositeDecorator = new CompositeDecorator([
  {
    strategy: templateStrategy,
    component: TemplateSection,
  },
]);

function templateStrategy(contentBlock: any, callback: any, contentState: any) {
  findWithRegex(contentBlock, callback);
}

const TEMPLATE_REGEX = /\{\{.*\}\}/g;

function findWithRegex(
  contentBlock: { getText: () => any },
  callback: (arg0: number, arg1: number) => void
) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = TEMPLATE_REGEX.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

function FormatButton(props: formatButtonProps): ReactElement {
  const { text, inlineStyle, handler } = props;

  const classes = useButtonStyles();

  return (
    <button
      onMouseDown={(e) => handler(e, inlineStyle)}
      className={classes.button}
    >
      <Typography
        variant={'h3'}
        style={{ fontWeight: 300, fontSize: '1.5rem', color: 'white' }}
      >
        {text}
      </Typography>
    </button>
  );
}

const styleMap = {
  TEMPLATE: {
    backgroundColor: mainColors.pineGreen,
    color: 'white',
  },
};

const TemplateEditorTile: FC<EditorTileProps> = (props) => {
  // const data = localStorage.getItem(TEXT_EDITOR_ITEM);
  // const initialState = EditorState.createWithContent(ContentState.createFromText(props.text || ''));
  const {
    setKiidsContent,
    kiidsContent,
    index,
    setChangesMade,
    setTemplateUpdates,
    templateUpdates,
    filtersApplied,
    noFiltersApplied,
    shareClass,
  } = props;

  const text = props.text || '';
  const cleanText = text.replace('{{', '{').replace('}}', '}');
  const templateObject = JSON.parse(cleanText);

  const blocksFromHTML = convertFromHTML(templateObject.text || '');
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks
  );
  // const initialState = EditorState.createWithContent(contentState, compositeDecorator);
  const initialState = EditorState.createWithContent(contentState);
  // const initialState = EditorState.createWithContent(contentState);
  // const [editorState, setEditorState] = React.useState<EditorState>(initialState);
  const [editorState, setEditorState] =
    React.useState<EditorState>(initialState);
  // const [boldIsActive, setBoldIsActive] = React.useState<boolean>(false);
  // const [underlineIsActive, setunderlineIsActive] = React.useState<boolean>(false);
  // const [italicIsActive, setitalicIsActive] = React.useState<boolean>(false);
  // Case for when the field is a template.
  // useEffect(() => {
  //     if (props.text?.startsWith('{{') && props.text.endsWith('}}')) {
  //         console.log("Setting the background color", props.text, blocksFromHTML.contentBlocks.entries())
  //         setEditorState(RichUtils.toggleInlineStyle(editorState, 'TEMPLATE'));
  //     }
  // }, [props.text])

  const handleTextChange = (updatedEditorState: EditorState) => {
    setEditorState(updatedEditorState);
    let htmlText = stateToHTML(updatedEditorState.getCurrentContent());
    const style = updatedEditorState.getCurrentInlineStyle().toArray();
    htmlText = htmlText
      .replace('<p>', '')
      .replace('</p>', '')
      .replace('<strong>', '<b>')
      .replace('</strong>', '</b>')
      .replace('<em>', '<i>')
      .replace('</em>', '</i>');
    const allContent = kiidsContent;
    const currentContentAtIndex = kiidsContent[index];
    // Indicate if a change has been made to enable saving.
    if (currentContentAtIndex.content !== htmlText) {
      setChangesMade(true);
      // check if any changes have already been made.
      // const templateChanges = JSON.parse(JSON.stringify(templateUpdates));
      // setArtists([
      //   ...artists,
      //   { id: nextId++, name: name }
      // ]);
      // const templateChanges: any = {};
      // templateChanges[templateObject.id] = htmlText;
      // console.log("Updating templates after", templateChanges)
      // Set the new template updates.
      // arr => [...arr, `${arr.length}`]
      setTemplateUpdates((templateUpdates: any) => [
        ...templateUpdates,
        { id: templateObject.id, text: htmlText },
      ]);
    }
    // update the template object.
    templateObject.text = htmlText;
    // Convert the template object back to a string so that it can be saved.
    const templateString = '{' + JSON.stringify(templateObject) + '}';
    // Update the content at the specific index.
    currentContentAtIndex.content = templateString;
    // Now replace in the overall array
    allContent[index] = currentContentAtIndex;

    setKiidsContent(allContent);
  };

  const handleKeyCommand = (command: DraftEditorCommand) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const handleTogggleClick = (e: React.MouseEvent, inlineStyle: string) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const handleBlockClick = (e: React.MouseEvent, blockType: string) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };
  return (
    <GridItem
      xs={12}
      card
      style={{
        marginBottom: '1rem',
      }}
    >
      <div
        style={{
          padding: '1rem',
          fontSize: '1.5rem',
          minHeight: '10rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
            }}
          >
            {/* <button onMouseDown={(e) => handleBlockClick(e, "unstyled")}>Normal</button> */}
            <FormatButton
              text={'bold'}
              inlineStyle={'BOLD'}
              handler={handleTogggleClick}
            />
            {/* <FormatButton text={'italic'} inlineStyle={'ITALIC'} handler={handleTogggleClick} /> */}
            <FormatButton
              text={'underline'}
              inlineStyle={'UNDERLINE'}
              handler={handleTogggleClick}
            />
            {/* <FormatButton text={'Toggle Template'} inlineStyle={'TEMPLATE'} handler={handleTogggleClick} /> */}

            {/* <button onMouseDown={(e) => handleTogggleClick(e, "BOLD")}>bold</button>
                <button onMouseDown={(e) => handleTogggleClick(e, "UNDERLINE")}>underline</button>
                <button onMouseDown={(e) => handleTogggleClick(e, "ITALIC")}>italic</button> */}
          </div>
          <CommentEditorButton
            fundId={props.fundId}
            shareClass={props.shareClass}
            commentId={props.commentId}
            kidIndex={props.index}
            hasUnresolvedComments={props.hasUnresolvedComments}
            setKiidsContent={props.setKiidsContent}
            kiidsContent={props.kiidsContent}
          />
        </div>

        <div
          style={{
            border: '0.5px solid',
            minHeight: '8rem',
            marginTop: '0.5rem',
          }}
        >
          <span
            style={{
              background:
                !noFiltersApplied && !filtersApplied[templateObject.type]
                  ? greys.grey700
                  : mapToPastelColor(templateObject.type),
              color:
                !noFiltersApplied && !filtersApplied[templateObject.type]
                  ? 'black'
                  : 'white',
              marginLeft: '0.5rem',
              marginTop: '0.5rem',
              paddingLeft: '2px',
              paddingRight: '2px',
              borderRadius: '5px',
              display: 'flex',
              width: 'fit-content',
            }}
          >
            {/* {props.decoratedText.replace('{{', '').replace('}}', '')} */}
            <Editor
              editorState={editorState}
              onChange={handleTextChange}
              handleKeyCommand={handleKeyCommand}
              readOnly={
                !noFiltersApplied && !filtersApplied[templateObject.type]
              }
              stripPastedStyles={true}
              // customStyleMap={styleMap}
              // blockRendererFn={mediaBlockRenderer}
            />
            {/* <CancelIcon style={{ marginLeft: '0.5rem' }} /> */}
          </span>
        </div>
      </div>
    </GridItem>
  );
};

// This function is used in order to force the component to re-render when the redux state is updated.
function mapStateToProps(state: any, ownProps: EditorTileProps) {
  return {
    filtersApplied: state.kids.kidsFilterState,
    noFiltersApplied: !(
      state.kids.kidsFilterState.share_class ||
      state.kids.kidsFilterState.manco ||
      state.kids.kidsFilterState.fund_manager ||
      state.kids.kidsFilterState.sub_fund
    ),
    shareClass: state.kids.kidsFilterState.share_class, // This prop is not really used but it forces re-renders when more than one filter is applied.
  };
}

export default connect(mapStateToProps)(TemplateEditorTile);
