import { FC } from 'react';
import { EditorTileProps } from './PriipsKidsEditorColumn.component';
import {
  ContentState,
  DraftEditorCommand,
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
} from 'draft-js';
import React from 'react';
import { stateToHTML } from 'draft-js-export-html';
import { PriipsKidsData } from '../UcitsKiidsEditor.component';
import PriipsKidsEditorGridItem from '../../layout/PriipsKidsEditorGridItem.component';
import StyleFormatButtons from '../../buttons/StyleFormatButtons.component';
import UtilityButtons from '../../buttons/UtilityButtons.component';
import makeStyles from '@mui/styles/makeStyles';
import { mainColors } from '../../../../../../styling/theme';
import { Theme, Typography } from '@mui/material';

export type TemplateType = 'manco' | 'sub_fund' | 'fund_manager';

interface StyleProps {
  templateType: TemplateType;
}

export const templateColors: { [key in TemplateType]: string } = {
  manco: mainColors.pastelGreen,
  sub_fund: mainColors.pastelBlue,
  fund_manager: mainColors.pastelYellow,
};

export const templateTypeFullText: { [key in TemplateType]: string } = {
  manco: 'ManCo',
  sub_fund: 'Sub Fund',
  fund_manager: 'Fund Manager',
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  textEditorBox: {
    border: '1px solid',
    // borderColor: mainColors.mainBlue,
    borderColor: 'black',
    marginTop: '1rem',
    backgroundColor: (props: StyleProps) => templateColors[props.templateType],
    // backgroundColor: 'red',
    marginBottom: '2rem',
    marginLeft: '1rem',
    // marginRight: '1rem',
    fontSize: '1.5rem',
    boxSizing: 'border-box',
    padding: '1rem',
    borderRadius: '6px',
    width: '100%',
    minHeight: '10rem',
  },
  editorContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginRight: '1rem',
  },
  formatContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  templateContainer: {
    border: '2px solid',
    borderColor: mainColors.mainBlue,
    backgroundColor: 'white',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '0.5rem',
  },
  templateTypeText: {
    marginTop: '0.5rem',
    borderRadius: '6px',
    backgroundColor: 'white',
    color: mainColors.mainBlue,
    borderColor: mainColors.mainBlue,
    width: '30rem',
    border: '1px solid',
    marginLeft: '1rem',
    boxSizing: 'border-box',
    padding: '0.5rem',
  },
}));

interface TemplateContent {
  text: string;
  id: string;
  type: TemplateType;
}

const TemplateTextEditorTile: FC<EditorTileProps> = ({
  priipsKidsElement,
  setPriipsKidsContent,
  updateFieldType,
  moveField,
  deleteField,
}) => {
  // Store the content converted to an object
  // Template fields are atored as objects converted to strings, with double curly braces
  // So we need to convert the string to an object, replcing the double curly braces with single curly braces
  // const [contentObject, setContentObject] = React.useState<TemplateContent>(JSON.parse(priipsKidsElement.content.replace('{{', '{').replace('}}', '}')) as TemplateContent);
  // Create an editor state object that will store the current text and allow it to be edited
  // Convert the content to html
  const blocksFromHTML = convertFromHTML(priipsKidsElement.content);
  // Now create cintent state object from the html
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks
  );
  // Store the initial state
  const initialState = EditorState.createWithContent(contentState);
  // Create a state object to store the editor state
  const [editorState, setEditorState] =
    React.useState<EditorState>(initialState);
  // Store the index of the current element in the overall data
  const index = priipsKidsElement.kiidIndex;
  // Store the id of the field
  const fieldId = priipsKidsElement.fieldId;

  // Create a function for handling text changes
  const handleTextChange = (updatedEditorState: EditorState) => {
    // Update data
    setEditorState(updatedEditorState);
    // Get The text from the editor state
    let text = stateToHTML(updatedEditorState.getCurrentContent());
    // Format some of the html tags to fit in with the pdf generator expected format
    text = text
      .replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll('<strong>', '<b>')
      .replaceAll('</strong>', '</b>')
      .replaceAll('<em>', '<i>')
      .replaceAll('</em>', '</i>');
    // Create a copy of the content object
    // const newContentObject = { ...contentObject };
    // Update the text
    // newContentObject.text = text;
    // Convert the content object to a string
    // const newContentString = "{" + JSON.stringify(newContentObject) + "}";
    // Update the content object
    // setContentObject(newContentObject);
    // Update the overall data
    setPriipsKidsContent((allContent: PriipsKidsData[]) => {
      const newContent = [...allContent];
      newContent[index].content = text;
      return newContent;
    });
  };

  // Function for han handling keyboard shorthcuts for styling
  const handleKeyCommand = (command: DraftEditorCommand) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  // Create a function for handling key commands
  const handleTogggleClick = (e: React.MouseEvent, inlineStyle: string) => {
    e.preventDefault();
    // Apply the desired inline style to the highlighted text
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    // Get the updated state as plain text
    let text = stateToHTML(editorState.getCurrentContent());
    // Format some of the html tags to fit in with the pdf generator expected format
    text = text
      .replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll('<strong>', '<b>')
      .replaceAll('</strong>', '</b>')
      .replaceAll('<em>', '<i>')
      .replaceAll('</em>', '</i>');
    // Create a copy of the content object
    // const newContentObject = { ...contentObject };
    // // Update the text
    // newContentObject.text = text;
    // // Convert the content object to a string
    // const newContentString = "{" + JSON.stringify(newContentObject) + "}";
    // // Update the content object
    // setContentObject(newContentObject);
    // Update the overall data
    setPriipsKidsContent((allContent: PriipsKidsData[]) => {
      const newContent = [...allContent];
      newContent[index].content = text;
      return newContent;
    });
  };

  const classes = useStyles({
    templateType: priipsKidsElement.templateType as TemplateType,
  });

  return (
    <PriipsKidsEditorGridItem xs={12}>
      <div className={classes.templateContainer}>
        <div className={classes.formatContainer}>
          <StyleFormatButtons handleTogggleClick={handleTogggleClick} />
        </div>
        <div className={classes.templateTypeText}>
          <Typography
            variant="body2"
            // style={{ fontWeight: 600 }}
          >
            Template Type:{' '}
            {
              templateTypeFullText[
                priipsKidsElement.templateType as TemplateType
              ]
            }
          </Typography>
        </div>
        <div className={classes.editorContainer}>
          <div className={classes.textEditorBox}>
            <Editor
              editorState={editorState}
              onChange={(updatedEditorState: EditorState) => {
                handleTextChange(updatedEditorState);
              }}
              stripPastedStyles={true}
              handleKeyCommand={handleKeyCommand}
            />
          </div>
          <UtilityButtons
            moveField={moveField}
            index={index}
            deleteField={deleteField}
            isTemplateField
            fieldId={fieldId}
          />
        </div>
      </div>
    </PriipsKidsEditorGridItem>
  );
};

export default TemplateTextEditorTile;
