import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { greys, mainColors } from '../../styling/theme';
import {
  LinkType,
  SectionType,
  SectionsAndPages,
} from './SectionsAndPages.component';
//  @ts-ignore
import PageNotFound from './pages/PageNotFound.md';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  container: {
    fontSize: '2rem',
    margin: '2rem',
    padding: '2rem',
    borderRadius: '1rem',
    backgroundColor: 'white',
    position: 'relative',
  },
  downloadSection: {
    backgroundColor: greys.grey100,
    borderRadius: '0.4rem',
    padding: '1rem',
  },
  downloadSectionHeading: {
    color: mainColors.mainBlue,
    fontSize: '1.6rem',
    fontWeight: 600,
    marginBottom: '1rem',
  },
  linkButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  linkButton: {
    width: 'fit-content',
    height: '3rem',
    padding: '0.5rem 1rem',
    borderRadius: '0.4rem',
    backgroundColor: mainColors.mainBlue,
    color: 'white',
    fontSize: '1.4rem',
    fontWeight: 600,
    margin: '0.5rem 0',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
  },
  reactMarkDown: {
    // this can be used to style specific elements of the markdown components
    '& table': {
      borderCollapse: 'collapse',
    },
    '& table, & td, & th': {
      border: '1px solid black',
    },
    '& th': {
      backgroundColor: mainColors.mainBlue,
      color: 'white',
    },
    '& td, & th': {
      padding: '0.5rem 5rem 0.5rem 1rem',
    },
    '& a': {
      color: mainColors.mainBlue_lighter,
      fontWeight: 500,
      '&:hover': {
        color: mainColors.Fail,
      },
    },
    '& hr': {
      border: 'none',
      height: '1px',
      margin: '4rem 0',
      backgroundColor: mainColors.mainBlue_lighter,
    },
    '& img': {
      maxWidth: '100%',
      boxShadow: `0.1rem 0.1rem 1rem ${greys.grey100}`,
      borderRadius: '0.4rem',
    },
  },
});

// Find the correct component to return, if any, given sectionId and pageId
// If not matching section or page, return 'PageNotFound' component
const generateDocumentFromKey = (
  SectionsAndPages: SectionType[],
  sectionId: string | undefined,
  pageId: string | undefined
): any => {
  const section = SectionsAndPages.find(
    (section) => section.route === sectionId
  );
  if (section === undefined) {
    return PageNotFound;
  } else {
    if (pageId === undefined) {
      return section.component;
    } else {
      const page = section.pages.find((page) => page.route === pageId);

      if (page === undefined) {
        return PageNotFound;
      } else {
        return page.component;
      }
    }
  }
};

// Find the correct component to return, if any, given sectionId and pageId
// If not matching section or page, return 'PageNotFound' component
const getDownloadsFromKey = (
  SectionsAndPages: SectionType[],
  sectionId: string | undefined,
  pageId: string | undefined
): any => {
  const section = SectionsAndPages.find(
    (section) => section.route === sectionId
  );
  if (section === undefined) {
    return [];
  } else {
    if (pageId === undefined) {
      if (section.links === undefined) {
        return [];
      } else {
        return section.links;
      }
    } else {
      const page = section.pages.find((page) => page.route === pageId);
      if (page === undefined) {
        return [];
      } else {
        if (page.links === undefined) {
          return [];
        } else {
          return page.links;
        }
      }
    }
  }
};

const DocumentationViewer: FC = () => {
  const classes = useStyles();

  const { pathname } = useLocation();
  const [_, __, section, page] = pathname.split('/');

  const [content, setContent] = useState('');

  useEffect(() => {
    fetch(generateDocumentFromKey(SectionsAndPages, section, page))
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, [section, page]);

  return (
    <div className={classes.container}>
      <ReactMarkdown
        children={content}
        remarkPlugins={[remarkGfm, remarkMath]}
        rehypePlugins={[rehypeKatex]}
        className={classes.reactMarkDown}
      />
      <div
        className={
          getDownloadsFromKey(SectionsAndPages, section, page).length
            ? classes.downloadSection
            : undefined
        }
      >
        {getDownloadsFromKey(SectionsAndPages, section, page).length ? (
          <div className={classes.downloadSectionHeading}>Downloads:</div>
        ) : null}
        <div className={classes.linkButtonsContainer}>
          {getDownloadsFromKey(SectionsAndPages, section, page).map(
            (link: LinkType) => (
              <div key={link.name} className={classes.linkButton}>
                <a href={link.url} target="_blank">
                  {link.name}
                </a>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentationViewer;
