import React from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import { EsAlignmentUnderlyingTableData } from '../EsAlignmentMonitor.component';
import { percentageToNdecialPlaces } from '../../../../../utilities/numberFormatters';

interface SecondLevelTableProps {
  data: DataObject;
  rowData: EsAlignmentUnderlyingTableData[];
}

// The standard columns for the table (Full Set)
const standardColumns: CustomColumn<EsAlignmentUnderlyingTableData>[] = [
  {
    title: 'Exposure %',
    field: 'exposure_percentage',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData) =>
      percentageToNdecialPlaces(rowData.exposure_percentage, 2),
    width: '20%',
  },
  {
    title: 'GMID',
    field: 'gmid',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Gross Exposure %',
    field: 'gross_exposure_percentage',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData) =>
      percentageToNdecialPlaces(rowData.gross_exposure_percentage, 2),
    width: '20%',
  },
  {
    title: 'ISIN',
    field: 'isin',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    width: '15%',
  },
  {
    title: 'Position ID',
    field: 'position_id',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Position Name',
    field: 'position_name',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
];

// Build the columns using the columns in the configs
const buildColumns = (
  data: DataObject,
  columns: CustomColumn<EsAlignmentUnderlyingTableData>[]
): CustomColumn<EsAlignmentUnderlyingTableData>[] => {
  if (!data.data[0].configs || !data.data[0].configs.position_columns) {
    return columns;
  } else {
    const returnColumns: CustomColumn<EsAlignmentUnderlyingTableData>[] = [];
    data.data[0].configs.position_columns.forEach((column: string) => {
      columns.forEach((col: CustomColumn<EsAlignmentUnderlyingTableData>) => {
        if (col.field === column) {
          returnColumns.push(col);
        }
      });
    });
    return returnColumns;
  }
};

const SecondLevelTable: React.FC<SecondLevelTableProps> = ({
  data,
  rowData,
}) => {
  return (
    <GridItem xs={12} card>
      <CustomTable<EsAlignmentUnderlyingTableData>
        // The columns are built using only the columns in the configs
        columns={buildColumns(data, standardColumns)}
        showToolbar
        data={rowData}
        title={'Underlying Positions'}
        options={{
          draggable: false,
          sorting: false,
          paging: false,
          search: true,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default SecondLevelTable;
