import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../styling/theme';
import { FC } from 'react';
import { RAIDR_BASE_URL } from '../../../../../utilities/requestClient';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles(() => ({
  conrolContainer: {
    display: 'flex',
  },
  toggleIcon: {
    color: mainColors.mainBlue,
  },
  toggleIconMessage: {
    transform: 'translateY(8rem)',
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
}));

interface DownloadFilesButtonProps {
  fundId?: string;
  fundName?: string;
  mancoId: string;
  tooltipMessage: string;
  documentLanguage: string;
}

const DownloadFileButton: FC<DownloadFilesButtonProps> = (props) => {
  const { fundId, fundName, mancoId, tooltipMessage, documentLanguage } = props;

  // Define a function for downloading files
  function downloadFile() {
    // Create a form object that will be used for posting the request
    const mapForm = document.createElement('form');
    mapForm.target = '_blank';
    mapForm.method = 'POST';
    const params = ['manco_id', 'document_language']; // Add document_language to the params
    // On form submit send the request to the correct endpoint
    mapForm.action =
      RAIDR_BASE_URL +
      `risksystem_pdf_generator/get_all_kid_files/${
        fundName ? fundName + '_documents.zip' : 'all_documents.zip'
      }`;
    // add the fund_id
    const mancoIdInput = document.createElement('input');
    mancoIdInput.type = 'text';
    mancoIdInput.name = 'manco_id';
    mancoIdInput.value = mancoId;
    // Add to the form
    mapForm.appendChild(mancoIdInput);

    // If fund_id is provided then add to the params
    if (fundId) {
      params.push('fund_id');
      const fundIdInput = document.createElement('input');
      fundIdInput.type = 'text';
      fundIdInput.name = 'fund_id';
      fundIdInput.value = fundId;
      // Add to the form
      mapForm.appendChild(fundIdInput);
    }

    // add the document_language
    const documentLanguageInput = document.createElement('input');
    documentLanguageInput.type = 'text';
    documentLanguageInput.name = 'document_language';
    documentLanguageInput.value = documentLanguage;
    // Add to the form
    mapForm.appendChild(documentLanguageInput);

    // add the params
    const paramsInput = document.createElement('input');
    paramsInput.type = 'text';
    paramsInput.name = 'params';
    paramsInput.value = JSON.stringify(params);
    // Add to the form
    mapForm.appendChild(paramsInput);

    // Add the form to the dom
    document.body.appendChild(mapForm);
    // submit the form
    mapForm.submit();
    // remove the form
    document.body.removeChild(mapForm);
  }

  const classes = useStyles();

  return (
    <Tooltip
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      title={tooltipMessage}
      placement={'top'}
      arrow
      enterNextDelay={2000}
      enterDelay={2000}
    >
      <Button
        variant="contained"
        sx={{
          fontSize: '1.5rem',
          width: '18rem',
          height: '3rem',
          color: 'white',
          backgroundColor: mainColors.mainBlue,
          borderRadius: '0.2rem',
          padding: '0.2rem',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: mainColors.mainBlue_lighter,
          },
          fontWeight: 600,
          textTransform: 'none',
        }}
        disableElevation={true}
        onClick={downloadFile}
        endIcon={
          <DownloadForOfflineIcon
            sx={{
              padding: '0.2rem',
              fontSize: '3rem',
              width: '2.5rem',
              height: '2.5rem',
            }}
          />
        }
      >
        Download Files
      </Button>
    </Tooltip>
  );
};

export default DownloadFileButton;
