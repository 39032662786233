import { CisData, generateCisColumns, generateCisTableData } from '../cis.data';
import CustomTable from '../../../../../tables/CustomTable';
import DisplayAreaCenteredWrapper from '../../../../../layout/utilities/displayAreaWrapper';
import { DataObject } from '../../../../../../types/redux/data/dataTypes';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import { useTheme } from '@mui/material';
export type ExposureType = 'netExposureBaseCurrency' | 'netExposurePcNav';
export interface CisComponentProps {
  inputData: DataObject;
  selectedExposureType: ExposureType;
}
const CisTable = (props: CisComponentProps) => {
  const dataForUse = generateCisTableData(
    props.inputData.data,
    props.selectedExposureType
  );
  const columns = generateCisColumns(props.selectedExposureType);

  const theme = useTheme();
  return !props.inputData.isFetching ? (
    dataForUse.length ? (
      <GridItem xs={12} card>
        <CustomTable<CisData>
          id="assetClassCisTable"
          title="CIS"
          options={{
            pageSize: 50,
            pageSizeOptions: [50, 100, 200, 500],
            emptyRowsWhenPaging: false,
            paging: true,
            exportButton: true,
            search: true,
          }}
          csvFields={columns.map(
            (col: CustomColumn<CisData>) => col.field as string
          )}
          data={dataForUse}
          columns={columns}
          showToolbar
        />
      </GridItem>
    ) : (
      <DisplayAreaCenteredWrapper>
        <h1 style={{ color: theme.palette.primary.main }}>
          No relevant records to display
        </h1>
      </DisplayAreaCenteredWrapper>
    )
  ) : null;
};

export default CisTable;
