import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { RaptorTheme, greys, mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  roundNumberToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import { specifyStatus } from '../../../../utilities/specfyStatus';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { PEPageLinkButton } from '../../privateEquity/peOverview/PeOverview.component';
import { Typography } from '@mui/material';

interface ArticleOverviewPanelProps {
  data: any;
  fundName: string;
}

const useArticelOverviewPanelStyles = makeStyles<RaptorTheme>((theme) => ({
  componentsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 8,
  },
  chartContainer: {
    width: '60%',
    // display: 'flex',
    // justifyContent: 'space-between',
  },
  tableContainer: {
    width: '40%',
    justifyContent: 'center',
  },
  basicInfoContainer: {
    width: '40%',
    justifyContent: 'center',
  },
  fundInfoLine: {
    display: 'flex',
  },
  individualChart: {
    marginBottom: '1rem',
    height: '20rem',
    width: '20rem',
    justifyContent: 'center',
  },
}));

interface CoverageData {
  coverage: string;
  exposurePositions: string;
}

function buildCoverageData(data: any): CoverageData {
  const coverage: CoverageData = {
    coverage: '',
    exposurePositions: '',
  };
  if (!data.data.length) return coverage;
  if (!('sfdr_page' in data.data[0])) return coverage;
  const currentData = data.data[0].sfdr_page.overview_information.coverage;
  // For Now use regex to get the coverage data
  coverage.coverage = currentData.number_positions;
  // Format the exposure value
  const pattern = /\d+\.\d+/g;
  coverage.exposurePositions = `${roundNumberToTwoDecimalPlaces(
    parseFloat(currentData.exposure_positions.match(pattern)) * 100
  )}%`;
  // coverage.coverage = `${roundNumberToTwoDecimalPlaces(parseFloat(currentData.exposure_positions.match(pattern)))}%`
  // coverage.exposurePositions = currentData.exposure_positions;
  return coverage;
}

interface pieChartData {
  name: string;
  value: number;
}

function buildPieChartData(data: any): pieChartData[] {
  if (!data.data.length) return [];
  if (!('sfdr_page' in data.data[0])) return [];
  const currentData = data.data[0].sfdr_page.overview_information.coverage;

  // For Now use regex to get the coverage data
  const coverageData = currentData.number_positions.match(/\d+/g);
  if (!coverageData.length) return [];
  const chartData: pieChartData[] = [
    {
      name: 'Covered Positions',
      value: parseInt(coverageData[0]),
    },
    {
      name: 'Uncovered Positions',
      value: parseInt(coverageData[1]) - parseInt(coverageData[0]),
    },
  ];
  return chartData;
}

interface IArticleOverviewData {
  reportedArticleLevel: string;
  observedArticleLevel: string;
  status: string;
}

const detailColumns: CustomColumn<IArticleOverviewData>[] = [
  {
    title: 'Stated Article Level',
    field: 'reportedArticleLevel',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Observed Article Level',
    field: 'observedArticleLevel',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Status',
    field: 'status',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: IArticleOverviewData) => (
      <PEPageLinkButton status={specifyStatus(rowData.status)} />
    ),
  },
];
const gemini802 = 'Third Avenue Real Estate Value Fund';
function buildTableData(data: any, fundName: string): IArticleOverviewData[] {
  if (!data.data.length) return [];
  if (!('sfdr_page' in data.data[0])) return [];
  const articleData = data.data[0].sfdr_page.overview_information;
  const pattern = /\d+\.\d+/g;
  const tableData: IArticleOverviewData[] = [
    {
      reportedArticleLevel: articleData.reported_level.replace('_', 'rticle '),
      observedArticleLevel: articleData.observed_level.replace('_', 'rticle '),
      // status: articleData.status
      status: articleData.status,
    },
  ];
  return tableData;
}

const ArticleOverviewPanel: FC<ArticleOverviewPanelProps> = (props) => {
  const { data, fundName } = props;
  const classes = useArticelOverviewPanelStyles();
  const { height } = useWindowSize();
  const coverageData = buildCoverageData(data);
  const chartData = buildPieChartData(data);

  const tableData = buildTableData(data, fundName);
  // const timSeriesData = buildOverviewTimeseriesData(data);
  return (
    <>
      <GridItem card xs={6} cardStyle={{ height: '100%', minHeight: '40rem' }}>
        <div className={classes.componentsContainer}>
          <div className={classes.basicInfoContainer}>
            <Typography variant="h2" align="left">
              Fund Information
            </Typography>
            <div className={classes.fundInfoLine}>
              <Typography variant="h3">Fund Name: </Typography>
              <Typography
                variant="h3"
                style={{ color: greys.grey600, marginLeft: '1rem' }}
              >
                {fundName}
              </Typography>
            </div>
            <div className={classes.fundInfoLine}>
              <Typography variant="h3">NAV: </Typography>
              <Typography
                variant="h3"
                style={{ color: greys.grey600, marginLeft: '1rem' }}
              >
                {addCommasToNumbersAndRound(data.data[0]['nav'])}
              </Typography>
            </div>
            <Typography variant="h2" align="left" style={{ marginTop: '2rem' }}>
              Coverage
            </Typography>
            <div className={classes.fundInfoLine}>
              <Typography
                variant="h3"
                style={{ color: mainColors.mainBlue }}
              >{`${coverageData.coverage} Positions`}</Typography>
            </div>
            <div className={classes.fundInfoLine}>
              <Typography
                variant="h3"
                style={{ color: mainColors.mainBlue }}
              >{`${coverageData.exposurePositions} of Exposure`}</Typography>
            </div>
          </div>
          <div className={classes.chartContainer}>
            <Typography variant="h2" align="left" style={{ marginLeft: '30%' }}>
              Portfolio Coverage
            </Typography>
            <ResponsiveContainer width={'100%'} height={height / 3}>
              <PieChart>
                <Pie
                  data={chartData}
                  labelLine={false}
                  isAnimationActive={false}
                  paddingAngle={1}
                  legendType="rect"
                  dataKey="value"
                  nameKey="name"
                  label={(dataPoint) => `${dataPoint.name}: ${dataPoint.value}`}
                >
                  <Cell fill={mainColors.mainBlue} />
                  <Cell fill={greys.grey400} />
                  {/* {
                                    colours ? (

                                        props.data.map((entry: any, index: number) => <Cell fill={colours[index % colours.length]} />)
                                    ) : null} */}
                  {/* <LabelList dataKey="name" position="outside" /> */}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </GridItem>
      <GridItem card xs={6} cardStyle={{ height: '100%', minHeight: '40rem' }}>
        <CustomTable<IArticleOverviewData>
          columns={detailColumns}
          showToolbar
          data={tableData}
          title={'Article Scanner Results'}
          options={{
            paging: false,
            search: false,
            exportButton: false,
          }}

          // detailPanel={esgSecondLevelTableWrapper()}
          // // options={{
          // //   headerStyle: {
          // //     color: 'white',
          // //     textAlign: 'left',
          // //     backgroundColor: theme.palette.grey[100],
          // //   },

          //   paging: false,
          //   exportButton: false,
          // }}
        />
      </GridItem>
    </>
  );
};

export default ArticleOverviewPanel;
