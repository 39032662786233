import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import {
  percentageToTwoDecimalPlaces,
  percentageToTwoDecimalPlacesNoHundred,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { Typography } from '@mui/material';

interface subscriptionsRedemptionsTableProps {
  data: any;
  title: string;
}

interface IsubscriptionsRedemptionsData {
  metric: string;
  scenario: string;
  value: number;
  M1: number;
  coverageRatio: number;
}

function buildSubscriptionsRedemptionData(
  data: any,
  title: string
): IsubscriptionsRedemptionsData[] {
  const tableData: IsubscriptionsRedemptionsData[] = [];
  const subsRedsData = data.data[0];
  if (title === 'Redemption Risk') {
    for (
      let index = 0;
      index < subsRedsData.statistical_redemption_data.length;
      index++
    ) {
      const coverageRatio =
        subsRedsData.statistical_redemption_data[index] === 0
          ? 0
          : subsRedsData.moab_data2.top_level_data.all_currency[1] /
            (subsRedsData.statistical_redemption_data[index] * 100);
      tableData.push({
        metric: subsRedsData.statistical_metric[index],
        scenario: subsRedsData.statistical_scenario[index],
        value: subsRedsData.statistical_redemption_data[index],
        M1: subsRedsData.moab_data2.top_level_data.all_currency[1],
        coverageRatio: Math.abs(coverageRatio),
      });
    }
  } else if (title === 'Gross Activity Risk') {
    for (
      let index = 0;
      index < subsRedsData.statistical_gross_data.length;
      index++
    ) {
      const coverageRatio =
        subsRedsData.statistical_gross_data[index] === 0
          ? 0
          : subsRedsData.moab_data2.top_level_data.all_currency[1] /
            (subsRedsData.statistical_gross_data[index] * 100);

      tableData.push({
        metric: subsRedsData.statistical_metric[index],
        scenario: subsRedsData.statistical_scenario[index],
        value: subsRedsData.statistical_gross_data[index],
        M1: subsRedsData.moab_data2.top_level_data.all_currency[1],
        coverageRatio: Math.abs(coverageRatio),
      });
    }
  }

  return tableData;
}

function buildTableColumns(
  clientName: string
): CustomColumn<IsubscriptionsRedemptionsData>[] {
  const detailColumns: CustomColumn<IsubscriptionsRedemptionsData>[] = [
    {
      title: 'Metric',
      field: 'metric',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Scenario',
      field: 'scenario',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Value',
      field: 'value',
      render: (rowData) => percentageToTwoDecimalPlaces(rowData.value),
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    clientName !== 'gem'
      ? {
          title: 'M1',
          field: 'M1',
          render: (rowData) =>
            percentageToTwoDecimalPlacesNoHundred(rowData.M1),
          cellStyle: { textAlign: 'center' },
          headerStyle: { textAlign: 'center' },
        }
      : {
          width: '0%',
        },
    clientName !== 'gem'
      ? {
          title: 'Coverage Ratio',
          field: 'coverageRatio',
          render: (rowData) =>
            percentageToTwoDecimalPlacesNoHundred(rowData.coverageRatio),
          cellStyle: { textAlign: 'center' },
          headerStyle: { textAlign: 'center' },
        }
      : {
          width: '0%',
        },
  ];
  return detailColumns;
}

const SubscriptionsRedemptionsTable: FC<subscriptionsRedemptionsTableProps> = (
  props
) => {
  const { data, title } = props;

  const clientName = useSelector(clientNameSelector) || 'mersenne';
  const tableData = buildSubscriptionsRedemptionData(data, title);
  const { height } = useWindowSize();
  const detailColumns = buildTableColumns(clientName);

  return (
    <GridItem
      xs={6}
      card
      style={{ padding: 8 }}
      cardStyle={{ minHeight: height / 2 - 90 }}
    >
      <Typography
        variant="h2"
        style={{
          marginLeft: '2rem',
          marginTop: '1rem',
          fontSize: '2rem',
          fontWeight: 400,
        }}
      >
        {title}
      </Typography>
      <CustomTable<IsubscriptionsRedemptionsData>
        pdfTitle={title}
        id={
          title === 'Redemption Risk'
            ? 'redemption_table'
            : 'subscription_table'
        }
        columns={detailColumns}
        showToolbar
        data={tableData}
        options={{
          paging: false,
          search: false,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default SubscriptionsRedemptionsTable;
