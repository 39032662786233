import React, { ReactElement } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';

interface restricedCompaniesTableProps {
  restrictedCompanies: any;
  selectedOption: string | null;
}

interface IRestricedCompanies {
  name: string;
  aliasNames: string;
  listingStartDate: string;
  comments: string;
}

function buildRestrictedCompaniesTableData(restrictedCompanies: any) {
  if (!restrictedCompanies) return [];
  if (!restrictedCompanies.data.length) return [];

  const optionData =
    typeof restrictedCompanies.data[0] === 'object'
      ? Object.values(restrictedCompanies.data[0])
      : restrictedCompanies.data[0];

  const companiesData: IRestricedCompanies[] = [];
  // Create data for the table containing required columnns.
  optionData.forEach((value: any) => {
    companiesData.push({
      name: value.first_name,
      aliasNames: tryParseJson(value.alias_names),
      listingStartDate: value.listing_start_date,
      comments: value.comments,
    });
  });
  return companiesData;
}

function tryParseJson(value: any) {
  // Function to parse a json string (if the string is a json object)
  // If not then just return the object as is.
  let returnValue = null;
  try {
    returnValue = decodeURIComponent(JSON.parse(value));
  } catch (e) {
    returnValue = value;
  }
  return returnValue;
}

const detailColumns: CustomColumn<IRestricedCompanies>[] = [
  {
    title: 'Name',
    field: 'name',
    cellStyle: {
      textAlign: 'center',
    },
    width: '20%',
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Alias Names',
    field: 'aliasNames',
    cellStyle: {
      textAlign: 'center',
    },
    width: '10%',
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Comments',
    field: 'comments',
    cellStyle: {
      textAlign: 'left',
    },
    width: '60%',
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Listing Start Date',
    field: 'listingStartDate',
    cellStyle: {
      textAlign: 'center',
    },
    width: '10%',
    headerStyle: { textAlign: 'center' },
    defaultSort: 'desc',
    render: (rowData: IRestricedCompanies) =>
      rowData.listingStartDate.substring(0, 10),
  },
];

function RestrictedCompaniesTable(
  props: restricedCompaniesTableProps
): ReactElement {
  const { restrictedCompanies, selectedOption } = props;

  const tableData = buildRestrictedCompaniesTableData(restrictedCompanies);

  return (
    <GridItem xs={12} card>
      <CustomTable<IRestricedCompanies>
        columns={detailColumns}
        showToolbar
        data={tableData}
        title={'Restricted Companies'}
        options={{
          paging: tableData.length > 50,
          pageSize: 50,
          pageSizeOptions: [50, 100, 200, 500, 1000],
          search: true,
          exportButton: true,
          emptyRowsWhenPaging: false,
        }}
        additionalDetailForPdf={`${selectedOption} Restriction List`}
      />
    </GridItem>
  );
}

export default RestrictedCompaniesTable;
