import makeStyles from '@mui/styles/makeStyles';
import { Dispatch, FC } from 'react';
import FilterForTemplateFieldsSelect from '../buttons/FilterForTemplateFieldsSelect.comonent';
import { Typography } from '@mui/material';

interface ControlBarOptionsProps {
  fundName: string;
  setPriipsKidsContent: Dispatch<any>;
  fundId: string;
  shareClass: string;
  setForceRerender: Dispatch<boolean>;
  setTemplateFilter: Dispatch<string>;
}

const useStyles = makeStyles(() => ({
  optionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: '4rem',
    // width: '100%',
    // paddingLeft: '1rem',
    // gap: '1rem',
  },
  fundNameContainer: {
    width: '300%',
    overflow: 'hidden',
    maxHeight: '3rem',
    marginRight: '1rem',
  },
  additionalButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '1rem',
    gap: '1rem',
    // marginLeft: '10rem',
  },
}));

const ControlBarOptions: FC<ControlBarOptionsProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.optionsContainer}>
      <div className={classes.fundNameContainer}>
        <Typography variant="h6">{props.fundName}</Typography>
      </div>
      <div className={classes.additionalButtonsContainer}>
        <FilterForTemplateFieldsSelect
          setTemplateFilter={props.setTemplateFilter}
          setForceRerender={props.setForceRerender}
        />
      </div>
    </div>
  );
};

export default ControlBarOptions;
