import { Options } from 'rs-material-table';
import React, { ReactElement } from 'react';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  hexToRGBA,
  mapStatusToColor,
} from '../../../../../utilities/colorUtilities';
import { percentageToTwoDecimalPlaces } from '../../../../../utilities/numberFormatters';
import CustomTable from '../../../../tables/CustomTable';
import { Status } from '../../../../../types/redux/data/dataTypes';
import PageLinkButton from './PageLinkButton';
import { statusSort } from '../../overview/data';

interface SecondLevelTableProps {
  data: Array<any>;
}

interface SecondLevelTableData {
  fundName: string;
  shareClassIsin: string;
  shareClassGmid: number;
  shareClassName: string;
  shareClassCurrency: string;
  publishedSrri: number;
  calculatedSrri: number;
  calculatedVolatility: number;
  calculationDate: string;
  status: Status;
  fundType: string;
  weekOfDeviation: string;
  srriDate: string;
}

function buildSecondLevelTableData(data: any): SecondLevelTableData[] {
  // Create an array for storing the data
  const tableData: SecondLevelTableData[] = [];
  data.forEach((value: any) => {
    const row = {
      fundName: value[0],
      shareClassIsin: value[8],
      shareClassGmid: value[5],
      shareClassName: value[6],
      shareClassCurrency: value[7],
      publishedSrri: value[10],
      calculatedSrri: value[11],
      calculatedVolatility: value[12],
      calculationDate: value[14],
      status: value[10] === value[11] ? Status.Pass : Status.Fail,
      fundType: value[4],
      weekOfDeviation: value[15],
      srriDate: value[13],
    };
    tableData.push(row);
  });
  return tableData;
}

export const secondaryColumns: CustomColumn<SecondLevelTableData>[] = [
  {
    title: 'ISIN',
    field: 'shareClassIsin',
    width: '20%',
    headerStyle: {
      textAlign: 'center',
      padding: 10,
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Name',
    field: 'shareClassName',
    width: '20%',
    headerStyle: {
      textAlign: 'center',
      padding: 10,
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Currency',
    field: 'shareClassCurrency',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Volatility',
    field: 'calculatedVolatility',
    render: (rawData: SecondLevelTableData) => (
      <span>{percentageToTwoDecimalPlaces(rawData.calculatedVolatility)}</span>
    ),
    // you still need to add in the correct PDF render type here
    renderMethod: {
      methodName: 'percentageToTwoDecimalPlaces',
      params: ['calculatedVolatility'],
    },
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    customSort: (data1, data2) =>
      data2.calculatedVolatility - data1.calculatedVolatility,
  },
  {
    title: 'Published SRRI',
    field: 'publishedSrri',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Calculated SRRI',
    field: 'calculatedSrri',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Calculation Date',
    field: 'calculationDate',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Fund Type',
    field: 'fundType',
    // width: '50%',
    // cellStyle: {
    //     textAlign: 'left',
    // },
    // headerStyle: {
    //     textAlign: 'left',
    //     padding: 10,
    // },
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Week of Deviation',
    field: 'weekOfDeviation',
    // width: '50%',
    // cellStyle: {
    //     textAlign: 'left',
    // },
    // headerStyle: {
    //     textAlign: 'left',
    //     padding: 10,
    // },
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'SRRI Date',
    field: 'srriDate',
    // width: '50%',
    // cellStyle: {
    //     textAlign: 'left',
    // },
    // headerStyle: {
    //     textAlign: 'left',
    //     padding: 10,
    // },
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Status',
    field: 'status',
    pdfRenderType: 'PageLinkButton',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    width: '10%',
    pdfWidth: 10,
    render: (rowData: SecondLevelTableData) => (
      <PageLinkButton
        status={rowData.status}
        fundName={rowData.fundName}
        text={rowData.status}
        page={'srri-share-class'}
        section={'ucits'}
        shareClass={rowData.shareClassGmid}
      />
    ),
    customSort: (a, b) => statusSort(a, b, 'status'),
  },
];

export const secondLevelOptions: Options = {
  paging: false,
  showTitle: false,
  exportButton: false,
  search: false,
  toolbar: true,
  rowStyle: (rowData: SecondLevelTableData) => ({
    backgroundColor: hexToRGBA(mapStatusToColor(rowData.status), 0.3),
    fontWeight: 'normal',
  }),
};

function SecondLevelTable({ data }: SecondLevelTableProps): ReactElement {
  const tableData = buildSecondLevelTableData(data);
  // const columns = secondaryColumns
  return (
    <CustomTable<SecondLevelTableData>
      columns={secondaryColumns.map(
        (col: CustomColumn<SecondLevelTableData>) => ({
          ...col,
          cellStyle: { fontWeight: 'inherit', ...col.cellStyle },
        })
      )}
      title="Share Classes"
      id="share_class_detail"
      data={tableData}
      options={secondLevelOptions}
      showToolbar={false}
    />
  );
}

export default SecondLevelTable;
