import { FC } from 'react';
import IndividualPie from './IndividualPie.component';
import makeStyles from '@mui/styles/makeStyles';

interface PieContainerProps {
  data: any;
  delayIndex: number;
  sectionId: string;
  sectionTitle: string;
}

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
}));

const mapPieToRoute = (section: string, pie: string): string | null => {
  // any special cases go here (hopefully over time we can remove these)
  if (section.toUpperCase() === pie) {
    return `/${section}/${section}-law`;
  } else if (section == 'pe' && pie == 'Commitment') {
    return `/${section}/pe-exposure`;
  }
  // for bflexion / aif weirdness
  if (section === 'aifmd' && pie === 'Value at Risk') {
    return `/${section}/exposure`;
  }

  // else standard page selections
  switch (pie) {
    case 'Commitment':
      return `/${section}/exposure`;
    case 'Value at Risk':
      return `/${section}/value-at-risk`;
    case 'Prospectus':
      return `/${section}/prospectus-restrictions`;
    case 'CSSF Risk Spreading':
      return `/${section}/cssf-risk-spreading`;
    case 'Leverage':
      return `/${section}/exposure`;
    default:
      return null;
  }
};

const PieContainer: FC<PieContainerProps> = ({
  data,
  delayIndex,
  sectionId,
  sectionTitle,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {Object.keys(data).map((item: any, index: number) => {
        const pieRoute = mapPieToRoute(sectionId, item);

        // Sum the data values to ensure that they are greater than 0, if not then do not create chart
        const dataSum = data[item]
          .slice(1)
          .reduce(
            (acucumulator: number, currentValue: Array<string | number>) =>
              acucumulator + Number(currentValue[1]),
            0
          );

        return dataSum > 0 ? (
          <IndividualPie
            pieRoute={pieRoute}
            animBegin={(index + 1) * 300 + 1200 * delayIndex}
            key={index}
            dataTitle={item}
            data={data[item]}
          />
        ) : null;
      })}
    </div>
  );
};

export default PieContainer;
