import React, { FC } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { percentageToTwoDecimalPlaces } from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';

interface MonteCarloTableProps {
  data: any;
  position: string | null;
}

interface MonteCarloTableData {
  name: string;
  value: number;
}

function buildMonteCarloTableData(
  data: any,
  position: string | null
): MonteCarloTableData[] {
  if (!data.data.length) return [];
  if (!position) return [];
  if (!(position in data.data[0])) return [];
  const valueTableData = data.data[0][position].value_table;
  const tableData: MonteCarloTableData[] = [];
  for (const key in valueTableData) {
    if (key !== 'Correlation Matrix') {
      tableData.push({
        name: key,
        value: valueTableData[key],
      });
    }
  }
  return tableData;
}

const columns: CustomColumn<MonteCarloTableData>[] = [
  {
    title: 'Metric',
    field: 'name',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Value',
    field: 'value',

    cellStyle: {
      textAlign: 'center',
      // borderRight: theme.borders!.light,
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: MonteCarloTableData) =>
      percentageToTwoDecimalPlaces(rowData.value),
  },
];

const MonteCarloTable: FC<MonteCarloTableProps> = (props) => {
  const { data, position } = props;
  const tableData = buildMonteCarloTableData(data, position);

  return (
    <GridItem
      card
      xs={4}
      cardStyle={{ minHeight: 560 }}
      style={{
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: 580,
      }}
    >
      <CustomTable<MonteCarloTableData>
        id={'monte_carlo_table'}
        title={'Monte Carlo Metrics'}
        showToolbar={true}
        loading={data.isFetching}
        columns={columns}
        data={tableData}
        options={{
          paging: false,
          search: false,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default MonteCarloTable;
