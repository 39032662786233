// This function is used to replace the placeholders in the strings with the data provided.
// Original ideae goes to tjcafferkey (https://github.com/tjcafferkey/stringinject)
// I have heavily modified it to suit my needs, and make it typescript compatible.

// Example:
// stringInject("Hello {location}, my name is {name}", { location: "World", name: "Tom" }) => "Hello World, my name is Tom"

export default function stringInject(
  str: string,
  data: { [index: string]: any }
) {
  const pattern = /\{(.+?)\}/g;
  let matches = pattern.exec(str);

  let result = str;
  while (matches !== null) {
    const key = matches[1];
    const value = data[key];
    if (value !== undefined) {
      result = result.replace(matches[0], value);
    }
    matches = pattern.exec(str);
  }

  return result;
}
