import makeStyles from '@mui/styles/makeStyles';
import Icon from '@mui/material/Icon';
import { Dispatch, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { greys, mainColors } from '../../../../../styling/theme';
import { Tooltip } from '@mui/material';

interface styleProps {
  isHovered: boolean;
  setIsHovered: Dispatch<boolean>;
  dontSetPosition?: boolean;
}

const useStyles = makeStyles<styleProps>((props) => ({
  conrolContainer: {
    display: 'flex',
  },
  toggleIcon: {
    color: props.isHovered ? mainColors.mainBlue : greys.grey700,
  },
  toggleIconMessage: {
    transform: props.isHovered ? 'translateY(8rem)' : 'translateY(-8rem)',
  },
  arrow: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: mainColors.controlButtonBlue,
  },
  tooltip: {
    backgroundColor: mainColors.controlButtonBlue,
    border: '1px solid #E6E8ED',
    color: 'white',
  },
}));

interface backToOverviewButtonProps {
  backToOverview: () => void;
  dontSetPosition?: boolean;
}

const BackToOverviewButton: FC<backToOverviewButtonProps> = (props) => {
  const { backToOverview, dontSetPosition } = props;

  const dispatch = useDispatch();
  // const controlBarIsShowing = useSelector(isControlBarShowingSelector);
  const [isHovered, setIsHovered] = useState(false);
  const classes = useStyles({
    isHovered: isHovered,
    setIsHovered: setIsHovered,
    dontSetPosition: dontSetPosition,
  });

  return (
    <div
      className={classes.conrolContainer}
      style={{ position: dontSetPosition ? 'static' : 'fixed' }}
    >
      <Tooltip
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        title="Back To Overview"
        placement={dontSetPosition ? 'top' : 'right'}
        arrow
        enterNextDelay={1000}
        enterDelay={1000}
      >
        <Icon
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          sx={{
            fontSize: 30,
            // color: isHovered ? mainColors.controlButtonBlue : greys.grey900,
            color: mainColors.controlButtonBlue,
            opacity: isHovered ? 1 : dontSetPosition ? 0.8 : 0.6,
            cursor: isHovered ? 'pointer' : null,
          }}
          onClick={() => backToOverview()}
        >
          arrow_circle_left
        </Icon>
      </Tooltip>
    </div>
  );
};

export default BackToOverviewButton;
