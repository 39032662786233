import { FC, useEffect, useState } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import FundBreakdownContainer from './FundBrekdownContainer/FundBreakdownContainer.component';
import MancoSummaryContainer from './MancoSummaryContainer/MancoSummaryContainer.component';
import { Grid } from '@mui/material';
import client from '../../../../utilities/requestClient';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import axios from 'axios';

const ReportDeliveryOversight: FC<GeneralOverviewPageRenderedComponentProps> = (
  props
) => {
  const [filterState, setFilterState] = useState<string>('');
  // Store today's date.
  const today = new Date();
  const [weekStartDate, setWeekStartDate] = useState(new Date());

  // This mimics the data object returned from useFetchData hook
  const [fundStatusData, setFundStatusData] = useState<DataObject>({
    error: null,
    isFetching: true,
    data: [],
  });

  // /kpi_data/<start_date>/<end_date>/
  const url =
    formatDateForCheckingState(weekStartDate) ===
    formatDateForCheckingState(today)
      ? 'getMancoReportDeliveryStatus'
      : `getMancoReportDeliveryStatus/${formatDateForCheckingState(
          weekStartDate
        )}`;
  // const url = weekStartDate === today ? 'getMancoReportDeliveryStatus' : `getMancoReportDeliveryStatus/${formatDateForCheckingState(weekStartDate)}`;

  useEffect(() => {
    async function refetchFunction() {
      try {
        const cancelToken = axios.CancelToken;
        // TODO: Account for all status codes as cookies/token not validated as in useFetchData hook
        const response = await client().get(url, {
          cancelToken: cancelToken.source().token,
        });
        if (response.status === 200) {
          // On status 200 set data in this shape
          setFundStatusData({
            error: null,
            isFetching: false,
            data: [response.data],
          });
        }
      } catch (error) {
        if (error instanceof Error) {
          // If thrown error is an instance of Error, set error in this shape
          // Set data to previous data if exists so not to break the UI
          setFundStatusData((prev) => {
            return { error: null, isFetching: false, data: prev?.data || [] };
          });
        } else {
          // If thrown error is unknown, set error in this shape
          // Set data to previous data if exists so not to break the UI

          setFundStatusData((prev) => {
            return { error: null, isFetching: false, data: prev?.data || [] };
          });
        }
      }
    }
    const interval = setInterval(() => {
      console.log('Refetching data...');
      refetchFunction();
    }, 10000); // 10 seconds - logged and to be increased to Toms specification

    return () => clearInterval(interval);
  }, []);

  // const downloadHandler = () => {
  //   downloadPdfDocument("grid", 297, 210, 'Historical Data');
  // };
  return (
    <GeneralComponentErrorShield
      dataObjects={[fundStatusData]}
      customLoadingMessages={[
        'Loading Oversight Data...',
        'Just a moment...',
        '...',
      ]}
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        <MancoSummaryContainer data={fundStatusData} />
        <FundBreakdownContainer
          data={fundStatusData}
          filterState={filterState}
          setFilterState={setFilterState}
          weekStartDate={weekStartDate}
          setWeekStartDate={setWeekStartDate}
        />
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default ReportDeliveryOversight;
