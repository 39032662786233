import { FC } from 'react';
import { greys } from '../../../../styling/theme';
import { percentageToTwoDecimalPlaces } from '../../../../utilities/numberFormatters';
import GenericLineChart from '../../../charts/GenericLineChart';
import GridItem from '../../../layout/GridComponents/GridItem';
import { Typography } from '@mui/material';

interface MonteCarloTimeSeriesChartProps {
  data: any;
  selectedPosition: string | null;
}

interface MonteCarloTimeSeriesChartData {
  name: number;
  mainValue: number;
  extraValue0?: number;
  extraValue1?: number;
  extraValue2?: number;
  extraValue3?: number;
  extraValue4?: number;
  extraValue5?: number;
  extraValue6?: number;
  extraValue7?: number;
  extraValue8?: number;
  extraValue9?: number;
  extraValue10?: number;
  extraValue11?: number;
  extraValue12?: number;
  extraValue13?: number;
  extraValue14?: number;
  extraValue15?: number;
  extraValue16?: number;
  extraValue17?: number;
  extraValue18?: number;
  extraValue19?: number;
}

function buildMonteCarloTimeSeriesChartData(
  data: any,
  selectedPosition: string | null
) {
  if (!data.data.length) return [];
  if (!selectedPosition) return [];
  if (!(selectedPosition in data.data[0])) return [];
  const positionDataMain = data.data[0][selectedPosition].ebit_mc[1];
  const positionDataOther = data.data[0][selectedPosition].ebit_mc[0];
  const chartData: any[] = [];
  // Build the data from the list of lists.
  for (let i = 0; i < 10; i++) {
    const currentYearData: any = {
      name: i,
    };
    // Add the main data and 0 values for others
    if (positionDataMain.length > i) {
      currentYearData['mainValue'] = positionDataMain[i];
    }
    for (let j = 0; j < positionDataOther.length; j++) {
      if (positionDataOther[j].length > i) {
        currentYearData[`extraValue${j}`] = positionDataOther[j][i];
      }
    }
    chartData.push(currentYearData);
  }
  return chartData;
}

const MonteCarloTimeSeriesChart: FC<MonteCarloTimeSeriesChartProps> = (
  props
) => {
  const { data, selectedPosition } = props;
  const lineChartData = buildMonteCarloTimeSeriesChartData(
    data,
    selectedPosition
  );

  return (
    <GridItem xs={8} card style={{ padding: 8 }} cardStyle={{ minHeight: 450 }}>
      <Typography
        variant="h2"
        style={{ marginLeft: '2rem', marginTop: '1rem', textAlign: 'center' }}
      >
        {'Monte Carlo Simulations'}
      </Typography>
      <GenericLineChart
        title={'Monte Carlo Simulations'}
        data={lineChartData}
        id={'monte_carlo_line_chart'}
        xAxisDataKey="name"
        yAxisTickFormatter={(val: number) => percentageToTwoDecimalPlaces(val)}
        showTooltip={false}
        lines={[
          {
            dataKey: 'extraValue0',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue1',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue2',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue3',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue4',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue5',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue6',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue7',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue8',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue9',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue10',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue11',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue12',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue13',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue14',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue15',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue16',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue17',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue18',
            color: greys.grey300,
          },
          {
            dataKey: 'extraValue19',
            color: greys.grey300,
          },
          {
            dataKey: 'mainValue',
            color: greys.grey300,
            // strokeWidth: 3,
          },
        ]}
        width={'100%'}
        height={400}
        // showDot={false}
        margin={{
          top: 10,
          bottom: 10,
          left: 100,
          right: 40,
        }}
        error={'No Simulation Data Available'}
        monotone
        xAxisLabel={{ value: 'Year', dy: 10 }}
        yAxisLabel={{ value: 'P/L (% of NAV)', dx: -70 }}
      />
    </GridItem>
  );
};

export default MonteCarloTimeSeriesChart;
