import React, { Dispatch, FC, useEffect, useState } from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import GenericLineChart from '../../../../../charts/GenericLineChart';
import {
  RaptorTheme,
  greys,
  mainColors,
} from '../../../../../../styling/theme';
import GeneralSelect from '../../../../../selects/GeneralSelect';
import makeStyles from '@mui/styles/makeStyles';
import CachedIcon from '@mui/icons-material/Cached';
import { raidrClient } from '../../../../../../utilities/requestClient';
import { Button } from '@mui/material';

interface TableEditorTileProps {
  factSheetElement: string;
  factSheetsData: any[];
  setFactSheetsData: Dispatch<any>;
  setHaveChangesBeenMade: Dispatch<any>;
  index: number;
  tag: string;
}

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  generalSelectContainer: {
    // position: 'relative',
    display: 'flex',
    justifyContent: 'space-around',
    // height: 500,
    // width: '80%',
    padding: '1.2rem',
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    // marginRight: '1.2rem',
    height: '5rem',
    width: '30%',
    marginBottom: '1.2rem',
  },
  selectRoot: {
    width: '100%',
  },
  button: {
    height: '5rem',
    // marginTop: '0.5rem',
    // marginRight: '1rem',
    width: '16rem',
    backgroundColor: mainColors.controlButtonBlue,
    textColor: 'white',
  },
}));

function buildChartData(data: any): ChartEditorData[] {
  const chartData: ChartEditorData[] = [];
  data.forEach((value: any) => {
    chartData.push({
      date: value.date,
      fund: value['Fund Returns (%)'],
      benchmark: value['Benchmark Returns (%)'],
    });
  });

  return chartData;
}

// We allow a max of 10 columns in the table.
interface ChartEditorData {
  date: string;
  fund: number;
  benchmark: number;
}
const MONTH_LOOKUP: any = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12',
};

interface selectDateObject {
  rawDates: string[];
  formattedDates: string[];
  // fundData: number[];
  // benchmarkData: number[];
}

function formatDatesForSelect(data: any): selectDateObject {
  const dates: selectDateObject = { rawDates: [], formattedDates: [] }; //, fundData: [], benchmarkData: [] }
  if (!data) return dates;
  // if (!data.data) return dates;
  // if (!data.data.length) return dates;
  // if (!(data.data.length > 0)) return dates;
  if (!('cis_data' in data)) return dates;

  const monthlyReturns = JSON.parse(JSON.stringify(data.cis_data));
  // Remove the first element from the monthly returns (header)
  monthlyReturns.shift();
  monthlyReturns.shift();

  // const dates: selectDateObject = {rawDates: [], formattedDates: []}
  monthlyReturns.map((value: any) => {
    const date = value[0].split(' ');
    // Note this assumes that data will not go back before 2000
    dates.formattedDates.push(`20${date[1]}-${MONTH_LOOKUP[date[0]]}-01`);
    dates.rawDates.push(value[0]);
    // dates.fundData.push(value[1]);
    // dates.benchmarkData.push(value[2]);
  });
  return dates;
}

const LineChartEditorTile: FC<TableEditorTileProps> = (props) => {
  const {
    factSheetElement,
    factSheetsData,
    setFactSheetsData,
    setHaveChangesBeenMade,
    index,
    tag,
  } = props;

  const [chartData, setChartData] = useState(
    buildChartData(JSON.parse(factSheetElement))
  );
  const fundId = factSheetsData[index].fund_id_string;
  const [factSheetFigures, setFactSheetFigures] = useState<any>(null);

  const requestClient = raidrClient();
  const getDelegeatedActsData = async () => {
    requestClient.get(`/fact_sheets_data/${fundId}`).then((response) => {
      setFactSheetFigures(response.data);
    });
  };
  useEffect(() => {
    getDelegeatedActsData();
  }, []);

  // const stressTestData = useFetchData({
  //   url: `fact_sheets_data/${fundId}`,
  //   keyName: `fact_sheets_data/${fundId}`,
  //   makeFetch: true,
  // });

  const [selectDatesLabels, setSelectDatesLabels] = useState<string[]>([]);
  const [selectDatesValues, setSelectDatesValues] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  useEffect(() => {
    if (factSheetFigures) {
      const dates = formatDatesForSelect(factSheetFigures);
      setSelectDatesLabels(dates.formattedDates);
      setSelectDatesValues(dates.rawDates);
      setStartDate(dates.rawDates.length > 0 ? dates.rawDates[0] : '');
      setEndDate(
        dates.rawDates.length > 0
          ? dates.rawDates[dates.rawDates.length - 1]
          : ''
      );
    }
  }, [factSheetFigures]);

  function updateChartData() {
    // Find the index of the start and end date
    const startIndex = selectDatesValues.indexOf(startDate);
    const endIndex = selectDatesValues.indexOf(endDate);

    const monthlyReturns = JSON.parse(
      JSON.stringify(factSheetFigures.cis_data)
    );
    // Remove the first element from the monthly returns (header)
    monthlyReturns.shift();
    monthlyReturns.shift();
    // Creatre a list for storing the data.
    const newChartData: any[] = [];
    let dateIndex = startIndex;
    while (dateIndex <= endIndex) {
      newChartData.push({
        date: selectDatesLabels[dateIndex],
        'Fund Returns (%)': monthlyReturns[dateIndex][1],
        'Benchmark Returns (%)': monthlyReturns[dateIndex][2],
      });
      dateIndex += 1;
    }
    setChartData(buildChartData(newChartData));
    const chartDataJson = JSON.stringify(newChartData);
    // Now update the element in the overall data

    const allContent = factSheetsData;
    // Check if the text has changes and if so indicate it
    // TODO: Look into this - after a save I think this will not behave exactly as intended
    if (allContent[index].content !== chartDataJson)
      setHaveChangesBeenMade(true);
    // Now replace in the overall array
    allContent[index].content = chartDataJson;
    // Update the overall data
    setFactSheetsData(allContent);
  }

  const classes = useStyles();

  return (
    <GridItem xs={12} card style={{ marginBottom: '1rem' }}>
      <div className={classes.generalSelectContainer}>
        {selectDatesLabels.length > 0 && (
          <>
            <GeneralSelect
              labels={selectDatesLabels}
              values={selectDatesValues}
              selected={startDate}
              setSelected={setStartDate}
              placeholderValue=""
              placeholderColor={greys.grey900}
              selectTitle={'Start Date'}
              customStyles={{
                pickerRoot: classes.pickerRoot,
                selectRoot: classes.selectRoot,
              }}
            />
            <GeneralSelect
              labels={selectDatesLabels}
              values={selectDatesValues}
              selected={endDate}
              setSelected={setEndDate}
              placeholderValue=""
              placeholderColor={greys.grey900}
              selectTitle={'End Date'}
              customStyles={{
                pickerRoot: classes.pickerRoot,
                selectRoot: classes.selectRoot,
              }}
            />
            <Button
              className={classes.button}
              variant="contained"
              onClick={updateChartData}
              endIcon={<CachedIcon style={{ color: 'white' }} />}
              // disabled={!haveChangesBeenMade}
            >
              <div style={{ color: 'white' }}>Refresh Chart</div>
            </Button>
          </>
        )}
      </div>
      <GenericLineChart
        error={'No Timeseries Data available'}
        title={'Scores Timeseries'}
        showTitle
        id={`esg_var_timeseries`}
        loading={false}
        showDot={false}
        // lines={[
        //     {
        //         dataKey: metric,
        //         color: getChartColour(chartTitle),
        //         //   key: 'score',
        //         // yAxisId: 'left'
        //     },
        // ]}
        lines={[
          { dataKey: 'fund', color: mainColors.Fail },
          { dataKey: 'benchmark', color: mainColors.controlButtonBlue },
        ]}
        xAxisDataKey="date"
        //   loadingHeight={400}
        height={400}
        width={'100%'}
        margin={{
          top: 10,
          bottom: 10,
          left: 10,
          right: 40,
        }}
        yAxisLabel={{
          position: 'left',
          angle: -90,
          offset: 0,
          fontSize: '1.4rem',
          fill: greys.grey700,
          value: '% Growth',
          style: {
            textAnchor: 'middle',
          },
        }}
        // tooltipFormatter={(val: any, name: any) =>
        //     [roundNumberToTwoDecimalPlaces(val as number),
        //     FormatLabel(name)]
        // }

        data={chartData}
      />
    </GridItem>
  );
};

export default LineChartEditorTile;
