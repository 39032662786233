import { ReactElement } from 'react';
import { mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbers,
  addCommasToNumbersAndRound,
  formatMillions,
  percentageToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GenericLineChart from '../../../charts/GenericLineChart';
import CustomTable from '../../../tables/CustomTable';
import { Typography } from '@mui/material';

interface PeExposureSecondLevelTableProps {
  data: any;
  gmid: string;
}

interface PeExposureSecondLevelTableData {
  name: string;
  price: number;
  delta: number;
  size: number;
  riskFactor: number;
  countryIndex: string;
  strategyIndex: string;
  sectorIndex: string;
}
interface PeExposureDCFTableData {
  ebit: number;
  growth: number;
  volatility: number;
  wacc: number;
  valuation: number;
  maturityDate: string;
}

const dcfColumns: CustomColumn<PeExposureDCFTableData>[] = [
  {
    title: 'EBIT',
    field: 'ebit',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
      color: 'white',
    },
    render: (rowData: PeExposureDCFTableData) =>
      percentageToTwoDecimalPlaces(rowData.ebit),
  },
  {
    title: 'EBIT Growth',
    field: 'growth',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
      color: 'white',
    },
    render: (rowData: PeExposureDCFTableData) =>
      percentageToTwoDecimalPlaces(rowData.growth),
  },
  {
    title: 'EBIT Volatility',
    field: 'volatility',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
      color: 'white',
    },
    render: (rowData: PeExposureDCFTableData) =>
      percentageToTwoDecimalPlaces(rowData.volatility),
  },
  {
    title: 'wacc',
    field: 'wacc',
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData: PeExposureDCFTableData) =>
      percentageToTwoDecimalPlaces(rowData.wacc),
    headerStyle: {
      textAlign: 'center',
      color: 'white',
    },
  },
  {
    title: 'Present Valuation',
    field: 'valuation',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
      color: 'white',
    },
    render: (rowData: PeExposureDCFTableData) =>
      addCommasToNumbersAndRound(rowData.valuation),
  },
  {
    title: 'Maturity Date',
    field: 'maturityDate',
    headerStyle: {
      textAlign: 'center',
      color: 'white',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
];

function buildPeExposureSecondLevelTableColumns(
  countryIndex: string,
  strategyIndex: string,
  sectorIndex: string
) {
  const columns: CustomColumn<PeExposureSecondLevelTableData>[] = [
    // {
    //     title: 'Name',
    //     field: 'name',
    //     cellStyle: {
    //         textAlign: 'center',
    //     },
    //     headerStyle: {
    //         textAlign: 'center',
    //         color: 'white',
    //     },
    // },
    // {
    //     title: 'Valuation',
    //     field: 'price',
    //     cellStyle: {
    //         textAlign: 'center',
    //     },
    //     headerStyle: {
    //         textAlign: 'center',
    //         color: 'white',
    //     },
    //     render: (rowData: PeExposureSecondLevelTableData) => roundNumberToTwoDecimalPlaces(rowData.price),
    // },
    {
      title: 'Delta',
      field: 'delta',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        color: 'white',
      },
    },
    {
      title: 'Size',
      field: 'size',
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData: PeExposureSecondLevelTableData) =>
        addCommasToNumbers(rowData.size),
      headerStyle: {
        textAlign: 'center',
        color: 'white',
      },
    },
    {
      title: 'Risk Factor',
      field: 'riskFactor',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        color: 'white',
      },
    },
    {
      // title: (
      //     <div>
      //         <Tooltip title={
      //             <Typography variant='h3' style={{ color: 'white' }}>
      //                 {countryIndexTooltip}
      //             </Typography>
      //         }
      //             placement="top">

      //             <HelpIcon style={{ cursor: 'pointer' }} />

      //         </Tooltip>
      //         <span style={{ marginLeft: '0.5rem' }}>Country Index</span>
      //     </div>
      // ),
      title: 'Country Index',
      field: 'countryIndex',
      headerStyle: {
        textAlign: 'center',
        color: 'white',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      // title: (
      //     <div>
      //         <Tooltip title={
      //             <Typography variant='h3' style={{ color: 'white' }}>
      //                 {strategyIndexTooltip}
      //             </Typography>
      //         }
      //             placement="top">

      //             <HelpIcon style={{ cursor: 'pointer' }} />

      //         </Tooltip>
      //         <span style={{ marginLeft: '0.5rem' }}>Strategy Index</span>
      //     </div>
      // ),
      title: 'Strategy Index',
      field: 'strategyIndex',
      headerStyle: {
        textAlign: 'center',
        color: 'white',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      // title: (
      //     <div>
      //         <Tooltip title={
      //             <Typography variant='h3' style={{ color: 'white' }}>
      //                 {sectorIndexTooltip}
      //             </Typography>
      //         }
      //             placement="top">

      //             <HelpIcon style={{ cursor: 'pointer' }} />

      //         </Tooltip>
      //         <span style={{ marginLeft: '0.5rem' }}>Sector Index</span>
      //     </div>
      // ),
      title: 'Sector Index',
      field: 'sectorIndex',
      headerStyle: {
        textAlign: 'center',
        color: 'white',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
  ];
  return columns;
}

function buildSecondLevelTableData(
  data: any
): PeExposureSecondLevelTableData[] {
  if (!data.underlyingAssets.length) return [];
  const underlyingAssets = data.underlyingAssets;
  const tableData: PeExposureSecondLevelTableData[] = [];
  underlyingAssets.forEach((value: any) => {
    tableData.push({
      name: value[0],
      price: value[1],
      delta: value[2],
      size: value[3],
      riskFactor: value[4],
      countryIndex: data.countryIndex,
      strategyIndex: data.strategyIndex,
      sectorIndex: data.sectorIndex,
    });
  });

  return tableData;
}

function buildProxyTimeseriesData(data: any) {
  if (!data.data.length) return [];
  if (!data.data[0].length) return [];
  const timseriesData = data.data[0];
  const chartData: any[] = [];
  timseriesData.forEach((value: any) => {
    chartData.push({
      name: value[0],
      value: value[1],
    });
  });

  return chartData;
}

function ProxyTimeseriesChart(data: any): ReactElement {
  const chartData = buildProxyTimeseriesData(data.data);
  return (
    <GenericLineChart
      title={'NAV Timeseries'}
      data={chartData}
      id={'proxy_chart'}
      xAxisDataKey="name"
      // yAxisDomain={[
      //     minvalue === 0 ? -0.01 : minvalue - Math.abs(minvalue) * 0.1,
      //     maxvalue + Math.abs(maxvalue) * 0.1,
      // ]}
      yAxisTickFormatter={(value: number) => formatMillions(value)}
      tooltipFormatter={(value: number) => addCommasToNumbersAndRound(value)}
      lines={[
        {
          dataKey: 'value',
          color: mainColors.Pass_darker,
        },
      ]}
      width={'100%'}
      height={400}
      showDot={false}
      margin={{
        top: 10,
        bottom: 10,
        left: 10,
        right: 40,
      }}
      error={'No Proxy Data available'}
    />
  );
}

function buildDCFTableData(data: any): PeExposureDCFTableData[] {
  if (!data.underlyingAssets.length) return [];
  const underlyingAssets = data.underlyingAssets;
  const tableData: PeExposureDCFTableData[] = [];
  underlyingAssets.forEach((value: any) => {
    tableData.push({
      ebit: data.ebit,
      valuation: value[1],
      growth: data.ebitGrowth,
      volatility: data.ebitVolatility,
      wacc: data.wacc,
      maturityDate: data.maturityDate,
    });
  });

  return tableData;
}

function PeExposureSecondLevelTable({
  data,
  gmid,
}: PeExposureSecondLevelTableProps): ReactElement {
  const secondLevelTableData = buildSecondLevelTableData(data);
  const dcfTableData = buildDCFTableData(data);
  const PeExposureSecondLevelTableColumns =
    buildPeExposureSecondLevelTableColumns(
      data.Location,
      data.Strategy,
      data.Industry
    );
  return secondLevelTableData.length ? (
    // !navTimeSeriesData.isFetching ? (
    <div
      style={{
        marginTop: '1rem',
        marginBottom: '1rem',
        marginLeft: '1rem',
        marginRight: '1rem',
      }}
    >
      <CustomTable<PeExposureDCFTableData>
        columns={dcfColumns}
        exportFileName="position-exposure"
        showToolbar={false}
        title={'Positon Exposure'}
        id={`positionExposure`}
        data={dcfTableData}
        options={{
          draggable: false,
          sorting: false,
          paging: false,
          search: false,
          exportButton: false,
          emptyRowsWhenPaging: false,
          headerStyle: {
            color: 'white',
            textAlign: 'left',
            backgroundColor: mainColors.mainBlue_lighter,
            opacity: 0.7,
          },
        }}
      />
      <CustomTable<PeExposureSecondLevelTableData>
        columns={PeExposureSecondLevelTableColumns}
        exportFileName="position-exposure"
        showToolbar={false}
        title={'Positon Exposure'}
        id={`positionExposure`}
        data={secondLevelTableData}
        options={{
          draggable: false,
          sorting: false,
          paging: false,
          search: false,
          exportButton: false,
          emptyRowsWhenPaging: false,
          headerStyle: {
            color: 'white',
            textAlign: 'left',
            backgroundColor: mainColors.pineGreen,
            opacity: 0.7,
          },
        }}
      />
      {/* <ProxyTimeseriesChart data={navTimeSeriesData} /> */}
    </div>
  ) : (
    // : <RaptorLoading centerWrap />)
    <Typography variant="h3">No Data Available</Typography>
  );
}
export default PeExposureSecondLevelTable;
