import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme } from '../../../../../../../../styling/theme';

interface IUseKeySelectStyleProps {
  inputKey: string;
}

export const useKeySelectStyles = makeStyles<
  RaptorTheme,
  IUseKeySelectStyleProps
>((theme) => ({
  keySelectContainer: {
    borderRadius: '50%',
    position: 'relative',
    color: theme.palette.grey[200],
    display: 'flex',
    alignItems: 'center',
  },
  topLevelPickerRoot: {
    height: '100%',
  },
  topLevelFormControl: {
    margin: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  topLevelSelectRoot: {
    color: (props) =>
      props.inputKey !== 'none_selected'
        ? theme.palette.primary.main
        : theme.palette.grey[400],
  },
  selectMenu: {
    width: 200,
    height: 200,
    position: 'absolute',
    top: 0,
    right: -200,

    transition: 'all .3s',
  },
  buttonRoot: {
    // color: theme.palette.grey[100],
    paddingLeft: 0,
  },
  buttonSelect: {
    color: (props) =>
      props.inputKey !== 'none_selected'
        ? theme.palette.primary.main
        : theme.palette.grey[400],
  },
}));
export default useKeySelectStyles;
