import React, { useEffect, useRef } from 'react';
import { createChart, IChartApi, ColorType } from 'lightweight-charts';
import { mainColors } from '../../../../styling/theme';
import { TimeSeriesDataPoint } from './MarketsGraph';

interface ChartProps {
  graphData: TimeSeriesDataPoint[];
  lineColour: string;
}

const Chart: React.FC<ChartProps> = ({ graphData, lineColour}) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<IChartApi | null>(null);

  useEffect(() => {
    if (!chartContainerRef.current || graphData.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      layout: {
        textColor: 'black',
        background: { color: 'white' as ColorType },
      },
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      handleScale: {
        axisPressedMouseMove: false,  // Disable scaling by dragging the y-axis
        mouseWheel: false,            // Disable zooming via mouse wheel
        pinch: false,                 // Disable pinch-to-zoom gesture
      },
      handleScroll: {
        horzTouchDrag: false,         // Disable horizontal dragging
        vertTouchDrag: false,         // Disable vertical dragging
        mouseWheel: false,            // Disable scrolling using the mouse wheel
      },
    });

    const areaSeries = chart.addAreaSeries({
      lastValueVisible: false,
      crosshairMarkerVisible: false,
      lineColor: 'transparent',
      topColor:
        lineColour === mainColors.Fail_darker
          ? 'rgba(255, 0, 0, 0.8)'
          : 'rgba(77, 228, 39, 0.8)',
      bottomColor:
      lineColour === mainColors.Fail_darker
          ? 'rgba(255, 216, 216, 0.2)'
          : 'rgba(221, 250, 213, 0.2)',
    });

    // Area Series
    areaSeries.setData(graphData);
    chartRef.current = chart;
    const lineSeries = chart.addLineSeries({
      color: lineColour,
      lineWidth: 4,
    });

    lineSeries.setMarkers([
      {
        time: graphData[graphData.length - 1].time,
        position: 'inBar',
        color: lineColour,
        shape: 'square', // 'circle', 'square', 'arrowUp', 'arrowDown'
      },
    ]);

    const transformedData = graphData.map((dataPoint) => ({
      time: dataPoint.time,
      value: dataPoint.value,
    }));

    lineSeries.setData(transformedData);

    chart.timeScale().fitContent();

    return () => {
      chart.remove();
    };
  }, [graphData]);

  // Resize
  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current && chartContainerRef.current) {
        chartRef.current.applyOptions({
          width: chartContainerRef.current.clientWidth,
          height: chartContainerRef.current.clientHeight,
        });
        chartRef.current.timeScale().fitContent();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div ref={chartContainerRef} style={{ width: '100%', height: '100%' }} />
  );
};

export default Chart;
