import React, { ReactElement } from 'react';
import { UseFundNoteProps } from '../../hooks/useFundNotes';
import ExportButton, {
  ColumnIdToHeaderMapType,
} from '../feedback/ExportButton';
import TableSearch from './TableSearch';
import { Grid, Typography } from '@mui/material';

interface CustomTableToolbarProps<T extends object> {
  searchHandler: (input: string) => void;
  csvFields: string[];
  fieldsMap?: ColumnIdToHeaderMapType;
  exportFileName?: string;
  data: T[];
  columns: any[];
  toolbarComponents?: any;
  options: any;
  selectedPositionDate?: string;
  pdfExport?: boolean;
  allowNoteExport?: UseFundNoteProps;
  tableId: string;
  title?: string;
}

function CustomTableToolbar<T extends object>(
  props: CustomTableToolbarProps<T>
): ReactElement | null {
  // const { toolbarTitle } = props.toolbarComponents;
  const toolbarStyles =
    props.toolbarComponents && props.toolbarComponents.toolbarStyle
      ? props.toolbarComponents.toolbarStyle
      : {};

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="flex-start"
      style={{
        height: '4.8rem',
        paddingRight: 8,
        paddingLeft: 16,
        display: 'flex',
        alignItems: 'center',
        ...toolbarStyles,
      }}
    >
      <Grid
        item
        xs
        style={{
          display: 'flex',
          alignSelf: 'center',
          justifyContent: 'space-between',
        }}
      >
        {props.toolbarComponents?.toolbarTitle || props.title ? (
          <Typography
            variant="h2"
            style={
              props.toolbarComponents?.titleStyle || {
                fontWeight: 400,
                fontSize: '2rem',
              }
            }
          >
            {props.toolbarComponents?.toolbarTitle || props.title}
          </Typography>
        ) : null}

        {props.toolbarComponents?.selects}

        {props.toolbarComponents?.otherComponents}
      </Grid>
      <Grid
        item
        xs
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flexGrow: 0,
          // top: "-4rem",
        }}
      >
        {props.options.exportButton ? (
          <ExportButton
            exportData={props.data}
            pdfIdentifier={props.tableId}
            fields={props.csvFields}
            fieldsMap={props.fieldsMap}
            fileName={props.exportFileName}
            selectedPositionDate={props.selectedPositionDate}
            allowPdfExport={props.pdfExport}
            allowNoteExport={
              props.allowNoteExport
                ? {
                    data: props.data,
                    columns: props.columns,
                    ...props.allowNoteExport,
                  }
                : undefined
            }
            linkToRaptorExport={props.options.raptorExport}
          />
        ) : null}
        {props.options.search && (
          <TableSearch searchHandler={props.searchHandler} />
        )}
      </Grid>
    </Grid>
  );
}

export default React.memo(CustomTableToolbar);
