import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Dispatch, FC } from 'react';
import RaptorSelect from '../../../../selects/RaptorSelect.component';
import { greys, mainColors } from '../../../../../styling/theme';
import { Tooltip } from '@mui/material';

interface FilterForTemplateFieldsSelectProps {
  setTemplateFilter: Dispatch<string>;
  setForceRerender: Dispatch<boolean>;
}

const templateOptions: string[] = [
  'No Filter',
  'ManCo',
  'Sub Fund',
  'Fund Manager',
];

const highlightColors: { [key: string]: string } = {
  ManCo: mainColors.pastelGreen,
  'Sub Fund': mainColors.pastelBlue,
  'Fund Manager': mainColors.pastelYellow,
  'No Filter': mainColors.mainBlue,
};
const textColors: { [key: string]: string } = {
  ManCo: 'black',
  'Sub Fund': 'black',
  'Fund Manager': 'black',
  'No Filter': 'white',
};

const useStyles = makeStyles(() => ({
  selectContainer: {
    width: '100%',
    minWidth: '20rem',
    // marginLeft: '10rem',
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
}));

const FilterForTemplateFieldsSelect: FC<FilterForTemplateFieldsSelectProps> = (
  props
) => {
  const { setTemplateFilter, setForceRerender } = props;

  const classes = useStyles();
  const [currentType, setCurrentType] = React.useState<string>('No Filter');
  function handleChoice(choice: string) {
    setCurrentType(choice);
    setTemplateFilter(choice);
    setForceRerender(true);
  }
  return (
    <Tooltip
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      title="Filter for template fields"
      placement={'top'}
      arrow
      enterNextDelay={2000}
      enterDelay={2000}
    >
      <div className={classes.selectContainer}>
        <RaptorSelect
          options={templateOptions}
          handleChoice={handleChoice}
          defaultOption={'No Filter'}
          textColor={textColors[currentType]}
          backgroundColor={highlightColors[currentType]}
          hoverColor={mainColors.mainBlue_lighter}
        />
      </div>
    </Tooltip>
  );
};

export default FilterForTemplateFieldsSelect;
