import React from 'react';
import { FixedIncomeComponent } from '../FixedIncome';
import CustomTable from '../../../../tables/CustomTable';
import {
  ICreditRatingsData,
  generateCreditRatingsColumns,
  generateCreditRatingsData,
} from './creditRatings.data';
import GridItem from '../../../../layout/GridComponents/GridItem';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';

type CreditRatingsProps = FixedIncomeComponent;

const CreditRatingsTable = (props: CreditRatingsProps) => {
  const { data } = props.inputData;
  const tableData = generateCreditRatingsData(data);
  const tableColumns = generateCreditRatingsColumns();
  return (
    <GridItem card xs>
      <CustomTable<ICreditRatingsData>
        showToolbar={true}
        options={{
          paging: false,
          search: true,
          exportButton: true,
        }}
        toolbarComponents={{
          toolbarTitle: 'Credit Ratings Table',
        }}
        csvFields={tableColumns.map(
          (col: CustomColumn<ICreditRatingsData>) => col.field!
        )}
        data={tableData}
        columns={tableColumns}
      />
    </GridItem>
  );
};

export default CreditRatingsTable;
