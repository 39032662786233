import { FC } from 'react';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { mainColors } from '../../../../styling/theme';
import { percentageToTwoDecimalPlaces } from '../../../../utilities/numberFormatters';
import GenericLineChart from '../../../charts/GenericLineChart';
import GridItem from '../../../layout/GridComponents/GridItem';
import { Typography } from '@mui/material';

interface subscriptionsRedemptionsChartProps {
  data: any;
  title: string;
}

interface IsubscriptionsRedemptionsData {
  metric: string;
  scenario: string;
  value: number;
}

function buildSubscriptionsRedemptionsData(data: any, title: string) {
  const chartData: any[] = [];
  const subsRedsData = data.data[0];
  if (title === 'Redemption Data') {
    const redemptionData = subsRedsData.redemptions;
    redemptionData.shift();
    redemptionData.forEach((value: any) => {
      chartData.push({
        name: value[0],
        value: value[1],
      });
    });
  } else if (title === 'Gross Activity Data') {
    const subscriptionData = subsRedsData.subscriptions;
    subscriptionData.shift();
    subscriptionData.forEach((value: any) => {
      chartData.push({
        name: value[0],
        value: value[1],
      });
    });
  }

  return chartData;
}

const SubscriptionsRedemptionsChart: FC<subscriptionsRedemptionsChartProps> = (
  props
) => {
  const { data, title } = props;
  const chartData = buildSubscriptionsRedemptionsData(data, title);
  const { height, width } = useWindowSize();
  let minvalue = chartData[0].value;
  let maxvalue = chartData[0].value;
  chartData.forEach((value: any) => {
    if (minvalue > value.value) minvalue = value.value;
    if (maxvalue < value.value) maxvalue = value.value;
  });

  return (
    <GridItem xs={6} card style={{ padding: 8 }}>
      <Typography
        variant="h2"
        style={{
          marginLeft: '2rem',
          marginTop: '1rem',
          fontSize: '2rem',
          fontWeight: 400,
        }}
      >
        {title}
      </Typography>

      <GenericLineChart
        title={title}
        data={chartData}
        export
        id={
          title === 'Redemption Data'
            ? 'redemption_chart'
            : 'subscription_chart'
        }
        xAxisDataKey="name"
        yAxisDomain={[
          minvalue === 0 ? -0.01 : minvalue - Math.abs(minvalue) * 0.1,
          maxvalue + Math.abs(maxvalue) * 0.1,
        ]}
        yAxisTickFormatter={(value: number) =>
          percentageToTwoDecimalPlaces(value)
        }
        tooltipFormatter={(value: number) =>
          percentageToTwoDecimalPlaces(value)
        }
        lines={[
          {
            dataKey: 'value',
            color:
              title === 'Redemption Data'
                ? mainColors.mainBlue_lighter
                : mainColors.Pass,
          },
        ]}
        width={'100%'}
        height={height / 2 - 160}
        showDot={false}
        margin={{
          top: 10,
          bottom: 10,
          left: 10,
          right: 40,
        }}
        error={'No Subcriptions/Redemptions Data available'}
      />
    </GridItem>
  );
};

export default SubscriptionsRedemptionsChart;
