import React, { FC, useEffect } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import { makeUniqueDataKey } from '../../../../utilities/urlBuilder';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import LasdabChart from './chart';
import LasdabTable from './table';
import { useDispatch } from 'react-redux';
import { Select } from '../../../../types/redux/pages/PagesStore';
import { addAdditionalTitleBarComponents, removeTitleBarComponent } from '../../../../redux/pages/actions';
import { APPEND_NEW_SELECT_TO_TITLEBAR, REMOVE_SELECT_FROM_TITLEBAR } from '../../../../types/redux/pages/pageTypes';

export const LiquidityUrls = {
  FETCH_FUND_ASSET_LIQUIDITY: 'agg_liquidation_zone_inc_cash',
  FETCH_FUND_LIQUIDATION_TIME_COST: 'aggregate_liquidity_risk_report_data/2',
  FETCH_FUND_LIQUIDITY_RISK_OVERVIEW: 'aggregate_liquidity_risk_report_data/3',
  FETCH_EVOLUTION: 'aggregate_lqdty_review_data',
  FETCH_RST: 'historical_liquidity_reverse_stress_tests_agg',
};

export const scenarioMapGeneral: {
  [key: string]: string;
} = {
  standard: 'Standard',
  stressed: 'Stressed',
  superStressed: 'Super Stressed',
};
export const scenarioMapGem: {
  [key: string]: string;
} = {
  standard: 'Standard',
  stressed: 'Stressed',
  superStressed: 'Super Stressed',
  euSovDebtCrisisJul11: 'EU Sov Debt Crisis Jul11',
  creditCrisisSept2008: 'Credit Crisis Sept08',
};

const LiquidityAdjustedSettlementDateAchievableBalance: FC<
  FundInfoComponentProps
> = ({
  fundId,
  fundName,
  positionDate,
}) => {
  
  const dispatch = useDispatch();

  // Add the select components to the title bar
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: 'Stress Level: ',
        titleBarKey: 'stressLevel',
        values: [
          {
            label: 'Standard',
            value: 'standard',
          },
          {
            label: 'Stressed',
            value: 'stressed',
          },
          {
            label: 'Super Stressed',
            value: 'superStressed',
          },
        ],
        displayOnOverviewPage: true,
        aValueIsAlwaysSelected: true,
        currentValue: 'standard',
      },
    ];
    
    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR
      )
    );

    return () => {
      dispatch(removeTitleBarComponent('stressLevel', REMOVE_SELECT_FROM_TITLEBAR));
    };

  }, []);

  const chosenStressLevel = useTitleBarSelect('stressLevel') || 'standard';

  const urlForFetch = !positionDate
    ? `most_recently_stored_report_data_agg/${fundId}/cpl_risk`
    : `stored_report_data_agg/${fundId}/cpl_risk/${positionDate}`;

  const fundLiquidityData = useFetchData({
    url: urlForFetch,
    keyName: makeUniqueDataKey(fundId, 'lasdab', positionDate),
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[fundLiquidityData]}
      customErrorMessage="No Data Available."
    >
      <LasdabTable
        fundName={fundName}
        data={fundLiquidityData}
        stressLevel={chosenStressLevel}
      />
      <LasdabChart data={fundLiquidityData} stressLevel={chosenStressLevel} />
    </GeneralComponentErrorShield>
  );
};

export default LiquidityAdjustedSettlementDateAchievableBalance;
