import { percentageToTwoDecimalPlaces } from '../../../../../../utilities/numberFormatters';
import formatDate from '../../../../../../utilities/dateFormatters';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';

export interface ILiquidationTimeCostTableData {
  description: string;
  oneDay: number;
  oneWeek: number;
  twoWeeks: number;
  oneMonth: number;
  threeMonths: number;
  sixMonths?: number;
  oneYear?: number;
}

export interface ILiquidationTimeCostChartData {
  nav: number;
  daily: number;
  dailyCost: number;
  totalRedeemed: number;
  bauRedemptions: number;
}

export type BauOrStressed = 'bau' | 'stressed';

export const liquidationColumns = (
  section?: string
): CustomColumn<ILiquidationTimeCostTableData>[] =>
  section && section.includes('ucits')
    ? [
        {
          field: 'description',
          title: 'Description',
        },
        {
          field: 'oneDay',
          title: '1 Day',
          render: (rowData: ILiquidationTimeCostTableData) =>
            percentageToTwoDecimalPlaces(rowData.oneDay),
        },
        {
          field: 'oneWeek',
          title: '1 Week',
          render: (rowData: ILiquidationTimeCostTableData) =>
            percentageToTwoDecimalPlaces(rowData.oneWeek),
        },
        {
          field: 'twoWeeks',
          title: '2 Weeks',
          render: (rowData: ILiquidationTimeCostTableData) =>
            percentageToTwoDecimalPlaces(rowData.twoWeeks),
        },
        {
          field: 'oneMonth',
          title: '1 Month',
          render: (rowData: ILiquidationTimeCostTableData) =>
            percentageToTwoDecimalPlaces(rowData.oneMonth),
        },
        {
          field: 'threeMonths',
          title: '3 Months',
          render: (rowData: ILiquidationTimeCostTableData) =>
            percentageToTwoDecimalPlaces(rowData.threeMonths),
        },
      ]
    : [
        {
          field: 'description',
          title: 'Description',
        },
        {
          field: 'oneDay',
          title: '1 Day',
          render: (rowData: ILiquidationTimeCostTableData) =>
            percentageToTwoDecimalPlaces(rowData.oneDay),
        },
        {
          field: 'oneWeek',
          title: '1 Week',
          render: (rowData: ILiquidationTimeCostTableData) =>
            percentageToTwoDecimalPlaces(rowData.oneWeek),
        },
        {
          field: 'twoWeeks',
          title: '2 Weeks',
          render: (rowData: ILiquidationTimeCostTableData) =>
            percentageToTwoDecimalPlaces(rowData.twoWeeks),
        },
        {
          field: 'threeWeeks',
          title: '3 Weeks',
          render: (rowData: ILiquidationTimeCostTableData) =>
            percentageToTwoDecimalPlaces(rowData.twoWeeks),
        },
        {
          field: 'oneMonth',
          title: '1 Month',
          render: (rowData: ILiquidationTimeCostTableData) =>
            percentageToTwoDecimalPlaces(rowData.oneMonth),
        },
        {
          field: 'threeMonths',
          title: '3 Months',
          render: (rowData: ILiquidationTimeCostTableData) =>
            percentageToTwoDecimalPlaces(rowData.threeMonths),
        },
        {
          field: 'sixMonths',
          title: '6 Months',
          render: (rowData: ILiquidationTimeCostTableData) =>
            percentageToTwoDecimalPlaces(rowData.sixMonths!),
        },
        {
          field: 'nineMonths',
          title: '9 Months',
          render: (rowData: ILiquidationTimeCostTableData) =>
            percentageToTwoDecimalPlaces(rowData.sixMonths!),
        },
        {
          field: 'oneYear',
          title: '12 Months',
          render: (rowData: ILiquidationTimeCostTableData) =>
            percentageToTwoDecimalPlaces(rowData.oneYear!),
        },
      ];

export const descriptionNamesBAU = [
  'BAU Redemptions',
  'Redeemed',
  'Cost',
  'Total Redeemed',
  'NAV',
];
export const descriptionNamesStressed = [
  'Stressed Redemptions',
  'Redeemed',
  'Cost',
  'Total Redeemed',
  'NAV',
];
export function buildLTCTable(type: BauOrStressed) {
  // we are going to be building a function for either bau or stressed

  return (inputData: any) => {
    if (!inputData.length) return [];

    // time_points = [1,5,10,20,61,122,183];
    const data =
      type === 'bau'
        ? inputData[0].funding_liquidity_bau
        : inputData[0].funding_liquidity_stressed;

    const descriptionNames =
      type === 'bau' ? descriptionNamesBAU : descriptionNamesStressed;

    const daysToShow = [
      1,
      5,
      10,
      20,
      61,
      121,
      data.daily_cost_of_redemption.length - 1,
    ];
    const keysForObjects = [
      'oneDay',
      'oneWeek',
      'twoWeeks',
      'oneMonth',
      'threeMonths',
      'sixMonths',
      'oneYear',
    ];

    const descriptions = buildLTCData(data, type);
    const returnData: any = [];

    descriptions.forEach((description, index: number) => {
      const descriptionObject: any = {};
      descriptionObject['description'] = descriptionNames[index];
      let counter = 0; // this is for getting the key name from keys for objects

      for (let i = 0; i < description.length; i++) {
        if (daysToShow.includes(i)) {
          descriptionObject[keysForObjects[counter++]] = description[i][1];
        }
      }

      if (Object.keys(descriptionObject).length > 1) {
        returnData.push(descriptionObject);
      }
    });

    return returnData;
  };
}

export function buildLTCData(data: any, key: BauOrStressed) {
  // this takes in the data and "bau" or "stressed"

  if (data.daily_redemption.length < 1) {
    return [];
  }

  const data1: any = [];
  const data2: any = [];
  const data3: any = [];
  const data4: any = [];
  const data5: any = [];
  const dates = data['funding_data']['dates'];
  const nav = data.funding_data.indicative_info.nav;
  const d1 = data.daily_redemption;
  const d2 = data.daily_cost_of_redemption;
  const d3 = data.funding_data.fund_redemption[key]; // could be "bau" or "stressed"

  for (let i = 0; i < d1.length; i++) {
    data1.push([dates[i], d3[i] / nav]); // bau
    data2.push([dates[i], d1[i] / nav]); // daily
    data3.push([dates[i], d2[i] / nav]); // daily cost
    data4.push([dates[i], (d1[i] + d2[i]) / nav]); // total redeemed
    data5.push([dates[i], (nav - d3[i] - d2[i]) / nav]); // nav
  }

  return [data1, data2, data3, data4, data5];
}

export const buildBauTableData = buildLTCTable('bau');
export const buildStressedTable = buildLTCTable('stressed');

export const colNamesBAU = [
  'BAU Redemptions',
  'Redeemed',
  'Cost',
  'Total Redeemed',
  'NAV',
];
export const colNamesStressed = [
  'Stressed Redemptions',
  'Redeemed',
  'Cost',
  'Total Redeemed',
  'NAV',
];

export const colColors = ['red', 'green', 'blue', 'yellow', 'purple'];

export function buildLTCChart(type: BauOrStressed) {
  return (inputData: any) => {
    if (!inputData.length) return [];
    const data =
      type === 'bau'
        ? inputData[0].funding_liquidity_bau
        : inputData[0].funding_liquidity_stressed;

    const colNames = type === 'bau' ? colNamesBAU : colNamesStressed;

    const returnData: any = [];
    const descriptions = buildLTCData(data, type);

    const mainLength = descriptions[0].length;
    for (let i = 0; i < mainLength; i++) {
      let newObj: any = {};
      descriptions.forEach((description, index) => {
        if (description.length) {
          newObj['name'] = formatDate(new Date(description[i][0]), false);
          newObj[colNames[index]] = description[i][1] * 100;
        }
      });
      returnData.push(newObj);
      newObj = {};
    }

    return returnData;
  };
}

export const adjustedNames = {
  bau_redemptions: 'BAU Redemptions',
  daily: 'Daily',
  daily_cost: 'Daily Cost',
  total_redeemed: 'Total Redeemed',
  nav: 'NAV',
};

export const buildBauChart = buildLTCChart('bau');
export const buildStressedChart = buildLTCChart('stressed');
