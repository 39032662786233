import { CSSProperties } from 'react';
import { hexToRGBA } from '../../../../../utilities/colorUtilities';
import { RaptorTheme, mainColors } from '../../../../../styling/theme';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { useTheme } from '@mui/material';

type NumOrString = number | string;
export interface IRiskComparisonTableVarChanges {
  number: NumOrString;
  netExposureRef_Title: NumOrString;
  netExposureRef: NumOrString;
  netExposureComp_Title: NumOrString;
  netExposureComp: NumOrString;
  varRefTitle: NumOrString;
  varRef: NumOrString;
  varCompTitle: NumOrString;
  varComp: NumOrString;
  cvarRefTitle: NumOrString;
  cvarRef: NumOrString;
  cvarCompTitle: NumOrString;
  cvarComp: NumOrString;
  dvarRefTitle: NumOrString;
  dvarRef: NumOrString;
  dvarCompTitle: NumOrString;
  dvarComp: NumOrString;
}
export const csvVarChanges = [
  'number',
  'netExposureRef_Title',
  'netExposureRef',
  'netExposureComp_Title',
  'netExposureComp',
  'varRefTitle',
  'varRef',
  'varCompTitle',
  'varComp',
  'cvarRefTitle',
  'cvarRef',
  'cvarCompTitle',
  'cvarComp',
  'dvarRefTitle',
  'dvarRef',
  'dvarCompTitle',
  'dvarComp',
];

const titleDateStyle = {
  fontSize: '1.3rem',
};

const netExposureStyle = {
  backgroundColor: hexToRGBA(mainColors.mainBlue, 0.3),
};
const varStyle = {
  backgroundColor: hexToRGBA(mainColors.secondaryRed, 0.3),
};
const cvarStyle = {
  backgroundColor: hexToRGBA(mainColors.tertiaryGreen, 0.3),
};

const dvarStyle = {
  backgroundColor: hexToRGBA(mainColors.fourthPurple, 0.3),
};

const styles = {
  netExposureStyle,
  varStyle,
  cvarStyle,
  dvarStyle,
};

const netExposureBackground = hexToRGBA(mainColors.mainBlue, 0.3);

enum varChangesAreas {
  netExposure = 'netExposureStyle',
  var = 'varStyle',
  cvar = 'cvarStyle',
  dvar = 'dvarStyle',
}

export const useVarChangesColumns = (
  referenceDate: string,
  comparisonDate: string
): CustomColumn<IRiskComparisonTableVarChanges>[] => {
  const theme: RaptorTheme = useTheme();

  const generateVarChangesCellStyles = (
    area: varChangesAreas,
    column: NumOrString,
    refOrComp: string
  ): CSSProperties => {
    switch (column) {
      case 'Aggregate':
        return { ...titleDateStyle, ...styles[area], fontWeight: 'bold' };
      case refOrComp:
        return { ...titleDateStyle, textAlign: 'right', ...styles[area] };
      default:
        return { ...styles[area], textAlign: 'left' };
    }
  };

  return [
    {
      title: '#',
      field: 'number',
      width: 1,
    },
    {
      title: 'Net Exposure',
      field: 'netExposureRef_Title',
      cellStyle: (
        data: IRiskComparisonTableVarChanges[],
        rowData: IRiskComparisonTableVarChanges
      ) => {
        return generateVarChangesCellStyles(
          varChangesAreas.netExposure,
          rowData.netExposureRef_Title,
          referenceDate
        );
      },
      width: 100,
      headerStyle: {
        fontWeight: 'bold',
        fontSize: '1.4rem',
        color: theme.palette.primary.main,
        backgroundColor: netExposureBackground,
        borderBottom: theme.borders!.main,
      },
    },
    {
      title: '',
      field: 'netExposureRef',
      cellStyle: (
        data: IRiskComparisonTableVarChanges[],
        rowData: IRiskComparisonTableVarChanges
      ) => {
        return {
          ...generateVarChangesCellStyles(
            varChangesAreas.netExposure,
            rowData.netExposureRef,
            referenceDate
          ),
          borderRight: '1px solid white',
        };
      },
      headerStyle: {
        backgroundColor: netExposureBackground,
      },
    },
    {
      title: '',
      field: 'netExposureComp_Title',
      cellStyle: (
        data: IRiskComparisonTableVarChanges[],
        rowData: IRiskComparisonTableVarChanges
      ) => {
        return generateVarChangesCellStyles(
          varChangesAreas.netExposure,
          rowData.netExposureComp_Title,
          comparisonDate
        );
      },
      headerStyle: {
        backgroundColor: netExposureBackground,
      },
    },
    {
      title: '',
      field: 'netExposureComp',
      cellStyle: (
        data: IRiskComparisonTableVarChanges[],
        rowData: IRiskComparisonTableVarChanges
      ) => {
        return {
          ...generateVarChangesCellStyles(
            varChangesAreas.netExposure,
            rowData.netExposureComp_Title,
            comparisonDate
          ),
          borderRight: '1px solid white',
        };
      },
      headerStyle: {
        backgroundColor: netExposureBackground,
      },
    },
    {
      title: 'VaR',
      field: 'varRefTitle',

      cellStyle: (
        data: IRiskComparisonTableVarChanges[],
        rowData: IRiskComparisonTableVarChanges
      ) => {
        return generateVarChangesCellStyles(
          varChangesAreas.var,
          rowData.varRefTitle,
          referenceDate
        );
      },

      headerStyle: {
        fontWeight: 'bold',
        fontSize: '1.4rem',
        color: theme.palette.primary.main,
        backgroundColor: varStyle.backgroundColor,
      },
    },
    {
      title: '',
      field: 'varRef',
      cellStyle: (
        data: IRiskComparisonTableVarChanges[],
        rowData: IRiskComparisonTableVarChanges
      ) => {
        return {
          ...generateVarChangesCellStyles(
            varChangesAreas.var,
            rowData.varRef,
            referenceDate
          ),
          borderRight: '1px solid white',
        };
      },
      headerStyle: {
        backgroundColor: varStyle.backgroundColor,
      },
    },
    {
      title: '',
      field: 'varCompTitle',
      cellStyle: (
        data: IRiskComparisonTableVarChanges[],
        rowData: IRiskComparisonTableVarChanges
      ) => {
        return generateVarChangesCellStyles(
          varChangesAreas.var,
          rowData.varCompTitle,
          comparisonDate
        );
      },
      headerStyle: {
        backgroundColor: varStyle.backgroundColor,
      },
    },
    {
      title: '',
      field: 'varComp',
      cellStyle: (
        data: IRiskComparisonTableVarChanges[],
        rowData: IRiskComparisonTableVarChanges
      ) => {
        return {
          ...generateVarChangesCellStyles(
            varChangesAreas.var,
            rowData.varComp,
            comparisonDate
          ),
          borderRight: '1px solid white',
        };
      },
      headerStyle: {
        backgroundColor: varStyle.backgroundColor,
      },
    },
    {
      title: 'C-Var',
      field: 'cvarRefTitle',
      cellStyle: (
        data: IRiskComparisonTableVarChanges[],
        rowData: IRiskComparisonTableVarChanges
      ) => {
        return generateVarChangesCellStyles(
          varChangesAreas.cvar,
          rowData.cvarRefTitle,
          referenceDate
        );
      },

      headerStyle: {
        fontWeight: 'bold',
        fontSize: '1.4rem',
        color: theme.palette.primary.main,
        backgroundColor: cvarStyle.backgroundColor,
      },
    },
    {
      title: '',
      field: 'cvarRef',
      cellStyle: (
        data: IRiskComparisonTableVarChanges[],
        rowData: IRiskComparisonTableVarChanges
      ) => {
        return {
          ...generateVarChangesCellStyles(
            varChangesAreas.cvar,
            rowData.cvarRef,
            referenceDate
          ),
          borderRight: '1px solid white',
        };
      },
      headerStyle: {
        backgroundColor: cvarStyle.backgroundColor,
      },
    },
    {
      title: '',
      field: 'cvarCompTitle',
      cellStyle: (
        data: IRiskComparisonTableVarChanges[],
        rowData: IRiskComparisonTableVarChanges
      ) => {
        return generateVarChangesCellStyles(
          varChangesAreas.cvar,
          rowData.cvarCompTitle,
          comparisonDate
        );
      },
      headerStyle: {
        backgroundColor: cvarStyle.backgroundColor,
      },
    },
    {
      title: '',
      field: 'cvarComp',
      cellStyle: (
        data: IRiskComparisonTableVarChanges[],
        rowData: IRiskComparisonTableVarChanges
      ) => {
        return {
          ...generateVarChangesCellStyles(
            varChangesAreas.cvar,
            rowData.cvarComp,
            comparisonDate
          ),
          borderRight: '1px solid white',
        };
      },
      headerStyle: {
        backgroundColor: cvarStyle.backgroundColor,
      },
    },
    {
      title: 'D-Var',
      field: 'dvarRefTitle',
      cellStyle: (
        data: IRiskComparisonTableVarChanges[],
        rowData: IRiskComparisonTableVarChanges
      ) => {
        return generateVarChangesCellStyles(
          varChangesAreas.dvar,
          rowData.dvarRefTitle,
          referenceDate
        );
      },
      headerStyle: {
        fontWeight: 'bold',
        fontSize: '1.4rem',
        color: theme.palette.primary.main,
        backgroundColor: dvarStyle.backgroundColor,
      },
    },
    {
      title: '',
      field: 'dvarRef',
      cellStyle: (
        data: IRiskComparisonTableVarChanges[],
        rowData: IRiskComparisonTableVarChanges
      ) => {
        return {
          ...generateVarChangesCellStyles(
            varChangesAreas.dvar,
            rowData.dvarRef,
            referenceDate
          ),
          borderRight: '1px solid white',
        };
      },
      headerStyle: {
        backgroundColor: dvarStyle.backgroundColor,
      },
    },
    {
      title: '',
      field: 'dvarCompTitle',
      cellStyle: (
        data: IRiskComparisonTableVarChanges[],
        rowData: IRiskComparisonTableVarChanges
      ) => {
        return generateVarChangesCellStyles(
          varChangesAreas.dvar,
          rowData.dvarCompTitle,
          comparisonDate
        );
      },
      headerStyle: {
        backgroundColor: dvarStyle.backgroundColor,
      },
    },
    {
      title: '',
      field: 'dvarComp',
      cellStyle: (
        data: IRiskComparisonTableVarChanges[],
        rowData: IRiskComparisonTableVarChanges
      ) => {
        return {
          ...generateVarChangesCellStyles(
            varChangesAreas.dvar,
            rowData.dvarComp,
            comparisonDate
          ),
        };
      },
      headerStyle: {
        backgroundColor: dvarStyle.backgroundColor,
      },
    },
  ];
};
