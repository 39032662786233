import { ContentState, Editor, EditorState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React from 'react';
import { Dispatch, FC } from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';

interface TextEditorTileProps {
  factSheetElement: string;
  factsSheetsData: any[];
  setfactSheetsData: Dispatch<any>;
  setHaveChangesBeenMade: Dispatch<any>;
  index: number;
}

const TextEditorTile: FC<TextEditorTileProps> = (props) => {
  // const data = localStorage.getItem(TEXT_EDITOR_ITEM);
  // const initialState = EditorState.createWithContent(ContentState.createFromText(props.text || ''));
  const {
    factSheetElement,
    factsSheetsData,
    setfactSheetsData,
    index,
    setHaveChangesBeenMade,
  } = props;

  // Create 4 different editor states for each year of the data, explaination and actions taken (These are the only values that can be edited)
  const blocksFromHTML = convertFromHTML(factSheetElement || '');
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks
  );
  // const contentState = ContentState.createFromText(factSheetElement);
  const initialState = EditorState.createWithContent(contentState);
  const [editorState, setEditorState] =
    React.useState<EditorState>(initialState);

  const handleTextChange = (updatedEditorState: EditorState) => {
    // Update data
    setEditorState(updatedEditorState);
    // Get The text from the editor state
    let text = stateToHTML(updatedEditorState.getCurrentContent());

    text = text
      .replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll('<strong>', '<b>')
      .replaceAll('</strong>', '</b>')
      .replaceAll('<em>', '<i>')
      .replaceAll('</em>', '</i>');
    // Now update the element in the overall data
    const allContent = factsSheetsData;
    // Check if the text has changes and if so indicate it
    // TODO: Look into this - after a save I think this will not behave exactly as intended
    if (allContent[index].content !== factSheetElement)
      setHaveChangesBeenMade(true);
    // Now replace in the overall array
    allContent[index].content = text;
    // Update the overall data
    setfactSheetsData(allContent);
  };

  return (
    <GridItem
      xs={12}
      card
      style={{
        marginBottom: '1rem',
      }}
    >
      <div
        style={{
          border: '0.5px solid',
          minHeight: '2rem',
          marginTop: '2rem',
          backgroundColor: 'white',
          marginBottom: '2rem',
          marginLeft: '1rem',
          marginRight: '1rem',
          fontSize: '1.5rem',
        }}
      >
        <Editor
          editorState={editorState}
          onChange={(updatedEditorState: EditorState) => {
            handleTextChange(updatedEditorState);
          }}
          stripPastedStyles={true}
          //   handleKeyCommand={handleKeyCommand}
          //   customStyleMap={styleMap}
          //   readOnly={disabled}
          // blockRendererFn={mediaBlockRenderer}
        />
      </div>
    </GridItem>
  );
};

export default TextEditorTile;
