import { useSelector } from 'react-redux';
import {
  createSpecificTitleBarComponentSelector,
  createSpecificTitleBarSelectSelector,
} from '../redux/pages/selectors';
import { TitleBarComponent } from '../types/redux/pages/PagesStore';
export const useTitleBarComponent = (selectKey: string) => {
  const relevantComponent: TitleBarComponent | null = useSelector(
    createSpecificTitleBarComponentSelector(selectKey)
  );
  return relevantComponent?.currentValue;
};

export const useTitleBarSelect = (selectKey: string) => {
  /**
   * This hook assumes that a title bar select has already been rendered, and you can use it to gain access
   * to the value of the select
   */
  const selectValueSelector = createSpecificTitleBarSelectSelector(selectKey);
  const relevantSelect = useSelector(selectValueSelector);
  return relevantSelect.length > 0 ? relevantSelect[0].currentValue : null;
};

export const useTitleBarSelectValues = (selectKey: string) => {
  /**
   * This hook assumes that a title bar select has already been rendered, and you can use it to gain access
   * to the value of the select
   */
  const selectValueSelector = createSpecificTitleBarSelectSelector(selectKey);
  const relevantSelect = useSelector(selectValueSelector);
  return relevantSelect.length > 0 ? relevantSelect[0].values : null;
};

export const useTitleBarSelectLabel = (selectKey: string) => {
  const selectValueSelector = createSpecificTitleBarSelectSelector(selectKey);
  const relevantSelect = useSelector(selectValueSelector);
  return relevantSelect.length > 0
    ? relevantSelect[0].values.find(
        (val) => val.value === relevantSelect[0].currentValue
      )?.label
    : null;
};

export default useTitleBarSelect;
