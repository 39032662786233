import { ReactElement } from 'react';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  RenderDataType,
  useOverviewSecondLevelStyles,
} from '../OverviewSecondLevelGraphs.component';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { Grid, Typography, useTheme } from '@mui/material';

interface DetailBarChartProps {
  renderData: RenderDataType;
  varFund: boolean;
}

type BarChartDataType = {
  name: string;
  exposure: number;
}[];

const buildBarChartData = (
  renderData: RenderDataType,
  varFund: boolean
): BarChartDataType => {
  const newData: BarChartDataType = [];
  renderData.position_exposure.forEach((position: any[]) => {
    newData.push({
      name: position[0],
      exposure: Math.round(position[1] * 10000) / 100,
    });
  });
  // Have to reverse the array if it's not a var fund for some reason
  if (!varFund) {
    newData.reverse();
  }
  return newData;
};

function DetailBarChart(props: DetailBarChartProps): ReactElement {
  const theme = useTheme();

  const classes = useOverviewSecondLevelStyles();

  const { width } = useWindowSize();

  return (
    <Grid
      item
      xs={props.renderData.historical_time_series.length ? 4 : 6}
      className={classes.chart}
    >
      <Typography variant="h2" className={classes.mapTitle}>
        {props.varFund ? 'Value at Risk by Issuer' : 'Gross Exposure by Issuer'}
      </Typography>
      <div className={classes.chartContainerDiv}>
        <ResponsiveContainer width="90%" height={width > 1600 ? 400 : 300}>
          <BarChart
            margin={{ top: 0, left: 40, right: 30, bottom: 50 }}
            data={buildBarChartData(props.renderData, props.varFund)}
          >
            <XAxis
              dataKey="name"
              tick={false}
              label={{
                value: 'Issuer',
                style: {
                  fill: theme.palette.grey[500],
                },
              }}
            />
            {/* interval={"preserveStartEnd"} tickCount={2} */}
            <YAxis
              label={{
                angle: -90,
                position: 'left',
                value: 'Exposure %',
                style: {
                  textAnchor: 'middle',
                  fill: theme.palette.grey[500],
                },
              }}
            />
            <Tooltip />
            {/* <Legend /> */}
            <Bar
              dataKey="exposure"
              fill={theme.palette.primary.main}
              unit="%"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Grid>
  );
}

export default DetailBarChart;
