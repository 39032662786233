import React, { ReactElement, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme } from '../../styling/theme';
import { hexToRGBA } from '../../utilities/colorUtilities';
import { percentageToTwoDecimalPlacesNoHundred } from '../../utilities/numberFormatters';
import { Typography } from '@mui/material';

interface Props {
  value: number;
  cellColor?: string;
  delayMultiplier?: number;
}

export interface FillCellStyleProps {
  height: number;
  active: boolean | undefined;
  cellColor?: string;
}
export const useFillCellStyles = makeStyles<RaptorTheme, FillCellStyleProps>(
  (theme: RaptorTheme) => ({
    container: {
      height: '5rem',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      justifyContent: 'center',
      backgroundColor: hexToRGBA('#000', 0.2),
      overflow: 'hidden',
    },
    text: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 3,

      // color: (props) => props.height < 50 ? theme.palette.grey[400] : "white",
      color: 'white',
      // color: "#0f0f0f",
      fontSize: '1.4rem',
    },

    innerContainer: {
      height: (props) => `${props.height}%`,
      position: 'absolute',
      overflow: 'hidden',
      bottom: 0,
      left: 0,
      zIndex: 1,
      width: '100%',
      transition: 'transform .3s',
      transform: (props) =>
        props.active ? 'translateY(100%)' : 'translateY(0)',
      backgroundColor: (props) => props.cellColor || theme.palette.primary.main,
    },
  })
);

function FillCell({ value, ...props }: Props): ReactElement {
  const [animationsActive, setAnimationsActive] = useState(value > 10);
  const additionalDelay = props.delayMultiplier
    ? props.delayMultiplier * 10
    : 0;
  const delay = additionalDelay;
  useEffect(() => {
    setTimeout(() => {
      setAnimationsActive(false);
    }, delay);
  }, []);
  const classes = useFillCellStyles({
    height: value,
    active: animationsActive,
    cellColor: props.cellColor,
  });
  return (
    <div id="fillOuter" className={classes.container}>
      <Typography className={classes.text} variant="h5">
        {percentageToTwoDecimalPlacesNoHundred(value)}
      </Typography>
      <div className={classes.innerContainer}>
        <div id="waterFill" className={classes.waterFill}></div>
      </div>
    </div>
  );
}

export default FillCell;
