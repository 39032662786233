import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useFetchData from '../../../../hooks/useFetchData';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import {
  addAdditionalTitleBarComponents,
  toggleTitleBarLoading,
} from '../../../../redux/pages/actions';
import { Select } from '../../../../types/redux/pages/PagesStore';
import { APPEND_NEW_SELECT_TO_TITLEBAR } from '../../../../types/redux/pages/pageTypes';
import { makeUniqueDataKey } from '../../../../utilities/urlBuilder';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import VariableMarketVolumeLiquidationCostsTables from './VariableMarketVolumeLiquidationCostTables.component';
import { Grid } from '@mui/material';

const dropDownOptions = [
  {
    label: '5 x 4% Redemptions',
    value: 'redemption_liquidation_cost_4_5',
  },
  {
    label: '1 x 20% Redemptions',
    value: 'redemption_liquidation_cost_20_1',
  },
  {
    label: 'Weekly 20% Redemptions',
    value: 'redemption_liquidation_cost_20w_5',
  },
];

const VariableMarketVolumeLiquidationCosts: FC<FundInfoComponentProps> = (
  props
) => {
  const activeDate = props.positionDate;

  const dispatch = useDispatch();
  // add a dropdown for the scenarios
  useEffect(() => {
    if (dropDownOptions) {
      dispatch(toggleTitleBarLoading(true));
      const selectsToAdd: Select[] = [
        {
          titleBarTitle: 'Select Scenario',
          titleBarKey: 'scenarioSelection',
          values: dropDownOptions,
          displayOnOverviewPage: false,
          aValueIsAlwaysSelected: true,
          currentValue: dropDownOptions[0].value,
        },
      ];
      dispatch(
        addAdditionalTitleBarComponents(
          selectsToAdd,
          APPEND_NEW_SELECT_TO_TITLEBAR
        )
      );
      dispatch(toggleTitleBarLoading(false));
    }
  }, [dropDownOptions]);

  const selectedScenario = useTitleBarSelect('scenarioSelection');

  const urlForFetch = !activeDate
    ? `most_recently_stored_report_data_agg/${props.fundId}/${selectedScenario}`
    : `stored_report_data_agg/${props.fundId}/${selectedScenario}/${activeDate}`;

  const liquidationData = useFetchData({
    keyName: makeUniqueDataKey(
      props.fundId,
      `volume_liquidation_cost_${selectedScenario}`,
      activeDate
    ),
    url: urlForFetch,
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[liquidationData]}
      customLoadingMessages={['Loading Market Liquidity Risk Data...']}
      customErrorMessage="No Data Available."
    >
      <Grid container>
        <VariableMarketVolumeLiquidationCostsTables
          data={liquidationData}
          selectedScenario={selectedScenario}
        />
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default VariableMarketVolumeLiquidationCosts;
