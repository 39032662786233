import { ColumnSizingState } from '@tanstack/react-table';
import {
  ColumnData,
  ColumnFields,
  PresetData,
  PresetExposureType,
} from '../types/column.types';

// 200px estimated padding and index - This is still hardcoded at 12 column minsize which is not ideal
const SIZE = (window.innerWidth - 200) / 12;
const MIN_SIZE = 100;

// All Preset Columns and their visibility/order
export const allPresetColumns: Record<PresetExposureType, string[]> = {
  alternativeExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.BC_EXPOSURE_PCT,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COMMITMENT,
    ColumnFields.COMMITMENT_PCT,
  ],
  generalExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.INSTRUMENT_TITLE,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.BC_EXPOSURE_PCT,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COMMITMENT,
    ColumnFields.COMMITMENT_PCT,
  ],
  amlExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.LC_EXPOSURE_PCT,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.BC_EXPOSURE_PCT,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COUNTRY_CODE,
    ColumnFields.SECTOR_NAME,
    ColumnFields.IS_LISTED,
    ColumnFields.AML_EXPOSURE,
  ],
  countryExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COUNTRY_CODE_RISK_LEVEL,
    ColumnFields.COUNTRY_CODE,
  ],
  cashExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.BC_EXPOSURE_PCT,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COMMITMENT,
    ColumnFields.COMMITMENT_PCT,
  ],
  commodityExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.BC_EXPOSURE_PCT,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COMMITMENT,
    ColumnFields.COMMITMENT_PCT,
  ],
  equityExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.SECTOR_NAME,
    ColumnFields.YIELD,
    ColumnFields.CURRENCY,
    ColumnFields.COUNTRY_CODE,
    ColumnFields.FX,
    ColumnFields.PRICE,
    ColumnFields.RISK_FACTOR,
    ColumnFields.NOTIONAL_AMOUNT,
    ColumnFields.OUTSTANDING_SECURITIES,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COMMITMENT,
    ColumnFields.COMMITMENT_PCT,
  ],
  fixedIncomeExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.COUPON,
    ColumnFields.MATURITY_DATE,
    ColumnFields.CURRENCY,
    ColumnFields.COUNTRY_CODE,
    ColumnFields.TIME_TO_MATURITY,
    ColumnFields.YIELD,
    ColumnFields.PRICE,
    ColumnFields.NOTIONAL_AMOUNT,
    ColumnFields.OUTSTANDING_SECURITIES,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.DURATION,
    ColumnFields.DV01,
    ColumnFields.CREDIT_RATING,
    ColumnFields.IS_CB,
  ],
  foreginExchangeExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.BC_EXPOSURE_PCT,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COMMITMENT,
    ColumnFields.COMMITMENT_PCT,
  ],
};

const allColumns: ColumnData[] = [
  // {
  //   field: 'all',
  //   title: '',
  //   show: false,
  //   minSize: 60,
  //   size: 60,
  //   enableResizing: false,
  //   enableColumnFilter: false,
  // },
  {
    field: 'name',
    title: 'Asset Name',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: 300,
    minSize: MIN_SIZE,
  },
  {
    field: 'isin',
    title: 'ISIN',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'instrument_title',
    title: 'Instrument Title',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'instrument_description_title',
    title: 'Instrument Description',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'asset_class',
    title: 'Asset Class',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'asset_type',
    title: 'Asset Type',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'currency',
    title: 'Currency',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },

  {
    field: 'fx',
    title: 'FX Rate',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'lc_exposure',
    title: 'Local Exposure',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'lc_exposure_pct',
    title: 'Local Exposure %',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'bc_exposure',
    title: 'Base Exposure',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'bc_exposure_pct',
    title: 'Base Exposure %',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'gross_exposure',
    title: 'Gross Exposure',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'gross_exposure_pct',
    title: 'Gross Exposure %',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'commitment',
    title: 'Commitment',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'commitment_pct',
    title: 'Commitment %',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  // {
  //   title: 'Notes',
  //   field: 'notes',
  //   show: false,
  // },
  {
    field: 'aml_exposure',
    title: 'AML Exposure',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'country_code_risk_level',
    title: 'Country Code Risk Level',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'country_code',
    title: 'Country Code',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'client_price',
    title: 'Client Price',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'credit_rating',
    title: 'Credit Rating',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'credit_type',
    title: 'Credit Type',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'da_son_exposure',
    title: 'DA SON Exposure',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'days_to_maturity',
    title: 'Days To Maturity',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  // {
  //   field: 'g_name',
  //   title: 'G Name',
  //   show: false,
  //   filterVariant: 'range',
  // },
  {
    field: 'icb_sector_name',
    title: 'ICB Sector Name',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'is_listed',
    title: 'Is Listed',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'asset_form',
    title: 'Asset Form',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'cis',
    title: 'Is Collective Investment Scheme (CIS)',
    short_title: 'CIS',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  // {
  //   title: 'Currency Code',
  //   field: 'currency_code',
  //   show: false
  // },
  // {
  //   title: 'G Key',
  //   field: 'g_key',
  //   show: false
  // },
  // {
  //   title: 'GM Id New',
  //   field: 'gm_id_new',
  //   show: false
  // },
  {
    field: 'icb_sector_id',
    title: 'ICB Sector ID',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'is_cb',
    title: 'Is Convertible Bond',
    short_title: 'CB',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'is_cds',
    title: 'Is Credit Default Swap (CDS)',
    short_title: 'CDS',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'is_etf',
    title: 'Is Exchange Traded Fund (ETF)',
    short_title: 'ETF',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'is_loan',
    title: 'Is Loan',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'is_sfi',
    title: 'Is Structured Financial Instrument (SFI)',
    short_title: 'SFI',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'maturity_date',
    title: 'Maturity Date',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'msci_sector_code',
    title: 'MSCI Sector Code',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'msci_sector_name',
    title: 'MSCI Sector Name',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'msci_sector_id',
    title: 'MSCI Sector ID',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },

  {
    field: 'notional_amount',
    title: 'Notional Amount',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'portfolio_id_name',
    title: 'Portfolio ID Name',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'price',
    title: 'Price',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'sum_of_notionals',
    title: 'Sum Of Notionals',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'sector_name',
    title: 'Sector Name',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'sector_id',
    title: 'Sector ID',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'total_issue_size',
    title: 'Total Issue Size',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'unrealised_pl',
    title: 'Unrealised P/L',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'interest_rate',
    title: 'Interest Rate',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  // {
  //   field: 'interest_type',
  //   title: 'Interest Type',
  //   show: false,
  //   filterVariant: 'select',
  //   filterFn: 'arrIncludesSome',
  // },
  {
    field: 'time_to_maturity',
    title: 'Time To Maturity',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  // {
  //   field: 'asset_cs01',
  //   title: 'Asset CS01',
  //   show: false,
  //   filterVariant: 'range',
  // },
  // {
  //   field: 'asset_dv01',
  //   title: 'Asset DV01',
  //   show: false,
  //   filterVariant: 'range',
  // },
  {
    field: 'cs01',
    title: 'CS01',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'dv01',
    title: 'DV01',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'yield',
    title: 'Yield',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'risk_factor',
    title: 'Risk Factor',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'outstanding_securities',
    title: 'Outstanding Securities',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'coupon',
    title: 'Coupon',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'duration',
    title: 'Duration',
    show: false,
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'counterparty_names',
    title: 'Counterparties',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'is_coco',
    title: 'Is Contingent Convertible',
    short_title: 'CoCo',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    field: 'is_at1',
    title: 'Is Additional Tier 1',
    short_title: 'AT1',
    show: false,
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
];

// This is to introduct state to the columns so the initial render can access the value ids
export const defaultColumnSizing: ColumnSizingState = {
  instrument_title: SIZE,
  instrument_description_title: SIZE,
  asset_class: SIZE,
  asset_type: SIZE,
  currency: SIZE,
  fx: SIZE,
  lc_exposure: SIZE,
  lc_exposure_pct: SIZE,
  bc_exposure: SIZE,
  bc_exposure_pct: SIZE,
  gross_exposure: SIZE,
  gross_exposure_pct: SIZE,
  commitment: SIZE,
  commitment_pct: SIZE,
  aml_exposure: SIZE,
  country_code_risk_level: SIZE,
  country_code: SIZE,
  client_price: SIZE,
  credit_rating: SIZE,
  credit_type: SIZE,
  da_son_exposure: SIZE,
  days_to_maturity: SIZE,
  icb_sector_name: SIZE,
  is_listed: SIZE,
  asset_form: SIZE,
  cis: SIZE,
  icb_sector_id: SIZE,
  is_cb: SIZE,
  is_cds: SIZE,
  is_etf: SIZE,
  is_loan: SIZE,
  is_sfi: SIZE,
  maturity_date: SIZE,
  msci_sector_code: SIZE,
  msci_sector_name: SIZE,
  msci_sector_id: SIZE,
  notional_amount: SIZE,
  portfolio_id_name: SIZE,
  price: SIZE,
  sum_of_notionals: SIZE,
  sector_name: SIZE,
  sector_id: SIZE,
  total_issue_size: SIZE,
  unrealised_pl: SIZE,
  interest_rate: SIZE,
  time_to_maturity: SIZE,
  cs01: SIZE,
  dv01: SIZE,
  yield: SIZE,
  risk_factor: SIZE,
  outstanding_securities: SIZE,
  coupon: SIZE,
  duration: SIZE,
  counterparty_names: SIZE,
  is_coco: SIZE,
  is_at1: SIZE,
};

function updateColumnVisibility(columns: ColumnData[], preset: string[]) {
  return columns.map((column) => {
    if (preset.includes(column.field)) {
      return { ...column, show: true };
    } else {
      return { ...column };
    }
  });
}

export const AMLPreset: PresetData = {
  id: 'aml',
  name: 'AML',
  columns: updateColumnVisibility(allColumns, allPresetColumns.amlExposure),
};

export const AlternativePreset: PresetData = {
  id: 'alternative',
  name: 'Alternative',
  columns: updateColumnVisibility(
    allColumns,
    allPresetColumns.alternativeExposure
  ),
};

export const CashPreset: PresetData = {
  id: 'cash',
  name: 'Cash ',
  columns: updateColumnVisibility(allColumns, allPresetColumns.cashExposure),
};

export const CommodityPreset: PresetData = {
  id: 'commodity',
  name: 'Commodity',
  columns: updateColumnVisibility(
    allColumns,
    allPresetColumns.commodityExposure
  ),
};

export const CountryPreset: PresetData = {
  id: 'country',
  name: 'Country',
  columns: updateColumnVisibility(allColumns, allPresetColumns.countryExposure),
};

export const EquityPreset: PresetData = {
  id: 'equity',
  name: 'Equity',
  columns: updateColumnVisibility(allColumns, allPresetColumns.equityExposure),
};

export const FixedIncomePreset: PresetData = {
  id: 'fixed_income',
  name: 'Fixed Income',
  columns: updateColumnVisibility(
    allColumns,
    allPresetColumns.fixedIncomeExposure
  ),
};

export const ForeginExchangePreset: PresetData = {
  id: 'foregin_exchange',
  name: 'Foregin Exchange',
  columns: updateColumnVisibility(
    allColumns,
    allPresetColumns.foreginExchangeExposure
  ),
};

export const GeneralPreset: PresetData = {
  id: 'general',
  name: 'General',
  columns: updateColumnVisibility(allColumns, allPresetColumns.generalExposure),
};

export const getStandardPresetWithExposureType = (
  exposureType: PresetExposureType
) => {
  switch (exposureType) {
    case 'amlExposure':
      return AMLPreset;
    case 'alternativeExposure':
      return AlternativePreset;
    case 'cashExposure':
      return CashPreset;
    case 'commodityExposure':
      return CommodityPreset;
    case 'countryExposure':
      return CountryPreset;
    case 'equityExposure':
      return EquityPreset;
    case 'fixedIncomeExposure':
      return FixedIncomePreset;
    case 'foreginExchangeExposure':
      return ForeginExchangePreset;
    case 'generalExposure':
      return GeneralPreset;
    default:
      return GeneralPreset;
  }
};
