import React, { Dispatch, FC, useEffect } from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import {
  ContentState,
  DraftHandleValue,
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import CommentEditorButton from '../Buttons/CommentEditorButton.component';

interface TableEditorTileProps {
  tileType: string;
  fundId: string | null;
  content: string;
  testSpan?: 'column' | 'full';
  formatOptions?: string;
  index: number;
  setKiidsContent: Dispatch<any>;
  kiidsContent: any[];
  setChangesMade: Dispatch<any>;
  shareClass: string;
  commentId: string;
  hasUnresolvedComments: boolean;
  setWasFieldTypeChanged: Dispatch<any>;
  setEditorTileComponents: Dispatch<any>;
  editorTileComponents: any[];
  setWasNewFieldAdded: Dispatch<any>;
}

interface TableData {
  [key: string]: TableRowData;
}
interface TableRowData {
  [key: string]: EditorState;
}

// Store the default format for different tables.
type TableType = 'table_style4' | 'table_style5' | 'table_style6';
type TableDefaultsType = {
  [key in TableType]: any[];
};
const TABLE_DEFAULTS: TableDefaultsType = {
  table_style4: [
    ['', '', ''],
    ['', '', ''],
    ['', '', ''],
    ['', '', ''],
    ['', '', ''],
    ['', '', ''],
    ['', '', ''],
    ['', '', ''],
  ],
  table_style5: [
    ['', '', ''],
    ['', '', ''],
  ],
  table_style6: [
    ['', 'The impact of the costs you pay when entering your investment.'],
    ['', 'The impact of the costs of exiting your investment when it matures.'],
    [
      '',
      'The impact of the costs of us buying and selling underlying investments for the product.',
    ],
    [
      '',
      'The impact of the costs that we take each year for managing your investments.',
    ],
    [
      '',
      'The impact of the performance fee. We take these from your investment if the product outperforms its high water mark, of (a) the highest previous net asset value per share of the series concerned at the end of a performance period; and (b) the initial issue price of the shares of that series.',
    ],
    [
      '',
      'The impact of carried interests. We do not charge carried interests to the Company.',
    ],
  ],
};

function buildTableData(data: any): TableData {
  const returnData: TableData = {};
  if (!data.length) return {};
  // Account for cases where the data is a flat array (one column table)
  if (data[0].constructor === Array) {
    // Loop over each row of the table
    data.forEach((row: any, rowIndex: number) => {
      // create an object for storing the current row's data.
      const currentRow: TableRowData = {};
      // Now Loop through each column in the row.
      row.forEach((column: any, columnIndex: number) => {
        // Create an editor state object from the cell contents.
        const blocksFromHTML = convertFromHTML(column);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks
        );
        const initialState = EditorState.createWithContent(contentState);
        // Add each column to the row.
        currentRow[`col_${columnIndex}`] = initialState;
      });
      // Add the row to the table.
      returnData[`row_${rowIndex}`] = currentRow;
    });
  } else {
    // This is the flat array case
    // Loop through each row.
    data.forEach((row: any, rowIndex: number) => {
      // Create an editor state object from the cell contents.
      const blocksFromHTML = convertFromHTML(row);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks
      );
      const initialState = EditorState.createWithContent(contentState);
      returnData[`row_${rowIndex}`] = { col_1: initialState };
    });
  }
  return returnData;
}

const OCFTableEditorTile: FC<TableEditorTileProps> = (props) => {
  // const blocksFromHTML = convertFromHTML(props.text || '');
  // const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks);
  // const initialState = EditorState.createWithContent(contentState);

  // const [editorState, setEditorState] = React.useState<EditorState>(initialState);
  const {
    fundId,
    content,
    setKiidsContent,
    kiidsContent,
    index,
    setChangesMade,
  } = props;

  // Convert the table data to an array
  const [contentState, setContentState] = React.useState(
    JSON.parse(props.content || '[]')
  );
  // Create a table data object from the array.
  const tableData = buildTableData(contentState);
  // Create an object for storing the state of each cell in the table.
  const [editorState, setEditorState] = React.useState<TableData>(tableData);
  const handleTextChange = (
    updatedEditorState: EditorState,
    rowIndex: number,
    columnIndex: number
  ) => {
    // Get the current editor state of the whole table.
    const currentEditorState = editorState;
    // Now update the cell that was changed.
    currentEditorState[`row_${rowIndex}`][`col_${columnIndex + 1}`] =
      updatedEditorState;
    // Update the overall table state.
    setEditorState(currentEditorState);

    // Convert the stateEditors to an array.
    const updatedArray: any[] = [];
    Object.keys(editorState).forEach((rowKey: string) => {
      const rowData: any[] = [];
      Object.keys(editorState[rowKey]).forEach((columnKey: string) => {
        let htmlText = stateToHTML(
          editorState[rowKey][columnKey].getCurrentContent()
        );
        htmlText = htmlText
          .replace('<p>', '')
          .replace('</p>', '')
          .replace('<strong>', '<b>')
          .replace('</strong>', '</b>')
          .replace('<em>', '<i>')
          .replace('</em>', '</i>');
        rowData.push(htmlText);
      });
      updatedArray.push(rowData);
    });
    // Now update the contentState array.
    setContentState(updatedArray);

    // Use the Content state array to update the overall state of the KIID.
    const allContent = kiidsContent;
    const currentContentAtIndex = kiidsContent[index];
    // Check if the content has changed to indicate that data can be saved.
    if (currentContentAtIndex.content !== JSON.stringify(contentState)) {
      setChangesMade(true);
    }
    currentContentAtIndex.content = JSON.stringify(contentState);
    allContent[index] = currentContentAtIndex;
  };

  const handleKeyCommand = (
    command: string,
    rowIndex: number,
    columnIndex: number
  ): DraftHandleValue => {
    // Don't allow new lines.
    if (command === 'split-block') return 'handled';
    // Get the current editor state of the whole table.
    const currentEditorState =
      editorState[`row_${rowIndex}`][`col_${columnIndex}`];
    const newState = RichUtils.handleKeyCommand(currentEditorState, command);
    if (newState) {
      const overallEditorState = editorState;
      overallEditorState[`row_${rowIndex}`][`col_${columnIndex}`] = newState;
      setEditorState(overallEditorState);
      // Convert the stateEditors to an array.
      const updatedArray: any[] = [];
      Object.keys(editorState).forEach((rowKey: string) => {
        const rowData: any[] = [];
        Object.keys(editorState[rowKey]).forEach((columnKey: string) => {
          let htmlText = stateToHTML(
            editorState[rowKey][columnKey].getCurrentContent()
          );
          htmlText = htmlText
            .replace('<p>', '')
            .replace('</p>', '')
            .replace('<strong>', '<b>')
            .replace('</strong>', '</b>')
            .replace('<em>', '<i>')
            .replace('</em>', '</i>');
          rowData.push(htmlText);
        });
        updatedArray.push(rowData);
      });
      // Now update the contentState array.
      setContentState(updatedArray);

      // Use the Content state array to update the overall state of the KIID.
      const allContent = kiidsContent;
      const currentContentAtIndex = kiidsContent[index];
      currentContentAtIndex.data = JSON.stringify(contentState);
      allContent[index] = currentContentAtIndex;
      return 'handled';
    }
    return 'not-handled';
  };

  // This Section of code is used to set default values when the field type is changed to a table.
  // I will also force a rerender when one table type is changed to another.
  const refreshEditorState = (defaultContentState: any) => {
    // console.log("REFRESHING EDITOR");
    // // Get the current editor state of the whole table.
    // const currentEditorState = editorState;
    // // Now update the cell that was changed.
    // currentEditorState['row_0']['col_0'] = EditorState.createEmpty();
    // setEditorState(currentEditorState);
    // return editorState;
    // Create a table data object from the array.
    const tableData = buildTableData(defaultContentState);
    setContentState(defaultContentState);
    // Use the Content state array to update the overall state of the KIID.
    const allContent = kiidsContent;
    const currentContentAtIndex = kiidsContent[index];
    currentContentAtIndex.content = JSON.stringify(defaultContentState);
    allContent[index] = currentContentAtIndex;
    // Create an object for storing the state of each cell in the table.
    setEditorState(tableData);
  };

  useEffect(() => {
    if (
      !props.content.length &&
      ['table_style4', 'table_style5', 'table_style6'].includes(props.tileType)
    ) {
      refreshEditorState(TABLE_DEFAULTS[props.tileType as TableType]);
    }
  }, [props.tileType]);

  return (
    <GridItem xs={12} card style={{ marginBottom: '1rem' }}>
      <h1>OCF</h1>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            marginTop: '0.5rem',
            marginRight: '1rem',
          }}
        >
          {/* <ChangeFieldTypeButton
            fieldType={props.tileType}
            setKiidsContent={props.setKiidsContent}
            kiidsContent={props.kiidsContent}
            index={props.index}
            setChangesMade={props.setChangesMade}
            setWasFieldTypeChanged={props.setWasFieldTypeChanged}
          /> */}
          <CommentEditorButton
            fundId={props.fundId}
            shareClass={props.shareClass}
            commentId={props.commentId}
            kidIndex={props.index}
            hasUnresolvedComments={props.hasUnresolvedComments}
            setKiidsContent={props.setKiidsContent}
            kiidsContent={props.kiidsContent}
          />
          {/* <RemoveFieldButton
            index={index}
            setKiidsContent={props.setKiidsContent}
            kiidsContent={props.kiidsContent}
            setChangesMade={props.setChangesMade}
            setWasFieldTypeChanged={props.setWasFieldTypeChanged}
            setWasNewFieldAdded={props.setWasNewFieldAdded}
            setEditorTileComponents={props.setEditorTileComponents}
            editorTileComponents={props.editorTileComponents}
          /> */}
        </div>
        <div
          style={{
            padding: '1rem',
            fontSize: '1.5rem',
            minHeight: '10rem',
            display: 'flex',
            justifyContent: 'space-around',
            marginBottom: '1rem',
          }}
        >
          <TableEditor
            tableContents={editorState}
            handleChange={handleTextChange}
            handleKeyCommand={handleKeyCommand}
          />
        </div>
      </div>
    </GridItem>
  );
};

interface TableEditorProps {
  tableContents: TableData;
  handleChange: (
    updatedEditorState: EditorState,
    rowIndex: number,
    ColumnIndex: number
  ) => void;
  handleKeyCommand: (
    command: string,
    rowIndex: number,
    columnIndex: number
  ) => DraftHandleValue;
}

const TableEditor: FC<TableEditorProps> = (props) => {
  const { tableContents, handleChange, handleKeyCommand } = props;

  return (
    <>
      <table>
        {
          // Go through each row of the table
          Object.keys(tableContents).map((rowKey: string, rowIndex: number) => {
            // Go through each column in the row.
            return (
              <tr key={`row_number_${rowIndex}`}>
                {
                  // Go through each column in the row.
                  Object.keys(tableContents[rowKey]).map(
                    (columnKey: string, columnIndex) => {
                      // Add column to table.
                      return (
                        <td
                          key={`row_number_${rowIndex}_column_number_${columnIndex}`}
                        >
                          <div
                            style={{
                              border: '0.5px solid',
                              // width: '30rem',
                              minWidth: '5rem',
                              // marginTop: '0.5rem'
                            }}
                          >
                            <Editor
                              editorState={tableContents[rowKey][columnKey]}
                              onChange={(updatedEditorState: EditorState) => {
                                handleChange(
                                  updatedEditorState,
                                  rowIndex,
                                  columnIndex
                                );
                              }}
                              stripPastedStyles={true}
                              handleKeyCommand={(
                                command: string,
                                editorState: EditorState,
                                eventTimeStamp: number
                              ) => {
                                return handleKeyCommand(
                                  command,
                                  rowIndex,
                                  columnIndex
                                );
                              }}
                              // blockRendererFn={mediaBlockRenderer}
                            />
                          </div>
                        </td>
                      );
                    }
                  )
                }
              </tr>
            );
          })
        }
      </table>
    </>
  );
};

export default OCFTableEditorTile;
