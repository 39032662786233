import React, { Dispatch, FC, useEffect } from 'react';
import { PriipsKidsData } from '../PriipsKidsEditor.component';
import TextEditorTile from './TextEditorTile.component';
import AddFieldButton from '../../buttons/AddFieldbutton.component';
import { v4 as uuidv4 } from 'uuid';
import TemplateTextEditorTile from './TemplateTextEditorTile.component';
import SRIChartEditorTile from './SRIChartEditorTile.component';
import PageBreakEditorTile from './PageBreakEditorTile.component';
import PerformanceScenariosEditorTile from './PerformanceScenarioTableEditorTile.component';
import CostsOverTimeTableEditorTile from './CostsOverTimeTableEditorTile.component';
import CompositionOfCostsTableEditorTile from './CompositionOfCostsTableEditorTile.component';
import Raptor2Loading from '../../../../../feedback/Raptor2Loading';

interface PriipsKidsEditorColumnProps {
  priipsKidsData: PriipsKidsData[];
  setPriipsKidsContent: Dispatch<any>;
  filter: string;
}
// The editor tile props are stored here as they are generalised for each type of editor tile.
// This allows us to only need to edit in one place if props need to be updated.
export interface EditorTileProps {
  // Contains all the infor required for deciding what editor tile to render
  priipsKidsElement: PriipsKidsData;
  // Allows us to edit the overall list of the priips kids data.
  setPriipsKidsContent: Dispatch<any>;
  // Allows us to update the field type of the element at the specified index.
  updateFieldType: (
    index: number,
    newFieldType: string,
    defualtText: string
  ) => void;
  // Allows us to move the element at the specified index up or down in the list of priips kids data.
  moveField: (index: number, direction: 'up' | 'down') => void;
  // Allows us to delete a new field to the list of priips kids data.
  deleteField: (fieldId: string) => void;
}
interface GeneralEditorTileProps {
  // Contains all the infor required for deciding what editor tile to render
  priipsKidsElement: PriipsKidsData;
  // Allows us to edit the overall list of the priips kids data.
  setPriipsKidsContent: Dispatch<any>;
  // Allows us to update the field type of the element at the specified index.
  updateFieldType: (
    index: number,
    newFieldType: string,
    defualtText: string
  ) => void;
  // Allows us to move the element at the specified index up or down in the list of priips kids data.
  moveField: (index: number, direction: 'up' | 'down') => void;
  // Allows us to add a new field to the list of priips kids data.
  addField: (fieldId: string) => void;
  // Allows us to delete a new field to the list of priips kids data.
  deleteField: (fieldId: string) => void;
  // If there is a filter set then we don't want to allow new fields to be added.
  filter: string;
}

const filterMap: { [key: string]: string } = {
  'No Filter': '',
  ManCo: 'manco',
  'Sub Fund': 'sub_fund',
  'Fund Manager': 'fund_manager',
};

// This is a generalised editor tile component that will decide what editor tile to render based on the field type.
const GeneralEditorTile: FC<GeneralEditorTileProps> = ({
  priipsKidsElement,
  setPriipsKidsContent,
  updateFieldType,
  moveField,
  addField,
  deleteField,
  filter,
}: GeneralEditorTileProps) => {
  // State used for deciding what editor tile to render (will be updated when the field type select is changed)
  const [fieldType, setFieldType] = React.useState<String>(
    priipsKidsElement.tag
  );
  const [kiidIndex, setKiidIndex] = React.useState<number>(
    priipsKidsElement.kiidIndex
  );
  const fieldId = priipsKidsElement.fieldId;
  // State used for storing the editor tile that will be rendered
  const [editorTile, setEditorTile] = React.useState<JSX.Element>(<></>);
  // This function will update the field type state and also update the overall priips kids data.
  function handleFieldTypeChange(
    index: number,
    newFieldType: string,
    defualtText: string
  ) {
    setFieldType(newFieldType);
    updateFieldType(index, newFieldType, defualtText);
  }
  function handleMoveField(index: number, direction: 'up' | 'down') {
    moveField(index, direction);
    if (direction === 'up') {
      setKiidIndex(index - 1);
    } else {
      setKiidIndex(index + 1);
    }
  }
  // Update the editor tile whenever the field type changes
  useEffect(() => {
    switch (fieldType) {
      case 'text_full':
        // Additional check to see if the content is a template field
        // If it is then we need to render the template editor tile
        if (priipsKidsElement.templateId && priipsKidsElement.templateType) {
          setEditorTile(
            <div
              key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
            >
              <TemplateTextEditorTile
                priipsKidsElement={priipsKidsElement}
                setPriipsKidsContent={setPriipsKidsContent}
                updateFieldType={handleFieldTypeChange}
                moveField={handleMoveField}
                deleteField={deleteField}
              />
              {filter === 'No Filter' &&
              priipsKidsElement.documentLanguage !== 'Swiss English' ? (
                <AddFieldButton addField={addField} fieldId={fieldId} />
              ) : null}
            </div>
          );
        } else {
          // Otherwise just use the regular text editor tile
          setEditorTile(
            <div
              key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
            >
              <TextEditorTile
                priipsKidsElement={priipsKidsElement}
                setPriipsKidsContent={setPriipsKidsContent}
                updateFieldType={handleFieldTypeChange}
                moveField={handleMoveField}
                deleteField={deleteField}
              />
              {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
                <AddFieldButton addField={addField} fieldId={fieldId} />
              ) : null}
            </div>
          );
        }

        break;
      case 'text_col':
        // Additional check to see if the content is a template field
        // If it is then we need to render the template editor tile
        if (priipsKidsElement.templateId && priipsKidsElement.templateType) {
          setEditorTile(
            <div
              key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
            >
              <TemplateTextEditorTile
                priipsKidsElement={priipsKidsElement}
                setPriipsKidsContent={setPriipsKidsContent}
                updateFieldType={handleFieldTypeChange}
                moveField={handleMoveField}
                deleteField={deleteField}
              />
              {filter === 'No Filter' &&
              priipsKidsElement.documentLanguage !== 'Swiss English' ? (
                <AddFieldButton addField={addField} fieldId={fieldId} />
              ) : null}
            </div>
          );
        } else {
          // Otherwise just use the regular text editor tile
          setEditorTile(
            <div
              key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
            >
              <TextEditorTile
                priipsKidsElement={priipsKidsElement}
                setPriipsKidsContent={setPriipsKidsContent}
                updateFieldType={handleFieldTypeChange}
                moveField={handleMoveField}
                deleteField={deleteField}
              />
              {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
                <AddFieldButton addField={addField} fieldId={fieldId} />
              ) : null}
            </div>
          );
        }

        break;
      case 'section_header':
        // Header will use th same editor component as text_full
        // Additional check to see if the content is a template field
        // If it is then we need to render the template editor tile
        if (priipsKidsElement.templateId && priipsKidsElement.templateType) {
          setEditorTile(
            <div
              key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
            >
              <TemplateTextEditorTile
                priipsKidsElement={priipsKidsElement}
                setPriipsKidsContent={setPriipsKidsContent}
                updateFieldType={handleFieldTypeChange}
                moveField={handleMoveField}
                deleteField={deleteField}
              />
              {filter === 'No Filter' &&
              priipsKidsElement.documentLanguage !== 'Swiss English' ? (
                <AddFieldButton addField={addField} fieldId={fieldId} />
              ) : null}
            </div>
          );
        } else {
          // Otherwise just use the regular text editor tile
          setEditorTile(
            <div
              key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
            >
              <TextEditorTile
                priipsKidsElement={priipsKidsElement}
                setPriipsKidsContent={setPriipsKidsContent}
                updateFieldType={handleFieldTypeChange}
                moveField={handleMoveField}
                deleteField={deleteField}
              />
              {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
                <AddFieldButton addField={addField} fieldId={fieldId} />
              ) : null}
            </div>
          );
        }
        break;
      case 'srri_chart':
        setEditorTile(
          <div
            key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
          >
            <SRIChartEditorTile
              priipsKidsElement={priipsKidsElement}
              setPriipsKidsContent={setPriipsKidsContent}
              updateFieldType={handleFieldTypeChange}
              moveField={handleMoveField}
              deleteField={deleteField}
            />
            {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
              <AddFieldButton addField={addField} fieldId={fieldId} />
            ) : null}
          </div>
        );
        break;
      case 'page_break':
        setEditorTile(
          <div
            key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
          >
            <PageBreakEditorTile
              priipsKidsElement={priipsKidsElement}
              setPriipsKidsContent={setPriipsKidsContent}
              updateFieldType={handleFieldTypeChange}
              moveField={handleMoveField}
              deleteField={deleteField}
            />
            {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
              <AddFieldButton addField={addField} fieldId={fieldId} />
            ) : null}
          </div>
        );
        break;
      case 'table_style4':
        setEditorTile(
          <div
            key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
          >
            <PerformanceScenariosEditorTile
              priipsKidsElement={priipsKidsElement}
              setPriipsKidsContent={setPriipsKidsContent}
              updateFieldType={handleFieldTypeChange}
              moveField={handleMoveField}
              deleteField={deleteField}
            />
            {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
              <AddFieldButton addField={addField} fieldId={fieldId} />
            ) : null}
          </div>
        );
        break;
      case 'table_style5':
        setEditorTile(
          <div
            key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
          >
            <CostsOverTimeTableEditorTile
              priipsKidsElement={priipsKidsElement}
              setPriipsKidsContent={setPriipsKidsContent}
              updateFieldType={handleFieldTypeChange}
              moveField={handleMoveField}
              deleteField={deleteField}
            />
            {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
              <AddFieldButton addField={addField} fieldId={fieldId} />
            ) : null}
          </div>
        );
        break;
      case 'table_style6':
        setEditorTile(
          <div
            key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
          >
            <CompositionOfCostsTableEditorTile
              priipsKidsElement={priipsKidsElement}
              setPriipsKidsContent={setPriipsKidsContent}
              updateFieldType={handleFieldTypeChange}
              moveField={handleMoveField}
              deleteField={deleteField}
            />
            {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
              <AddFieldButton addField={addField} fieldId={fieldId} />
            ) : null}
          </div>
        );
        break;
      default:
        setEditorTile(
          <div
            key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
          >
            <TextEditorTile
              priipsKidsElement={priipsKidsElement}
              setPriipsKidsContent={setPriipsKidsContent}
              updateFieldType={handleFieldTypeChange}
              moveField={moveField}
              deleteField={deleteField}
            />
            {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
              <AddFieldButton addField={addField} fieldId={fieldId} />
            ) : null}
          </div>
        );
        break;
    }
  }, [fieldType]);
  return (
    <div key={`editorTile${priipsKidsElement.kiidIndex}_${fieldType}`}>
      {editorTile}
    </div>
  );
};

const reIndexKiidContent = (
  content: PriipsKidsData[],
  kiidIndex: number
): any[] => {
  // Create an iterator index for the data
  let iteratorIndex = kiidIndex;
  // Now loop over each element in the array from index to len(array) and increment each kiid_index by 1
  while (iteratorIndex < content.length) {
    content[iteratorIndex].kiidIndex = iteratorIndex + 1;
    iteratorIndex++;
  }

  return content;
};

const PriipsKidsEditorColumn: FC<PriipsKidsEditorColumnProps> = ({
  priipsKidsData,
  setPriipsKidsContent,
  filter,
}: PriipsKidsEditorColumnProps) => {
  // This function will be used to update the field type of element at the specified index when the select is updated.
  // Content will also be set to some defualt value for the new field type.
  function updateFieldType(
    index: number,
    newFieldType: string,
    defualtText: string
  ) {
    const updatedPriipsKidsData: PriipsKidsData[] = [...priipsKidsData];
    updatedPriipsKidsData[index].tag = newFieldType;
    updatedPriipsKidsData[index].content = defualtText;
    setPriipsKidsContent([...updatedPriipsKidsData]);
  }
  // This function will be used to move a field up or down in the list of priips kids data.
  function moveField(index: number, direction: 'up' | 'down') {
    // make a copy of the priips kids data
    const updatedPriipsKidsData: PriipsKidsData[] = [...priipsKidsData];
    // get the element that is being moved
    const tempElement: PriipsKidsData = JSON.parse(
      JSON.stringify(updatedPriipsKidsData[index])
    );
    // Swap the element with the element above or below it
    // If the element is at the top or bottom of the list then do nothing
    if (direction === 'up' && index > 0) {
      updatedPriipsKidsData[index] = updatedPriipsKidsData[index - 1];
      updatedPriipsKidsData[index - 1] = tempElement;
      // Update the kiid index of the elements that have been moved
      updatedPriipsKidsData[index].kiidIndex = index;
      updatedPriipsKidsData[index - 1].kiidIndex = index - 1;
    } else if (
      direction === 'down' &&
      index < updatedPriipsKidsData.length - 1
    ) {
      updatedPriipsKidsData[index] = updatedPriipsKidsData[index + 1];
      updatedPriipsKidsData[index + 1] = tempElement;
      // Update the kiid index of the elements that have been moved
      updatedPriipsKidsData[index].kiidIndex = index;
      updatedPriipsKidsData[index + 1].kiidIndex = index + 1;
    }
    // Update the overall priips kids data
    setPriipsKidsContent([...updatedPriipsKidsData]);
  }
  // This function will be used to add a new field to the list of priips kids data.
  function addField(fieldId: string) {
    // Make a copy of the priips kids data
    const updatedPriipsKidsData: PriipsKidsData[] = JSON.parse(
      JSON.stringify(priipsKidsData)
    );
    // If the field Id is an empty string then we add it to the beginning of the list
    // Otherwise we add it after the field with the corresponding field id
    const index = updatedPriipsKidsData.findIndex(
      (element: PriipsKidsData) => element.fieldId === fieldId
    );
    // Create a new field
    const newField: PriipsKidsData = {
      formatOptions: '',
      mancoId: priipsKidsData[0].mancoId,
      tag: 'text_full',
      content: '',
      version: priipsKidsData[0].version,
      editedByName: priipsKidsData[0].editedByName,
      editTimestamp: priipsKidsData[0].editTimestamp,
      editedBy: priipsKidsData[0].editedBy,
      fundName: priipsKidsData[0].fundName,
      commentId: null,
      fieldId: uuidv4(),
      publishedBy: priipsKidsData[0].publishedBy,
      kiidId: priipsKidsData[0].kiidId,
      kiidIndex: index + 1,
      shareClassName: priipsKidsData[0].shareClassName,
      shareClass: priipsKidsData[0].shareClass,
      fundIdString: priipsKidsData[0].fundIdString,
      hasUnresolvedComment: false,
      documentLanguage: priipsKidsData[0].documentLanguage,
      publishedByName: priipsKidsData[0].publishedByName,
      fundId: priipsKidsData[0].fundId,
      isPublished: false,
    };

    // Re-INdex the data before we insert the new field.
    const reIndexedContent = reIndexKiidContent(
      updatedPriipsKidsData,
      index + 1
    );
    // Insert the new field into the list of priips kids data
    reIndexedContent.splice(index + 1, 0, newField);
    // Update the overall priips kids data
    setPriipsKidsContent([...reIndexedContent]);
  }
  // This function will be used to delete a field from the list of priips kids data.
  function deleteField(fieldId: string) {
    // make a copy of the priips kids data
    const updatedPriipsKidsData: PriipsKidsData[] = [...priipsKidsData];
    // Delete the element from the list with the corresponding field id
    const index = updatedPriipsKidsData.findIndex(
      (element: PriipsKidsData) => element.fieldId === fieldId
    );
    // Re-INdex the data before we delete the field.
    const reIndexedContent = reIndexKiidContent(updatedPriipsKidsData, index);
    // Delete the field from the list of priips kids data
    reIndexedContent.splice(index, 1);
    // Update the overall priips kids data
    setPriipsKidsContent([...reIndexedContent]);
  }

  // This function will be used to delete a field from the list of priips kids data.
  // function deleteField(index: number) {
  //     // Make a copy of the priips kids data
  //     const updatedPriipsKidsData: PriipsKidsData[] = JSON.parse(JSON.stringify(priipsKidsData));
  //     // Re-INdex the data before we delete the field.
  //     const reIndexedContent = reIndexKiidContent(updatedPriipsKidsData, index);
  //     // Delete the field from the list of priips kids data
  //     reIndexedContent.splice(index, 1);
  //     // Update the overall priips kids data
  //     setPriipsKidsContent([...reIndexedContent]);
  // }

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      {priipsKidsData.length > 0 &&
        filter === 'No Filter' &&
        priipsKidsData[0].documentLanguage !== 'Swiss English' && (
          <AddFieldButton addField={addField} fieldId={''} />
        )}

      {priipsKidsData.length > 0 ? (
        priipsKidsData
          .filter((priipsKidsElement: PriipsKidsData) => {
            const filterValue = filterMap[filter];
            if (filterValue === '') {
              return true;
            } else {
              // First check if the field is a template field
              if (priipsKidsElement.templateType) {
                // If it is then we need to check if the template field is the same as the filter
                // If it is then we need to render the editor tile
                if (priipsKidsElement.templateType === filterValue) {
                  return true;
                } else {
                  // Otherwise we don't render the editor tile
                  return false;
                }
              } else {
                // Otherwise it is a different type of template and we do not want to render it
                return false;
              }
            }
          })
          .map((priipsKidsElement: PriipsKidsData, index) => {
            return (
              <GeneralEditorTile
                key={`editorTile${index}_${priipsKidsElement.fieldId}`}
                priipsKidsElement={priipsKidsElement}
                setPriipsKidsContent={setPriipsKidsContent}
                updateFieldType={updateFieldType}
                moveField={moveField}
                addField={addField}
                deleteField={deleteField}
                filter={filter}
              />
            );
          })
      ) : (
        <div
          style={{
            justifyContent: 'space-around',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Raptor2Loading messages={['Loading Editor...']} />
        </div>
      )}
    </div>
  );
};

export default PriipsKidsEditorColumn;
