import { IRiskComparisonTableExposureChanges } from './exposureChangesTable.data';
import { IRiskComparisonTablePositionChangesData } from './positionChangesTable.data';
import { IRiskComparisonTableVarChanges } from './varChangesTable.data';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import {
  addCommasToNumbers,
  toTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';

// const sectionsMap = ['position', 'exposure', 'risk'];
// we use this sections map because the selected section comes in as an integer

type IRiskComparisonDataType =
  | IRiskComparisonTableExposureChanges
  | IRiskComparisonTablePositionChangesData
  | IRiskComparisonTableVarChanges;

// interface IRiskAnalysisData extends DataObject {
//   data: [
//     {
//       cvar: any;
//       dvar: any;
//       ne: any;
//       var: any;
//     }
//   ];
// }
function buildComparisonData(
  currentlySelectedSectionAsInteger: string,
  changeData: DataObject | null,
  referenceDate: string,
  comparisonDate: string
): IRiskComparisonDataType[] {
  if (!changeData) return [];
  if (changeData.isFetching || changeData.error) {
    return [];
  }
  const selectedSection = currentlySelectedSectionAsInteger;
  const returnData: IRiskComparisonDataType[] = [];
  switch (selectedSection) {
    case 'exposureChanges':
      changeData.data[0].forEach((row: any, index: number) => {
        returnData.push({
          number: index + 1,
          name: row[1],
          class: row[2],
          type: row[3],
          currency: row[4],
          localCurrencyExposure_pc_ref: row[5],
          baseCurrencyExposure_pc_ref: row[6],
          grossCurrencyExposure_pc_ref: row[7],
          localCurrencyExposure_pc_comp: row[8],
          baseCurrencyExposure_pc_comp: row[9],
          grossCurrencyExposure_pc_comp: row[10],
          localCurrencyExposure_pc_diff: row[11],
          baseCurrencyExposure_pc_diff: row[12],
          grossCurrencyExposure_pc_diff: row[13],
        });
      });

      returnData.unshift({
        number: '',
        name: '',
        class: '',
        type: '',
        currency: '',
        localCurrencyExposure_pc_ref: '',
        baseCurrencyExposure_pc_ref: referenceDate,
        grossCurrencyExposure_pc_ref: '',
        localCurrencyExposure_pc_comp: '',
        baseCurrencyExposure_pc_comp: comparisonDate,
        grossCurrencyExposure_pc_comp: '',
        localCurrencyExposure_pc_diff: '',
        baseCurrencyExposure_pc_diff: 'Difference',
        grossCurrencyExposure_pc_diff: '',
      });

      break;

    case 'varChanges':
      changeData.data[0].ne.forEach((row: any, index: number) => {
        returnData.push({
          number: index > 0 ? index : '',
          netExposureRef_Title: row[0],
          netExposureRef: row[1],
          netExposureComp_Title: row[2],
          netExposureComp: row[3],
          varRef: '',
          varRefTitle: '',
          varCompTitle: '',
          varComp: '',
          cvarRefTitle: '',
          cvarRef: '',
          cvarCompTitle: '',
          cvarComp: '',
          dvarRefTitle: '',
          dvarRef: '',
          dvarCompTitle: '',
          dvarComp: '',
        });
      });

      changeData.data[0].var.forEach((row: any, index: number) => {
        returnData[index] = {
          ...returnData[index],
          varRefTitle: row[0],
          varRef: row[1],
          varCompTitle: row[2],
          varComp: row[3],
        };
      });
      changeData.data[0].cvar.forEach((row: any, index: number) => {
        returnData[index + 1] = {
          ...returnData[index + 1],
          cvarRefTitle: row[0],
          cvarRef: row[1],
          cvarCompTitle: row[2],
          cvarComp: row[3],
        };
      });
      changeData.data[0].dvar.forEach((row: any, index: 0) => {
        returnData[index + 1] = {
          ...returnData[index + 1],
          dvarRefTitle: row[0],
          dvarRef: row[1],
          dvarCompTitle: row[2],
          dvarComp: row[3],
        };
      });

      returnData.unshift({
        number: 'Date',
        netExposureRef_Title: referenceDate,
        netExposureRef: '',
        netExposureComp_Title: comparisonDate,
        netExposureComp: '',
        varRefTitle: referenceDate,
        varRef: '',
        varCompTitle: comparisonDate,
        varComp: '',
        cvarRefTitle: referenceDate,
        cvarRef: '',
        cvarCompTitle: comparisonDate,
        cvarComp: '',
        dvarRefTitle: referenceDate,
        dvarRef: '',
        dvarCompTitle: comparisonDate,
        dvarComp: '',
      });

      break;

    case 'positionChanges':
      changeData.data[0].forEach((positionChangeDatum: any, index: number) => {
        returnData.push({
          number: index + 1,
          genericMarket: positionChangeDatum.gm_name,
          instrument: positionChangeDatum.instrument_type
            ? positionChangeDatum.instrument_type
            : 'Unspecified',
          info: positionChangeDatum.expiry_date,
          positionReference: positionChangeDatum.reference_mr_position
            ? addCommasToNumbers(positionChangeDatum.reference_mr_position)
            : 0,
          comparisonPosition: addCommasToNumbers(
            positionChangeDatum.mr_position
          ),
          market: toTwoDecimalPlaces(positionChangeDatum.used_underlying_price),
          risk: Math.round(positionChangeDatum.risk_metric_value),
          portfolio: 'N/A',
          status: positionChangeDatum.status,
        });
      });
      break;
    default:
      return [];
  }
  return returnData;
}

export default buildComparisonData;
