import countryCodesToNames from './countryCodesToNames.json';

// convert given country code to name
export const countryCodeToName = (code: string): string => {
  const mapping: any = countryCodesToNames;
  return mapping[code] ?? code;
};

export const currency_symbols_map: { [index: string]: string } = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
  CHF: '₣', // Swiss Franc
  SEK: 'kr', // Swedish Krona
  DKK: 'kr.', // Danish Krone
  NOK: 'kr', // Norwegian Krone
};
