import React from 'react';
import CustomTable from '../../../../../tables/CustomTable';
import makeStyles from '@mui/styles/makeStyles';
import { mainColors } from '../../../../../../styling/theme';
import GeneralCard from '../../../../../general/GeneralCard';

const useStyles = makeStyles({
  container: {
    margin: '2em',
    minWidth: '40rem',
  },
  assetTable: {
    padding: '2rem',
    '& th, td': {
      padding: '1em',
      textAlign: 'center',
    },
    '& tr:nth-of-type(even)': {
      backgroundColor: mainColors.lightGrey,
    },
  },
});

const columns = [
  { title: 'Maturity', field: 'matur', type: 'numeric' as const },
  { title: 'Par', field: 'par', type: 'numeric' as const },
  { title: 'Spot', field: 'spot', type: 'numeric' as const },
  { title: 'Forward', field: 'forward', type: 'numeric' as const },
];

const table_data = [
  { matur: 0.25, par: 3.2642, spot: 3.3043, forward: 3.3043 },
  { matur: 0.5, par: 3.0417, spot: 3.0648, forward: 2.8258 },
  { matur: 1, par: 2.7032, spot: 2.7032, forward: 2.3428 },
  { matur: 2, par: 2.318, spot: 2.3136, forward: 1.9255 },
  { matur: 3, par: 2.1587, spot: 2.1525, forward: 1.8309 },
  { matur: 4, par: 2.1431, spot: 2.1385, forward: 1.854 },
  { matur: 5, par: 2.1275, spot: 2.1228, forward: 2.0782 },
  { matur: 6, par: 2.1741, spot: 2.1728, forward: 2.2755 },
  { matur: 7, par: 2.2207, spot: 2.2227, forward: 2.4729 },
  { matur: 8, par: 2.2548, spot: 2.2639, forward: 2.6137 },
  { matur: 9, par: 2.3171, spot: 2.3283, forward: 2.7071 },
  { matur: 10, par: 2.3795, spot: 2.3956, forward: 2.8004 },
  { matur: 15, par: 2.4995, spot: 2.5357, forward: 2.8342 },
  { matur: 20, par: 2.5858, spot: 2.6307, forward: 2.8664 },
  { matur: 25, par: 2.5214, spot: 2.5053, forward: 2.4687 },
  { matur: 30, par: 2.457, spot: 2.4438, forward: 2.0709 },
];

const YieldTable: React.FC = () => {
  const classes = useStyles();

  return (
    <GeneralCard className={classes.container}>
      <CustomTable
        showToolbar={false}
        columns={columns}
        data={table_data}
        tableRootStyles={classes.assetTable}
        options={{
          maxBodyHeight: '600px',
          draggable: false,
          paging: false,
        }}
      />
    </GeneralCard>
  );
};

export default React.memo(YieldTable);
