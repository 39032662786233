import { CSSProperties } from 'react';
import { mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import { mapStatusToColor } from '../../../../utilities/colorUtilities';
import { specifyStatus } from '../../../../utilities/specfyStatus';

export interface SrriStatusData {
  zeroToPointFive: string;
  pointFiveToTwo: string;
  twoToFive: string;
  fiveToTen: string;
  tenToFifteen: string;
  fifteenToTwentyFive: string;
  twentyFiveUp: string;
}

/**
 * The titles are empty because we hide the header
 */
export const srriStatusColumns: CustomColumn<SrriStatusData>[] = [
  {
    field: 'zeroToPointFive',
    title: '1',
  },
  {
    field: 'pointFiveToTwo',
    title: '2',
  },
  {
    field: 'twoToFive',
    title: '3',
  },
  {
    field: 'fiveToTen',
    title: '4',
  },
  {
    field: 'tenToFifteen',
    title: '5',
  },
  {
    field: 'fifteenToTwentyFive',
    title: '6',
  },
  {
    field: 'twentyFiveUp',
    title: '7',
  },
];
/**
 *
 * @param srriData - a data object
 * @returns
 */

const volatilityMap = {
  zeroToPointFive: {
    lower: 0,
    upper: 0.5,
    label: 'O.O% <= SRRI < 0.5%',
  },
  pointFiveToTwo: {
    lower: 0.5,
    upper: 2,
    label: 'O.5% <= SRRI < 2.0%',
  },
  twoToFive: {
    lower: 2,
    upper: 5,
    label: '2.0% <= SRRI < 5.0%',
  },
  fiveToTen: {
    lower: 5,
    upper: 10,
    label: '5.0% <= SRRI < 10.0%',
  },
  tenToFifteen: {
    lower: 10,
    upper: 15,
    label: '10.0% <= SRRI < 15.0%',
  },
  fifteenToTwentyFive: {
    lower: 15,
    upper: 25,
    label: '15.0% <= SRRI < 25.0%',
  },
  twentyFiveUp: {
    lower: 25,
    upper: Infinity,
    label: '25.0% <= SRRI',
  },
};
// export function generateStatusBoxData(
//   srriData: DataObject
// ): [SrriStatusData[], CustomColumn<SrriStatusData>[]] {
//   console.log("HERE IS SRRI STATUS DATA", srriData);
//   if (!srriData || srriData.isFetching) {
//     return [[], []];
//   }
//   try {
//     if (srriData.error) {
//       throw new Error('Error generating SRRI data');
//     }
//     // const { weekly_volatility: volatility, status } = srriData.data[0];
//     // const volatility = "weekly_volatility" in srriData.data[0] ? srriData.data[0].daily_volatility : srriData.data[0].data1.daily_volatility[0];
//     // const volatility = "weekly_volatility" in srriData.data[0] ? srriData.data[0].weekly_volatility : srriData.data[0].data1.weekly_volatility[0];
//     const status = "status" in srriData.data[0] ? srriData.data[0].status : srriData.data[0].data2.status;
//     const srriBucket = srriData.data[0].data2.limits[2];

//     const returnObj: any = {};
//     Object.keys(volatilityMap).forEach((volatilityKey) => {
//       returnObj[volatilityKey as keyof SrriStatusData] =
//         volatilityMap[volatilityKey as keyof SrriStatusData].label;
//     });

//     const columns = srriStatusColumns.map((col) => {
//       const { lower, upper } = volatilityMap[col.field as keyof SrriStatusData];
//       // const isActiveVolatility = volatility >= lower && volatility < upper;
//       const isActiveVolatility = col.title === srriBucket.toString();
//       return {
//         ...col,
//         cellStyle: {
//           textAlign: 'center' as CSSProperties['textAlign'],
//           backgroundColor: isActiveVolatility
//             ? mapStatusToColor(specifyStatus(status))
//             : undefined,
//           color: isActiveVolatility ? 'white' : '#8f8f8f',
//           // borderTop: '1px solid #DFDFDF',
//           borderBottom: isActiveVolatility ? `1px solid ${mainColors.mainBlue}` : 'none',
//           borderRight: isActiveVolatility ? `1px solid ${mainColors.mainBlue}` : 'none',
//           borderLeft: isActiveVolatility ? `1px solid ${mainColors.mainBlue}` : 'none',
//         },
//         headerStyle: {
//           textAlign: 'center' as CSSProperties['textAlign'],
//           backgroundColor: isActiveVolatility
//             ? mapStatusToColor(specifyStatus(status))
//             : undefined,
//           color: isActiveVolatility ? 'white' : '#8f8f8f',
//           borderBottom: "none",
//           borderTop: isActiveVolatility ? `1px solid ${mainColors.mainBlue}` : '1px solid #DFDFDF',
//           borderRight: isActiveVolatility ? `1px solid ${mainColors.mainBlue}` : 'none',
//           borderLeft: isActiveVolatility ? `1px solid ${mainColors.mainBlue}` : 'none',
//         }
//       };
//     });

//     return [[returnObj], columns];
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// }
export function generateCouttsStatusBoxData(
  srriData: DataObject
): [SrriStatusData[], CustomColumn<SrriStatusData>[]] {
  if (!srriData || srriData.isFetching) {
    return [[], []];
  }
  try {
    if (srriData.error) {
      throw new Error('Error generating SRRI data');
    }
    // const { weekly_volatility: volatility, status } = srriData.data[0];
    // const volatility = "weekly_volatility" in srriData.data[0] ? srriData.data[0].daily_volatility : srriData.data[0].data1.daily_volatility[0];
    const volatility =
      'weekly_volatility' in srriData.data[0]
        ? srriData.data[0].weekly_volatility
        : srriData.data[0].data1.weekly_volatility[0];
    const status =
      'status' in srriData.data[0]
        ? srriData.data[0].status
        : srriData.data[0].data2.status;
    // const srriBucket = srriData.data[0].data2.limits[2];

    const returnObj: any = {};
    Object.keys(volatilityMap).forEach((volatilityKey) => {
      returnObj[volatilityKey as keyof SrriStatusData] =
        volatilityMap[volatilityKey as keyof SrriStatusData].label;
    });

    const columns = srriStatusColumns.map((col) => {
      const { lower, upper } = volatilityMap[col.field as keyof SrriStatusData];
      const isActiveVolatility = volatility >= lower && volatility < upper;
      // const isActiveVolatility = col.title === srriBucket.toString();
      return {
        ...col,
        cellStyle: {
          textAlign: 'center' as CSSProperties['textAlign'],
          backgroundColor: isActiveVolatility
            ? mapStatusToColor(specifyStatus(status))
            : undefined,
          color: isActiveVolatility ? 'white' : '#8f8f8f',
          // borderTop: '1px solid #DFDFDF',
          borderBottom: isActiveVolatility
            ? `1px solid ${mainColors.mainBlue}`
            : 'none',
          borderRight: isActiveVolatility
            ? `1px solid ${mainColors.mainBlue}`
            : 'none',
          borderLeft: isActiveVolatility
            ? `1px solid ${mainColors.mainBlue}`
            : 'none',
        },
        headerStyle: {
          textAlign: 'center' as CSSProperties['textAlign'],
          backgroundColor: isActiveVolatility
            ? mapStatusToColor(specifyStatus(status))
            : undefined,
          color: isActiveVolatility ? 'white' : '#8f8f8f',
          borderBottom: 'none',
          borderTop: isActiveVolatility
            ? `1px solid ${mainColors.mainBlue}`
            : '1px solid #DFDFDF',
          borderRight: isActiveVolatility
            ? `1px solid ${mainColors.mainBlue}`
            : 'none',
          borderLeft: isActiveVolatility
            ? `1px solid ${mainColors.mainBlue}`
            : 'none',
        },
      };
    });

    return [[returnObj], columns];
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export function generateHistoricalNavChartData(data: any): any[] {
  //   return [
  //     {
  //       nav: 3,
  //       date: '2021-03-31',
  //     },
  //   ];
  const timeSeriesData =
    'time_series' in data[0]
      ? [...data[0].time_series]
      : [...data[0].data1.time_series];
  // remove header row
  timeSeriesData.shift();
  return timeSeriesData.map((el) => ({
    nav: el[1],
    date: el[0],
  }));
}

export interface HistoricalSrriData {
  lowerBound: number;
  upperBound: number;
  srri: number;
  date: string;
}

export function generateSrriLineChartData(
  srriData: DataObject
): HistoricalSrriData[] {
  if (!srriData || srriData.isFetching) return [];
  try {
    // const { time_series_data } = srriData.data[0];
    const time_series_data =
      'time_series_data' in srriData.data[0]
        ? srriData.data[0].time_series_data
        : srriData.data[0].data2.time_series_data;
    const dataForUse = [...time_series_data];
    //remove header row
    dataForUse.shift();

    return dataForUse.map((el) => {
      const [date, srri, lowerBound, upperBound] = el;
      return {
        date,
        srri,
        lowerBound,
        upperBound,
      };
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}
