import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
import RaptorSelect from '../../../../selects/RaptorSelect.component';
import { greys } from '../../../../../styling/theme';
import { raidrClient } from '../../../../../utilities/requestClient';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@mui/material';

interface ReviewDocumentsButtonsProps {
  fundId: string;
  isin: string;
  documentLanguage: string;
  documentOptions: string[];
  filteredOverviewData: any[];
  defaultSelection: string;
}

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '1rem',
    gap: '2rem',
  },
  selectContainer: {
    width: '100%',
    minWidth: '60rem',
    // marginLeft: '10rem',
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
  arrowSelect: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltipSelect: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
}));

const ReviewDocumentsButtons: FC<ReviewDocumentsButtonsProps> = (props) => {
  const {
    fundId,
    isin,
    documentLanguage,
    documentOptions,
    filteredOverviewData,
    defaultSelection,
  } = props;
  const history = useHistory();
  const classes = useStyles();

  const requestClient = raidrClient();

  // // Function for pulling and populating the overview data (will be used to dertmine which documents are available for review)
  // const getPriipsOverviewData = async () => {
  //     requestClient.get('kiids_generator_get_overview_data').then((response) => {
  //         setPriipsOverviewData(response.data);
  //     });
  // };
  // // Run the function once on render
  // useEffect(() => {
  //     getPriipsOverviewData();
  // }, []);

  // // Create an array of options for the select when overview data is available
  // useEffect(() => {
  //     if (priipsOverviewData.length > 0) {
  //         const documentOptions: string[] = [];
  //         const overviewDataFiltered: any[] = [];
  //         priipsOverviewData.forEach((item) => {
  //             // Only add documents that are available for review.
  //             const version = item['version'].split('.');
  //             if (version[2] !== '0' && item['document_language'] === documentLanguage) {
  //                 documentOptions.push(`${item['fund_name']} - ${item['share_class']}`);
  //                 overviewDataFiltered.push(item);
  //             }
  //         });
  //         setDocumentOptions(documentOptions);
  //         setFilteredOverviewData(overviewDataFiltered);
  //         // Set the default selection
  //         const selctionValue = findDefaultSelection(fundId, isin, priipsOverviewData);
  //         setDefaultSelection(selctionValue);
  //     }
  // }, [priipsOverviewData]
  // );

  // This function will switch the document being reviewed when the user selects a different option from the dropdown
  function onSeletionChange(choice: string) {
    // Find the index of the selection in the options array
    const index = documentOptions.indexOf(choice);
    // Use the index to find the corresponding item in the overview data array
    const rowData = filteredOverviewData[index];
    history.push(
      `review?fundId=${rowData.fund_id_string}&isin=${
        rowData.share_class
      }&documentLanguage=${rowData.document_language}&currentVersion=${
        rowData.version
      }&publishedVersion=${rowData.version.split('.')[0] + '.0.0'}`
    );
  }

  return (
    <div className={classes.buttonsContainer}>
      <Tooltip
        classes={{ arrow: classes.arrowSelect, tooltip: classes.tooltipSelect }}
        title="Select Document for Review"
        placement={'top'}
        arrow
        enterNextDelay={2000}
        enterDelay={2000}
      >
        <div className={classes.selectContainer}>
          {defaultSelection !== '' && (
            <RaptorSelect
              options={documentOptions}
              handleChoice={onSeletionChange}
              defaultOption={defaultSelection}
              maxWidth="120rem"
            />
          )}
        </div>
      </Tooltip>
      {/* <Tooltip
                placement='top'
                enterDelay={1000}
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                arrow
                title="Go to Edit page for this document."
            >
                <Button
                    variant="contained"
                    sx={{
                        fontSize: '1.5rem',
                        width: '25rem',
                        height: '3rem',
                        color: 'white',
                        backgroundColor: mainColors.mainBlue,
                        borderRadius: '0.2rem',
                        padding: '0.2rem',
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: mainColors.mainBlue_lighter,
                        },
                        fontWeight: 600,
                        textTransform: 'none',
                    }}
                    disableElevation={true}

                    onClick={() => history.push(`editor?fundId=${fundId}&isin=${isin}&documentLanguage=${documentLanguage}`)}
                    endIcon={<EditIcon
                        sx={{
                            padding: '0.2rem',
                            fontSize: '3rem',
                            width: '2.5rem',
                            height: '2.5rem',
                        }}
                    />}
                >
                    Edit Document
                </Button>

        </Tooltip>  */}
    </div>
  );
};

export default ReviewDocumentsButtons;
