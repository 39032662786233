import { ShowChart } from '@mui/icons-material';
import { RaptorTheme } from '../../styling/theme';
import { IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

interface ChartDownloadProps {
  handler?: () => void;
  title: string;
}

const useStyles = makeStyles<RaptorTheme>((theme) => {
  return {
    tooltip: {
      backgroundColor: theme.palette.primary.main,
    },
  };
});

const ChartDownloadButton = (props: ChartDownloadProps) => {
  const classes = useStyles();
  const { handler, title } = props;
  return (
    <Tooltip classes={{ tooltip: classes.tooltip }} title="Download Chart">
      <IconButton onClick={handler}>
        <ShowChart />
      </IconButton>
    </Tooltip>
  );
};

export default ChartDownloadButton;
