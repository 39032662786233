//@ts-nocheck

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './fonts/Jost-Regular.ttf';
import './fonts/Jost-Light.ttf';
import './fonts/Jost-Bold.ttf';
import './fonts/Jost-ExtraBold.ttf';
import { BrowserRouter } from 'react-router-dom';
import store from './redux/Store';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <Provider store={store}>
    <BrowserRouter basename="/raptor">
      <App />
    </BrowserRouter>
  </Provider>
);
