import React, { FC } from 'react';
import { EditorTileProps } from './PriipsKidsEditorColumn.component';
import PriipsKidsEditorGridItem from '../../layout/PriipsKidsEditorGridItem.component';
import { greys, mainColors } from '../../../../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import UtilityButtons from '../../buttons/UtilityButtons.component';

const useStyles = makeStyles(() => ({
  sriStatusContainer: {
    // border: '1px solid',
    // borderColor: mainColors.mainBlue,
    borderColor: 'black',
    marginTop: '2rem',
    // backgroundColor: 'white',
    marginBottom: '2rem',
    marginLeft: '1rem',
    // marginRight: '1rem',
    fontSize: '1.5rem',
    boxSizing: 'border-box',
    padding: '1rem',
    borderRadius: '6px',
    width: '100%',
    minHeight: '10rem',
  },
  editorContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginRight: '1rem',
  },
  formatContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '1rem',
    gap: '2rem',
  },
  riskWorkdingContainerLeft: {
    // display: 'flex',
    // flexDirection: 'column',
    // width: '100%',
    backgroundColor: mainColors.mainBlue,
    color: 'white',
    textAlign: 'left',
    fontWeight: 500,
    boxSizing: 'border-box',
    padding: '1rem',
    borderRadius: '6px',
  },
  riskWorkdingContainerRight: {
    // display: 'flex',
    // flexDirection: 'column',
    // width: '100%',
    backgroundColor: mainColors.mainBlue,
    color: 'white',
    textAlign: 'right',
    fontWeight: 500,
    boxSizing: 'border-box',
    padding: '1rem',
    borderRadius: '6px',
  },
  statusBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: '1rem',
    // justifyContent: 'space-between',
  },
  sriStatusBoxSelected: {
    backgroundColor: mainColors.controlButtonBlue,
    color: 'white',
    fontWeight: 700,
    boxSizing: 'border-box',
    padding: '1rem',
    // borderRadius: '6px',
    width: '100%',
    textAlign: 'center',
    height: '10rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid',
    borderColor: mainColors.controlButtonBlue,
    fontSize: '3rem',
  },
  sriStatusBox: {
    // backgroundColor: greys.grey400,
    backgroundColor: 'white',
    color: mainColors.mainBlue,
    fontWeight: 600,
    boxSizing: 'border-box',
    padding: '1rem',
    // borderRadius: '6px',
    width: '100%',
    textAlign: 'center',
    height: '10rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'black',
  },
}));

const SRIChartEditorTile: FC<EditorTileProps> = ({
  priipsKidsElement,
  setPriipsKidsContent,
  updateFieldType,
  moveField,
  deleteField,
}) => {
  const classes = useStyles();
  // Store the index of the current element in the overall data
  const index = priipsKidsElement.kiidIndex;
  // Store the field ID of the element
  const fieldId = priipsKidsElement.fieldId;
  return (
    <PriipsKidsEditorGridItem xs={12}>
      <div className={classes.editorContainer}>
        <div className={classes.sriStatusContainer}>
          <SriStatusBoxTable sri={parseInt(priipsKidsElement.content)} />
        </div>
        <UtilityButtons
          moveField={moveField}
          index={index}
          deleteField={deleteField}
          fieldId={fieldId}
          disableDelete
          disableDeleteMessage="You cannot delete the SRI chart."
        />
      </div>
    </PriipsKidsEditorGridItem>
  );
};

const SriStatusBoxTable: React.FC<{ sri: number }> = ({ sri }) => {
  const classes = useStyles();
  const iterator = [...Array(7).keys()].map((x) => x + 1);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div className={classes.riskWorkdingContainerLeft}>
          <span>Lower Risk</span>
          <br />
          <span>Typically Lower Rewards</span>
        </div>
        <div className={classes.riskWorkdingContainerRight}>
          <span>Higher Risk</span>
          <br />
          <span>Typically Higher Rewards</span>
        </div>
      </div>
      <div className={classes.statusBoxContainer}>
        {iterator.map((i) => {
          return (
            <div
              className={
                sri === i ? classes.sriStatusBoxSelected : classes.sriStatusBox
              }
            >
              <span>{i}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SRIChartEditorTile;
