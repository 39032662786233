import makeStyles from '@mui/styles/makeStyles';
import { Dispatch, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { clientNameSelector } from '../../../../../redux/auth/selectors';
import { RaptorTheme, greys, mainColors } from '../../../../../styling/theme';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { Status } from '../../../../../types/redux/data/dataTypes';
import { hexToRGBA } from '../../../../../utilities/colorUtilities';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import ProcessingStatusBox from './ProcessingStatusBox.component';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { formatDateForCheckingState } from '../../../../../utilities/dateFormatters';
import { Avatar, Button, Typography } from '@mui/material';

interface FundBreakdownContainerProps {
  data: any;
  filterState: string;
  setFilterState: Dispatch<any>;
  weekStartDate: Date;
  setWeekStartDate: Dispatch<any>;
}

interface IFundBreakdownTableData {
  fundId: string;
  fundName: string;
  frequency: string;
  positionDate: string[];
  status: string[];
  statusColor: string[];
  dataTimestamp: string[];
  processingTimestamp: string[];
  reportTimestamp: string[];
  index: number;
}

function buildFundBreakdownTableColumns(
  dates: string[],
  clientName: string | null
): CustomColumn<IFundBreakdownTableData>[] {
  // Store days of the week.
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return [
    {
      title: 'Fund Id',
      field: 'fundId',
      width: '8%',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      defaultSort:
        clientName && clientName.includes('mersenne') ? undefined : 'asc',
      render: (rowData: IFundBreakdownTableData) =>
        clientName && clientName.includes('mersenne')
          ? `Fund${rowData.index}`
          : rowData.fundId,
    },
    {
      title: 'Fund Name',
      field: 'fundName',
      cellStyle: {
        textAlign: 'left',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: IFundBreakdownTableData) =>
        clientName && clientName.includes('mersenne')
          ? `Mersenne Fund${rowData.index}`
          : rowData.fundName,
    },
    {
      title: 'Fund Frequency',
      field: 'frequency',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: (
        <div>
          <div>{daysOfWeek[new Date(dates[4]).getDay()]}</div>
          <div>{`Position Date: ${dates[4]}`}</div>
        </div>
      ),
      field: 'day5',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      customSort: (a: IFundBreakdownTableData, b: IFundBreakdownTableData) =>
        a.status[4] > b.status[4] ? 1 : -1,
      render: (rowData: IFundBreakdownTableData) => (
        <ProcessingStatusBox
          statusColor={rowData.statusColor[4]}
          text={rowData.status[4]}
          dataTimestamp={rowData.dataTimestamp[4]}
          processingTimestamp={rowData.processingTimestamp[4]}
          reportTimestamp={rowData.reportTimestamp[4]}
        />
      ),
    },

    {
      title: (
        <div>
          <div>{daysOfWeek[new Date(dates[3]).getDay()]}</div>
          <div>{`Position Date: ${dates[3]}`}</div>
        </div>
      ),
      field: 'day4',
      cellStyle: {
        textAlign: 'center',
        // backgroundColor: mainColors.mainBlue_lighter,
        // color: 'white',
        // fontWeight: 800,
      },
      headerStyle: { textAlign: 'center' },
      customSort: (a: IFundBreakdownTableData, b: IFundBreakdownTableData) =>
        a.status[3] > b.status[3] ? 1 : -1,
      render: (rowData: IFundBreakdownTableData) => (
        <ProcessingStatusBox
          statusColor={rowData.statusColor[3]}
          text={rowData.status[3]}
          dataTimestamp={rowData.dataTimestamp[3]}
          processingTimestamp={rowData.processingTimestamp[3]}
          reportTimestamp={rowData.reportTimestamp[3]}
        />
      ),
    },
    {
      title: (
        <div>
          <div>{daysOfWeek[new Date(dates[2]).getDay()]}</div>
          <div>{`Position Date: ${dates[2]}`}</div>
        </div>
      ),
      field: 'day3',
      cellStyle: {
        textAlign: 'center',
        // backgroundColor: mainColors.mainBlue_lighter,
        // color: 'white',
        // fontWeight: 800,
      },
      headerStyle: { textAlign: 'center' },
      customSort: (a: IFundBreakdownTableData, b: IFundBreakdownTableData) =>
        a.status[2] > b.status[2] ? 1 : -1,
      render: (rowData: IFundBreakdownTableData) => (
        <ProcessingStatusBox
          statusColor={rowData.statusColor[2]}
          text={rowData.status[2]}
          dataTimestamp={rowData.dataTimestamp[2]}
          processingTimestamp={rowData.processingTimestamp[2]}
          reportTimestamp={rowData.reportTimestamp[2]}
        />
      ),
    },
    {
      title: (
        <div>
          <div>{daysOfWeek[new Date(dates[1]).getDay()]}</div>
          <div>{`Position Date: ${dates[1]}`}</div>
        </div>
      ),
      field: 'day1',
      cellStyle: {
        textAlign: 'center',
        // backgroundColor: mainColors.mainBlue_lighter,
        // color: 'white',
        // fontWeight: 800,
      },
      headerStyle: { textAlign: 'center' },
      customSort: (a: IFundBreakdownTableData, b: IFundBreakdownTableData) =>
        a.status[1] > b.status[1] ? 1 : -1,
      // render: (rowData: IFundBreakdownTableData) => rowData.status.length > 0 ? rowData.status[0] : ''
      render: (rowData: IFundBreakdownTableData) => (
        <ProcessingStatusBox
          statusColor={rowData.statusColor[1]}
          text={rowData.status[1]}
          dataTimestamp={rowData.dataTimestamp[1]}
          processingTimestamp={rowData.processingTimestamp[1]}
          reportTimestamp={rowData.reportTimestamp[1]}
        />
      ),
    },
    {
      title: (
        <div>
          <div>{daysOfWeek[new Date(dates[0]).getDay()]}</div>
          <div>{`Position Date: ${dates[0]}`}</div>
        </div>
      ),
      field: 'day2',
      cellStyle: {
        textAlign: 'center',
        // backgroundColor: mainColors.mainBlue_lighter,
        // color: 'white',
        // fontWeight: 800,
      },
      headerStyle: { textAlign: 'center' },
      customSort: (a: IFundBreakdownTableData, b: IFundBreakdownTableData) =>
        a.status[0] > b.status[0] ? 1 : -1,
      render: (rowData: IFundBreakdownTableData) => (
        <ProcessingStatusBox
          statusColor={rowData.statusColor[0]}
          text={rowData.status[0]}
          dataTimestamp={rowData.dataTimestamp[0]}
          processingTimestamp={rowData.processingTimestamp[0]}
          reportTimestamp={rowData.reportTimestamp[0]}
        />
      ),
    },
  ];
}

const fundBreakdownTableColumns: CustomColumn<IFundBreakdownTableData>[] = [
  {
    title: 'Fund Id',
    field: 'fundId',
    cellStyle: {
      textAlign: 'center',
      // backgroundColor: mainColors.mainBlue_lighter,
      // color: 'white',
      // fontWeight: 800,
    },
    headerStyle: { textAlign: 'center' },
    defaultSort: 'asc',
  },
  {
    title: 'Fund Name',
    field: 'fundName',
    cellStyle: {
      textAlign: 'left',
      // backgroundColor: mainColors.mainBlue_lighter,
      // color: 'white',
      // fontWeight: 800,
    },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Latest Position Date',
    field: 'latestDate',
    cellStyle: {
      textAlign: 'center',
      // backgroundColor: mainColors.mainBlue_lighter,
      // color: 'white',
      // fontWeight: 800,
    },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Data Received At',
    field: 'dataTimestamp',
    cellStyle: {
      textAlign: 'center',
      // backgroundColor: mainColors.mainBlue_lighter,
      // color: 'white',
      // fontWeight: 800,
    },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Processing Began At',
    field: 'processingTimestamp',
    cellStyle: {
      textAlign: 'center',
      // backgroundColor: mainColors.mainBlue_lighter,
      // color: 'white',
      // fontWeight: 800,
    },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Report Delivered At',
    field: 'reportTimestamp',
    cellStyle: {
      textAlign: 'center',
      // backgroundColor: mainColors.mainBlue_lighter,
      // color: 'white',
      // fontWeight: 800,
    },
    headerStyle: { textAlign: 'center' },
  },
  // {
  //     title: 'Average Time (Minutes)',
  //     field: 'averageTime',
  //     cellStyle: {
  //         textAlign: 'center',
  //         backgroundColor: mainColors.mainBlue_lighter,
  //         color: 'white',
  //         fontWeight: 800,
  //     },
  //     headerStyle: { textAlign: 'center' },
  // },
  {
    title: 'Status',
    field: 'status',
    cellStyle: {
      textAlign: 'center',
      color: mainColors.Pass_darker,
      // color: 'white',
      fontWeight: 800,
    },
    headerStyle: {
      textAlign: 'center',
      // backgroundColor: mainColors.Pass_darker,
      // color: 'white'
    },
    // render: (rowData: IFundBreakdownTableData) => (
    //   <StatusBox
    //     status={rowData.statusColor as Status}
    //     text={rowData.status}
    //   />
    // ),
  },
];

function buildFundBreakdownData(
  data: any,
  dateColumns: string[],
  filterState: string
): IFundBreakdownTableData[] {
  if (!data.data.length) return [];
  if (!('funds' in data.data[0])) return [];
  const fundsData = data.data[0].funds;
  const tableData: IFundBreakdownTableData[] = [];
  const fundInformation = data.data[0].fund_information;

  // Create a helper function for finding the correct data based on date.
  const searchByDate = (date: string, element: any[]) => {
    let retValue: string[] = ['', '', '', '', ''];
    // Loop through the list and see if the date is present.
    for (let i = 0; i < element.length; i++) {
      if (element[i][0] === date) {
        retValue = element[i];
        break;
      }
    }
    // IF not found return a list of empty strings.
    return retValue;
  };
  let index = 1;
  for (const fundKey in fundsData) {
    // Get data for the current fund (a list).
    const currentFundData = fundsData[fundKey];
    // store the fund name
    const fundName =
      fundKey in fundInformation ? fundInformation[fundKey].fund_name : '';
    const fundFrequency =
      fundKey in fundInformation ? fundInformation[fundKey].frequency_type : '';
    // Create lists fr storing data.
    const positionDate: string[] = [];
    const status: string[] = [];
    const statusColor: string[] = [];
    const dataTimestamp: string[] = [];
    const processingTimestamp: string[] = [];
    const reportTimestamp: string[] = [];

    // Loop through the current fund data.
    // Loop through the dates and ensure a value is added for each.
    dateColumns.forEach((date: string) => {
      // Try to find an element in the fund data with the corresponding date.
      const value = searchByDate(date, currentFundData);
      positionDate.push(date);
      dataTimestamp.push(value[1]);
      processingTimestamp.push(value[2]);
      reportTimestamp.push(value[3]);
      status.push(value[4]);
      statusColor.push(mapProcessingStatusToColor(value[4]));
    });
    // currentFundData.forEach((value: any) => {
    //   positionDate.push(value[0]);
    //   dataTimestamp.push(value[1]);
    //   processingTimestamp.push(value[2]);
    //   reportTimestamp.push(value[3]);
    //   status.push(value[4]);
    //   statusColor.push(mapProcessingStatusToColor(value[4]));
    // });

    // Check if the data should be filtered.
    let shouldBeIncluded = true;
    // Special case for Filtering for delivered reports.
    // In this case we only include the row if the report is delivered for every day.
    if (filterState === 'Report Delivered') {
      status.forEach((value: any) => {
        if (value !== filterState && value !== '') shouldBeIncluded = false;
      });
    } else if (filterState === 'No Data') {
      shouldBeIncluded = false;
      status.forEach((value: any) => {
        if (value.includes('No Data')) shouldBeIncluded = true;
      });
    } else if (filterState === 'Analyst Input Required') {
      shouldBeIncluded = false;
      status.forEach((value: any) => {
        if (value.includes('Analyst Input Required')) shouldBeIncluded = true;
      });
    } else if (filterState === 'Data Expected') {
      shouldBeIncluded = false;
      status.forEach((value: any) => {
        if (value.includes('Expected')) shouldBeIncluded = true;
      });
    } else if (filterState !== '') {
      // Otherwise we look for the filter value on any of the days.
      shouldBeIncluded = false;
      status.forEach((value: any) => {
        if (value === filterState) shouldBeIncluded = true;
      });
    }

    if (shouldBeIncluded) {
      tableData.push({
        fundId: fundKey,
        fundName: fundName,
        frequency: fundFrequency,
        positionDate: positionDate,
        status: status,
        statusColor: statusColor,
        dataTimestamp: dataTimestamp,
        processingTimestamp: processingTimestamp,
        reportTimestamp: reportTimestamp,
        index: index,
      });
      index++;
    }
  }

  return tableData;
}

function mapProcessingStatusToColor(status: string) {
  switch (status) {
    case 'Report Delivered':
      return mainColors.Pass;
    case 'Data Expected':
    case 'Data Processing':
      return mainColors.mainBlue;
    case 'Analyst Input Required':
      return mainColors.actionRequiredYellow;
    case 'Data Present':
      return mainColors.Alert;
    case 'No Data':
    case 'No Data Received':
      return mainColors.Fail;
    case 'Public Holiday':
      return mainColors.pastelPink;
    default:
      if (status.includes('@')) return mainColors.mainBlue_lighter;
      return mainColors.Fail;
  }
}

// function mapProcessingStatusToColor(status: Status) {
//   switch (status) {
//     case Status.Pass:
//       return mainColors.Pass;
//     case Status.Alert:
//       return mainColors.mainBlue;
//     case Status.PassAlert:
//       return mainColors.Alert;
//     case Status.Fail:
//       return mainColors.Fail;
//     default:
//       return mainColors.Fail;
//   }
// }

const StatusBox = ({
  status,
  text,
}: {
  status: Status;
  text: string;
  dataTimestamp: string;
  processingTimestamp: string;
  reporttimestamp: string;
}) => (
  <div
    style={{
      padding: '.8rem',
      borderRadius: 8,
      border: `1px solid ${mapProcessingStatusToColor(status)}`,
      backgroundColor: mapProcessingStatusToColor(status),
      // maxWidth: '40rem'
    }}
  >
    <Typography
      variant="h3"
      style={{
        transform: 'translateY(1px)',
        color: 'white',
        fontSize: '1.8rem',
      }}
    >
      {text}
    </Typography>
  </div>
);

interface StyleProps {
  filterState: string;
}

const useDisplayOptionsButtonsStyles = makeStyles<RaptorTheme, StyleProps>(
  (theme) => ({
    container: {
      lineHeight: 25,
    },
    contained: {
      height: '3rem',
      background: 'white',
      borderRadius: 8,
      // paddingBottom: (props) => (props.clicked ? 0 : '2rem'),
      // paddingTop: (props) => (props.clicked ? 0 : '2rem'),
    },
    rootReportDelivered: {
      marginRight: '0.4rem',
      marginTop: '1rem',
      display: 'inline-flex',
      backgroundColor: (props) =>
        props.filterState === 'Report Delivered'
          ? mainColors.Pass
          : greys.grey400,
      '&:hover': {
        backgroundColor: hexToRGBA(mainColors.Pass, 0.5),
      },
    },
    rootDataProcessing: {
      marginRight: '0.4rem',
      marginTop: '1rem',
      display: 'inline-flex',
      backgroundColor: (props) =>
        props.filterState === 'Data Processing'
          ? mainColors.mainBlue
          : greys.grey400,
      '&:hover': {
        backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
      },
    },
    rootAnalystInputNeeded: {
      marginRight: '0.4rem',
      marginTop: '1rem',
      display: 'inline-flex',
      backgroundColor: (props) =>
        props.filterState === 'Analyst Input Required'
          ? mainColors.actionRequiredYellow
          : greys.grey400,
      '&:hover': {
        backgroundColor: hexToRGBA(mainColors.actionRequiredYellow, 0.5),
      },
    },
    rootNoData: {
      marginRight: '0.4rem',
      marginTop: '1rem',
      display: 'inline-flex',
      backgroundColor: (props) =>
        props.filterState === 'No Data' ? mainColors.Fail : greys.grey400,
      '&:hover': {
        backgroundColor: hexToRGBA(mainColors.Fail, 0.5),
      },
    },
    rootDataPresent: {
      marginRight: '0.4rem',
      marginTop: '1rem',
      display: 'inline-flex',
      backgroundColor: (props) =>
        props.filterState === 'Data Present' ? mainColors.Alert : greys.grey400,
      '&:hover': {
        backgroundColor: hexToRGBA(mainColors.Alert, 0.5),
      },
    },
    rootDataExpected: {
      marginRight: '0.4rem',
      marginTop: '1rem',
      display: 'inline-flex',
      backgroundColor: (props) =>
        props.filterState === 'Data Expected'
          ? mainColors.mainBlue
          : greys.grey400,
      '&:hover': {
        backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
      },
    },
    label: {
      flexDirection: 'column',

      alignItems: 'start',
    },
    buttonText: {
      colorPrimary: theme.palette.common.white,
    },
  })
);

function buildFundDates(data: any): string[] {
  if (!data.data.length) return [];
  // Get the start and end dates.
  const startDate = data.data[0].manco.week_start_date;
  const endDate = data.data[0].manco.week_end_date;
  // Convert to dates.
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Loop through the dates.
  const returnDates = [startDate];
  const tempDate = start;
  while (tempDate < end) {
    tempDate.setDate(tempDate.getDate() + 1);
    if (tempDate.getDay() !== 0 && tempDate.getDay() !== 6) {
      returnDates.push(tempDate.toISOString().split('T')[0]);
    }
  }

  return returnDates;
}

function FundBreakdownContainer({
  data,
  filterState,
  setFilterState,
  weekStartDate,
  setWeekStartDate,
}: FundBreakdownContainerProps): ReactElement {
  const clientName = useSelector(clientNameSelector);
  const columnDates = buildFundDates(data);

  const tableData = buildFundBreakdownData(data, columnDates, filterState);
  const classes = useDisplayOptionsButtonsStyles({ filterState: filterState });

  const tableColumns = buildFundBreakdownTableColumns(columnDates, clientName);

  const moveDateForward = () => {
    // Get date a week later.
    const dateOneWeekFromNow = new Date(
      weekStartDate.getTime() + 7 * 24 * 60 * 60 * 1000
    );
    setWeekStartDate(dateOneWeekFromNow);
  };

  const moveDateBackward = () => {
    // get date a week ago.
    const dateOneWeekAgo = new Date(
      weekStartDate.getTime() - 7 * 24 * 60 * 60 * 1000
    );
    setWeekStartDate(dateOneWeekAgo);
  };

  // Store todays date.
  const today = new Date();
  const handleFilter = (filterValue: string) => {
    if (filterValue === filterState) setFilterState('');
    else setFilterState(filterValue);
  };

  return (
    <GridItem card key="manco-overview" item xs={12}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '1rem',
          marginLeft: '1rem',
          marginRight: '1rem',
        }}
      >
        <Avatar
          style={{
            backgroundColor: mainColors.mainBlue,
            cursor: 'pointer',
          }}
          onClick={moveDateBackward}
        >
          <ArrowBackIcon
            fontSize="large"
            sx={{
              color: 'white',
            }}
          />
        </Avatar>
        <Typography
          variant="h3"
          style={{
            // transform: 'translateY(1px)',
            color: mainColors.mainBlue,
            fontSize: '1.8rem',
          }}
        >
          {`Start Position Date: ${columnDates[0]}`}
        </Typography>
        <Avatar
          style={{
            backgroundColor:
              formatDateForCheckingState(today) ===
              formatDateForCheckingState(weekStartDate)
                ? greys.grey500
                : mainColors.mainBlue,
            cursor:
              formatDateForCheckingState(today) ===
              formatDateForCheckingState(weekStartDate)
                ? undefined
                : 'pointer',
          }}
          onClick={
            formatDateForCheckingState(today) ===
            formatDateForCheckingState(weekStartDate)
              ? undefined
              : moveDateForward
          }
        >
          <ArrowForwardIcon
            fontSize="large"
            sx={{
              color: 'white',
            }}
          />
        </Avatar>
      </div>
      <div
        style={{
          display: 'flex',
          marginLeft: '2rem',
        }}
      >
        <Button
          variant="contained"
          onClick={() => handleFilter('Report Delivered')}
          classes={{
            contained: classes.contained,
            root: classes.rootReportDelivered,
            // label: classes.label,
          }}
        >
          <Typography
            variant="h3"
            color="primary"
            style={{
              color: 'white',
            }}
          >
            {'Complete'}
          </Typography>
        </Button>
        <Button
          variant="contained"
          onClick={() => handleFilter('Data Processing')}
          // onClick={() => }
          classes={{
            contained: classes.contained,
            root: classes.rootDataProcessing,
            // label: classes.label,
          }}
        >
          <Typography
            variant="h3"
            color="primary"
            style={{
              color: 'white',
            }}
          >
            {'Currently Processing'}
          </Typography>
        </Button>
        <Button
          variant="contained"
          onClick={() => handleFilter('Analyst Input Required')}
          // onClick={() => }
          classes={{
            contained: classes.contained,
            root: classes.rootAnalystInputNeeded,
            // label: classes.label,
          }}
        >
          <Typography
            variant="h3"
            color="primary"
            style={{
              color: 'white',
            }}
          >
            {'Analyst Input Required'}
          </Typography>
        </Button>
        <Button
          variant="contained"
          onClick={() => handleFilter('Data Present')}
          // onClick={() => }
          classes={{
            contained: classes.contained,
            root: classes.rootDataPresent,
            // label: classes.label,
          }}
        >
          <Typography
            variant="h3"
            color="primary"
            style={{
              color: 'white',
            }}
          >
            {'To Be Processed'}
          </Typography>
        </Button>
        <Button
          variant="contained"
          onClick={() => handleFilter('No Data')}
          // onClick={() => }
          classes={{
            contained: classes.contained,
            root: classes.rootNoData,
            // label: classes.label,
          }}
        >
          <Typography
            variant="h3"
            color="primary"
            style={{
              color: 'white',
            }}
          >
            {'Awaiting Data'}
          </Typography>
        </Button>
        <Button
          variant="contained"
          onClick={() => handleFilter('Data Expected')}
          // onClick={() => }
          classes={{
            contained: classes.contained,
            root: classes.rootDataExpected,
            // label: classes.label,
          }}
        >
          <Typography
            variant="h3"
            color="primary"
            style={{
              color: 'white',
            }}
          >
            {'Data Expected'}
          </Typography>
        </Button>
      </div>
      <CustomTable<IFundBreakdownTableData>
        columns={tableColumns}
        showToolbar
        // data={[]}
        data={tableData}
        // title="Aggregated Data"
        options={{
          paging: false,
          search: true,
          exportButton: false,
        }}
      />
    </GridItem>
  );
}

export default FundBreakdownContainer;
