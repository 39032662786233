import React from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject, Status } from '../../../../types/redux/data/dataTypes';
import {
  formatESGWithUnit,
  percentageToNdecialPlaces,
} from '../../../../utilities/numberFormatters';
import RaptorStatusBox from '../../../feedback/RaptorStatusBox.component';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { IndicatorCoverageRatioMonitorTableData } from './IndictaorCoverageRatioMonitor.component';
import IndicatorCoverageRatioSecondLevelTable from './IndicatorCoverageRatioMonitorSecondLevelTable.component';
import makeStyles from '@mui/styles/makeStyles';
import { mainColors } from '../../../../styling/theme';

interface IndictaorCoverageRatioMonitorTableProps {
  data: DataObject;
}

const useStyles = makeStyles(() => ({
  tabsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '2rem',
    width: '100%',
  },
  tab_active: {
    all: 'unset',
    width: 'fit-content',
    height: '3rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 2rem',
    borderRadius: '0.4rem',
    fontWeight: 400,
    fontSize: '1.8rem',
    backgroundColor: mainColors.mainBlue,
    color: 'white',
    cursor: 'pointer',
    userSelect: 'none',
  },
  tab_inactive: {
    all: 'unset',
    width: 'fit-content',
    height: '3rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 2rem',
    borderRadius: '0.4rem',
    fontWeight: 400,
    fontSize: '1.8rem',
    backgroundColor: 'white',
    color: mainColors.mainBlue,
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: mainColors.hoverOverWhite,
    },
  },
}));

// The standard columns for the table (Full Set)
const standardColumns: CustomColumn<IndicatorCoverageRatioMonitorTableData>[] =
  [
    {
      title: 'Indicator',
      field: 'indicator',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Name',
      field: 'indicator_name',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Description',
      field: 'description',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      width: '25%',
    },
    {
      title: 'Portfolio Indicator Value',
      field: 'value',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => formatESGWithUnit(rowData.value, rowData.unit),
    },
    {
      title: 'Total Gross Exposure %',
      field: 'total_gross_exposure_percentage',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        percentageToNdecialPlaces(rowData.total_gross_exposure_percentage, 2),
    },
    {
      title: 'Total Coverage Exposure %',
      field: 'total_covered_exposure_percentage',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        percentageToNdecialPlaces(rowData.total_covered_exposure_percentage, 2),
    },
    {
      title: 'Coverage Ratio',
      field: 'coverage_ratio_percentage',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        percentageToNdecialPlaces(rowData.coverage_ratio_percentage, 2),
    },
    {
      title: 'Limit',
      field: 'limit',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => percentageToNdecialPlaces(rowData.limit, 2),
    },
    {
      title: 'Limit Type',
      field: 'limit_type',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Status',
      field: 'pass_fail',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => (
        <RaptorStatusBox status={rowData.pass_fail as Status} />
      ),
    },
  ];

// Build the columns using the columns in the configs
const buildColumns = (
  data: DataObject,
  columns: CustomColumn<IndicatorCoverageRatioMonitorTableData>[]
): CustomColumn<IndicatorCoverageRatioMonitorTableData>[] => {
  if (!data.data[0].configs || !data.data[0].configs.indicator_columns) {
    return columns;
  } else {
    const returnColumns: CustomColumn<IndicatorCoverageRatioMonitorTableData>[] =
      [];
    data.data[0].configs.indicator_columns.forEach((column: string) => {
      columns.forEach(
        (col: CustomColumn<IndicatorCoverageRatioMonitorTableData>) => {
          if (col.field === column) {
            returnColumns.push(col);
          }
        }
      );
    });
    return returnColumns;
  }
};

// Build the data using the indicators in the active tab
const buildTableData = (
  data: DataObject,
  tab: string
): IndicatorCoverageRatioMonitorTableData[] => {
  if (
    !data.data[0].data ||
    !data.data[0].data.length ||
    !data.data[0].configs.indicators ||
    !data.data[0].configs.indicators[tab]
  ) {
    return [];
  } else {
    const indicators = data.data[0].configs.indicators[tab];
    const returnData: any[] = [];
    data.data[0].data.forEach((row: any) => {
      if (
        Object.keys(row).includes('indicator') &&
        indicators.includes(row['indicator'])
      ) {
        returnData.push(row);
      }
    });
    return returnData;
  }
};

const IndicatorCoverateMonitorTable: React.FC<
  IndictaorCoverageRatioMonitorTableProps
> = ({ data }) => {
  const classes = useStyles();

  // from the configs item in the data object, get the tabs
  const [tabs] = React.useState<string[]>(
    Object.keys(data.data[0].configs.indicators)
  );
  const [activeTab, setActiveTab] = React.useState<string>(
    Object.keys(data.data[0].configs.indicators)[0]
  );

  return (
    <GridItem xs={12} card>
      <CustomTable<IndicatorCoverageRatioMonitorTableData>
        // The columns are built using only the columns in the configs
        columns={buildColumns(data, standardColumns)}
        showToolbar
        // The data is built using only the indicators in the active tab
        data={buildTableData(data, activeTab)}
        // If there is more than one tab, then the title is a row of interactive tabs, else it is just the active tab as a string
        title={
          tabs.length > 1 ? (
            <div className={classes.tabsContainer}>
              {Object.keys(data.data[0].configs.indicators).map(
                (tab: string) => {
                  return (
                    <div
                      className={
                        activeTab === tab
                          ? classes.tab_active
                          : classes.tab_inactive
                      }
                      onClick={() => setActiveTab(tab)}
                    >
                      {tab}
                    </div>
                  );
                }
              )}
            </div>
          ) : (
            activeTab
          )
        }
        options={{
          draggable: false,
          sorting: false,
          paging: false,
          search: true,
          exportButton: true,
        }}
        detailPanel={[
          {
            disabled: false,
            render: (rowData: IndicatorCoverageRatioMonitorTableData) => {
              return (
                <IndicatorCoverageRatioSecondLevelTable
                  data={data}
                  rowData={rowData}
                />
              );
            },
          },
        ]}
      />
    </GridItem>
  );
};

export default IndicatorCoverateMonitorTable;
