import React, { FC } from 'react';
import LoggedInRoute from '../../../../routes/LoggedInRoute';
import DelegatedActsOverview from './overview/DelegatedActsOverview.component';
import { Redirect } from 'react-router-dom';

const DelegatedActs: FC = (props) => {
  return (
    // Here we route to different sections of the delegated acts functionality
    <>
      <Redirect
        exact
        from="/raidr/delegated-acts"
        to="/raidr/delegated-acts/overview"
      />
      <LoggedInRoute
        exact
        path="/raidr/delegated-acts/overview"
        Component={DelegatedActsOverview}
      />
    </>
  );
};

export default DelegatedActs;
