import {
  activeSectionSelector,
  createActiveFundSelectorBySection,
} from '../redux/pages/selectors';
import { useSelector } from 'react-redux';

function useActivefund() {
  const activeSection = useSelector(activeSectionSelector);
  const activeFund = useSelector(
    createActiveFundSelectorBySection(activeSection)
  );
  return activeFund;
}

export default useActivefund;
