import React from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToNdecialPlaces,
} from '../../../../../../utilities/numberFormatters';
import { mainColors } from '../../../../../../styling/theme';

interface SubFundAnalysisTableProps {
  data: any;
}

interface SubFundAnalysisTableData {
  asset: string;
  assetClass: string;
  assetType: string;
  baseCurrency: string;
  currencyPL: number;
  currencyPctPL: number;
  assetPL: number;
  assetPctPL: number;
  totalPL: number;
  totalPctPL: number;
  isAggregateRow: boolean;
}

addCommasToNumbersAndRound;

const detailColumns: CustomColumn<SubFundAnalysisTableData>[] = [
  {
    title: 'Asset',
    field: 'asset',
    width: '15%',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Asset Class',
    field: 'assetClass',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Asset Type',
    field: 'assetType',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Base Currency',
    field: 'baseCurrency',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Currency PL',
    field: 'currencyPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SubFundAnalysisTableData) =>
      addCommasToNumbersAndRound(rowData.currencyPL),
  },
  {
    title: 'Currency PL %',
    field: 'currencyPctPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SubFundAnalysisTableData) =>
      percentageToNdecialPlaces(rowData.currencyPctPL / 100, 2),
  },
  {
    title: 'Asset PL',
    field: 'assetPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SubFundAnalysisTableData) =>
      addCommasToNumbersAndRound(rowData.assetPL),
  },
  {
    title: 'Asset PL %',
    field: 'assetPctPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SubFundAnalysisTableData) =>
      percentageToNdecialPlaces(rowData.assetPctPL / 100, 2),
  },
  {
    title: 'Total PL',
    field: 'totalPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SubFundAnalysisTableData) =>
      addCommasToNumbersAndRound(rowData.totalPL),
  },
  {
    title: 'Total PL %',
    field: 'totalPctPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SubFundAnalysisTableData) =>
      percentageToNdecialPlaces(rowData.totalPctPL / 100, 2),
  },
];

const buildTableData = (inputData: any): SubFundAnalysisTableData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const uniqueAssetClasses = [
      ...new Set(
        Object.keys(inputData.data[0].position_level).map(
          (asset: any) => inputData.data[0].position_level[asset].asset_class
        )
      ),
    ];
    let returnData: SubFundAnalysisTableData[] = [];
    uniqueAssetClasses.map((category: string) => {
      const categoryData: SubFundAnalysisTableData[] = [];
      let aggregatePositionSize = 0;
      let aggregateCurrencyPL = 0;
      let aggregateCurrencyPLPct = 0;
      let aggregateAssetPL = 0;
      let aggregateAssetPLPct = 0;
      let aggregateTotalPL = 0;
      let aggregateTotalPLPct = 0;
      Object.keys(inputData.data[0].position_level).map((asset) => {
        if (inputData.data[0].position_level[asset].asset_class === category) {
          aggregatePositionSize +=
            inputData.data[0].position_level[asset].mr_position;
          aggregateCurrencyPL +=
            inputData.data[0].position_level[asset].aggregate_currency_pl;
          aggregateCurrencyPLPct +=
            inputData.data[0].position_level[asset].aggregate_currency_pl_pct;
          aggregateAssetPL +=
            inputData.data[0].position_level[asset].aggregate_asset_pl;
          aggregateAssetPLPct +=
            inputData.data[0].position_level[asset].aggregate_asset_pl_pct;
          aggregateTotalPL +=
            inputData.data[0].position_level[asset].aggregate_pl;
          aggregateTotalPLPct +=
            inputData.data[0].position_level[asset].aggregate_pct_pl;
          categoryData.push({
            asset: inputData.data[0].position_level[asset].asset_name,
            assetClass: inputData.data[0].position_level[asset].asset_class,
            assetType: inputData.data[0].position_level[asset].asset_type,
            baseCurrency: inputData.data[0].position_level[asset].base_currency,
            currencyPL:
              inputData.data[0].position_level[asset].aggregate_currency_pl,
            currencyPctPL:
              inputData.data[0].position_level[asset].aggregate_currency_pl_pct,
            assetPL: inputData.data[0].position_level[asset].aggregate_asset_pl,
            assetPctPL:
              inputData.data[0].position_level[asset].aggregate_asset_pl_pct,
            totalPL: inputData.data[0].position_level[asset].aggregate_pl,
            totalPctPL:
              inputData.data[0].position_level[asset].aggregate_pct_pl,
            isAggregateRow: false,
          });
        }
      });
      categoryData.unshift({
        asset: `${category} (Aggregation)`,
        assetClass: category,
        assetType: 'aggregate',
        baseCurrency: '',
        currencyPL: aggregateCurrencyPL,
        currencyPctPL: aggregateCurrencyPLPct,
        assetPL: aggregateAssetPL,
        assetPctPL: aggregateAssetPLPct,
        totalPL: aggregateTotalPL,
        totalPctPL: aggregateTotalPLPct,
        isAggregateRow: true,
      });
      returnData = returnData.concat(categoryData);
    });
    return returnData;
  }
};

const SubFundAnalysisTable: React.FC<SubFundAnalysisTableProps> = (props) => {
  const builtTableData = buildTableData(props.data);

  return (
    <GridItem xs={12} card>
      <CustomTable<SubFundAnalysisTableData>
        columns={detailColumns}
        showToolbar
        data={builtTableData}
        title={'Sub-Fund Historical Performance - Portfolio Analysis'}
        options={{
          paging: false,
          search: true,
          exportButton: true,
          rowStyle: (rowData: SubFundAnalysisTableData) => ({
            backgroundColor: rowData.isAggregateRow
              ? mainColors.lightGrey
              : 'white',
          }),
        }}
      />
    </GridItem>
  );
};

export default SubFundAnalysisTable;
