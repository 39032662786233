import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { makeStyles } from '@mui/styles';
import { debounce } from 'lodash';
import { SearchSharp } from '@mui/icons-material';
import { Input } from '@mui/material';
import { RaptorTheme } from '../../../../../../styling/theme';

const useNewSearchStyles = makeStyles<RaptorTheme>((theme) => ({
  root: {
    // backgroundColor: theme.palette.grey[100],
    border: '1px solid lightgrey',
    minWidth: '12rem',
    transition: 'width .2s',
    borderRadius: '.8rem',
    paddingLeft: '1rem',
    '& svg': {
      pointerEvents: 'none',
      fontSize: '2rem',
      color: theme.palette.grey[800],
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  },
  input: {
    padding: '.5rem',
    paddingLeft: '.5rem',
  },
  focused: {
    color: theme.palette.primary.main,
    '& svg': {
      color: theme.palette.primary.main,
    },
    backgroundColor: theme.palette.grey[100],
  },
}));

interface TableSearchProps {
  onChange: Dispatch<SetStateAction<string>>;
}

const TableSearch: FunctionComponent<TableSearchProps> = ({ onChange }) => {
  const debounceSearch = debounce(async (value: string) => {
    onChange(value);
  }, 200);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debounceSearch(event.target.value);
  };

  const newSearchStyles = useNewSearchStyles();
  return (
    <Input
      placeholder="Search..."
      disableUnderline
      classes={newSearchStyles}
      startAdornment={<SearchSharp />}
      onChange={handleChange}
    />
  );
};

export default TableSearch;
