import React, { Dispatch, FC } from 'react';
import { greys } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import PercentValueToggleButton from '../../../buttons/PercentValueToggleButton.component';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';

interface DCFConfigurationTableProps {
  data: any;
  selectedPosition: string | null;
  setBarChartFilter: Dispatch<any>;
  barChartValue: string;
  percentValueOption: string;
  setPercentValueOption: Dispatch<string>;
}

interface IDiscountCashflowData {
  year: number;
  ebit: number;
  investments: number;
  bondInterest: number;
  cashInterest: number;
  bondRepayments: number;
  tax: number;
  fcf: number;
  cash: number;
  pvFcf: number;
  fvFcf: number;
  netEquityValue: number;
}

function buildDetailColumns(
  filterFunction: Dispatch<any>,
  barChartValue: string,
  percentValueOption: string
): CustomColumn<IDiscountCashflowData>[] {
  return [
    {
      title: 'Year',
      field: 'year',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      // customSort: (rowData: any) => 1,
    },
    {
      title: <div onClick={() => filterFunction('EBIT')}>EBIT</div>,
      field: 'ebit',
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'EBIT'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.ebit)
          : addCommasToNumbersAndRound(rowData.ebit),
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('EBIT')
      //     return 1;
      // },
    },
    {
      title: (
        <div onClick={() => filterFunction('Investments')}>Investments</div>
      ),
      field: 'investments',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.investments)
          : addCommasToNumbersAndRound(rowData.investments),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'Investments'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('Investments')
      //     return 1;
      // }
    },
    // {
    //   title: (
    //     <div onClick={() => filterFunction('Bond Interest')}>Bond Interest</div>
    //   ),
    //   field: 'bondInterest',
    //   render: (rowData) =>
    //     percentValueOption === 'percent'
    //       ? percentageToTwoDecimalPlaces(rowData.bondInterest)
    //       : addCommasToNumbersAndRound(rowData.bondInterest),
    //   cellStyle: { textAlign: 'center' },
    //   headerStyle:
    //     barChartValue === 'Bond Interest'
    //       ? {
    //           textAlign: 'center',
    //           backgroundColor: greys.grey100,
    //           borderRadius: '10px',
    //           textDecoration: 'underline',
    //         }
    //       : { textAlign: 'center' },
    //   // customSort: (rowData: IDiscountCashflowData) => {
    //   //     filterFunction('Bond Interest')
    //   //     return 1;
    //   // }
    // },
    {
      title: (
        <div onClick={() => filterFunction('Bond Repayments')}>
          Bond Repayments
        </div>
      ),
      field: 'bondRepayments',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.bondRepayments)
          : addCommasToNumbersAndRound(rowData.bondRepayments),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'Bond Repayments'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('Bond Repayments')
      //     return 1;
      // }
    },

    {
      title: <div onClick={() => filterFunction('Tax')}>Tax</div>,
      field: 'tax',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.tax)
          : addCommasToNumbersAndRound(rowData.tax),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'Tax'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('Tax')
      //     return 1;
      // }
    },
    {
      title: <div onClick={() => filterFunction('FCF')}>FCF</div>,
      field: 'fcf',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.fcf)
          : addCommasToNumbersAndRound(rowData.fcf),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'FCF'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('FCF')
      //     return 1;
      // }
    },
    {
      title: <div onClick={() => filterFunction('Cash')}>Cash</div>,
      field: 'cash',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.cash)
          : addCommasToNumbersAndRound(rowData.cash),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'Cash'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('Cash')
      //     return 1;
      // }
    },
    {
      title: <div onClick={() => filterFunction('PV FCF')}>PV FCF</div>,
      field: 'pvFcf',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.pvFcf)
          : addCommasToNumbersAndRound(rowData.pvFcf),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'PV FCF'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('PV FCF')
      //     return 1;
      // }
    },
    {
      title: <div onClick={() => filterFunction('FV FCF')}>FV FCF</div>,
      field: 'fvFcf',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.fvFcf)
          : addCommasToNumbersAndRound(rowData.fvFcf),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'FV FCF'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('FV FCF')
      //     return 1;
      // }
    },
    {
      title: <div onClick={() => filterFunction('Return')}>Return</div>,
      field: 'netEquityValue',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.netEquityValue)
          : addCommasToNumbersAndRound(rowData.netEquityValue),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'Return'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('Return')
      //     return -1;
      // }
    },
  ];
}

function buildDetailColumnsPDF(
  percentValueOption: string
): CustomColumn<IDiscountCashflowData>[] {
  return [
    {
      title: 'Year',
      field: 'year',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      // customSort: (rowData: any) => 1,
    },
    {
      title: 'EBIT',
      field: 'ebit',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.ebit)
          : addCommasToNumbersAndRound(rowData.ebit),
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('EBIT')
      //     return 1;
      // },
    },
    {
      title: 'Investments',
      field: 'investments',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.investments)
          : addCommasToNumbersAndRound(rowData.investments),
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('Investments')
      //     return 1;
      // }
    },
    // {
    //   title: 'Bond Interest',
    //   field: 'bondInterest',
    //   render: (rowData) =>
    //     percentValueOption === 'percent'
    //       ? percentageToTwoDecimalPlaces(rowData.bondInterest)
    //       : addCommasToNumbersAndRound(rowData.bondInterest),
    //   cellStyle: { textAlign: 'center' },
    //   headerStyle: { textAlign: 'center' },
    //   // customSort: (rowData: IDiscountCashflowData) => {
    //   //     filterFunction('Bond Interest')
    //   //     return 1;
    //   // }
    // },
    {
      title: 'Bond Repayments',
      field: 'bondRepayments',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.bondRepayments)
          : addCommasToNumbersAndRound(rowData.bondRepayments),
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('Bond Repayments')
      //     return 1;
      // }
    },

    {
      title: 'Tax',
      field: 'tax',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.tax)
          : addCommasToNumbersAndRound(rowData.tax),
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('Tax')
      //     return 1;
      // }
    },
    {
      title: 'FCF',
      field: 'fcf',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.fcf)
          : addCommasToNumbersAndRound(rowData.fcf),
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('FCF')
      //     return 1;
      // }
    },
    {
      title: 'Cash',
      field: 'cash',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.cash)
          : addCommasToNumbersAndRound(rowData.cash),
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('Cash')
      //     return 1;
      // }
    },
    {
      title: 'PV FCF',
      field: 'pvFcf',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.pvFcf)
          : addCommasToNumbersAndRound(rowData.pvFcf),
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('PV FCF')
      //     return 1;
      // }
    },
    {
      title: 'FV FCF',
      field: 'fvFcf',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.fvFcf)
          : addCommasToNumbersAndRound(rowData.fvFcf),
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('FV FCF')
      //     return 1;
      // }
    },
    {
      title: 'Return',
      field: 'netEquityValue',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.netEquityValue)
          : addCommasToNumbersAndRound(rowData.netEquityValue),
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('Return')
      //     return -1;
      // }
    },
  ];
}

function buildDCFConfigurationData(
  data: any,
  selectedPosition: string | null,
  percentValueOption: string,
  nav: number
): IDiscountCashflowData[] {
  if (!data.data.length) return [];
  if (!selectedPosition) return [];
  // if (!('Bond Interest' in data.data[0])) return [];
  const peDataPositions = data.data[0];
  const peDataFiltered = peDataPositions.filter((obj: any) => {
    return obj.name === selectedPosition;
  });
  const peData = peDataFiltered[0];
  const numYears = Object.keys(peData['Bond Interest']).length;
  const tableData: IDiscountCashflowData[] = [];

  for (let i = 0; i < numYears; i++) {
    tableData.push({
      year: i,
      ebit:
        percentValueOption === 'percent'
          ? peData['EBIT'][i] / nav
          : peData['EBIT'][i],
      investments:
        percentValueOption === 'percent'
          ? peData['Investments'][i] / nav
          : peData['Investments'][i],
      bondInterest:
        percentValueOption === 'percent'
          ? peData['Bond Interest'][i] / nav
          : peData['Bond Interest'][i],
      cashInterest:
        percentValueOption === 'percent'
          ? peData['Cash Interest'][i] / nav
          : peData['Cash Interest'][i],
      bondRepayments:
        percentValueOption === 'percent'
          ? peData['Bond Repayments'][i] / nav
          : peData['Bond Repayments'][i],
      tax:
        percentValueOption === 'percent'
          ? peData['Tax'][i] / nav
          : peData['Tax'][i],
      fcf:
        percentValueOption === 'percent'
          ? peData['FCF'][i] / nav
          : peData['FCF'][i],
      cash:
        percentValueOption === 'percent'
          ? peData['Cash'][i] / nav
          : peData['Cash'][i],
      pvFcf:
        percentValueOption === 'percent'
          ? peData['PV FCF'][i] / nav
          : peData['PV FCF'][i],
      fvFcf:
        percentValueOption === 'percent'
          ? peData['FV FCF'][i] / nav
          : peData['FV FCF'][i],
      netEquityValue:
        percentValueOption === 'percent'
          ? peData['Return'][i] / nav
          : peData['Return'][i],
    });
  }

  return tableData;
}

export function getNav(data: any) {
  if (!data.data.length) return [];
  const peDataPositions = data.data[0];
  const peDataFiltered = peDataPositions.filter((obj: any) => {
    return obj.name === 'Portfolio';
  });
  const peData = peDataFiltered[0].preferences;
  return peData['NAV'];
}

const DiscountCashflowConfigurationTable: FC<DCFConfigurationTableProps> = (
  props
) => {
  // const theme = useTheme();
  const { data, selectedPosition } = props;
  // const tableData = isDefaultScenario ? buildSubscriptionsRedemptionData(data, selectedPosition, percentValueOption, nav) : buildDCFDataForComparison(data, selectedPosition, percentValueOption, nav, defaultData);
  // Allow user to switch between scenrio, change and both
  const detailColumns = buildDetailColumns(
    props.setBarChartFilter,
    props.barChartValue,
    props.percentValueOption
  );
  const pdfColumns = buildDetailColumnsPDF(props.percentValueOption);
  const nav = getNav(data);
  const tableData = buildDCFConfigurationData(
    data,
    selectedPosition,
    props.percentValueOption,
    nav
  );

  return (
    <GridItem xs={12} card style={{ padding: 8 }}>
      {/* <Typography variant='h2' style={{ marginLeft: '2rem', marginTop: '1rem', textAlign: 'center' }}>
                {title}
            </Typography> */}

      <div
        style={{
          float: 'left',
          marginLeft: '2rem',
          textAlign: 'center',
          marginTop: '2rem',
        }}
      >
        <PercentValueToggleButton
          percentValueOption={props.percentValueOption}
          updatePercentValueOption={props.setPercentValueOption}
        />
      </div>
      <CustomTable<IDiscountCashflowData>
        pdfTitle={'DCF Data'}
        id={'DCF Data'}
        columns={detailColumns}
        pdfColumns={pdfColumns}
        showToolbar
        data={tableData}
        // toolbarComponents={{
        //     toolbarTitle: formattedNames[tableType],
        // }}
        options={{
          paging: false,
          search: false,
          exportButton: true,
          sorting: false,
        }}
      />
    </GridItem>
  );
};

export default DiscountCashflowConfigurationTable;
