import React from 'react';
import Pages from './routes/Pages';
import './styling/base.css';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Layout from './components/layout/layouts/MasterLayout.component';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from './styling/theme';

const useStyles = makeStyles(() => ({
  app: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
  },
}));

const App: React.FC = () => {
  const classes = useStyles();

  // Get the url to check if we are on the login page.
  const { pathname } = useLocation();
  const [_, section, __page] = pathname.split('/');

  return (
    <DndProvider backend={HTML5Backend}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div className={classes.app}>
            <Layout section={section}>
              <Pages />
            </Layout>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    </DndProvider>
  );
};

export default App;
