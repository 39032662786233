import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MarketsAssets, {
  MarketsAssetsProp,
} from './subComponents/MarketsAssets';
import MarketsGraph, { MarketsGraphProp } from './subComponents/MarketsGraph';
import MarketsNews from './subComponents/MarketsNews';
import { mainColors } from '../../../styling/theme';
import { DataObject } from '../../../types/redux/data/dataTypes';


const useStyles = makeStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      margin: '2em 2em 0 2em',
      width: '100%',
      height: '100%',
      gap: '2em',
    },
    bottom: {
      display: 'flex',
      gap: '2em',
    },
  });

  interface MarketsPageProps {
    responsef: DataObject;
  }
  

  const MarketsPage: React.FC<MarketsPageProps> = ({ responsef }) => {

    const classes = useStyles();
    const [marketAssets, setMarketAssets] = useState<MarketsAssetsProp[]>([]);
    const [marketGraphData, setMarketGraphData] = useState<MarketsGraphProp[]>([]);
    const [displayedNews, setDisplayedNews] = useState<string>('all');
    const [displayedGraph, setDisplayedGraph] = useState<string>('');
    const [lineColour, setLineColour] = useState<string>(mainColors.Fail_darker);

    useEffect(() => {
        const makeAssets = () => {
        try {
            
          if (responsef) {
              const res = responsef.data[0];
                // Market Assets Prop maker
                const mappedData_Assets: MarketsAssetsProp[] = res.data_table;
                const sortedData_Assets = mappedData_Assets.sort((a: MarketsAssetsProp, b: MarketsAssetsProp) => {
                const var1dA = a.var_ratio.value as number;  
                const var1dB = b.var_ratio.value as number;
                return var1dB - var1dA;
            });
            setMarketAssets(sortedData_Assets);
            const firstAssetName = sortedData_Assets[0].asset_name.value;
            setDisplayedGraph(String(firstAssetName));

            // Markets Graph Prop maker
            const mappedData_Graph: MarketsGraphProp[] = Object.keys(res.data_table).map((key) => {
            const assetData_Graph = res.data_table[key];
            if (assetData_Graph.timeseries && Array.isArray(assetData_Graph.timeseries.value)) {
            return {
                asset: assetData_Graph.asset_name?.value || key,
                time: assetData_Graph.timeseries.value.map((dataPoint: [string, number]) => ({
                time: dataPoint[0], 
                value: dataPoint[1], 
                })),
            };
            }
            return {
            asset: assetData_Graph.asset_name?.value || key,
            time: [],
            };
        });

          setMarketGraphData(mappedData_Graph);
          }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        };

        makeAssets();
    }, []);
    

    return (  
        <div className={classes.container}>
          <MarketsAssets
            market_assets_data={marketAssets}
            setGraph={setDisplayedGraph}
            setNews={setDisplayedNews}
            setLineColour={setLineColour}
          />
          <div className={classes.bottom}>
            <MarketsGraph
              market_graph_data={marketGraphData}
              which_asset={displayedGraph}
              line_colour={lineColour}
            />
            <MarketsNews which_news={displayedNews} />
          </div>
        </div>
    );
}
 
export default MarketsPage;