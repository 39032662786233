import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Rectangle,
  RectangleProps,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import { mainColors } from '../../../../../styling/theme';
import {
  percentageToNdecialPlaces,
  percentageToNdecialPlacesAddZeros,
  roundToNDecimalPlacesReturnNumber,
} from '../../../../../utilities/numberFormatters';

interface RelativeRiskChartProps {
  data: any;
}

type RelativeRiskChartData = {
  dataType: string;
  index: string;
  max: number;
  upperQuartile: number;
  median: number;
  lowerQuartile: number;
  min: number;
  var: number;
  bottomWhisker: number;
  bottomBox: number;
  topBox: number;
  topWhisker: number;
  size: number; // for dot size
};

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 2.5rem 1rem 1.5rem',
  },
  cardTitle: {
    color: mainColors.mainBlue,
    fontSize: '2.2rem',
    fontWeight: 400,
    margin: '1rem 1.5rem',
  },
  customTooltipContainer: {
    backgroundColor: 'white',
    padding: '1rem 1.5rem',
    borderRadius: '0.5rem',
    border: `1px solid ${mainColors.mainBlue}`,
    width: '25rem',
    color: mainColors.mainBlue,
  },
  tooltipTitle: {
    fontSize: '2rem',
    fontWeight: 500,
  },
  tooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tooltipItemLabel: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: mainColors.mainBlue,
  },
  tooltipItemLabelValue: {
    fontSize: '1.6rem',
    fontWeight: 500,
    color: mainColors.mainBlue,
  },
  tooltipRelativeRiskLabel: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: mainColors.Fail,
  },
  tooltipRelativeRiskValue: {
    fontSize: '1.6rem',
    fontWeight: 500,
    color: mainColors.Fail,
  },
}));

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    return (
      <div className={classes.customTooltipContainer}>
        <div>
          <div className={classes.tooltipTitle}>{label}</div>
          <hr />
          <div className={classes.tooltipItem}>
            <div className={classes.tooltipRelativeRiskLabel}>VaR: </div>
            <div className={classes.tooltipRelativeRiskValue}>
              {percentageToNdecialPlacesAddZeros(payload[0].payload.var, 2)}
            </div>
          </div>
          <hr />
          <div className={classes.tooltipItem}>
            <div className={classes.tooltipItemLabel}>Max: </div>
            <div className={classes.tooltipItemLabelValue}>
              {percentageToNdecialPlacesAddZeros(payload[0].payload.max, 2)}
            </div>
          </div>
          <div className={classes.tooltipItem}>
            <div className={classes.tooltipItemLabel}>Upper Quartile: </div>
            <div className={classes.tooltipItemLabelValue}>
              {percentageToNdecialPlacesAddZeros(
                payload[0].payload.upperQuartile,
                2
              )}
            </div>
          </div>

          <div className={classes.tooltipItem}>
            <div className={classes.tooltipItemLabel}>Median: </div>
            <div className={classes.tooltipItemLabelValue}>
              {percentageToNdecialPlacesAddZeros(payload[0].payload.median, 2)}
            </div>
          </div>
          <div className={classes.tooltipItem}>
            <div className={classes.tooltipItemLabel}>Lower Quartile: </div>
            <div className={classes.tooltipItemLabelValue}>
              {percentageToNdecialPlacesAddZeros(
                payload[0].payload.lowerQuartile,
                2
              )}
            </div>
          </div>
          <div className={classes.tooltipItem}>
            <div className={classes.tooltipItemLabel}>Min: </div>
            <div className={classes.tooltipItemLabelValue}>
              {percentageToNdecialPlacesAddZeros(payload[0].payload.min, 2)}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const HorizonBar = (props: RectangleProps) => {
  const { x, y, width, height } = props;

  if (x == null || y == null || width == null || height == null) {
    return null;
  }

  return (
    <line x1={x} y1={y} x2={x + width} y2={y} stroke={'#000'} strokeWidth={2} />
  );
};

const CustomBox = (props: any) => {
  return (
    <Rectangle
      {...props}
      fill={
        props.payload.dataType === 'fund'
          ? mainColors.Pass
          : mainColors.mainBlue_lighter
      }
    />
  );
};

const DotBar = (props: RectangleProps) => {
  const { x, y, width, height } = props;

  if (x == null || y == null || width == null || height == null) {
    return null;
  }

  return (
    <line
      x1={x + width / 2}
      y1={y + height}
      x2={x + width / 2}
      y2={y}
      stroke={'#000'}
      strokeWidth={3}
      stroke-dasharray={'5'}
    />
  );
};

const buildChartData = (inputData: any): RelativeRiskChartData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: RelativeRiskChartData[] = [];
    inputData.data[0].fund_data.map((fund: any) => {
      returnData.push({
        dataType: fund.data_type,
        index: fund.index,
        max: fund.max,
        upperQuartile: fund.upperQuartile,
        median: fund.median,
        lowerQuartile: fund.lowerQuartile,
        min: fund.min,
        var: fund.fund_var,
        bottomWhisker: fund.lowerQuartile - fund.min,
        bottomBox: fund.median - fund.lowerQuartile,
        topBox: fund.upperQuartile - fund.median,
        topWhisker: fund.max - fund.upperQuartile,
        size: 150,
      });
    });
    inputData.data[0].index_data.map((index: any) => {
      returnData.push({
        dataType: index.data_type,
        index: index.index,
        max: index.max,
        upperQuartile: index.upperQuartile,
        median: index.median,
        lowerQuartile: index.lowerQuartile,
        min: index.min,
        var: index.index_var,
        bottomWhisker: index.lowerQuartile - index.min,
        bottomBox: index.median - index.lowerQuartile,
        topBox: index.upperQuartile - index.median,
        topWhisker: index.max - index.upperQuartile,
        size: 150,
      });
    });
    return returnData;
  }
};

const RelativeRiskChart: React.FC<RelativeRiskChartProps> = (props) => {
  const classes = useStyles();

  const builtChartData = buildChartData(props.data);

  return (
    <GridItem xs={12} card>
      <div className={classes.toolbar}>
        <h2 className={classes.cardTitle}>Relative Value At Risk</h2>
      </div>
      <ResponsiveContainer minHeight={560}>
        <ComposedChart
          data={builtChartData}
          margin={{ top: 10, right: 50, bottom: 20, left: 30 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <Bar stackId={'a'} dataKey={'min'} fill={'none'} />
          <Bar stackId={'a'} dataKey={'bar'} shape={<HorizonBar />} />
          <Bar stackId={'a'} dataKey={'bottomWhisker'} shape={<DotBar />} />
          <Bar stackId={'a'} dataKey={'topBox'} shape={<CustomBox />} />
          <Bar stackId={'a'} dataKey={'bar'} shape={<HorizonBar />} />
          <Bar stackId={'a'} dataKey={'bottomBox'} shape={<CustomBox />} />
          <Bar stackId={'a'} dataKey={'topWhisker'} shape={<DotBar />} />
          <Bar stackId={'a'} dataKey={'bar'} shape={<HorizonBar />} />
          <ZAxis type="number" dataKey="size" range={[0, 150]} />

          <Scatter dataKey="var" fill={'red'} stroke={'#FFF'} />

          <Tooltip content={<CustomTooltip />} />
          <XAxis
            dataKey="index"
            angle={-25}
            textAnchor="end"
            height={100}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
              fontWeight: 500,
            }}
            tickLine={true}
            interval={0}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '200%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              value={'Index'}
              position={'insideBottom'}
            />
          </XAxis>
          <YAxis
            width={70}
            tickFormatter={(tickItem) => {
              return percentageToNdecialPlaces(tickItem, 2);
            }}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
            domain={[
              (dataMin: number) =>
                roundToNDecimalPlacesReturnNumber(dataMin, 2),
              (dataMax: number) =>
                roundToNDecimalPlacesReturnNumber(dataMax + 0.01, 2),
            ]}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '250%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              angle={270}
              value={'VaR %'}
              position={'insideLeft'}
            />
          </YAxis>
        </ComposedChart>
      </ResponsiveContainer>
    </GridItem>
  );
};

export default RelativeRiskChart;
