import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getControlBarComponentsSelector,
  isControlBarShowingSelector,
} from '../../../redux/ui/controlbar/selectors';
import theme, { mainColors } from '../../../styling/theme';
import { zIndexes } from '../../../styling/zIndexes';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  controlBarRoot: {
    height: '6rem',
    width: '100vw',
    padding: '1rem 2rem 1rem 0.5rem',
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: zIndexes.titleBar,
  },
  controlBarContents: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  toggleIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: mainColors.Fail,
    cursor: 'pointer',
    '& svg': {
      fontSize: '3rem',
    },
    '&:hover': {
      color: mainColors.Fail_darker,
    },
    '&:active': {
      color: mainColors.Fail_even_darker,
    },
  },
});

const ControlBar: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const controlBarIsShowing = useSelector(isControlBarShowingSelector);
  const controlBarComponents = useSelector(getControlBarComponentsSelector);

  return (
    <div className={classes.controlBarRoot}>
      <div className={classes.controlBarContents}>
        {/*
        Getting rid of the option of closing the control bar for now (may want to add back in future) - DC 2023-12-20
        <div>
          <div
            className={classes.toggleIcon}
            onClick={() =>
              dispatch(toggleControlBarShowing(!controlBarIsShowing))
            }
          >
            <RemoveCircle />
          </div>
        </div> */}
        {controlBarComponents
          ? controlBarComponents.map(
              (controlBarComponent: ReactElement) => controlBarComponent
            )
          : null}
      </div>
    </div>
  );
};

export default ControlBar;
