import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { KiidsEditorOverviewTableData } from './KiidsEditorOverview.component';
import KiidsEditorOverviewSecondLevelTable from './KiidsEditorOverviewSecondLevelTable.coponent';
import { Dispatch } from 'react';

const KiidsEditorOverviewSecondLevelTableWrapper = (
  setSelectedKiid: Dispatch<any>,
  setCanBePublished: Dispatch<any>,
  setViewTrackedChanges: Dispatch<any>,
  setViewReview: Dispatch<any>,
  setTrackedChangesVersions: Dispatch<any>
) => [
  (rowData: KiidsEditorOverviewTableData) => {
    const icon = () =>
      rowData.secondLevelData.length ? <UnfoldMore /> : <></>;
    const render = () => (
      <KiidsEditorOverviewSecondLevelTable
        data={rowData.secondLevelData}
        setSelectedKiid={setSelectedKiid}
        setCanBePublished={setCanBePublished}
        setViewTrackedChanges={setViewTrackedChanges}
        setViewReview={setViewReview}
        setTrackedChangesVersions={setTrackedChangesVersions}
      />
    );
    return {
      disabled: !rowData.secondLevelData.length,
      icon,
      openIcon: UnfoldLess,
      render,
    };
  },
];

export default KiidsEditorOverviewSecondLevelTableWrapper;
