import React, { FC } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import { percentageToTwoDecimalPlacesNoHundred } from '../../../../utilities/numberFormatters';
import CustomTable from '../../../tables/CustomTable';

export interface AssetAmlLevelGrossExposureProps {
  dataForRender: DataObject;
  fundId: string;
  fundName: string;
}
export interface IAmlExposure {
  name: string;
  grossExposure: string;
}
// export function buildAmlLevelExposure(data: any): ICountryCodeExposure[] {
//     // Loop through each country and create an array of all data
//     const finalData = [];
//     let i = 0;
//     for (i = 0; i < data.country_code_exposure.gross.length; i++) {
//         const countryCode = data.country_code_exposure.gross[i][0];
//         const grossExposure = data.country_code_exposure.gross[i][1];
//         const riskRank =
//             'risk_rank' in data.country_code_exposure
//                 ? data.country_code_exposure.risk_rank[countryCode]
//                 : '';
//         finalData.push({
//             countryCode: countryCode,
//             grossExposure:
//                 grossExposure === 0
//                     ? '0%'
//                     : percentageToTwoDecimalPlaces(grossExposure),
//             riskRank: riskRank,
//         });
//     }

//     return finalData.filter(
//         (item: any) => !(item.grossExposure === '0%' || item.countryCode === '')
//     );
// }

export function buildOldAmlExposure(inputData: any[]) {
  if (!inputData.length) return [];

  const data = inputData[0];
  if ('gross' in data.aml_exposure) return [];
  // Get the gross AML exposure data
  // const grossAmlExposure = data.aml_exposure.gross.slice(1, data.aml_exposure.gross.length);
  // Get the headers from the first list emelemt
  const names = data.aml_exposure[0];
  const values = data.aml_exposure[1];

  // Create a list for storing the data.
  const returnData: any[] = [];

  // Loop through each element in the lists and add to the data.
  for (let i = 0; i < names.length; i++) {
    returnData.push({
      name: names[i],
      grossExposure: percentageToTwoDecimalPlacesNoHundred(values[i]),
    });
  }
  return returnData;
  // return grossAmlExposure.map((el: any[]) => {
  //   return {
  //     name: el[0],
  //     Exposure: el[1],
  //   };
  // });
}

export function buildAmlLevelExposure(inputData: any[]) {
  if (!inputData.length) return [];

  const data = inputData[0];
  if (!('aml_exposure' in data)) return [];
  if ('gross' in data.aml_exposure) return [];
  if (data.aml_exposure[0].includes('Country'))
    return buildOldAmlExposure(inputData);
  // Get the gross AML exposure data
  // const grossAmlExposure = data.aml_exposure.gross.slice(1, data.aml_exposure.gross.length);
  // Get the headers from the first list emelemt
  // const names = data.aml_exposure[0];
  // const values = data.aml_exposure[1];
  // ignore the first element of array, these are the categories.
  const values = data.aml_exposure.slice(1);

  // Create a list for storing the data.
  const returnData: any[] = [];
  // Keep track of total exposure.
  let totalExposure = 0;
  // loop through the data and populate returnData.
  values.forEach((item: any) => {
    totalExposure += item[1];
    returnData.push({
      riskType: item[0],
      exposure: percentageToTwoDecimalPlacesNoHundred(
        item[0] === 'Total' ? totalExposure : item[1]
      ),
      riskCategory: item[2],
      riskPoints: item[3],
    });
  });
  // // Loop through each element in the lists and add to the data.
  // for (let i = 0; i < names.length; i++) {
  //     returnData.push({
  //         name: names[i],
  //         grossExposure: percentageToTwoDecimalPlacesNoHundred(values[i])
  //     });
  // };
  return returnData;
  // return grossAmlExposure.map((el: any[]) => {
  //   return {
  //     name: el[0],
  //     Exposure: el[1],
  //   };
  // });
}

export const useAssetAmlLevelExposureColumns =
  (): CustomColumn<IAmlExposure>[] => {
    const generalStyle = {
      fontSize: '1.6rem',
      padding: '1rem',
    };
    return [
      {
        title: 'Risk Type',
        width: '25%',
        field: 'riskType',
        headerStyle: { textAlign: 'center' },
        cellStyle: { ...generalStyle, textAlign: 'center' },
      },
      {
        title: 'Exposure',
        width: '25%',
        field: 'exposure',
        headerStyle: { textAlign: 'center' },
        cellStyle: { ...generalStyle, textAlign: 'center' },
      },
      {
        title: 'Risk Category',
        width: '25%',
        field: 'riskCategory',
        headerStyle: { textAlign: 'center' },
        cellStyle: { ...generalStyle, textAlign: 'center' },
      },
      {
        title: 'Risk Points',
        width: '25%',
        field: 'riskPoints',
        headerStyle: { textAlign: 'center' },
        cellStyle: { ...generalStyle, textAlign: 'center' },
      },
      // {
      //     title: 'Risk Rank',
      //     field: 'riskRank',
      //     width: '33%',
      //     cellStyle: { ...generalStyle, textAlign: 'center' },
      //     headerStyle: { textAlign: 'center' },
      // },
    ];
  };
export const useOldAssetAmlLevelExposureColumns =
  (): CustomColumn<IAmlExposure>[] => {
    const generalStyle = {
      fontSize: '1.6rem',
      padding: '1rem',
    };
    return [
      {
        title: 'Name',
        width: '50%',
        field: 'name',
        headerStyle: { textAlign: 'center' },
        cellStyle: { ...generalStyle, textAlign: 'center' },
      },
      {
        title: 'Gross Exposure',
        width: '50%',
        field: 'grossExposure',
        headerStyle: { textAlign: 'center' },
        cellStyle: { ...generalStyle, textAlign: 'center' },
      },
      // {
      //     title: 'Risk Rank',
      //     field: 'riskRank',
      //     width: '33%',
      //     cellStyle: { ...generalStyle, textAlign: 'center' },
      //     headerStyle: { textAlign: 'center' },
      // },
    ];
  };

const AssetAmlLevelExposure: FC<AssetAmlLevelGrossExposureProps> = (props) => {
  const { fundId, fundName } = props;
  const { data } = props.dataForRender;
  const renderedData = !data.length ? [] : buildAmlLevelExposure(data);
  const columns = renderedData.length
    ? 'name' in renderedData[0]
      ? useOldAssetAmlLevelExposureColumns()
      : useAssetAmlLevelExposureColumns()
    : useAssetAmlLevelExposureColumns();

  ////   const renderedData = buildAssetAmlLevelExposure(data);
  //const classes = useAssetAmlLevelExposureStyles();
  return (
    <CustomTable<IAmlExposure>
      selectedPositionDate={data[0].selected_position_date}
      multipleTablePdfParameters={{
        specifiedWidth: 4,
        tablesInRow: 3,
      }}
      id={`${fundId}_asset_aml_exposure`}
      title="AML Level Exposure"
      showToolbar={true}
      loading={props.dataForRender.isFetching}
      csvFields={['riskType', 'exposure', 'riskCategory', 'riskPoints']}
      toolbarComponents={{
        toolbarTitle: 'AML Exposure',
      }}
      columns={columns}
      data={renderedData}
      options={{
        paging: false,
        search: false,
        exportButton: true,
      }}
    />
  );
};

export default AssetAmlLevelExposure;
