import { FC, useState } from 'react';
import { greys, mainColors } from '../../../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import RaptorSelect from '../../../../selects/RaptorSelect.component';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles(() => ({
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
    zIndex: 10,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
    maxWidth: 'none',
    pointerEvents: 'auto',
    zIndex: 10,
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    marginRight: '1.2rem',
    height: '5rem',
  },
  runButton: {
    display: 'flex',
    jsutifyContent: 'center',
    alignItems: 'center',
  },
  selectContainer: {
    width: '100%',
    minWidth: '10rem',
    // marginLeft: '10rem',
  },
  trackedChangesContainer: {
    display: 'flex',
    // border: '1px solid black',
    borderRadius: '10px',
    padding: '5px',
    gap: '5px',
    boxSizing: 'border-box',
  },
}));

interface TrackedChangesButtonProps {
  fundId: string | null;
  shareClass: string;
  documentLanguage: string;
  versionNumbers: string[];
  latestVersionNumber: string;
}

const ViewSpecificVersionButton: FC<TrackedChangesButtonProps> = ({
  fundId,
  shareClass,
  documentLanguage,
  versionNumbers,
  latestVersionNumber,
}) => {
  const classes = useStyles();

  const soretedVersionNumbers = versionNumbers.sort((a: string, b: string) => {
    // Split the version numbers into their parts
    const aParts = a.split('.');
    const bParts = b.split('.');
    // Compare the major version numbers (id not equal)
    if (aParts[0] !== bParts[0]) {
      return parseInt(aParts[0]) - parseInt(bParts[0]);
    } else if (aParts[1] !== bParts[1]) {
      // Compare the minor version numbers (if not equal)
      return parseInt(aParts[1]) - parseInt(bParts[1]);
    } else {
      // Compare the patch version numbers (if not equal)
      return parseInt(aParts[2]) - parseInt(bParts[2]);
    }
  });
  const [selectedVersion, setSelectedVersion] = useState(latestVersionNumber);

  const history = useHistory();
  return (
    <div className={classes.trackedChangesContainer}>
      <Tooltip
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        title="Select Version Number"
        placement={'top'}
        arrow
      >
        <div className={classes.selectContainer}>
          <RaptorSelect
            options={soretedVersionNumbers}
            handleChoice={setSelectedVersion}
            defaultOption={selectedVersion}
          />
        </div>
      </Tooltip>
      <div className={classes.runButton}>
        <VisibilityIcon
          sx={{
            fontSize: '3rem',
            color: 'white',
            backgroundColor: mainColors.mainBlue,
            borderRadius: 1,
            padding: '0.2rem',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: mainColors.mainBlue_lighter,
            },
          }}
          onClick={() => {
            history.push(
              `view?fundId=${fundId}&isin=${shareClass}&documentLanguage=${documentLanguage}&version=${selectedVersion}&latestVersion=${latestVersionNumber}`
            );
          }}
        />
      </div>
    </div>
  );
};

export default ViewSpecificVersionButton;
