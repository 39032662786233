import React, { FC, useEffect } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import CounterpartyStressTestsTable from './CounterpartyStressTestsTable.component';
import { useDispatch } from 'react-redux';
import {
  addAdditionalTitleBarComponents,
  toggleTitleBarLoading,
} from '../../../../redux/pages/actions';
import { Select } from '../../../../types/redux/pages/PagesStore';
import { APPEND_NEW_SELECT_TO_TITLEBAR } from '../../../../types/redux/pages/pageTypes';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';

const CounterpartyStressTests: FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
}) => {
  const counterpartyStressTestData = useFetchData({
    url: `stored_report_data_agg/${fundId}/cp_aggregate_stress_tests/${positionDate}`,
    keyName: `${fundId}_stress_scenario_test_data_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  const stressTestRestrictions = useFetchData({
    url: `stress_test_restrictions/${fundId}`,
    keyName: `${fundId}_stress_test_restrictions`,
    makeFetch: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleTitleBarLoading(true));
    if (counterpartyStressTestData && !counterpartyStressTestData.isFetching) {
      const selectsToAdd: Select[] = [
        {
          titleBarTitle: 'Counterparty:',
          titleBarKey: 'counterparty',
          values: Object.keys(counterpartyStressTestData.data[0]).map(
            (counterparty) => ({
              label: counterparty,
              value: counterparty,
            })
          ),
          displayOnOverviewPage: false,
          aValueIsAlwaysSelected: true,
          currentValue: Object.keys(counterpartyStressTestData.data[0])[0],
        },
      ];
      dispatch(
        addAdditionalTitleBarComponents(
          selectsToAdd,
          APPEND_NEW_SELECT_TO_TITLEBAR
        )
      );
      dispatch(toggleTitleBarLoading(false));
    }
  }, [counterpartyStressTestData]);

  const chosenCounterparty = useTitleBarSelect('counterparty');

  return (
    <GeneralComponentErrorShield
      dataObjects={[counterpartyStressTestData, stressTestRestrictions]}
      customLoadingMessages={[
        'Loading Counterparty Stress Test Data',
        'Just a few more seconds...',
      ]}
      customErrorMessage="No Data Available."
    >
      <CounterpartyStressTestsTable
        counterpartyStressTestData={counterpartyStressTestData}
        stressTestRestrictions={stressTestRestrictions}
        counterparty={chosenCounterparty}
      />
    </GeneralComponentErrorShield>
  );
};

export default CounterpartyStressTests;
