import React, { ReactElement, useEffect } from 'react';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  AdaptedYAxis,
  IGenericLineChartProps,
  LineDescriptor,
} from '../../types/components/charts/lineCharts';
import ExportButton from '../feedback/ExportButton';
import FileSaver from 'file-saver';
import ChartDownloadButton from '../buttons/ChartDownloadButton';
import usePngFromRecharts from '../../hooks/usePngFromRecharts';
import { useDispatch } from 'react-redux';
import { addComponentToPdfExport } from '../../redux/pdfExport/actions';
import { PdfComponentType } from '../../types/redux/pdfExports/pdfExportsStore';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, useTheme } from '@mui/material';

const useStyles = makeStyles({
  center: {
    margin: '0 auto',
    fontSize: '1.4rem',
  },
});

function GenericLineChart({
  data,
  height,
  width,
  lines,
  title,
  margin,
  error,
  ...props
}: IGenericLineChartProps): ReactElement {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { ref, handleDownload } = usePngFromRecharts();

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: props.id || 'no_id',
        handler: handleDownload,
        type: PdfComponentType.LINE_CHART,
        title: props.pdfTitle ? props.pdfTitle : title,
        dontMoveToNewPage: props.pdfDontmoveToNewPage ? true : false,
        specificYPosition: props.pdfSpecificYPosition
          ? props.pdfSpecificYPosition
          : undefined,
        specificXPosition: props.pdfSpecificXPosition
          ? props.pdfSpecificXPosition
          : undefined,
        alignment: props.pdfAligment,
        dontIncrementYPosition: props.pdfDontIncrementYPosition,
      })
    );
  }, [ref]);

  const saveImage = async () => {
    const imageData = await handleDownload();
    if (imageData) {
      FileSaver.saveAs(imageData.data, title + '.png');
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <div
          id="chart-title"
          style={
            props.smallTitle
              ? { width: 'calc(100% - 20rem)', textAlign: 'center' }
              : { width: '100%' }
          }
        >
          <Typography
            variant={props.smallTitle ? 'h5' : 'h2'}
            style={{ color: theme.palette.primary.main }}
          >
            {props.showTitle && title}
          </Typography>
        </div>

        <div id="export_area" style={{ display: 'flex', alignItems: 'center' }}>
          {props.export && (
            <ExportButton exportData={data} fields={Object.keys(data[0])} />
          )}
          {props.download && (
            <ChartDownloadButton handler={saveImage} title={title} />
          )}
        </div>
      </div>
      <ResponsiveContainer
        width={width}
        height={height}
        className={classes.center}
      >
        <LineChart data={data} margin={margin} ref={ref}>
          <CartesianGrid
            strokeDasharray="3 3"
            fill={props.backgroundColor || 'transparent'}
          />
          <XAxis
            dataKey={props.xAxisDataKey || 'name'}
            interval={props.xInterval || 'preserveStartEnd'}
            minTickGap={props.minTickGap || 30}
            tick={props.showXTick && { dy: 5 }}
            label={props.xAxisLabel}
            width={props.xAxisWidth || undefined}
            height={props.xAxisHeight || undefined}
          />
          <YAxis
            domain={
              props.yAxisDomain || [
                (dataMin: number) => dataMin - Math.abs(dataMin * 0.001),
                (dataMax: number) => dataMax + Math.abs(dataMax * 0.001),
              ]
            }
            tickFormatter={props.yAxisTickFormatter || null}
            interval="preserveStartEnd"
            label={props.yAxisLabel || null}
            ticks={props.yAxisTicks}
            tick={props.showYTicks}
            yAxisId="left"
            scale="linear"
            width={props.yAxisWidth || undefined}
            height={props.yAxisHeight || undefined}
            allowDataOverflow={false}
            orientation={props.yAxisOrientation || 'left'}
          />

          {props.additionalYAxis ? (
            props.additionalYAxis.map((el: AdaptedYAxis, index: number) => {
              return (
                <YAxis
                  key={title}
                  scale="linear"
                  domain={[
                    (dataMin: number) => {
                      return dataMin - dataMin * 0.1;
                    },
                    (dataMax: number) => dataMax + dataMax * 0.1,
                  ]}
                  {...el}
                />
              );
            })
          ) : (
            <></>
          )}

          {props.showTooltip !== false ? (
            <Tooltip formatter={props.tooltipFormatter || null} />
          ) : null}
          {props.showLegend && (
            <Legend
              wrapperStyle={
                props.legendWrapper ? props.legendWrapper : { bottom: 0 }
              }
              formatter={props.legendFormatter}
              verticalAlign={props.legendVAlign}
            />
          )}
          {props.referenceLineY && (
            <ReferenceLine
              y={props.referenceLineY}
              yAxisId="left"
              stroke="red"
              strokeDasharray="3 3"
            >
              <Label position={'bottom'}>
                {props.referenceLineYLabel
                  ? props.referenceLineYLabel
                  : undefined}
              </Label>
            </ReferenceLine>
          )}

          {lines.map((line: LineDescriptor) => {
            return (
              <Line
                dot={props.showDot && true}
                type={props.monotone ? 'monotone' : 'linear'}
                dataKey={line.dataKey}
                stroke={line.color}
                strokeWidth={line.strokeWidth || props.strokeWidth || 1}
                key={line.dataKey + title}
                yAxisId={line.yAxisId || 'left'}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default React.memo(GenericLineChart);
