import React from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToNdecialPlaces,
} from '../../../../../../utilities/numberFormatters';
import SecondLevelWrapper from './SecondLevelWrapper.component';
import { SecondLevelTableData } from './SecondLevelTable.component';

interface CurrencyVsFxTableProps {
  data: any;
}

export interface CurrencyVsFxTableData {
  currency: string;
  fx_pl_pct: number;
  currency_pl_pct: number;
  diff_pl_pct: number;
  underlyingAssets: SecondLevelTableData[];
}

addCommasToNumbersAndRound;

const detailColumns: CustomColumn<CurrencyVsFxTableData>[] = [
  {
    title: 'Currency',
    field: 'currency',
    width: '25%',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'FX PL %',
    field: 'fx_pl_pct',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: CurrencyVsFxTableData) =>
      percentageToNdecialPlaces(rowData.fx_pl_pct / 100, 2),
  },
  {
    title: 'Currency PL %',
    field: 'currency_pl_pct',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: CurrencyVsFxTableData) =>
      percentageToNdecialPlaces(rowData.currency_pl_pct / 100, 2),
  },
  {
    title: 'Difference PL %',
    field: 'diff_pl_pct',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: CurrencyVsFxTableData) =>
      percentageToNdecialPlaces(rowData.diff_pl_pct / 100, 2),
  },
];

const buildTableData = (inputData: any): CurrencyVsFxTableData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: CurrencyVsFxTableData[] = [];
    // we want to always ensure the 'ALL' currency is at the bottom of the table
    Object.keys(inputData.data[0].currency_wise_daily_returns.table_data).map(
      (currency_key: any) => {
        if (currency_key !== 'ALL') {
          returnData.push({
            currency: currency_key,
            fx_pl_pct:
              inputData.data[0].currency_wise_daily_returns.table_data[
                currency_key
              ].fx_pl,
            currency_pl_pct:
              inputData.data[0].currency_wise_daily_returns.table_data[
                currency_key
              ].currency_pl,
            diff_pl_pct:
              inputData.data[0].currency_wise_daily_returns.table_data[
                currency_key
              ].difference,
            underlyingAssets:
              inputData.data[0].currency_wise_daily_returns.table_data[
                currency_key
              ].underlying,
          });
        }
      }
    );
    returnData.push({
      currency: 'ALL',
      fx_pl_pct:
        inputData.data[0].currency_wise_daily_returns.table_data['ALL'].fx_pl,
      currency_pl_pct:
        inputData.data[0].currency_wise_daily_returns.table_data['ALL']
          .currency_pl,
      diff_pl_pct:
        inputData.data[0].currency_wise_daily_returns.table_data['ALL']
          .difference,
      underlyingAssets:
        inputData.data[0].currency_wise_daily_returns.table_data['ALL']
          .underlying,
    });
    return returnData;
  }
};

const CurrencyVsFxTable: React.FC<CurrencyVsFxTableProps> = ({ data }) => {
  const builtTableData = buildTableData(data);

  const title = `Sub-Fund Historical Performance - Returns by Currency`;

  return (
    <GridItem xs={12} card>
      <CustomTable<CurrencyVsFxTableData>
        columns={detailColumns}
        showToolbar
        data={builtTableData}
        title={title}
        options={{
          paging: false,
          search: true,
          exportButton: true,
        }}
        detailPanel={SecondLevelWrapper()}
      />
    </GridItem>
  );
};

export default CurrencyVsFxTable;
