import InsightsIcon from '@mui/icons-material/Insights';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import { IconKey } from '../types/server-data/ClientConfig';
import MoabIcon from '../components/pages/liquidity/moab/MoabIcon';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import ShuffleOnIcon from '@mui/icons-material/ShuffleOn';
import RadarIcon from '@mui/icons-material/Radar';
import GppBadIcon from '@mui/icons-material/GppBad';
import FactoryIcon from '@mui/icons-material/Factory';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FeedIcon from '@mui/icons-material/Feed';
import {
  AccessAlarm,
  AccessTime,
  AcUnit,
  AlignHorizontalLeft,
  AllInbox,
  Assessment,
  Assignment,
  AssuredWorkload,
  AttachMoney,
  Book,
  Bookmarks,
  BubbleChartTwoTone,
  Cancel,
  ChangeHistory,
  Cloud,
  CloudUpload,
  CompareArrows,
  CropSquare,
  Directions,
  Dns,
  EnergySavingsLeaf,
  ErrorOutline,
  Extension,
  FlashOn,
  FormatListBulleted,
  Gavel,
  Group,
  History,
  Leaderboard,
  Lock,
  LowPriority,
  MonetizationOn,
  MonitorHeart,
  More,
  Numbers,
  Opacity,
  OpenWith,
  Polyline,
  RemoveRedEye,
  Restore,
  Rotate90DegreesCcw,
  Search,
  SettingsEthernet,
  ShowChart,
  Shuffle,
  Start,
  Storage,
  Store,
  SwapCalls,
  TableRows,
  Timeline,
  Toll,
  Visibility,
  Warning,
  Work,
  Business
} from '@mui/icons-material';

export function iconMap(iconName: IconKey) {
  switch (iconName) {
    case IconKey.SUB_FUND:
      return Dns;
    case IconKey.WARNING:
      return Warning;
    case IconKey.BOOK:
      return Book;
    case IconKey.EYE:
      return RemoveRedEye;
    case IconKey.TOLL:
      return Toll;
    case IconKey.SHARE_CLASS:
      return Work;
    case IconKey.EXPOSURE:
      return AcUnit;
    case IconKey.POSITION_CHANGES:
      return LowPriority;
    case IconKey.OPEN_WIDTH:
      return OpenWith;
    case IconKey.LAW:
      return Gavel;
    case IconKey.NOTES:
      return More;
    case IconKey.CANCEL:
      return Cancel;
    case IconKey.FAIL:
      return ErrorOutline;
    case IconKey.SHUFFLE:
      return Shuffle;
    case IconKey.STATUS_CHANGE:
      return SettingsEthernet;
    case IconKey.CLOUD_UP:
      return CloudUpload;
    case IconKey.RIGHT_TURN:
      return Directions;
    case IconKey.MOAB:
      return MoabIcon;
    case IconKey.FULL_CLOUD:
      return Cloud;
    case IconKey.VAR:
      return ChangeHistory;
    case IconKey.RST_FIXED:
      return AccessAlarm;
    case IconKey.LIQUIDATION_RISK_OVERVIEW:
      return BubbleChartTwoTone;
    case IconKey.RST_VARIABLE:
      return AccessTime;
    case IconKey.RISK:
      return Bookmarks;
    case IconKey.COUNTERPARTY:
      return Polyline;
    case IconKey.HISTORICAL:
      return Restore;
    case IconKey.FIXED_INCOME:
      return Lock;
    case IconKey.FUNCTIONALITY:
      return Extension;
    case IconKey.STRESS_TEST:
      return FlashOn;
    case IconKey.COMPARISON:
      return CompareArrows;
    case IconKey.BACKTEST:
      return Rotate90DegreesCcw;
    case IconKey.REPORTS:
      return Bookmarks;
    case IconKey.LIQUIDATION_TIME:
      return Opacity;
    case IconKey.CHART:
      return ShowChart;
    case IconKey.CURRENCY:
      return AttachMoney;
    case IconKey.CIS:
      return Storage;
    case IconKey.LIQUIDITY_OVERVIEW:
      return Visibility;
    case IconKey.KIID:
      return Assignment;
    case IconKey.ECO:
      return EnergySavingsLeaf;
    case IconKey.GRAPH_SQUARE:
      return Assessment;
    case IconKey.PREVIEW:
      return Assessment;
    case IconKey.GROUP:
      return Group;
    case IconKey.INSIGHTS:
      return InsightsIcon;
    case IconKey.MULTIPLE_STOP:
      return MultipleStopIcon;
    case IconKey.CASHFLOW:
      return MonetizationOn;
    case IconKey.HEAT:
      return LocalFireDepartmentIcon;
    case IconKey.TIMELINE:
      return Timeline;
    case IconKey.SHUFFLE_ON:
      return ShuffleOnIcon;
    case IconKey.DASHBOARD:
    case IconKey.FACT_CHECK:
      return FactCheckIcon;
    case IconKey.SQUARE:
      return CropSquare;
    case IconKey.RADAR:
      return RadarIcon;
    case IconKey.BAD:
      return GppBadIcon;
    case IconKey.FACTORY:
      return FactoryIcon;
    case IconKey.DOWNLOAD:
      return FileDownloadIcon;
    case IconKey.FEED:
      return FeedIcon;
    case IconKey.SWAP_CALLS:
      return SwapCalls;
    case IconKey.MONITOR_HEART:
      return MonitorHeart;
    case IconKey.BULLET_LIST:
      return FormatListBulleted;
    case IconKey.LEADERBOARD:
      return Leaderboard;
    case IconKey.NUMBERS:
      return Numbers;
    case IconKey.STORE:
      return Store;
    case IconKey.ALIGN_HORIZONTAL_LEFT:
      return AlignHorizontalLeft;
    case IconKey.SEARCH:
      return Search;
    case IconKey.ALL_INBOX:
      return AllInbox;
    case IconKey.TABLE_ROWS:
      return TableRows;
    case IconKey.START:
      return Start;
    case IconKey.HISTORY:
      return History;
    case IconKey.ASSURED_WORKLOAD:
      return AssuredWorkload;
    case IconKey.BUSINESS:
      return Business;
    default:
      return RemoveRedEye;
  }
}
