import {
  ClientConfiguration,
  Section,
} from '../../types/server-data/ClientConfig.js';
import { PagesStore } from '../../types/redux/pages/PagesStore.js';

// all of the page routes, e.g /ucits/overview or /dashboard
// export const allowedPageRoutes: string[] = fakeClientJson.sections.reduce(
//   (prev: string[], current) => {
//     const { sectionRoute } = current;
//     const arrOfReturnedRoutes: string[] = [...prev];
//     current.pages.forEach((page) => {
//       arrOfReturnedRoutes.push(page.pageRoute);
//     });
//     return arrOfReturnedRoutes;
//   },
//   ['/dashboard']
// );

// all of the page routes where you can also extend with a fund name
export const generateAllowedPageRoutesWherePagesAllowFunds = (
  clientConfig: ClientConfiguration
): string[] => {
  if (clientConfig.pages) {
    return Object.keys(clientConfig.pages).reduce(
      (prev: string[], current: any) => {
        const arrOfReturnedRoutes: string[] = [...prev];
        const currentPage = clientConfig.pages
          ? clientConfig.pages[current]
          : null;
        if (currentPage && currentPage.allowFundPages) {
          arrOfReturnedRoutes.push(currentPage.pageRoute);
        }
        return arrOfReturnedRoutes;
      },
      []
    );
  }
  return [];
};

// all of the valid routes where fund names are used
export const generateAllowedFundRoutes = (
  clientConfig: ClientConfiguration | null,
  funds: PagesStore['funds'] | null
) => {
  if (!clientConfig || !funds) return [];
  const allowedFundRoutes: string[] = [];
  if (clientConfig.sections) {
    clientConfig.sections.forEach((section: Section) => {
      section.pages.forEach((page: string) => {
        if (
          clientConfig.pages &&
          clientConfig.pages[page].allowFundPages &&
          funds &&
          section.fundTypes in funds
        ) {
          funds[section.fundTypes]!.fundData.forEach((fund) => {
            allowedFundRoutes.push(
              clientConfig.pages![page].pageRoute + '/' + fund.id
            );
          });
        }
      });
    });
  }
  return allowedFundRoutes;
};
