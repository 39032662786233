import React, { ReactElement } from 'react';
import { Cell, Label, Pie, PieChart } from 'recharts';
import { mainColors } from '../../styling/aifTheme';
import { greys } from '../../styling/theme';
import { Typography } from '@mui/material';

export interface GenericGaugeChartProps<T> {
  width: number;
  height: number;
  // fillValue represents the portion of the chart that will be coloured in.
  // fullValue represents the toal amount that the chart represents.
  // The chart will be coloured by dividing these two values.
  fillValue: number;
  fullValue: number;
  title: string;
  tooltipProps?: {
    formatter?: any;
    content?: any;
  };
  chartColor?: string;
  centerText?: string;
  // Start and end label will be displayed at each end of the gauge
  startLabel?: string | number;
  endLabel?: string | number;
  textBelow?: string;
}

function GenericGaugeChart<T extends object>({
  fillValue,
  fullValue,
  height,
  width,
  title,
  tooltipProps,
  chartColor,
  ...props
}: GenericGaugeChartProps<T>): ReactElement {
  // const classes = useStyles();
  // const theme = useTheme();
  // const dispatch = useDispatch();

  // const { ref, handleDownload } = usePngFromRecharts(title);

  // useEffect(() => {
  //   dispatch(
  //     addComponentToPdfExport({
  //       identifier: props.id || 'no_id',
  //       handler: handleDownload,
  //       type: PdfComponentType.LINE_CHART,
  //       title: props.pdfTitle ? props.pdfTitle : title,
  //     })
  //   );
  // }, [ref]);

  // const saveImage = async () => {
  //   const imageData = await handleDownload();
  //   if (imageData) {
  //     FileSaver.saveAs(imageData.data, title + '.png');
  //   }
  // };
  // percentageText: {
  //     fontSize: '1.8rem',
  //     position: 'absolute',
  //     top: '45%',
  //     left: '50%',
  //     transform: 'translate(-50%, -50%)',
  //     color: (props: StyleProps) =>
  //       (colorScale(props.pc_good) as unknown) as string,
  //     zIndex: -1,
  //   },
  const pieData = [{ value: fillValue }, { value: fullValue - fillValue }];
  const tooltipStuff = tooltipProps || {};
  return (
    <div style={{}}>
      <Typography
        variant="h1"
        style={{
          textAlign: 'center',
          fontSize: 'clamp(1.8rem, calc(3.1vw - 2.4rem), 3rem)',
        }}
      >
        {title}
      </Typography>
      {/* We multiply by 2 here as the height is for a full pie chart and we only display half of it here and offsel to allow for title*/}

      <PieChart
        height={height * 2 - height * 0.6}
        width={width}
        // style={{ transform: 'translate(0%, -5%)' }}
      >
        <Pie
          startAngle={180}
          endAngle={0}
          innerRadius="55%"
          data={pieData}
          dataKey="value"
          labelLine={false}
          blendStroke
          isAnimationActive={true}
        >
          {props.centerText && (
            <Label
              width={200}
              position="center"
              style={{
                fontSize: 'clamp(1.2rem, calc(3vw - 2.4rem), 3rem)',
                fontWeight: 400,
                fill: chartColor ? chartColor : mainColors.mainBlue,
                transform: 'translate(0%, -1.3vw)',
              }}
            >
              {props.centerText}
            </Label>
          )}
          {props.startLabel && (
            <Label
              width={30}
              position="center"
              style={{
                fontSize: 'clamp(1.2rem, calc(2.8vw - 2.4rem), 3rem)',
                fontWeight: 200,
                fill: mainColors.mainBlue,
                transform: 'translate(-30%, 5%)',
              }}
            >
              {props.startLabel}
            </Label>
          )}
          {props.endLabel && (
            <Label
              width={30}
              position="center"
              style={{
                fontSize: 'clamp(1.2rem, calc(2.8vw - 2.4rem), 3rem)',
                fontWeight: 200,
                fill: mainColors.mainBlue,
                transform: 'translate(30%, 5%)',
              }}
            >
              {props.endLabel}
            </Label>
          )}
          {props.textBelow && (
            <Label
              width={30}
              position="center"
              style={{
                fontSize: 'clamp(1.2rem, calc(2.8vw - 2.4rem), 3rem)',
                fontWeight: 200,
                fill: mainColors.mainBlue,
                transform: 'translate(0%, 10%)',
              }}
            >
              {props.textBelow}
            </Label>
          )}
          <Cell fill={chartColor ? chartColor : mainColors.mainBlue} />
          <Cell fill={greys.grey400} />
        </Pie>
      </PieChart>
    </div>
  );
}

export default React.memo(GenericGaugeChart);
