import { ContentState, Editor, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React, { FC, useEffect } from 'react';
import { Dispatch } from 'react';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../../tables/CustomTable';
import { roundNumberToTwoDecimalPlaces } from '../../../../../../utilities/numberFormatters';

interface TableEditorTileProps {
  factSheetElement: string;
  factSheetsData: any[];
  setFactSheetsData: Dispatch<any>;
  setHaveChangesBeenMade: Dispatch<any>;
  index: number;
  tag: string;
}

function buildTableData(data: any, hasHeader: boolean): TableEditorData[] {
  const tableData: TableEditorData[] = [];
  const dataCopy = JSON.parse(JSON.stringify(data));
  // Remove the header if the table has one.
  if (hasHeader) {
    dataCopy.shift();
  }
  // const columnKeys = Object.keys(data[0])
  dataCopy.forEach((value: any, index: number) => {
    const tableRow: any = {
      value0: '',
      value1: '',
      value2: '',
      value3: '',
      value4: '',
      value5: '',
      value6: '',
      value7: '',
      value8: '',
      value9: '',
      value10: '',
      valu11: '',
      valu12: '',
      valu13: '',
      index: index,
    };
    for (let i = 0; i < value.length; i++) {
      let valueToAdd = value[i];
      if (typeof valueToAdd === 'number') {
        valueToAdd = roundNumberToTwoDecimalPlaces(valueToAdd);
      }
      tableRow[`value${i}`] = valueToAdd;
    }
    tableData.push(tableRow);
  });

  return tableData;
}

// We allow a max of 10 columns in the table.
interface TableEditorData {
  value0: string;
  value1: string;
  value2: string;
  value3: string;
  value4: string;
  value5: string;
  value6: string;
  value7: string;
  value8: string;
  value9: string;
  value10: string;
  value11: string;
  value12: string;
  value13: string;
  index: number;
}

interface TableCellEditorBoxProps {
  initialText: string;
  onTextChange: (text: string) => void;
  setWidth?: string;
}

const TableCellEditorBox = React.memo(
  ({
    initialText,
    onTextChange,
    setWidth,
  }: TableCellEditorBoxProps): React.ReactElement => {
    const [editorState, setEditorState] = React.useState(
      EditorState.createEmpty()
    );

    useEffect(() => {
      setEditorState(
        EditorState.createWithContent(ContentState.createFromText(initialText))
      );
    }, []);

    const handleTextChange = (updatedEditorState: EditorState) => {
      setEditorState(updatedEditorState);
      let text = stateToHTML(updatedEditorState.getCurrentContent());

      text = text
        .replaceAll('<p>', '')
        .replaceAll('</p>', '')
        .replaceAll('<strong>', '<b>')
        .replaceAll('</strong>', '</b>')
        .replaceAll('<em>', '<i>')
        .replaceAll('</em>', '</i>');
      onTextChange(text);
    };

    return (
      <div
        style={{
          border: '0.5px solid',
          minHeight: '2rem',
          backgroundColor: 'white',
          width: setWidth ? setWidth : '18rem',
        }}
      >
        <Editor
          editorState={editorState}
          onChange={(updatedEditorState: EditorState) => {
            handleTextChange(updatedEditorState);
          }}
          stripPastedStyles={true}
        />
      </div>
    );
  }
);

function buildTableColumns(
  handleUpdateFunction: (
    rowIndex: number,
    columnIndex: number,
    text: string
  ) => void,
  tableColumns: string[],
  dataJson: any,
  hasHeader: boolean
): CustomColumn<TableEditorData>[] {
  const dataCopy = JSON.parse(JSON.stringify(dataJson));
  if (hasHeader) {
    dataCopy.shift();
  }
  const columns: CustomColumn<TableEditorData>[] = [
    {
      title: '#',
      field: 'index',
      cellStyle: {
        textAlign: 'center',
      },
      width: '1%',
      headerStyle: { textAlign: 'center' },
      render: (rowData: TableEditorData) => rowData.index + 1,
    },
  ];
  tableColumns.forEach((value: string, index: number) => {
    columns.push({
      title: hasHeader ? value : '',
      field: `value${index}`,
      cellStyle: {
        textAlign: 'center',
      },
      width: '18%',
      headerStyle: { textAlign: 'center' },
      // render: (rowData: TableEditorData) => <TableCellEditorBox initialText={getInitialText(dataJson, keys[index], rowData.index)} onTextChange={(text: string) => handleUpdateFunction(rowData.index, keys[index], text)} setWidth={`${(72 / keys.length) - 0.3}rem`} />
      render: (rowData: TableEditorData) => (
        <TableCellEditorBox
          initialText={
            dataCopy.length > rowData.index
              ? dataCopy[rowData.index][index]
              : ''
          }
          onTextChange={(text: string) =>
            handleUpdateFunction(rowData.index, index, text)
          }
          setWidth={`${72 / tableColumns.length - 0.3}rem`}
        />
      ),
    });
  });
  return columns;
}

function getMaxNumRows(tableType: string): number {
  switch (tableType) {
    case 'a4_esg_scores_table':
      return 8;
    case 'a4_asset_allocation_table':
      return 5;
    case 'a4_sector_table':
      return 12;
    default:
      return 15;
  }
}

const TableEditorTile: FC<TableEditorTileProps> = (props) => {
  const {
    factSheetElement,
    factSheetsData,
    setFactSheetsData,
    setHaveChangesBeenMade,
    index,
    tag,
  } = props;

  // Convert the data into an object.
  const [dataJson, setDataJson] = React.useState(JSON.parse(factSheetElement));

  // Create a table data object from the array.
  // const tableData = buildTableData(contentState);
  // Create an object for storing the state of each cell in the table.
  const [tableData, setTableData] = React.useState<TableEditorData[]>(
    buildTableData(
      tag.includes('fund_details_table') ? dataJson : dataJson.data,
      !tag.includes('fund_details_table')
    )
  );

  const handleTextChange = (
    rowIndex: number,
    columnIndex: number,
    text: string
  ) => {
    // Now update the element in the overall data
    const allContent = factSheetsData;
    // Check if the text has changes and if so indicate it
    const dataJsonCopy = dataJson;
    // if (dataJsonCopy.value[rowIndex][columnName] !== text)
    setHaveChangesBeenMade(true);

    // Update the value at the correct index in the list
    if (tag.includes('fund_details_table')) {
      dataJsonCopy[rowIndex][columnIndex] = text;
    } else {
      dataJsonCopy.data[rowIndex + 1][columnIndex] = text;
    }

    // dataJsonCopy.value[rowIndex][columnName] = text;
    setDataJson(dataJsonCopy);
    // Now replace in the overall array
    allContent[index].content = JSON.stringify(dataJsonCopy);

    // Update the overall data
    setFactSheetsData(allContent);
  };

  const columns = buildTableColumns(
    handleTextChange,
    tag.includes('fund_details_table') ? dataJson[0] : dataJson.data[0],
    tag.includes('fund_details_table') ? dataJson : dataJson.data,
    !tag.includes('fund_details_table')
  );

  const modifyTableRows = (addRemove: 'add' | 'remove') => {
    // Add another row to the table.
    if (addRemove === 'add') {
      // Get the keys that we need to populate in order to add a row.
      const numCols = tag.includes('fund_details_table')
        ? dataJson[0].length
        : dataJson.data[0].length;
      // Create a new row to add to the table.
      const newRow: any = [];
      for (let i = 0; i < numCols; i++) {
        newRow.push('');
      }
      // numCols.forEach((key: string) => {
      //   newRow[key] = '';
      // });
      // Now Get the overvall data for the document
      const allContent = factSheetsData;
      // Indicate that some data has been changed
      setHaveChangesBeenMade(true);
      const dataJsonCopy = dataJson;
      // const dataValue = JSON.parse(JSON.stringify(dataJsonCopy.value));
      // dataValue.push(newRow);
      // Add the new row to the array
      tag.includes('fund_details_table')
        ? dataJsonCopy.push(newRow)
        : dataJsonCopy.data.push(newRow);
      // dataJsonCopy.value = dataValue;
      setDataJson(dataJsonCopy);
      // Now update the overall data
      allContent[index].content = JSON.stringify(dataJsonCopy);

      // Update the overall data
      setFactSheetsData(allContent);
      // Rebuild the table
      // setTableData(buildTableData(dataJsonCopy.value, dataJsonCopy.keys));
      buildTableData(
        tag.includes('fund_details_table') ? dataJsonCopy : dataJsonCopy.data,
        !tag.includes('fund_details_table')
      );
    } else if (addRemove === 'remove') {
      // Remove a row from the table
      // Now Get the overvall data for the document
      const allContent = factSheetsData;
      // Indicate that some data has been changed
      setHaveChangesBeenMade(true);
      const dataJsonCopy = dataJson;
      // Remove the last element from the array
      // dataJsonCopy.value.pop();
      tag.includes('fund_details_table')
        ? dataJsonCopy.pop()
        : dataJsonCopy.data.pop();
      setDataJson(dataJsonCopy);
      // Now update the overall data
      allContent[index].content = JSON.stringify(dataJsonCopy);

      // Update the overall data
      setFactSheetsData(allContent);
      // Rebuild the table
      // setTableData(buildTableData(dataJsonCopy.value, dataJsonCopy.keys));
      buildTableData(
        tag.includes('fund_details_table') ? dataJsonCopy : dataJsonCopy.data,
        !tag.includes('fund_details_table')
      );
    }
  };

  return (
    <GridItem xs={12} card style={{ marginBottom: '1rem' }}>
      <CustomTable<TableEditorData>
        title={tag.includes('fund_details_table') ? '' : dataJson.title}
        showToolbar={true}
        id={`annex_investment_table_table_${index}`}
        data={tableData}
        options={{
          paging: false,
          search: false,
          exportButton: false,
          exportAllData: false,
          emptyRowsWhenPaging: false,
          sorting: false,
          draggable: false,
        }}
        columns={columns}
      />
      {/* <div
        style={{
          marginTop: '1rem',
          marginLeft: '1rem',
          marginBottom: '1rem',
        }}
      >
        <Icon
          sx={{
            fontSize: 25,
            color:
              (tag.includes("fund_details_table") ? dataJson.length : dataJson.data.length) === 1 ? greys.grey500 : mainColors.Fail,
            opacity: 0.6,
            '&:hover': {
              cursor: 'pointer',
              opacity: 1,
            },
          }}
          onClick={
            (tag.includes("fund_details_table") ? dataJson.length : dataJson.data.length) === 1
              ? undefined
              : () => {
                modifyTableRows('remove');
              }
          }
        >
          remove_circle
        </Icon>
        <Icon
          sx={{
            fontSize: 25,
            color:
              (tag.includes("fund_details_table") ? dataJson.length : dataJson.data.length) >= getMaxNumRows(tag)
                ? greys.grey500
                : mainColors.controlButtonBlue,
            opacity: 0.6,
            '&:hover': {
              cursor: 'pointer',
              opacity: 1,
            },
          }}
          onClick={
            (tag.includes("fund_details_table") ? dataJson.length : dataJson.data.length) >= getMaxNumRows(tag)
              ? undefined
              : () => {
                modifyTableRows('add');
              }
          }
        >
          add_circle
        </Icon>
      </div> */}
    </GridItem>
  );
};

export default TableEditorTile;
