import React, { FC, useEffect } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import useFetchData from '../../../../hooks/useFetchData';
import FirstLevelTable from './components/FirstLevelTable';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../../../../types/redux/pages/PagesStore';
import { addAdditionalTitleBarComponents, removeTitleBarComponent } from '../../../../redux/pages/actions';
import { APPEND_NEW_SELECT_TO_TITLEBAR, REMOVE_SELECT_FROM_TITLEBAR } from '../../../../types/redux/pages/pageTypes';
import { activePageSelector } from '../../../../redux/pages/selectors';

const ProspectusRestrictions: FC<FundInfoComponentProps> = ({
  fundId,
  fundName,
  positionDate,
}) => {

  const dispatch = useDispatch();
  const activePage = useSelector(activePageSelector);

  // Add the select components to the title bar
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: 'Data Display: ',
        titleBarKey: 'dataDisplay',
        values: [
          {
            label: 'Current',
            value: 'current',
          },
          {
            label: 'Historical',
            value: 'historical',
          },
        ],
        displayOnOverviewPage: false,
        aValueIsAlwaysSelected: true,
        currentValue: 'current',
      },
    ];
    
    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR
      )
    );

    return () => {
      dispatch(removeTitleBarComponent('dataDisplay', REMOVE_SELECT_FROM_TITLEBAR));
    };

  }, [activePage]);

  const prospectusRestrictionsData = useFetchData({
    url: `fund_Risk_Compliance_data/${fundId}/${positionDate}`,
    keyName: `fund_Risk_Compliance_data_${fundId}_${positionDate}`,
    makeFetch: positionDate && fundId ? true : false,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[prospectusRestrictionsData]}
      customLoadingMessages={['Loading Prospectus Restrictions Data']}
    >
      <FirstLevelTable
        fundDetails={{ id: fundId, name: fundName }}
        activeFund={fundId}
        prospectusRestrictionsData={prospectusRestrictionsData}
        activeFundName={fundName}
      />
    </GeneralComponentErrorShield>
  );
};

export default ProspectusRestrictions;
