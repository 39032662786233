import { useEffect, useState } from 'react';
import client from '../utilities/requestClient';
import { AxiosResponse } from 'axios';

import useSnackbar from './useSnackbar';
import { Note } from '../sharedInterfaces/notes';

export type NoteStore = Note[];

export interface UseFundNoteProps {
  fundId: string;
  // the subject is the page, for example "ucits" for ucits Law.
  subject: string;
  // the topic is some additional information for the note. For example, this could be
  //the rule number in ucits law
  topic: string;
  positionDate: string;
  startDate?: string;
  endDate?: string;
  // should call is reserved for when you want to call in all of the notes as soon as useFundNotes is rendered.
  // generally you'll want this to be false
  shouldCall: boolean;
  // if you use this, you'll immediately post this as a note before posting your real note
  prependWithDetail?: string;
  otherUrlProvided?: string;
}
const useFundNotes = ({
  fundId,
  subject,
  topic,
  positionDate,
  shouldCall,
  startDate,
  endDate,
  ...props
}: UseFundNoteProps) => {
  // this whole thing needs loads of refactoring

  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState<NoteStore>([]);
  const { showAsyncSnackbar } = useSnackbar();

  const requestClient = client();
  const fetchData = async () => {
    try {
      if (!positionDate) {
        throw new Error('No position date provided to fund notes!');
      }
      const notes: AxiosResponse<NoteStore> = await requestClient.get(
        `/get_fund_notes/${fundId}/${startDate || positionDate}/${
          endDate || positionDate
        }/${subject}/${topic}`
      );
      for (let index = 0; index < notes.data.length; index++) {
        // speacial case for adjusting adepa to lux time
        const offset = fundId.includes('adepa') ? 3 : 2;
        const timestamp = new Date(notes.data[index].fund_note_timestamp);
        timestamp.setHours(timestamp.getHours() + offset);
        notes.data[index].fund_note_timestamp = timestamp.toISOString();
      }
      if (notes) {
        setNotes(notes.data);
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!shouldCall) {
      setLoading(false);
    }
  }, []);
  const handlePost = async (note: any) => {
    setLoading(true);
    await requestClient.post('/v1/FundNote/', note, {
      headers: {
        'content-type': 'application/json',
      },
    });
    setLoading(false);
  };
  const postData = async (text: string) => {
    if (props.prependWithDetail) {
      const prependNoteData = {
        fund_note_fund_name: fundId,
        fund_note_subject: subject,
        fund_note_position_date: positionDate,
        fund_note_topic: topic,
        fund_note_is_active: true,
        fund_note_text: props.prependWithDetail,
      };
      await handlePost(prependNoteData);
    }
    const noteData = {
      fund_note_fund_name: fundId,
      fund_note_subject: subject,
      fund_note_position_date: positionDate,
      fund_note_topic: topic,
      fund_note_is_active: true,
      fund_note_text: text,
    };
    await showAsyncSnackbar({
      startMessage: 'Saving your note...',
      successMessage: 'Note saved!',
      failureMessage:
        'Failed to save note. Please check yor connection and try again.',
      promiseToResolve: requestClient.post('/v1/FundNote/', noteData, {
        headers: {
          'content-type': 'application/json',
        },
      }),
    });
    if (shouldCall) {
      await fetchData();
    }
  };

  useEffect(() => {
    if (shouldCall) {
      fetchData();
    }
  }, [shouldCall]);
  return { notes, fetchFundNotes: fetchData, postFundNotes: postData, loading };
};

export default useFundNotes;
