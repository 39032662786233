import { Dispatch, FC, ReactElement, useState } from 'react';
import { CSVLink } from 'react-csv';
import { RaptorTheme, greys, mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { hexToRGBA } from '../../../../utilities/colorUtilities';
import { buildCsv } from '../../../../utilities/csvBuilder';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import {
  addCommasToNumbersAndRound,
  percentageToNdecialPlaces,
  percentageToTwoDecimalPlaces,
  roundNumberToNDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import MancoExposureSecondLevelTableWrapper from './MancoExposureSecondLevelTableWrapper.component';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Typography } from '@mui/material';

interface MancoExposureTableProps {
  data: any;
  positionDate?: string;
  // Will us ethis so component cnan be used on counterparty page (only data key is slighlty different)
  isCounterpartyExposure?: boolean;
}

export interface IMancoExposureTableData {
  asset: string;
  isin: string;
  assetClass: string;
  assetType: string;
  currency: string;
  fxRate?: number;
  country?: string;
  sector?: string;
  localExposure?: number;
  baseExposure?: number;
  grossExposure: number;
  grossExposurePercentage: number;
  underlyingAssets: any;
  isAggregatedRow: boolean;
}

export const mancoExposureTableColumns: CustomColumn<IMancoExposureTableData>[] =
  [
    {
      title: 'Asset',
      field: 'asset',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      // customSort: (a: IMancoExposureTableData, b: IMancoExposureTableData) => a.isAggregatedRow ? -1 : a.asset > b.asset ? 1 : -1
    },
    {
      title: 'Isin',
      field: 'isin',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Asset Class',
      field: 'assetClass',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Asset Type',
      field: 'assetType',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Currency',
      field: 'currency',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Fx Rate',
      field: 'fxRate',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData: IMancoExposureTableData) =>
        rowData.fxRate
          ? roundNumberToNDecimalPlaces(rowData.fxRate, 4)
          : rowData.fxRate,
    },
    {
      title: 'Country',
      field: 'country',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      // render: (rowData: IMancoExposureTableData) => rowData.fxRate ? roundNumberToNDecimalPlaces(rowData.fxRate, 4) : rowData.fxRate
    },
    {
      title: 'Sector',
      field: 'sector',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      // render: (rowData: IMancoExposureTableData) => rowData.fxRate ? roundNumberToNDecimalPlaces(rowData.fxRate, 4) : rowData.fxRate
    },
    {
      title: 'Local Exposure',
      field: 'localExposure',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData: IMancoExposureTableData) =>
        rowData.localExposure
          ? addCommasToNumbersAndRound(rowData.localExposure)
          : rowData.localExposure,
    },
    {
      title: 'Base Exposure',
      field: 'baseExposure',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData: IMancoExposureTableData) =>
        rowData.baseExposure
          ? addCommasToNumbersAndRound(rowData.baseExposure)
          : rowData.baseExposure,
    },
    {
      title: 'Gross Exposure',
      field: 'grossExposure',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData: IMancoExposureTableData) =>
        rowData.grossExposure
          ? addCommasToNumbersAndRound(rowData.grossExposure)
          : rowData.grossExposure,
    },
    {
      title: 'Gross Exposure%',
      field: 'grossExposurePercentage',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData: IMancoExposureTableData) =>
        rowData.isAggregatedRow
          ? percentageToTwoDecimalPlaces(rowData.grossExposurePercentage)
          : percentageToNdecialPlaces(rowData.grossExposurePercentage, 4),
    },
  ];

interface exposureType {
  [key: string]: IMancoExposureTableData[];
}

function buildMancoExposureTableData(
  data: any,
  isCounterpartyExposure: boolean | undefined
): exposureType {
  if (!data.data.length) return {};
  const exposureData = isCounterpartyExposure
    ? data.data[0].exposure_data
    : data.data[0].manco_exposure_data;
  const nav = isCounterpartyExposure
    ? data.data[0].nav
    : data.data[0].manco_nav;
  const tableData: IMancoExposureTableData[] = [];

  // Get all of the asset classes in the data.
  const assetClasses: exposureType = {};
  for (const key of Object.keys(exposureData)) {
    if (!(exposureData[key]['asset class'] in assetClasses)) {
      // The first element in each asset class array will be the aggregation
      assetClasses[exposureData[key]['asset class']] = [
        {
          asset: `${exposureData[key]['asset class']} (Aggregation)`,
          isin: '',
          assetClass: exposureData[key]['asset class'],
          assetType: '',
          currency: '',
          fxRate: undefined,
          localExposure: undefined,
          baseExposure: undefined,
          grossExposure: 0,
          grossExposurePercentage: 0,
          underlyingAssets: [],
          isAggregatedRow: true,
        },
      ];
    }

    // Now append the asset to the corresponding list for that asset type.
    assetClasses[exposureData[key]['asset class']].push({
      asset: exposureData[key].name,
      isin: exposureData[key].isin,
      assetClass: exposureData[key]['asset class'],
      assetType: exposureData[key]['asset_type'],
      currency: exposureData[key].currency,
      fxRate: exposureData[key].fx,
      country: exposureData[key].country_code,
      sector: exposureData[key].icb_sector_name,
      localExposure: exposureData[key].lc_exposure,
      baseExposure: exposureData[key].bc_exposure,
      grossExposure: exposureData[key].gross_exposure,
      grossExposurePercentage: exposureData[key].gross_exposure / nav,
      underlyingAssets: exposureData[key].underlying_assets,
      isAggregatedRow: false,
    });

    // Finally increment values in the Aggregate row.
    assetClasses[exposureData[key]['asset class']][0].grossExposure =
      assetClasses[exposureData[key]['asset class']][0].grossExposure +
      exposureData[key].gross_exposure;
    assetClasses[exposureData[key]['asset class']][0].grossExposurePercentage =
      assetClasses[exposureData[key]['asset class']][0]
        .grossExposurePercentage +
      exposureData[key].gross_exposure / nav;
  }
  // Combine all the asset classes into one list.
  // for (const key of Object.keys(assetClasses)) {
  //     console.log("HERE IS THE EXPOSURE DATA APPENDING", assetClasses[key]);
  //     console.log("HERE IS THE EXPOSURE DATA APPENDING", assetClasses[key]);
  //     tableData.push(...assetClasses[key]);
  // }

  // for (const key of Object.keys(exposureData)) {
  //     tableData.push({
  //         asset: exposureData[key].name,
  //         isin: exposureData[key].isin,
  //         assetClass: exposureData[key]['asset class'],
  //         assetType: exposureData[key].asset_type,
  //         currency: exposureData[key].currency,
  //         fxRate: exposureData[key].fx,
  //         localExposure: exposureData[key].lc_exposure,
  //         baseExposure: exposureData[key].bc_exposure,
  //         grossExposure: exposureData[key].gross_exposure,
  //         grossExposurePercentage: exposureData[key].gross_exposure / nav,
  //         commitment: exposureData[key].commitment,
  //         commitmentPercentage: exposureData[key].commitment / nav,
  //         underlyingAssets: exposureData[key].underlying_assets,
  //         countryIndex: 'A Country',
  //         strategyIndex: 'A Strategy',
  //         sectorIndex: 'An Index',
  //         countryIndexTooltip: exposureData[key].Location_tooltip,
  //         strategyIndexTooltip: exposureData[key].Strategy_tooltip,
  //         sectorIndexTooltip: exposureData[key].Industry_tooltip

  //     })
  // }

  return assetClasses;
}

interface TableFilterProps {
  tableFilterOption: string;
  // This will be the function used to update the percentValue option depending on what is clicked.
  updateTableFilterOption: Dispatch<string>;
  tableData: exposureType;
}

interface StyleProps {
  tableFilterOption: string;
}

const useDisplayOptionsButtonsStyles = makeStyles<RaptorTheme, StyleProps>(
  (theme) => ({
    container: {
      lineHeight: 25,
    },
    contained: {
      height: '3rem',
      background: 'white',
      borderRadius: 8,
      // paddingBottom: (props) => (props.clicked ? 0 : '2rem'),
      // paddingTop: (props) => (props.clicked ? 0 : '2rem'),
    },
    rootEquity: {
      marginRight: '0.4rem',
      marginTop: '1rem',
      display: 'inline-flex',
      backgroundColor: (props) =>
        props.tableFilterOption === 'equity'
          ? mainColors.mainBlue
          : greys.grey400,
      '&:hover': {
        backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
      },
    },
    rootFixedIncome: {
      marginRight: '0.4rem',
      marginTop: '1rem',
      display: 'inline-flex',
      backgroundColor: (props) =>
        props.tableFilterOption === 'fixed income'
          ? mainColors.mainBlue
          : greys.grey400,
      '&:hover': {
        backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
      },
    },
    rootForeignExchange: {
      marginRight: '0.4rem',
      marginTop: '1rem',
      display: 'inline-flex',
      backgroundColor: (props) =>
        props.tableFilterOption === 'foreign exchange'
          ? mainColors.mainBlue
          : greys.grey400,
      '&:hover': {
        backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
      },
    },
    rootCash: {
      marginRight: '0.4rem',
      marginTop: '1rem',
      display: 'inline-flex',
      backgroundColor: (props) =>
        props.tableFilterOption === 'cash'
          ? mainColors.mainBlue
          : greys.grey400,
      '&:hover': {
        backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
      },
    },
    rootAlternative: {
      marginRight: '0.4rem',
      marginTop: '1rem',
      display: 'inline-flex',
      backgroundColor: (props) =>
        props.tableFilterOption === 'alternative'
          ? mainColors.mainBlue
          : greys.grey400,
      '&:hover': {
        backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
      },
    },
    rootExport: {
      marginRight: '0.4rem',
      marginTop: '1rem',
      display: 'inline-flex',
      backgroundColor: mainColors.pineGreen,
      '&:hover': {
        backgroundColor: hexToRGBA(mainColors.pineGreen, 0.5),
      },
    },
    label: {
      flexDirection: 'column',

      alignItems: 'start',
    },
    buttonText: {
      colorPrimary: theme.palette.common.white,
    },
  })
);

function TableFilterButtons({
  tableFilterOption,
  updateTableFilterOption,
  tableData,
}: TableFilterProps): ReactElement {
  const classes = useDisplayOptionsButtonsStyles({ tableFilterOption });
  const changeOption = (value: string) => {
    if (value === tableFilterOption) {
      updateTableFilterOption('');
    } else {
      updateTableFilterOption(value);
    }
  };
  return (
    <div style={{ display: 'flex', marginLeft: '2rem' }}>
      {'Equity' in tableData && (
        <Button
          variant="contained"
          onClick={() => changeOption('equity')}
          classes={{
            contained: classes.contained,
            root: classes.rootEquity,
            // label: classes.label,
          }}
        >
          <Typography
            variant="h3"
            color="primary"
            style={{
              color: 'white',
            }}
          >
            {'Equity'}
          </Typography>
        </Button>
      )}
      {'Fixed Income' in tableData && (
        <Button
          variant="contained"
          onClick={() => changeOption('fixed income')}
          classes={{
            contained: classes.contained,
            root: classes.rootFixedIncome,
            // label: classes.label,
          }}
        >
          <Typography
            variant="h3"
            color="primary"
            style={{
              color: 'white',
            }}
          >
            {'Fixed Income'}
          </Typography>
        </Button>
      )}
      {'Foreign Exchange' in tableData && (
        <Button
          variant="contained"
          onClick={() => changeOption('foreign exchange')}
          classes={{
            contained: classes.contained,
            root: classes.rootForeignExchange,
            // label: classes.label,
          }}
        >
          <Typography
            variant="h3"
            color="primary"
            style={{
              color: 'white',
            }}
          >
            {'Foreign Exchange'}
          </Typography>
        </Button>
      )}
      {'Cash' in tableData && (
        <Button
          variant="contained"
          onClick={() => changeOption('cash')}
          classes={{
            contained: classes.contained,
            root: classes.rootCash,
            // label: classes.label,
          }}
        >
          <Typography
            variant="h3"
            color="primary"
            style={{
              color: 'white',
            }}
          >
            {'Cash'}
          </Typography>
        </Button>
      )}
      {'Alternative' in tableData && (
        <Button
          variant="contained"
          onClick={() => changeOption('alternative')}
          classes={{
            contained: classes.contained,
            root: classes.rootAlternative,
            // label: classes.label,
          }}
        >
          <Typography
            variant="h3"
            color="primary"
            style={{
              color: 'white',
            }}
          >
            {'Alternative'}
          </Typography>
        </Button>
      )}
    </div>
  );
}

interface ExportButtonProps {
  dataToExport: any;
  positionDate?: string;
}

function ExportDataButton({
  dataToExport,
  positionDate,
}: ExportButtonProps): ReactElement {
  const classes = useDisplayOptionsButtonsStyles({ tableFilterOption: '' });
  const fields = [
    'asset',
    'isin',
    'assetClass',
    'assetType',
    'currency',
    'fxRate',
    'country',
    'sector',
    'localExposure',
    'baseExposure',
    'grossExposure',
    'grossExposurePercentage',
  ];
  let csvData: any[] = [];
  for (const [key, value] of Object.entries(dataToExport)) {
    const currentKeyData = dataToExport[key]
      ? buildCsv(fields, dataToExport[key])
      : [];
    csvData = csvData.concat(currentKeyData);
  }
  return (
    <CSVLink
      // onClick={(e: any) => e.stopPropagation()}
      // onClick={(e: any) => console.log('test')}
      data={csvData}
      target="_blank"
      filename={`manco_exposure_${
        positionDate ? positionDate : formatDateForCheckingState(new Date())
      }`}
      // style={{
      //   marginLeft: '1rem',
      // }}
    >
      <Button
        variant="contained"
        classes={{
          contained: classes.contained,
          root: classes.rootExport,
          // label: classes.label,
        }}
      >
        <Typography
          variant="h3"
          color="primary"
          style={{
            color: 'white',
          }}
        >
          {'Export to File'}
        </Typography>
      </Button>
    </CSVLink>
  );
}

function buildPageSizeOptions(numRows: number) {
  const pagesSizes = [50];
  // Paging will only be enabled if there are more than 50 rows.
  if (numRows > 100) pagesSizes.push(100);
  if (numRows > 200) pagesSizes.push(200);
  if (numRows > 500) pagesSizes.push(500);
  if (numRows > 1000) pagesSizes.push(1000);
  pagesSizes.push(numRows);
  return pagesSizes;
}

const MancoExposureTable: FC<MancoExposureTableProps> = (props) => {
  const { data, positionDate, isCounterpartyExposure } = props;
  const tableData = buildMancoExposureTableData(data, isCounterpartyExposure);
  const [tableFilterOption, updateTableFilterOption] = useState<string>('');
  return (
    <GridItem xs={12} card>
      <Typography
        variant="h2"
        style={{
          marginLeft: '2rem',
          marginTop: '1rem',
          fontSize: '3rem',
          fontWeight: 400,
        }}
      >
        Exposure Aggregated over Individual Assets
      </Typography>
      <div style={{ marginLeft: '2rem' }}>
        <ExportDataButton dataToExport={tableData} />
      </div>
      <Typography
        variant="h3"
        style={{ marginLeft: '2rem', marginTop: '1rem' }}
      >
        Asset Type Filters
      </Typography>
      <TableFilterButtons
        tableFilterOption={tableFilterOption}
        updateTableFilterOption={updateTableFilterOption}
        tableData={tableData}
      />
      {(tableFilterOption === '' || tableFilterOption === 'equity') &&
        'Equity' in tableData && (
          <CustomTable<IMancoExposureTableData>
            //   selectedPositionDate={data[0].selected_position_date}
            showToolbar={true}
            id={'manco_equity_exposure_main'}
            title={'Equity Assets'}
            //   csvFields={exposureMainTableCsvFields}
            loading={props.data.isFetching}
            data={tableData['Equity']}
            exportFileName={'manco-exposure'}
            detailPanel={MancoExposureSecondLevelTableWrapper()}
            options={{
              paging: tableData['Equity'].length > 50,
              pageSize: 50,
              pageSizeOptions: buildPageSizeOptions(tableData['Equity'].length),
              search: true,
              rowStyle: (rowData: IMancoExposureTableData) => ({
                backgroundColor: rowData.isAggregatedRow
                  ? mainColors.mainBlue_lightAlt
                  : 'white',
              }),
              // exportButton: true,
              exportAllData: true,
              // grouping: true,
              emptyRowsWhenPaging: false,
              showTitle: false,
              // maxBodyHeight: 'calc(100vh - 19.8rem)',
            }}
            columns={mancoExposureTableColumns}
            // toolbarComponents={{
            //     toolbarTitle: 'Exposure Aggregated Over Individual Assets',
            // }}
          />
        )}
      {(tableFilterOption === '' || tableFilterOption === 'fixed income') &&
        'Fixed Income' in tableData && (
          <CustomTable<IMancoExposureTableData>
            //   selectedPositionDate={data[0].selected_position_date}
            showToolbar={true}
            id={'manco_fixed_income_exposure_main'}
            title={'Fixed Income'}
            //   csvFields={exposureMainTableCsvFields}
            loading={props.data.isFetching}
            data={tableData['Fixed Income']}
            exportFileName={'manco-exposure'}
            detailPanel={MancoExposureSecondLevelTableWrapper()}
            options={{
              paging: tableData['Fixed Income'].length > 50,
              pageSize: 50,
              pageSizeOptions: buildPageSizeOptions(
                tableData['Fixed Income'].length
              ),
              search: true,
              rowStyle: (rowData: IMancoExposureTableData) => ({
                backgroundColor: rowData.isAggregatedRow
                  ? mainColors.mainBlue_lightAlt
                  : 'white',
              }),
              // exportButton: true,
              exportAllData: true,
              // grouping: true,
              emptyRowsWhenPaging: false,
              showTitle: false,
              // maxBodyHeight: 'calc(100vh - 19.8rem)',
            }}
            columns={mancoExposureTableColumns}
            // toolbarComponents={{
            //     toolbarTitle: 'Exposure Aggregated Over Individual Assets',
            // }}
          />
        )}
      {(tableFilterOption === '' || tableFilterOption === 'foreign exchange') &&
        'Foreign Exchange' in tableData && (
          <CustomTable<IMancoExposureTableData>
            //   selectedPositionDate={data[0].selected_position_date}
            showToolbar={true}
            id={'manco_foreign_exchange_exposure_main'}
            title={'Foreign Exchange Assets'}
            //   csvFields={exposureMainTableCsvFields}
            loading={props.data.isFetching}
            data={tableData['Foreign Exchange']}
            exportFileName={'manco-foreign-exchange-exposure'}
            detailPanel={MancoExposureSecondLevelTableWrapper()}
            options={{
              paging: tableData['Foreign Exchange'].length > 50,
              pageSize: 50,
              pageSizeOptions: buildPageSizeOptions(
                tableData['Foreign Exchange'].length
              ),
              search: true,
              rowStyle: (rowData: IMancoExposureTableData) => ({
                backgroundColor: rowData.isAggregatedRow
                  ? mainColors.mainBlue_lightAlt
                  : 'white',
              }),
              // exportButton: true,
              exportAllData: true,
              // grouping: true,
              emptyRowsWhenPaging: false,
              showTitle: false,
              // maxBodyHeight: 'calc(100vh - 19.8rem)',
            }}
            columns={mancoExposureTableColumns}
            // toolbarComponents={{
            //     toolbarTitle: 'Exposure Aggregated Over Individual Assets',
            // }}
          />
        )}
      {(tableFilterOption === '' || tableFilterOption === 'cash') &&
        'Cash' in tableData && (
          <CustomTable<IMancoExposureTableData>
            //   selectedPositionDate={data[0].selected_position_date}
            showToolbar={true}
            id={'manco_cash_exposure_main'}
            title={'Cash Assets'}
            //   csvFields={exposureMainTableCsvFields}
            loading={props.data.isFetching}
            data={tableData['Cash']}
            exportFileName={'manco-cash-exposure'}
            detailPanel={MancoExposureSecondLevelTableWrapper()}
            options={{
              paging: tableData['Cash'].length > 50,
              pageSize: 50,
              pageSizeOptions: buildPageSizeOptions(tableData['Cash'].length),
              search: true,
              rowStyle: (rowData: IMancoExposureTableData) => ({
                backgroundColor: rowData.isAggregatedRow
                  ? mainColors.mainBlue_lightAlt
                  : 'white',
              }),
              // exportButton: true,
              exportAllData: true,
              // grouping: true,
              emptyRowsWhenPaging: false,
              showTitle: false,
              // maxBodyHeight: 'calc(100vh - 19.8rem)',
            }}
            columns={mancoExposureTableColumns}
            // toolbarComponents={{
            //     toolbarTitle: 'Exposure Aggregated Over Individual Assets',
            // }}
          />
        )}
      {(tableFilterOption === '' || tableFilterOption === 'alternative') &&
        'Alternative' in tableData && (
          <CustomTable<IMancoExposureTableData>
            //   selectedPositionDate={data[0].selected_position_date}
            showToolbar={true}
            id={'manco_alternative_exposure_main'}
            title={'Alternative Assets'}
            //   csvFields={exposureMainTableCsvFields}
            loading={props.data.isFetching}
            data={tableData['Alternative']}
            exportFileName={'manco-alternative-exposure'}
            detailPanel={MancoExposureSecondLevelTableWrapper()}
            options={{
              paging: tableData['Alternative'].length > 50,
              pageSize: 50,
              pageSizeOptions: buildPageSizeOptions(
                tableData['Alternative'].length
              ),
              search: true,
              rowStyle: (rowData: IMancoExposureTableData) => ({
                backgroundColor: rowData.isAggregatedRow
                  ? mainColors.mainBlue_lightAlt
                  : 'white',
              }),
              // exportButton: true,
              exportAllData: true,
              // grouping: true,
              emptyRowsWhenPaging: false,
              showTitle: false,
              // maxBodyHeight: 'calc(100vh - 19.8rem)',
            }}
            columns={mancoExposureTableColumns}
            // toolbarComponents={{
            //     toolbarTitle: 'Exposure Aggregated Over Individual Assets',
            // }}
          />
        )}
    </GridItem>
  );
};

export default MancoExposureTable;
