import React from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import EsgPositionsTable from '../../sharedComponents/exposureComponents/generalTable/EsgPositionsTable';
import { buildEsgColumns } from '../../sharedComponents/exposureComponents/generalTable/utils/buildEsgTableData';
import { transformESGData } from '../../sharedComponents/exposureComponents/generalTable/data/esgPresetColumnData';

// Future zod validation
export type DataSchema = [
  {
    data: any[];
    columns: any[];
    presets: {
      Environmental: any[];
      Others: any[];
      Governance: any[];
      Social: any[];
      'Agriculture, Biotechnology, and Chemicals': any[];
      'Financial and Lending Activities': any[];
      'Fossil Fuels and Environmental Impact': any[];
      'Gambling and Adult Entertainment': any[];
      'Healthcare and Ethical Issues': any[];
      'Other Business Involvements': any[];
      'Tobacco, Alcohol, and Cannabis': any[];
      'Weapons and Military': any[];
    };
  }
];

const SustainabilityDataPage: React.FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
}) => {
  const sustainabilityData = useFetchData({
    url: `category_data/esg/${fundId}/${positionDate}`,
    keyName: `category_data_esg_${fundId}_${positionDate}`,
    makeFetch: fundId && positionDate ? true : false,
  });

  console.log(sustainabilityData?.data);
  let originalData = {} as any;
  // ZOD for typing in future
  const data = sustainabilityData?.data as unknown as DataSchema;

  if (data && data.length > 0) {
    const presets = {
      environmental: data[0].presets.Environmental,
      others: data[0].presets.Others,
      governance: data[0].presets.Governance,
      social: data[0].presets.Social,
    };

    originalData = {
      columns: buildEsgColumns(transformESGData(data[0].columns)),
      data: data[0].data,
      presets,
    };
  }

  return (
    <GeneralComponentErrorShield
      dataObjects={[sustainabilityData]}
      customLoadingMessages={[
        'Loading Sustainability Data...',
        'This may take a few seconds...',
      ]}
      customErrorMessage="No Data Available."
    >
      <EsgPositionsTable
        originalData={originalData}
        fundId={fundId}
        fundName={'Sustainability Data'}
        getRowCanExpand={() => true}
        exposureType="environmental"
        positionDate={positionDate}
      />
    </GeneralComponentErrorShield>
  );
};

export default SustainabilityDataPage;
