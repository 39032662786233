import React, { FC } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import { percentageToTwoDecimalPlacesNoHundred } from '../../../../utilities/numberFormatters';
import GeneralCard from '../../../general/GeneralCard';
import CustomTable from '../../../tables/CustomTable';

export interface PerformanceReportTablesProps {
  dataForRender: DataObject;
  lookBackPeriod: string;
}

interface PerformanceReportTableData {
  fund: string;
  month1: number;
  month3: number;
  month6: number;
  year1: number;
  year3: number | null;
}

function buildTableData(
  data: any,
  tableType: string,
  lookBackPeriod: string
): PerformanceReportTableData[] {
  if (!data) return [];
  if (!data.length) return [];
  if (!('volatility_data' in data[0])) return [];
  if (!('cumulative_data' in data[0])) return [];
  if (!('risk_free_rate' in data[0])) return [];
  const volatilityData = data[0].volatility_data;
  const cumulativeData = data[0].cumulative_data;
  const riskFreeRate = data[0].risk_free_rate;
  const sharpeRatioFactors = [3.4641, 2, 1.4142, 1, 0.574456265];
  const tableData: PerformanceReportTableData[] = [];

  for (let i = 0; i < volatilityData.length; i++) {
    if (tableType === 'performance') {
      const currentData = cumulativeData[i];
      tableData.push({
        fund: currentData[0],
        month1: currentData[1],
        month3: currentData[2],
        month6: currentData[3],
        year1: currentData[4],
        year3: lookBackPeriod === '3' ? currentData[5] : null,
      });
    } else if (tableType === 'volatility') {
      const currentData = volatilityData[i];
      tableData.push({
        fund: currentData[0],
        month1: currentData[1],
        month3: currentData[2],
        month6: currentData[3],
        year1: currentData[4],
        year3: lookBackPeriod === '3' ? currentData[5] : null,
      });
    } else if (tableType === 'sharpe') {
      const cumulative = cumulativeData[i];
      const volatility = volatilityData[i];
      tableData.push({
        fund: cumulative[0],
        month1:
          sharpeRatioFactors[0] *
          ((cumulative[1] - riskFreeRate[1]) / volatility[1]),
        month3:
          sharpeRatioFactors[1] *
          ((cumulative[2] - riskFreeRate[2]) / volatility[2]),
        month6:
          sharpeRatioFactors[2] *
          ((cumulative[3] - riskFreeRate[3]) / volatility[3]),
        year1:
          sharpeRatioFactors[3] *
          ((cumulative[4] - riskFreeRate[4]) / volatility[4]),
        year3:
          lookBackPeriod === '3'
            ? sharpeRatioFactors[4] *
              ((cumulative[5] - riskFreeRate[5]) / volatility[5])
            : null,
      });
    }
  }

  return tableData;
}

function buildTableColumns(
  lookBackPeriod: string
): CustomColumn<PerformanceReportTableData>[] {
  const columns: CustomColumn<PerformanceReportTableData>[] = [
    {
      // title: 'Ebit Growth / EBIT',
      title: 'Fund',
      field: 'fund',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      width: '33%',
    },
    {
      // title: 'Ebit Growth / EBIT',
      title: '1m',
      field: 'month1',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData: PerformanceReportTableData) =>
        percentageToTwoDecimalPlacesNoHundred(rowData.month1),
    },
    {
      // title: 'Ebit Growth / EBIT',
      title: '3m',
      field: 'month3',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData: PerformanceReportTableData) =>
        percentageToTwoDecimalPlacesNoHundred(rowData.month3),
    },
    {
      // title: 'Ebit Growth / EBIT',
      title: '6m',
      field: 'month3',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData: PerformanceReportTableData) =>
        percentageToTwoDecimalPlacesNoHundred(rowData.month6),
    },
    {
      // title: 'Ebit Growth / EBIT',
      title: '1y',
      field: 'year1',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData: PerformanceReportTableData) =>
        percentageToTwoDecimalPlacesNoHundred(rowData.year1),
    },
  ];

  if (lookBackPeriod === '3') {
    columns.push({
      title: '3y',
      field: 'year3',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData: PerformanceReportTableData) =>
        percentageToTwoDecimalPlacesNoHundred(rowData.year3),
    });
  }

  return columns;
}

const PerformanceReportTables: FC<PerformanceReportTablesProps> = (props) => {
  const { data } = props.dataForRender;
  const lookBackPeriod = props.lookBackPeriod;
  const columns = buildTableColumns(lookBackPeriod);
  // const { width, height } = useWindowSize();
  return (
    <>
      <GeneralCard
        cardStyle={{ overflow: 'auto', marginBottom: '1.6rem', flexGrow: 0.33 }}
      >
        <CustomTable<PerformanceReportTableData>
          pdfTitle="Cumulative Fund Performance"
          title="Cumulative Fund Performance"
          columns={columns}
          id={'cumulative_fund_performance'}
          showToolbar
          data={buildTableData(data, 'performance', lookBackPeriod)}
          multipleTablePdfParameters={{
            specifiedWidth: 5,
            tablesInRow: 1,
          }}
          // toolbarComponents={{
          //     toolbarTitle: formattedNames[tableType],
          // }}
          options={{
            paging: false,
            search: false,
            exportButton: true,
            rowStyle: {
              marginLeft: 0,
            },
            sorting: false,
            draggable: false,
            toolbar: true,
          }}
          pdfSpecificYPosition={25}
          pdfSpecificXPosition={160}
        />
      </GeneralCard>
      <GeneralCard
        cardStyle={{ overflow: 'auto', marginBottom: '1.6rem', flexGrow: 0.33 }}
      >
        <CustomTable<PerformanceReportTableData>
          pdfTitle="Cumulative Fund Volatility"
          title="Cumulative Fund Volatility"
          columns={columns}
          id={'cumulative_fund_volatility'}
          showToolbar
          data={buildTableData(data, 'volatility', lookBackPeriod)}
          multipleTablePdfParameters={{
            specifiedWidth: 5,
            tablesInRow: 1,
          }}
          // toolbarComponents={{
          //     toolbarTitle: formattedNames[tableType],
          // }}
          options={{
            paging: false,
            search: false,
            exportButton: true,
            rowStyle: {
              marginLeft: 0,
            },
            sorting: false,
            draggable: false,
            toolbar: true,
          }}
          pdfSpecificYPosition={65}
          pdfSpecificXPosition={160}
        />
      </GeneralCard>
      <GeneralCard cardStyle={{ flexGrow: 0.33 }}>
        <CustomTable<PerformanceReportTableData>
          pdfTitle="Cumulative Anualised Fund Sharpe Ratio"
          title="Cumulative Anualised Fund Sharpe Ratio"
          columns={columns}
          id={'cumulative_fund_sharpe_ratio'}
          showToolbar
          data={buildTableData(data, 'sharpe', lookBackPeriod)}
          multipleTablePdfParameters={{
            specifiedWidth: 5,
            tablesInRow: 1,
          }}
          // toolbarComponents={{
          //     toolbarTitle: formattedNames[tableType],
          // }}
          options={{
            paging: false,
            search: false,
            exportButton: true,
            rowStyle: {
              marginLeft: 0,
            },
            sorting: false,
            draggable: false,
            toolbar: true,
          }}
          pdfSpecificYPosition={105}
          pdfSpecificXPosition={160}
        />
      </GeneralCard>
    </>
  );
};

export default PerformanceReportTables;
