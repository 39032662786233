import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme } from '../../../../../../styling/theme';

interface IUseControlBarStyles {
  // fourShowing states if there are four currently being shown
  fourShowing: boolean;
  // external is if it's the overarching data or not
  external: boolean;
}
export const useControlBarStyles = makeStyles<
  RaptorTheme,
  IUseControlBarStyles
>((theme) => ({
  controlBarContainer: {
    borderRadius: 8,
    backgroundColor: (props) => (!props.external ? 'white' : 'transparent'),
    boxShadow: (props) =>
      !props.external ? '0px 2px 5px rgba(0,0,0,0.1)' : 'none',
    display: 'inline-flex',
    justifyContent: (props) => (props.external ? 'center' : 'space-evenly'),
    height: (props) => (props.external ? 'auto' : '100%'),
    flexWrap: (props) => (props.external ? 'wrap' : 'inherit'),
    alignItems: 'center',
    marginRight: (props) => (!props.external ? '.8rem' : '0'),
    flexDirection: (props) => (props.fourShowing ? 'column' : 'row'),
    // height: (props) => (!props.external ? "100%" : "4rem"),
    '& > div': {
      marginBottom: '1rem',
    },
  },
  viewConfigIconRoot: {
    paddingLeft: 0,
  },
  showTableToggle: {
    color: theme.palette.primary.main,
  },
}));

export default useControlBarStyles;
