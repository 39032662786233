import { FC } from 'react';
import { useAdditionalTitleBarDatePicker } from '../../../../hooks/useAdditionalTitleBarComponents';
import useFetchData from '../../../../hooks/useFetchData';
import useTitleBarDatePicker from '../../../../hooks/useTitleBarDatePicker';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import {
  getMostRecentWeekday,
  getWeekdayXMonthsAgo,
} from '../../../../utilities/dateUtilities';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import FundBreakdownContainer from './FundBreakdownContainer/FundBreakdownContainer.component';
import MancoSummaryContainer from './MancoSummaryContainer/MancoSummaryContainer.component';
import dayjs from 'dayjs';
import { Grid } from '@mui/material';

const KPIMonitoring: FC<GeneralOverviewPageRenderedComponentProps> = (
  props
) => {
  const startDateWeekday = getWeekdayXMonthsAgo(1);
  const endDateWeekday = getMostRecentWeekday();

  useAdditionalTitleBarDatePicker({
    displayOnOverviewPage: true,
    titleBarKey: 'startDate',
    titleBarTitle: 'Start Date: ',
    currentValue: dayjs(startDateWeekday).format('YYYY-MM-DD'),
  });
  useAdditionalTitleBarDatePicker({
    displayOnOverviewPage: true,
    titleBarKey: 'endDate',
    titleBarTitle: 'End Date: ',
    currentValue: dayjs(endDateWeekday).format('YYYY-MM-DD'),
  });

  const fromDate = useTitleBarDatePicker('startDate') || startDateWeekday;
  const toDate = useTitleBarDatePicker('endDate') || endDateWeekday;

  const fundStatusData = useFetchData({
    url: `kpi_data/${fromDate}/${toDate}/`,
    keyName: `kpi_data_${fromDate}_${toDate}`,
    makeFetch: fromDate !== null && toDate !== null,
  });

  return (
    <GeneralComponentErrorShield dataObjects={[fundStatusData]}>
      <Grid container spacing={2} style={{ padding: 8 }}>
        <MancoSummaryContainer
          data={fundStatusData}
          periodStart={fromDate}
          periodEnd={toDate}
        />
        <FundBreakdownContainer data={fundStatusData} />
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default KPIMonitoring;
