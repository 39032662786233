import { ReactElement } from 'react';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useOverviewDetailStyles } from './component';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { Grid, Typography, useTheme } from '@mui/material';

interface Props {
  barChartData: any;
  timeSeriesData: any;
  varFund: any;
}

function DetailBarChart(props: Props): ReactElement {
  const theme = useTheme();

  const { barChartData: info } = props;
  const classes = useOverviewDetailStyles();

  const readyData = info.map((arr: any) => {
    const obj: any = {};
    const [name] = arr;
    obj.name = name;
    obj.Exposure = Math.round(arr[1] * 10000) / 100;

    return obj;
  });

  // We have to reverse the array if it's an exposure fund
  if (!props.varFund) readyData.reverse();

  const { width } = useWindowSize();
  return (
    <Grid
      item
      xs={props.timeSeriesData.length ? 4 : 6}
      className={classes.chart}
    >
      <Typography variant="h2" className={classes.mapTitle}>
        {props.varFund ? 'Value at Risk by Issuer' : 'Gross Exposure by Issuer'}
      </Typography>
      <div className={classes.chartContainerDiv}>
        <ResponsiveContainer width="90%" height={width > 1600 ? 400 : 300}>
          <BarChart
            margin={{ top: 0, left: 40, right: 30, bottom: 50 }}
            data={readyData}
          >
            <XAxis
              dataKey="name"
              tick={false}
              label={{
                value: 'Issuer',
                style: {
                  fill: theme.palette.grey[500],
                },
              }}
            />
            {/* interval={"preserveStartEnd"} tickCount={2} */}
            <YAxis
              label={{
                angle: -90,
                position: 'left',
                value: 'Exposure %',
                style: {
                  textAnchor: 'middle',
                  fill: theme.palette.grey[500],
                },
              }}
            />
            <Tooltip />
            {/* <Legend /> */}
            <Bar
              dataKey="Exposure"
              fill={theme.palette.primary.main}
              unit="%"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Grid>
  );
}

export default DetailBarChart;
