import { FC } from 'react';
import FileExport from './FileExport.component';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import GridItem from '../../../layout/GridComponents/GridItem';

const HistoricalDataExport: FC<GeneralOverviewPageRenderedComponentProps> = (
  props
) => {
  
  const { availableDates } = props;
  const riskMetrics = useFetchData({
    url: 'get_risk_metrics',
    keyName: 'risk_metrics',
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield dataObjects={[riskMetrics]}>
      <FileExport
        availableDates={availableDates || []}
        riskMetrics={riskMetrics}
      />
    </GeneralComponentErrorShield>
  );
};

export default HistoricalDataExport;
