import { AppState } from '../../types/redux/store';
import { createSelector } from 'reselect';

const getDataState = (state: AppState) => state.data;

export const dataSelector = createSelector(
  getDataState,
  (dataState) => dataState
);

export const fetchDataFromStoreWithKey = (key: string) =>
  createSelector(getDataState, (dataState) => dataState[key]);
