import { MaterialTableProps } from 'rs-material-table';
import React, { ReactElement } from 'react';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import CustomTable from '../../../../tables/CustomTable';
import { NoteData } from '../../../ucits/overview/Notes';

interface Props {
  tableData: NoteData[];
  useDates?: {
    startDate: string;
    endDate: string;
  };
  showToolbar: boolean;
  title: string;
  loadingHeight?: string;
  options?: MaterialTableProps<NoteData>['options'];
}

const noteColumns: CustomColumn<NoteData>[] = [
  {
    title: 'Position Date',
    field: 'positionDate',
    width: '10rem',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
      verticalAlign: 'top',
    },
  },
  {
    title: 'Date Added',
    field: 'date',
    width: '12rem',
    render: (rowData) => {
      const newDate = new Date(rowData.date);
      return newDate.toLocaleString('en-GB', {
        timeZone: 'UTC',
        hour: '2-digit',
        minute: '2-digit',
        weekday: 'long',
        month: '2-digit',
        year: '2-digit',
        day: '2-digit',
      });
    },
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
      verticalAlign: 'top',
    },
  },
  {
    title: 'Note',
    field: 'noteValue',
    headerStyle: {
      textAlign: 'left',
    },
    pdfWidth: 80,
    pdfRenderType: 'Table',
    render: (noteData) => {
      if (noteData.noteType === 'table') {
        const tableDataString = noteData.noteValue.slice(
          noteData.noteValue.indexOf('{')
        );
        const tableData = JSON.parse(tableDataString);
        return (
          <CustomTable<any>
            columns={tableData.columns}
            data={tableData.data}
            showToolbar={false}
            options={{
              paging: false,
              search: false,
            }}
          />
        );
      } else {
        return noteData.noteValue;
      }
    },
  },
];
function NotesTable(props: Props): ReactElement {
  return (
    <CustomTable<NoteData>
      showToolbar={props.showToolbar}
      title={props.title}
      loadingHeight={props.loadingHeight}
      columns={noteColumns}
      options={{
        paging: false,
        exportButton: true,
        ...props.options,
      }}
      data={props.tableData}
    />
  );
}

export default React.memo(NotesTable);
