import React, { useEffect } from 'react';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { formatESGWithUnit } from '../../../../utilities/numberFormatters';
import RaptorStatusBox from '../../../feedback/RaptorStatusBox.component';
import { DataObject, Status } from '../../../../types/redux/data/dataTypes';
import {
  IndicatorComplianceTableData,
  IndicatorComplianceUnderlyingTableData,
} from './IndicatorCompliance.component';
import requestClient from '../../../../utilities/requestClient';
import RaptorLoading from '../../../feedback/RaptorLoading';
import makeStyles from '@mui/styles/makeStyles';
import EditValueModal from './EditValueModal.component';
import { ClassNameMap } from '@mui/material';

const useStyles = makeStyles(() => ({
  editableValueContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
  },
}));

interface SecondLevelTableProps {
  data: DataObject;
  rowData: IndicatorComplianceTableData;
  positionDate: string | undefined;
  fundId: string;
}

// The standard columns for the table (Full Set)
const buildStandardColumns = (
  onChange: () => void,
  manualOverwrite: boolean,
  unit: string,
  classes: ClassNameMap
): CustomColumn<IndicatorComplianceUnderlyingTableData>[] => [
  {
    title: 'Position Name',
    field: 'position_name',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    width: '35%',
  },
  {
    title: 'Indicator',
    field: 'indicator',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    width: '15%',
  },
  {
    title: 'Involvement',
    field: 'involvement',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    width: '15%',
  },
  {
    title: 'Value',
    field: 'value',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData) => {
      if (manualOverwrite === true) {
        return (
          <div className={classes.editableValueContainer}>
            <EditValueModal
              gmid={rowData.gmid}
              indicator={rowData.indicator}
              value={rowData.value}
              unit={unit}
              onChange={onChange}
              isEdited={
                rowData.is_edited !== undefined ? rowData.is_edited : false
              }
            />
          </div>
        );
      } else {
        return formatESGWithUnit(rowData.value, unit);
      }
    },
    width: '15%',
  },
  {
    title: 'Pass / Fail',
    field: 'pass_fail',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData) => (
      <RaptorStatusBox status={rowData.pass_fail as Status} />
    ),
    width: '15%',
  },
];

// Build the columns using the columns in the configs
const buildColumns = (
  data: DataObject,
  columns: CustomColumn<IndicatorComplianceUnderlyingTableData>[]
): CustomColumn<IndicatorComplianceUnderlyingTableData>[] => {
  if (!data.data[0].configs || !data.data[0].configs.position_columns) {
    return columns;
  } else {
    const returnColumns: CustomColumn<IndicatorComplianceUnderlyingTableData>[] =
      [];
    data.data[0].configs.position_columns.forEach((column: string) => {
      columns.forEach(
        (col: CustomColumn<IndicatorComplianceUnderlyingTableData>) => {
          if (col.field === column) {
            returnColumns.push(col);
          }
        }
      );
    });
    return returnColumns;
  }
};

// Build the data using the indicators in the active tab
const buildTableData = (
  indicator: string,
  data: any[]
): IndicatorComplianceUnderlyingTableData[] => {
  if (!data || !data.length) {
    return [];
  } else {
    let returnData: IndicatorComplianceUnderlyingTableData[] = [];
    data.forEach((row: any) => {
      if (row['indicator'] === indicator) {
        returnData = row['underlying'];
      }
    });
    return returnData;
  }
};

const SecondLevelTable: React.FC<SecondLevelTableProps> = ({
  data,
  rowData,
  positionDate,
  fundId,
}) => {
  const [requestStatus, setRequestStatus] = React.useState<'idle' | 'loading'>(
    'idle'
  );
  const [secondLevelTable, setSecondLevelTableData] = React.useState<
    IndicatorComplianceUnderlyingTableData[]
  >([]);

  const client = requestClient();

  const classes = useStyles();

  // this data cannot be cached because it is updated by the user
  const getSecondLevelTableData = () => {
    setRequestStatus('loading');
    client
      .get(`indicator_compliance/${fundId}/${positionDate}`)
      .then((response) => {
        setSecondLevelTableData(
          buildTableData(rowData.indicator, response.data.data)
        );
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setRequestStatus('idle');
      });
  };

  useEffect(() => {
    getSecondLevelTableData();
  }, [fundId, positionDate]);

  return (
    <GridItem xs={12} card>
      {requestStatus === 'loading' && secondLevelTable.length <= 0 ? (
        <RaptorLoading />
      ) : (
        <CustomTable<IndicatorComplianceUnderlyingTableData>
          // The columns are built using only the columns in the configs
          columns={buildColumns(
            data,
            buildStandardColumns(
              () => getSecondLevelTableData(),
              data.data[0].configs.manual_overwrite,
              rowData.unit,
              classes
            )
          )}
          showToolbar
          data={secondLevelTable}
          title={'Underlying Positions'}
          options={{
            draggable: false,
            sorting: false,
            paging: false,
            search: true,
            exportButton: true,
          }}
        />
      )}
    </GridItem>
  );
};

export default SecondLevelTable;
