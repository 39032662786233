import {
  ControlBarAction,
  TOGGLE_CONTROLBAR_SHOWING_ACTION,
  UPDATE_CONTROLBAR_COMPONENTS_ACTION,
} from './actions';

export interface ControlBarStore {
  controlBarIsShowing: boolean;
  controlBarComponents: any | null;
}

export const defaultControlBarStore: ControlBarStore = {
  controlBarIsShowing: false,
  controlBarComponents: null,
};

function controlBarReducer(
  state: ControlBarStore = defaultControlBarStore,
  action: ControlBarAction
): ControlBarStore {
  switch (action.type) {
    case UPDATE_CONTROLBAR_COMPONENTS_ACTION:
      return {
        ...state,
        controlBarComponents: action.payload,
      };
    case TOGGLE_CONTROLBAR_SHOWING_ACTION:
      return {
        ...state,
        controlBarIsShowing: action.payload,
      };
    default:
      return state;
  }
}

export default controlBarReducer;
