import React, { useState } from 'react';
import CustomTable from '../../../tables/CustomTable';
import GeneralCard from '../../../general/GeneralCard';
import makeStyles from '@mui/styles/makeStyles';
import { mainColors } from '../../../../styling/theme';
import SimpleLineChart from './AssetsCharts';

export interface MarketsAssetsProp {
  [key: string]: asset_details;
}

export interface asset_details {
  column_name: string;
  data_type: string;
  display_name: string | null;
  value: string | number | time_series_array;
}

export interface time_series_array {
  value: Array<[string, string | number]>;
}

export interface MarketsAssetsPropArray {
  market_assets_data: MarketsAssetsProp[];
  setGraph: (value: string) => void;
  setNews: (value: string) => void;
  setLineColour: (value: string) => void;
}

function getVarExceedanceColor(varExceedance: number) {
  // Greens
  if (varExceedance < -1) return '#6AE84A';
  if (varExceedance < -0.7) return '#A2F08E';
  if (varExceedance < -0.4) return '#C7F6BB';
  if (varExceedance < -0.1) return '#ECFCE8';

  // Reds
  if (varExceedance >= 1) return '#ff0000';
  if (varExceedance >= 0.7) return '#ff6767';
  if (varExceedance >= 0.4) return '#ff9a9a';
  if (varExceedance >= 0.1) return '#FFDAD5';

  return '#f9f9f9';
}

const useStyles = makeStyles({
  container: {
    padding: '0.5em',
  },
  assetTable: {
    '& tbody': {
      '&::-webkit-scrollbar': {
        width: '0.5rem',
      },
      '&::-webkit-scrollbar-track': {
        border: '0.2rem solid white',
        backgroundColor: mainColors.lightGrey,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: mainColors.mainBlue,
        borderRadius: '1rem',
      },
    },
    overflow: 'hidden',
    padding: '0.5em 1em 2em',
    '& th, td': {
      padding: '0.2em',
      textAlign: 'center',
    },
  },
});

// to format value based on data_type and field name
function formatValue(data: asset_details): string {
  if (!data || data.value === null || data.value === undefined) {
    return '';
  }
  let value = data.value;
  // Check if the value is a percentage based on data_type (e.g., "percent_3f")
  if (data.data_type.startsWith('percent_') && typeof value === 'number') {
    value = value * 100; // Convert to percentage
  }
  if (typeof value === 'number') {
    // check what number it is
    const match = data.data_type.match(/_(\d+)f/);
    const decimalPlaces = match ? parseInt(match[1], 10) : 2; // Default to 2 decimal places (???)
    let formattedValue = value.toFixed(decimalPlaces);
    // Append '%' if it's a percentage
    if (data.data_type.startsWith('percent_')) {
      formattedValue += '%';
    }
    return formattedValue;
  }
  // Return the value as a string if it's not a number
  return data.value as string;
}

const MarketAssets: React.FC<MarketsAssetsPropArray> = ({
  market_assets_data,
  setGraph,
  setNews,
  setLineColour,
}) => {
  const handleRowClick = (
    event?: React.MouseEvent<Element, MouseEvent>,
    rowData?: MarketsAssetsProp
  ) => {
    //// For later
    // setNews(tags)
    /////////////
    const returnValue = rowData?.return_1d.value;

    const return_colour =
      typeof returnValue === 'number'
        ? returnValue >= 0
          ? mainColors.Pass_darker
          : mainColors.Fail_darker
        : mainColors.Pass_darker;

    setLineColour(return_colour);
    const asset =
      rowData?.asset_name.value ?? 'MSCI World Index US CH0047784696';

    if (!event) return;
    const tableRow = (event.target as HTMLElement).closest('tr')!;
    const tableBody = (event.target as HTMLElement).closest('tbody')!;

    const indexOfClickedTableRow = tableRow.getAttribute('index');

    // Set the ref to the index of the clicked row

    // Loop through all rows in the table body and set background color
    tableBody.querySelectorAll('tr').forEach((row) => {
      const rowIndex = row.getAttribute('index');
      row.style.backgroundColor =
        rowIndex === indexOfClickedTableRow ? mainColors.lightGrey : 'inherit';
    });

    // Ensure that the asset is a string before calling setGraph
    if (typeof asset === 'string') {
      setGraph(asset);
      // Set the selected asset to highlight the row
      // setSelectedAsset(asset);
    } else {
      console.error('Expected a string for asset_name.value, but got:', asset);
    }
  };

  const classes = useStyles();
  const commonTextStyle = {};

  const columns = [
    {
      title: 'Asset',
      field: 'asset_name.value',
      cellStyle: {
        fontWeight: 'bold',
        width: '40em',
      },
    },
    {
      title: 'Price',
      field: 'current_price.value',
      render: (rowData: MarketsAssetsProp) => {
        return formatValue(rowData.current_price);
      },
      cellStyle: {},
    },
    {
      title: 'Diff.',
      field: 'difference.value',
      render: (rowData: MarketsAssetsProp) => {
        return formatValue(rowData.difference);
      },
      cellStyle: { borderRight: '1px solid gray' },
    },
    {
      title: 'Return 1D',
      field: 'return_1d.value',
      render: (rowData: MarketsAssetsProp) => {
        return formatValue(rowData.return_1d);
      },
      cellStyle: {},
    },
    {
      title: 'VaR 1D',
      field: 'var_1d.value',
      render: (rowData: MarketsAssetsProp) => {
        return formatValue(rowData.var_1d);
      },
      cellStyle: { borderRight: '1px solid gray' },
    },
    {
      title: 'Return 1W',
      field: 'return_1w.value',
      render: (rowData: MarketsAssetsProp) => {
        return formatValue(rowData.return_1w);
      },
      cellStyle: {},
    },
    {
      title: 'VaR 1W',
      field: 'var_1w.value',
      render: (rowData: MarketsAssetsProp) => {
        return formatValue(rowData.var_1w);
      },
      cellStyle: { borderRight: '1px solid gray' },
    },
    {
      title: 'Return 1M',
      field: 'return_1m.value',
      render: (rowData: MarketsAssetsProp) => {
        return formatValue(rowData.return_1m);
      },
      cellStyle: {},
    },
    {
      title: 'VaR 1M',
      field: 'var_1m.value',
      render: (rowData: MarketsAssetsProp) => {
        return formatValue(rowData.var_1m);
      },
      cellStyle: { borderRight: '1px solid gray' },
    },
    {
      title: 'VaR Ratio',
      field: 'var_ratio.value',
      render: (rowData: MarketsAssetsProp) => {
        return formatValue(rowData.var_ratio);
      },
      cellStyle: (data: MarketsAssetsProp[], rowData: any) => {
        return {
          ...commonTextStyle,
          backgroundColor: getVarExceedanceColor(rowData.var_ratio.value),
        };
      },
    },
    {
      title: '',
      render(rowData: MarketsAssetsProp) {
        // Extract timeseries data
        const timeseriesValue = rowData.timeseries?.value;
        const dataPoints = Array.isArray(timeseriesValue)
          ? (timeseriesValue as [string, number][]).map(([, value]) => value)
          : [];
        // This is for return>= 0 then line colour = green
        const return1DValue =
          typeof rowData.return_1d?.value === 'number'
            ? rowData.return_1d.value
            : 0;

        return (
          <SimpleLineChart dataPoints={dataPoints} returnDay={return1DValue} />
        );
      },
      cellStyle: {},
    },
  ];

  return (
    <GeneralCard className={classes.container}>
      <CustomTable
        showToolbar={false}
        columns={columns}
        data={market_assets_data}
        tableRootStyles={classes.assetTable}
        options={{
          maxBodyHeight: '40em',
          draggable: false,
          paging: false,
        }}
        onRowClick={handleRowClick}
      />
    </GeneralCard>
  );
};

export default React.memo(MarketAssets);
