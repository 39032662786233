import React from 'react';
import './loader.css';
import DisplayAreaCenteredWrapper from '../layout/utilities/displayAreaWrapper';
import RaptorLogoWithText from '../../images/RaptorLogoWithText.png';
import { RaptorTheme, mainColors } from '../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
  centerWrap?: boolean;
  setWidth?: number | string;
  message?: string;
  noMargin?: boolean;
  noMinHeightOnError?: boolean;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
    marginBottom: '10rem',
    backgroundColor: 'white',
    padding: '5rem 6rem 2rem 6rem',
    borderRadius: '2rem',
  },
  message: {
    fontSize: '5rem',
    fontWeight: 500,
    color: mainColors.mainBlue,
    textAlign: 'center',
  },
});

function NoDataMessage(props: Props) {
  const classes = useStyles();

  const message = (
    <div className={classes.container}>
      <div>
        <img src={RaptorLogoWithText} alt={'Raptor Logo'} />
      </div>
      <h2 className={classes.message}>
        {props.message ? props.message : 'No Data Available'}
      </h2>
    </div>
  );

  return (
    <DisplayAreaCenteredWrapper noMinHeight={props.noMinHeightOnError}>
      {message}
    </DisplayAreaCenteredWrapper>
  );
}

export default NoDataMessage;
