import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import {
  alpha,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Popover,
  Switch,
  Tooltip,
} from '@mui/material';
import { Column, Table } from '@tanstack/react-table';
import { useState } from 'react';
import { mainColors, RaptorTheme } from '../../../../../../styling/theme';
import { Position } from '../types/position.types';

const usePopoverColumnSelectorStyles = makeStyles<RaptorTheme, StyleProps>(
  (theme) => ({
    button: {
      width: (props) => (props.buttonWidth ? props.buttonWidth : '15rem'),
      transition: 'width .2s',
      borderRadius: '.8rem',
      paddingLeft: '.5rem',
      color: theme.palette.grey[800],
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
      fontSize: '1.2rem',
    },
    popoverContainer: {
      borderRadius: '.8rem',
      border: `1px solid ${theme.palette.grey[800]}`,
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[800],
      padding: '4rem 4rem',
      spacing: '1rem',
      maxWidth: '90vw',
    },
    toolbar: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: '-1rem',
      marginRight: '-0.5rem',
      gap: '2rem',
    },
    resetButton: {
      fontSize: '1.2rem',
      transition: 'width .2s',
      borderRadius: '.8rem',
      padding: '0.5 3rem',
      color: theme.palette.grey[800],
      '&:hover': {
        backgroundColor: 'white',
      },
    },
    closeButton: {
      marginLeft: '1rem',
      '& svg': {
        transform: 'scale(2.5)',
        cursor: 'pointer',
      },
    },
    groupContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
    },
    groupItems: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    switchContainer: {
      width: '18rem',
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      padding: '1rem 1rem',
      fontSize: '1.5rem',
      '& .MuiSwitch-switchBase.Mui-checked': {
        color: mainColors.mainBlue,
        '&:hover': {
          backgroundColor: alpha(
            mainColors.mainBlue_lighter,
            theme.palette.action.hoverOpacity
          ),
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: mainColors.mainBlue_lighter,
      },
    },
    tooltip: {
      backgroundColor: mainColors.mainBlue,
      fontSize: '1.5rem',
    },
  })
);

interface StyleProps {
  buttonWidth?: string;
}

interface PopoverGroupsType {
  name: string;
  columns: string[];
}

const PopoverGroups: PopoverGroupsType[] = [
  //Changing standard to meet mancopositions columns
  {
    name: 'Standard',
    columns: [
      'name',
      'isin',
      'instrument_title',
      'instrument_description_title',
      'asset_class',
      'asset_type',
      'currency',
      'fx',
      'lc_exposure',
      'lc_exposure_pct',
      'bc_exposure',
      'bc_exposure_pct',
      'gross_exposure',
      'gross_exposure_pct',
      'commitment',
      'commitment_pct',
      // 'notes'
    ],
  },
  {
    name: 'Indicative',
    columns: [
      'yield',
      'coupon',
      'duration',
      'country_code',
      'credit_rating',
      'credit_type',
      'days_to_maturity',
      'time_to_maturity',
      'maturity_date',
      'interest_rate',
      'risk_factor',
      'outstanding_securities',
      // 'interest_type',
    ],
  },
  {
    name: 'Sector Data',
    columns: [
      'icb_sector_id',
      'icb_sector_name',
      'msci_sector_code',
      'msci_sector_id',
      'msci_sector_name',
      'sector_id',
      'sector_name',
    ],
  },
  {
    name: 'Instrument',
    columns: [
      'cis',
      'is_cb',
      'is_cds',
      'is_etf',
      'is_sfi',
      'is_coco',
      'is_at1',
      'asset_form',
      'counterparty_names',
    ],
  },
  {
    name: 'Valuation',
    columns: ['client_price', 'price', 'unrealised_pl'],
  },
  {
    name: 'Leverage',
    columns: ['da_son_exposure', 'notional_amount', 'sum_of_notionals'],
  },
  {
    name: 'AML',
    columns: ['aml_exposure', 'country_code_risk_level', 'is_listed'],
  },
  {
    name: 'Sensitivity',
    columns: [
      // 'asset_cs01',
      // 'asset_dv01',
      'cs01',
      'dv01',
    ],
  },
];

interface PopoverColumnSelectorProps {
  buttonName: string;
  buttonWidth?: string;
  columns: Column<Position>[];
  table: Table<any>;
  handleResetFilters: () => void;
}

export default function PopoverColumnSelector(
  props: PopoverColumnSelectorProps
) {
  const classes = usePopoverColumnSelectorStyles({
    buttonWidth: props.buttonWidth,
  } as StyleProps);

  // const newSearchStyles = useNewSearchStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  function toggleAllVisible() {
    props.columns.forEach((column) => {
      column.toggleVisibility(true);
    });
  }

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="text"
        disableElevation
        onClick={handleClick}
        className={classes.button}
      >
        {props.buttonName}
      </Button>
      <Popover
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        role={undefined}
        onClose={handleClose}
        anchorEl={anchorEl}
        className={classes.popover}
      >
        <Grid container className={classes.popoverContainer}>
          <div className={classes.toolbar}>
            <Button
              aria-describedby={'reset'}
              variant="text"
              disableElevation
              onClick={toggleAllVisible}
              className={classes.resetButton}
            >
              Select All
            </Button>
            <Button
              aria-describedby={'reset'}
              variant="text"
              disableElevation
              onClick={props.handleResetFilters}
              className={classes.resetButton}
            >
              Reset Columns
            </Button>
            <div className={classes.closeButton}>
              <div onClick={handleClose}>
                <CloseIcon />
              </div>
            </div>
          </div>
          <FormGroup row={true}>
            {PopoverGroups.map((group) => {
              return (
                <div key={group.name} className={classes.groupContainer}>
                  <h1>{group.name}</h1>
                  <div className={classes.groupItems}>
                    {group.columns.map((column: string, index) => {
                      const res = props.columns.find(
                        (obj) => obj.id === column
                      );
                      const title =
                        res?.columnDef.meta?.short_title?.toString() ||
                        res?.columnDef.header?.toString();

                      return (
                        <Grid key={`${res?.id} / ${index}`}>
                          <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            title={res?.columnDef.header?.toString()}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  id={res?.id}
                                  checked={res?.getIsVisible()}
                                  onChange={(e) =>
                                    res?.getToggleVisibilityHandler()?.(e)
                                  }
                                />
                              }
                              label={title}
                              className={classes.switchContainer}
                            />
                          </Tooltip>
                        </Grid>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </FormGroup>
        </Grid>
      </Popover>
    </div>
  );
}
