import React from 'react';
import OverviewCard from '../../../cards/OverviewCard';
import { useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme, mainColors } from '../../../../styling/theme';
import clsx from 'clsx';
import GridItem from '../../../layout/GridComponents/GridItem';
import {
  createSectionFundsSelector,
  currentFundSearchStringSelector,
} from '../../../../redux/pages/selectors';
import { useSelector } from 'react-redux';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import { Typography } from '@mui/material';

interface SelectAFundPageProps {
  // this is the key of the status from the overall data
  fundsForUse: string;
  section: string;
}

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  lowerText: {
    marginBottom: '1rem',
  },
  allText: {
    color: 'white',
  },
  status: { fontSize: '1.8rem', marginBottom: 0 },
  alpha: {
    fontSize: '2.4rem',
    marginBottom: '1rem',
  },
  divider: {
    backgroundColor: theme.palette.grey[300],
    width: '100%',
    height: 1,
  },
}));

const SelectAFundPage: React.FC<SelectAFundPageProps> = (props) => {
  const location = useLocation();
  const clientName = useSelector(clientNameSelector) || 'mersenne';
  const addedClasses = useStyles();

  const funds = useSelector(createSectionFundsSelector(props.section || ''));

  const currentFundSearchString = useSelector(currentFundSearchStringSelector);

  const selectedSortingKey = useTitleBarSelect('statusSort');

  const Title = ({ title }: { title: string }) => (
    <Typography className={addedClasses.allText} variant="subtitle2">
      {title}
    </Typography>
  );
  const LowerText = ({ text, ...rest }: { text: string; status?: boolean }) => (
    <Typography
      className={clsx(
        addedClasses.lowerText,
        addedClasses.allText,
        rest.status && addedClasses.status
      )}
      variant="h3"
    >
      {text}
    </Typography>
  );

  return funds ? (
    <>
      {selectedSortingKey === 'name'
        ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
            .split('')
            .filter(
              (letter) =>
                !currentFundSearchString.toLowerCase() ||
                currentFundSearchString[0].toLowerCase() ===
                  letter.toLowerCase()
            )
            .map((letter) => {
              return (
                <>
                  <GridItem xs={12}>
                    <div>Select A Fund Page</div>
                    <Typography className={addedClasses.alpha} variant="h3">
                      z{letter}
                    </Typography>
                    <div className={addedClasses.divider} />
                  </GridItem>
                  {funds
                    .filter((el: any) =>
                      el.name
                        .toLowerCase()
                        .includes(currentFundSearchString.toLowerCase())
                    )
                    .filter(
                      (el: any) =>
                        el.name[0].toLowerCase() === letter.toLowerCase()
                    )
                    .map((datum: any) => {
                      return (
                        <OverviewCard
                          clickHandler={() => null}
                          toVal={location.pathname + '?fundId=' + datum[0]}
                          key={datum.id + '_overview_card_key'}
                          backgroundColor={mainColors.mainBlue}
                        >
                          <div>
                            <Title title="name" />
                            <LowerText text={datum.name} />
                          </div>
                        </OverviewCard>
                      );
                    })}
                </>
              );
            })
        : funds
            .sort((a: any, b: any) =>
              a.id.localeCompare(b.id, undefined, {
                numeric: true,
                sensitivity: 'base',
              })
            )
            .map((datum: any, index: number) => {
              return (
                <OverviewCard
                  toVal={location.pathname + '?fundId=' + datum.id}
                  clickHandler={() => null}
                  key={datum.id + '_overview_card_key'}
                  backgroundColor={mainColors.mainBlue}
                >
                  <div>
                    <Title title="ID" />
                    <LowerText
                      text={
                        clientName.includes('mersenne')
                          ? `Fund ${index + 1}`
                          : datum.id
                      }
                    />
                    <Title title="name" />
                    <LowerText text={datum.name} />
                  </div>
                </OverviewCard>
              );
            })}
    </>
  ) : (
    <div></div>
  );
};

export default SelectAFundPage;
