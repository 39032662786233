import { createSelector } from 'reselect';
import { getUiState } from '../selectors';

export const snackbarStateSelector = createSelector(
  getUiState,
  (uiState) => uiState.snackbarState
);
export const isSnackbarLoadingSelector = createSelector(
  snackbarStateSelector,
  (snackbarState) => {
    return snackbarState.snackbarIsLoading;
  }
);

export const isSnackbarShowingSelector = createSelector(
  snackbarStateSelector,
  (snackbarState) => {
    return snackbarState.snackbarDisplayMessage !== null;
  }
);

export const getSnackbarMessage = createSelector(
  snackbarStateSelector,
  (snackbarState) => {
    return snackbarState.snackbarDisplayMessage;
  }
);

/**
 *
 * Mission for David
 *
 * See if you can refactor the snackbar reducer so that
 * we merge snackbarIsShowing and snackbarDisplayMessage into one property
 * (this will be snackbarDisplayMessage)
 *
 * Steps 1: create an action creator called updateSnackbarMessage
    * This should take either a string or a null value as an input
* Step 2: Update the reducer so that it's expecting this action to be passed to it
* Step 3: Update the selector so that there are two selectors:
*   1. getSnackbarMessage selector
    2. isSnackbarShowing selector

    Both of these should rely on the same property, which is snackbarDisplayMessage property
 */
