import { ColumnData, ESGPresetTypes } from '../types/column.types';

import { ColumnDef } from '@tanstack/react-table';

// 200px estimated padding and index - This is still hardcoded at 12 column minsize which is not ideal
const SIZE = (window.innerWidth - 200) / 8;
const MIN_SIZE = 100;

// All Preset Columns and their visibility/order

// Add this as enum later
export const allESGPresetColumns: Record<ESGPresetTypes, string[]> = {
  environmental: [
    'gm_name_long',
    'isin',
    'currency',
    'mr_position',
    'gross_exposure',
    'exposure',
    'instrument_title',
    'C_EMISSIONS',
    'C_ENVIRONMENTAL_FOOTPRINT_OF_PRODUCTS',
    'C_ENVIRONMENTAL_FOOTPRINT_OF_SUPPLY_CHAIN',
    'C_ENVIRONMENTAL_GOVERNANCE_AND_PROCESSES',
    'C_RESOURCE_USE',
    'SC_AIR_EMISSIONS_AIR_POLLUTANTS',
    'SC_AIR_EMISSIONS_CO2',
    'SC_ENERGY',
    'SC_ENVIRONMENTAL_BUSINESS_STRATEGY',
    'SC_ENVIRONMENTAL_MANAGEMENT_TEAM',
    'SC_IMPACT_OF_PRODUCTS_ON_ENVIRONMENT',
    'SC_IMPACT_OF_SUPPLY_CHAIN_ON_ENVIRONMENT',
    'SC_LAND_AND_BIO',
    'SC_MANAGING_THE_ENVIRONMENTAL_PERFORMANCE_OF_SUPPLY_CHAI',
    'SC_OTHER_EMISSIONS',
    'SC_WASTE',
    'SC_WATER',
  ],
  others: [
    'gm_name_long',
    'isin',
    'currency',
    'mr_position',
    'gross_exposure',
    'exposure',
    'instrument_title',
    'E_Score',
    'S_Score',
    'ESG_Score',
    'OECD_GUIDELINES_MULTI_ENTERPRISES',
    'GLOBAL_COMPACT',
  ],
  governance: [
    'gm_name_long',
    'isin',
    'currency',
    'mr_position',
    'gross_exposure',
    'exposure',
    'instrument_title',
    'G_Score',
    'C_CORPORATE_ETHICS_AND_BEHAVIOR',
    'C_CORPORATE_GOVERNANCE',
    'C_CSR_AND_GOVERNANCE_FOR_SUSTAINABILITY',
    'SC_ACCOUNTING_AND_AUDIT',
    'SC_CSR_AND_SUSTAINABILITY_MANAGEMENT_TEAM',
    'SC_CSR_AND_SUSTAINABLE_BUSINESS_STRATEGY',
    'SC_GOV_ECONOMIC_RULES',
    'SC_GOV_FINANCIAL_RULES',
    'SC_GOV_GOVERNMENT_EFFECTIVENESS',
    'SC_GOV_ICT_ADOPTION',
    'SC_GOV_INNOVATION',
    'SC_GOV_POLITICAL_RULES',
    'SC_NOMINATION_COMMITTEE',
    'SC_RISK_MANAGEMENT',
  ],
  social: [
    'gm_name_long',
    'isin',
    'currency',
    'mr_position',
    'gross_exposure',
    'exposure',
    'instrument_title',
    'ILO_FUNDAMENTAL_HUMAN_RIGHTS',
    'POLICY_HUMAN_RIGHTS',
    'C_COMMUNITY_AND_SOCIETY',
    'C_EMPLOYEES',
    'SC_BOARD_COMPOSITION',
    'SC_BOARD_FUNCTIONING',
    'SC_BUSINESS_ETHICS_AND_CODE_OF_CONDUCT',
    'SC_DIVERSITY',
    'SC_GOV_BIODIVERSITY',
    'SC_GOV_CORRUPTION',
    'SC_GOV_DEMOGRAPHIC_TRANSITION',
    'SC_GOV_GENDER_INEQUALITY',
    'SC_GOV_HEALTH',
    'SC_GOV_NATURAL_DISASTERS',
    'SC_HUMAN_RIGHTS_AND_ETHICAL_PRINCIPLES',
    'SC_LABOR_RIGHTS',
    'SC_PRODUCT_RESPONSIBILITY',
    'SC_SOCIETY_AND_HUMAN_RIGHS',
    'SC_SUSTAINABLE_PRODUCT_DEVELOPMENT',
    'SC_WORKING_CONDITIONS_AND_EMPLOYEE_SATISFACTION',
  ],
  general: [],
  agrbiochem: [
    'gm_name_long',
    'isin',
    'currency',
    'mr_position',
    'gross_exposure',
    'exposure',
    'instrument_title',
    'REVENUE_C_EXP_AGROCHEMICAL_PRODUCTS_AND_PESTICIDES',
    'REVENUE_C_EXP_ANIMAL_WELFARE',
    'REVENUE_C_EXP_GMO_AND_GENETIC_ENGINEERING',
    'REVENUE_C_EXP_MEAT_PRODUCTS',
    'REVENUE_C_EXP_PALM_OIL',
    'REVENUE_C_EXP_PORK_PRODUCTS',
    'REVENUE_EXP_AGROCHEMICAL_PRODUCTS_PART',
    'REVENUE_EXP_AGROCHEMICAL_PRODUCTS_PROD',
    'REVENUE_EXP_ANIMAL_CRUELTY',
    'REVENUE_EXP_ANIMAL_TESTING',
    'REVENUE_EXP_ANIMAL_TESTING_COSMETICS',
    'REVENUE_EXP_GMO_PRODUCTS_PART',
    'REVENUE_EXP_GMO_RESEARCH_PART',
    'REVENUE_EXP_MEAT_PRODUCTS_PART',
    'REVENUE_EXP_MEAT_PRODUCTS_PROD',
    'REVENUE_EXP_PALM_OIL_PART',
    'REVENUE_EXP_PORK_PRODUCTS_PART',
    'REVENUE_EXP_PORK_PRODUCTS_PROD',
  ],
  finlending: [
    'gm_name_long',
    'isin',
    'currency',
    'mr_position',
    'gross_exposure',
    'exposure',
    'instrument_title',
    'REVENUE_C_EXP_PRED_LENDING',
    'REVENUE_EXP_PRED_LENDING',
  ],
  fossilfuel: [
    'gm_name_long',
    'isin',
    'currency',
    'mr_position',
    'gross_exposure',
    'exposure',
    'instrument_title',
    'REVENUE_C_EXP_OIL_GAS_OTHER_FOSSIL_FUELS',
    'REVENUE_EXP_SHALE_ENERGY_PROD',
    'REVENUE_EXP_SHALE_ENERGY_PART',
    'REVENUE_EXP_COAL_MINING_PROD',
    'REVENUE_EXP_COAL_MINING_PART',
    'REVENUE_EXP_COAL_POWER_GENERATION_PROD',
    'REVENUE_EXP_COAL_POWER_GENERATION_PART',
    'REVENUE_EXP_ARCTIC_OIL_GAS',
    'REVENUE_EXP_OIL-SANDS_PART',
    'REVENUE_EXP_OIL-SANDS_PROD',
    'REVENUE_EXP_FOSSIL_FUEL_PART',
    'REVENUE_EXP_FOSSIL_FUEL_PROD',
    'REVENUE_EXP_GAS_FUELS_PART',
    'REVENUE_EXP_GAS_FUELS_PROD',
  ],
  gambling: [
    'gm_name_long',
    'isin',
    'currency',
    'mr_position',
    'gross_exposure',
    'exposure',
    'instrument_title',
    'REVENUE_C_EXP_GAMBLING',
    'REVENUE_EXP_GAMBLING_PART',
    'REVENUE_EXP_GAMBLING_PROD',
    'BOOL_C_EXP_GAMBLING',
    'BOOL_EXP_GAMBLING_PART',
    'BOOL_EXP_GAMBLING_PROD',
    'REVENUE_C_EXP_ADULT_ENTERTAINMENT',
    'REVENUE_EXP_ADULT_ENTERTAINMENT_PART',
    'BOOL_C_EXP_ADULT_ENTERTAINMENT',
    'BOOL_EXP_ADULT_ENTERTAINMENT_PART',
  ],
  healthcare: [
    'gm_name_long',
    'isin',
    'currency',
    'mr_position',
    'gross_exposure',
    'exposure',
    'instrument_title',
    'REVENUE_C_EXP_ABORTION_ABORTIFACIENTS_CONTRACEPTIVES',
    'REVENUE_C_EXP_STEM_CELL_RESEARCH',
    'REVENUE_EXP_ABORTIFACIENTS_PROD',
    'REVENUE_EXP_CONTRACEPTIVES_PROD',
    'REVENUE_EXP_EMBRYONIC_STEM_CELL_RESEARCH_PART',
    'REVENUE_EXP_STEM_CELL_RESEARCH_PART',
  ],
  businessinvolve: [
    'gm_name_long',
    'isin',
    'currency',
    'mr_position',
    'gross_exposure',
    'exposure',
    'instrument_title',
    'REVENUE_PRODUCTS_ACTIVITIES',
  ],
  tobacco: [
    'gm_name_long',
    'isin',
    'currency',
    'mr_position',
    'gross_exposure',
    'exposure',
    'instrument_title',
    'REVENUE_C_EXP_TOBACCO',
    'REVENUE_EXP_TOBACCO_PART',
    'REVENUE_EXP_TOBACCO_PROD',
    'BOOL_C_EXP_TOBACCO',
    'BOOL_EXP_TOBACCO_PART',
    'BOOL_EXP_TOBACCO_PROD',
    'REVENUE_C_EXP_ALCOHOLIC_BEVERAGES',
    'REVENUE_EXP_ALCOHOL_PROD',
    'REVENUE_EXP_ALCOHOL_PART',
    'BOOL_C_EXP_ALCOHOLIC_BEVERAGES',
    'BOOL_EXP_ALCOHOL_PART',
    'BOOL_EXP_ALCOHOL_PROD',
    'REVENUE_C_EXP_CANNABIS',
    'REVENUE_EXP_CANNABIS_PART',
    'BOOL_C_EXP_CANNABIS',
    'BOOL_EXP_CANNABIS_PART',
  ],
  weapons: [
    'gm_name_long',
    'isin',
    'currency',
    'mr_position',
    'gross_exposure',
    'exposure',
    'instrument_title',
    'REVENUE_C_EXP_TOBACCO',
    'REVENUE_EXP_TOBACCO_PART',
    'REVENUE_EXP_TOBACCO_PROD',
    'BOOL_C_EXP_TOBACCO',
    'BOOL_EXP_TOBACCO_PART',
    'BOOL_EXP_TOBACCO_PROD',
    'REVENUE_C_EXP_ALCOHOLIC_BEVERAGES',
    'REVENUE_EXP_ALCOHOL_PROD',
    'REVENUE_EXP_ALCOHOL_PART',
    'BOOL_C_EXP_ALCOHOLIC_BEVERAGES',
    'BOOL_EXP_ALCOHOL_PART',
    'BOOL_EXP_ALCOHOL_PROD',
    'REVENUE_C_EXP_CANNABIS',
    'REVENUE_EXP_CANNABIS_PART',
    'BOOL_C_EXP_CANNABIS',
    'BOOL_EXP_CANNABIS_PART',
  ],
};

type ESGDataColumn = {
  display_title: 'Asset Name';
  description: 'Name of the asset';
  title: 'gm_name_long';
};

export function transformESGData(columns: ESGDataColumn[]): ColumnData[] {
  return columns.map((item) => ({
    field: item.title,
    title: item.title,
    show: false,
    short_title: item.display_title,
    filterVariant: 'range' as 'text' | 'range' | 'select' | undefined,
    description: item.description,
    size: SIZE,
    minSize: MIN_SIZE,
  }));
}

// export const allEsgColumns: ColumnData[] = [...transformedData];

function updateColumnVisibility(
  columns: ColumnDef<ESG_DATA>[],
  preset: string[]
) {
  return columns.map((column) => {
    if (column.id) {
      if (
        column.id === 'index' ||
        preset.length === 0 ||
        preset.includes(column.id)
      ) {
        return { ...column, meta: { ...column.meta, show: true } };
      } else {
        return { ...column, meta: { ...column.meta, show: false } };
      }
    }
    return column;
  });
}

export const createAgriPreset = (
  esgColumns: ColumnDef<ESG_DATA>[]
): ESGPresetData => {
  return {
    id: 'agrbiochem',
    name: 'Agrbiochem',
    columns: updateColumnVisibility(esgColumns, allESGPresetColumns.agrbiochem),
  };
};

export const createFinLeadingPreset = (
  esgColumns: ColumnDef<ESG_DATA>[]
): ESGPresetData => {
  return {
    id: 'finlending',
    name: 'Finlending',
    columns: updateColumnVisibility(esgColumns, allESGPresetColumns.finlending),
  };
};

export const createFossilFuelPreset = (
  esgColumns: ColumnDef<ESG_DATA>[]
): ESGPresetData => {
  return {
    id: 'fossilfuel',
    name: 'FossilFuel',
    columns: updateColumnVisibility(esgColumns, allESGPresetColumns.fossilfuel),
  };
};

export const createGamblingPreset = (
  esgColumns: ColumnDef<ESG_DATA>[]
): ESGPresetData => {
  return {
    id: 'gambling',
    name: 'Gambling',
    columns: updateColumnVisibility(esgColumns, allESGPresetColumns.gambling),
  };
};

export const createHealthCarePreset = (
  esgColumns: ColumnDef<ESG_DATA>[]
): ESGPresetData => {
  return {
    id: 'healthcare',
    name: 'HealthCare',
    columns: updateColumnVisibility(esgColumns, allESGPresetColumns.healthcare),
  };
};

export const createBusinessInvolvementPreset = (
  esgColumns: ColumnDef<ESG_DATA>[]
): ESGPresetData => {
  return {
    id: 'businessinvolve',
    name: 'businessinvolve',
    columns: updateColumnVisibility(
      esgColumns,
      allESGPresetColumns.businessinvolve
    ),
  };
};

export const createTobaccoPreset = (
  esgColumns: ColumnDef<ESG_DATA>[]
): ESGPresetData => {
  return {
    id: 'tobacco',
    name: 'Tobacco',
    columns: updateColumnVisibility(esgColumns, allESGPresetColumns.tobacco),
  };
};

export const createWeaponsPreset = (
  esgColumns: ColumnDef<ESG_DATA>[]
): ESGPresetData => {
  return {
    id: 'weapons',
    name: 'Weapons',
    columns: updateColumnVisibility(esgColumns, allESGPresetColumns.weapons),
  };
};

export const createGeneralPreset = (
  esgColumns: ColumnDef<ESG_DATA>[]
): ESGPresetData => {
  return {
    id: 'general',
    name: 'General',
    columns: updateColumnVisibility(esgColumns, allESGPresetColumns.general),
  };
};

export const createEnvironmentalPreset = (
  esgColumns: ColumnDef<ESG_DATA>[]
): ESGPresetData => {
  return {
    id: 'environmental',
    name: 'Environmental',
    columns: updateColumnVisibility(
      esgColumns,
      allESGPresetColumns.environmental
    ),
  };
};

export const createOthersPreset = (
  esgColumns: ColumnDef<ESG_DATA>[]
): ESGPresetData => {
  return {
    id: 'others',
    name: 'Others',
    columns: updateColumnVisibility(esgColumns, allESGPresetColumns.others),
  };
};

export const createGovernancePreset = (
  esgColumns: ColumnDef<ESG_DATA>[]
): ESGPresetData => {
  console.log('esgColumns', esgColumns);
  return {
    id: 'governance',
    name: 'Governance',
    columns: updateColumnVisibility(esgColumns, allESGPresetColumns.governance),
  };
};

export const createSocialPreset = (
  esgColumns: ColumnDef<ESG_DATA>[]
): ESGPresetData => {
  return {
    id: 'social',
    name: 'Social',
    columns: updateColumnVisibility(esgColumns, allESGPresetColumns.social),
  };
};

export const getStandardESGPresetWithExposureType = (
  columnData: ColumnDef<ESG_DATA>[],
  exposureType: ESGPresetTypes
) => {
  switch (exposureType) {
    case 'environmental':
      return createEnvironmentalPreset(columnData);
    case 'others':
      return createOthersPreset(columnData);
    case 'governance':
      return createGovernancePreset(columnData);
    case 'social':
      return createSocialPreset(columnData);
    case 'general':
      return createGeneralPreset(columnData);
    case 'agrbiochem':
      return createAgriPreset(columnData);
    case 'finlending':
      return createFinLeadingPreset(columnData);
    case 'fossilfuel':
      return createFossilFuelPreset(columnData);
    case 'gambling':
      return createGamblingPreset(columnData);
    case 'healthcare':
      return createHealthCarePreset(columnData);
    case 'businessinvolve':
      return createBusinessInvolvementPreset(columnData);
    case 'tobacco':
      return createTobaccoPreset(columnData);
    case 'weapons':
      return createWeaponsPreset(columnData);
    default:
      return createGeneralPreset(columnData);
  }
};

export interface ESGPresetData {
  id: string;
  name: string;
  columns: ColumnDef<ESG_DATA>[];
}

export type ESG_DATA = {
  SC_GOV_MIGRATION?: string;
  SC_AWARDS_AND_CERTIFICATIONS?: string;
  SC_HEALTH_AND_SAFETY_FOR_SUPPLIERS?: string;
  C_GOV_TECHNOLOGY?: string;
  C_GOV_ENVIRONMENTAL_EXTERNALITIES?: string;
  SC_IMPACT_OF_SUPPLY_CHAIN_ON_ENVIRONMENT?: string;
  SC_PUBLIC_AFFAIRS?: string;
  C_GOV_STABILITY?: string;
  SC_PRODUCT_RESPONSIBILITY?: string;
  gross_exposure?: number;
  SC_GOV_INFRASTRUCTURE?: string;
  SC_ACCOUNTING_AND_AUDIT?: string;
  E_Score?: number;
  C_GOV_EMISSIONS?: string;
  SC_GOV_WEALTH_INEQUALITY?: string;
  C_EMISSIONS?: string;
  SC_GOV_SAFETY_SECURITY?: string;
  SC_GOV_CORRUPTION?: string;
  SC_HEALTH_AND_SAFETY_FOR_EMPLOYEES?: string;
  SC_LAND_AND_BIO?: string;
  SC_GOV_LAND?: string;
  SC_GOV_ECONOMIC_ENVIRONMENT?: string;
  SC_GOV_BIODIVERSITY?: string;
  SC_NOMINATION_COMMITTEE?: string;
  C_SUPPLIERS?: string;
  C_CORPORATE_GOVERNANCE?: string;
  SC_GOV_ICT_ADOPTION?: string;
  SC_GOV_WATER?: string;
  SC_ENERGY?: string;
  SC_GOV_ECONOMIC_RULES?: string;
  SC_IMPACT_OF_PRODUCTS_ON_ENVIRONMENT?: string;
  instrument_title?: string;
  SC_COMMUNITY_INVOLVEMENT?: string;
  SC_BOARD_FUNCTIONING?: string;
  C_CSR_AND_GOVERNANCE_FOR_SUSTAINABILITY?: string;
  SC_GOV_NATURAL_DISASTERS?: string;
  SC_GOV_OCEANS?: string;
  C_ENVIRONMENTAL_FOOTPRINT_OF_SUPPLY_CHAIN?: string;
  SC_GOV_ENERGY_SELF_SUFFICIENCY?: string;
  SC_OTHER_EMISSIONS?: string;
  SC_GOV_GOVERNMENT_EFFECTIVENESS?: string;
  SC_COMPENSATION?: string;
  SC_GOV_HEALTH?: string;
  SC_PARTNERSHIPS_AND_MEMBERSHIPS?: string;
  isin?: string;
  C_PARTNERSHIPS_MEMBERSHIPS_AWARDS_AND_CERTIFICATIONS?: string;
  S_Score?: number;
  SC_GOV_JUDICIAL_RULES?: string;
  SC_SOCIETY_AND_HUMAN_RIGHS?: string;
  SC_RISK_MANAGEMENT?: string;
  SC_GOV_OTHER_NATURAL_RESOURCES?: string;
  SC_GOV_ENERGY_MANAGEMENT?: string;
  G_Score?: number;
  SC_GOV_EDUCATION?: string;
  SC_BUSINESS_ETHICS_AND_CODE_OF_CONDUCT?: string;
  C_ENVIRONMENTAL_FOOTPRINT_OF_PRODUCTS?: string;
  SC_CSR_AND_SUSTAINABILITY_MANAGEMENT_TEAM?: string;
  SC_ENVIRONMENTAL_BUSINESS_STRATEGY?: string;
  C_RESOURCE_USE?: string;
  SC_CSR_AND_SUSTAINABLE_BUSINESS_STRATEGY?: string;
  SC_GOV_INNOVATION?: string;
  SC_GOV_GENDER_INEQUALITY?: string;
  SC_AIR_EMISSIONS_AIR_POLLUTANTS?: string;
  SC_VOTING?: string;
  SC_GOV_AIR_POLLUTANT_EMISSIONS?: string;
  C_CORPORATE_ETHICS_AND_BEHAVIOR?: string;
  SC_BOARD_COMPOSITION?: string;
  SC_CUSTOMER_EXPERIENCE?: string;
  C_GOV_HUMAN_CAPITAL?: string;
  SC_WASTE?: string;
  C_EMPLOYEES?: string;
  C_GOV_INFORMAL_INSTITUTIONS?: string;
  SC_GOV_WORKING_CONDITIONS?: string;
  SC_GOV_ACCESS_TO_SERVICES?: string;
  SC_WORKING_CONDITIONS_AND_EMPLOYEE_SATISFACTION?: string;
  SC_GOV_FINANCIAL_RULES?: string;
  SC_CORPORATE_GOVERNANCE_MECHANISMS?: string;
  SC_GOV_GHG_EMISSIONS?: string;
  gm_name_long?: string;
  SC_SUSTAINABLE_PRODUCT_DEVELOPMENT?: string;
  currency?: string;
  ILO_FUNDAMENTAL_HUMAN_RIGHTS?: string;
  OECD_GUIDELINES_MULTI_ENTERPRISES?: string;
  SC_LABOR_RIGHTS?: string;
  C_GOV_FORMAL_INSTITUTIONS?: string;
  SC_GOV_EMPLOYMENT?: string;
  mr_position?: number;
  SC_HUMAN_RIGHTS_AND_ETHICAL_PRINCIPLES?: string;
  C_CUSTOMERS_AND_PRODUCTS?: string;
  GLOBAL_COMPACT?: string | null;
  SC_ENVIRONMENTAL_MANAGEMENT_TEAM?: string;
  C_ENVIRONMENTAL_GOVERNANCE_AND_PROCESSES?: string;
  ESG_Score?: number;
  C_COMMUNITY_AND_SOCIETY?: string;
  COVERAGE?: string;
  C_GOV_RESOURCE_USE?: string;
  exposure?: number;
  SC_AIR_EMISSIONS_CO2?: string;
  C_GOV_PHYSICAL_CAPITAL?: string;
  SC_GOV_DEMOGRAPHIC_TRANSITION?: string;
  SC_DIVERSITY?: string;
  SC_GOV_SOCIAL_CAPITAL?: string;
  POLICY_HUMAN_RIGHTS?: string | null;
  SC_GOV_POLITICAL_RULES?: string;
  SC_MANAGING_THE_ENVIRONMENTAL_PERFORMANCE_OF_SUPPLY_CHAI?: string;
};
