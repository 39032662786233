import { useEffect } from 'react';
import {
  DatePicker,
  Input,
  Select,
  TitleBarComponent,
} from '../types/redux/pages/PagesStore';
import {
  addAdditionalComponentsType,
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
  removeTitleBarComponentType,
  toggleTitleBarLoading,
} from '../redux/pages/actions';
import { useTitleBarComponent } from './useTitleBarSelect';
import { useDispatch } from 'react-redux';
import {
  APPEND_NEW_DATE_PICKER_TO_TITLEBAR,
  APPEND_NEW_INPUT_TO_TITLEBAR,
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_DATE_PICKER_FROM_TITLEBAR,
  REMOVE_INPUT_FROM_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../types/redux/pages/pageTypes';

function useAdditionalTitleBarComponent<T extends TitleBarComponent>(
  component: T,
  addType: addAdditionalComponentsType,
  removeType: removeTitleBarComponentType,
  removeOnUnMount = true,
  forceRerender = false
) {
  const dispatch = useDispatch();
  const componentValue = useTitleBarComponent(component.titleBarKey);

  useEffect(() => {
    dispatch(toggleTitleBarLoading(true));
    dispatch(addAdditionalTitleBarComponents([component], addType));
    dispatch(toggleTitleBarLoading(false));

    return () => {
      if (removeOnUnMount) {
        dispatch(removeTitleBarComponent(component.titleBarKey, removeType));
      }
    };
  }, [forceRerender]);

  return componentValue;
}

export const useAdditionalTitleBarSelect = (
  component: Select,
  removeOnUnMount = true,
  forceRerender = false
) =>
  useAdditionalTitleBarComponent(
    component,
    APPEND_NEW_SELECT_TO_TITLEBAR,
    REMOVE_SELECT_FROM_TITLEBAR,
    removeOnUnMount,
    forceRerender
  );

export const useAdditionalTitleBarInput = (
  component: Input,
  removeOnUnMount = true
) =>
  useAdditionalTitleBarComponent(
    component,
    APPEND_NEW_INPUT_TO_TITLEBAR,
    REMOVE_INPUT_FROM_TITLEBAR,
    removeOnUnMount
  );

/**
 *
 * @param component - the configuration for the date picker
 * @param removeOnUnMount  -> do you want to remove the component when you unmount
 * @returns
 */
export const useAdditionalTitleBarDatePicker = (
  component: DatePicker,
  removeOnUnMount = true
) =>
  useAdditionalTitleBarComponent(
    component,
    APPEND_NEW_DATE_PICKER_TO_TITLEBAR,
    REMOVE_DATE_PICKER_FROM_TITLEBAR,
    removeOnUnMount
  );
export default useAdditionalTitleBarComponent;
