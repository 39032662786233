import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BASE_URL } from '../../../../utilities/requestClient';
import { RaptorTheme, mainColors } from '../../../../styling/theme';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import { hexToRGBA } from '../../../../utilities/colorUtilities';
import RaptorLoading from '../../../feedback/RaptorLoading';
import { useSelector } from 'react-redux';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import MonthlyVarReport from './functionalityComponents/monthlyVarReport/MonthlyVarReport.component';
import CombinedReports from './functionalityComponents/combinedReports/CombinedReports.component';
import RiskMetricsReport from './functionalityComponents/riskMetricsReport/RiskMetricsReport.component';
import CounterpartyReport from './functionalityComponents/counterpartyReport/CounterpartyReport.component';
import {
  activeSectionSelector,
  createSectionByIdSelector,
} from '../../../../redux/pages/selectors';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import { Button, Grid, GridSize, Typography, useTheme } from '@mui/material';

// DAVID USE THIS FUNCTION TO OPEN THE PAGE
function openInNewTab(url: string) {
  return async () => {
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
      return true;
    } else {
      return false;
    }
  };
}
// Lookup for coutts monthly report based on uk or ie
function getCouttsURL(key: string | null) {
  switch (key) {
    case 'coutts_ie_funds':
      return `${BASE_URL}generate_multipage_report2/aggregate_ucits_monthly_review1.html,aggregate_risk_compliance_monthly_review1.html,hd201.html,hd202.html,hd203.html,hd204.html,hd205.html,hd301.html,hd302.html,hd303.html,hd304.html,hd305.html,hd306.html,hd307.html,hd401.html,hd402.html,hd403.html,srri201.html,srri202.html,srri203.html,srri204.html,srri205.html,srri301.html,srri302.html,srri303.html,srri304.html,srri305.html,srri306.html,srri307.html,srri401.html,srri402.html,srri403.html,FLR_agg4_PDF.html,FLR_agg4_PDF_s.html,FLR_agg4_PDF_ss.html,agg_liquidation_time_ind.html/250/0.7/1`;
    case 'coutts_uk_funds':
      return `${BASE_URL}generate_multipage_report2/aggregate_ucits_monthly_review1.html,aggregate_risk_compliance_monthly_review1.html,hd101.html,hd102.html,hd103.html,hd104.html,hd105.html,hd106.html,hd107.html,srri101.html,srri102.html,srri103.html,srri104.html,srri105.html,srri106.html,srri107.html,FLR_agg4_PDF.html,FLR_agg4_PDF_s.html,FLR_agg4_PDF_ss.html,agg_liquidation_time_ind.html,var_ov2.html/130/0.7/1`;
    default:
      return '';
  }
}

export interface FunctionalityHandlerProps {
  size: GridSize;
  handler: (val?: any) => void;
  cardTitle: string;
  positionDate?: string;
  Component: React.FC<any>;
  value?: Date;
  buttonTitle?: string;
  availableDates?: DataObject;
}

export interface ButtonCardProps extends FunctionalityHandlerProps {
  contained?: any;
  root?: any;
  label?: any;
}

interface StyleProps {
  clicked: boolean;
}

const useFunctionalityStyles = makeStyles<RaptorTheme, StyleProps>(() => ({
  container: {
    lineHeight: 25,
  },
  contained: {
    height: (props) => (props.clicked ? '11rem' : '7rem'),
    background: 'white',
    '&:hover': {
      backgroundColor: hexToRGBA(mainColors.mainBlue, 0.1),
    },
    borderRadius: 8,
  },
  root: {
    marginRight: '1.6rem',
    display: 'inline-flex',
  },
  label: {
    flexDirection: 'column',

    alignItems: 'start',
  },
}));

export interface ClientFunctionalityProps {
  buttons: React.FC<FunctionalityHandlerProps>[];
}

export const ButtonCard: React.FC<ButtonCardProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const theme = useTheme();
  const buttonHandler = async () => {
    setLoading(true);
    setLoading(false);
    setSent(true);
  };

  const subtitle = `Sending report for ${
    props.positionDate || formatDateForCheckingState(new Date())
  }`;

  const classes = useFunctionalityStyles({
    clicked: loading || sent,
  });

  return (
    <Button
      variant="contained"
      classes={{
        contained: classes.contained,
        root: classes.root,
        // label: classes.label,
      }}
      onClick={buttonHandler}
    >
      <Typography variant="h3">{props.cardTitle}</Typography>
      {(loading || sent) && (
        <div
          style={{
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography
            style={{
              color: !sent ? theme.palette.grey[900] : mainColors.tertiaryGreen,
              fontStyle: 'italic',
              marginRight: '1rem',
              fontWeight: 'normal',
            }}
            variant="subtitle2"
          >
            {loading ? subtitle : sent ? 'Sent!' : ''}
          </Typography>
          {loading && <RaptorLoading noMargin setWidth="4rem" />}
        </div>
      )}
    </Button>
  );
};

const Functionality: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = () => {
  const clientName = useSelector(clientNameSelector);
  const activeSection = useSelector(activeSectionSelector);
  const sectionDetails = useSelector(
    createSectionByIdSelector(activeSection ?? '')
  );

  const opening =
    sectionDetails?.fundTypes.length || sectionDetails?.rsResources.length
      ? '?'
      : '';
  const schemas = sectionDetails?.fundTypes.length
    ? `schemas=${sectionDetails?.fundTypes}`
    : '';
  const middle =
    sectionDetails?.fundTypes.length && sectionDetails?.rsResources.length
      ? '&'
      : '';
  const resource = sectionDetails?.rsResources.length
    ? `rs_resources=${sectionDetails?.rsResources}`
    : '';
  const dates = useFetchData({
    keyName: `get_position_dates_${sectionDetails?.fundTypes}_${sectionDetails?.rsResources}}`,
    url: `/get_position_dates` + opening + schemas + middle + resource,
    makeFetch: sectionDetails ? true : false,
  });

  const renderedButtons: {
    [key: string]: FunctionalityHandlerProps[];
  } = {
    adepa: [
      {
        size: 12,
        handler: () => null,
        cardTitle: 'Generate Report',
        Component: CombinedReports,
      },
    ],
    trium: [
      {
        size: 12,
        handler: (num: any) => null,
        cardTitle: 'Monthly Var Report',
        Component: MonthlyVarReport,
      },
      {
        size: 12,
        handler: (num: any) => null,
        cardTitle: 'Risk Metrics Report',
        Component: RiskMetricsReport,
      },
      {
        size: 12,
        handler: (num: any) => null,
        cardTitle: 'Counterparty Report',
        Component: CounterpartyReport,
      },
    ],
  };

  const thereIsStuffToRender = clientName && clientName in renderedButtons;

  return (
    <GeneralComponentErrorShield
      dataObjects={[dates]}
      customLoadingMessages={['Collecting necessary data...']}
      customErrorMessage="Error collecting data."
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        {thereIsStuffToRender ? (
          renderedButtons[clientName!].map(
            (functionalityElement: FunctionalityHandlerProps) => (
              <functionalityElement.Component
                key={functionalityElement.cardTitle}
                availableDates={dates}
                {...functionalityElement}
              />
            )
          )
        ) : (
          <Typography variant="h1">No functionality specified</Typography>
        )}
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default Functionality;
