import { AppState } from '../../types/redux/store';
import { createSelector } from 'reselect';

const getAuth = (state: AppState) => state.auth;

export const authSelector = createSelector(getAuth, (auth) => auth);

export const isLoggedInSelector = createSelector(
  authSelector,
  (auth) => auth.isAuthenticated
);

export const authIsLoadingSelector = createSelector(
  authSelector,
  (auth) => auth.authenticationInProgress
);

export const accessTokenSelector = createSelector(
  authSelector,
  (auth) => auth.accessToken
);

export const authKeySelector = createSelector(
  authSelector,
  (auth) => auth.authKey
);

export const authIsEmailTokenRequiredSelector = createSelector(
  authSelector,
  (auth) => auth.isEmailTokenRequired
);

export const authIsQrCodeRequiredSelector = createSelector(
  authSelector,
  (auth) => auth.isQRCodeRequired
);

export const authErrorSelector = createSelector(
  authSelector,
  (auth) => auth.authenticationError
);

// [2023-10-18, Tom Walsh] - this is a halfway step to setting up sub accounts
// eventually there will be user specific configs? or else a set number of configs per organisation, and each user will be assigned to one of those configs
export const configNameSelector = createSelector(
  authSelector,
  (auth) => auth.config_name
);

// I plan on removing all uses of "if userId == x_funds", but that will require a complete redesign of how bespoke edits are handled
// for the meantime, I will start using "if clientName == x" instead, as this will keep the legacy organisation level bespoke changes working
export const clientNameSelector = createSelector(
  authSelector,
  (auth) => auth.client_name
);

export const userIdSelector = createSelector(
  authSelector,
  (auth) => auth.user_id
);

export const qrCodeSentSelector = createSelector(
  authSelector,
  (auth) => auth.qrCodeEmailSent
);
