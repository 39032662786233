import React, { FC } from 'react';

import Mersenne from '../../../../images/prime2.png';
import AdepaLogo from '../../../../images/adepa-logo.png';
import Coutts from '../../../../images/coutts.png';
import CorumButler from '../../../../images/corumbutler.png';
import Gemini from '../../../../images/gemini.png';
import wCapital from '../../../../images/amadeus_logo.png';
import quayside from '../../../../images/quayside.png';
import CreditSuisse from '../../../../images/cs.png';
import Trium from '../../../../images/trium.png';
import Gem from '../../../../images/gem.png';
import Nikko from '../../../../images/SMBC_nikko_logo.jpeg';
import Bflexion from '../../../../images/bflexion.png';
import VAM from '../../../../images/vam.png';
import Fundpartner from '../../../../images/fundpartner.svg';
import Pure from '../../../../images/pure-capital.png';
import GreshamHouse from '../../../../images/greshamhouse.png';
import Alt from '../../../../images/clientLogos/alt.svg';
import Affinity from '../../../../images/Afinity Logo.png';
import IFP from '../../../../images/ifp_logo.svg';
import BuyAndHold from '../../../../images/buy_and_hold.png';
import EPIC from '../../../../images/epic-logo.svg';

export interface LogoRenderProps {
  configName: string | null;
}
export function handleLogo(configName: string | null) {
  switch (configName) {
    case 'mersenne_funds':
    case 'mersenne_prime_funds':
      return Mersenne;
    case 'fundpartner_funds':
      return Fundpartner;
    case 'quayside_funds':
      return quayside;
    case 'adepa_funds':
      return AdepaLogo;
    case 'cs_funds':
      return CreditSuisse;
    case 'gemini_funds':
      return Gemini;
    case 'wcapital_funds':
      return wCapital;
    case 'coutts_funds':
    case 'coutts_product_team':
      return Coutts;
    case 'gem_funds':
      return Gem;
    case 'butler_funds':
      return CorumButler;
    case 'trium_funds':
      return Trium;
    case 'waypoint_funds':
    case 'bflexion_funds':
      return Bflexion;
    case 'snif_funds':
      return Nikko;
    case 'vam_funds':
      return VAM;
    case 'pure_funds':
      return Pure;
    case 'greshamhouse_funds':
      return GreshamHouse;
    case 'alt_funds':
      return Alt;
    case 'ifp_funds':
      return IFP;
    case 'affinity_funds':
      return Affinity;
    case 'buyandhold_funds':
      return BuyAndHold;
    case 'epic_funds':
      return EPIC;
    default:
      return Mersenne;
  }
}

const LogoRender: FC<LogoRenderProps> = ({ configName }) => {

  const Logo = handleLogo(configName);

  return (
    <img
      alt={`${configName} logo`}
      style={{
        marginRight: '1rem',
        height: ((configName) => {
          // some logos need to be different heights
          switch (configName) {
            case 'quayside_funds':
            case 'snif_funds':
            case 'cs_funds':
            case 'mersenne_funds':
              return '4rem';
            case 'gem_funds':
              return '1.8rem';
            default:
              return '2.4rem';
          }
        })(configName),
        maxWidth: '10rem',
        objectFit: 'contain',
      }}
      src={Logo}
    />
  );
};

export default LogoRender;
