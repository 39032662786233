import { FC, useEffect } from 'react';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import PositionTable from './table/table.component';
import ExposureGraphics from './exposureGraphics/exposureGraphics.component';
import CreditRatingsTable from './creditRatings/creditRatings.component';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import AggregateFixedIncomeTable from './aggTable/aggTable.component';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import useActivefund from '../../../../hooks/useActiveFund';
import { useDispatch } from 'react-redux';
import { clearPdfExports } from '../../../../redux/pdfExport/actions';
import { BubbleChart } from '@mui/icons-material';
import { Select } from '../../../../types/redux/pages/PagesStore';
import { addAdditionalTitleBarComponents, removeTitleBarComponent } from '../../../../redux/pages/actions';
import { APPEND_NEW_SELECT_TO_TITLEBAR, REMOVE_SELECT_FROM_TITLEBAR } from '../../../../types/redux/pages/pageTypes';

export interface FixedIncomeComponent {
  inputData: DataObject;
}

export const FIXED_INCOME_OPTIONS = [
  'Position Table',
  'Exposure Graphics',
  'Credit Ratings Table',
  'Bubble Chart',
];

export type FixedIncomePages =
  | 'positionTable'
  | 'exposureGraphics'
  | 'creditRatingsTable'
  | 'bubbleChart';

export enum FixedIncomeOption {
  PC_OF_NAV = 'Percentage of NAV',
  BASE_CURRENCY = 'Base Currency',
}

export const fiOptions = [
  FixedIncomeOption.PC_OF_NAV,
  FixedIncomeOption.BASE_CURRENCY,
];

const handleComponent = (selectedPage: string | null) => {
  switch (selectedPage) {
    case 'positionTable':
      return PositionTable;
    case 'exposureGraphics':
      return ExposureGraphics;
    case 'creditRatingsTable':
      return CreditRatingsTable;
    case 'bubbleChart':
      return BubbleChart;
    default:
      return PositionTable;
  }
};

const FixedIncome: FC<FundInfoComponentProps> = ({
  fundId,
  fundName,
  positionDate
}) => {

  const dispatch = useDispatch();

  // Add the select components to the title bar
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: 'Select Output: ',
        titleBarKey: 'dataOutput',
        values: [
          {
            label: 'Bonds and Derivatives',
            value: 'bondsAndDerivatives',
          },
          {
            label: 'Bonds',
            value: 'bonds',
          },
        ],
        displayOnOverviewPage: false,
        aValueIsAlwaysSelected: true,
        currentValue: 'bondsAndDerivatives',
      },
      {
        titleBarTitle: 'Visualisation: ',
        titleBarKey: 'selectVisualisation',
        values: [
          {
            label: 'Position Table',
            value: 'positionTable',
          },
          {
            label: 'Exposure Graphics',
            value: 'exposureGraphics',
          },
          {
            label: 'Credit Ratings Table',
            value: 'creditRatingsTable',
          },
          {
            label: 'Bubble Chart',
            value: 'bubbleChart',
          },
        ],
        displayOnOverviewPage: false,
        aValueIsAlwaysSelected: true,
        currentValue: 'positionTable',
      },
    ];
    
    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR
      )
    );

    return () => {
      dispatch(removeTitleBarComponent('dataOutput', REMOVE_SELECT_FROM_TITLEBAR));
      dispatch(removeTitleBarComponent('selectVisualisation', REMOVE_SELECT_FROM_TITLEBAR));
    };

  }, []);

  const selectedOutput = useTitleBarSelect('dataOutput');
  const selectedPage = useTitleBarSelect('selectVisualisation');

  const SelectedComponent = handleComponent(selectedPage);

  useEffect(() => {
    dispatch(clearPdfExports());
  }, [selectedPage, selectedOutput]);

  const suffix =
    selectedOutput === 'bonds'
      ? 'bond_only_specific_data'
      : 'bond_specific_data';
  const datePrefix = !positionDate
    ? 'most_recently_stored_report_data_agg'
    : 'stored_report_data_agg';
  const url = !positionDate
    ? `${datePrefix}/${fundId}/${suffix}`
    : `${datePrefix}/${fundId}/${suffix}/${positionDate}`;

  const fixedIncomeData = useFetchData({
    keyName: `acd_fixedIncomeData_${fundId}_${suffix}_${
      positionDate || formatDateForCheckingState(new Date())
    }`,
    url,
    makeFetch: selectedPage !== null && selectedOutput !== null,
  });
  return (
    <GeneralComponentErrorShield
      dataObjects={[fixedIncomeData]}
      customErrorMessage={'No data found for ' + fundName}
    >
      <AggregateFixedIncomeTable
        currentPage={(selectedPage as FixedIncomePages) || 'positionTable'}
        inputData={fixedIncomeData}
      />
      <SelectedComponent inputData={fixedIncomeData} />
    </GeneralComponentErrorShield>
  );
};

export default FixedIncome;
