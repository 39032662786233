import React from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import { buildEsgColumns } from '../../sharedComponents/exposureComponents/generalTable/utils/buildEsgTableData';
import { transformESGData } from '../../sharedComponents/exposureComponents/generalTable/data/esgPresetColumnData';
import EsgPositionsTable from '../../sharedComponents/exposureComponents/generalTable/EsgPositionsTable';
import { DataSchema } from '../sustainabilityData/SustainabiltyData.page';

const BusinessInvolvementPage: React.FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
}) => {
  const businessInvolvementData = useFetchData({
    url: `category_data/exposure/${fundId}/${positionDate}`,
    keyName: `category_data_exposure_${fundId}_${positionDate}`,
    makeFetch: fundId && positionDate ? true : false,
  });

  let originalData = {} as any;
  // ZOD for typing in future
  const data = businessInvolvementData?.data as unknown as DataSchema;

  if (data && data.length > 0) {
    const presets = {
      agrbiochem: data[0].presets['Agriculture, Biotechnology, and Chemicals'],
      finlending: data[0].presets['Financial and Lending Activities'],
      fossilfuel: data[0].presets['Fossil Fuels and Environmental Impact'],
      gambling: data[0].presets['Gambling and Adult Entertainment'],
      healthcare: data[0].presets['Healthcare and Ethical Issues'],
      businessinvolve: data[0].presets['Other Business Involvements'],
      tobacco: data[0].presets['Tobacco, Alcohol, and Cannabis'],
      weapons: data[0].presets['Weapons and Military'],
    };

    originalData = {
      columns: buildEsgColumns(transformESGData(data[0].columns)),
      data: data[0].data,
      presets,
    };
  }

  console.log(originalData);

  return (
    <GeneralComponentErrorShield
      dataObjects={[businessInvolvementData]}
      customLoadingMessages={[
        'Loading Business Involvement Data...',
        'This may take a few seconds...',
      ]}
      customErrorMessage="No Data Available."
    >
      <EsgPositionsTable
        originalData={originalData}
        fundId={fundId}
        fundName={'Sustainability Data'}
        getRowCanExpand={() => true}
        exposureType="general"
        positionDate={positionDate}
      />{' '}
    </GeneralComponentErrorShield>
  );
};

export default BusinessInvolvementPage;
