import React, { useState } from 'react';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import RaptorExportTiles from './RaptorExportTiles.component';
import { DatePicker } from '../../../../types/redux/pages/PagesStore';
import { useAdditionalTitleBarDatePicker } from '../../../../hooks/useAdditionalTitleBarComponents';
import dayjs from 'dayjs';
import { createSpecificTitleBarDatePickerSelector } from '../../../../redux/pages/selectors';
import { useSelector } from 'react-redux';
import { getAllRelevantWeekdays } from '../../../../utilities/dateUtilities';

const RaptorExportToday: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = () => {
  // Adepa require that the Saturday 31st of December 2022 be available in the date picker along with all week days
  // the solution is to geta  list of all weekedays and manually add 2022-12-31 to the list.
  // DC - 2023-05-04
  const [weekdays] = useState<string[]>(getAllRelevantWeekdays());
  weekdays.push('2022-12-31');
  const datePicker: DatePicker = {
    titleBarKey: 'datePicker',
    titleBarTitle: 'Position Date: ',
    currentValue: dayjs().format('YYYY-MM-DD'),
    displayOnOverviewPage: false,
    availableDates: weekdays,
  };

  useAdditionalTitleBarDatePicker(datePicker);

  const picker = useSelector(
    createSpecificTitleBarDatePickerSelector('datePicker')
  );

  return <RaptorExportTiles positionDate={picker[0]?.currentValue} />;
};

export default RaptorExportToday;
