import { BondDataServer } from '../table/table.data';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';

export interface ICreditRatingsData {
  number: number;
  name: string;
  sAndP: string;
  moodys: string;
  fitch: string;
  scope: string;
  usedRating: string;
}

function creditRatingColumnRender(
  param: keyof ICreditRatingsData,
  rowData: ICreditRatingsData
) {
  return rowData[param] || 'N/A';
}
export const generateCreditRatingsColumns =
  (): CustomColumn<ICreditRatingsData>[] => {
    return [
      {
        title: '#',
        field: 'number',
        render: (rowData) => creditRatingColumnRender('number', rowData),
        width: 1,
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        title: 'Name',
        field: 'name',
        render: (rowData) => creditRatingColumnRender('name', rowData),
      },
      {
        title: 'S&P',
        field: 'sAndP',
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: {
          textAlign: 'center',
        },
        render: (rowData) => creditRatingColumnRender('sAndP', rowData),
      },
      {
        title: 'Moodys',
        field: 'moodys',
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: {
          textAlign: 'center',
        },
        render: (rowData) => creditRatingColumnRender('moodys', rowData),
      },
      {
        title: 'Fitch',
        field: 'fitch',
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: {
          textAlign: 'center',
        },
        render: (rowData) => creditRatingColumnRender('fitch', rowData),
      },
      {
        title: 'Scope',
        field: 'scope',
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: {
          textAlign: 'center',
        },
        render: (rowData) => creditRatingColumnRender('scope', rowData),
      },
      {
        title: 'Used Rating',
        field: 'usedRating',
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: {
          textAlign: 'center',
        },
        render: (rowData) => creditRatingColumnRender('usedRating', rowData),
      },
    ];
  };

export function generateCreditRatingsData(
  inputData: BondDataServer[]
): ICreditRatingsData[] {
  if (!inputData.length) return [];
  try {
    const dataForUse = inputData[0].bond_data;

    return dataForUse.map((el, index) => {
      return {
        number: index + 1,
        name: el.gm_name || 'N/A',
        sAndP: el.rating ? el.rating['S&P'] : 'N/A',
        moodys: el.rating ? el.rating.moodys || el.rating.Moodys : 'N/A',
        fitch: el.rating ? el.rating.fitch || el.rating.Fitch : 'N/A',
        scope: el.rating ? el.rating.scope || el.rating.Scope : 'N/A',
        usedRating: el.credit_rating,
      };
    });
  } catch (err) {
    console.error(err);
    return [];
  }
}
