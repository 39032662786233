import React, { FC, useEffect, useState } from 'react';
import { useWindowSize } from '../../../../../../hooks/useWindowSize';
import ControlBar, { ValuesType } from '../controlBar/controlBar.component';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import GeneralCard from '../../../../../general/GeneralCard';
import HistoricalDataView from '../data-view/data-view.component';
import { flexify } from '../../../../../../utilities/cssMixins';
import RaptorLoading from '../../../../../feedback/RaptorLoading';
import useFetchData from '../../../../../../hooks/useFetchData';

export interface DataContainerProps {
  topLevel: ValuesType;
  fourShowing: boolean;
  itemIdentifier: number;
  activeFund: string | undefined;
  // the fixed title is for when there is no toggle
  fixedTitle?: 'Table' | 'Time Series';
  external: boolean;
  noLoader?: boolean;
}

const generateKeyString = (key1: string, key2: string) => {
  if (key1 === 'none_selected') {
    if (key2 === 'none_selected') {
      return null;
    } else {
      return key2;
    }
  } else {
    if (key2 !== 'none_selected') {
      return key1 + '/' + key2;
    } else {
      return key1;
    }
  }
};

const DataContainer: FC<DataContainerProps> = ({
  topLevel,
  fourShowing,
  itemIdentifier,
  activeFund,
  ...props
}) => {
  const [showTable, setShowTable] = useState(true);

  const [key1, setKey1] = useState<string>(topLevel.key1);
  const [key2, setKey2] = useState<string>(topLevel.key2);
  const [fromDate, setFromDate] = useState<string | null>(topLevel.fromDate);
  const [toDate, setToDate] = useState<string | null>(topLevel.toDate);

  const handleUpdateValues = (values: ValuesType) => {
    setKey1(values.key1);
    setKey2(values.key2);
    setFromDate(values.fromDate);
    setToDate(values.toDate);
  };

  const personalData = useFetchData({
    keyName: `aggregate_historical_risk_report_data_${activeFund}}_${fromDate}_${toDate}_${generateKeyString(
      key1,
      key2
    )}`,
    url: `aggregate_historical_risk_report_data/${activeFund}/${fromDate}/${toDate}/${generateKeyString(
      key1,
      key2
    )}`,
    makeFetch: fromDate !== null && toDate !== null,
  });

  useEffect(() => {
    setKey1(topLevel.key1);
    setKey2(topLevel.key2);
    setFromDate(topLevel.fromDate);
    setToDate(topLevel.toDate);
  }, [topLevel]);

  const { height, width } = useWindowSize();

  const AddControl = fourShowing ? (
    <ControlBar
      displaySelect={false}
      showFour={fourShowing}
      showTable={showTable}
      setShowTable={setShowTable}
      values={topLevel}
      handleChoice={handleUpdateValues}
    />
  ) : null;

  const adjustedHeight = fourShowing ? (height - 276) / 2 : height - 204;

  return personalData ? (
    <GridItem
      xs={fourShowing ? 6 : 12}
      lg={
        props.fixedTitle
          ? personalData.isFetching
            ? 12
            : props.fixedTitle === 'Time Series'
            ? 8
            : 4
          : 6
      }
      style={{
        display: 'flex',
        alignItems: personalData.isFetching ? 'center' : 'flex-start',
        justifyContent: personalData.isFetching ? 'flex-start' : 'center',
        height:
          personalData && personalData.isFetching ? adjustedHeight + 16 : '',
      }}
    >
      {AddControl}
      {!personalData.isFetching ? (
        <>
          <GeneralCard cardStyle={{ height: '100%' }}>
            <HistoricalDataView
              external={props.external}
              dimensions={{ height, width }}
              adjustedHeight={adjustedHeight}
              fourShowing={fourShowing}
              inputData={personalData}
              showTable={
                props.fixedTitle ? props.fixedTitle !== 'Table' : showTable
              }
              keysAndDates={topLevel}
            />
          </GeneralCard>
        </>
      ) : !props.noLoader ? (
        <div
          style={{
            height: '100%',
            width: '100%',
            ...flexify('center', 'center'),
          }}
        >
          <RaptorLoading />
        </div>
      ) : null}
    </GridItem>
  ) : null;
};

export default React.memo(DataContainer);
/*
 */
