import React, { useState, useEffect } from 'react';
import GeneralComponentErrorShield from '../../general/GeneralComponentErrorShield';
import { GeneralOverviewPageRenderedComponentProps } from '../../../types/components/componentTypes';
import YieldMain from './subComponents/yieldCurve/yieldMain';
import useFetchData from '../../../hooks/useFetchData';
import MarketsPage from './MarketsPageOverall';
import { DataObject } from '../../../types/redux/data/dataTypes';


const Markets: React.FC<GeneralOverviewPageRenderedComponentProps> = (
  props
) => {

  const responsef = useFetchData({
    url: `markets/overview/${props.positionDate}`,
    keyName: `markets/overview/${props.positionDate}`,
    makeFetch: true,
  });

  /////////////////////////////////////////
  const yieldCurve = false;

  return (
    <GeneralComponentErrorShield
      dataObjects={[responsef]}
      customLoadingMessages={['Loading Market Data...', '...']}
      customErrorMessage={'No Data Found'}
    >
      {yieldCurve ? (
        <YieldMain />
      ) : (
          <MarketsPage responsef={responsef} />
      )}
    </GeneralComponentErrorShield>
  );
};

export default Markets;
