import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
import { EditorTileProps } from './PriipsKidsEditorColumn.component';
import PriipsKidsEditorGridItem from '../../layout/PriipsKidsEditorGridItem.component';
import PriipsKidsFieldTypeSelect from '../../buttons/PriipsKidsFieldTypeSelect.component';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import { mainColors } from '../../../../../../styling/theme';
import UtilityButtons from '../../buttons/UtilityButtons.component';

const useStyles = makeStyles(() => ({
  pageBreakIconBox: {
    // border: '1px solid',
    // borderColor: mainColors.mainBlue,
    // borderColor: 'black',
    marginTop: '2rem',
    // backgroundColor: 'white',
    marginBottom: '2rem',
    marginLeft: '1rem',
    // marginRight: '1rem',
    fontSize: '1.5rem',
    boxSizing: 'border-box',
    padding: '1rem',
    borderRadius: '6px',
    width: '100%',
    minHeight: '10rem',
    display: 'flex',
    justifyContent: 'space-around',
  },
  editorContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginRight: '1rem',
  },
  formatContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingLeft: '34rem',
  },
}));

const PageBreakEditorTile: FC<EditorTileProps> = ({
  priipsKidsElement,
  setPriipsKidsContent,
  updateFieldType,
  moveField,
  deleteField,
}) => {
  // Store the index of the current element in the overall data
  const index = priipsKidsElement.kiidIndex;
  // Store the field ID of the element
  const fieldId = priipsKidsElement.fieldId;
  const classes = useStyles();

  return (
    <PriipsKidsEditorGridItem xs={12}>
      <div className={classes.formatContainer}>
        <PriipsKidsFieldTypeSelect
          initialType={priipsKidsElement.tag}
          updateFieldType={updateFieldType}
          index={priipsKidsElement.kiidIndex}
        />
      </div>
      <div className={classes.editorContainer}>
        <div className={classes.pageBreakIconBox}>
          <InsertPageBreakIcon
            sx={{
              color: mainColors.mainBlue,
              // color: 'white',
              fontSize: '10rem',
              cursor: 'pointer',
              backgroundColor: 'white',
              border: '1px solid',
              borderColor: mainColors.mainBlue,
              borderRadius: 4,
            }}
            // onMouseDown={(e) => handleTogggleClick(e, 'BOLD')}
          />
        </div>
        <UtilityButtons
          moveField={moveField}
          index={index}
          deleteField={deleteField}
          fieldId={fieldId}
        />
      </div>
    </PriipsKidsEditorGridItem>
  );
};

export default PageBreakEditorTile;
