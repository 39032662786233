import { FilterFnOption } from '@tanstack/react-table';

export interface ColumnData {
  field: string;
  title: string;
  show: boolean;
  short_title?: string;
  tooltipText?: string;
  description?: string;
  size?: number;
  minSize: number;
  filterVariant?: 'select' | 'range' | 'text';
  filterFn?: FilterFnOption<any>;
  enableColumnFilter?: boolean;
  enableResizing?: boolean;
}

export enum ColumnFields {
  INDEX = 'index',
  NAME = 'name',
  ISIN = 'isin',
  INSTRUMENT_TITLE = 'instrument_title',
  INSTRUMENT_DESCRIPTION_TITLE = 'instrument_description_title',
  ASSET_CLASS = 'asset_class',
  ASSET_TYPE = 'asset_type',
  CURRENCY = 'currency',
  FX = 'fx',
  LC_EXPOSURE = 'lc_exposure',
  LC_EXPOSURE_PCT = 'lc_exposure_pct',
  BC_EXPOSURE = 'bc_exposure',
  BC_EXPOSURE_PCT = 'bc_exposure_pct',
  GROSS_EXPOSURE = 'gross_exposure',
  GROSS_EXPOSURE_PCT = 'gross_exposure_pct',
  COMMITMENT = 'commitment',
  COMMITMENT_PCT = 'commitment_pct',
  AML_EXPOSURE = 'aml_exposure',
  COUNTRY_CODE_RISK_LEVEL = 'country_code_risk_level',
  COUNTRY_CODE = 'country_code',
  CLIENT_PRICE = 'client_price',
  CREDIT_RATING = 'credit_rating',
  CREDIT_TYPE = 'credit_type',
  DA_SON_EXPOSURE = 'da_son_exposure',
  DAYS_TO_MATURITY = 'days_to_maturity',
  ICB_SECTOR_NAME = 'icb_sector_name',
  IS_LISTED = 'is_listed',
  ASSET_FORM = 'asset_form',
  CIS = 'cis',
  ICB_SECTOR_ID = 'icb_sector_id',
  IS_CB = 'is_cb',
  IS_CDS = 'is_cds',
  IS_ETF = 'is_etf',
  IS_LOAN = 'is_loan',
  IS_SFI = 'is_sfi',
  MATURITY_DATE = 'maturity_date',
  MSCI_SECTOR_CODE = 'msci_sector_code',
  MSCI_SECTOR_NAME = 'msci_sector_name',
  MSCI_SECTOR_ID = 'msci_sector_id',
  NOTIONAL_AMOUNT = 'notional_amount',
  PORTFOLIO_ID_NAME = 'portfolio_id_name',
  PRICE = 'price',
  SUM_OF_NOTIONALS = 'sum_of_notionals',
  SECTOR_NAME = 'sector_name',
  SECTOR_ID = 'sector_id',
  TOTAL_ISSUE_SIZE = 'total_issue_size',
  UNREALISED_PL = 'unrealised_pl',
  INTEREST_RATE = 'interest_rate',
  TIME_TO_MATURITY = 'time_to_maturity',
  CS01 = 'cs01',
  DV01 = 'dv01',
  YIELD = 'yield',
  RISK_FACTOR = 'risk_factor',
  OUTSTANDING_SECURITIES = 'outstanding_securities',
  COUPON = 'coupon',
  DURATION = 'duration',
  COUNTERPARTY_NAMES = 'counterparty_names',
  IS_COCO = 'is_coco',
  IS_AT1 = 'is_at1',
}

export interface PresetData {
  id: string;
  name: string;
  columns: ColumnData[];
}

export type PresetExposureType =
  | 'amlExposure'
  | 'alternativeExposure'
  | 'cashExposure'
  | 'commodityExposure'
  | 'countryExposure'
  | 'equityExposure'
  | 'fixedIncomeExposure'
  | 'foreginExchangeExposure'
  | 'generalExposure';

export type ESGPresetTypes =
  | 'environmental'
  | 'others'
  | 'governance'
  | 'social'
  | 'general'
  | 'agrbiochem'
  | 'finlending'
  | 'fossilfuel'
  | 'gambling'
  | 'healthcare'
  | 'businessinvolve'
  | 'tobacco'
  | 'weapons';
