import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { greys, mainColors } from '../../../styling/theme';
import { hexToRGBA } from '../../../utilities/colorUtilities';
import { Section } from '../../../types/server-data/ClientConfig';
import { iconMap } from '../../../images/iconMap';
import {
  activeSectionSelector,
  allPagesSelector,
} from '../../../redux/pages/selectors';
import NavPage from './NavPage.component';
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { EnergySavingsLeaf } from '@mui/icons-material';
import ECO from '../../../images/eco.svg';

const useStyles = makeStyles({
  removePaddingFromInput: {
    padding: '0 !important',
    color: greys.grey800,
  },
  listbox: {
    color: greys.grey800,
  },
  container: {
    display: 'flex',
    height: '101%',
    width: 'fit-content',
    cursor: 'pointer',
    margin: '0 1.2rem',
    position: 'relative',
    alignItems: 'center',
    '& h4': {
      color: greys.grey400,
    },

    '&:hover': {
      '& h4': {
        color: hexToRGBA(mainColors.mainBlue, 0.8),
      },
    },
  },
  container__active: {
    '& h4': {
      color: mainColors.mainBlue,
    },
  },
  sectionTitle: {
    padding: '0.8rem 1.2rem',
    transition: 'transform .3s',
    transformOrigin: 'center',
    '&:hover': {
      transform: 'scale(1.2)',
    },
    display: 'flex',
  },
  sectionTitle__active: {
    transform: 'scale(1.2)',
  },
  navPagesContainer: {
    padding: '2.4rem 0 4.8rem',
    display: 'flex',

    flexDirection: 'column',
    backgroundColor: 'white',
  },
  navPagesTitle: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '1.6rem',
    '& h2': {
      marginRight: '2rem',
      fontSize: '3.2rem',
      lineHeight: 0.95,
      letterSpacing: 2,
      marginBottom: 0,
      color: mainColors.mainBlue,
    },
  },
  pageAreasContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '4rem',
    gridColumnGap: '4rem',
    justifyContent: 'start',
    gridTemplateRows: 'repeat(1, 1fr)',
  },
  pagesAndTitle: {
    width: '95%',
    margin: '0 auto',
  },

  pageArea: {
    '& h1': {
      marginTop: 0,
      marginBottom: '.8rem',
      fontSize: '2.4rem',
      color: '#DBA11C',
      letterSpacing: 1,
      fontWeight: 400,
      paddingBottom: '1rem',
      borderBottom: '2px solid #DBA11C',
    },
  },
  pageAreaPages: {
    display: 'grid',
    gridTemplateRows: 'repeat(5, min-content)',
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    '& a': {
      marginRight: '2rem',
      justifySelf: 'start',
    },
  },
  link: {
    fontSize: '1.8rem',
    textDecoration: 'none',
    color: 'inherit',
    '& h2': {
      textTransform: 'uppercase',
      marginRight: '1rem',
      fontSize: '2rem',
      fontWeight: 700,
    },
  },
  navContainer: {
    transition: 'opacity 0.3s',
    position: 'fixed',
    left: '0',
    minWidth: '100vw',
    zIndex: -1,
    backgroundColor: 'transparent',
    boxShadow: '0px 3px 3px rgba(0,0,0,0.03)',
    top: '4.5rem',
    boxSizing: 'border-box',
  },
});

const pageAreaMap: any = {
  Reports: 5,
  Liquidity: 3,
  ['Asset Class Data']: 2,
  Utilities: 1,
};

interface NavSectionProps {
  section: Section;
}

const NavSection: React.FC<NavSectionProps> = ({ section }) => {
  const history = useHistory();

  const activeSection = useSelector(activeSectionSelector);

  const [menuShowing, setMenuShowing] = useState<boolean>(false);
  const [isSectionActive, setIsSectionActive] = useState<boolean>(false);

  useEffect(() => {
    setIsSectionActive(activeSection === section.sectionId);
  }, [activeSection, section.sectionId]);

  const classes = useStyles();

  const allPages = useSelector(allPagesSelector);

  const pageAreas: string[] = [];
  section.pages.forEach((page: string) => {
    if (!allPages) return;
    const pageDetails = allPages[page];
    if (!pageAreas.includes(pageDetails.pageArea)) {
      pageAreas.push(pageDetails.pageArea);
    }
  });

  return (
    <div
      className={
        isSectionActive
          ? clsx(classes.container, classes.container__active)
          : classes.container
      }
      onMouseEnter={() => {
        setMenuShowing(true);
      }}
      onMouseLeave={() => setMenuShowing(false)}
    >
      <Link className={classes.link} to={'/' + section.sectionId}>
        <Typography
          variant="h4"
          className={
            isSectionActive
              ? clsx(classes.sectionTitle, classes.sectionTitle__active)
              : classes.sectionTitle
          }
        >
          {section.sectionTitle}
          {/* Add section icons here... */}
          {section.sectionTitle === 'ESG' ? (
            <EnergySavingsLeaf
              style={{ fill: mainColors.Pass, fontSize: '1.8rem' }}
            />
          ) : null}
        </Typography>
      </Link>
      {menuShowing && section.sectionId !== 'dashboard' && (
        <div className={classes.navContainer}>
          <div className={classes.navPagesContainer}>
            <div className={classes.pagesAndTitle}>
              <div className={classes.navPagesTitle}>
                <h2>{section.sectionTitle}</h2>
                {allPages && (
                  <Autocomplete
                    id="page-select-box"
                    options={section.pages.map(
                      (page: string) => allPages[page]
                    )}
                    getOptionLabel={(option) => option.pageTitle}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: classes.removePaddingFromInput,
                          },
                          ...params.InputProps,
                        }}
                        variant="standard"
                        placeholder="Select a page"
                        autoFocus={true}
                      />
                    )}
                    classes={{ listbox: classes.listbox }}
                    onChange={(event: any, value: any) => {
                      history.push(`/${section.sectionId}/${value.pageKey}`);
                      setMenuShowing(false);
                    }}
                  />
                )}
              </div>

              <div className={classes.pageAreasContainer}>
                {allPages &&
                  pageAreas
                    .sort(
                      (a: string, b: string) => pageAreaMap[b] - pageAreaMap[a]
                    )
                    .map((pageArea: string) => {
                      return (
                        <div
                          key={section.sectionId + pageArea}
                          className={classes.pageArea}
                        >
                          <h1>{pageArea}</h1>
                          <div className={classes.pageAreaPages}>
                            {(() => {
                              const pagesToRender = section.pages.map(
                                (pageId: string) => {
                                  const page = allPages[pageId];
                                  const Icon = iconMap(page.iconKey);
                                  if (page.pageArea === pageArea) {
                                    return { page, icon: Icon };
                                  } else {
                                    return null;
                                  }
                                }
                              );
                              return pagesToRender
                                .filter(
                                  (pageDetails: any) => pageDetails !== null
                                )
                                .map((pageDetails: any) => {
                                  const { page, icon } = pageDetails;
                                  return (
                                    <NavPage
                                      Icon={icon}
                                      section={section}
                                      page={page}
                                      key={page.pageKey}
                                      handleShowing={() =>
                                        setMenuShowing(false)
                                      }
                                    />
                                  );
                                });
                            })()}
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavSection;
