import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme, mainColors } from '../../../styling/theme';
import PoundIcon from '../../../images/PoundIcon';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../utilities/numberFormatters';
import { scaleLinear } from 'd3-scale';
import { hexToRGBA } from '../../../utilities/colorUtilities';
import { useSelector } from 'react-redux';
import { GeneralOverviewPageRenderedComponentProps } from '../../../types/components/componentTypes';
import { useHistory, useLocation } from 'react-router-dom';
import OverviewCard from '../../cards/OverviewCard';
import { createSectionByIdSelector } from '../../../redux/pages/selectors';
import useFetchData from '../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../general/GeneralComponentErrorShield';
import { Typography } from '@mui/material';
import { AttachMoney, ErrorOutline, EuroSymbol } from '@mui/icons-material';

interface OverviewDataCardProps {
  content: any;
  exposureColorScale: any;
  clickHandler: (fundId: string) => void;
}

interface OverviewCardStyleProps {
  currency: string;
  grossExposure: number;
  colorForStyling: string;
}

export const useOverviewCardStyles = makeStyles<RaptorTheme>((theme) => ({
  root: {
    alignSelf: 'stretch',
    cursor: 'pointer',
    transition: 'all 0.3s',
    '&:hover': {
      transform: 'scale(0.98)',
    },
  },
  firstTextSection: {
    marginTop: '.5rem',
  },
  titleSection: {
    color: 'white',
  },
  link: {
    textDecoration: 'none',
    width: 'inherit',
  },
}));
const useIconCardStyles = makeStyles<RaptorTheme, OverviewCardStyleProps>(
  (theme) => ({
    iconRoot: {
      position: 'absolute',
      fontSize: (props) => (props.currency === 'EUR' ? '7rem' : '8rem'),
      transform: (props) =>
        props.currency === 'EUR' || !props.grossExposure
          ? 'translateX(-1rem)'
          : '0',
      right: '0rem',
      bottom: '1rem',
      fill: (props) =>
        props.grossExposure ? 'white' : hexToRGBA('#ff0000', 0.3),
    },
    typ: {
      color: 'white',
    },
  })
);

function deriveSymbolFromCurrency(currency: string, grossExposure: number) {
  if (grossExposure === 0) {
    return ErrorOutline;
  }
  switch (currency) {
    case 'GBP':
      return PoundIcon;
    case 'EUR':
      return EuroSymbol;
    case 'USD':
      return AttachMoney;
    default:
      return null;
  }
}

const OverviewDataCard: React.FC<OverviewDataCardProps> = ({
  content,
  clickHandler,
  exposureColorScale,
}) => {
  const handleClick = () => {
    /* clickHandler(fundId); */
  };
  // fund info
  const {
    nav,
    base_currency: baseCurrency,
    gross_exposure_value: grossExposure,
  } = content.monitor_data;
  const colorForStyling = exposureColorScale(
    (grossExposure as number) * 100 || 0
  );

  const location = useLocation();
  const classes = useOverviewCardStyles();

  const iconClasses = useIconCardStyles({
    currency: baseCurrency as string,
    grossExposure: (grossExposure as number) || 0,
    colorForStyling,
  });

  const CurrencySymbol = deriveSymbolFromCurrency(
    baseCurrency as string,
    (grossExposure as number) || 0
  );

  return (
    <OverviewCard
      toVal={`${location.pathname}?fundId=${content.fund_name}`}
      backgroundColor={colorForStyling}
      clickHandler={handleClick}
    >
      <div className={classes.titleSection}>
        <Typography variant="subtitle2" className={iconClasses.typ}>
          Name
        </Typography>
        <Typography variant="h3" className={iconClasses.typ}>
          {content.fund_name_full}
        </Typography>
      </div>
      <div className={classes.firstTextSection}>
        <Typography variant="subtitle2" className={iconClasses.typ}>
          NAV:
        </Typography>
        <Typography variant="h3" className={iconClasses.typ}>
          {addCommasToNumbersAndRound(nav as number)}
        </Typography>
      </div>
      <div className={classes.firstTextSection}>
        <Typography variant="subtitle2" className={iconClasses.typ}>
          Gross Exposure
        </Typography>
        <Typography variant="h3" className={iconClasses.typ}>
          {grossExposure
            ? percentageToTwoDecimalPlaces(grossExposure as number)
            : 'No data available'}{' '}
        </Typography>
      </div>
      {CurrencySymbol && (
        <CurrencySymbol classes={{ root: iconClasses.iconRoot }} />
      )}
    </OverviewCard>
  );
};

function sortOverviewDatum(datum: any): void {
  const [internalFundName, fundName, fundData] = datum;
  let largestValue = -Infinity;
  let lowestValue = Infinity;
  if (
    fundData.gross_exposure_value &&
    (fundData.gross_exposure_value as number) > largestValue
  ) {
    largestValue = fundData.gross_exposure_value as number;
  }
  if (
    fundData.gross_exposure_value &&
    (fundData.gross_exposure_value as number) < lowestValue
  ) {
    lowestValue = fundData.gross_exposure_value as number;
  }
}

const ExposureOverview: React.FC<GeneralOverviewPageRenderedComponentProps> = (
  props
) => {
  const sectionDetails = useSelector(createSectionByIdSelector(props.section));
  const fundTypes = sectionDetails?.fundTypes;
  const history = useHistory();
  const location = useLocation();

  const schemas = sectionDetails?.fundTypes.length
    ? `?schemas=${sectionDetails?.fundTypes}`
    : '';
  const overviewData = useFetchData({
    url: `schema_overview_monitor/${props.positionDate}` + schemas,
    keyName: `${fundTypes}_overview_data_${props.positionDate}`,
    makeFetch: props.positionDate ? true : false,
  });

  const handleCardClick = (fundId: string) => {
    history.push(location.pathname + '?fundId=' + fundId);
  };

  const exposureScale = scaleLinear()
    .domain([100, 300])
    .range([
      mainColors.Pass as unknown as number,
      '#10caeb' as unknown as number,
    ])
    .clamp(true);

  let renderData: any = [];
  if (overviewData && overviewData.data.length) {
    renderData = overviewData.data[0];
  }

  return overviewData ? (
    <GeneralComponentErrorShield
      dataObjects={[overviewData]}
      customLoadingMessages={['Loading Fund Data...']}
      customErrorMessage="No Fund Data Available"
    >
      {renderData.map((fund: any) => {
        return (
          <OverviewDataCard
            exposureColorScale={exposureScale}
            clickHandler={handleCardClick}
            key={fund.fund_name}
            content={fund}
          />
        );
      })}
    </GeneralComponentErrorShield>
  ) : null;
};

export default ExposureOverview;
