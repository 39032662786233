import makeStyles from '@mui/styles/makeStyles';
import { ReactElement, useState } from 'react';
import { RaptorTheme, mainColors } from '../../../../../../styling/theme';
import client, { BASE_URL } from '../../../../../../utilities/requestClient';
import { FunctionalityHandlerProps } from '../../Functionality';
import FunctionalityButton from '../functionalityButton';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import {
  calculateQuarter,
  getAvailableDateXMonthsAgo,
  getMostRecentAvailableDate,
} from '../../../../../../utilities/dateUtilities';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import {
  activeSectionSelector,
  createSectionByIdSelector,
  createSectionFundsSelector,
} from '../../../../../../redux/pages/selectors';
import GeneralMultiSelect from '../../../../../selects/GeneralMultiSelect.component';
import saveAs from 'file-saver';
import { AxiosError } from 'axios';
import { CircularProgress, Typography } from '@mui/material';
import RaptorDatePicker from '../../../../../selects/RaptorDatePicker.component';
import { getAllRelevantDates } from '../../../../../../utilities/dateUtilities';

function openInNewTab(url: string) {
  return async () => {
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
      return true;
    } else {
      return false;
    }
  };
}

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  fdiContainer: {
    display: 'flex',
    position: 'relative',
    top: '1rem',
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '1.2rem',
    height: '6rem',
    width: '30rem',
  },
  header: {
    position: 'relative',
    // top: '0.5rem',
  },
  feedback: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '2rem',
    fontWeight: 500,
  },
  loadingMessage: {
    color: mainColors.mainBlue,
  },
  errorMessage: {
    color: mainColors.Fail,
  },
  successMessage: {
    color: mainColors.Pass,
  },
}));

function CounterpartyReport(props: FunctionalityHandlerProps): ReactElement {
  const classes = useStyles();

  // Get a list of the available funds
  const section = useSelector(activeSectionSelector);
  const fundDetails = useSelector(
    createSectionFundsSelector(section || 'ucits')
  );
  const fundIds = fundDetails != null ? fundDetails.map(({ id }) => id) : [];
  const fundNames =
    fundDetails != null ? fundDetails.map(({ name }) => name) : [];

  const [funds, setFunds] = useState<string[]>(fundIds);

  // get available dates (just take overview dates for now) maybe in future choose dates based on the funds selected?
  const [availableDates] = useState<string[]>(
    props.availableDates?.data[0].overview || []
  );

  // End date for running the report (default to today)
  const [startDate, setStartDate] = useState<string>(
    getAvailableDateXMonthsAgo(1, availableDates)
  );
  const [endDate, setEndDate] = useState<string>(
    getMostRecentAvailableDate(availableDates)
  );

  // Create a function for generating the url and calling the report when the button is pressed
  const { showAsyncSnackbar } = useSnackbar();
  const sectionDetails = useSelector(
    createSectionByIdSelector(section || 'ucits')
  );
  const fundTypes = sectionDetails?.fundTypes;

  const schemas = sectionDetails?.fundTypes.length
    ? `?schemas=${sectionDetails?.fundTypes}`
    : '';
  const middle =
    fundTypes?.length && funds.length ? `&` : funds.length ? '?' : '';
  const fundsForParam = funds.length ? `fund_names=${funds}` : '';

  // async function handleRequest() {

  //   const url = `${BASE_URL}bespoke_reporting/counterparty_report/${startDate}/${endDate}` + schemas + middle + `fund_ids=${funds}`;
  //   // const func = openInNewTab(url);
  //   // await showAsyncSnackbar({
  //   //   startMessage: 'Generating Risk Metrics Report',
  //   //   successMessage: 'Report Sent',
  //   //   failureMessage: 'Failed to generate report',
  //   //   promiseToResolve: func(),
  //   // });
  //   // fetch(url).then((response) => {
  //   //   response.blob().then((blob: any) => {
  //   //     const FileSaver = require('file-saver');
  //   //     FileSaver.saveAs(new Blob(blob, {type: "text/plain;charset=utf-8"}));
  //   //   });
  //   // });
  //   const FileSaver = require('file-saver');
  //   FileSaver.saveAs(url);
  // }

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [messageStatus, setMessageStatus] = useState<
    'success' | 'error' | 'loading'
  >('success');
  const handleRequest = () => {
    const url =
      `${BASE_URL}bespoke_reporting/counterparty_report/${startDate}/${endDate}` +
      schemas +
      middle +
      fundsForParam;
    const requestClient = client();
    setLoading(true);
    setMessageStatus('loading');
    setMessage('Generating Report...');
    requestClient
      .get(url, {
        responseType: 'blob',
      })
      .then((response: any) => {
        const blob = response.data;
        // Goal: download the blob as a file and name it with the filename from the response header
        // The issue is that axios is not exposing the content-disposition header
        // will name the file manually for now and try resolve that issue later
        // console.log(response)
        // console.log(response.headers['content-length'])
        // const filename = response.headers['content-disposition']
        //   .split(';')
        //   .find((n: string) => n.includes('filename='))
        //   .replace('filename=', '')
        //   .trim()
        // ;
        // saveAs(blob, filename);
        saveAs(
          blob,
          `${calculateQuarter(endDate)} ${dayjs(
            endDate
          ).year()} Counterparty Data.zip`
        );

        setLoading(false);
        setMessageStatus('success');
        setMessage('Report Generated!');
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        setMessageStatus('error');
        if (error.response?.status) {
          setMessage('Selected funds not found within the specified range.');
        } else {
          setMessage('Failed to generate report.');
        }
      })
      .finally(() => {
        setTimeout(() => {
          setMessageStatus('loading');
          setMessage('');
        }, 10000);
      });
  };

  return (
    <GridItem
      card
      xs={props.size}
      cardStyle={{ height: '100%', padding: '2rem' }}
    >
      <div className={classes.header}>
        <Typography
          variant="h2"
          style={{
            fontSize: '2.4rem',
            width: '100%',
            fontWeight: 500,
            textAlign: 'left',
          }}
          className={classes.header}
        >
          {props.cardTitle}
        </Typography>
      </div>
      <div className={classes.fdiContainer}>
        <RaptorDatePicker
          key={'start-date'}
          title={'Start Date: '}
          handler={(val) => {
            setStartDate(dayjs(val).format('YYYY-MM-DD'));
          }}
          datesToInclude={getAllRelevantDates()}
          selectedDate={dayjs(startDate)}
          tiedToActiveDate={false}
        />
        <RaptorDatePicker
          // key={picker.titleBarKey}
          title={'End Date: '}
          handler={(val) =>
            setEndDate(dayjs(val).format('YYYY-MM-DD'))
          }
          datesToInclude={getAllRelevantDates()}
          selectedDate={dayjs(endDate)}
          tiedToActiveDate={false}
        />
        <GeneralMultiSelect
          labels={fundNames}
          values={fundIds}
          selected={funds}
          setSelected={setFunds}
          placeholderValue={'None Selected'}
          placeholderColor={mainColors.mainBlue}
          selectTitle={'Select funds'}
          customStyles={{
            pickerRoot: classes.pickerRoot,
          }}
        />
        <FunctionalityButton
          handler={handleRequest}
          title="Run Report"
          height="6rem"
        />
        <div className={classes.feedback}>
          <div
            className={
              messageStatus === 'success'
                ? classes.successMessage
                : messageStatus === 'error'
                ? classes.errorMessage
                : classes.loadingMessage
            }
          >
            {loading && (
              <CircularProgress size={20} style={{ marginRight: '1rem' }} />
            )}
            {message}
          </div>
        </div>
      </div>
    </GridItem>
  );
}

export default CounterpartyReport;
