import makeStyles from '@mui/styles/makeStyles';
import React, { FC, useState } from 'react';
import { RaptorTheme, greys, mainColors } from '../../../../../styling/theme';
import { percentageToTwoDecimalPlacesNoHundred } from '../../../../../utilities/numberFormatters';
import GenericBarChart from '../../../../charts/GenericBarChart';
import GeneralSelect from '../../../../selects/GeneralSelect';
import { Typography } from '@mui/material';

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  visualisationsContainer: {
    display: 'flex',
    position: 'relative',
    // top: '4rem',
    // height: 500,
    width: '100%',
  },

  genericBarChartContainer: {
    position: 'relative',
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '1.2rem',
    paddingTop: '1.2rem',
    paddingLeft: '1.2rem',
  },
  header: {
    position: 'relative',
    // top: '3rem',
  },
  tableContainer: {
    position: 'relative',
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '1.2rem',
  },
  generalSelectContainer: {
    position: 'relative',
    // height: 500,
    width: '15%',
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '1.2rem',
    height: '5rem',
    width: '100%',
    marginBottom: '1.2rem',
  },
  selectRoot: {
    width: '100%',
  },
}));

interface SectorExposureData {
  sector: string;
  Environmental: number;
  Social: number;
  'Corporate Governance': number;
  ESG: number;
}

function prepareSectorExposureData(
  data: any,
  sortBy: any
): Array<SectorExposureData> {
  const SectorExposureData: SectorExposureData[] = [];
  data.forEach((value: any) => {
    SectorExposureData.push({
      sector: value.sector_name,
      Environmental: value.scores.gross.e,
      Social: value.scores.gross.s,
      'Corporate Governance': value.scores.gross.g,
      ESG: value.scores.gross.esg,
    });
  });
  SectorExposureData.sort((a: any, b: any) => (a[sortBy] < b[sortBy] ? 1 : -1));
  return SectorExposureData;
}

interface esgSectorExposureProps {
  esgData: any;
}

interface esgSectorExposureChartProps {
  scoreType: string;
  data: any;
}

function createBars(selection: string) {
  switch (selection) {
    case 'Environmental':
      return [
        { dataKey: 'Environmental', key: 'e', fill: mainColors.Pass_darker },
      ];
    case 'Social':
      return [{ dataKey: 'Social', key: 's', fill: mainColors.pumpkin }];
    case 'Corporate Governance':
      return [
        {
          dataKey: 'Corporate Governance',
          key: 'g',
          fill: mainColors.fourthPurple,
        },
      ];
    default:
      return [{ dataKey: 'ESG', key: 'ESG', fill: mainColors.mainBlue }];
  }
}

const EsgSectorExposureChart: FC<esgSectorExposureChartProps> = (props) => {
  const { data, scoreType } = props;
  const SectorData = prepareSectorExposureData(data, scoreType);

  // Set the x axis props
  const xAxis = [
    {
      dataKey: 'sector',
      label: { value: 'Sector', dy: 10 },
      tickFormatter: (val: string) =>
        val.length > 10 ? val.substr(0, 10) + '...' : val,
    },
  ];

  // Set the bars and colors
  const bars = createBars(scoreType);

  const legendFormatter = (value: string) => {
    switch (value) {
      case 'environmental':
        return 'Environmental';
      case 'social':
        return 'Social';
      case 'corporateGovernance':
        return 'Corporate Governance';
      default:
        return value;
    }
  };
  const tooltipFormatter = (value: number) => {
    return percentageToTwoDecimalPlacesNoHundred(value);
  };

  return (
    <GenericBarChart
      xAxes={xAxis}
      height={500}
      width={'100%'}
      bars={bars}
      data={SectorData}
      title={`${scoreType} Sector Score`}
      loading={false}
      legend={false}
      yAxisLabel={{
        angle: -90,
        position: 'left',
        value: 'Score',
        style: {
          textAnchor: 'middle',
          // fill: theme.palette.grey[500],
        },
      }}
      legendWrapper={{ left: 0 }}
      legendLayout="horizontal"
      legendPositionHorizontal="center"
      legendPositionVertical="top"
      // legendFormatter={legendFormatter}
      // tooltipFormatter={tooltipFormatter}
    />
  );
};

const EsgSectorExposure: FC<esgSectorExposureProps> = (props) => {
  const classes = useStyles();
  const { esgData } = props;
  const [scoreType, setScoreType] = useState('ESG');
  // Set up the select for chosing net or gross exposure
  const setSelected = (value: any) => {
    setScoreType(value);
  };
  return (
    <>
      <div className={classes.visualisationsContainer}>
        <Typography
          variant="h2"
          style={{
            fontSize: '2.4rem',
            width: '100%',
            fontWeight: 500,
            textAlign: 'left',
          }}
          className={classes.header}
        >
          {'ESG Sector Scores'}
        </Typography>
        <div className={classes.generalSelectContainer}>
          <GeneralSelect
            labels={['ESG', 'Environmental', 'Social', 'Corporate Governance']}
            values={['ESG', 'Environmental', 'Social', 'Corporate Governance']}
            selected={scoreType}
            setSelected={setSelected}
            placeholderValue=""
            placeholderColor={greys.grey900}
            selectTitle={'Score Type'}
            customStyles={{
              pickerRoot: classes.pickerRoot,
              selectRoot: classes.selectRoot,
            }}
          />
        </div>
      </div>
      <EsgSectorExposureChart data={esgData} scoreType={scoreType} />
    </>
  );
};

export default EsgSectorExposure;
