import makeStyles from '@mui/styles/makeStyles';
import React, { Dispatch, FC, ReactElement, useState } from 'react';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { RaptorTheme, greys, mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { hexToRGBA } from '../../../../utilities/colorUtilities';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import PercentValueToggleButton from '../../../buttons/PercentValueToggleButton.component';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { Button, Typography } from '@mui/material';

interface DiscountCashflowTableProps {
  data: any;
  title: string;
  selectedPosition: string | null;
  setBarChartFilter: Dispatch<any>;
  barChartValue: string;
  percentValueOption: string;
  setPercentValueOption: Dispatch<string>;
  nav: number;
  defaultData: any;
  isDefaultScenario: boolean;
}

interface IDiscountCashflowData {
  year: number;
  ebit: number;
  investments: number;
  bondInterest: number;
  cashInterest: number;
  bondRedemptions: number;
  tax: number;
  fcf: number;
  cash: number;
  pvFcf: number;
  fvFcf: number;
  netEquityValue: number;
}
interface IDiscountCashflowDifferenceData {
  year: number;
  ebit: number;
  ebitChange: number;
  investments: number;
  investmentsChange: number;
  bondInterest: number;
  bondInterestChange: number;
  cashInterest: number;
  cashInterestChange: number;
  bondRedemptions: number;
  bondRedemptionsChange: number;
  tax: number;
  taxChange: number;
  fcf: number;
  fcfChange: number;
  cash: number;
  cashChange: number;
  pvFcf: number;
  pvFcfChange: number;
  fvFcf: number;
  fvFcfChange: number;
  netEquityValue: number;
  netEquityValueChange: number;
}

function buildSubscriptionsRedemptionData(
  data: any,
  selectedPosition: string | null,
  percentValueOption: string,
  nav: number
): IDiscountCashflowData[] {
  if (!data.data.length) return [];
  if (!selectedPosition) return [];
  // if (!('Bond Interest' in data.data[0])) return [];
  const peDataPositions = data.data[0];
  const peDataFiltered = peDataPositions.filter((obj: any) => {
    return obj.name === selectedPosition;
  });
  const peData = peDataFiltered[0];
  const numYears = Object.keys(peData['Bond Interest']).length;
  const tableData: IDiscountCashflowData[] = [];

  for (let i = 0; i < numYears; i++) {
    tableData.push({
      year: i,
      ebit:
        percentValueOption === 'percent'
          ? peData['EBIT'][i] / nav
          : peData['EBIT'][i],
      investments:
        percentValueOption === 'percent'
          ? peData['Investments'][i] / nav
          : peData['Investments'][i],
      bondInterest:
        percentValueOption === 'percent'
          ? peData['Bond Interest'][i] / nav
          : peData['Bond Interest'][i],
      cashInterest:
        percentValueOption === 'percent'
          ? peData['Cash Interest'][i] / nav
          : peData['Cash Interest'][i],
      bondRedemptions:
        percentValueOption === 'percent'
          ? peData['Bond Redemptions'][i] / nav
          : peData['Bond Redemptions'][i],
      tax:
        percentValueOption === 'percent'
          ? peData['Tax'][i] / nav
          : peData['Tax'][i],
      fcf:
        percentValueOption === 'percent'
          ? peData['FCF'][i] / nav
          : peData['FCF'][i],
      cash:
        percentValueOption === 'percent'
          ? peData['Cash'][i] / nav
          : peData['Cash'][i],
      pvFcf:
        percentValueOption === 'percent'
          ? peData['PV FCF'][i] / nav
          : peData['PV FCF'][i],
      fvFcf:
        percentValueOption === 'percent'
          ? peData['FV FCF'][i] / nav
          : peData['FV FCF'][i],
      netEquityValue:
        percentValueOption === 'percent'
          ? peData['Return'][i] / nav
          : peData['Return'][i],
    });
  }

  return tableData;
}
function buildDCFDataForComparison(
  data: any,
  selectedPosition: string | null,
  percentValueOption: string,
  nav: number,
  defaultScenarioData: any
): IDiscountCashflowDifferenceData[] {
  if (!data.data.length) return [];
  if (!defaultScenarioData.data.length) return [];
  if (!selectedPosition) return [];
  // if (!('Bond Interest' in data.data[0])) return [];
  const peDataPositions = data.data[0];
  const peDataFiltered = peDataPositions.filter((obj: any) => {
    return obj.name === selectedPosition;
  });
  const peData = peDataFiltered[0];

  const peDataDefaultPositions = defaultScenarioData.data[0];
  const peDataDefaultFiltered = peDataDefaultPositions.filter((obj: any) => {
    return obj.name === selectedPosition;
  });
  const defaultData = peDataDefaultFiltered[0];
  const numYears = Object.keys(peData['Bond Interest']).length;
  const tableData: IDiscountCashflowDifferenceData[] = [];

  for (let i = 0; i < numYears; i++) {
    tableData.push({
      year: i,
      ebit:
        percentValueOption === 'percent'
          ? peData['EBIT'][i] / nav
          : peData['EBIT'][i],
      ebitChange:
        percentValueOption === 'percent'
          ? (peData['EBIT'][i] - defaultData['EBIT'][i]) / nav
          : peData['EBIT'][i] - defaultData['EBIT'][i],
      investments:
        percentValueOption === 'percent'
          ? peData['Investments'][i] / nav
          : peData['Investments'][i],
      investmentsChange:
        percentValueOption === 'percent'
          ? (peData['Investments'][i] - defaultData['Investments'][i]) / nav
          : peData['Investments'][i] - defaultData['Investments'][i],
      bondInterest:
        percentValueOption === 'percent'
          ? peData['Bond Interest'][i] / nav
          : peData['Bond Interest'][i],
      bondInterestChange:
        percentValueOption === 'percent'
          ? (peData['Bond Interest'][i] - defaultData['Bond Interest'][i]) / nav
          : peData['Bond Interest'][i] - defaultData['Bond Interest'][i],
      cashInterest:
        percentValueOption === 'percent'
          ? peData['Cash Interest'][i] / nav
          : peData['Cash Interest'][i],
      cashInterestChange:
        percentValueOption === 'percent'
          ? (peData['Cash Interest'][i] - defaultData['Cash Interest'][i]) / nav
          : peData['Cash Interest'][i] - defaultData['Cash Interest'][i],
      bondRedemptions:
        percentValueOption === 'percent'
          ? peData['Bond Redemptions'][i] / nav
          : peData['Bond Redemptions'][i],
      bondRedemptionsChange:
        percentValueOption === 'percent'
          ? (peData['Bond Redemptions'][i] -
              defaultData['Bond Redemptions'][i]) /
            nav
          : peData['Bond Redemptions'][i] - defaultData['Bond Redemptions'][i],
      tax:
        percentValueOption === 'percent'
          ? peData['Tax'][i] / nav
          : peData['Tax'][i],
      taxChange:
        percentValueOption === 'percent'
          ? (peData['Tax'][i] - defaultData['Tax'][i]) / nav
          : peData['Tax'][i] - defaultData['Tax'][i],
      fcf:
        percentValueOption === 'percent'
          ? peData['FCF'][i] / nav
          : peData['FCF'][i],
      fcfChange:
        percentValueOption === 'percent'
          ? (peData['FCF'][i] - defaultData['FCF'][i]) / nav
          : peData['FCF'][i] - defaultData['FCF'][i],
      cash:
        percentValueOption === 'percent'
          ? peData['Cash'][i] / nav
          : peData['Cash'][i],
      cashChange:
        percentValueOption === 'percent'
          ? (peData['Cash'][i] - defaultData['Cash'][i]) / nav
          : peData['Cash'][i] - defaultData['Cash'][i],
      pvFcf:
        percentValueOption === 'percent'
          ? peData['PV FCF'][i] / nav
          : peData['PV FCF'][i],
      pvFcfChange:
        percentValueOption === 'percent'
          ? (peData['PV FCF'][i] - defaultData['PV FCF'][i]) / nav
          : peData['PV FCF'][i] - defaultData['PV FCF'][i],
      fvFcf:
        percentValueOption === 'percent'
          ? peData['FV FCF'][i] / nav
          : peData['FV FCF'][i],
      fvFcfChange:
        percentValueOption === 'percent'
          ? (peData['FV FCF'][i] - defaultData['FV FCF'][i]) / nav
          : peData['FV FCF'][i] - defaultData['FV FCF'][i],
      netEquityValue:
        percentValueOption === 'percent'
          ? peData['Return'][i] / nav
          : peData['Return'][i],
      netEquityValueChange:
        percentValueOption === 'percent'
          ? (peData['Return'][i] - defaultData['Return'][i]) / nav
          : peData['Return'][i] - defaultData['Return'][i],
    });
  }

  return tableData;
}

// function buildWorstPositionColumns(
//     filterFunction: (value: any) => any,
//     scoreType: string
// ): CustomColumn<IEsgExposureData>[] {

function buildDetailColumns(
  filterFunction: Dispatch<any>,
  barChartValue: string,
  percentValueOption: string
): CustomColumn<IDiscountCashflowData>[] {
  return [
    {
      title: 'Year',
      field: 'year',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      // customSort: (rowData: any) => 1,
    },
    {
      title: <div onClick={() => filterFunction('EBIT')}>EBIT</div>,
      field: 'ebit',
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'EBIT'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.ebit)
          : addCommasToNumbersAndRound(rowData.ebit),
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('EBIT')
      //     return 1;
      // },
    },
    {
      title: (
        <div onClick={() => filterFunction('Investments')}>Investments</div>
      ),
      field: 'investments',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.investments)
          : addCommasToNumbersAndRound(rowData.investments),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'Investments'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('Investments')
      //     return 1;
      // }
    },
    {
      title: (
        <div onClick={() => filterFunction('Bond Interest')}>Bond Interest</div>
      ),
      field: 'bondInterest',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.bondInterest)
          : addCommasToNumbersAndRound(rowData.bondInterest),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'Bond Interest'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('Bond Interest')
      //     return 1;
      // }
    },
    {
      title: (
        <div onClick={() => filterFunction('Bond Redemptions')}>
          Bond Redemptions
        </div>
      ),
      field: 'bondRedemptions',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.bondRedemptions)
          : addCommasToNumbersAndRound(rowData.bondRedemptions),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'Bond Redemptions'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('Bond Redemptions')
      //     return 1;
      // }
    },

    {
      title: <div onClick={() => filterFunction('Tax')}>Tax</div>,
      field: 'tax',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.tax)
          : addCommasToNumbersAndRound(rowData.tax),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'Tax'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('Tax')
      //     return 1;
      // }
    },
    {
      title: <div onClick={() => filterFunction('FCF')}>FCF</div>,
      field: 'fcf',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.fcf)
          : addCommasToNumbersAndRound(rowData.fcf),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'FCF'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('FCF')
      //     return 1;
      // }
    },
    {
      title: <div onClick={() => filterFunction('Cash')}>Cash</div>,
      field: 'cash',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.cash)
          : addCommasToNumbersAndRound(rowData.cash),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'Cash'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('Cash')
      //     return 1;
      // }
    },
    {
      title: <div onClick={() => filterFunction('PV FCF')}>PV FCF</div>,
      field: 'pvFcf',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.pvFcf)
          : addCommasToNumbersAndRound(rowData.pvFcf),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'PV FCF'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('PV FCF')
      //     return 1;
      // }
    },
    {
      title: <div onClick={() => filterFunction('FV FCF')}>FV FCF</div>,
      field: 'fvFcf',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.fvFcf)
          : addCommasToNumbersAndRound(rowData.fvFcf),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'FV FCF'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('FV FCF')
      //     return 1;
      // }
    },
    {
      title: <div onClick={() => filterFunction('Return')}>Return</div>,
      field: 'netEquityValue',
      render: (rowData) =>
        percentValueOption === 'percent'
          ? percentageToTwoDecimalPlaces(rowData.netEquityValue)
          : addCommasToNumbersAndRound(rowData.netEquityValue),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'Return'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      // customSort: (rowData: IDiscountCashflowData) => {
      //     filterFunction('Return')
      //     return -1;
      // }
    },
  ];
}

// function PEHeatMapCell(props: PEHeatMapCellProps): ReactElement
interface DifferenceCellProps {
  value: number;
  changeValue: number;
  percentValueOption: string;
  displayOption: string;
}
function DifferenceCell(props: DifferenceCellProps): ReactElement {
  const { value, changeValue, percentValueOption, displayOption } = props;
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      {displayOption !== 'change' && (
        <div>
          {percentValueOption === 'percent'
            ? percentageToTwoDecimalPlaces(value)
            : addCommasToNumbersAndRound(value)}
        </div>
      )}
      {displayOption !== 'scenario' && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {displayOption === 'both' && '('}
          <div
            style={{
              color:
                changeValue === 0
                  ? mainColors.mainBlue
                  : changeValue < 0
                  ? mainColors.Fail
                  : mainColors.Pass,
            }}
          >
            {`${
              percentValueOption === 'percent'
                ? percentageToTwoDecimalPlaces(changeValue)
                : addCommasToNumbersAndRound(changeValue)
            }`}
          </div>
          {displayOption === 'both' && ')'}
        </div>
      )}
    </div>
  );
}
function buildDetailColumnsDifferenceData(
  filterFunction: Dispatch<any>,
  barChartValue: string,
  percentValueOption: string,
  displayOption: string
): CustomColumn<IDiscountCashflowDifferenceData>[] {
  return [
    {
      title: 'Year',
      field: 'year',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      width: '6.5%',
    },
    {
      title: <div onClick={() => filterFunction('EBIT')}>EBIT</div>,
      field: 'ebit',
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'EBIT'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      render: (rowData) => (
        <DifferenceCell
          value={rowData.ebit}
          changeValue={rowData.ebitChange}
          percentValueOption={percentValueOption}
          displayOption={displayOption}
        />
      ),
      width: '8.5%',
    },
    {
      title: (
        <div onClick={() => filterFunction('Investments')}>Investments</div>
      ),
      field: 'investments',
      render: (rowData) => (
        <DifferenceCell
          value={rowData.investments}
          changeValue={rowData.investmentsChange}
          percentValueOption={percentValueOption}
          displayOption={displayOption}
        />
      ),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'Investments'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      width: '8.5%',
    },
    {
      title: (
        <div onClick={() => filterFunction('Bond Interest')}>Bond Interest</div>
      ),
      field: 'bondInterest',
      render: (rowData) => (
        <DifferenceCell
          value={rowData.bondInterest}
          changeValue={rowData.bondInterestChange}
          percentValueOption={percentValueOption}
          displayOption={displayOption}
        />
      ),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'Bond Interest'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      width: '8.5%',
    },
    {
      title: (
        <div onClick={() => filterFunction('Bond Redemptions')}>
          Bond Redemptions
        </div>
      ),
      field: 'bondRedemptions',
      render: (rowData) => (
        <DifferenceCell
          value={rowData.bondRedemptions}
          changeValue={rowData.bondRedemptionsChange}
          percentValueOption={percentValueOption}
          displayOption={displayOption}
        />
      ),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'Bond Redemptions'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      width: '8.5%',
    },

    {
      title: <div onClick={() => filterFunction('Tax')}>Tax</div>,
      field: 'tax',
      render: (rowData) => (
        <DifferenceCell
          value={rowData.tax}
          changeValue={rowData.taxChange}
          percentValueOption={percentValueOption}
          displayOption={displayOption}
        />
      ),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'Tax'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      width: '8.5%',
    },
    {
      title: <div onClick={() => filterFunction('FCF')}>FCF</div>,
      field: 'fcf',
      render: (rowData) => (
        <DifferenceCell
          value={rowData.fcf}
          changeValue={rowData.fcfChange}
          percentValueOption={percentValueOption}
          displayOption={displayOption}
        />
      ),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'FCF'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      width: '8.5%',
    },
    {
      title: <div onClick={() => filterFunction('Cash')}>Cash</div>,
      field: 'cash',
      render: (rowData) => (
        <DifferenceCell
          value={rowData.cash}
          changeValue={rowData.cashChange}
          percentValueOption={percentValueOption}
          displayOption={displayOption}
        />
      ),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'Cash'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      width: '8.5%',
    },
    {
      title: (
        <div onClick={() => filterFunction('Cash Interest')}>Cash Interest</div>
      ),
      field: 'cashInterest',
      render: (rowData) => (
        <DifferenceCell
          value={rowData.cashInterest}
          changeValue={rowData.cashInterestChange}
          percentValueOption={percentValueOption}
          displayOption={displayOption}
        />
      ),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'Cash Interest'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      width: '8.5%',
    },
    {
      title: <div onClick={() => filterFunction('PV FCF')}>PV FCF</div>,
      field: 'pvFcf',
      render: (rowData) => (
        <DifferenceCell
          value={rowData.pvFcf}
          changeValue={rowData.pvFcfChange}
          percentValueOption={percentValueOption}
          displayOption={displayOption}
        />
      ),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'PV FCF'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      width: '8.5%',
    },
    {
      title: <div onClick={() => filterFunction('FV FCF')}>FV FCF</div>,
      field: 'fvFcf',
      render: (rowData) => (
        <DifferenceCell
          value={rowData.fvFcf}
          changeValue={rowData.fvFcfChange}
          percentValueOption={percentValueOption}
          displayOption={displayOption}
        />
      ),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'FV FCF'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      width: '8.5%',
    },
    {
      title: <div onClick={() => filterFunction('Return')}>Return</div>,
      field: 'netEquityValue',
      render: (rowData) => (
        <DifferenceCell
          value={rowData.netEquityValue}
          changeValue={rowData.netEquityValueChange}
          percentValueOption={percentValueOption}
          displayOption={displayOption}
        />
      ),
      cellStyle: { textAlign: 'center' },
      headerStyle:
        barChartValue === 'Return'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      width: '8.5%',
    },
  ];
}

interface DisplayOptionsButtonsProps {
  setCurrentOption: Dispatch<any>;
  currentOption: string;
}

interface StyleProps {
  currentOption: string;
}

const useDisplayOptionsButtonsStyles = makeStyles<RaptorTheme, StyleProps>(
  (theme) => ({
    container: {
      lineHeight: 25,
    },
    contained: {
      height: '3rem',
      background: 'white',
      borderRadius: 8,
      // paddingBottom: (props) => (props.clicked ? 0 : '2rem'),
      // paddingTop: (props) => (props.clicked ? 0 : '2rem'),
    },
    rootScenario: {
      marginRight: '0.4rem',
      marginTop: '1rem',
      display: 'inline-flex',
      backgroundColor: (props) =>
        props.currentOption === 'scenario'
          ? mainColors.mainBlue
          : greys.grey400,
      '&:hover': {
        backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
      },
    },
    rootChange: {
      marginRight: '0.4rem',
      marginTop: '1rem',
      display: 'inline-flex',
      backgroundColor: (props) =>
        props.currentOption === 'change' ? mainColors.mainBlue : greys.grey400,
      '&:hover': {
        backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
      },
    },
    rootBoth: {
      marginRight: '0.4rem',
      marginTop: '1rem',
      display: 'inline-flex',
      backgroundColor: (props) =>
        props.currentOption === 'both' ? mainColors.mainBlue : greys.grey400,
      '&:hover': {
        backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
      },
    },
    label: {
      flexDirection: 'column',

      alignItems: 'start',
    },
    buttonText: {
      colorPrimary: theme.palette.common.white,
    },
  })
);

function DisplayOptionsButtons(
  props: DisplayOptionsButtonsProps
): ReactElement {
  const { currentOption, setCurrentOption } = props;
  const classes = useDisplayOptionsButtonsStyles({ currentOption });
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <Button
        variant="contained"
        onClick={() => setCurrentOption('scenario')}
        classes={{
          contained: classes.contained,
          root: classes.rootScenario,
          // label: classes.label,
        }}
      >
        <Typography
          variant="h3"
          color="primary"
          style={{
            color: 'white',
          }}
        >
          {'Current Scenario'}
        </Typography>
      </Button>
      <Button
        variant="contained"
        onClick={() => setCurrentOption('change')}
        classes={{
          contained: classes.contained,
          root: classes.rootChange,
          // label: classes.label,
        }}
      >
        <Typography
          variant="h3"
          color="primary"
          style={{
            color: 'white',
          }}
        >
          {'Change'}
        </Typography>
      </Button>
      <Button
        variant="contained"
        onClick={() => setCurrentOption('both')}
        classes={{
          contained: classes.contained,
          root: classes.rootBoth,
          // label: classes.label,
        }}
      >
        <Typography
          variant="h3"
          color="primary"
          style={{
            color: 'white',
          }}
        >
          {'Scenario With Change'}
        </Typography>
      </Button>
    </div>
  );
}

const DiscountCashflowTable: FC<DiscountCashflowTableProps> = (props) => {
  // const theme = useTheme();
  const {
    data,
    title,
    selectedPosition,
    percentValueOption,
    setPercentValueOption,
    nav,
    defaultData,
    isDefaultScenario,
  } = props;
  // const tableData = isDefaultScenario ? buildSubscriptionsRedemptionData(data, selectedPosition, percentValueOption, nav) : buildDCFDataForComparison(data, selectedPosition, percentValueOption, nav, defaultData);
  const { height, width } = useWindowSize();
  // Allow user to switch between scenrio, change and both
  const [displayOption, setDisplayOption] = useState<string>('scenario');
  const detailColumns = buildDetailColumns(
    props.setBarChartFilter,
    props.barChartValue,
    percentValueOption
  );
  const detailColumnsDifference = buildDetailColumnsDifferenceData(
    props.setBarChartFilter,
    props.barChartValue,
    percentValueOption,
    displayOption
  );

  return (
    <GridItem
      xs={12}
      card
      style={{ padding: 8 }}
      cardStyle={{ minHeight: height / 2 + 70 }}
    >
      <Typography
        variant="h2"
        style={{ marginLeft: '2rem', marginTop: '1rem', textAlign: 'center' }}
      >
        {title}
      </Typography>

      <div style={{ float: 'left', marginLeft: '2rem', textAlign: 'center' }}>
        <PercentValueToggleButton
          percentValueOption={percentValueOption}
          updatePercentValueOption={setPercentValueOption}
        />
        {!isDefaultScenario && (
          <DisplayOptionsButtons
            currentOption={displayOption}
            setCurrentOption={setDisplayOption}
          />
        )}
      </div>
      {isDefaultScenario ? (
        <CustomTable<IDiscountCashflowData>
          pdfTitle={title}
          id={title}
          columns={detailColumns}
          showToolbar
          data={buildSubscriptionsRedemptionData(
            data,
            selectedPosition,
            percentValueOption,
            nav
          )}
          // toolbarComponents={{
          //     toolbarTitle: formattedNames[tableType],
          // }}
          options={{
            paging: false,
            search: false,
            exportButton: true,
            sorting: false,
          }}
        />
      ) : (
        <CustomTable<IDiscountCashflowDifferenceData>
          pdfTitle={title}
          id={title}
          columns={detailColumnsDifference}
          showToolbar
          data={buildDCFDataForComparison(
            data,
            selectedPosition,
            percentValueOption,
            nav,
            defaultData
          )}
          // toolbarComponents={{
          //     toolbarTitle: formattedNames[tableType],
          // }}
          options={{
            paging: false,
            search: false,
            exportButton: true,
            sorting: false,
          }}
        />
      )}
    </GridItem>
  );
};

export default DiscountCashflowTable;
