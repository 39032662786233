import React, { FC } from 'react';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import GeneralCard from '../../../general/GeneralCard';
import ExposureBarChart from './ExposureBarChart';
import { useWindowSize } from '../../../../hooks/useWindowSize';

export interface AssetClassGrossExposureProps {
  dataForRender: DataObject;
}

const AssetClassGrossExposureChart: FC<AssetClassGrossExposureProps> = (
  props
) => {
  const { data } = props.dataForRender;
  const { width, height } = useWindowSize();
  return (
    <GeneralCard cardStyle={{ flexGrow: 1 }}>
      <ExposureBarChart
        windowWidth={width}
        windowHeight={height}
        inputData={props.dataForRender}
        exposureType="assetClass"
      />
    </GeneralCard>
  );
};

export default AssetClassGrossExposureChart;
