import React from 'react';
import RsSwipe from '../../../images/RsSwipe';
import RAIDRLayout from './RAIDRLayout.component';
import DocumentationLayout from './DocumentationLayout.component';
import GeneralLayout from './GeneralLayout.component';
import SettingsLayout from './SettingsLayout.component';
import { ClassNameMap } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  loginPage: {
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rsSwipe: {
    position: 'fixed',
    right: 0,
    height: '100vh',
    width: '100vw',
  },
});

type LayoutProps = {
  children?: React.ReactNode;
  section: string;
};

const CalculateLayout = (
  section: string,
  children: React.ReactNode,
  classes: ClassNameMap<string>
) => {
  switch (section) {
    case 'login':
    case 'signup':
    case 'reset-password':
      return (
        <main className={classes.loginPage}>
          {children}
          <RsSwipe className={classes.rsSwipe} />
        </main>
      );
    case 'kiids':
    case 'kids':
    case 'raidr':
      return <RAIDRLayout>{children}</RAIDRLayout>;
    case 'documentation':
      return <DocumentationLayout>{children}</DocumentationLayout>;
    case 'settings':
      return <SettingsLayout>{children}</SettingsLayout>;
    default:
      return <GeneralLayout>{children}</GeneralLayout>;
  }
};

const Layout = ({ children, section }: LayoutProps) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {CalculateLayout(section, children, classes)}
    </React.Fragment>
  );
};

export default Layout;
