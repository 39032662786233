import React, { useEffect, useMemo, useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme, mainColors } from '../../styling/theme';
import {
  Box,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Tooltip,
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import lodash from 'lodash';

const DATE_FORMAT = 'YYYY-MM-DD';

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
  },
  tooltipError: {
    backgroundColor: mainColors.Fail,
  },
  mainRoot: {
    color: theme.palette.primary.main,
    padding: '0.5rem',
    borderRadius: '.8rem',
    display: 'flex',
    flexDirection: 'column',
    transition: 'backgroundColor .3s',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    userSelect: 'none',
  },
  title: {
    color: 'inherit',
    fontSize: '1.7rem',
    whiteSpace: 'nowrap',
  },
  textFieldRoot: {
    color: theme.palette.grey[800],
    '& svg': {
      fontSize: '2rem',
      color: theme.palette.primary.main,
      display: 'inline-block',
    },
    '& .MuiInputAdornment-positionEnd': {
      margin: 0,
      display: 'flex',
    },
  },
  textFieldRootInvalid: {
    color: mainColors.Fail,
  },
  input: {
    fontFamily: 'monospace',
    backgroundColor: 'inherit !important',
    width: '10rem',
    padding: '1rem',
    paddingRight: '0',
    lineHeight: 1.5,
  },
  inputRoot: {
    justifyContent: 'space-between',
    color: 'inherit',
    paddingRight: 0,
    borderRadius: 8,
  },
  popoverContainer: {
    width: '31rem',
  },
  selector: {
    display: 'flex',
    height: '28rem',
  },
  scrollArea: {
    width: '100%',
    padding: '1rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.6rem',
    },
    '&::-webkit-scrollbar-track': {
      border: '0.2rem solid white',
      backgroundColor: mainColors.lightGrey,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.hoverOverMainBlue,
      borderRadius: '1rem',
    },
  },
  scrollItem: {
    fontSize: '2rem',
    fontWeight: 500,
    cursor: 'pointer',
    transition: 'background-color .2s ease-in-out',
    padding: '1rem 2rem',
    borderRadius: '0.5rem',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: mainColors.hoverOverWhite,
    },
  },
  monthItem: {
    margin: '0.5rem',
  },
  yearItem: {
    margin: '0.5rem 1.5rem',
  },

  // for calendar component
  datePickerCalendar: {
    padding: '1rem',
  },
  datePickerCalendar__row: {
    display: 'flex',
  },
  datePickerCalendar__header: {
    marginBottom: '8px',
    display: 'flex',
  },
  datePickerCalendar__cell: {
    width: '4rem',
    height: '4rem',
    margin: '0.1rem',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.2rem',
    userSelect: 'none',
  },
  datePickerCalendar__headerCell: {
    fontWeight: 600,
    fontSize: '1.4rem',
    color: mainColors.mainBlue,
  },
  datePickerCalendar__dayCell__included: {
    color: mainColors.mainBlue,
    cursor: 'pointer',
    transition: 'background-color .2s ease-in-out, color .2s ease-in-out',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: mainColors.hoverOverWhite,
    },
  },
  datePickerCalendar__dayCell_selected: {
    cursor: 'pointer',
    backgroundColor: mainColors.mainBlue,
    color: 'white',
    '&:hover': {
      backgroundColor: mainColors.hoverOverMainBlue,
    },
  },
  datePickerCalendar__notIncluded: {
    color: mainColors.inactiveGrey,
  },

  // for DatePickerSelector
  datePickerSelector: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '4rem',
    borderBottom: '1px solid #b3b3b3',
    padding: '2.5rem 1rem',
    fontSize: '1.6rem',
    fontWeight: 500,
    backgroundColor: mainColors.mainBlue,
    color: 'white',
  },

  datePickerSelector__month: {
    cursor: 'pointer',
    transition: 'background-color .2s ease-in-out',
    padding: '0.5rem 1rem',
    marginLeft: '3rem',
    borderRadius: '0.5rem',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: mainColors.hoverOverMainBlue,
    },
  },
  datePickerSelector__year: {
    cursor: 'pointer',
    transition: 'background-color .2s ease-in-out',
    padding: '0.5rem 1rem',
    marginRight: '3rem',
    borderRadius: '0.5rem',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: mainColors.hoverOverMainBlue,
    },
  },

  datePickerSelector__icon: {
    width: '3rem',
    height: '3rem',
    borderRadius: '1rem',
    cursor: 'pointer',
    transition: 'background-color .2s ease-in-out',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fontSize: '2rem',
    },
    '&:hover': {
      backgroundColor: mainColors.hoverOverMainBlue,
    },
  },

  actionButtonsContainer: {
    padding: '0 2rem 2rem 2rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionButton: {
    userSelect: 'none',
    border: 'none',
    color: 'white',
    padding: '1rem',
    cursor: 'pointer',
    borderRadius: '0.5rem',
    transition: 'background-color .2s ease-in-out',
    backgroundColor: mainColors.mainBlue,
    '&:hover': {
      backgroundColor: mainColors.hoverOverMainBlue,
    },
  },
}));

// our interface for a single cell
interface ICalendarCell {
  text: string;
  value: Dayjs;
}

// the function to generate all calendar cells, orgnaised into rows of weeks, for a given month and year
function getCalendarRows(month: number, year: number): Array<ICalendarCell[]> {
  const dayjsMonth = dayjs().set('month', month).set('year', year);
  const daysInMonth = dayjsMonth.daysInMonth();
  const calendarCells: ICalendarCell[] = [];

  const prepareCell = (
    date: Dayjs,
    dayNumber: number,
    originalMonth: number,
    currentMonth: number
  ) => {
    if (originalMonth === currentMonth) {
      return {
        text: String(dayNumber),
        value: date.clone().set('date', dayNumber),
      };
    } else {
      return {
        text: '',
        value: date.clone().set('date', dayNumber),
      };
    }
  };

  // push current month day cells
  for (let i = 0; i < daysInMonth; i++) {
    calendarCells.push(
      prepareCell(dayjsMonth, i + 1, dayjsMonth.month(), dayjsMonth.month())
    );
  }

  // add to start from prev month
  const lastMonth = dayjsMonth.subtract(1, 'month');
  const startDay = calendarCells[0].value.get('day');
  for (let i = 0; i < startDay; i++) {
    calendarCells.unshift(
      prepareCell(
        lastMonth,
        lastMonth.daysInMonth() - i,
        dayjsMonth.month(),
        dayjsMonth.month() - 1
      )
    );
  }

  // add to end from next month
  const nextMonth = dayjsMonth.add(1, 'month');
  const prevLength = calendarCells.length;
  for (let i = prevLength; i < 35; i++) {
    calendarCells.push(
      prepareCell(
        nextMonth,
        i - prevLength + 1,
        dayjsMonth.month(),
        dayjsMonth.month() + 1
      )
    );
  }

  // organise the cells into a list of weeks
  const rows: Array<ICalendarCell[]> = [];
  for (let i = 0; i < calendarCells.length; i += 7) {
    rows.push(calendarCells.slice(i, i + 7));
  }

  return rows;
}

interface IDatePickerCalendarProps {
  selectedDate: Dayjs;
  onChange: (newDate: Dayjs) => void;
  datesToInclude?: any[];
  pickerMonth: number;
  pickerYear: number;
}

// the calendar component, which renders rows of date cells for the user to select
const DatePickerCalendar: React.FC<IDatePickerCalendarProps> = ({
  selectedDate,
  onChange,
  datesToInclude,
  pickerMonth,
  pickerYear,
}) => {
  const classes = useStyles();

  const [date, setDate] = useState<Dayjs>(selectedDate);

  useEffect(() => {
    setDate(selectedDate);
  }, [selectedDate]);

  const handleSelectDate = (value: Dayjs) => {
    return () => {
      setDate(value);
      onChange(value);
    };
  };

  const rows = useMemo(
    () => getCalendarRows(pickerMonth, pickerYear),
    [selectedDate, pickerMonth, pickerYear]
  );

  return (
    <div className={classes.datePickerCalendar}>
      <div className={classes.datePickerCalendar__header}>
        {rows[0].map(({ value }, i) => (
          <div
            key={i}
            className={clsx(
              classes.datePickerCalendar__cell,
              classes.datePickerCalendar__headerCell
            )}
          >
            {value.format('dd')}
          </div>
        ))}
      </div>

      {rows.map((cells, rowIndex) => (
        <div key={rowIndex} className={classes.datePickerCalendar__row}>
          {cells.map(({ text, value }, i) => {
            // if the cell is related to a previous or next month, only render a blank cell for spacing
            if (text === '') {
              return (
                <div
                  key={`${text} - ${i}`}
                  className={classes.datePickerCalendar__cell}
                >
                  {text}
                </div>
              );
              // else if datesToInclude were provided and the current cell is in them, render it as clickable
            } else if (
              datesToInclude &&
              datesToInclude.includes(value.format(DATE_FORMAT))
            ) {
              return (
                <div
                  key={`${text} - ${i}`}
                  className={clsx(
                    classes.datePickerCalendar__cell,
                    value.format(DATE_FORMAT) === date.format(DATE_FORMAT)
                      ? classes.datePickerCalendar__dayCell_selected
                      : classes.datePickerCalendar__dayCell__included
                  )}
                  onClick={handleSelectDate(value)}
                >
                  {text}
                </div>
              );
              // else if datesToInclude were provided and the current cell is not in them, render it as not clickable
            } else if (datesToInclude) {
              return (
                <div
                  key={`${text} - ${i}`}
                  className={clsx(
                    classes.datePickerCalendar__cell,
                    classes.datePickerCalendar__notIncluded
                  )}
                >
                  {text}
                </div>
              );
              // else render the cell as clickable by default
            } else {
              return (
                <div
                  key={`${text} - ${i}`}
                  className={clsx(
                    classes.datePickerCalendar__cell,
                    value.toString() === date.toString()
                      ? classes.datePickerCalendar__dayCell_selected
                      : classes.datePickerCalendar__dayCell__included
                  )}
                  onClick={handleSelectDate(value)}
                >
                  {text}
                </div>
              );
            }
          })}
        </div>
      ))}
    </div>
  );
};

interface IDatePickerProps {
  title: string;
  selectedDate: Dayjs;
  datesToInclude?: any[];
  handler: (val: Date | null) => void;
}

const ReportDatePicker: React.FC<IDatePickerProps> = ({
  selectedDate,
  datesToInclude,
  handler,
}) => {
  const classes = useStyles();

  const [shownDate, setShownDate] = useState<string>(
    selectedDate.format(DATE_FORMAT)
  );
  const [validDate, setValidDate] = useState<Dayjs>(selectedDate);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [pickerYear, setPickerYear] = useState<number>(
    selectedDate.get('year')
  );
  const [pickerMonth, setPickerMonth] = useState<number>(
    selectedDate.get('month')
  );
  const [selector, setSelector] = useState<null | 'year' | 'month'>(null);
  const [availableYears] = useState<number[]>((): number[] => {
    if (datesToInclude) {
      const allYears = datesToInclude.map((date: string) =>
        Number(date.split('-')[0])
      );
      return allYears.filter(
        (value, index, self) => self.indexOf(value) === index
      );
    } else {
      const currentYear = Number(dayjs().format('YYYY'));
      return lodash.range(currentYear, 2009, -1);
    }
  });
  const [availableMonths] = useState<number[]>((): number[] => {
    return lodash.range(11, -1, -1);
  });

  // new ref so that calendar anchor can be set to a custom point of the component
  const datePickerRef = React.createRef();

  // when calendar component opened, set anchor and set active year and month to current
  const handleOpen = () => {
    setAnchorEl(datePickerRef.current as HTMLElement);
    setPickerYear(selectedDate.get('year'));
    setPickerMonth(selectedDate.get('month'));
  };

  // handle closing of calendar by setting anchor to null
  const handleClose = () => {
    setAnchorEl(null);
    setSelector(null);
  };

  // function to test if string date is of a valid format
  const stringDateIsValid = (date: string, DATE_FORMAT: string): boolean => {
    if (
      dayjs(date, DATE_FORMAT).format(DATE_FORMAT) === date &&
      date.length === 10
    ) {
      return true;
    } else {
      return false;
    }
  };

  // function to test if string exists in datesToInclude
  const dateIncluded = (
    date: string,
    datesToInclude: any[] | undefined
  ): boolean => {
    if (datesToInclude && datesToInclude.includes(date)) {
      return true;
    } else {
      return false;
    }
  };

  // this function takes the user input and auto formats it to align with YYYY-MM-DD before updating the state
  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    //eslint-disable-next-line
    const numPattern = /^[0-9\-]+$/;
    if (
      event.target.value.substring(event.target.value.length - 1) === '-' &&
      event.target.value.length !== 5 &&
      event.target.value.length !== 8
    ) {
      setShownDate(
        event.target.value.substring(0, event.target.value.length - 1)
      );
    } else if (
      (event.target.value.length === 5 && shownDate.length === 4) ||
      (event.target.value.length === 8 && shownDate.length === 7)
    ) {
      if (event.target.value.substring(event.target.value.length - 1) === '-') {
        setShownDate(event.target.value);
      } else {
        setShownDate(
          event.target.value.substring(0, event.target.value.length - 1) +
            '-' +
            event.target.value.substring(event.target.value.length - 1)
        );
      }
    } else {
      if (
        numPattern.test(event.target.value) &&
        event.target.value.length <= 10
      ) {
        if (
          (event.target.value.length === 4 && shownDate.length === 3) ||
          (event.target.value.length === 7 && shownDate.length === 6)
        ) {
          setShownDate(event.target.value + '-');
        } else {
          setShownDate(event.target.value);
        }
        if (
          datesToInclude &&
          dateIncluded(event.target.value, datesToInclude)
        ) {
          setValidDate(dayjs(event.target.value));
        } else if (
          !datesToInclude &&
          stringDateIsValid(event.target.value, DATE_FORMAT)
        ) {
          setValidDate(dayjs(event.target.value));
        }
      } else if (!event.target.value.length) {
        setShownDate('');
      }
    }
  };

  // handler for the date chosen in the calendar component
  const handleChoice = (newDate: Dayjs) => {
    setValidDate(newDate);
    setPickerMonth(newDate.month());
    setPickerYear(newDate.year());
    handler(newDate.toDate());
    handleClose();
  };

  // this function calculates the tooltip title based on if data is valid/ if included in datesIncluded
  const getTooltipTitle = () => {
    if (datesToInclude) {
      if (!stringDateIsValid(shownDate, DATE_FORMAT)) {
        return 'Invalid Date';
      } else if (!dateIncluded(shownDate, datesToInclude)) {
        return 'No Report Available For This Date';
      } else {
        return 'Select a Date To Generate A Report';
      }
    } else {
      if (!stringDateIsValid(shownDate, DATE_FORMAT)) {
        return 'Invalid Date';
      } else {
        return 'Select a Date To Generate A Report';
      }
    }
  };

  useEffect(() => {
    if (datesToInclude) {
      setIsValid(dateIncluded(shownDate, datesToInclude));
    } else {
      setIsValid(stringDateIsValid(shownDate, DATE_FORMAT));
    }
  }, [shownDate]);

  // these two handlers are for moving one month forward or backwards on the calendar component
  const handleBackMonth = () => {
    setSelector(null);
    if (pickerMonth === 0) {
      setPickerMonth(11);
      setPickerYear(pickerYear - 1);
    } else {
      setPickerMonth(pickerMonth - 1);
    }
  };
  const handleForwardMonth = () => {
    setSelector(null);
    if (pickerMonth === 11) {
      setPickerMonth(0);
      setPickerYear(pickerYear + 1);
    } else {
      setPickerMonth(pickerMonth + 1);
    }
  };

  const toggleMonthSelector = () => {
    if (!selector || selector === 'year') {
      setSelector('month');
    } else {
      setSelector(null);
    }
  };

  const toggleYearSelector = () => {
    if (!selector || selector === 'month') {
      setSelector('year');
    } else {
      setSelector(null);
    }
  };

  const handleYearChoice = (year: number) => {
    setPickerYear(year);
    setSelector(null);
  };

  const handleMonthChoice = (month: number) => {
    setPickerMonth(month);
    setSelector(null);
  };

  const handleChooseMostRecent = (date: string) => {
    const today = dayjs(date);
    setPickerMonth(today.month());
    setPickerYear(today.year());
    setValidDate(today);
  };

  const handleChooseToday = () => {
    const today = dayjs();
    setPickerMonth(today.month());
    setPickerYear(today.year());
    setValidDate(today);
  };

  return (
    <Box ref={datePickerRef}>
      <Tooltip
        title={getTooltipTitle()}
        classes={{ tooltip: isValid ? classes.tooltip : classes.tooltipError }}
      >
        <div className={classes.mainRoot}>
          {/* <Typography className={classes.title}>{title}</Typography> */}
          <TextField
            value={shownDate}
            onChange={handleInput}
            placeholder={DATE_FORMAT}
            variant="standard"
            classes={{
              root: isValid
                ? classes.textFieldRoot
                : clsx(classes.textFieldRoot, classes.textFieldRootInvalid),
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    type="button"
                    aria-label="calendar"
                    onClick={handleOpen}
                  >
                    <EventIcon />
                  </IconButton>
                </InputAdornment>
              ),
              disableUnderline: true,
              classes: {
                input: classes.input,
                root: clsx([classes.inputRoot, classes.additionalStyles]),
              },
            }}
          />
        </div>
      </Tooltip>
      <Popover
        id={'calendar-popper'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div>
          <div className={classes.datePickerSelector}>
            <div
              className={classes.datePickerSelector__icon}
              onClick={handleBackMonth}
            >
              <KeyboardArrowLeftIcon />
            </div>

            <div
              className={classes.datePickerSelector__month}
              onClick={toggleMonthSelector}
            >
              {dayjs().month(pickerMonth).format('MMMM')}
            </div>
            <div
              className={classes.datePickerSelector__year}
              onClick={toggleYearSelector}
            >
              {dayjs().year(pickerYear).format('YYYY')}
            </div>

            <div
              className={classes.datePickerSelector__icon}
              onClick={handleForwardMonth}
            >
              <KeyboardArrowRightIcon />
            </div>
          </div>
          <div className={classes.popoverContainer}>
            {selector ? (
              <div className={classes.selector}>
                {selector === 'year' ? (
                  <div className={classes.scrollArea}>
                    {availableYears.map((year) => {
                      return (
                        <div
                          key={year}
                          className={clsx(classes.scrollItem, classes.yearItem)}
                          onClick={() => handleYearChoice(year)}
                        >
                          {year}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className={classes.scrollArea}>
                    {availableMonths.map((month) => {
                      return (
                        <div
                          key={month}
                          className={clsx(
                            classes.scrollItem,
                            classes.monthItem
                          )}
                          onClick={() => handleMonthChoice(month)}
                        >
                          {dayjs().set('month', month).format('MMMM')}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ) : (
              <div>
                <DatePickerCalendar
                  datesToInclude={datesToInclude}
                  selectedDate={validDate}
                  pickerMonth={pickerMonth}
                  pickerYear={pickerYear}
                  onChange={handleChoice}
                />
                <div className={classes.actionButtonsContainer}>
                  {datesToInclude && datesToInclude.length ? (
                    <button
                      className={classes.actionButton}
                      onClick={() => handleChooseMostRecent(datesToInclude[0])}
                    >
                      Most Recent
                    </button>
                  ) : (
                    <button
                      className={classes.actionButton}
                      onClick={() => handleChooseToday()}
                    >
                      Today
                    </button>
                  )}
                  <button
                    className={classes.actionButton}
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Popover>
    </Box>
  );
};

export default ReportDatePicker;
