import { FC, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { toggleKidFilter } from '../../../../../../redux/kids/actions';
import { getKidsFilterStateSelector } from '../../../../../../redux/kids/selectors';
import { mainColors } from '../../../../../../styling/theme';
import { TemplateFieldType } from '../../../../../../types/redux/kids/kidsTypes';
import { Theme, Tooltip, Typography } from '@mui/material';

interface StyleProps {
  mancoFilterOn: boolean;
  fundManagerFilterOn: boolean;
  subFundFilterOn: boolean;
  shareClassFilterOn: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toggleIcon: {
    // color: props.isHovered ? mainColors.mainBlue : greys.grey700,
  },
  toggleIconMessage: {
    // transform: props.isHovered ? 'translateY(8rem)' : 'translateY(-8rem)',
  },
  arrow: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    // color: mainColors.controlButtonBlue
  },
  tooltipManco: {
    backgroundColor: mainColors.pastelGreen,
    border: '1px solid #E6E8ED',
    color: 'white',
  },
  filterButtonManco: {
    backgroundColor: mainColors.pastelGreen,
    // backgroundColor: (props) => props.filter['manco'] ? mainColors.mainBlue : mainColors.pastelGreen,
    color: 'white',
    borderRadius: '5px',
    border: (props) =>
      props.mancoFilterOn ? '1px solid #000' : '1px solid #E6E8ED',
    marginRight: '0.5rem',
    marginLeft: '0.5rem',
    // opacity: 0.8,
    '&:hover': {
      // opacity: 1,
      cursor: 'pointer',
      border: '1px solid #000',
      backgroundColor: mainColors.pastelGreen_lighter,
    },
  },
  arrowManco: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: mainColors.pastelGreen,
  },
  tooltipFundManager: {
    backgroundColor: mainColors.pastelBlue,
    border: '1px solid #E6E8ED',
    color: 'white',
  },
  filterButtonFundManager: {
    backgroundColor: mainColors.pastelBlue,
    color: 'white',
    borderRadius: '5px',
    border: (props) =>
      props.fundManagerFilterOn ? '1px solid #000' : '1px solid #E6E8ED',
    marginRight: '0.5rem',
    // opacity: 0.8,
    '&:hover': {
      border: '1px solid #000',
      cursor: 'pointer',
      backgroundColor: mainColors.pastelBlue_lighter,
    },
  },
  arrowFundManager: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: mainColors.pastelBlue,
  },
  tooltipSubFund: {
    backgroundColor: mainColors.pastelYellow,
    border: '1px solid #E6E8ED',
    color: 'white',
  },
  filterButtonSubFund: {
    backgroundColor: mainColors.pastelYellow,
    color: 'white',
    borderRadius: '5px',
    border: (props) =>
      props.subFundFilterOn ? '1px solid #000' : '1px solid #E6E8ED',
    marginRight: '0.5rem',
    // opacity: 0.8,
    '&:hover': {
      border: '1px solid #000',
      cursor: 'pointer',
      backgroundColor: mainColors.pastelYellow_lighter,
    },
  },
  arrowSubFund: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: mainColors.pastelYellow,
  },
  tooltipShareClass: {
    backgroundColor: mainColors.pastelRed,
    border: '1px solid #E6E8ED',
    color: 'white',
  },
  filterButtonShareClass: {
    backgroundColor: mainColors.pastelRed,
    color: 'white',
    borderRadius: '5px',
    border: (props) =>
      props.shareClassFilterOn ? '1px solid #000' : '1px solid #E6E8ED',
    marginRight: '0.5rem',
    // opacity: 0.8,
    '&:hover': {
      border: '1px solid #000',
      cursor: 'pointer',
      backgroundColor: mainColors.pastelRed_lighter,
    },
  },
  arrowShareClass: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: mainColors.pastelRed,
  },
}));

interface backToOverviewButtonProps {
  filterFunction: (value: string) => void;
  filter: string[];
}

const FilterEditorColumnButton: FC<backToOverviewButtonProps> = (props) => {
  const { filter, filterFunction } = props;
  type fieldType = 'manco' | 'fund_manager' | 'sub_fund' | 'share_class';
  type buttonDecoratorType = {
    [key in fieldType]: boolean;
  };

  const dispatch = useDispatch();
  // get values to decide which filters are active
  const kidsFilterState = useSelector(getKidsFilterStateSelector);

  const [mancoFilterOn, setMancoFilterOn] = useState(false);
  const [fundManagerFilterOn, setFundManagerFilterOn] = useState(false);
  const [subFundFilterOn, setSubFundFilterOn] = useState(false);
  const [shareClassFilterOn, setShareClassFilterOn] = useState(false);
  // const [filter, setFilter] = useState({
  //   manco: false,
  //   fund_manager: false,
  //   sub_fund: false,
  //   share_class: false
  // });
  const classes = useStyles({
    mancoFilterOn: mancoFilterOn,
    fundManagerFilterOn: fundManagerFilterOn,
    subFundFilterOn: subFundFilterOn,
    shareClassFilterOn: shareClassFilterOn,
  });

  const updateFilter = (filterUpdate: TemplateFieldType) => {
    filterFunction(filterUpdate);
    if (filterUpdate === TemplateFieldType.MANCO) {
      setMancoFilterOn(!mancoFilterOn);
    } else if (filterUpdate === TemplateFieldType.FUND_MANAGER) {
      setFundManagerFilterOn(!fundManagerFilterOn);
    } else if (filterUpdate === TemplateFieldType.SUB_FUND) {
      setSubFundFilterOn(!subFundFilterOn);
    } else if (filterUpdate === TemplateFieldType.SHARE_CLASS) {
      setShareClassFilterOn(!shareClassFilterOn);
    }
    dispatch(toggleKidFilter(filterUpdate));
  };

  // manco = "manco"
  // fund_manager = "fund_manager"
  // sub_fund = "sub_fund"
  // share_class = "share_class"
  return (
    <div className={classes.filterContainer}>
      <Tooltip
        classes={{ arrow: classes.arrowManco, tooltip: classes.tooltipManco }}
        title="Filter for MANCO fields only."
        placement="top"
        arrow
        enterNextDelay={2000}
        enterDelay={2000}
      >
        <button
          onClick={() => updateFilter(TemplateFieldType.MANCO)}
          className={classes.filterButtonManco}
          // style={filter.includes('manco') ? { border: "1px solid #000" } : {}}
        >
          <Typography
            variant={'h3'}
            style={{ fontWeight: 400, fontSize: '1.5rem', color: 'white' }}
          >
            MANCO
          </Typography>
        </button>
      </Tooltip>
      <Tooltip
        classes={{
          arrow: classes.arrowFundManager,
          tooltip: classes.tooltipFundManager,
        }}
        title="Filter for Fund Manager fields only."
        placement="top"
        arrow
        enterNextDelay={2000}
        enterDelay={2000}
      >
        <button
          onMouseDown={() => updateFilter(TemplateFieldType.FUND_MANAGER)}
          className={classes.filterButtonFundManager}
        >
          <Typography
            variant={'h3'}
            style={{ fontWeight: 400, fontSize: '1.5rem', color: 'white' }}
          >
            Fund Manager
          </Typography>
        </button>
      </Tooltip>
      <Tooltip
        classes={{
          arrow: classes.arrowSubFund,
          tooltip: classes.tooltipSubFund,
        }}
        title="Filter for Sub Fund fields only."
        placement="top"
        arrow
        enterNextDelay={2000}
        enterDelay={2000}
      >
        <button
          onMouseDown={() => updateFilter(TemplateFieldType.SUB_FUND)}
          className={classes.filterButtonSubFund}
        >
          <Typography
            variant={'h3'}
            style={{ fontWeight: 400, fontSize: '1.5rem', color: 'white' }}
          >
            Sub-Fund
          </Typography>
        </button>
      </Tooltip>
      {/* <Tooltip
                classes={{ arrow: classes.arrowShareClass, tooltip: classes.tooltipShareClass }}
                title="Filter for Share Class fields only."
                placement="top"
                arrow
                enterNextDelay={2000}
                enterDelay={2000}
            >
                <button
                    onMouseDown={() => updateFilter(TemplateFieldType.SHARE_CLASS)}
                    className={classes.filterButtonShareClass}>
                    <Typography variant={'h3'} style={{ fontWeight: 400, fontSize: '1.5rem', color: 'white' }}>Share Class</Typography>

                </button>

            </Tooltip> */}
    </div>
  );
};

export default FilterEditorColumnButton;
