import React from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';

function withChartWrapper<T>(
  WrappedComponent: React.ComponentType<T>
): React.FC<T> {
  const wrappedEl = ({ ...props }) => (
    <GridItem
      {...props.dimensions}
      card
      style={{ alignSelf: 'flex-start' }}
      cardStyle={{
        padding: '1rem',
        paddingLeft: '1.5rem',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      id="pdf-target"
    >
      <WrappedComponent {...(props as T)} />
    </GridItem>
  );
  return wrappedEl;
}

export default withChartWrapper;
