import React, { useMemo } from 'react';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import {
  OverviewTableData,
  createMainTableData,
  nursOverviewCsvFields,
  useOverviewColumns,
} from '../overview/data';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { useSelector } from 'react-redux';
import { clientNameSelector } from '../../../../redux/auth/selectors';

interface DeceasedTableProps {
  reportsData: DataObject;
  tableData: DataObject;
}

const DeceasedTable: React.FC<DeceasedTableProps> = (props) => {
  const clientName = useSelector(clientNameSelector);
  const columns: CustomColumn<OverviewTableData>[] =
    useOverviewColumns(clientName);
  const data = useMemo(
    () =>
      createMainTableData(props.tableData.data[0].data, props.reportsData.data),
    [props.tableData, props.reportsData]
  );
  return (
    <GridItem card xs={12}>
      <CustomTable<OverviewTableData>
        csvFields={nursOverviewCsvFields}
        showToolbar
        toolbarComponents={{
          toolbarTitle: 'Deceased Data',
        }}
        columns={columns}
        data={data}
        options={{
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 20, data.length],
          search: true,
          exportButton: true,
          rowStyle: {
            padding: '1rem',
          },
        }}
      />
    </GridItem>
  );
};

export default DeceasedTable;
