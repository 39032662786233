import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Autocomplete, {
  AutocompleteCloseReason,
} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import {
  RaptorTheme,
  greys,
  mainColors,
} from '../../../../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import SortIcon from '@mui/icons-material/Sort';
import Checkbox from '@mui/material/Checkbox';
import { debounce, isEqual } from 'lodash';
import { FilterStateType, SortingStateType } from './presetsData/DataTypes';
import {
  Button,
  FormControlLabel,
  FormGroup,
  Input,
  Popper,
  Typography,
} from '@mui/material';
import { Tune } from '@mui/icons-material';

interface PositionsTableFilterProps {
  columnID: string;
  originalFilterState: FilterStateType;
  filterState: FilterStateType;
  onFilterChange: (columnID: string, newFilters: string[]) => void;
  sortState: SortingStateType;
  onSortChange: () => void;
}

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  buttonBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainButton: {
    fontSize: '1rem',
    textAlign: 'center',
    fontWeight: 500,
    color: greys.grey300,
    '&:hover,&:focus': {
      // backgroundColor: 'transparent',
      // color: mainColors.mainBlue_lighter
    },
    '& svg': {
      width: '1.5rem',
      height: '1.5rem',
      marginRight: '1rem',
    },
  },
  mainButtonNoFilter: {
    backgroundColor: 'transparent',
  },
  mainButtonWithFilters: {
    backgroundColor: mainColors.mainBlue,
    color: 'white',
  },
  mainPopper: {
    border: `1px solid #e1e4e8`,
    boxShadow: '0 8px 24px rgba(149, 157, 165, 0.2)',
    borderRadius: '1rem',
    width: '30rem',
    zIndex: theme.zIndex.modal,
    color: mainColors.mainBlue,
    backgroundColor: 'white',
  },
  mainPopperHeader: {
    borderBottom: '1px solid #eaecef',
    padding: '1.5rem',
    fontWeight: 600,
    fontSize: '1.5rem',
  },
  sortContainer: {
    borderBottom: '1px solid #eaecef',
    padding: '0.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
  },
  sortButton: {
    padding: '0.5rem 1rem 0.5rem 0.5rem',
    fontSize: '1.2rem',
    display: 'flex',
    justifyContent: 'flex-start',
    '& svg': {
      width: '2rem',
      height: '2rem',
      margin: '0.5rem',
    },
  },
  inputBox: {
    borderBottom: '1px solid #eaecef',
    paddingBottom: '1.5rem',
  },
  inputSearch: {
    width: '100%',
    padding: '1.5rem',
    '& input': {
      borderRadius: '0.5rem',
      backgroundColor: '#fff',
      marginBottom: '1.5rem',
      border: `1px solid #eaecef`,
      fontSize: '1.5rem',
      '&:focus': {
        boxShadow: `0px 0px 0px 3px rgba(3, 102, 214, 0.3)`,
        borderColor: '#0366d6',
      },
    },
  },
  formGroup: {
    paddingLeft: '2rem',
  },
  inputChooseAll: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
    },
  },
  label: {
    fontSize: '1.5rem',
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <div {...other}></div>;
}

export default function PositionsTableFilterString(
  props: PositionsTableFilterProps
) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [value, setValue] = useState<string[]>(
    props.filterState[props.columnID]
  );
  const [active, setActive] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSearchValue('');
    setAnchorEl(null);
    if (
      isEqual(
        props.originalFilterState[props.columnID],
        props.filterState[props.columnID]
      )
    ) {
      setValue([]);
    }
  };

  const handleSort = () => {
    props.onSortChange();
  };

  const debounceSendState = debounce(
    async (columnID: string, filterValue: string[]) => {
      props.onFilterChange(columnID, filterValue);
    },
    300
  );

  useEffect(() => {
    if (
      !value.length &&
      !isEqual(
        props.originalFilterState[props.columnID],
        props.filterState[props.columnID]
      )
    ) {
      debounceSendState(
        props.columnID,
        props.originalFilterState[props.columnID]
      );
    } else if (
      value.length &&
      !isEqual(value, props.filterState[props.columnID])
    ) {
      debounceSendState(props.columnID, value);
    }
  }, [value]);

  useEffect(() => {
    if (
      value.length ===
      props.originalFilterState[props.columnID].filter((item) =>
        item.toLowerCase().includes(searchValue.toLowerCase())
      ).length
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [searchValue, value]);

  useEffect(() => {
    if (
      isEqual(
        props.filterState[props.columnID],
        props.originalFilterState[props.columnID]
      ) &&
      props.sortState[props.columnID] === ''
    ) {
      setValue([]);
      setActive(false);
    } else if (
      isEqual(
        props.filterState[props.columnID],
        props.originalFilterState[props.columnID]
      )
    ) {
      setValue([]);
      setActive(true);
    } else {
      setValue(props.filterState[props.columnID]);
      setActive(true);
    }
  }, [props.sortState[props.columnID], props.filterState[props.columnID]]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      if (searchValue.length) {
        setValue(
          props.originalFilterState[props.columnID].filter((item) =>
            item.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
      } else {
        setValue(props.originalFilterState[props.columnID]);
      }
    } else {
      setValue([]);
    }
  };

  const handleSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    if (
      isEqual(
        props.filterState[props.columnID],
        props.originalFilterState[props.columnID]
      )
    ) {
      setValue([]);
    } else {
      setValue(props.filterState[props.columnID]);
    }
  }, [props.filterState[props.columnID]]);

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popper' : undefined;

  return (
    <React.Fragment>
      <Box className={classes.buttonBox}>
        {active ? (
          <Button
            onClick={handleClick}
            disableRipple
            aria-describedby={id}
            className={clsx(classes.mainButton, classes.mainButtonWithFilters)}
          >
            <Tune />
            <span>Filter</span>
          </Button>
        ) : (
          <Button
            onClick={handleClick}
            disableRipple
            aria-describedby={id}
            className={clsx(classes.mainButton, classes.mainButtonNoFilters)}
          >
            <Tune />
            <span>Filter</span>
          </Button>
        )}
      </Box>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={classes.mainPopper}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box className={classes.mainPopperHeader}>
              Choose Column Filters
            </Box>
            <Box className={classes.sortContainer}>
              <Button
                disableRipple
                onClick={handleSort}
                className={classes.sortButton}
              >
                <SortIcon />
                <span>{props.sortState[props.columnID]}</span>
              </Button>
            </Box>
            <Autocomplete
              open
              multiple
              onClose={(
                event: React.ChangeEvent<{}>,
                reason: AutocompleteCloseReason
              ) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              value={value}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                setValue(newValue);
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText="No Options"
              renderOption={(props, item, { selected }) => (
                <li {...props}>
                  <Box
                    component={DoneIcon}
                    sx={{ width: 17, height: 17, mr: '5px', ml: '-2px' }}
                    style={{
                      visibility: selected ? 'visible' : 'hidden',
                    }}
                  />
                  <Box
                    component="span"
                    sx={{
                      width: 14,
                      height: 14,
                      flexShrink: 0,
                      borderRadius: '3px',
                      mr: 1,
                      mt: '2px',
                    }}
                    // style={{ backgroundColor: option.color }}
                  />
                  <Box
                    sx={{
                      flexGrow: 1,
                      '& span': {
                        color:
                          theme.palette.mode === 'light'
                            ? '#586069'
                            : '#8b949e',
                      },
                    }}
                  >
                    {item}
                  </Box>
                  <Box
                    component={CloseIcon}
                    sx={{ opacity: 0.6, width: 18, height: 18 }}
                    style={{
                      visibility: selected ? 'visible' : 'hidden',
                    }}
                  />
                </li>
              )}
              options={[
                ...props.originalFilterState[props.columnID].map((el) => el),
              ]}
              // options={[...props.items].sort((a, b) => {
              //   // Display the selected labels first.
              //   let ai = value.indexOf(a);
              //   ai = ai === -1 ? value.length + labels.indexOf(a) : ai;
              //   let bi = value.indexOf(b);
              //   bi = bi === -1 ? value.length + labels.indexOf(b) : bi;
              //   return ai - bi;
              // })}
              getOptionLabel={(option) => option}
              inputValue={searchValue}
              renderInput={(params) => (
                <Box className={classes.inputBox}>
                  <Input
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    autoFocus
                    disableUnderline
                    className={classes.inputSearch}
                    onChange={handleSearchValue}
                    placeholder="Search..."
                  />
                  <FormGroup className={classes.formGroup}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.inputChooseAll}
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      }
                      label={
                        <Typography className={classes.label}>
                          Select All
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Box>
              )}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>
  );
}
