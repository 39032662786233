import { scaleLinear } from 'd3-scale';
import { FC, ReactElement } from 'react';
import { mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import { flexify } from '../../../../utilities/cssMixins';
import { percentageToTwoDecimalPlaces } from '../../../../utilities/numberFormatters';
import CustomTable from '../../../tables/CustomTable';
import GridItem from '../../../layout/GridComponents/GridItem';
import { Typography } from '@mui/material';

export interface FundCorrelationMatrixProps {
  dataForRender: DataObject;
  position: string | null;
}

interface CorrelationMatrixData {
  label: string;

  ebit: number;
  ebitGrowth: number;
  wacc: number;
  borrowRate: number;
}

function buildFundCorrelationMatrixData(
  data: any,
  position: string | null
): CorrelationMatrixData[] {
  if (!data) return [];
  if (!data.length) return [];
  if (!position) return [];
  if (!(position in data[0])) return [];
  const correlationMatrixData =
    data[0][position].value_table['Correlation Matrix'];
  const tableData: CorrelationMatrixData[] = [];

  correlationMatrixData.forEach((value: any, index: number) => {
    tableData.push({
      label: value.label,
      ebit: value['EBIT'],
      ebitGrowth: value['EBIT Growth'],
      wacc: value['WACC'],
      borrowRate: value['Borrow Rate'],
    });
  });

  return tableData;
}

// function getIndexNames(data: any) {
//   if (!data) return [];
//   if (!data.length) return [];
//   if (!('names' in data[0])) return [];

//   return data[0].names;
// }

interface FundCorrelationMatrixCellProps {
  value: string;
  color: string;
}

function getGradinetColor(value: number) {
  const gradientFirstStep = scaleLinear()
    .domain([-1, -0.67])
    .range([
      mainColors.mainBlue_lighter as unknown as number,
      mainColors.jonquil as unknown as number,
    ]);
  const gradientSecondStep = scaleLinear()
    .domain([-0.67, -0.33])
    .range([
      mainColors.jonquil as unknown as number,
      mainColors.tertiaryGreen as unknown as number,
    ]);
  const gradientThirdStep = scaleLinear()
    .domain([-0.33, 0])
    .range([
      mainColors.tertiaryGreen as unknown as number,
      mainColors.Pass as unknown as number,
    ]);
  const gradientFourthStep = scaleLinear()
    .domain([0, 0.33])
    .range([
      mainColors.Pass as unknown as number,
      mainColors.Alert as unknown as number,
    ]);
  const gradientFifthStep = scaleLinear()
    .domain([0.33, 0.67])
    .range([
      mainColors.Pass as unknown as number,
      mainColors.Alert as unknown as number,
    ]);
  const gradientSixthStep = scaleLinear()
    .domain([0.67, 1])
    .range([
      mainColors.Alert as unknown as number,
      mainColors.Fail as unknown as number,
    ]);

  if (value <= -0.67) {
    return gradientFirstStep(value);
  } else if (value <= -0.33) {
    return gradientSecondStep(value);
  } else if (value <= 0) {
    return gradientThirdStep(value);
  } else if (value <= 0.33) {
    return gradientFourthStep(value);
  } else if (value <= 0.67) {
    return gradientFifthStep(value);
  } else {
    return gradientSixthStep(value);
  }
  // } else if (absValue <= 0.75) {
  //     return gradientFourthStep(absValue);
  // } else {
  //     return gradientFifthStep(absValue);
  // }
}

function FundCorrelationMatrixCell(
  props: FundCorrelationMatrixCellProps
): ReactElement {
  const { value, color } = props;

  // const backgroundColor = getGradinetColor(value);

  return (
    <div
      style={{
        height: '8rem',
        // backgroundColor: getGradinetColor(value, minValue, maxValue, nav, percentValueOption) as unknown as string,
        backgroundColor: color,
        borderColor: 'white',
        borderStyle: 'solid',
        borderWidth: '0.5px',
        ...flexify('center', 'center'),
      }}
    >
      <Typography
        variant="subtitle1"
        style={{ color: 'white', fontSize: 16, fontWeight: 600 }}
      >
        {value}
      </Typography>
    </div>
    //   </Wrapper>
  );
}

// function getCorrelationMatrixColumns(
//   indexNames: string[],
// //   fundId: string
// ): CustomColumn<CorrelationMatrixData>[] {
const columns: CustomColumn<CorrelationMatrixData>[] = [
  {
    // title: 'Ebit Growth / EBIT',
    title: '',
    field: 'label',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'EBIT',
    field: 'ebit',
    pdfRenderType: 'HeatMapCell',
    render: (rowData: CorrelationMatrixData) => (
      <FundCorrelationMatrixCell
        value={percentageToTwoDecimalPlaces(rowData.ebit)}
        color={getGradinetColor(rowData.ebit) as unknown as string}
      />
    ),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
      padding: 0,
    },
  },
  {
    title: 'EBIT Growth',
    field: 'ebitGrowth',
    pdfRenderType: 'HeatMapCell',
    render: (rowData: CorrelationMatrixData) => (
      <FundCorrelationMatrixCell
        value={percentageToTwoDecimalPlaces(rowData.ebitGrowth)}
        color={getGradinetColor(rowData.ebitGrowth) as unknown as string}
      />
    ),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
      padding: 0,
    },
  },
  {
    title: 'WACC',
    field: 'wacc',
    pdfRenderType: 'HeatMapCell',
    render: (rowData: CorrelationMatrixData) => (
      <FundCorrelationMatrixCell
        value={percentageToTwoDecimalPlaces(rowData.wacc)}
        color={getGradinetColor(rowData.wacc) as unknown as string}
      />
    ),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
      padding: 0,
    },
  },
  {
    title: 'Borrow Rate',
    field: 'borrowRate',
    pdfRenderType: 'HeatMapCell',
    render: (rowData: CorrelationMatrixData) => (
      <FundCorrelationMatrixCell
        value={percentageToTwoDecimalPlaces(rowData.borrowRate)}
        color={getGradinetColor(rowData.borrowRate) as unknown as string}
      />
    ),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
      padding: 0,
    },
  },
];
// indexNames.forEach((value: any, index: number) => {
//     columns.push({
//         title: value,
//         field: value,
//         pdfRenderType: 'PageLinkButton',
//         render: (rowData: CorrelationMatrixData) => <FundCorrelationMatrixCell value={rowData[indexNumber]} color={getGradinetColor(rowData.indexValues[index]) as unknown as string} />,
//         headerStyle: {
//             textAlign: 'center',
//         },
//         cellStyle: {
//             textAlign: 'center',
//             padding: 0,
//         },
//     })
// })
//   return columns;
// }

const MonteCarloCorrelationMatrix: FC<FundCorrelationMatrixProps> = (props) => {
  const { data } = props.dataForRender;
  const position = props.position;
  const tableData = buildFundCorrelationMatrixData(data, position);
  //   const indexNames = getIndexNames(data);
  //   const columns = getCorrelationMatrixColumns(indexNames);
  // const { width, height } = useWindowSize();
  return (
    <GridItem
      xs={4}
      card
      style={{
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
        // minHeight: 500
      }}
      cardStyle={{ minHeight: 450 }}
    >
      <CustomTable<CorrelationMatrixData>
        pdfTitle="DCF Correlation Matrix"
        title="DCF Correlation Matrix"
        columns={columns}
        id={'dcf_correlation_matrix'}
        pdfColumns={columns}
        showToolbar
        data={tableData}
        multipleTablePdfParameters={{
          specifiedWidth: 6,
          tablesInRow: 1,
        }}
        // toolbarComponents={{
        //     toolbarTitle: formattedNames[tableType],
        // }}
        options={{
          paging: false,
          search: false,
          exportButton: false,
          rowStyle: {
            marginLeft: 0,
          },
          sorting: false,
          draggable: false,
          toolbar: true,
        }}
        // pdfSpecificYPosition={100}
        // pdfSpecificXPosition={5}
      />
    </GridItem>
  );
};

export default MonteCarloCorrelationMatrix;
