import { createSelector } from 'reselect';
import { AppState } from '../../types/redux/store';

export const getKidsState = (state: AppState) => state.kids;

export const kidsStateSelector = createSelector(
  getKidsState,
  (kidsState) => kidsState
);

export const getKidsFilterStateSelector = createSelector(
  kidsStateSelector,
  (kidsState) => {
    return kidsState.kidsFilterState;
  }
);

export const getCanUserNavigateAwaySelector = createSelector(
  kidsStateSelector,
  (kidsState) => {
    return kidsState.canUserNavigateAway;
  }
);
