import React, { ReactElement } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';

import {
  CssfTableData,
  generateCssfColumns,
  generateCssfData,
} from './CssfRiskSpreading.data';
import CustomTable from '../../../tables/CustomTable';
import GridItem from '../../../layout/GridComponents/GridItem';
import { Fund } from '../../../../types/server-data/ClientConfig';
import CssfDetailComponent from './detailPanel/CssfDetail.component';

interface TableProps {
  data: CssfTableData[] | Error;
  fundDetails: Fund;
  positionDate?: string;
}
const CssfTable = ({
  data,
  fundDetails,
  positionDate,
}: TableProps): ReactElement => {
  
  const columns = generateCssfColumns();

  return (
    <GridItem xs={12} card>
      <CustomTable<CssfTableData>
        options={{
          paging: false,
          search: true,
          exportButton: true,
          raptorExport: true,
        }}
        id={'cssf-risk-spreading-table'}
        columns={columns}
        data={data}
        showToolbar={true}
        title={fundDetails.name}
        detailPanel={(rowData: CssfTableData) => (
          <CssfDetailComponent
            ruleTitle={rowData.ruleTitle}
            fundDetails={fundDetails}
            positionDate={positionDate}
            ruleNumber={rowData.number}
            isCounterparty={rowData.ruleTitle
              .toLowerCase()
              .includes('counterparty')}
          />
        )}
      />
    </GridItem>
  );
};

function CssfRiskSpreading(props: FundInfoComponentProps): ReactElement {

  const { fundId, positionDate } = props;

  const riskSpreadingData = useFetchData({
    url: `stored_cssf_risk_spreading_monitor/${fundId}/${positionDate ? `${positionDate}` : ''}`,
    makeFetch: true,
    keyName: `riskSpreadData_${fundId}_${
      positionDate ? positionDate : formatDateForCheckingState(new Date())
    }`,
  });

  const fundDetails = {
    name: props.fundName,
    id: props.fundId,
  };
  const data = generateCssfData(riskSpreadingData);
  return (
    <GeneralComponentErrorShield dataObjects={[riskSpreadingData]}>
      <CssfTable
        data={data}
        fundDetails={fundDetails}
        positionDate={riskSpreadingData?.data[0]?.returned_position_date || positionDate}
      />
    </GeneralComponentErrorShield>
  );
}
export default CssfRiskSpreading;
