import { mapStatusToColor } from '../../../../utilities/colorUtilities';
import { Status } from '../../../../types/redux/data/dataTypes';
import { useTheme } from '@mui/material';
export type RstStatus = {
  status: string;
  message: string;
};

type RstCellProps = {
  current: RstStatus;
  forecast: RstStatus;
  scenario: RstStatus;
  stress: RstStatus;
};

const RstCell = (props: RstCellProps) => {
  const theme = useTheme();
  return (
    <>
      {Object.keys(props).map((key) => {
        const messages = props[key as keyof RstCellProps].message
          .split('<br>')
          .filter((val) => val !== '');

        return (
          <div key={key}>
            <h3
              style={{ color: theme.palette.primary.main, textAlign: 'center' }}
            >
              {key.replace(/\b\w/g, (c) => c.toUpperCase())}
            </h3>
            <div>
              {messages.length > 0 ? (
                messages.map((message: string) => (
                  <p
                    key={message}
                    style={{
                      color: mapStatusToColor(
                        message.toLowerCase().includes('fail')
                          ? Status.Fail
                          : Status.Alert
                      ),
                      textAlign: 'center',
                    }}
                  >
                    {message}
                  </p>
                ))
              ) : (
                <p
                  style={{
                    color: mapStatusToColor(Status.Pass),
                    textAlign: 'center',
                  }}
                >
                  Pass
                </p>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default RstCell;
