import { BondDataServer } from '../table/table.data';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  percentageToTwoDecimalPlacesNoHundred,
  toTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';

export interface IGeneralExposureData {
  exposure: number;
  dv01: number;
  name: string;
}

export interface CountryExposureData {
  countryCode: string;
  exposure: number;
}

export enum FixedIncomeExposureOptionType {
  CREDIT = 'credit',
  MATURITY = 'maturity',
  SECTOR = 'sector',
  GROSS_COUNTRY_EXPOSURE = 'grossCountryExposure',
  NET_COUNTRY_EXPOSURE = 'netCountryExposure',
}
export const generateFixedIncomeMapColumns =
  (): CustomColumn<CountryExposureData>[] => {
    return [
      {
        title: 'Country Code',
        field: 'countryCode',
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
      },
      {
        title: 'Exposure',
        field: 'exposure',
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
      },
    ];
  };
export const generateExposureColumns = (
  exposureType: FixedIncomeExposureOptionType
): CustomColumn<IGeneralExposureData>[] => {
  return [
    {
      title: ((innerExposureType: FixedIncomeExposureOptionType) => {
        switch (innerExposureType) {
          case FixedIncomeExposureOptionType.CREDIT:
            return 'Rating';
          case FixedIncomeExposureOptionType.MATURITY:
            return 'Maturity';
          case FixedIncomeExposureOptionType.SECTOR:
            return 'Sector';
          default:
            return '';
        }
      })(exposureType),
      field: 'name',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Exposure',
      field: 'exposure',
      render: (rowData) =>
        percentageToTwoDecimalPlacesNoHundred(rowData.exposure),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'DV01',
      field: 'dv01',
      render: (rowData) => toTwoDecimalPlaces(rowData.dv01),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
  ];
};

const exposureDataKeyMap = {
  maturity: 'maturity_data',
  credit: 'credit_data',
  sector: 'sector_data',
  grossCountryExposure: 'regional_exposure',
  netCountryExposure: 'regional_exposure',
};
export function generateExposureGraphicsData(
  inputData: BondDataServer[],
  graphicsType: FixedIncomeExposureOptionType
) {
  if (!inputData.length) return [];
  let dataForUse: any[][];

  try {
    dataForUse = [...inputData[0][exposureDataKeyMap[graphicsType]]];
  } catch (err) {
    if (graphicsType === 'maturity') {
      try {
        dataForUse = [...inputData[0]['dv01_maturity_data']];
      } catch (error) {
        dataForUse = [];
      }
    } else {
      dataForUse = [];
    }
  }

  if (['credit', 'sector', 'maturity'].includes(graphicsType)) {
    dataForUse.shift();
  }
  return !['grossCountryExposure', 'netCountryExposure'].includes(graphicsType)
    ? dataForUse.map((el: any) => {
        return {
          name: el[0],
          exposure: el[1],
          dv01: el[2],
        };
      })
    : dataForUse.map((el: any) => {
        return {
          countryCode: el[0],
          exposure:
            graphicsType === 'grossCountryExposure' ? Math.abs(el[1]) : el[1],
        };
      });
}
