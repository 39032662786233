import React, { FC } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
  roundNumberToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import { specifyStatus } from '../../../../utilities/specfyStatus';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { PEPageLinkButton } from '../../privateEquity/peOverview/PeOverview.component';
import PrincipleAdverseImpactSecondLevelTableWrapper from './PrincipleAdverseImpactSecondLevelTableWrapper.component';
import { DataObject } from '../../../../types/redux/data/dataTypes';

interface PrincipleAdverseImpactTableProps {
  esgData: DataObject;
  rulesData: DataObject;
}

export interface PrincipleAdverseImpactTableData {
  name: string;
  qualitativeIndicatorScan: string;
  metricIndicatorScan: string;
  concerningExposure: number;
  concerningExposureValue: number;
  codes: any;
  positions: any[];
  ruleNumber: number;
  topic: string;
}

export function buildPrincipleAdverseImpactTableData(
  data: any
): PrincipleAdverseImpactTableData[] {
  if (!data.data.length) return [];
  if (!('pai_page' in data.data[0])) return [];
  const paiData = data.data[0].pai_page;
  const tableData: PrincipleAdverseImpactTableData[] = [];
  paiData.forEach((value: any) => {
    const parsedNumber = value.name.match(/\d+/);
    const ruleNumber = parseInt(parsedNumber);

    tableData.push({
      name: value.name,
      qualitativeIndicatorScan: value.qualitative,
      metricIndicatorScan: value.metric,
      positions: value.concerning_positions,
      ruleNumber: ruleNumber,
      concerningExposure: value.concerning_exposure,
      concerningExposureValue: value.concerning_exposure_value,
      topic: value.topic,
      codes: value.codes,
    });
  });
  const sortedData = tableData.sort((a, b) =>
    a.ruleNumber > b.ruleNumber ? 1 : -1
  );

  return sortedData;
}

const detailColumns: CustomColumn<PrincipleAdverseImpactTableData>[] = [
  {
    title: 'Name',
    field: 'name',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Topic',
    field: 'topic',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'In Scope Exposure',
    field: 'concerningExposure',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: PrincipleAdverseImpactTableData) =>
      percentageToTwoDecimalPlaces(rowData.concerningExposure),
  },
  {
    title: 'In Scope Exposure Value',
    field: 'concerningExposureValue',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: PrincipleAdverseImpactTableData) =>
      addCommasToNumbersAndRound(rowData.concerningExposureValue),
  },
  {
    title: 'Qualitative Indicator Scan',
    field: 'qualitativeIndicatorScan',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: PrincipleAdverseImpactTableData) => (
      <PEPageLinkButton
        status={specifyStatus(rowData.qualitativeIndicatorScan)}
      />
    ),
  },
  {
    title: 'Metric Indicator Scan',
    field: 'metricIndicatorScan',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: PrincipleAdverseImpactTableData) =>
      roundNumberToTwoDecimalPlaces(rowData.metricIndicatorScan),
  },
];

const PrincipleAdverseImpactTable: FC<PrincipleAdverseImpactTableProps> = ({
  esgData,
  rulesData,
}) => {
  const tableData = buildPrincipleAdverseImpactTableData(esgData);

  return (
    <GridItem card xs={12}>
      <CustomTable<PrincipleAdverseImpactTableData>
        columns={detailColumns}
        showToolbar={false}
        data={tableData}
        title={'Principal Adverse Impact'}
        options={{
          paging: false,
          search: false,
          exportButton: false,
        }}
        detailPanel={PrincipleAdverseImpactSecondLevelTableWrapper(
          esgData.data.length ? esgData.data[0].positions : [],
          esgData.data.length ? esgData.data[0].nav : 0,
          rulesData.data.length ? rulesData.data[0] : {}
        )}
      />
    </GridItem>
  );
};

export default PrincipleAdverseImpactTable;
