import { Status } from '../../../../../types/redux/data/dataTypes';
import { AifmdReportType } from '../../overview/table/components/ReportsLinkButton/reportsLinkButton.component';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { mainColors } from '../../../../../styling/theme';
import { toFourDecimalPlacesIfNumber } from '../../../../../utilities/numberFormatters';
import { mapStatusToColor } from '../../../../../utilities/colorUtilities';
import { specifyStatus } from '../../../../../utilities/specfyStatus';
import { Typography } from '@mui/material';

export interface AifReportsData extends Record<string, unknown> {
  id: number;
  riskCategory: string;
  description: string;
  limitValue: number;
  currentValue: number;
  status: Status;
}

export interface AifFundServerData {
  aifm_limit: any;
  risk_monitor: {
    Counterparty: any;
    Credit: any;
    ['Funding Liquidity']: any;
    ['Market Liquidity']: any;
    Market: {
      [key: string]: {
        Status: Status;
        ['Risk Colour']: number;
        ['Metric Value']: number;
        ['Risk Metric']: string;
        Detail: string;
      };
    };
  };
}

export const generateReportsColumns = (
  reportType: AifmdReportType
): CustomColumn<AifReportsData>[] => {
  const cols: CustomColumn<AifReportsData>[] = [
    {
      title: 'I/D',
      field: 'id',
      width: 1,
    },
  ];

  if (reportType === 'liquidity') {
    cols.push({
      title: 'Financial Risk',
      field: 'financialRisk',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
        color: mainColors.mainBlue,
        fontWeight: 'bold',
      },
    });
  }

  cols.push(
    {
      title: 'Risk Category',
      field: 'riskCategory',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Description',
      field: 'description',
    },
    {
      title: 'Limit Value',
      field: 'limitValue',
      render: (rowData: AifReportsData) =>
        rowData.limitValue !== undefined
          ? toFourDecimalPlacesIfNumber(rowData.limitValue)
          : null,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Current Value',
      field: 'currentValue',
      render: (rowData: AifReportsData) =>
        rowData.currentValue !== undefined
          ? toFourDecimalPlacesIfNumber(rowData.currentValue)
          : null,

      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Status',
      field: 'status',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },

      render: (rowData: AifReportsData) => {
        if (rowData.status) {
          return (
            <div
              style={{
                padding: '.8rem',
                width: '11rem',
                borderRadius: 8,
                textAlign: 'center',
                margin: '0 auto',
                border: `1px solid ${mapStatusToColor(
                  specifyStatus(rowData.status)
                )}`,
              }}
            >
              <Typography
                variant="h3"
                style={{
                  transform: 'translateY(1px)',
                  color: mapStatusToColor(specifyStatus(rowData.status)),
                  fontSize: '1.8rem',
                }}
              >
                Status: {rowData.status}
              </Typography>
            </div>
          );
        }
        return null;
      },
    }
  );

  return cols;
};

const reportKeyMap: {
  [K in AifmdReportType]: 'Market' | 'Counterparty' | 'Credit' | 'Liquidity';
} = {
  market: 'Market',
  counterparty: 'Counterparty',
  credit: 'Credit',
  liquidity: 'Liquidity',
};

function aifmdDataHelper(
  data: any,
  limitData: any,
  returnData: any[],
  nameKey: string,
  counter: number
) {
  const categories = Object.values(data);
  const categoryTitles = Object.keys(data);

  if (nameKey === 'Funding Liquidity' || nameKey === 'Market Liquidity') {
    returnData.push({
      financialRisk: nameKey,
    });
  }
  categories.forEach((category: any, catIndex: number) => {
    const currentCategoryTitle = categoryTitles[catIndex];

    Object.keys(category).forEach((key: string) => {
      if (
        typeof category[key] !== 'string' &&
        typeof category[key] !== 'number'
      ) {
        returnData.push({
          id: counter,
          riskCategory: currentCategoryTitle,
          description: category[key]['Risk Metric'],
          limitValue: limitData[currentCategoryTitle][key].Limit[0],
          currentValue: category[key]['Metric Value'],
          status: category[key].Status,
        });
      }
    });
  });
}

export function generateAifmdData(
  inputData: AifFundServerData[],
  reportType: AifmdReportType
) {
  if (!inputData.length) return [];

  try {
    let data: any;
    let limitData: any;
    const returnData: AifReportsData[] = [];
    const key = reportKeyMap[reportType];

    // NOTE: when we deal with liquidity, we need values from both funding liquidity and market liquidity
    let counter: number;
    if (key !== 'Liquidity') {
      counter = 1;
      data = inputData[0].risk_monitor[key];
      limitData = inputData[0].aifm_limit[key];
      aifmdDataHelper(data, limitData, returnData, key, counter);
    } else {
      const keys: ['Funding Liquidity', 'Market Liquidity'] = [
        'Funding Liquidity',
        'Market Liquidity',
      ];
      counter = 1;
      keys.forEach((el: 'Funding Liquidity' | 'Market Liquidity') => {
        data = inputData[0].risk_monitor[el];
        limitData = inputData[0].aifm_limit[el];
        aifmdDataHelper(data, limitData, returnData, el, counter);
        counter += 1;
      });
    }

    return returnData;
  } catch (err) {
    console.error('error generating AIFMD data', err);
    return [];
  }
}
