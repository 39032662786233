import React, { ReactElement } from 'react';
import {
  ProspectusRestrictionsSecondLevelColumns,
  ProspectusRestrictionsSecondLevelTableData,
  buildSecondaryData,
} from './second-level-table.data';
import { DataObject } from '../../../../../../types/redux/data/dataTypes';
import { IProspectusRestrictionsFirstLevelData } from '../FirstLevelTable';
import CustomTable from '../../../../../tables/CustomTable';
import { flexify } from '../../../../../../utilities/cssMixins';
import { Fund } from '../../../../../../types/server-data/ClientConfig';
import { Typography, useTheme } from '@mui/material';

interface ProspectusRestrictionsSecondLevelTableProps {
  rowData: IProspectusRestrictionsFirstLevelData;
  prospectusRestrictionsData: DataObject;
  selectedPositionDate: string;
  fundDetails: Fund;
}

function SecondLevelTable(
  props: ProspectusRestrictionsSecondLevelTableProps
): ReactElement {
  const secondLevelTableData = buildSecondaryData(
    props.rowData,
    props.prospectusRestrictionsData.data[0].positions,
    props.fundDetails.id
  );
  const theme = useTheme();
  const tableColumns = ProspectusRestrictionsSecondLevelColumns.map((col) => ({
    ...col,
    cellStyle: {
      ...col.cellStyle,
      backgroundColor: 'white',
    },
  }));
  if (
    props.rowData.name === 'Single Industry Exposure (Maximum)' &&
    props.fundDetails.id.includes('bflexion')
  ) {
    tableColumns.push({
      title: 'Client Industry',
      field: 'clientIndustry',
      width: 1,
      cellStyle: {
        backgroundColor: 'white',
        borderBottom: `1px solid #446699`,
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        borderBottom: `1px solid #446699`,
        backgroundColor: 'rgb(232, 232, 232)',
      },
    });
  }
  return secondLevelTableData.length > 0 ? (
    <CustomTable<ProspectusRestrictionsSecondLevelTableData>
      noteExport={{
        fundId: props.fundDetails.id,
        subject: 'ucits_prospectus-restrictions',
        topic: `rule_${props.rowData.number}`,
        positionDate: props.selectedPositionDate,
        shouldCall: false,
      }}
      data={secondLevelTableData}
      options={{
        paging: false,
        search: true,
        exportButton: true,
      }}
      toolbarComponents={{
        toolbarTitle: props.rowData.name,
      }}
      columns={tableColumns}
      csvFields={Object.keys(secondLevelTableData[0] || [])}
      showToolbar={true}
      loading={false}
    />
  ) : (
    <div style={{ padding: '2rem', ...flexify() }}>
      <Typography variant="h3">No in-scope data</Typography>
    </div>
  );
}

export default SecondLevelTable;
