import { CurrencyServerData } from '../table/currencyTable.data';

interface CurrencyChartData {
  name: string;
  cash: number;
  forex: number;
  asset: number; // currency name
  unrealisedPl: number;
  total: number;
}

function generateCurrencyChartData(
  inputData: CurrencyServerData[]
): CurrencyChartData[] {
  if (!inputData.length) return [];
  try {
    const dataForUse = [...inputData[0].currency_exposure];
    dataForUse.shift();

    const forReturn = dataForUse.map((el) => {
      const cashVal = el[1] as number;
      const forexVal = el[2] as number;
      const assetVal = el[3] as number;
      const unrealisedPlVal = el[4] as number;

      return {
        name: el[0] as string,
        cash: cashVal,
        forex: forexVal,
        asset: assetVal,
        unrealisedPl: unrealisedPlVal,
        total: cashVal + forexVal + assetVal + unrealisedPlVal,
      };
    });

    return forReturn;
  } catch (error) {
    console.error('Error generating chart data', error);
    return [];
  }
}
export default generateCurrencyChartData;
