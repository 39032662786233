import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import YieldCurve from './subComponents/yieldCurve';
import YieldTable from './subComponents/yieldTable';
import useTitleBarSelect from '../../../../../hooks/useTitleBarSelect';
import { Select } from '../../../../../types/redux/pages/PagesStore';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../../../redux/pages/actions';
import {
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../../../../../types/redux/pages/pageTypes';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
});

const YieldMain: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const curve_selected = useTitleBarSelect('Curve') || '1';
  const currency_selected = useTitleBarSelect('Currency') || '1';

  useEffect(() => {
    const selectsToAdd_curve: Select[] = [
      {
        titleBarTitle: 'Curve:',
        titleBarKey: 'Curve',
        values: [
          {
            label: 'Govt',
            value: '1',
          },
          {
            label: 'Swap',
            value: '2',
          },
        ],
        displayOnOverviewPage: true,
        aValueIsAlwaysSelected: true,
        currentValue: '1',
      },
    ];
    const selectsToAdd_currency: Select[] = [
      {
        titleBarTitle: 'Currency:',
        titleBarKey: 'Currency',
        values: [
          {
            label: 'EUR',
            value: '1',
          },
          {
            label: 'GBP',
            value: '2',
          },
          {
            label: 'USD',
            value: '3',
          },
          {
            label: 'CHF',
            value: '4',
          },
          {
            label: 'JPY',
            value: '5',
          },
          {
            label: 'CNY',
            value: '6',
          },
        ],
        displayOnOverviewPage: true,
        aValueIsAlwaysSelected: true,
        currentValue: '1',
      },
    ];
    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd_curve,
        APPEND_NEW_SELECT_TO_TITLEBAR
      )
    );
    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd_currency,
        APPEND_NEW_SELECT_TO_TITLEBAR
      )
    );

    return () => {
      dispatch(removeTitleBarComponent('Curve', REMOVE_SELECT_FROM_TITLEBAR));
      dispatch(
        removeTitleBarComponent('Currency', REMOVE_SELECT_FROM_TITLEBAR)
      );
    };
  }, []);

  return (
    <div className={classes.container}>
      <YieldTable />
      <YieldCurve />
    </div>
  );
};

export default YieldMain;
