import { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme } from '../../../../../styling/theme';
import MapDetail from './map';
import DetailBarChart from './barChart';
import DetailTimeSeries from './timeSeries';
import { Grid } from '@mui/material';

interface Props {
  varFund: boolean;
  mapData: any;
  passValue: string;
  val: number;
  barChartInputData: any;
  timeSeriesData: any;
  fundId: string;
}

export const useOverviewDetailStyles = makeStyles((theme: RaptorTheme) => ({
  chartContainerDiv: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  map: {
    textAlign: 'left',
    margin: '1.5rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  calendar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: '1.5rem',
    height: '25rem',
    width: '100%',
  },
  chart: {
    // NOTE this could be better - DRY
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: '1.5rem',
  },
  outerDiv: {
    textAlign: 'center',
    // height: "40rem",
    width: '80%',
    maxWidth: '60rem',
    height: '80%',
  },
  container: {
    position: 'relative',
    // height: "50rem",
    padding: '2rem 0',
    paddingBottom: 0,
  },
  mapTitle: {
    marginTop: 0,
    marginBottom: '3rem',
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  lineChart: {
    transform: 'translateX(2.5rem)',
    '& g:first-child': {
      backgroundColor: 'red',
    },
  },
  colorChartBox: {
    height: 100,
    width: 100,
    position: 'absolute',
    top: 10,
    left: 10,
    backgroundColor: 'red',
  },
  header: {
    width: '100%',
    borderBottom: '1px solid gray',
    fontSize: '18px',
    textAlign: 'center',
    padding: '5px',
  },
  content: {
    width: '100rem',
    padding: '10px 5px',
    background: 'orange',
  },
  actions: {
    width: '100%',
    padding: '10px 5px',
    margin: 'auto',
    textAlign: 'center',
  },
  close: {
    cursor: 'pointer',
    position: 'absolute',
    display: 'block',
    padding: '2px 5px',
    lineHeight: '20px',
    right: '-10px',
    top: '-10px',
    fontSize: '24px',
    background: '#ffffff',
    borderRadius: '18px',
    border: '1x solid #cfcece',
  },
}));

function Detail(props: Props): ReactElement {
  const classes = useOverviewDetailStyles();

  return (
    <div>
      <Grid container className={classes.container} justifyContent="flex-start">
        <MapDetail
          timeSeriesData={props.timeSeriesData}
          data={props.mapData}
          val={props.val}
        />
        <DetailBarChart
          barChartData={props.barChartInputData}
          timeSeriesData={props.timeSeriesData}
          varFund={props.varFund}
        />
        {props.timeSeriesData.length ? (
          <DetailTimeSeries
            timeSeriesData={props.timeSeriesData}
            varFund={props.varFund}
            passValue={props.passValue}
          />
        ) : null}
      </Grid>
    </div>
  );
}

export default Detail;
