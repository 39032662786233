import React, { FC } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { LiquidityUrls } from '../fundAssetLiquidationTime/component';
import {
  formatFundUrl,
  makeUniqueDataKey,
} from '../../../../utilities/urlBuilder';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import TimeCostTable from './sharedComponents/table/table.component';
import LiquidationTimeCostChart from './sharedComponents/chart/chart.component';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import { useSelector } from 'react-redux';
import WcapitalCustomGraphs from './sharedComponents/subComponents/WcapitalCustomGraphs.component';

const RstVariable: FC<FundInfoComponentProps> = (props) => {
  const activeFund = props.fundId;
  const activeDate = props.positionDate;
  const { section } = props;

  const clientName = useSelector(clientNameSelector);

  const variableData = useFetchData({
    url: formatFundUrl(
      LiquidityUrls.FETCH_FUND_LIQUIDATION_TIME_COST,
      activeFund,
      activeDate
    ),
    keyName: makeUniqueDataKey(activeFund, 'variable_rst', activeDate),
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield dataObjects={[variableData]}>
      {clientName === 'wcapital' ? (
        <WcapitalCustomGraphs section={section} timeCostData={variableData} />
      ) : (
        <>
          <TimeCostTable
            section={section}
            bauOrStressed="bau"
            timeCostData={variableData}
          />
          <TimeCostTable
            section={section}
            bauOrStressed="stressed"
            timeCostData={variableData}
          />
        </>
      )}
      <LiquidationTimeCostChart
        bauOrStressed="bau"
        liquidationData={variableData}
        pdfDontmoveToNewPage={true}
        pdfAligment={'left'}
        pdfDontIncrementYPosition={true}
      />
      <LiquidationTimeCostChart
        bauOrStressed="stressed"
        liquidationData={variableData}
        pdfDontmoveToNewPage={true}
        pdfAligment={'right'}
      />
    </GeneralComponentErrorShield>
  );
};

export default RstVariable;
