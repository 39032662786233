import React from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToNdecialPlaces,
} from '../../../../../utilities/numberFormatters';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import SecondLevelWrapper from './SecondLevelTableWrapper.component';
import { hexToRGBA } from '../../../../../utilities/colorUtilities';
import { mainColors } from '../../../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap, Tooltip } from '@mui/material';

interface StockSelectionPerformanceTableProps {
  data: DataObject;
}

export interface StockSelectionPerformanceTableData {
  sector_name: string;
  fund_weight: number;
  benchmark_weights: number;
  fund_sector_returns: number;
  fund_weighted_benchmark_returns: number;
  difference_fund_sector_returns_vs_fwbr: number;
  over_or_under_performed: string;
  underlying_assets: string;
}

addCommasToNumbersAndRound;

const useStyles = makeStyles(() => ({
  tableHeader: {
    cursor: 'pointer',
    width: 'fit-content',
    margin: '0 auto',
    padding: '0.5rem 1rem',
    borderRadius: '0.4rem',
    // '&:hover': {
    //   backgroundColor: mainColors.hoverOverWhite,
    // },
  },
}));

const detailColumns = (
  classes: ClassNameMap<string>,
  sortHeader: (header: keyof StockSelectionPerformanceTableData) => void
): CustomColumn<StockSelectionPerformanceTableData>[] => [
  {
    title: (
      <div
        className={classes.tableHeader}
        onClick={() => sortHeader('sector_name')}
      >
        <Tooltip title="">
          <div>Sector Name</div>
        </Tooltip>
      </div>
    ),
    field: 'sector_name',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: (
      <div
        className={classes.tableHeader}
        onClick={() => sortHeader('fund_weight')}
      >
        <Tooltip title="">
          <div>Fund Weight</div>
        </Tooltip>
      </div>
    ),
    field: 'fund_weight',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: StockSelectionPerformanceTableData) =>
      rowData.sector_name !== 'Total'
        ? percentageToNdecialPlaces(rowData.fund_weight / 100, 2)
        : '',
  },
  {
    title: (
      <div
        className={classes.tableHeader}
        onClick={() => sortHeader('benchmark_weights')}
      >
        <Tooltip title="">
          <div>Benchmark Weights</div>
        </Tooltip>
      </div>
    ),
    field: 'benchmark_weights',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: StockSelectionPerformanceTableData) =>
      rowData.sector_name !== 'Total'
        ? percentageToNdecialPlaces(rowData.benchmark_weights / 100, 2)
        : '',
  },
  {
    title: (
      <div
        className={classes.tableHeader}
        onClick={() => sortHeader('fund_sector_returns')}
      >
        <Tooltip title="">
          <div>Fund Sector Returns</div>
        </Tooltip>
      </div>
    ),
    field: 'fund_sector_returns',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: StockSelectionPerformanceTableData) =>
      rowData.sector_name !== 'Total'
        ? percentageToNdecialPlaces(rowData.fund_sector_returns / 100, 2)
        : '',
  },
  {
    title: (
      <div
        className={classes.tableHeader}
        onClick={() => sortHeader('fund_weighted_benchmark_returns')}
      >
        <Tooltip title="">
          <div>Fund Weighted Benchmark Returns (FWBR)</div>
        </Tooltip>
      </div>
    ),
    field: 'fund_weighted_benchmark_returns',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: StockSelectionPerformanceTableData) =>
      percentageToNdecialPlaces(
        rowData.fund_weighted_benchmark_returns / 100,
        2
      ),
  },
  {
    title: (
      <div
        className={classes.tableHeader}
        onClick={() => sortHeader('difference_fund_sector_returns_vs_fwbr')}
      >
        <Tooltip title="">
          <div>Difference</div>
        </Tooltip>
      </div>
    ),
    field: 'difference_fund_sector_returns_vs_fwbr',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: StockSelectionPerformanceTableData) =>
      percentageToNdecialPlaces(
        rowData.difference_fund_sector_returns_vs_fwbr / 100,
        2
      ),
  },
  {
    title: (
      <div
        className={classes.tableHeader}
        onClick={() => sortHeader('over_or_under_performed')}
      >
        <Tooltip title="">
          <div>Outperformance / Underperformance</div>
        </Tooltip>
      </div>
    ),
    field: 'over_or_under_performed',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
];

const buildTableData = (
  inputData: any
): StockSelectionPerformanceTableData[] => {
  if (
    !inputData.data ||
    !inputData.data.length ||
    !inputData.data[0].stock_selection
  ) {
    return [];
  } else {
    const tableData: StockSelectionPerformanceTableData[] = [];

    // Create a total row in the table
    const totalRow: StockSelectionPerformanceTableData = {
      sector_name: 'Total',
      fund_weight: 0,
      benchmark_weights: 0,
      fund_sector_returns: 0,
      fund_weighted_benchmark_returns: 0,
      difference_fund_sector_returns_vs_fwbr: 0,
      over_or_under_performed: '',
      underlying_assets: '',
    };
    tableData.push(totalRow);

    inputData.data[0].stock_selection.map((sector: any) => {
      tableData.push(sector);
    });

    tableData.forEach((row: StockSelectionPerformanceTableData) => {
      // totalRow.fund_weight += row.fund_weight;
      // totalRow.benchmark_weights += row.benchmark_weights;
      // totalRow.fund_sector_returns += row.fund_sector_returns;
      totalRow.fund_weighted_benchmark_returns +=
        row.fund_weighted_benchmark_returns;
      totalRow.difference_fund_sector_returns_vs_fwbr +=
        row.difference_fund_sector_returns_vs_fwbr;
    });
    return tableData;
  }
};

const StockSelectionPerformance: React.FC<
  StockSelectionPerformanceTableProps
> = ({ data }) => {
  const classes = useStyles();

  const title = `Stock Selection Performance`;

  const originalTableData = buildTableData(data);
  const [tableData, setTableData] =
    React.useState<StockSelectionPerformanceTableData[]>(originalTableData);
  const [currentSortedColumn, setCurrentSortedColumn] = React.useState<
    string | null
  >(null);
  const [currentSortOrder, setCurrentSortOrder] = React.useState<
    'asc' | 'desc' | null
  >(null);

  const sortTable = (
    column: keyof StockSelectionPerformanceTableData,
    order: 'asc' | 'desc'
  ) => {
    // sort originalTableData by column and order (using localecompare if string), leaving the first column in place
    const [totalRow, ...rest] = originalTableData;
    let sortedRest: StockSelectionPerformanceTableData[] = [];
    if (typeof rest[0][column] === 'number') {
      sortedRest = rest.sort((a: any, b: any) => {
        if (order === 'asc') {
          return a[column] - b[column];
        } else {
          return b[column] - a[column];
        }
      });
    } else if (typeof rest[0][column] === 'string') {
      sortedRest = rest.sort((a: any, b: any) => {
        if (order === 'asc') {
          return a[column].localeCompare(b[column], undefined, {
            numeric: true,
            sensitivity: 'base',
          });
        } else {
          return b[column].localeCompare(a[column], undefined, {
            numeric: true,
            sensitivity: 'base',
          });
        }
      });
    }
    setTableData([totalRow].concat(sortedRest));
  };

  const sortHeader = (header: keyof StockSelectionPerformanceTableData) => {
    if (currentSortedColumn === null || currentSortOrder === null) {
      setCurrentSortedColumn(header);
      setCurrentSortOrder('asc');
      sortTable(header, 'asc');
    } else if (currentSortedColumn === header && currentSortOrder === 'asc') {
      setCurrentSortedColumn(header);
      setCurrentSortOrder('desc');
      sortTable(header, 'desc');
    } else if (currentSortedColumn === header && currentSortOrder === 'desc') {
      setCurrentSortedColumn(null);
      setCurrentSortOrder(null);
      setTableData(originalTableData);
    } else {
      setCurrentSortedColumn(header);
      setCurrentSortOrder('asc');
      sortTable(header, 'asc');
    }
  };

  return (
    <GridItem xs={12} card>
      <CustomTable<StockSelectionPerformanceTableData>
        columns={detailColumns(classes, sortHeader)}
        showToolbar
        data={tableData}
        title={title}
        options={{
          draggable: false,
          sorting: false,
          paging: false,
          search: true,
          exportButton: true,
          rowStyle: (rowData: StockSelectionPerformanceTableData) => ({
            backgroundColor:
              rowData.sector_name === 'Total'
                ? hexToRGBA(mainColors.jonquil, 0.8)
                : undefined,
            borderTop:
              rowData.sector_name === 'Total' ? '1px solid black' : undefined,
          }),
        }}
        detailPanel={SecondLevelWrapper()}
      />
    </GridItem>
  );
};

export default StockSelectionPerformance;
