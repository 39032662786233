export const addCommasToNumbers = (number: number | string): string => {
  return Number(number).toLocaleString();
};

// export const addCommasToNumbersWithDecimalPlaces = (number) => {
//     return Number(number).toFixed(2).toLocaleString()
// }

// export const addCommasToNumbersWithDecimalPlaces2 = (number) => {
//     return (Number(number)).toLocaleString('en-US', { minimumFractionDigits: 2 })
// }

export const addCommasToNumbersAndRound = (number: number | string): string => {
  if (number === '') return number;
  return Number(Math.round(number as number)).toLocaleString();
};

export const roundNumberToTwoDecimalPlaces = (
  number: number | string
): string => {
  if (number === '') return number;
  return Number(number as number).toFixed(2);
};

export const roundNumberToNDecimalPlaces = (
  number: number | string,
  N: number
): string => {
  if (number === '') return number;
  return Number(number as number).toFixed(N);
};

export function formatMillions(amount: number | string): string {
  let amountToFormat: number;
  if (typeof amount === 'string') {
    amountToFormat = parseFloat(amount);
  } else {
    amountToFormat = amount;
  }
  if (Math.abs(amountToFormat) >= 1000000000) {
    return roundNumberToNDecimalPlaces(amountToFormat / 1000000000, 2) + 'B';
  } else if (Math.abs(amountToFormat) >= 1000000) {
    return roundNumberToNDecimalPlaces(amountToFormat / 1000000, 2) + 'M';
  } else if (Math.abs(amountToFormat) >= 1000) {
    return roundNumberToNDecimalPlaces(amountToFormat / 1000, 2) + 'K';
  } else {
    return addCommasToNumbers(amountToFormat);
  }
}
export function formatMillionsTwoDecimalPlaces(
  amount: number | string
): string {
  let amountToFormat: number;
  if (typeof amount === 'string') {
    amountToFormat = parseFloat(amount);
  } else {
    amountToFormat = amount;
  }
  return Math.abs(amountToFormat) < 1000000
    ? addCommasToNumbers(amountToFormat)
    : (amountToFormat / 1000000).toFixed(2) + 'm';
}

// For creating decimal values to any number of decimal places
export const percentageToNdecialPlaces = (
  value: number | string | undefined,
  n: number
): string => {
  if (typeof value === 'string') return value;
  else if (value === undefined || isNaN(value)) return '-';
  else {
    return `${parseFloat((value * 100).toFixed(n))}%`;
  }
};

export const percentageToNdecialPlacesAddZeros = (
  value: number | string | undefined,
  n: number
): string => {
  if (typeof value === 'string') return value;
  else if (value === undefined) return '-';
  else {
    return `${(value * 100).toFixed(n)}%`;
  }
};

// For creating decimal values to any number of decimal places
export const percentageToNdecialPlacesNoHundred = (
  value: number | string | undefined,
  n: number
): string => {
  if (typeof value === 'string') return value;
  else if (value === undefined) return '-';
  else return `${parseFloat(value.toFixed(n))}%`;
};

// expects a percentage in decimal form, i.e between 0 and 1
export const percentageToTwoDecimalPlaces = (
  first: number | string | undefined,
  second = 1
): string => {
  // we're putting this in to deal with RST Fixed, but it might be useful
  // in other situations
  if (typeof first === 'string') return first;
  else if (first === undefined) return '-';
  else
    return Math.abs(first / second) * 100 < 0.01
      ? '0.00%'
      : `${((first / second) * 100).toFixed(2)} %`;
};
export const percentageToTwoDecimalPlacesNoHundred = (
  first: any,
  second: any = 1
) => {
  return `${(first / second).toFixed(2)}%`;
};

export const formatESGWithUnit = (
  value: number | string | undefined,
  unit: string
): string => {
  if (value === 'No Data' || value === 'Not Available' || value === 'N/A') {
    return value;
  } else if (unit && unit.includes('\\u00b3')) {
    unit = unit.replace('\\u00b3', '\u00b3');
  }
  if (unit === '%' && typeof value === 'number') {
    return `${roundNumberToTwoDecimalPlaces(value * 100)} ${unit}`;
  } else if (unit === '%' && Number(value)) {
    return `${roundNumberToTwoDecimalPlaces(Number(value) * 100)} ${unit}`;
  } else if (!unit && Number(value)) {
    return `${roundNumberToTwoDecimalPlaces(Number(value))}`;
  } else if (Number(value)) {
    return `${roundNumberToTwoDecimalPlaces(Number(value))} ${unit}`;
  } else if (!unit) {
    return `${value}`;
  } else {
    return `${value} ${unit}`;
  }
};

export const formatESGNumber = (value: number | string | undefined): string => {
  // If the value is undefined then just return an empty string
  if (value === null) return '';
  // First check if the value is a percentage.
  let percentageValue = false;
  // We want to have the value as a number.
  let numericValue = 0;
  if (typeof value === 'string') {
    if (
      value === 'Not Available' ||
      value === 'Not Applicable' ||
      value === 'N/A'
    )
      return value;
    if (value.includes('%')) {
      percentageValue = true;
      // Also convert the value to numeric.
      numericValue = parseFloat(value.replace('%', ''));
    } else {
      numericValue = parseFloat(value);
    }
  } else if (typeof value === 'number') numericValue = value;
  // Now format the number depending on its size.
  let formattedNumber = '';
  if (Math.abs(numericValue) === 0) formattedNumber = '0.00';
  else if (Math.abs(numericValue) < 0.0001)
    formattedNumber = numericValue.toExponential(4);
  else if (Math.abs(numericValue) < 1)
    formattedNumber = roundNumberToNDecimalPlaces(numericValue, 4);
  else if (Math.abs(numericValue) <= 1000)
    formattedNumber = roundNumberToTwoDecimalPlaces(numericValue);
  else formattedNumber = addCommasToNumbersAndRound(numericValue);

  // Add back in the percentage value if needed.
  if (percentageValue) return formattedNumber + '%';
  else return formattedNumber;
};

export const percentageToNoDecimalPlacesNoHundred = (
  first: any,
  second: any = 1
) => {
  return `${(first / second).toFixed(0)}%`;
};

export const nonStringPercentageToTwoDecimalPlaces = (
  first: any,
  second: any = 1
) => {
  return parseFloat(((100 * first) / second).toFixed(2));
};

export const toTwoDecimalPlaces = (num: number | string) => {
  return num !== 0 ? ((100 * parseFloat(num as string)) / 100).toFixed(2) : '0';
};
export const toFourDecimalPlaces = (num: number) =>
  num !== 0 ? ((10000 * num) / 10000).toFixed(4) : '0';

// export const removeDecimalPlaces = (number) => {
//     return Math.round(number)
// }

export const roundToTwoDecimalPlacesReturnNumber = (
  number: number | string
): number => {
  if (number === '' || !number) return 0;
  return typeof number === 'string'
    ? Number(parseFloat(number).toFixed(2))
    : Number(number.toFixed(2));
};

export const roundToNDecimalPlacesReturnNumber = (
  number: number | string,
  N: number
): number => {
  if (number === '' || !number) return 0;
  return typeof number === 'string'
    ? Number(parseFloat(number).toFixed(N))
    : Number(number.toFixed(N));
};

export const toFourDecimalPlacesIfNumber = (val: number | string) => {
  if (typeof val === 'number') {
    return val !== 0 ? ((10000 * val) / 10000).toFixed(4) : '0';
  } else {
    return val;
  }
};

export const roundToXDecimalPlacesReturnNumber = (
  number: number | string,
  X: number
): number => {
  if (number === '' || !number) return 0;
  return typeof number === 'string'
    ? Number(parseFloat(number).toFixed(X))
    : Number(number.toFixed(X));
};

export const numberToXDecimalPlaces = (val: number, X: number): number => {
  return Number((val * 100).toFixed(X));
};

export const ifNumberAddCommasAndRound = (input: any): string => {
  if (typeof input === 'number') {
    return input.toLocaleString();
  } else {
    return input;
  }
};

export const ifNumberRoundToNDecimalPlaces = (
  input: any,
  N: number
): string => {
  if (typeof input === 'number') {
    return roundNumberToNDecimalPlaces(input.toLocaleString(), N);
  } else {
    return input;
  }
};
