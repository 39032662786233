import { ReactElement, useState } from 'react';
import { Bar, Line, ReferenceLine } from 'recharts';
import { RaptorTheme } from '../../../../../../styling/theme';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../../../../utilities/numberFormatters';
import { IOutSampleChartData, buildOutSampleBarChart } from './Daily.data';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import { DataObject } from '../../../../../../types/redux/data/dataTypes';
import GenericComposedChart from '../../../../../charts/GenericComposedChart';
import { useWindowSize } from '../../../../../../hooks/useWindowSize';
import PercentValueToggleButton from '../../../../../buttons/PercentValueToggleButton.component';
import { useTheme } from '@mui/material';

interface Props {
  varData: DataObject;
}

function DailyPAndL({ varData }: Props): ReactElement {
  // Allow user to toggle between percent and dollar value display.
  const [percentValueOption, setPercentValueOption] = useState('value');
  const chartData = varData.data.length
    ? buildOutSampleBarChart(varData.data)
    : [];
  const legendFormatter = (value?: any, entry?: any, i?: number) => {
    return (
      <span style={{ color: theme.palette.grey[700] }}>
        {value === 'dailypl' ? 'Daily P/L' : 'Daily VaR'}
      </span>
    );
  };
  const tooltipFormatter = (
    value: string | number | Array<string | number>,
    name: string,
    entry: any,
    index: number
  ) => {
    return [
      percentValueOption === 'value'
        ? addCommasToNumbersAndRound(value as number)
        : percentageToTwoDecimalPlaces(value as number),
      name.includes('dailypl') ? 'Daily P/L' : 'Daily VaR',
    ];
  };
  const theme: RaptorTheme = useTheme();
  const { width } = useWindowSize();
  return (
    <GridItem
      xs={9}
      style={{ display: 'flex', alignSelf: 'stretch' }}
      card
      cardStyle={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
      }}
    >
      <div style={{ marginLeft: '0.5rem', marginTop: '0.5rem' }}>
        <PercentValueToggleButton
          percentValueOption={percentValueOption}
          updatePercentValueOption={setPercentValueOption}
        />
      </div>
      <GenericComposedChart<IOutSampleChartData>
        loading={varData.isFetching}
        data={chartData}
        title="Daily P/L"
        export
        download
        height={'90%'}
        width={width * 0.7}
        margin={{ top: 50, right: 50, left: 100, bottom: 50 }}
        xAxes={[
          {
            dataKey: 'tick',
            interval: 'preserveStartEnd',
            type: 'category',
            minTickGap: 70,
          },
        ]}
        yAxes={[
          {
            label: {
              value: varData.data[0]?.base_currency || 'EUR',
              angle: -90,
              position: 'left',
              offset: 30,

              style: { fill: theme.palette.grey[700] },
            },
            tickFormatter: (val) =>
              percentValueOption === 'value'
                ? addCommasToNumbersAndRound(val)
                : percentageToTwoDecimalPlaces(val),
          },
        ]}
        legendFormatter={legendFormatter}
        tooltipFormatter={tooltipFormatter}
        components={[
          {
            ComponentType: Bar,
            props: {
              dataKey:
                percentValueOption === 'value'
                  ? 'dailypl'
                  : 'dailyplpercentage',
              fill: theme.palette.primary.main,
            },
          },
          {
            ComponentType: Line,
            props: {
              type: 'monotone',
              dataKey:
                percentValueOption === 'value'
                  ? 'dailyvar'
                  : 'dailyvarpercentage',
              stroke: 'red',
            },
          },
          {
            ComponentType: ReferenceLine,
            props: {
              y: 0,
              stroke: 'red',
            },
          },
        ]}
      />
    </GridItem>
  );
}

export default DailyPAndL;
