import React from 'react';

const Raptor2LoadingIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_317_49"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="80"
        height="80"
      >
        <rect width="80" height="80" fill="#012169" />
      </mask>
      <g mask="url(#mask0_317_49)">
        <rect width="80" height="80" fill="#012169" rx="15" ry="15" />
        <path d="M44.878 51.4286L44.878 80H0L44.878 51.4286Z" fill="#ED8B00" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.5501 72.998C8.46091 81.8715 -11.0374 87.6191 -37.0732 87.619L-37.0732 77.0853C-12.8595 77.0854 4.56292 71.7688 17.6954 64.046C30.8759 56.2949 40.1082 45.9018 47.5904 35.1874C51.3415 29.816 54.6134 24.4237 57.7559 19.2254L58.0083 18.8078C61.023 13.8202 63.9749 8.93659 67.024 4.69978C73.1899 -3.86791 80.8925 -11.4286 92.6829 -11.4286C104.493 -11.4286 112.127 -3.84477 118.21 4.72875C121.186 8.92471 124.065 13.7564 127.005 18.69L127.34 19.2535C130.437 24.4494 133.665 29.8385 137.38 35.2052C144.789 45.9087 153.982 56.294 167.204 64.0415C180.383 71.7633 197.941 77.0854 222.439 77.0853L222.439 87.619C196.144 87.6191 176.51 81.877 161.365 73.0024C146.263 64.1536 136.002 52.4104 128.094 40.9854C124.15 35.2879 120.754 29.6127 117.669 24.4358L117.444 24.0589C114.412 18.969 111.722 14.4563 108.993 10.6084C103.301 2.58551 98.5666 -0.894924 92.6829 -0.894923C86.7793 -0.894922 81.9777 2.60867 76.1997 10.6374C73.3998 14.5279 70.6363 19.0994 67.5203 24.254L67.3934 24.464C64.2653 29.6384 60.828 35.3104 56.8524 41.0032C48.8816 52.4173 38.5912 64.1527 23.5501 72.998Z"
          fill="white"
        />
        <animate
          attributeName="opacity"
          dur="5s"
          keyTimes="0;0.1;0.5;0.6;1"
          values="0.2;1;1;0.2;0.2"
          repeatCount="indefinite"
        />
      </g>
    </svg>
  );
};

export default React.memo(Raptor2LoadingIcon);
