import './Tooltip.css';

import * as React from 'react';

import useTooltip from './useTooltip';

interface TooltipProps {
  label: React.ReactNode;
  value?: number;
  // projects?: Record<string, number>;
  missing_files?: string[];
}

export const Tooltip = React.memo<TooltipProps>(
  ({ label, value, missing_files }) => {
    const { tooltipClassName, valueFn } = useTooltip();
    return (
      <div className={tooltipClassName}>
        <div className="CalendarHeatMap__tooltip">
          {value !== undefined ? (
            <>
              <span className="CalendarHeatMap__tooltipLabel">
                {`${label}`}:{' '}
              </span>
              <span className="CalendarHeatMap__tooltipValue">{`${valueFn(
                value
              )}`}</span>
            </>
          ) : (
            'TEST'
          )}
          {missing_files && (
            <div className="CalendarHeatMap__tooltipProjects">
              {missing_files.map((file: string) => {
                return (
                  <div key={file}>
                    <span className="CalendarHeatMap__tooltipLabel">
                      {file}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
);
