import React, { useEffect, useRef } from 'react';
import { createChart, ColorType } from 'lightweight-charts';
import GeneralCard from '../../../../../general/GeneralCard';
import { makeStyles } from '@mui/styles';
import { mainColors } from '../../../../../../styling/theme';
import GenericLineChart from '../../../../../charts/GenericLineChart';

const useStyles = makeStyles({
  chartContainer: {
    margin: '2em 0',
    padding: '2em',
    flexGrow: 1,
    height: '100%',
    width: '320rem',
  },
});

const data = [
  { Maturity: 0.25, Par: 3.2642, Spot: 3.3043, Forward: 3.3043 },
  { Maturity: 0.5, Par: 3.0417, Spot: 3.0648, Forward: 2.8258 },
  { Maturity: 1, Par: 2.7032, Spot: 2.7032, Forward: 2.3428 },
  { Maturity: 2, Par: 2.318, Spot: 2.3136, Forward: 1.9255 },
  { Maturity: 3, Par: 2.1587, Spot: 2.1525, Forward: 1.8309 },
  { Maturity: 4, Par: 2.1431, Spot: 2.1385, Forward: 1.854 },
  { Maturity: 5, Par: 2.1275, Spot: 2.1228, Forward: 2.0782 },
  { Maturity: 6, Par: 2.1741, Spot: 2.1728, Forward: 2.2755 },
  { Maturity: 7, Par: 2.2207, Spot: 2.2227, Forward: 2.4729 },
  { Maturity: 8, Par: 2.2548, Spot: 2.2639, Forward: 2.6137 },
  { Maturity: 9, Par: 2.3171, Spot: 2.3283, Forward: 2.7071 },
  { Maturity: 10, Par: 2.3795, Spot: 2.3956, Forward: 2.8004 },
  { Maturity: 15, Par: 2.4995, Spot: 2.5357, Forward: 2.8342 },
  { Maturity: 20, Par: 2.5858, Spot: 2.6307, Forward: 2.8664 },
  { Maturity: 25, Par: 2.5214, Spot: 2.5053, Forward: 2.4687 },
  { Maturity: 30, Par: 2.457, Spot: 2.4438, Forward: 2.0709 },
];

const lines = [
  { dataKey: 'Par', color: '#8884d8', strokeWidth: 2 },
  { dataKey: 'Spot', color: '#82ca9d', strokeWidth: 2 },
  { dataKey: 'Forward', color: '#D20103', strokeWidth: 2 },
];

const YieldCurve: React.FC = () => {
  const classes = useStyles();
  const error = data.length === 0 ? 'No data available' : null;

  return (
    <GeneralCard className={classes.chartContainer}>
      <GenericLineChart
        data={data}
        lines={lines}
        title=""
        width={'100%'}
        height={600}
        showTitle={true}
        showLegend={true}
        showTooltip={true}
        xAxisDataKey="name"
        yAxisTicks={[1.8, 2.0, 2.2, 2.4, 2.6, 2.8, 3.0, 3.2, 3.4]}
        // referenceLineY={3000}
        // referenceLineYLabel="Reference Line"
        showDot={false} // hides the dots at data points
        backgroundColor="white" // sets the background color for the chart
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        xInterval={0} // ensures every x-axis label is shown
        pdfAligment="center" // aligns the chart in the PDF
        error={error}
      />
    </GeneralCard>
  );
};

export default YieldCurve;
