import React from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject, Status } from '../../../../types/redux/data/dataTypes';
import { percentageToTwoDecimalPlaces } from '../../../../utilities/numberFormatters';
import { specifyStatus } from '../../../../utilities/specfyStatus';
import RaptorStatusBox from '../../../feedback/RaptorStatusBox.component';

export interface CssfTableData {
  number: number;
  ruleTitle: string;
  value: number;
  limit: number;
  status: Status;
}

export const generateCssfColumns = (): CustomColumn<CssfTableData>[] => [
  {
    field: 'number',
    title: '#',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    width: '1%',
  },
  {
    field: 'ruleTitle',
    title: 'Rule',
    headerStyle: {
      textAlign: 'left',
    },
    cellStyle: {
      textAlign: 'left',
    },
  },
  {
    field: 'value',
    title: 'Value',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    width: '15rem',
    render: (rowData) => percentageToTwoDecimalPlaces(rowData.value),
  },
  {
    field: 'limit',
    title: 'Limit',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData) => percentageToTwoDecimalPlaces(rowData.limit),
    width: '15rem',
  },
  {
    field: 'status',
    title: 'Status',
    pdfRenderType: 'PageLinkButton',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    width: '15rem',
    render: (rowData: CssfTableData) => (
      <RaptorStatusBox status={specifyStatus(rowData.status)} />
    ),
  },
];

export const generateCssfData = (data: DataObject) => {
  try {
    if (!data || !data?.data.length) return [];
    const dataForUse = data.data[0].cssf_list.slice(1); // don't include the header
    return dataForUse.map(
      (el: [string, string, number, number], index: number) => {
        const [ruleTitle, status, value, limit] = el;
        return {
          number: index + 1,
          ruleTitle,
          value,
          limit,
          status: specifyStatus(status),
        };
      }
    );
  } catch (err) {
    return err;
  }
};
