import { TemplateFieldType } from '../../types/redux/kids/kidsTypes';

export const TOGGLE_KID_FILTER_ACTION = 'TOGGLE_KID_FILTER_ACTION';
export type TOGGLE_KID_FILTER_ACTION = typeof TOGGLE_KID_FILTER_ACTION;
export const TURN_OFF_ALL_FILTERS_ACTION = 'TURN_OFF_ALL_FILTERS_ACTION';
export type TURN_OFF_ALL_FILTERS_ACTION = typeof TURN_OFF_ALL_FILTERS_ACTION;

export type TOGGLE_CAN_USER_NAVIGATE_AWAY_ACTION =
  'TOGGLE_CAN_USER_NAVIGATE_AWAY_ACTION';
export const TOGGLE_CAN_USER_NAVIGATE_AWAY_ACTION =
  'TOGGLE_CAN_USER_NAVIGATE_AWAY_ACTION';
export type ALLOW_USER_TO_NAVIGATE_AWAY_ACTION =
  'ALLOW_USER_TO_NAVIGATE_AWAY_ACTION';
export const ALLOW_USER_TO_NAVIGATE_AWAY_ACTION =
  'ALLOW_USER_TO_NAVIGATE_AWAY_ACTION';
export type DONT_ALLOW_USER_TO_NAVIGATE_AWAY_ACTION =
  'DONT_ALLOW_USER_TO_NAVIGATE_AWAY_ACTION';
export const DONT_ALLOW_USER_TO_NAVIGATE_AWAY_ACTION =
  'DONT_ALLOW_USER_TO_NAVIGATE_AWAY_ACTION';

export const KidsActionType =
  TOGGLE_KID_FILTER_ACTION ||
  TURN_OFF_ALL_FILTERS_ACTION ||
  TOGGLE_CAN_USER_NAVIGATE_AWAY_ACTION ||
  ALLOW_USER_TO_NAVIGATE_AWAY_ACTION ||
  DONT_ALLOW_USER_TO_NAVIGATE_AWAY_ACTION;

export type ToggleKidFilterAction = {
  type: TOGGLE_KID_FILTER_ACTION;
  payload: TemplateFieldType;
};

export type ToggleCanUserNavigateAwayAction = {
  type: TOGGLE_CAN_USER_NAVIGATE_AWAY_ACTION;
};

export type AllowUserToNavigateAwayAction = {
  type: ALLOW_USER_TO_NAVIGATE_AWAY_ACTION;
};

export type DontAllowUserToNavigateAwayAction = {
  type: DONT_ALLOW_USER_TO_NAVIGATE_AWAY_ACTION;
};

export type TurnOffAllFiltersAction = {
  type: TURN_OFF_ALL_FILTERS_ACTION;
};

// ACTION CREATOR FUNCTIONS
export const toggleKidFilter = (
  whichFilterToToggle: TemplateFieldType
): ToggleKidFilterAction => {
  return {
    type: TOGGLE_KID_FILTER_ACTION,
    payload: whichFilterToToggle,
  };
};

export const toggleCanUserNavigateAwayAction =
  (): ToggleCanUserNavigateAwayAction => {
    return {
      type: TOGGLE_CAN_USER_NAVIGATE_AWAY_ACTION,
    };
  };

// This will be used to force the value to true when every the page is first loaded
export const setAllowUserToNavigateAway = (): AllowUserToNavigateAwayAction => {
  return {
    type: ALLOW_USER_TO_NAVIGATE_AWAY_ACTION,
  };
};

// This will be used to force the value to false whenever an event occurs that would not allow the user to leave the page
export const setDontAllowUserToNavigateAway =
  (): DontAllowUserToNavigateAwayAction => {
    return {
      type: DONT_ALLOW_USER_TO_NAVIGATE_AWAY_ACTION,
    };
  };

export const turnOffAllFilters = (): TurnOffAllFiltersAction => {
  return {
    type: TURN_OFF_ALL_FILTERS_ACTION,
  };
};

export type KidAction =
  | ToggleKidFilterAction
  | TurnOffAllFiltersAction
  | ToggleCanUserNavigateAwayAction
  | AllowUserToNavigateAwayAction
  | DontAllowUserToNavigateAwayAction;
