import React, { FC } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { LiquidityUrls } from '../fundAssetLiquidationTime/component';
import useFetchData from '../../../../hooks/useFetchData';
import PortfolioLiquidation from './charts/portfolioLiquidation/portfolioLiquidation.component';
import MaximumOneDay from './charts/maximumOneDay/maximumOneDay.component';
import LiquidationCost from './charts/liquidationCost/liquidationCost.component';
import HistoricalNav from './charts/historicalNav/historicalNav.component';
import { DatePicker } from '../../../../types/redux/pages/PagesStore';
import {
  getMostRecentWeekday,
  getWeekdayXMonthsAgo,
} from '../../../../utilities/dateUtilities';
import { useSelector } from 'react-redux';
import {
  activeSectionSelector,
  createActiveFundSelectorBySection,
  createSpecificTitleBarDatePickerSelector,
} from '../../../../redux/pages/selectors';
import { useAdditionalTitleBarDatePicker } from '../../../../hooks/useAdditionalTitleBarComponents';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

const LiquidityRiskEvolution: FC<FundInfoComponentProps> = (props) => {
  const startDateWeekday = getWeekdayXMonthsAgo(1);
  const endDateWeekday = getMostRecentWeekday();

  const startDatePicker: DatePicker = {
    titleBarKey: 'startDate',
    titleBarTitle: 'Select Start Date: ',
    currentValue: dayjs(startDateWeekday).format('YYYY-MM-DD'),
    displayOnOverviewPage: false,
  };
  const { pathname, search } = useLocation();

  const endDatePicker: DatePicker = {
    titleBarKey: 'endDate',
    titleBarTitle: 'Select End Date: ',
    currentValue: dayjs(endDateWeekday).format('YYYY-MM-DD'),
    displayOnOverviewPage: false,
  };
  const activeSection = useSelector(activeSectionSelector);
  const activeFund = useSelector(
    createActiveFundSelectorBySection(activeSection)
  );

  useAdditionalTitleBarDatePicker(startDatePicker);
  useAdditionalTitleBarDatePicker(endDatePicker);

  const startDate = useSelector(
    createSpecificTitleBarDatePickerSelector('startDate')
  );
  const endDate = useSelector(
    createSpecificTitleBarDatePickerSelector('endDate')
  );
  const startDateProper = startDate[0] ? startDate[0].currentValue : null;
  const endDateProper = endDate[0] ? endDate[0].currentValue : null;

  const liquidityRiskData = useFetchData({
    keyName: `${props.fundId}_risk_evolution_${startDateProper}_${endDateProper}`,
    url: `${LiquidityUrls.FETCH_EVOLUTION}/${props.fundId}/${startDateProper}/${endDateProper}`,
    makeFetch:
      startDateProper !== null &&
      startDateProper !== undefined &&
      endDateProper !== null &&
      endDateProper !== undefined,
  });

  const { height, width } = useWindowSize();
  const renderHeight = (height - 296) / 2;
  const renderWidth = width * 0.4;

  return (
    <GeneralComponentErrorShield dataObjects={[liquidityRiskData]}>
      {/* <section id="pdf-target" > */}
      <PortfolioLiquidation
        inputData={liquidityRiskData}
        height={renderHeight}
        dimensions={{ xs: 6 }}
      />
      <MaximumOneDay
        inputData={liquidityRiskData}
        height={renderHeight}
        dimensions={{ xs: 6 }}
      />
      <LiquidationCost
        inputData={liquidityRiskData}
        height={renderHeight}
        dimensions={{ xs: 6 }}
      />
      <HistoricalNav
        inputData={liquidityRiskData}
        height={renderHeight}
        width={renderWidth}
        dimensions={{ xs: 6 }}
      />
      {/* </section> / */}
    </GeneralComponentErrorShield>
  );
};

export default LiquidityRiskEvolution;
