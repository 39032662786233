import { createSelector } from 'reselect';
import { getUiState } from '../selectors';

export const controlBarStateSelector = createSelector(
  getUiState,
  (uiState) => uiState.controlBarState
);

export const isControlBarShowingSelector = createSelector(
  controlBarStateSelector,
  (controlBarState) => {
    return controlBarState.controlBarIsShowing;
  }
);

export const getControlBarComponentsSelector = createSelector(
  controlBarStateSelector,
  (controlBarState) => {
    return controlBarState.controlBarComponents;
  }
);
