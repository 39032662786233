import React, { FC, useEffect, useState } from 'react';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import useFetchData from '../../../../hooks/useFetchData';
import { makeUniqueDataKey } from '../../../../utilities/urlBuilder';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import FixedRstTable from './components/fixedRstTable.component';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import {
  SifRiskSpreadFixedColumns,
  act40FixedColumns,
  buildAct40FixedData,
  buildProspectusFixedData,
  buildRiaifFixedData,
  buildSifRiskColumns,
  buildTimeSeriesFixedData,
  buildUcitsFixedData,
  prospectusFixedColumns,
  riaifFixedColumns,
  timeSeriesFixedColumns,
  ucitsFixedColumns,
} from './components/fixedRst.data';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAdditionalTitleBarComponents,
  toggleTitleBarLoading,
} from '../../../../redux/pages/actions';
import { Select } from '../../../../types/redux/pages/PagesStore';
import { APPEND_NEW_SELECT_TO_TITLEBAR } from '../../../../types/redux/pages/pageTypes';
import {
  activeSectionSelector,
  createActiveFundSelectorBySection,
} from '../../../../redux/pages/selectors';

function getRedemptionLevel(keyValues: any[], selectedOption: string | null) {
  if (!selectedOption || !keyValues) return null;
  for (let i = 0; i < keyValues.length; i++) {
    if (keyValues[i].value === selectedOption) {
      // get the percentage value from the beginning of the name.
      const splitString = keyValues[i].label.split('%');
      try {
        return parseInt(splitString[0]);
      } catch {
        return null;
      }
    }
  }
  return null;
}

const RstFixed: FC<FundInfoComponentProps> = (props) => {
  const activeSection = useSelector(activeSectionSelector);
  const activeFund = useSelector(
    createActiveFundSelectorBySection(activeSection)
  );

  const [scenariosOptions, setScenariosOptions] = useState<any>(null);
  // Get the options for the dropdown
  const dropdownOptions = useFetchData({
    keyName: `${props.fundId}_lrst_dropdown_options`,
    url: `most_recently_stored_report_data_agg/${props.fundId}/lrst_fmv_key_data`,
    makeFetch: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleTitleBarLoading(true));
    if (
      dropdownOptions &&
      !dropdownOptions.isFetching &&
      dropdownOptions.data[0].length
    ) {
      setScenariosOptions(
        dropdownOptions.data[0].map((item: any) => ({
          label: item[0],
          value: item[1],
        }))
      );
    } else {
      setScenariosOptions(null);
    }
  }, [dropdownOptions, activeFund]);
  // add a dropdown for the scenarios
  useEffect(() => {
    if (scenariosOptions) {
      const selectsToAdd: Select[] = [
        {
          titleBarTitle: 'Select Scenario',
          titleBarKey: 'scenarioSelection',
          values: scenariosOptions,
          displayOnOverviewPage: false,
          aValueIsAlwaysSelected: true,
          currentValue: scenariosOptions[0].value,
        },
      ];
      dispatch(
        addAdditionalTitleBarComponents(
          selectsToAdd,
          APPEND_NEW_SELECT_TO_TITLEBAR
        )
      );
      dispatch(toggleTitleBarLoading(false));
    }
  }, [scenariosOptions]);

  // Format the options as needed for the select component
  interface scenarioMapType {
    [key: string]: any;
  }
  const scenarioMap: scenarioMapType = {};

  const selectedScenario = useTitleBarSelect('scenarioSelection');

  const { section } = props;
  const activeDate = props.positionDate;
  const urlForFetch = !activeDate
    ? `most_recently_stored_report_data_agg/${props.fundId}/${selectedScenario}`
    : `stored_report_data_agg/${props.fundId}/${selectedScenario}/${activeDate}`;

  // Check currently Active section
  const currentSection = useSelector(activeSectionSelector);
  const ucitsComplianceChecklist =
    currentSection === 'ucits'
      ? useFetchData({
          url: 'compliance_checklist/UCITS',
          keyName: 'compliance_checklist',
          makeFetch: true,
        })
      : null;
  const riaifComplianceChecklist =
    currentSection === 'riaif'
      ? useFetchData({
          url: 'compliance_checklist/RIAIF',
          keyName: 'riaif_compliance_checklist',
          makeFetch: true,
        })
      : null;
  const act40ComplianceChecklist =
    currentSection === 'act40'
      ? useFetchData({
          url: 'compliance_checklist/ACT40',
          keyName: 'act40_compliance_checklist',
          makeFetch: true,
        })
      : null;
  const liquidityData = useFetchData({
    keyName: makeUniqueDataKey(
      props.fundId,
      `fixed_rst_${selectedScenario}`,
      activeDate
    ),
    url: urlForFetch,
    makeFetch: true,
  });
  const redemptionLevel = getRedemptionLevel(
    scenariosOptions,
    selectedScenario
  );

  return (
    <GeneralComponentErrorShield
      dataObjects={[liquidityData]}
      customErrorMessage="No Data Available."
    >
      {liquidityData && (
        <>
          {!section.includes('qiaif') && !section.includes('partii') && (
            <FixedRstTable<any>
              scenario={scenarioMap[selectedScenario || '3_bau']}
              positionDate={liquidityData.data[0]?.selected_position_date || ''}
              columns={
                section.includes('ucits')
                  ? ucitsFixedColumns
                  : section.includes('riaif')
                  ? riaifFixedColumns
                  : section.includes('act40')
                  ? act40FixedColumns
                  : SifRiskSpreadFixedColumns
              }
              title={
                section.includes('ucits')
                  ? 'UCITS Restrictions Under Redemptions'
                  : section.includes('riaif')
                  ? 'RIAIF Restrictions Under Redemptions'
                  : section.includes('act40')
                  ? '40 Act Restrictions Under Redemptions'
                  : 'CSSF Risk Spreading Under Redemptions'
              }
              data={
                section.includes('ucits')
                  ? buildUcitsFixedData(
                      liquidityData.data,
                      ucitsComplianceChecklist,
                      redemptionLevel
                    )
                  : section.includes('riaif')
                  ? buildRiaifFixedData(
                      liquidityData.data,
                      riaifComplianceChecklist,
                      redemptionLevel
                    )
                  : section.includes('act40')
                  ? buildAct40FixedData(
                      liquidityData.data,
                      act40ComplianceChecklist,
                      redemptionLevel
                    )
                  : buildSifRiskColumns(liquidityData.data, redemptionLevel)
              }
            />
          )}
          <FixedRstTable
            scenario={scenarioMap[selectedScenario || '3_bau']}
            positionDate={liquidityData.data[0]?.selected_position_date || ''}
            columns={prospectusFixedColumns}
            title="Prospectus Restrictions Under Redemptions"
            data={buildProspectusFixedData(liquidityData.data, redemptionLevel)}
            fixed={
              section.includes('qiaif') || section.includes('partii')
                ? 6
                : undefined
            }
          />
          <FixedRstTable
            scenario={scenarioMap[selectedScenario || '3_bau']}
            positionDate={liquidityData.data[0]?.selected_position_date || ''}
            columns={timeSeriesFixedColumns}
            title="TimeSeries Data under Redemptions"
            data={buildTimeSeriesFixedData(liquidityData.data, redemptionLevel)}
            fixed={
              section.includes('qiaif') || section.includes('partii')
                ? 6
                : undefined
            }
          />
        </>
      )}
    </GeneralComponentErrorShield>
  );
};

export default RstFixed;
