import {
  SnackbarAction,
  TOGGLE_SNACKBAR_LOADING_ACTION,
  UPDATE_SNACKBAR_MESSAGE_ACTION,
} from './actions';

export interface SnackbarStore {
  snackbarIsLoading: boolean;
  snackbarDisplayMessage: string | null;
}

export const defaultSnackbarStore: SnackbarStore = {
  snackbarDisplayMessage: null,
  snackbarIsLoading: false,
};

function snackBarReducer(
  state: SnackbarStore = defaultSnackbarStore,
  action: SnackbarAction
): SnackbarStore {
  switch (action.type) {
    case UPDATE_SNACKBAR_MESSAGE_ACTION:
      return {
        ...state,
        snackbarDisplayMessage: action.payload,
      };
    case TOGGLE_SNACKBAR_LOADING_ACTION:
      return {
        ...state,
        snackbarIsLoading: action.payload,
      };
    default:
      return state;
  }
}

export default snackBarReducer;
