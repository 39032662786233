export const flexify = (
  justify = 'center',
  align = 'center',
  direction: 'row' | 'column' = 'row'
): React.CSSProperties => ({
  display: 'flex',
  justifyContent: justify,
  alignItems: align,
  flexDirection: direction,
});
