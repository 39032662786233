import React from 'react';
import GeneralCard from '../../../general/GeneralCard';
import Chart from './Chart';
import { makeStyles } from '@mui/styles';
import { mainColors } from '../../../../styling/theme';

export interface TimeSeriesDataPoint {
  time: string;
  value: number;
}

export interface MarketsGraphProp {
  asset: string;
  time: TimeSeriesDataPoint[];
}

export interface MarketsGraphPropArray {
  market_graph_data: MarketsGraphProp[];
  which_asset: string;
  line_colour: string;
}

const useStyles = makeStyles({
  cardHeader: {
    color: mainColors.mainBlue,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  chartContainer: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
  },
});

function getFormattedDate() {
  const today = new Date();
  const dayNumber = today.getDate();
  const monthName = today.toLocaleString('default', { month: 'long' });
  return `${dayNumber} ${monthName}`;
}

const Graph: React.FC<MarketsGraphPropArray> = ({
  market_graph_data,
  which_asset,
  line_colour
}) => {

  const classes = useStyles();
  const selectedAsset = market_graph_data.find(
    (asset) => asset.asset === which_asset
  );
  const hasData = selectedAsset && selectedAsset.time.length > 0;
  const today = getFormattedDate();

  return (
    <GeneralCard
      cardStyle={{
        height: '38rem',
        minWidth: '50em',
        padding: '0.7em 2em',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className={classes.cardHeader}>
        {hasData ? <h2>{which_asset}</h2> : <h2>No data available</h2>}
        <h2>{today}</h2>
        
      </div>
      <div className={classes.chartContainer}>
        {hasData ? (
          <>
          <Chart graphData={selectedAsset!.time} lineColour={line_colour}/>
          </>
        ) : (
          <div>No data available</div>
        )}
      </div>
      <h4 style={{fontWeight: 'lighter', color: 'grey'}}>TradingView Lightweight Charts™
      Copyright (с) 2023 TradingView</h4>
    </GeneralCard>
  );
};

export default Graph;
