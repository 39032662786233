import { useEffect, useState } from 'react';
import PotentialHedgePositionsTable from './subComponents/PotentialHedgePositionsTable.component';
import axios from 'axios';
import { BASE_URL } from '../../../../utilities/requestClient';
import RaptorLoading from '../../../feedback/RaptorLoading';
import NoDataMessage from '../../../feedback/NoDataMessage.component';
import { Grid } from '@mui/material';

export default function HedgeDerivativesSelector() {
  const [potentialHedgeData, setPotentialHedgeData] = useState<any>(null);

  useEffect(() => {
    axios
      .get(BASE_URL + 'get_all_potential_hedge_positions', {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200) {
          setPotentialHedgeData(response.data);
        } else {
          setPotentialHedgeData('error');
        }
      });
  }, []);

  if (potentialHedgeData === null) {
    return (
      <RaptorLoading
        centerWrap
        messages={[
          'Loading Potential Hedge Positions Data',
          'This may take a few seconds...',
        ]}
      />
    );
  } else if (potentialHedgeData === 'error') {
    return <NoDataMessage message={'Error Retrieving Data'} />;
  } else {
    return (
      <Grid container spacing={2} style={{ padding: 8 }}>
        <PotentialHedgePositionsTable originalData={potentialHedgeData} />
      </Grid>
    );
  }
}
