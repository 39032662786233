import React, { ReactElement } from 'react';
import {
  addCommasToNumbers,
  percentageToTwoDecimalPlaces,
  toFourDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import { useOverviewDetailStyles } from './component';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { hexToRGBA } from '../../../../../utilities/colorUtilities';
import { mainColors } from '../../../../../styling/theme';
import { IGenericLineChartProps } from '../../../../../types/components/charts/lineCharts';
import GenericLineChart from '../../../../charts/GenericLineChart';
import { Grid, Typography, useTheme } from '@mui/material';

interface Props {
  // TODO type definitions
  timeSeriesData: any;
  passValue: any;
  varFund: any;
}

function DetailTimeSeries({
  timeSeriesData,
  passValue,
  varFund,
}: Props): ReactElement {
  const theme = useTheme();
  const classes = useOverviewDetailStyles();
  function timeSeriesFormatter(data: any, lines: any) {
    // ? lines looks like this:
    /*
     * {dataKey, color, yAxisID}
     *
     * lineNames then will be
     */
    const lineNames = lines.map((line: any) => {
      return {
        name: line.dataKey,
        formatter: line.formatter,
      };
    });

    return data.map((row: any) => {
      const obj: any = {};

      obj.name = row[0];
      // the name value will always be what goes on the y axis

      lineNames.forEach((lineName: any, index: number) => {
        obj[lineName.name] = lineName.formatter(row[index + 1]);
      });

      return obj;
    });
  }
  const secondaryDataKey = varFund ? 'Value at Risk' : 'Commitment';

  function customFormatter(value: number | string) {
    return parseFloat(toFourDecimalPlaces(value as number));
  }
  const chartData = timeSeriesFormatter(timeSeriesData, [
    {
      dataKey: 'NAV',
      formatter: Math.round,
    },
    {
      dataKey: secondaryDataKey,
      formatter: customFormatter,
    },
  ]);

  const { width } = useWindowSize();
  return (
    <Grid item xs={4} className={classes.chart}>
      <Typography variant="h2" className={classes.mapTitle}>
        Historical Global Exposure
      </Typography>
      <div className={classes.chartContainerDiv}>
        <TimeSeriesContainer
          title="Historical Global Exposure"
          width="100%"
          height={width > 1600 ? 400 : 300}
          error={null}
          data={chartData}
          backgroundColor={hexToRGBA(passValue, 0.3)}
          lines={[
            {
              dataKey: 'NAV',
              color: mainColors.Fail,
              yAxisId: 'left',
            },
            {
              dataKey: secondaryDataKey,
              color: theme.palette.primary.main,
              yAxisId: 'right',
            },
          ]}
          yAxisLabel={{
            value: 'NAV',
            angle: -90,
            style: { textAnchor: 'middle', fill: theme.palette.grey[500] },

            position: 'left',
          }}
          yAxisDomain={[
            (dataMin: number) => dataMin - dataMin * 0.1,
            (dataMax: number) => dataMax + dataMax * 0.1,
          ]}
          yAxisTickFormatter={(val: any) => {
            return `${val / 1000000}m`;
          }}
          xAxisLabel={{
            value: 'Date',
            position: 'bottom',
            style: {
              fill: theme.palette.grey[500],
            },
          }}
          additionalYAxis={[
            {
              // ticks: secondaryTickValues,
              leftDataKey: 'globalExposure',
              yAxisId: 'right',
              orientation: 'right',
              domain: [
                (dataMin: any) => {
                  return dataMin - dataMin * 0.1;
                },
                (dataMax: any) => dataMax + dataMax * 0.1,
              ],
              label: {
                value: secondaryDataKey,
                angle: -90,
                position: 'right',
                style: {
                  textAnchor: 'middle',
                  fill: theme.palette.grey[500],
                },
              },
              tickFormatter: (val: any) => {
                return `${(val * 100).toFixed(1)}%`;
              },
            },
          ]}
          margin={{ top: 0, left: 10, right: 40, bottom: 50 }}
          tooltipFormatter={(value: any, name: any) => {
            let returnVal;
            if (name !== 'NAV') {
              returnVal = `${percentageToTwoDecimalPlaces(value)}`;
            } else {
              returnVal = addCommasToNumbers(value);
            }
            return returnVal;
          }}
        />
      </div>
    </Grid>
  );
}

export default DetailTimeSeries;

const TimeSeriesContainer = React.memo((props: IGenericLineChartProps) => {
  return <GenericLineChart {...props} />;
});
