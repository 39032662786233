import makeStyles from '@mui/styles/makeStyles';
import React, { Dispatch, useEffect, useState } from 'react';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../../types/components/componentTypes';
import GeneralComponentErrorShield from '../../../../general/GeneralComponentErrorShield';
import KiidsData from './data/kiids_data.json';
import KiidsEditorOverview from './overview/KiidsEditorOverview.component';
import KiidsViewer from './kiidsViewer/KiidsViewer.component';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import { useDispatch, useSelector } from 'react-redux';
import client from '../../../../../utilities/requestClient';
import { toggleControlBarShowing } from '../../../../../redux/ui/controlbar/actions';
import { isControlBarShowingSelector } from '../../../../../redux/ui/controlbar/selectors';
import BackToOverviewButton from './Buttons/BackToOverviewButton.component';
import Review from './review/Review.component';
import { RaptorTheme, mainColors } from '../../../../../styling/theme';
import { Grid } from '@mui/material';

// Will eventually be redundant as data returned from backend.
function filterKiidsData(
  data: any,
  fundId: string | null,
  isin: string | null
) {
  if (!fundId) return [];
  if (!isin) return [];
  const filteredData = data.filter((item: any) => {
    return item.fund_id === fundId && item.share_class === isin;
  });
  return filteredData;
}

interface KiidsContent {
  [key: number]: any;
}

function buildKiidsContent(data: DataObject): KiidsContent {
  if (!data) return {};
  if (!data.data.length) return {};
  const kiidsData = data.data[0];
  const kiidsContent: KiidsContent = {};
  kiidsData.forEach((value: any, index: number) => {
    kiidsContent[index] = value;
  });
  return kiidsContent;
}

function updateKiidsContent(
  filteredData: any[],
  setKiidsContent: Dispatch<any>
): void {
  if (!filteredData.length) return;
  setKiidsContent(filteredData);
}

const useStyles = makeStyles<RaptorTheme>(() => ({
  editorPageParent: {
    width: '100vw',
    display: 'flex',
    padding: '2rem',
    gap: '5rem',
    justifyContent: 'center',
    height: '100vh',
  },
  editorContainer: {
    maxWidth: '80rem',
    padding: '0 1rem',
    overflowY: 'auto',
    // overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.5rem',
    },
    '&::-webkit-scrollbar-track': {
      border: '0.2rem solid white',
      backgroundColor: mainColors.lightGrey,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.mainBlue,
      borderRadius: '1rem',
    },
  },
  viewerContainer: {
    // position: 'sticky',
    // top: 0
    // overflow: 'hidden',
    width: '100%',
  },
}));

const KiidEditorViewOnly: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = (props) => {
  const classes = useStyles();

  const [selectedKiid, setSelectedKiid] = useState({
    fundId: null,
    isin: null,
    lastEditedDate: null,
    fundName: null,
    version: null,
    documentLanguage: null,
  });
  const [changesMade, setChangesMade] = useState(false);
  const [canBePublished, setCanBePublished] = useState(false);
  const [kiidsContentData, setKiidsContentData] = useState<any[] | null>(null);
  const [reviewContentData, setReviewContentData] = useState<any[] | null>(
    null
  );
  const filteredData = filterKiidsData(
    KiidsData,
    selectedKiid.fundId,
    selectedKiid.isin
  );
  const [kiidsOverviewData, setKiidsOverviewData] = useState<any[] | null>(
    null
  );
  const [viewTrackedChanges, setViewTrackedChanges] = useState<boolean>(false);
  const [viewReview, setViewReview] = useState<boolean>(false);
  const [templateUpdates, setTemplateUpdates] = useState([]);
  const [numReviewPages, setNumReviewChanges] = useState<number>(0);
  const [trackedChangesVersions, setTrackedChangesVersions] = useState({
    target: null,
    comparison: null,
  });

  // Pull Kiids overview data.
  // const kiidsOverviewData = useFetchData({
  //     keyName: 'kiids_overview_data',
  //     url: 'kiids_generator_get_overview_data',
  //     makeFetch: true,
  // });
  const dispatch = useDispatch();
  const controlBarIsShowing = useSelector(isControlBarShowingSelector);

  const [kiidsContent, setKiidsContent] = useState(filteredData);
  const [reviewContent, setReviewContent] = useState(filteredData);

  // Pull Kiids content of the selected kiid.
  const requestClient = client();
  const requestKiidContentData = async () => {
    requestClient
      .get(
        `kiid_generator_get_kiid_data/${selectedKiid.fundId}/${selectedKiid.isin}/${selectedKiid.documentLanguage}`
      )
      .then((response) => {
        setKiidsContentData(response.data);
      });
  };

  const requestReviewDataSpecificDocument = async () => {
    // Note: Ideally we would do some refatcoring here to make this a bit cleaner but for now this is the best solution.
    // Get the current version number
    const latestversion = selectedKiid.version ? selectedKiid.version : '1.0.0';
    // Split the version number into an array
    const versionArray = latestversion ? latestversion.split('.') : [];
    // Get the major version number
    const publishedVersion = versionArray[0] + '.0.0';
    requestClient
      .get(
        `kiid_generator_get_review_changes/${selectedKiid.fundId}/${selectedKiid.isin}/${selectedKiid.version}/${publishedVersion}/${selectedKiid.documentLanguage}`
      )
      .then((response) => {
        setNumReviewChanges(response.data.num_pages);
        setReviewContentData(response.data.data);
      });
  };
  const requestReviewDataSpecificVersions = async () => {
    requestClient
      .get(
        `kiid_generator_get_review_changes/${selectedKiid.fundId}/${selectedKiid.isin}/${trackedChangesVersions.target}/${trackedChangesVersions.comparison}/${selectedKiid.documentLanguage}`
      )
      .then((response) => {
        setNumReviewChanges(response.data.num_pages);
        setReviewContentData(response.data.data);
      });
  };
  const requestReviewDataAll = async () => {
    requestClient.get('kiid_generator_get_review_changes/').then((response) => {
      setNumReviewChanges(response.data.num_pages);
      setReviewContentData(response.data.data);
    });
  };
  const requestKiidsOverviewData = async () => {
    requestClient.get('kiids_generator_get_overview_data').then((response) => {
      setKiidsOverviewData(response.data);
    });
  };
  useEffect(() => {
    if (selectedKiid.fundId !== null && !viewTrackedChanges && !viewReview) {
      requestKiidContentData();
      setKiidsOverviewData(null);
    } else if (
      selectedKiid.fundId !== null &&
      viewTrackedChanges &&
      !viewReview
    ) {
      requestReviewDataSpecificVersions();
      setKiidsOverviewData(null);
    } else if (
      selectedKiid.fundId !== null &&
      !viewTrackedChanges &&
      viewReview
    ) {
      requestReviewDataSpecificDocument();
      setKiidsOverviewData(null);
    } else if (
      selectedKiid.fundId === null &&
      !viewTrackedChanges &&
      viewReview
    ) {
      requestReviewDataAll();
      setKiidsOverviewData(null);
    } else {
      setKiidsContentData(null);
      requestKiidsOverviewData();
    }
  }, [selectedKiid]);

  useEffect(() => {
    // if (kiidsContentData && kiidsContentData.data.length) {
    if (kiidsContentData && kiidsContentData.length) {
      // const data = filterKiidsData(KiidsData, selectedKiid.fundId, selectedKiid.isin);
      const data = kiidsContentData;
      // Sort by the kiid index
      const sortedData = data.sort((a: any, b: any) =>
        a.kiid_index > b.kiid_index ? 1 : -1
      );
      setKiidsContent(sortedData);
    }
    // }, [selectedKiid.fundId]);
  }, [kiidsContentData]);

  useEffect(() => {
    if (reviewContentData && reviewContentData.length) {
      setReviewContent(reviewContentData);
    }
  }, [reviewContentData]);

  useEffect(() => {
    selectedKiid.fundId && !viewReview && !viewTrackedChanges
      ? dispatch(toggleControlBarShowing(true))
      : dispatch(toggleControlBarShowing(false));
  }, [selectedKiid]);

  return viewReview || viewTrackedChanges ? (
    <GeneralComponentErrorShield
      dataObjects={[
        {
          data: reviewContentData || [],
          isFetching: reviewContentData ? false : true,
          error: null,
        },
      ]}
      customLoadingMessages={['Loading Review Data']}
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        <div
          style={{
            // display: 'flex-column',
            // position: 'fixed'
            marginBottom: '1rem',
          }}
        >
          {controlBarIsShowing ? null : (
            <>
              <BackToOverviewButton
                backToOverview={() => {
                  setSelectedKiid({
                    fundId: null,
                    isin: null,
                    lastEditedDate: null,
                    fundName: null,
                    version: null,
                    documentLanguage: null,
                  });
                  dispatch(toggleControlBarShowing(false));
                  setViewTrackedChanges(false);
                  setViewReview(false);
                  setKiidsContentData(null);
                  setTemplateUpdates([]);
                }}
              />
            </>
          )}
        </div>
        <Review
          fundId={selectedKiid.fundId}
          fundName={selectedKiid.fundName}
          lastEditedDate={selectedKiid.lastEditedDate}
          isin={selectedKiid.isin}
          kiidsData={reviewContent}
          numPages={numReviewPages}
          viewTrackedChanges={viewTrackedChanges}
        />
      </Grid>
    </GeneralComponentErrorShield>
  ) : selectedKiid.fundId ? (
    <GeneralComponentErrorShield
      dataObjects={[
        {
          data: kiidsContentData || [],
          isFetching: kiidsContentData ? false : true,
          error: null,
        },
      ]}
      customLoadingMessages={['Loading KIID Data']}
    >
      <div className={classes.editorPageParent}>
        <div className={classes.viewerContainer}>
          <KiidsViewer
            setChangesMade={setChangesMade}
            changesMade={changesMade}
            fundId={selectedKiid.fundId}
            isin={selectedKiid.isin}
            kiidsData={kiidsContent}
            documentLanguage={selectedKiid.documentLanguage}
            lastEditedDate={selectedKiid.lastEditedDate}
            fundName={selectedKiid.fundName}
            canBePublished={canBePublished}
            setCanBePublished={setCanBePublished}
            templateUpdates={templateUpdates}
            version={selectedKiid.version}
          />
        </div>
      </div>
    </GeneralComponentErrorShield>
  ) : (
    <GeneralComponentErrorShield
      dataObjects={[
        {
          data: kiidsOverviewData || [],
          isFetching: kiidsOverviewData ? false : true,
          error: null,
        },
      ]}
      customLoadingMessages={['Loading KIID Data']}
    >
      <KiidsEditorOverview
        data={kiidsOverviewData || []}
        setSelectedKiid={setSelectedKiid}
        setCanBePublished={setCanBePublished}
        setViewTrackedChanges={setViewTrackedChanges}
        setViewReview={setViewReview}
        setTrackedChangesVersions={setTrackedChangesVersions}
      />
    </GeneralComponentErrorShield>
  );
};

export default KiidEditorViewOnly;
