import React from 'react';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { percentageToTwoDecimalPlacesNoHundred } from '../../../../../utilities/numberFormatters';

interface ValueAtRiskPositionLevelProps {
  data: DataObject;
}

interface ValueAtRiskPositionLevelData {
  asset: string;
  countryIndex: string;
  sectorIndex: string;
  strategyIndex: string;
  exposure: number;
  dailyReturn: number;
  monthlyReturn: number;
  volatility: number;
  oneDayVar: number;
  monthlyVar: number;
  gmid: number;
}

const buildTableData = (
  inputData: DataObject
): ValueAtRiskPositionLevelData[] => {
  if (!inputData.data.length) return [];
  if (!('positions_data' in inputData.data[0])) return [];
  const varData = inputData.data[0].positions_data;
  const tableData: ValueAtRiskPositionLevelData[] = [];

  varData.forEach((value: any) => {
    tableData.push({
      asset: value.gm_name,
      countryIndex: value.location,
      sectorIndex: value.sector,
      strategyIndex: value.strategy,
      exposure: value.bc_exposure,
      dailyReturn: value.one_day_pct_return,
      monthlyReturn: value.one_month_pct_return,
      volatility: value['Daily Volatility (% NAV)'],
      oneDayVar: value.one_day_VaR,
      monthlyVar: value.monthly_VaR,
      gmid: value.gm_id_old,
    });
  });

  return tableData;
};

export const ValueAtRiskPositionLevelTableColumns: CustomColumn<ValueAtRiskPositionLevelData>[] =
  [
    {
      title: 'Asset',
      field: 'asset',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Country Index',
      field: 'countryIndex',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Sector Index',
      field: 'sectorIndex',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Strategy Index',
      field: 'strategyIndex',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Exposure',
      field: 'exposure',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: ValueAtRiskPositionLevelData) =>
        percentageToTwoDecimalPlacesNoHundred(rowData.exposure),
    },
    {
      title: 'Monthly Return',
      field: 'monthlyReturn',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: ValueAtRiskPositionLevelData) =>
        percentageToTwoDecimalPlacesNoHundred(rowData.monthlyReturn),
    },

    {
      title: 'Monthly Quantile Risk',
      field: 'monthlyVar',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: ValueAtRiskPositionLevelData) =>
        percentageToTwoDecimalPlacesNoHundred(rowData.monthlyVar),
    },
  ];

const ValueAtRiskPositionLevel: React.FC<ValueAtRiskPositionLevelProps> = ({
  data,
}) => {
  const tableData = buildTableData(data);

  return (
    <GridItem card xs={12} style={{ padding: 8 }}>
      <CustomTable<ValueAtRiskPositionLevelData>
        columns={ValueAtRiskPositionLevelTableColumns}
        showToolbar
        data={tableData}
        title={'Position Level Data'}
        options={{
          paging: false,
          search: true,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default ValueAtRiskPositionLevel;
