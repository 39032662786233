import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  activeDateRangeEndSelector,
  activeDateRangeStartSelector,
  activeSectionSelector,
  createSectionFundIdsSelector,
  createSectionFundNamesSelector,
} from '../../../../redux/pages/selectors';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import client, { BASE_URL } from '../../../../utilities/requestClient';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import RaptorFileDownloadButton, {
  RaptorFileDownloadButtonStatus,
} from '../../../feedback/RaptorFileDownloadButton.component';

interface ReportButtonProps {
  fundId: string;
  startDate: string;
  endDate: string;
  fundName: string;
}

function openInNewTab(url: string) {
  return async () => {
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
      return true;
    } else {
      return false;
    }
  };
}

const PAReportButton: React.FC<ReportButtonProps> = ({
  fundId,
  startDate,
  endDate,
  fundName,
}) => {
  const [status, setStatus] = useState<RaptorFileDownloadButtonStatus>('idle');

  const requestClient = client();

  // For the moment we include all available pages
  // In future we may be able to include or exclude any pages
  const defaultPages = [
    'cover',
    'performance_diagnostics',
    'sub_fund_performance',
    'asset_allocation_performance',
    'sub_fund_cumulative_breakdown',
    'sub_fund_historical',
    'stock_selection_performance',
  ];

  function generateReport() {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object.
    formData.append('fund_id', fundId);
    formData.append('fund_name', fundName);
    formData.append('start_date', startDate);
    formData.append('end_date', endDate);
    formData.append('pages_to_include', JSON.stringify(defaultPages));

    //   requestClient
    //     .post('generate_performance_attribution_report', formData, {
    //       headers: {
    //         'content-type': 'multipart/form-data',
    //       },
    //     })
    //     .then((response) => {
    //       const pdfDoc = response.data;
    //       fileDownload(
    //         pdfDoc,
    //         `Performance_Attribution_Report__${fundName.replaceAll(
    //           ' ',
    //           '_'
    //         )}_(${startDate}_to_${endDate}).pdf`
    //       );
    //       setStatus('success');
    //     })
    //     .catch((error) => {
    //       setStatus('error');
    //     });
    // }

    const openReport = openInNewTab(
      `${BASE_URL}generate_performance_attribution_report/${fundId}/${startDate}/${endDate}`
    );
    openReport();
  }

  return (
    <div
      style={{
        display: 'flex',
        margin: 'auto',
        maxWidth: '20rem',
      }}
      onClick={() => {
        if (status === 'idle') {
          setStatus('loading');
          generateReport();
        }
      }}
    >
      <RaptorFileDownloadButton status={status} />
    </div>
  );
};

interface PAReportTableData {
  index: number;
  internalFundName: string;
  fundName: string;
}

const buildDetailColumns = (
  startDate: string,
  endDate: string,
  clientName: string | null
): CustomColumn<PAReportTableData>[] => {
  const detailColumns: CustomColumn<PAReportTableData>[] = [
    {
      title: 'Internal Fund Name',
      field: 'internalFundName',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData) =>
        clientName?.includes('mersenne')
          ? `Fund ${rowData.index}`
          : rowData.internalFundName,
    },
    {
      title: 'Fund Name',
      field: 'fundName',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Generate Report',
      field: '',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData) => (
        <PAReportButton
          fundId={rowData.internalFundName}
          startDate={startDate}
          endDate={endDate}
          fundName={rowData.fundName}
        />
      ),
    },
  ];
  return detailColumns;
};

function buildPAReportTableData(
  fundIds: string[],
  fundNames: string[]
): PAReportTableData[] {
  const tableData: PAReportTableData[] = [];

  for (let i = 0; i < fundIds.length; i++) {
    tableData.push({
      index: i + 1,
      internalFundName: fundIds[i],
      fundName: fundNames[i],
    });
  }
  return tableData;
}

const ReportGenerator: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = () => {
  const clientName = useSelector(clientNameSelector);
  const fromDate = useSelector(activeDateRangeStartSelector);
  const toDate = useSelector(activeDateRangeEndSelector);
  const activeSection = useSelector(activeSectionSelector);
  const fundIdsSelector = createSectionFundIdsSelector(activeSection || '');
  const fundNamesSelector = createSectionFundNamesSelector(activeSection || '');
  const fundIds = useSelector(fundIdsSelector) || [];
  const fundNames = useSelector(fundNamesSelector) || [];

  const dataPreparedForTable = buildPAReportTableData(fundIds, fundNames);

  const detailColumns = buildDetailColumns(fromDate, toDate, clientName);

  return (
    <GeneralComponentErrorShield dataObjects={[]}>
      <GridItem card xs={12}>
        <CustomTable<PAReportTableData>
          showToolbar
          columns={detailColumns}
          data={dataPreparedForTable}
          options={{
            paging: false,
            search: true,
            exportButton: false,
            rowStyle: {
              padding: '1rem',
            },
          }}
        />
      </GridItem>
    </GeneralComponentErrorShield>
  );
};

export default ReportGenerator;
