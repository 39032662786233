import { Dispatch, FC } from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import { mainColors } from '../../../../../../styling/theme';
import CommentEditorButton from '../Buttons/CommentEditorButton.component';
import { Typography } from '@mui/material';

interface PageBreakTileProps {
  tileType: string;
  fundId: string | null;
  index: number;
  setKiidsContent: Dispatch<any>;
  kiidsContent: any[];
  setChangesMade: Dispatch<any>;
  shareClass: string;
  commentId: string;
  hasUnresolvedComments: boolean;
  setWasFieldTypeChanged: Dispatch<any>;
  setEditorTileComponents: Dispatch<any>;
  editorTileComponents: any[];
  setWasNewFieldAdded: Dispatch<any>;
}

const PageBreakTile: FC<PageBreakTileProps> = (props) => {
  // const data = localStorage.getItem(TEXT_EDITOR_ITEM);
  // const initialState = EditorState.createWithContent(ContentState.createFromText(props.text || ''));
  // const { setKiidsContent, kiidsContent, index, setChangesMade } = props;

  // const blocksFromHTML = convertFromHTML(props.text || '');
  // const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks);
  // // const initialState = EditorState.createWithContent(contentState, compositeDecorator);
  // const initialState = EditorState.createWithContent(contentState);
  // // const [editorState, setEditorState] = React.useState<EditorState>(initialState);
  // const [editorState, setEditorState] = React.useState<EditorState>(initialState);
  // // const [boldIsActive, setBoldIsActive] = React.useState<boolean>(false);
  // // const [underlineIsActive, setunderlineIsActive] = React.useState<boolean>(false);
  // // const [italicIsActive, setitalicIsActive] = React.useState<boolean>(false);
  // // Case for when the field is a template.
  // // useEffect(() => {
  // //     if (props.text?.startsWith('{{') && props.text.endsWith('}}')) {
  // //         console.log("Setting the background color", props.text, blocksFromHTML.contentBlocks.entries())
  // //         setEditorState(RichUtils.toggleInlineStyle(editorState, 'TEMPLATE'));
  // //     }
  // // }, [props.text])

  // const handleTextChange = (updatedEditorState: EditorState) => {
  //   setEditorState(updatedEditorState);
  //   let htmlText = stateToHTML(updatedEditorState.getCurrentContent());
  //   const style = updatedEditorState.getCurrentInlineStyle().toArray()
  //   console.log("HERE IS THE TEXT OUT HTML", htmlText, updatedEditorState.getCurrentContent(), style);
  //   htmlText = htmlText.replace("<p>", "").replace("</p>", "").replace("<strong>", "<b>").replace("</strong>", "</b>").replace("<em>", "<i>").replace("</em>", "</i>");
  //   const allContent = kiidsContent;
  //   const currentContentAtIndex = kiidsContent[index];
  //   console.log("HERE IS THE TEXT OUT BEFORE", allContent[index], currentContentAtIndex);
  //   // Indicate if a change has been made to enable saving.
  //   if (currentContentAtIndex.content !== htmlText) {
  //     setChangesMade(true);
  //   }
  //   // Update the content at the specific index.
  //   currentContentAtIndex.content = htmlText;
  //   // Now replace in the overall array
  //   allContent[index] = currentContentAtIndex;

  //   console.log("HERE IS THE TEXT OUT AFTER", allContent[index], currentContentAtIndex);
  //   setKiidsContent(allContent);

  // }

  // const handleKeyCommand = (command: DraftEditorCommand) => {
  //   const newState = RichUtils.handleKeyCommand(editorState, command);
  //   if (newState) {
  //     setEditorState(newState);
  //     return "handled";
  //   }
  //   return "not-handled";
  // };

  // const handleTogggleClick = (e: React.MouseEvent, inlineStyle: string) => {
  //   e.preventDefault();
  //   setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  // };

  // const handleBlockClick = (e: React.MouseEvent, blockType: string) => {
  //   e.preventDefault();
  //   setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  // };

  return (
    <GridItem
      xs={12}
      card
      style={{
        marginBottom: '1rem',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          style={{ marginLeft: '0.5rem', marginTop: '0.5rem' }}
          variant="h3"
        >
          Page Break
        </Typography>
        <div style={{ display: 'flex', marginTop: '0.5rem' }}>
          {/* <ChangeFieldTypeButton
            fieldType={props.tileType}
            setKiidsContent={props.setKiidsContent}
            kiidsContent={props.kiidsContent}
            index={props.index}
            setChangesMade={props.setChangesMade}
            setWasFieldTypeChanged={props.setWasFieldTypeChanged}
          /> */}
          <CommentEditorButton
            fundId={props.fundId}
            shareClass={props.shareClass}
            commentId={props.commentId}
            kidIndex={props.index}
            hasUnresolvedComments={props.hasUnresolvedComments}
            setKiidsContent={props.setKiidsContent}
            kiidsContent={props.kiidsContent}
          />
          {/* <RemoveFieldButton
            index={props.index}
            setKiidsContent={props.setKiidsContent}
            kiidsContent={props.kiidsContent}
            setChangesMade={props.setChangesMade}
            setWasFieldTypeChanged={props.setWasFieldTypeChanged}
            setWasNewFieldAdded={props.setWasNewFieldAdded}
            setEditorTileComponents={props.setEditorTileComponents}
            editorTileComponents={props.editorTileComponents}
          /> */}
        </div>
      </div>
      <div
        style={{
          padding: '1rem',
          fontSize: '1.5rem',
          minHeight: '10rem',
          justifyContent: 'space-around',
          display: 'flex',
        }}
      >
        <InsertPageBreakIcon
          sx={{ color: mainColors.mainBlue, fontSize: '5rem' }}
        />
      </div>
    </GridItem>
  );
};

export default PageBreakTile;
