import makeStyles from '@mui/styles/makeStyles';
import { scaleSequential } from 'd3-scale';
import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import useFetchData from '../../../../../hooks/useFetchData';
import { greys, mainColors } from '../../../../../styling/theme';
import CalendarHeatMap from '../../../../calendar/CalendarHeatMap';
import RaptorLoading from '../../../../feedback/RaptorLoading';
import DisplayAreaCenteredWrapper from '../../../../layout/utilities/displayAreaWrapper';
import { RaptorTheme } from '../../../../../styling/theme';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

interface calendarViewProps {
  startDate: string;
  endDate: string;
  fundId: string;
  setBackArrowDisabled: Dispatch<SetStateAction<boolean>>;
  moveForward: () => void;
}

interface calendarProps {
  date: string;
  fundId: string;
  open: boolean;
  changeOpen: Dispatch<SetStateAction<boolean>>;
}

interface calendarPopupProps {
  date: string;
  fundId: string;
}

// const colorScale = d3.scaleOrdinal().domain(heatMapValues).range([mainColors.Pass, mainColors.Alert, mainColors.Fail]);
const interpolator = (value: number) => {
  const valueRounded = value.toFixed(2);
  switch (valueRounded) {
    case '0.00':
      return mainColors.Pass;
    case '0.25':
      return mainColors.mainBlue;
    case '0.50':
      return mainColors.Alert;
    case '0.75':
      return mainColors.Fail;
    default:
      return mainColors.mainBlue;
  }
};
const colorScale = scaleSequential().domain([0, 1]).interpolator(interpolator);

function prepareCalendarData(data: any): any {
  // Create an object for storing the processed data
  const processedData: any[] = [];
  const dates = Object.keys(data);
  dates.sort();
  // for (const key in data) {
  //     processedData.push({
  //         day: key,
  //         value: getProcessLevels(data[key].status.status),
  //         missing_files: 'missing_files' in data[key] ? data[key].missing_files : null,
  //     })

  // }
  const date2DaysAgo = new Date();
  date2DaysAgo.setDate(date2DaysAgo.getDate() - 2);
  dates.forEach((date: string) => {
    if (data[date].status.data_expected) {
      processedData.push({
        day: date,
        value:
          new Date(date) < date2DaysAgo &&
          data[date].status.status === 'PENDING'
            ? getProcessLevels('MISSING')
            : getProcessLevels(data[date].status.status),
        missing_files:
          'missing_files' in data[date] ? data[date].missing_files : null,
      });
    }
  });
  return processedData;
}

function getProcessLevels(level: string) {
  switch (level) {
    case 'DONE':
      return 1;
    case 'HOLIDAY':
      return 2;
    case 'PENDING':
      return 3;
    case 'MISSING':
      return 4;
    default:
      return 5;
  }
}

interface CalendarHeatMapItemType {
  day: string;
  value: number;
  missing_files?: any;
}

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  button: {
    backgroundColor: mainColors.mainBlue,
    color: 'white',
    width: '100%',
    maxWidth: '20rem',
    '&:hover': {
      backgroundColor: mainColors.mainBlue_lightAlt,
    },
  },
  calendar: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    marginTop: 0,
    marginBottom: '1rem',
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  tooltip: {
    zIndex: 3000,
  },
  navButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function CalendarPopup(props: calendarPopupProps): ReactElement {
  // This will be replaced eventually with a route call to get_latest_fund_processing_status/<fund_id>
  // const calenderTimeSeries = getCalendarDummyData(props.fundId);
  // const calendarData = prepareCalendarData(calenderTimeSeries);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  // Get the one year before position date
  const previousDate = new Date(props.date);
  previousDate.setFullYear(previousDate.getFullYear() - 1);
  const start2022 = new Date('01/01/2022');
  const yearBefore = previousDate < start2022 ? start2022 : previousDate;
  const dateString = yearBefore.toISOString().slice(0, 10);

  return (
    <div id="popupButton">
      <Button onClick={() => setOpen(true)} className={classes.button}>
        {props.date}
      </Button>
      {open && (
        <Calendar
          date={props.date}
          fundId={props.fundId}
          open={open}
          changeOpen={setOpen}
        />
      )}
    </div>
  );
}

function Calendar(props: calendarProps): ReactElement {
  // This will be replaced eventually with a route call to get_latest_fund_processing_status/<fund_id>
  // const calenderTimeSeries = getCalendarDummyData(props.fundId);
  // const calendarData = prepareCalendarData(calenderTimeSeries);
  // const [open, setOpen] = useState(true);
  const { open, changeOpen } = props;
  const closeModal = () => changeOpen(false);
  const classes = useStyles();

  const tooltipFormatter = (value: number) => {
    switch (value) {
      case 1:
        return 'Data has been processed';
      case 2:
        return 'Holiday';
      case 3:
        return 'Pending';
      default:
        return 'No data expected';
    }
  };

  // Get the one year before position date
  const previousDate = new Date(props.date);
  previousDate.setFullYear(previousDate.getFullYear() - 1);
  const start2022 = new Date('01/01/2022');
  const yearBefore = previousDate < start2022 ? start2022 : previousDate;
  // yearBefore.setMonth(yearBefore.getMonth() + 1);
  const dateString = yearBefore.toISOString().slice(0, 10);
  const [startDate, setStartDate] = useState(dateString);
  const [endDate, setEndDate] = useState(props.date);
  const navigateForward = () => {
    const yearAfter = new Date(endDate);
    yearAfter.setFullYear(yearAfter.getFullYear() + 1);
    const positionDate = new Date(props.date);
    if (yearAfter <= positionDate) {
      // yearBefore.setMonth(yearBefore.getMonth() + 1);
      const dateString = yearAfter.toISOString().slice(0, 10);
      setStartDate(endDate);
      setEndDate(dateString);
      setBackArrowDisabled(false);
    }
  };
  const navigateBackward = () => {
    const yearBefore = new Date(startDate);
    yearBefore.setFullYear(yearBefore.getFullYear() + -1);
    // yearBefore.setMonth(yearBefore.getMonth() + 1);
    const dateString = yearBefore.toISOString().slice(0, 10);
    setEndDate(startDate);
    setStartDate(dateString);
  };

  const forwardArrowDisabled = endDate !== props.date ? false : true;
  const [backArrowDisabled, setBackArrowDisabled] = useState(true);
  return (
    <>
      <Dialog
        onClose={closeModal}
        open={open}
        maxWidth="lg"
        fullWidth
        disableEnforceFocus
        id="test"
      >
        {
          <DialogContent id="calendar">
            <Typography variant="h2" className={classes.header}>
              Process Status Overview
            </Typography>

            <div id="calendar">
              <div className={classes.navButtons}>
                <IconButton
                  onClick={navigateBackward}
                  disabled={backArrowDisabled}
                >
                  <ArrowBack
                    style={{
                      color: backArrowDisabled
                        ? greys.grey300
                        : mainColors.mainBlue,
                    }}
                  />
                </IconButton>
                <IconButton
                  onClick={navigateForward}
                  disabled={forwardArrowDisabled}
                >
                  <ArrowForward
                    style={{
                      color: forwardArrowDisabled
                        ? greys.grey300
                        : mainColors.mainBlue,
                    }}
                  />
                </IconButton>
              </div>
              <CalendarView
                fundId={props.fundId}
                startDate={startDate}
                endDate={endDate}
                setBackArrowDisabled={setBackArrowDisabled}
                moveForward={navigateForward}
              />
            </div>
          </DialogContent>
        }
      </Dialog>
    </>
  );
}

function CalendarView(props: calendarViewProps): ReactElement {
  const { setBackArrowDisabled, moveForward } = props;
  const tooltipFormatter = (value: number) => {
    switch (value) {
      case 1:
        return 'Data has been processed';
      case 2:
        return 'Holiday';
      case 3:
        return 'Pending';
      case 4:
        return 'No Files Received';
      default:
        return 'No data expected.';
    }
  };

  const filesData = useFetchData({
    url: `/get_latest_fund_processing_status/${props.fundId}/${props.startDate}/${props.endDate}`,
    keyName: `${props.fundId}_${props.startDate}_${props.endDate}_aifmd_aggregate_data`,
    makeFetch: props.startDate !== undefined && props.endDate !== undefined,
  });

  const classes = useStyles();
  const ready = filesData ? (filesData.isFetching ? false : true) : false;
  // Disable the back arrow if no data is returned
  if (ready && !Object.keys(filesData.data[0]).length) {
    moveForward();
    setBackArrowDisabled(true);
  }
  return (
    <>
      {ready ? (
        Object.keys(filesData.data[0]).length ? (
          <CalendarHeatMap<CalendarHeatMapItemType>
            data={prepareCalendarData(filesData.data[0])}
            customD3ColorScale={colorScale}
            cellShape="square"
            tooltipPlacement="topRight"
            valueFn={tooltipFormatter}
            defaultColor={greys.grey400}
            tooltipClassName={classes.tooltip}
          />
        ) : (
          <DisplayAreaCenteredWrapper noMinHeight>
            <Typography variant="h1">No Data Available.</Typography>
          </DisplayAreaCenteredWrapper>
        )
      ) : (
        <RaptorLoading />
      )}
    </>
  );
}

export default CalendarPopup;
