import React, { Dispatch, FC, useEffect, useState } from 'react';
import MetricEditorTile from './MetricEditorTile.component';
import AnnexQuestionEditorTile from './AnnexQuestionTile.component';
import AnnexObjectivesEditorTile from './AnnexObjectivesTile.component';
import AnnexInvestmentTableTile from './AnnexInvestmentTableTile.component';
import TableEditorTile from './TableEditorTile.component';
import { raidrClient } from '../../../../../../utilities/requestClient';

interface DelegatedActsEditorColumnProps {
  delegatedActsData: any[];
  setDelegatedActsData: Dispatch<any>;
  setHaveChangesBeenMade: Dispatch<any>;
}

const DelegatedActsEditorColumn: FC<DelegatedActsEditorColumnProps> = (
  props
) => {
  const { delegatedActsData, setDelegatedActsData, setHaveChangesBeenMade } =
    props;

  // Pull the risksystem calculated figures
  const requestClient = raidrClient();
  const [risksystemData, setRisksystemData] = useState<any[] | null>(null);
  const getDelegeatedActsData = async () => {
    requestClient.get('/raidr/sfdr/gem101/2022-12-30').then((response) => {
      setRisksystemData(response.data);
    });
  };
  useEffect(() => {
    getDelegeatedActsData();
  }, []);

  return (
    <>
      {delegatedActsData.map((value: any, index: number) => {
        if (value.tag.includes('metric')) {
          return (
            <MetricEditorTile
              key={`editor${index}`}
              delegatedActsElement={value.content}
              delegatedActsData={delegatedActsData}
              setDelegatedActsData={setDelegatedActsData}
              index={index}
              setHaveChangesBeenMade={setHaveChangesBeenMade}
            />
          );
        } else if (value.tag.startsWith('a4_question')) {
          return (
            <AnnexQuestionEditorTile
              key={`editor${index}`}
              delegatedActsElement={value.content}
              delegatedActsData={delegatedActsData}
              setDelegatedActsData={setDelegatedActsData}
              index={index}
              setHaveChangesBeenMade={setHaveChangesBeenMade}
            />
          );
        } else if (value.tag.startsWith('a4_objective_table')) {
          return (
            <AnnexObjectivesEditorTile
              key={`editor${index}`}
              delegatedActsElement={value.content}
              delegatedActsData={delegatedActsData}
              setDelegatedActsData={setDelegatedActsData}
              index={index}
              setHaveChangesBeenMade={setHaveChangesBeenMade}
            />
          );
        } else if (value.tag.startsWith('a4_investment_table')) {
          return (
            <AnnexInvestmentTableTile
              key={`editor${index}`}
              delegatedActsElement={value.content}
              delegatedActsData={delegatedActsData}
              setDelegatedActsData={setDelegatedActsData}
              index={index}
              setHaveChangesBeenMade={setHaveChangesBeenMade}
              risksystemData={risksystemData}
            />
          );
        } else if (value.tag.includes('table')) {
          return (
            <TableEditorTile
              key={`editor${index}`}
              delegatedActsElement={value.content}
              delegatedActsData={delegatedActsData}
              setDelegatedActsData={setDelegatedActsData}
              index={index}
              setHaveChangesBeenMade={setHaveChangesBeenMade}
              risksystemData={risksystemData}
              tag={value.tag}
            />
          );
        }

        return null;
      })}
    </>
  );
};

export default DelegatedActsEditorColumn;
