import React from 'react';
import { greys, mainColors } from '../../../../../styling/theme';
import AdultEntertainment from '../../../../../images/adult_entertainment.svg';
import FossilFuels from '../../../../../images/fossil_fuels.svg';
import Gambling from '../../../../../images/gambling.svg';
import LabourStandards from '../../../../../images/human_rights.svg';
import Tobacco from '../../../../../images/tobacco.svg';
import Weapons from '../../../../../images/weapons.svg';
import { percentageToTwoDecimalPlaces } from '../../../../../utilities/numberFormatters';
import makeStyles from '@mui/styles/makeStyles';
import { scaleLinear } from 'd3-scale';
import { Typography } from '@mui/material';

interface BusinessInvolvementChartProps {
  chartType: string;
  value: number;
}

// Function for deciding which image should be displayed.
function getSvgImage(chartType: string) {
  switch (chartType) {
    case 'Adult Entertainment':
      return AdultEntertainment;
    case 'Fossil Fuels':
      return FossilFuels;
    case 'Gambling':
      return Gambling;
    case 'Labour Standards':
      return LabourStandards;
    case 'Tobacco':
      return Tobacco;
    case 'Weapons & Firearms':
      return Weapons;
    default:
      return AdultEntertainment;
  }
}

const BusinessInvovementImageRender = (
  props: BusinessInvolvementChartProps
) => {
  const { chartType, value } = props;
  const svgImage = getSvgImage(chartType);
  return (
    <img
      alt={`${chartType} logo`}
      style={{
        marginRight: '.5rem',
        height: ((chartType) => {
          // some logos need to be different heights
          switch (chartType) {
            case 'Adult Entertainment':
              return '15rem';
            default:
              return '15rem';
          }
        })(chartType),
        opacity: 0.4 + 0.6 * (1 - value),
        alignSelf: 'center',
      }}
      src={svgImage}
    />
  );
};

const useStyles = makeStyles(() => ({
  imageGrid: {
    width: '66%',
    display: 'flex',
  },
  individualImage: {
    // width: '33%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

function getGradinetColor(
  color1: string,
  color2: string,
  nSteps: number
): string[] {
  // Convert both values to rgb.
  const rgbColor1 = hexToRgb(color1);
  const rgbColor2 = hexToRgb(color2);
  if (rgbColor1 === null || rgbColor2 === null) return [greys.grey400];

  // create a list for storing the gradient.
  const gradient = [color1];
  for (let i = 1; i < nSteps; i++) {
    const currentRgb = {
      r: rgbColor1.r + (i / (nSteps - 1)) * (rgbColor2.r - rgbColor1.r),
      g: rgbColor1.g + (i / (nSteps - 1)) * (rgbColor2.g - rgbColor1.g),
      b: rgbColor1.b + (i / (nSteps - 1)) * (rgbColor2.b - rgbColor1.b),
    };
    const currentHex = rgbToHex(currentRgb);
    gradient.push(currentHex);
  }
  // return the gradient value as a hex color
  return gradient;
}
// // Function to convert rgb to Hex
function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length === 1
    ? '0'.concat(hex.substring(0, 1))
    : hex.substring(0, 2);
}

function rgbToHex(color: any) {
  return '#'.concat(
    componentToHex(color.r),
    componentToHex(color.g),
    componentToHex(color.b)
  );
}

// Function to  convert HEx colour to rgb
function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

const BusinessInvolvementChart = (props: BusinessInvolvementChartProps) => {
  const { chartType, value } = props;
  const classes = useStyles();
  // Create a colour gradient for colouring the percentage value.
  // const gradient = getGradinetColor(mainColors.Fail_darker, mainColors.Fail, 25).concat(getGradinetColor(mainColors.Alert_darker, mainColors.Alert, 50), getGradinetColor(mainColors.Pass_darker, mainColors.Pass, 25));
  // Split the gradients into pass, alert and fail for nicer colors
  const failGradient = scaleLinear()
    .domain([0, 0.25])
    .range([
      mainColors.Fail_darker as unknown as number,
      mainColors.Fail as unknown as number,
    ]);
  const alertGradient = scaleLinear()
    .domain([0.25, 0.75])
    .range([
      mainColors.Alert_darker as unknown as number,
      mainColors.Alert as unknown as number,
    ]);
  const passGradient = scaleLinear()
    .domain([0.75, 1])
    .range([
      mainColors.Pass_darker as unknown as number,
      mainColors.Pass as unknown as number,
    ]);
  const getTextColor = (value: number) => {
    if (value <= 0.25) {
      return failGradient(value);
    } else if (value <= 0.75) {
      return alertGradient(value);
    } else {
      return passGradient(value);
    }
  };
  const textColor = getTextColor(value);
  return (
    <div>
      <div className={classes.individualImage}>
        <BusinessInvovementImageRender chartType={chartType} value={value} />
      </div>
      <Typography variant="h2" align="center">
        {chartType}
      </Typography>
      <Typography
        variant="h2"
        align="center"
        style={{ color: textColor as unknown as string, fontWeight: 600 }}
      >
        {chartType === 'Labour Standards'
          ? percentageToTwoDecimalPlaces(value)
          : percentageToTwoDecimalPlaces(1 - value)}
      </Typography>
    </div>
  );
};

export default React.memo(BusinessInvolvementChart);
