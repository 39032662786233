import { Status } from '../types/redux/data/dataTypes';

export function specifyStatus(input: string): Status {
  if (!input || typeof input !== 'string') return Status.NA;
  switch (input.toLowerCase()) {
    case 'pass - alert':
    case 'Pass - Alert':
    case 'Pass-Alert':
    case 'pass-alert':
    case 'alert':
      return Status.Alert;
    case 'pass':
    case 'Pass':
      return Status.Pass;
    case 'fail':
    case 'Fail':
      return Status.Fail;
    case 'n/a':
    case 'N/A':
    case 'na':
      return Status.NA;
    case 'altered position':
      return Status.alteredPosition;
    case 'no change':
      return Status.noChange;
    case 'new position':
      return Status.newPosition;
    case 'multiple positions':
      return Status.multiplePositions;
    case 'removed position':
      return Status.removedPosition;
    default:
      return Status.Fail;
  }
}
