import React, { Dispatch, FC } from 'react';
import AnnexQuestionEditorTile from './AnnexQuestionTile.component';
import AnnexObjectivesEditorTile from './AnnexObjectivesTile.component';
import TableEditorTile from './TableEditorTile.component';
import { raidrClient } from '../../../../../../utilities/requestClient';
import TextEditorTile from './TextEditorTile.component';
import LineChartEditorTile from './LineChartEditorTile.component';

interface FactSheetsEditorColumnProps {
  factSheetsData: any[];
  setFactSheetsData: Dispatch<any>;
  setHaveChangesBeenMade: Dispatch<any>;
}

const FactSheetsEditorColumn: FC<FactSheetsEditorColumnProps> = (props) => {
  const { factSheetsData, setFactSheetsData, setHaveChangesBeenMade } = props;

  // Pull the risksystem calculated figures
  const requestClient = raidrClient();
  // const [risksystemData, setRisksystemData] = useState<any[] | null>(null);
  // const getFactSheetsData = async () => {
  //   requestClient.get('/raidr/sfdr/gem101/2022-12-30').then((response) => {
  //     setRisksystemData(response.data);
  //   });
  // };
  // useEffect(() => {
  //   getFactSheetsData();
  // }, []);

  return (
    <>
      {factSheetsData.map((value: any, index: number) => {
        if (value.tag.includes('header') || value.tag.includes('text')) {
          return (
            <TextEditorTile
              key={`editor${index}`}
              factSheetElement={value.content}
              factsSheetsData={factSheetsData}
              setfactSheetsData={setFactSheetsData}
              index={index}
              setHaveChangesBeenMade={setHaveChangesBeenMade}
            />
          );
        } else if (value.tag.startsWith('a4_question')) {
          return (
            <AnnexQuestionEditorTile
              key={`editor${index}`}
              delegatedActsElement={value.content}
              delegatedActsData={factSheetsData}
              setDelegatedActsData={setFactSheetsData}
              index={index}
              setHaveChangesBeenMade={setHaveChangesBeenMade}
            />
          );
        } else if (value.tag.startsWith('a4_objective_table')) {
          return (
            <AnnexObjectivesEditorTile
              key={`editor${index}`}
              delegatedActsElement={value.content}
              delegatedActsData={factSheetsData}
              setDelegatedActsData={setFactSheetsData}
              index={index}
              setHaveChangesBeenMade={setHaveChangesBeenMade}
            />
          );
          // } else if (value.tag.startsWith('a4_investment_table')) {
          //   return (
          //     <AnnexInvestmentTableTile
          //       key={`editor${index}`}
          //       delegatedActsElement={value.content}
          //       delegatedActsData={delegatedActsData}
          //       setDelegatedActsData={setFactSheetsData}
          //       index={index}
          //       setHaveChangesBeenMade={setHaveChangesBeenMade}
          //       risksystemData={risksystemData}
          //     />
          //   );
        } else if (value.tag.includes('table')) {
          return (
            <TableEditorTile
              key={`editor${index}`}
              factSheetElement={value.content}
              factSheetsData={factSheetsData}
              setFactSheetsData={setFactSheetsData}
              index={index}
              setHaveChangesBeenMade={setHaveChangesBeenMade}
              tag={value.tag}
            />
          );
        } else if (value.tag.includes('fund_performance_chart')) {
          return (
            <LineChartEditorTile
              key={`editor${index}`}
              factSheetElement={value.content}
              factSheetsData={factSheetsData}
              setFactSheetsData={setFactSheetsData}
              index={index}
              setHaveChangesBeenMade={setHaveChangesBeenMade}
              tag={value.tag}
            />
          );
        }

        return null;
      })}
    </>
  );
};

export default FactSheetsEditorColumn;
