import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Checkbox,
  createFilterOptions,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { HTMLAttributes, useState } from 'react';
import clsx from 'clsx';
import { greys } from '../../styling/theme';

const useStyles = makeStyles(() => ({
  option: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: greys.grey200
    }
  },
  selectedOption: {
    backgroundColor: greys.grey100
  },
}));

interface RaptorMultiSelectOption {
  label: string;
  value: string;
}

interface RaptorMultiSelectProps {
  items: RaptorMultiSelectOption[];
  label: string;
  placeholder: string;
  selectAllLabel: string;
  noOptionsText: string;
  limitTags: number;
  onChange: (selectedOptions: RaptorMultiSelectOption[]) => void;
}

const RaptorMultiSelect = ({
  items,
  label,
  placeholder,
  selectAllLabel,
  noOptionsText,
  limitTags,
  onChange,
}: RaptorMultiSelectProps) => {

  const classes = useStyles();
  
  const [selectedOptions, setSelectedOptions] = useState<
    RaptorMultiSelectOption[]
  >([]);

  const handleClearOptions = () => setSelectedOptions([]);
  const getOptionLabel = (option: any) => `${option.label}`;

  const isAllSelected = () => {
    return items.length === selectedOptions.length;
  }

  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedOptions(items);
    } else {
      handleClearOptions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!isAllSelected());
  };

  const handleChange = (
    event: any,
    selectedOptions: any,
    reason: any
  ) => {
    if (reason === 'removeOption') {
      if (
        selectedOptions.find(
          (option: RaptorMultiSelectOption) => option.value === 'select-all'
        )
      ) {
        handleToggleSelectAll();
        let result = [];
        result = items.filter((el) => el.value !== 'select-all');
        return onChange(result);
      } else {
        // ensure no duplicates
        const unique: RaptorMultiSelectOption[] = selectedOptions.filter(
          (option: RaptorMultiSelectOption, index: number, self: any) =>
            index ===
            self.findIndex(
              (t: RaptorMultiSelectOption) => t.value === option.value
            )
        );
        setSelectedOptions(unique);
        return onChange(unique);
      }
    } else if (reason === 'clear') {
      handleClearOptions && handleClearOptions();
    }
  };

  // Given an option, add or remove it from the selected options
  // Handle for the `select-all` option
  const addSelection = (option: RaptorMultiSelectOption) => {
    if (option.value === 'select-all') {
      handleToggleSelectAll();
      return;
    } else {
      const selected = selectedOptions.some(
        (selected) => selected.value === option.value
      );
      if (selected) {
        setSelectedOptions(selectedOptions.filter((el) => el.value !== option.value));
        onChange(selectedOptions.filter((el) => el.value !== option.value));
      } else {
        setSelectedOptions([...selectedOptions, option]);
        onChange([...selectedOptions, option]);
      }
    }
  }

  const optionRenderer = (
    props: HTMLAttributes<HTMLLIElement> & { key: any; },
    option: any,
    state: AutocompleteRenderOptionState
  ) => {
      return (
        <li
          className={isOptionSelected(option) || isAllSelected() ? clsx(classes.option, classes.selectedOption) : classes.option}
          onClick={() => addSelection(option)}
        >
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={option.value === 'select-all' ? isAllSelected() : isOptionSelected(option)}
          />
          {props.key}
        </li>
      );
  };

  const isOptionSelected = (option: RaptorMultiSelectOption) => {
    return selectedOptions.some((selected) => selected.value === option.value);
  };

  const inputRenderer = (params: any) => (
    <TextField {...params} label={label} placeholder={placeholder} />
  );

  const filter = createFilterOptions();

  return (
    <Autocomplete
      multiple
      size="small"
      style={{ width: '800px' }}
      limitTags={limitTags}
      options={items}
      value={selectedOptions}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      noOptionsText={noOptionsText}
      filterOptions={(options: any, params: any) => {
        const filtered = filter(options, params);
        return [{ label: selectAllLabel, value: 'select-all' }, ...filtered];
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
    />
  );
};

RaptorMultiSelect.defaultProps = {
  limitTags: 3,
  items: [],
  selectedValues: [],
  getOptionLabel: (value: any) => value,
};

export default RaptorMultiSelect;
