import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFundsData } from '../redux/pages/actions';
import { overviewFundsSelector } from '../redux/pages/selectors';
import axios from 'axios';

const getFundsRoute = (fundTypes?: string, rsResources?: string) => {
  const opening = fundTypes?.length || rsResources?.length ? '?' : '';
  const schemas = fundTypes?.length ? `schemas=${fundTypes}` : '';
  const middle = fundTypes?.length && rsResources?.length ? '&' : '';
  const resource = rsResources?.length ? `rs_resources=${rsResources}` : '';
  return `get_funds` + opening + schemas + middle + resource;
};

export const useAppendOverviewFunds = (
  fundType?: string,
  rsResource?: string
) => {
  const overviewFunds = useSelector(overviewFundsSelector);

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  const dispatch = useDispatch();
  useEffect(() => {
    if (fundType && rsResource) {
      if (!overviewFunds || !overviewFunds[`${fundType}_${rsResource}`]) {
        dispatch(
          fetchFundsData(
            fundType,
            rsResource,
            getFundsRoute(fundType, rsResource),
            source
          )
        );
      }
    }
  }, [fundType, rsResource, overviewFunds]);

  return overviewFunds && fundType
    ? overviewFunds[`${fundType}_${rsResource}`]?.requestedData
    : null;
};
