import FileSaver from 'file-saver';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Area,
  AreaChart,
  CartesianGrid,
  LabelProps,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CurveType } from 'recharts/types/shape/Curve';
import usePngFromRecharts from '../../hooks/usePngFromRecharts';
import { addComponentToPdfExport } from '../../redux/pdfExport/actions';
import { RaptorTheme } from '../../styling/theme';
import { PdfComponentType } from '../../types/redux/pdfExports/pdfExportsStore';
import ChartDownloadButton from '../buttons/ChartDownloadButton';
import ExportButton from '../feedback/ExportButton';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, useTheme } from '@mui/material';

const useStyles = makeStyles(() => ({
  center: {
    margin: '0 auto',
    fontSize: '1.4rem',
  },
}));

export interface AreaDescriptor {
  // type: 'basis' | 'basisClosed' | 'basisOpen' | 'linear' | 'linearClosed' | 'natural' | 'monotoneX' | 'monotoneY' | 'monotone' | 'step' | 'stepBefore' | 'stepAfter' | Function;
  type:
    | string
    | 'basis'
    | 'basisClosed'
    | 'basisOpen'
    | 'linear'
    | 'linearClosed'
    | 'natural'
    | 'monotoneX'
    | 'monotoneY'
    | 'monotone'
    | 'step'
    | 'stepBefore'
    | 'stepAfter'
    | Function;
  lineColor: string;
  fillColor: string;
  strokeWidth?: number;
  dataKey: string;
  dot?: Boolean | Object | ReactElement | Function;
}

interface IGenericAreaChartProps {
  data: any[];
  areas: AreaDescriptor[];
  width: number | string;
  height: number | string;
  title: string;
  // smallTitle?: boolean;
  // xInterval?: XAxisProps['interval'];
  margin: any;
  // yAxisDomain?: any;
  minTickGap?: number;
  tooltipFormatter?: any;
  yAxisTickFormatter?: any;
  // showLegend?: boolean;
  yAxisLabel?: any;
  // legendWrapper?: CSSProperties;
  // legendVAlign?: 'top' | 'bottom' | 'middle';
  loading?: boolean;
  export?: boolean;
  // yAxisTicks?: any[];
  // additionalYAxis?: AdaptedYAxis[];
  error: number | string | Error | null;
  // backgroundColor?: string; // in case you need to style the background
  xAxisLabel?: string | LabelProps;
  // yAxisDataKey?: string;
  // yAxisOrientation?: 'left' | 'right';
  // xAxisDataKey?: string;
  // showYTicks?: boolean;
  // showXTick?: boolean;
  // legendFormatter?: any;
  // showDot?: boolean;
  showTitle?: boolean;
  // strokeWidth?: number;
  download?: boolean;
  id?: string;
  pdfTitle?: string; // Optionally spoecify a title for when exporting to pdf
  referenceLineY?: number;
  referenceLineYLabel?: string;
  gradientFill?: boolean;
  fillOpacity?: number;
  // monotone?: boolean;
}

function GenericAreaChart({
  data,
  height,
  width,
  areas,
  title,
  margin,
  error,
  ...props
}: IGenericAreaChartProps): ReactElement {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  // const [refNode, setRefNode] = useState<any>(null);
  // const ref = (node: any) => {
  //   setRefNode(node);
  // };

  // const handleDownload = async () => {
  //   if (refNode !== null && refNode?.container) {
  //     const data = await html2canvas(
  //       refNode.container as HTMLElement
  //     ).then((canvas) => canvas.toDataURL('image/png', 1.0));
  //     FileSaver.saveAs(data, title + '.png');
  //   }
  // };
  //
  const { ref, handleDownload } = usePngFromRecharts();

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: props.id || 'no_id',
        handler: handleDownload,
        type: PdfComponentType.AREA_CHART,
        title: props.pdfTitle ? props.pdfTitle : title,
      })
    );
  }, [ref]);

  const saveImage = async () => {
    const imageData = await handleDownload();
    if (imageData) {
      FileSaver.saveAs(imageData.data, title + '.png');
    }
  };
  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <div id="chart-title" style={{ width: '100%', marginLeft: '1rem' }}>
          <Typography
            variant={'h2'}
            style={{ color: theme.palette.primary.main }}
          >
            {props.showTitle && title}
          </Typography>
        </div>
        <div id="export_area" style={{ display: 'flex', alignItems: 'center' }}>
          {props.export && (
            <ExportButton exportData={data} fields={Object.keys(data[0])} />
          )}
          {props.download && (
            <ChartDownloadButton handler={saveImage} title={title} />
          )}
        </div>
      </div>
      <ResponsiveContainer
        width={width}
        height={height}
        className={classes.center}
      >
        <AreaChart
          // width={730}
          // height={250}
          data={data}
          ref={ref}
          // margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          margin={margin}
        >
          {/* <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                    <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" /> */}

          <defs>
            {props.gradientFill &&
              areas.map((area: AreaDescriptor) => {
                return (
                  <linearGradient
                    id={`color${area.dataKey}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                    key={`color${area.dataKey}`}
                  >
                    <stop
                      offset="5%"
                      stopColor={area.fillColor}
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor={area.fillColor}
                      stopOpacity={0}
                    />
                  </linearGradient>
                );
              })}
          </defs>
          <XAxis
            //   dataKey={props.xAxisDataKey || 'name'}
            //   interval={props.xInterval || 'preserveStartEnd'}
            minTickGap={props.minTickGap || 30}
            //   tick={props.showXTick && { dy: 5 }}
            label={props.xAxisLabel}
            // Need to update this at some point - will be grand for now
            dataKey="name"
            // scale="band"
          />

          <YAxis tickFormatter={props.yAxisTickFormatter || null} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip formatter={props.tooltipFormatter || null} />

          {areas.map((area: AreaDescriptor) => {
            return (
              <Area
                type={area.type as CurveType}
                dataKey={area.dataKey}
                stroke={area.lineColor}
                fillOpacity={props.fillOpacity ? props.fillOpacity : 1}
                fill={
                  props.gradientFill
                    ? `url(#color${area.dataKey})`
                    : area.fillColor
                }
                key={area.dataKey + title}
                strokeWidth={area.strokeWidth || 1}
              />
            );
          })}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
  {
    /* //   <div style={{ width: '100%' }}>
        //     <div
        //       style={{
        //         display: 'flex',
        //         justifyContent: 'flex-end',
        //         alignItems: 'center',
        //       }}
        //     >
        //       <div
        //         id="chart-title"
        //         style={
        //           props.smallTitle
        //             ? {width: 'calc(100% - 20rem)', textAlign: 'center' }
        //             : {width: '100%' }
        //         }
        //       >
        //         <Typography
        //           variant={props.smallTitle ? 'h5' : 'h2'}
        //           style={{ color: theme.palette.primary.main }}
        //         >
        //           {props.showTitle && title}
        //         </Typography>
        //       </div>

        //       <div id="export_area" style={{ display: 'flex', alignItems: 'center' }}>
        //         {props.export && (
        //           <ExportButton exportData={data} fields={Object.keys(data[0])} />
        //         )}
        //         {props.download && (
        //           <ChartDownloadButton handler={saveImage} title={title} />
        //         )}
        //       </div>
        //     </div>
        //     <ResponsiveContainer
        //       width={width}
        //       height={height}
        //       className={classes.center}
        //     >
        //       <LineChart data={data} margin={margin} ref={ref}>
        //         <CartesianGrid
        //           strokeDasharray="3 3"
        //           fill={props.backgroundColor || 'transparent'}
        //         />
        //         <XAxis
        //           dataKey={props.xAxisDataKey || 'name'}
        //           interval={props.xInterval || 'preserveStartEnd'}
        //           minTickGap={props.minTickGap || 30}
        //           tick={props.showXTick && { dy: 5 }}
        //           label={props.xAxisLabel}
        //         // scale="band"
        //         />
        //         <YAxis
        //           domain={
        //             props.yAxisDomain || [
        //               (dataMin: number) => dataMin - dataMin * 0.1,
        //               (dataMax: number) => dataMax + dataMax * 0.1,
        //             ]
        //           }
        //           tickFormatter={props.yAxisTickFormatter || null}
        //           interval="preserveStartEnd"
        //           label={props.yAxisLabel || null}
        //           ticks={props.yAxisTicks}
        //           tick={props.showYTicks}
        //           yAxisId="left"
        //           scale="linear"
        //           allowDataOverflow={false}
        //           orientation={props.yAxisOrientation || 'left'}
        //         />

        //         {props.additionalYAxis ? (
        //           props.additionalYAxis.map((el: AdaptedYAxis, index: number) => {
        //             console.log('IS THIS BEING ACCESSED', props.additionalYAxis);
        //             return (
        //               <YAxis
        //                 key={title}
        //                 scale="linear"
        //                 domain={[
        //                   (dataMin: number) => {
        //                     return dataMin - dataMin * 0.1;
        //                   },
        //                   (dataMax: number) => dataMax + dataMax * 0.1,
        //                 ]}
        //                 {...el}
        //               />
        //             );
        //           })
        //         ) : (
        //           <></>
        //         )}

        //         <Tooltip formatter={props.tooltipFormatter || null} />
        //         {props.showLegend && (
        //           <Legend
        //             wrapperStyle={
        //               props.legendWrapper ? props.legendWrapper : { bottom: 0 }
        //             }
        //             formatter={props.legendFormatter}
        //             verticalAlign={props.legendVAlign}
        //           />
        //         )}
        //         {props.referenceLineY && (
        //           <ReferenceLine y={props.referenceLineY} yAxisId="left" stroke="red" strokeDasharray="3 3" >
        //             <Label position={"bottom"}>{props.referenceLineYLabel ? props.referenceLineYLabel : undefined}</Label>
        //           </ReferenceLine>
        //         )}

        //         {lines.map((line: LineDescriptor) => {
        //           return (
        //             <Line
        //               dot={props.showDot && true}
        //               type={props.monotone ? "monotone" : "linear"}
        //               dataKey={line.dataKey}
        //               stroke={line.color}
        //               strokeWidth={line.strokeWidth || props.strokeWidth || 1}
        //               key={line.dataKey + title}
        //               yAxisId={line.yAxisId || 'left'}
        //             />
        //           );
        //         })}
        //       </LineChart>
        //     </ResponsiveContainer>
        //   </div> */
  }
}

export default React.memo(GenericAreaChart);
