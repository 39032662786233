import makeStyles from '@mui/styles/makeStyles';
import { ReactElement } from 'react';
import { RaptorTheme, mainColors } from '../../../../../styling/theme';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { addCommasToNumbersAndRound } from '../../../../../utilities/numberFormatters';
import CustomTable from '../../../../tables/CustomTable';
import { LawSecondLevelData } from './SecondLevelTable';
import { Button } from '@mui/material';
import { ArrowRight, TripOrigin } from '@mui/icons-material';

export const rule17ThirdLevelDetail = [
  (rowData: LawSecondLevelData) => {
    const assets =
      rowData.assets && rowData.assets.length ? rowData.assets : [];
    return {
      render: () => {
        return <Rule17ThirdLevelTable assets={assets} />;
      },
      disabled: !assets.length,
      icon: rowData.headerRow ? TripOrigin : ArrowRight,
    };
  },
];

interface Props {
  assets: any[];
}

interface rule17ThirdLevelData {
  number: number;
  assetName: string;
  baseCurrencyExposure: number;
  exposurePercentage: number;
  assetType: string;
  collateralSubFund: string;
}

const rule17TableColumns: CustomColumn<rule17ThirdLevelData>[] = [
  {
    title: '#',
    field: 'number',
    width: 1,
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Asset Name',
    field: 'assetName',
    width: '20%',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Base Currency Exposure',
    field: 'baseCurrencyExposure',
    render: (rowData: rule17ThirdLevelData) =>
      addCommasToNumbersAndRound(rowData.baseCurrencyExposure),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Exposure %',
    field: 'exposurePercentage',
    render: (rowData: rule17ThirdLevelData) =>
      `${parseFloat((rowData.exposurePercentage * 100).toFixed(4))}%`,
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Asset Type',
    field: 'assetType',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Location',
    field: 'collateralSubFund',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
];

// lazily setting data to any, because what is returned from the server could either be
//
export function buildAssetData(data: any): rule17ThirdLevelData[] {
  const tableData: rule17ThirdLevelData[] = [];
  data.forEach((asset: any, index: number) => {
    tableData.push({
      number: index + 1,
      assetName: asset[0],
      baseCurrencyExposure: asset[1],
      exposurePercentage: asset[2],
      assetType: asset[3],
      collateralSubFund: asset[4],
    });
  });
  return tableData;
}

const useStyles = makeStyles<RaptorTheme, { alteredColor?: boolean }>(
  (theme) => ({
    minusButton: {
      color: (props) =>
        props.alteredColor ? mainColors.mainGold : theme.palette.grey[800],
      backgroundColor: 'white',
      minWidth: 0,
      padding: '4px 8px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.primary.main,
      },
    },
  })
);

export const PreviousPositionDateButton = ({
  currentPositionDate,
  activePositionDate,
  handler,
  ...props
}: {
  currentPositionDate: string;
  activePositionDate: string;
  handler: () => void;
  key: string;
  alteredColor?: boolean;
}) => {
  const classes = useStyles({
    alteredColor: props.alteredColor,
  });
  const isToday = currentPositionDate === activePositionDate;
  return (
    <Button onClick={handler} className={classes.minusButton}>
      {isToday
        ? 'Compare with previous position date'
        : `View today's position date (${activePositionDate})`}
    </Button>
  );
};
function Rule17ThirdLevelTable({ assets }: Props): ReactElement {
  const tableData = buildAssetData(assets);

  return (
    <CustomTable<rule17ThirdLevelData>
      showToolbar={true}
      columns={rule17TableColumns}
      options={{
        paging: false,
        showTitle: true,
        exportButton: true,
        search: true,
        toolbar: true,
      }}
      data={tableData}
      title="Underlying Assets"
      csvFields={tableData.length > 0 ? Object.keys(tableData[0]) : []}
    />
  );
}

export default Rule17ThirdLevelTable;
