export const TOGGLE_CONTROLBAR_SHOWING_ACTION =
  'TOGGLE_CONTROLBAR_SHOWING_ACTION';
export type TOGGLE_CONTROLBAR_SHOWING_ACTION =
  typeof TOGGLE_CONTROLBAR_SHOWING_ACTION;
export const UPDATE_CONTROLBAR_COMPONENTS_ACTION =
  ' UPDATE_CONTROLBAR_COMPONENTS_ACTION';
export type UPDATE_CONTROLBAR_COMPONENTS_ACTION =
  typeof UPDATE_CONTROLBAR_COMPONENTS_ACTION;

export const ControlBarActionType =
  TOGGLE_CONTROLBAR_SHOWING_ACTION || UPDATE_CONTROLBAR_COMPONENTS_ACTION;

export type ToggleControlBarShowingAction = {
  type: TOGGLE_CONTROLBAR_SHOWING_ACTION;
  payload: boolean;
};

export type UpdateControlBarComponentsAction = {
  type: UPDATE_CONTROLBAR_COMPONENTS_ACTION;
  payload: null | any;
};

// ACTION CREATOR FUNCTIONS
export const toggleControlBarShowing = (
  shouldControlBarBeShowing: boolean
): ToggleControlBarShowingAction => {
  return {
    type: TOGGLE_CONTROLBAR_SHOWING_ACTION,
    payload: shouldControlBarBeShowing,
  };
};
export const updateControlBarComponents = (
  snackbarMessage: null | any
): UpdateControlBarComponentsAction => {
  return {
    type: UPDATE_CONTROLBAR_COMPONENTS_ACTION,
    payload: snackbarMessage,
  };
};

export type ControlBarAction =
  | ToggleControlBarShowingAction
  | UpdateControlBarComponentsAction;
