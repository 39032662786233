import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { mainColors } from '../../../../../styling/theme';
import { RAIDR_BASE_URL } from '../../../../../utilities/requestClient';
import PriipsKidsEditorGridItem from '../layout/PriipsKidsEditorGridItem.component';
import Raptor2Loading from '../../../../feedback/Raptor2Loading';
import useRouteParams from '../../../../../hooks/useRouteParams';
import { useDispatch } from 'react-redux';
import {
  toggleControlBarShowing,
  updateControlBarComponents,
} from '../../../../../redux/ui/controlbar/actions';
import BackToOverviewButton from '../buttons/BackToOverviewButton.component';
import ReviewDocumentsButtons from '../buttons/ReviewDocumentsButtons.component';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import ReviewConfirmationDialogBox from '../buttons/ReviewConfirmationDialogBox.component';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles(() => ({
  parentContainer: {
    minHeight: '20rem',
    display: 'flex',
    flexDirection: 'column',
    margin: '2rem',
    gap: '2rem',
    width: '100%',
  },
  buttonsContainer: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
  },
  button: {
    height: '3rem',
    // marginTop: '0.2rem',
    marginRight: '1rem',
    width: '10rem',
  },
  viewerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  iframeContainer: {
    border: '2px solid',
    borderColor: mainColors.mainBlue,
    width: '100%',
    height: '80vh',
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: 'white',
  },
  arrow: {
    '&:before': {
      border: '1px solid #38BF26',
    },
    color: mainColors.Pass,
  },
  tooltip: {
    backgroundColor: mainColors.Pass,
    border: '1px solid #38BF26',
    color: 'white',
  },
}));

function findDefaultSelection(
  fundId: string,
  isin: string,
  priipsOverviewData: any[]
) {
  if (priipsOverviewData.length === 0) {
    return '';
  }
  let defaultSelection = '';
  priipsOverviewData.forEach((item) => {
    if (item['fund_id_string'] === fundId && item['share_class'] === isin) {
      defaultSelection = `${item['fund_name']} - ${item['share_class']}`;
    }
  });
  return defaultSelection;
}

const UcitsKiidsReview: FC = () => {
  const [reviewContentData, setReviewContentData] = useState<any[]>([]);
  const classes = useStyles();

  const [pdfDocLoading, setPdfDocLoading] = useState(false);

  // Variable used to display the confirm review dialog box.
  const [confirmReviewIsShowing, setConfirmReviewIsShowing] =
    useState<boolean>(false);

  const [priipsOverviewData, setPriipsOverviewData] = React.useState<any[]>([]);
  const [documentOptions, setDocumentOptions] = React.useState<string[]>([]);
  const [defaultSelection, setDefaultSelection] = React.useState<string>('');
  const [filteredOverviewData, setFilteredOverviewData] = React.useState<any[]>(
    []
  );

  const requestClient = axios.create({
    withCredentials: true,
    baseURL: RAIDR_BASE_URL,
  });

  // Get route params
  const fundId = useRouteParams('fundId')['fundId'];
  const isin = useRouteParams('isin')['isin'];
  const documentLanguage =
    useRouteParams('documentLanguage')['documentLanguage'];
  const currentVersion = useRouteParams('currentVersion')['currentVersion'];
  const publishedVersion =
    useRouteParams('publishedVersion')['publishedVersion'];

  const getReviewData = async () => {
    requestClient
      .get(
        `kiid_generator_get_review_changes/${fundId}/${isin}/${currentVersion}/${publishedVersion}/${documentLanguage}`
      )
      .then((response) => {
        // setNumReviewChanges(response.data.num_pages);
        setReviewContentData(response.data.data);
      });
  };

  useEffect(() => {
    if (
      fundId &&
      isin &&
      documentLanguage &&
      currentVersion &&
      publishedVersion
    ) {
      getReviewData();
    }
  }, [fundId, isin, documentLanguage, currentVersion, publishedVersion]);

  const getPDF = async () => {
    // Check if the required fields have been filled in.
    if (!reviewContentData || !reviewContentData.length) {
      return;
    }

    // Create an object of formData
    const formData = new FormData();
    // Convert the content to json
    const priipsKidsJson = JSON.stringify(reviewContentData);
    // Update the formData object.
    formData.append('contents', priipsKidsJson);
    formData.append('params', JSON.stringify(['contents']));

    // Display the loading symbol
    setPdfDocLoading(true);
    // Create the request
    const request = new Request(
      RAIDR_BASE_URL + 'risksystem_pdf_generator/generate_new_review_kiid',
      {
        method: 'POST',
        body: formData,
        headers: {
          accept: 'application/pdf',
        },
        mode: 'cors',
        cache: 'default',
      }
    );
    // Fetch the request to get the pdf file
    fetch(request)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const file = window.URL.createObjectURL(blob);
        const iframe = document.querySelector('iframe');
        if (iframe?.src) {
          iframe.src = file;
          iframe.title = 'kid.pdf';
        }
        setPdfDocLoading(false);
      });
  };

  useEffect(() => {
    if (reviewContentData.length) {
      getPDF();
    }
  }, [reviewContentData]);

  const dispatch = useDispatch();

  const history = useHistory();

  const backToOverview = () => {
    dispatch(toggleControlBarShowing(false));
    history.push('overview-view');
  };

  // Toggle the control bar to be shown
  useEffect(() => {
    if (reviewContentData.length > 0) {
      dispatch(
        updateControlBarComponents([
          <BackToOverviewButton
            backToOverview={backToOverview}
            dontSetPosition
            key="back_to_overview"
          />,
          <ReviewDocumentsButtons
            key="review_documents_buttons"
            fundId={fundId || ''}
            isin={isin || ''}
            documentLanguage={documentLanguage || ''}
            documentOptions={documentOptions}
            filteredOverviewData={filteredOverviewData}
            defaultSelection={documentOptions[0]}
          />,
        ])
      );
      dispatch(toggleControlBarShowing(true));
    }
  }, [reviewContentData]);

  // Function for pulling and populating the overview data (will be used to dertmine which documents are available for review)
  const getPriipsOverviewData = async () => {
    requestClient.get('kiids_generator_get_overview_data').then((response) => {
      setPriipsOverviewData(response.data);
    });
  };
  // Run the function once on render
  useEffect(() => {
    getPriipsOverviewData();
  }, []);

  // Create an array of options for the select when overview data is available
  useEffect(() => {
    if (priipsOverviewData.length > 0) {
      const documentOptions: string[] = [];
      const overviewDataFiltered: any[] = [];
      priipsOverviewData.forEach((item) => {
        // Only add documents that are available for review.
        const version = item['version'].split('.');
        if (
          version[2] !== '0' &&
          item['document_language'] === documentLanguage
        ) {
          documentOptions.push(`${item['fund_name']} - ${item['share_class']}`);
          overviewDataFiltered.push(item);
        }
      });
      setDocumentOptions(documentOptions);
      setFilteredOverviewData(overviewDataFiltered);
      // Set the default selection
      const selctionValue = findDefaultSelection(
        fundId || '',
        isin || '',
        priipsOverviewData
      );
      setDefaultSelection(selctionValue);
    }
  }, [priipsOverviewData]);

  return (
    <>
      <PriipsKidsEditorGridItem xs={12}>
        <div className={classes.viewerContainer}>
          <div className={classes.iframeContainer}>
            <iframe
              src=""
              width={pdfDocLoading ? '0%' : '100%'}
              height="100%"
              title={'kid.pdf'}
              id={'review-iframe'}
            ></iframe>
            {pdfDocLoading ? (
              <Raptor2Loading
                centerWrap
                messages={['Generating Document...']}
              />
            ) : null}
          </div>
        </div>
      </PriipsKidsEditorGridItem>
      <PriipsKidsEditorGridItem xs={12}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {confirmReviewIsShowing && (
            <ReviewConfirmationDialogBox
              setDialogIsShowing={setConfirmReviewIsShowing}
              dialogIsShowing={confirmReviewIsShowing}
              fundId={fundId || ''}
              shareClass={isin || ''}
              documentLanguage={documentLanguage || ''}
              documentOptions={documentOptions}
              filteredOverviewData={filteredOverviewData}
              fundName={defaultSelection.split(' - ')[0]}
              setDocumentOptions={setDocumentOptions}
              setFilteredOverviewData={setFilteredOverviewData}
            />
          )}
          <Tooltip
            placement="top"
            enterDelay={1000}
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            arrow
            title="Confirm Review"
          >
            <Button
              variant="contained"
              sx={{
                fontSize: '1.5rem',
                width: '18rem',
                height: '3rem',
                color: 'white',
                backgroundColor: mainColors.mainBlue,
                borderRadius: '0.2rem',
                padding: '0.2rem',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: mainColors.mainBlue_lighter,
                },
                fontWeight: 600,
                textTransform: 'none',
              }}
              disableElevation={true}
              onClick={() => setConfirmReviewIsShowing(true)}
              endIcon={
                <CheckIcon
                  sx={{
                    padding: '0.2rem',
                    fontSize: '3rem',
                    width: '2.5rem',
                    height: '2.5rem',
                  }}
                />
              }
            >
              Complete Review
            </Button>
          </Tooltip>
        </div>
      </PriipsKidsEditorGridItem>
    </>
  );
};

export default UcitsKiidsReview;
