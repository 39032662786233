import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applyFundFilter } from '../../../../redux/pages/actions';
import { createSectionByIdSelector } from '../../../../redux/pages/selectors';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import { Section } from '../../../../types/server-data/ClientConfig';
import SelectAFundPage from '../../sharedComponents/selectAFundPage/component';

export function useFilterFunds(
  filteredFunds: string[],
  funds: string,
  rsResources: string
) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (funds === 'UCITS') {
      dispatch(applyFundFilter(filteredFunds, funds, rsResources));
    }

    return () => {
      dispatch(applyFundFilter([], funds, rsResources));
    };
  }, []);
}

function ShareClassHedgeDataOverview({
  section,
}: GeneralOverviewPageRenderedComponentProps): ReactElement {
  const sectionDetailsSelector = createSectionByIdSelector(section);
  const sectionDetails: Section | null = useSelector(sectionDetailsSelector);

  // this filtering is for coutts - can adapt it based on different clients
  useFilterFunds(
    [
      'coutts305',
      'coutts306',
      'coutts307',
      'bflexion101',
      'nanjia_001fx',
      'nanjia_002fx',
      'nanjia_003fx',
    ],
    sectionDetails?.fundTypes as string,
    sectionDetails?.rsResources as string
  );
  return (
    <SelectAFundPage
      section={section}
      fundsForUse={sectionDetails?.fundTypes || 'UCITS'}
    />
  );
}

export default ShareClassHedgeDataOverview;
