import React, { useEffect } from 'react';
import { EquityComponentProps } from './table/table.component';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../../../redux/pages/actions';
import {
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../../../../../types/redux/pages/pageTypes';
import { useDispatch } from 'react-redux';
import { Select } from '../../../../../types/redux/pages/PagesStore';
import useTitleBarSelect from '../../../../../hooks/useTitleBarSelect';
import EquityBarChart from './barChart/barChart.component';
import EquityMap from './map/map.component';
export enum EquityExposureOptionType {
  SECTOR = 'sector',
  GROSS_COUNTRY_EXPOSURE = 'grossCountryExposure',
  NET_COUNTRY_EXPOSURE = 'netCountryExposure',
}
const ExposureGraphics = (props: EquityComponentProps) => {
  const dispatch = useDispatch();
  const localStorageKey = 'EXPOSURE_TYPE_EQUITY';
  const exposureType: EquityExposureOptionType =
    (useTitleBarSelect('exposureType') as EquityExposureOptionType) ||
    (localStorage.getItem(localStorageKey) as EquityExposureOptionType) ||
    EquityExposureOptionType.SECTOR;
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: 'Exposure Type:',
        titleBarKey: 'exposureType',
        values: [
          {
            label: 'Sector',
            value: 'sector',
          },
          {
            label: 'Gross Country Exposure',
            value: 'grossCountryExposure',
          },
          {
            label: 'Net Country Exposure',
            value: 'netCountryExposure',
          },
        ],
        displayOnOverviewPage: false,
        aValueIsAlwaysSelected: true,
        currentValue:
          localStorage.getItem(localStorageKey) ||
          EquityExposureOptionType.SECTOR,
      },
    ];
    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR
      )
    );

    return () => {
      dispatch(
        removeTitleBarComponent('exposureType', REMOVE_SELECT_FROM_TITLEBAR)
      );
    };
  }, []);

  useEffect(() => {
    localStorage.setItem(localStorageKey, exposureType);
  }, [exposureType]);

  const renderComponent = () => {
    switch (exposureType) {
      case EquityExposureOptionType.SECTOR:
        return <EquityBarChart {...props} />;
      case EquityExposureOptionType.GROSS_COUNTRY_EXPOSURE:
      case EquityExposureOptionType.NET_COUNTRY_EXPOSURE:
        return <EquityMap {...props} selectedEquityPage={exposureType} />;
      default:
        return <EquityBarChart {...props} />;
    }
  };
  return <>{renderComponent()}</>;
};

export default ExposureGraphics;
