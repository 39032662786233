import { ColumnDef } from '@tanstack/react-table';
import { ColumnData } from '../types/column.types';
import { ESG_DATA } from '../data/esgPresetColumnData';
import { ifNumberAddCommasAndRound } from '../../../../../../utilities/numberFormatters';

export const buildEsgColumns = (columns: ColumnData[]) => {
  const cols: ColumnDef<ESG_DATA>[] = [];
  cols.push({
    id: 'index',
    size: 60,
    enableResizing: false,
    enableColumnFilter: false,
    enablePinning: true,
    header: ({ table }) => (
      <span style={{ fontSize: '1.4rem' }}>{table.getRowCount()}</span>
    ),
    cell: ({ table, row }) => {
      const filteredRowIndex =
        table.getFilteredRowModel().rows.findIndex((r) => r.id === row.id) + 1;

      return <span style={{ fontWeight: 600 }}>{filteredRowIndex}</span>;
    },
  });

  columns.forEach((dc: ColumnData) => {
    cols.push({
      id: dc.title,
      accessorKey: dc.title,
      header: dc.title,
      footer: dc.title,
      ...(dc.enableResizing && { enableResizing: dc.enableResizing }),
      ...(dc.enableColumnFilter && {
        enableColumnFilter: dc.enableColumnFilter,
      }),
      minSize: dc.minSize,
      ...(dc.filterFn && { filterFn: dc.filterFn }),
      meta: {
        filterVariant: dc.filterVariant,
        short_title: dc.short_title ? dc.short_title : dc.title,
        show: false,
      },
      ...(dc.size && { size: dc.size }),
      cell: (props) => {
        // handle for id columns (they shouldn't receive commas)
        if (
          dc.field === 'icb_sector_id' ||
          dc.field === 'msci_sector_id' ||
          dc.field === 'sector_id'
        ) {
          return props.getValue();
        } else {
          return ifNumberAddCommasAndRound(props.getValue());
        }
      },
    });
  });

  return cols;
};
