import React, { FC } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
  roundNumberToNDecimalPlaces,
  roundNumberToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';

interface ArticleScannerSecondLevelTableProps {
  data: any;
  nav: number;
}

function buildAricleScannerSecondLevelTaleData(
  data: any,
  nav: number
): ArticleScannerSecondLevelTableData[] {
  // const articleData = data.positions;
  const tableData: ArticleScannerSecondLevelTableData[] = [];

  data.forEach((value: any) => {
    tableData.push({
      isin: value.isin,
      asset: value.gm_name,
      exposure: value.exposure * nav,
      grossExposure: value.gross_exposure * nav,
      grossExposurePc: value.gross_exposure,
      marketPrice: value.market_price,
      environmental: value.E_Score,
      social: value.S_Score,
      corporateGovernance: value.G_Score,
      esg: value.ESG_Score,
      currency: value.base_currency,
    });
  });

  return tableData;
}

//     "gm_name_long": "ArcelorMittal (MT NA 3 C29 Equity)",
//     "country_code": "LU",
//     "sector_icb_name": "Iron and Steel",
//     "exposure": -0.010400210874228509,
//     "ISIN": null,
//     "mr_position": -1241.0,
//     "gm_name": "ArcelorMittal",
//     "gross_exposure": 0.010400210874228509,
//     "market_price": 1.0800000000000018,
//     "sector_name": "Mining, coal & steel",
//     "total_carbon": 0.0,
//     "esg_scores": {
//         "esg": 64.95,
//         "s": 58.13,
//         "e": 59.64,
//         "g": 77.09
//     },
//     "g_name": "ArcelorMittal SA",
//     "base_currency": "EUR",

interface ArticleScannerSecondLevelTableData {
  isin: string;
  asset: string;
  exposure: number;
  grossExposure: number;
  grossExposurePc: number;
  marketPrice: number;
  environmental: number;
  social: number;
  corporateGovernance: number;
  esg: number;
  currency: string;
}

const detailColumns: CustomColumn<ArticleScannerSecondLevelTableData>[] = [
  {
    title: 'Asset',
    field: 'asset',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Isin',
    field: 'isin',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    // render: (rowData: ArticleScannerTableData) => percentageToTwoDecimalPlaces(rowData.unalignedGrossExposure)
  },
  {
    title: 'Exposure',
    field: 'exposure',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ArticleScannerSecondLevelTableData) =>
      addCommasToNumbersAndRound(rowData.exposure),
  },
  {
    title: 'Gross Exposure',
    field: 'grossExposure',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ArticleScannerSecondLevelTableData) =>
      addCommasToNumbersAndRound(rowData.grossExposure),
  },
  {
    title: 'Gross Exposure %',
    field: 'grossExposurePc',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ArticleScannerSecondLevelTableData) =>
      percentageToTwoDecimalPlaces(rowData.grossExposurePc),
  },
  {
    title: 'Market Price',
    field: 'marketPrice',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ArticleScannerSecondLevelTableData) =>
      roundNumberToNDecimalPlaces(rowData.marketPrice, 4),
  },
  {
    title: 'Currency',
    field: 'currency',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Environmental',
    field: 'environmental',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ArticleScannerSecondLevelTableData) =>
      roundNumberToTwoDecimalPlaces(rowData.environmental),
  },
  {
    title: 'Social',
    field: 'social',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ArticleScannerSecondLevelTableData) =>
      roundNumberToTwoDecimalPlaces(rowData.social),
  },
  {
    title: 'Corporate Governance',
    field: 'corporateGovernance',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ArticleScannerSecondLevelTableData) =>
      roundNumberToTwoDecimalPlaces(rowData.corporateGovernance),
  },
  {
    title: 'ESG',
    field: 'esg',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ArticleScannerSecondLevelTableData) =>
      roundNumberToTwoDecimalPlaces(rowData.esg),
  },
];

const ArticleScannerSecondLevelTable: FC<
  ArticleScannerSecondLevelTableProps
> = (props) => {
  // const theme = useTheme();
  const { data, nav } = props;

  const tableData = buildAricleScannerSecondLevelTaleData(data, nav);
  // const detailColumns = buildEsgPositionColumns();
  return (
    // <div>Im esg page</div>
    <GridItem xs={12} card>
      <CustomTable<ArticleScannerSecondLevelTableData>
        columns={detailColumns}
        showToolbar={true}
        data={tableData}
        options={{
          paging: false,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default ArticleScannerSecondLevelTable;
