import React from 'react';
import { CustomColumn } from '../../types/components/tables/tableTypes';
import CustomTable, { MultipleTablesInRowParameters } from './CustomTable';
import { useWindowSize } from '../../hooks/useWindowSize';

export interface SlimTableProps<T extends object> {
  data: T[];
  columns: CustomColumn<T>[];
  loading: boolean;
  maxHeight?: number | string;
  minHeight?: number | string;
  csvFields: string[];
  export?: boolean;
  search?: boolean;
  id?: string;
  title?: string;
  pdfPositioning?: MultipleTablesInRowParameters;
  tablesInRow?: number;
}

const SlimTable = <T extends object>(props: SlimTableProps<T>) => {
  const { height } = useWindowSize();
  return (
    <CustomTable<T>
      {...props}
      showToolbar
      toolbarComponents={{
        toolbarTitle: props.title,
      }}
      options={{
        exportButton: props.export !== undefined ? props.export : true,
        paging: props.data.length > 70,
        search: props.search,
        pageSize: 50,
        pageSizeOptions: [50, 100, 400, props.data.length],
        maxBodyHeight: props.maxHeight || height - 192,
      }}
    />
  );
};

export default SlimTable;
