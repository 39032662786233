import makeStyles from '@mui/styles/makeStyles';
import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import {
  activeSectionSelector,
  createSectionFundsSelector,
} from '../../../../../../redux/pages/selectors';
import { RaptorTheme, mainColors } from '../../../../../../styling/theme';
import client from '../../../../../../utilities/requestClient';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import GeneralSelect from '../../../../../selects/GeneralSelect';
import { FunctionalityHandlerProps } from '../../Functionality';
import FunctionalityButton from '../functionalityButton';
import { Typography } from '@mui/material';
import RaptorDatePicker from '../../../../../selects/RaptorDatePicker.component';
import dayjs from 'dayjs';
import { getAllRelevantDates, getMostRecentWeekday, getWeekdayXMonthsAgo } from '../../../../../../utilities/dateUtilities';

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  varReportContainer: {
    display: 'flex',
    position: 'relative',
    top: '1rem',
    bottom: '1rem',
    marginBottom: '1rem',
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '1.2rem',
    height: '6rem',
  },
  header: {
    position: 'relative',
    // top: '0.5rem',
  },
}));

const selectLabels = ['Shauna', 'Jamie', 'Onno', 'Trium Risk', 'RS Analysts'];
const selectValues = ['shauna', 'jaime', 'onno', 'trium_risk', 'rs_analysts'];
const varReportUrl =
  '/trium_monthly_var_report/<afund>/<date1>/<date2>/<recipient>';

function MonthlyVarReport(props: FunctionalityHandlerProps): ReactElement {
  const classes = useStyles();

  // Get a list of the available funds
  const section = useSelector(activeSectionSelector);
  const fundDetails = useSelector(
    createSectionFundsSelector(section || 'ucits')
  );
  const fundIds = fundDetails != null ? fundDetails.map(({ id }) => id) : [];
  const fundNames =
    fundDetails != null ? fundDetails.map(({ name }) => name) : [];

  const [fund, setFund] = useState('none_selected');

  // End date for running the report (default to today)
  const [startDate, setStartDate] = useState<string>(getWeekdayXMonthsAgo(1));
  const [endDate, setEndDate] = useState<string>(getMostRecentWeekday());

  const [selected, setSelected] = useState(selectValues[0]);

  // Create a function for generating the url and calling the report when the button is pressed
  const requestClient = client();
  const { showAsyncSnackbar } = useSnackbar();
  async function handleRequest() {
    let url = varReportUrl.replace('<afund>', fund);
    url = url.replace('<date1>', startDate);
    url = url.replace('<date2>', endDate);
    url = url.replace('<recipient>', selected);
    // url = url.replace('reciient')
    await showAsyncSnackbar({
      startMessage: 'Running VAR Report',
      successMessage: 'Report Sent',
      failureMessage: 'Failed to send report',
      promiseToResolve: requestClient.get(url),
    });
    // await requestClient.get(url);
  }
  return (
    <GridItem
      card
      xs={props.size}
      cardStyle={{ height: '100%', padding: '1rem' }}
    >
      <div className={classes.header}>
        <Typography
          variant="h2"
          style={{
            fontSize: '2.4rem',
            width: '100%',
            fontWeight: 500,
            textAlign: 'left',
            marginBottom: '1rem',
          }}
        >
          {props.cardTitle}
        </Typography>
      </div>
      <div className={classes.varReportContainer}>
        <GeneralSelect
          labels={fundNames}
          values={fundIds}
          selected={fund}
          setSelected={setFund}
          placeholderValue={'None Selected'}
          placeholderColor={mainColors.mainBlue}
          selectTitle={'Select a fund'}
          customStyles={{
            pickerRoot: classes.pickerRoot,
          }}
        />
        <RaptorDatePicker
          key={'start-date'}
          title={'Start Date: '}
          handler={(val) => {
            setStartDate(dayjs(val).format('YYYY-MM-DD'));
          }}
          datesToInclude={getAllRelevantDates()}
          selectedDate={dayjs(startDate)}
          tiedToActiveDate={false}
        />
        <RaptorDatePicker
          // key={picker.titleBarKey}
          title={'End Date: '}
          handler={(val) =>
            setEndDate(dayjs(val).format('YYYY-MM-DD'))
          }
          datesToInclude={getAllRelevantDates()}
          selectedDate={dayjs(endDate)}
          tiedToActiveDate={false}
        />
        <GeneralSelect
          labels={selectLabels}
          values={selectValues}
          selected={selected}
          setSelected={setSelected}
          placeholderValue={null}
          placeholderColor={mainColors.mainBlue}
          selectTitle={'Select Recipient'}
          customStyles={{
            pickerRoot: classes.pickerRoot,
          }}
        />
        <FunctionalityButton
          handler={handleRequest}
          title="Run Report"
          height="6rem"
        />
      </div>
    </GridItem>
  );
}

export default MonthlyVarReport;
