import React, { FC } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import { addCommasToNumbersAndRound } from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';

interface MancoCounterpartySummaryTableProps {
  dataForRender: DataObject;
}

interface MancoSummaryTableData {
  name: string;
  value: string;
}

function buildMancoSummaryTableData(data: any): MancoSummaryTableData[] {
  if (!data.length) return [];
  const mancoData = data[0];
  const nav = mancoData.manco_nav;
  const tableData: MancoSummaryTableData[] = [
    {
      name: 'Position Dates',
      value: mancoData.selected_position_date,
    },
    {
      name: 'Base Currency',
      value: mancoData.base_currency,
    },
    {
      name: 'Net Exposure',
      value: addCommasToNumbersAndRound(mancoData.total_net_exposure),
    },
    {
      name: 'Gross Exposure',
      value: addCommasToNumbersAndRound(mancoData.total_gross_exposure),
    },
    {
      name: 'Cash Exposure',

      value: addCommasToNumbersAndRound(mancoData.asset_class_bc_exposure.cash),
    },
    {
      name: 'Derivative Cash Exposure',
      value: addCommasToNumbersAndRound(
        mancoData.asset_class_derivative_gross_exposure.cash
      ),
    },
    {
      name: 'Unrealised P/L',
      value: addCommasToNumbersAndRound(mancoData.total_unrealised_pl),
    },
    {
      name: 'Collateral',
      value: addCommasToNumbersAndRound(mancoData.total_bc_collateral),
    },
  ];
  return tableData;
}

const tableColumns: CustomColumn<MancoSummaryTableData>[] = [
  {
    title: 'Data',
    field: 'name',
    width: '75%',
    cellStyle: {
      textAlign: 'left',
    },
    headerStyle: {
      textAlign: 'left',
    },
  },
  {
    title: 'Field',
    field: 'value',

    cellStyle: {
      textAlign: 'right',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
];

const MancoCounterpartySummaryTable: FC<MancoCounterpartySummaryTableProps> = (
  props
) => {
  const { isFetching, error, data } = props.dataForRender;
  const tableData = buildMancoSummaryTableData(data);

  return (
    <GridItem card xs={3} cardStyle={{ height: '100%' }}>
      <CustomTable<MancoSummaryTableData>
        // selectedPositionDate={data[0].selected_position_date}
        id="manco_agg_static_exposure"
        title={'Aggregate Static Exposure'}
        // csvFields={aggregateExposureCsvFields}
        showToolbar={true}
        multipleTablePdfParameters={{
          specifiedWidth: 4,
          tablesInRow: 3,
        }}
        loading={isFetching}
        columns={tableColumns}
        data={tableData}
        options={{
          paging: false,
          search: false,
          header: false,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default MancoCounterpartySummaryTable;
