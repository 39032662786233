import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material';
import {
  greys,
  mainColors,
  RaptorTheme,
} from '../../../../../../styling/theme';
import { hexToRGBA } from '../../../../../../utilities/colorUtilities';

// styling for the main positions table component
export const generalPositionsTableStyles = makeStyles<RaptorTheme>((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3rem 3rem 1rem 3rem',
  },
  colSelection: {
    maxHeight: '20rem',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  header: {
    ...theme.typography.h1,
    width: '22rem',
    fontSize: 'clamp(2rem, 1vw, 3rem)',
  },
  toolbarOptions: {
    flexGrow: 1,
    display: 'flex',
    gap: '1rem',
    '@media only screen and (max-width: 1850px)': {
      flexDirection: 'column',
      marginBottom: '1rem',
    },
  },
  mainOptions: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    gap: '0.2rem',
    '@media only screen and (max-width: 1850px)': {
      justifyContent: 'flex-end',
    },
    marginBlock: '1rem',
  },
  tableContainer: {
    overflow: 'auto',
    position: 'relative', //needed for sticky header
    height: 'calc(100vh - 33rem)', // 33rem to account for the topbar and titlebar
    minHeight: '40rem', // to ensure at least 10 rows rendered
    marginBottom: '2rem',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0.6rem',
      height: '0.6rem',
    },
    '&::-webkit-scrollbar-track': {
      border: '0.2rem solid white',
      backgroundColor: mainColors.lightGrey,
      marginTop: '7rem', // gives the illusion of the scrollbar being underneath the sticky header
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.hoverOverMainBlue,
      borderRadius: '1rem',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },

  table: {
    borderCollapse: 'collapse',
  },
  tableHead: {
    position: 'sticky',
  },
  tableBody: {
    paddingBottom: '2rem',
    height: '80vh',
    overflow: 'auto',
    display: 'block',
    '&::-webkit-scrollbar': {
      width: '0.6rem',
      height: '0.6rem',
    },
    '&::-webkit-scrollbar-track': {
      border: '0.2rem solid white',
      backgroundColor: mainColors.lightGrey,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.hoverOverMainBlue,
      borderRadius: '1rem',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  row: {
    display: 'flex',
    borderTop: `1px solid ${mainColors.lightGrey}`,
    '&:hover': {
      backgroundColor: mainColors.hoverOverWhite,
    },
  },
  aggregateRow: {
    backgroundColor: mainColors.lightGrey,
    '&:hover': {
      backgroundColor: '#D2D2D2',
    },
  },
  rowExpanded: {
    borderRight: `2px solid ${mainColors.hoverOverMainBlue}`,
    borderTop: `2px solid ${mainColors.hoverOverMainBlue}`,
    borderLeft: `2px solid ${mainColors.hoverOverMainBlue}`,
    marginTop: '-3px',
  },
  headerRow: {
    display: 'flex',
    borderBottom: '2px solid grey',
  },
  cell: {
    ...theme.typography.h3,
    fontWeight: 500,
    padding: '.25rem',
    overflow: 'clip',
    wordBreak: 'break-all',
    textAlign: 'center',
    fontSize: '1.3rem',
    width: '60px',
    minWidth: '5rem',
    minHeight: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
    borderLeft: `1px solid ${mainColors.lightGrey}`,
    borderBottom: `1px solid ${mainColors.lightGrey}`,
  },
  cellWithWordBreak: {
    wordBreak: 'break-word',
  },
  expander: {
    margin: '0 1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  expanderIcon: {
    transform: 'scale(0.9)',
  },
  expanderHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: `1px solid ${mainColors.lightGrey}`,
    width: '60px',
  },
  columnHead: {
    width: '100%',
    minWidth: '7rem',
    blockSize: 'fit-content',
  },
  columnHeadTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '6.5rem',
  },
  sortIcon: {
    cursor: 'pointer',
  },
  dragHandle: {
    fontWeight: 600,
    border: 'none',
    background: 'none',
    cursor: 'grab',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: 'clamp(1.2rem, 0.85vw, 1.6rem)',
    color: mainColors.mainBlue,
    position: 'relative',
  },
  columnHeadTooltip: {
    backgroundColor: mainColors.mainBlue,
    fontSize: '1.4rem',
  },
  numberInputs: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
    padding: '0 .8rem',
    height: '2.5rem',
    backgroundColor: theme.palette.grey[100],
    fontSize: '1.2rem',
    width: '35%',
  },
  inputRoot: {
    width: '100%',
  },
  picker: {
    width: '80%',
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
    height: '2.5rem',
  },
  pickerContent: {
    fontSize: '1.2rem',
  },
  pickerTextField: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    paddingLeft: '0.8rem',
  },
  switchContainer: {
    marginRight: '3rem',
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: mainColors.mainBlue,
      '&:hover': {
        backgroundColor: alpha(
          mainColors.mainBlue_lighter,
          theme.palette.action.hoverOpacity
        ),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
  },

  resetButton: {
    transition: 'width .2s',
    borderRadius: '.8rem',
    paddingInline: '1rem',
    color: theme.palette.grey[800],
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    fontSize: '1.2rem',
  },

  assetClassButtonsContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem 0.2rem',
    '@media only screen and (max-width: 1850px)': {
      justifyContent: 'flex-end',
    },
  },
  assetClassButton: {
    transition: 'width .2s',
    borderRadius: '.8rem',
    padding: '0.5 3rem',
    margin: '0 0.5rem',
    noWrap: 'true',
    fontSize: 'clamp(1rem, 0.9vw, 1.5rem)',
    backgroundColor: greys.grey400,
    height: '3rem',
    color: 'white',
    fontWeight: 500,
    blockSize: 'fit-content',
    filter: `drop-shadow(0.1rem 0.1rem 0.1rem ${greys.grey400})`,
    '&:hover': {
      backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
    },
  },
  activeAssetClassButton: {
    backgroundColor: mainColors.mainBlue,
  },
  noDataMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '3rem',
    marginTop: '20vh',
    fontWeight: 500,
    color: mainColors.mainBlue,
  },
  paginationControls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '1rem 2rem 0 2rem',
  },
  paginationButtons: {
    display: 'flex',
  },
  pageToggleButton: {
    all: 'unset',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    border: 'none',
    color: 'white',
    padding: '0.5rem 1rem',
    margin: '0 0.5rem',
    cursor: 'pointer',
    borderRadius: '0.5rem',
    transition: 'background-color .2s ease-in-out',
    backgroundColor: mainColors.mainBlue,
    '&:hover': {
      backgroundColor: mainColors.hoverOverMainBlue,
    },
  },
  pageToggleButtonInactive: {
    pointerEvents: 'none',
    backgroundColor: mainColors.inactiveGrey,
  },
  pageCounts: {
    fontSize: '1.2rem',
    fontWeight: 600,
    margin: '1.5rem 1rem 0 1rem',
  },
  pageInput: {
    width: '5rem',
  },
  itemsPerPageSelect: {
    width: '5rem',
  },
}));
