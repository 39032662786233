import React, { ReactElement, useEffect, useState } from 'react';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import useFetchData from '../../../../hooks/useFetchData';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import { useDispatch } from 'react-redux';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';

import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';

import RaptorLoading from '../../../feedback/RaptorLoading';
import { SyntheticRiskComponents } from '../synthetic-risk/SyntheticRisk';
import { APPEND_NEW_SELECT_TO_TITLEBAR } from '../../../../types/redux/pages/pageTypes';
import { makeUniqueDataKey } from '../../../../utilities/urlBuilder';
import {
  addAdditionalTitleBarComponents,
  toggleTitleBarLoading,
} from '../../../../redux/pages/actions';
import { Select } from '../../../../types/redux/pages/PagesStore';
import useRouteParams from '../../../../hooks/useRouteParams';

interface syntheticRiskProps {
  fundId: string;
}

const SyntheticRisk = (props: syntheticRiskProps): ReactElement => {
  const { fundId } = props;
  const shareClass = useTitleBarSelect('shareClassSelection');

  const srriData = useFetchData({
    url: `aggregate_srri/${fundId}/${shareClass}`,
    keyName: `${fundId}_srri_${formatDateForCheckingState(
      new Date()
    )}_${shareClass}`,
    makeFetch: shareClass ? true : false,
  });

  return (
    <GeneralComponentErrorShield dataObjects={[srriData]}>
      <SyntheticRiskComponents data={srriData} />
    </GeneralComponentErrorShield>
  );
};

const SyntheticRiskShareClass = (props: FundInfoComponentProps) => {
  const { fundId } = props;
  const [shareClassOptions, setShareClassOptions] = useState<any>(null);
  const shareClass = useRouteParams('shareClass');

  // Get share classes data
  const shareClassesData = useFetchData({
    url: `get_fund_share_class_information/${fundId}`,
    keyName: makeUniqueDataKey(fundId, 'shareClasses'),
    makeFetch: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleTitleBarLoading(true));
    if (shareClassesData && !shareClassesData.isFetching) {
      setShareClassOptions(
        shareClassesData.data[0].share_classes.map((item: any) => ({
          label: item.share_class_name,
          value: item.share_class_old_gm_id,
        }))
      );
    }
  }, [shareClassesData]);

  // add share classes
  useEffect(() => {
    if (shareClassOptions) {
      const selectsToAdd: Select[] = [
        {
          titleBarTitle: 'Share Class',
          titleBarKey: 'shareClassSelection',
          values: shareClassOptions,
          displayOnOverviewPage: false,
          aValueIsAlwaysSelected: true,
          currentValue: shareClass.shareClass
            ? shareClass.shareClass
            : shareClassOptions[0].value,
        },
      ];
      dispatch(
        addAdditionalTitleBarComponents(
          selectsToAdd,
          APPEND_NEW_SELECT_TO_TITLEBAR
        )
      );
      dispatch(toggleTitleBarLoading(false));
    }
  }, [shareClassOptions]);

  const ready = shareClassesData && shareClassOptions;
  const notLoading = ready && !shareClassesData.isFetching;
  return notLoading ? (
    <SyntheticRisk fundId={fundId} />
  ) : (
    <RaptorLoading centerWrap />
  );
};
export default SyntheticRiskShareClass;
