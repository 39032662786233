import React, {
  CSSProperties,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Column,
  ColumnDef,
  ColumnSizingState,
  FilterFn,
  PaginationState,
  Row,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import GridItem from '../../../../layout/GridComponents/GridItem';
import clsx from 'clsx';

import { useDispatch } from 'react-redux';
import { Button, FormControlLabel, Switch } from '@mui/material';

import { addComponentToPdfExport } from '../../../../../redux/pdfExport/actions';
import { PdfComponentType } from '../../../../../types/redux/pdfExports/pdfExportsStore';
import { generalPositionsTableStyles } from './styles/generalPositions.styles';
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import { getPaginationRowModel } from '@tanstack/react-table';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { countTrueValues } from './utils/buildTableData';
import TablePaginator from './components/TablePaginator';
import TableSearch from './components/TableSearch';
import ExportButton from '../../../../feedback/ExportButton';
import { ESGPresetTypes } from './types/column.types';
import { DraggableTableHeader } from './components/DraggableTableHeader';
import { formatDateForCheckingState } from '../../../../../utilities/dateFormatters';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Position } from './types/position.types';
import {
  allESGPresetColumns,
  ESG_DATA,
  getStandardESGPresetWithExposureType,
} from './data/esgPresetColumnData';
import EsgColumnPopoverSelector from './components/EsgPopoverColumnSelectors';

declare module '@tanstack/react-table' {
  //add fuzzy filter to the filterFns
  interface FilterFns {
    fuzzy?: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

// Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const getCommonPinningStyles = (column: Column<Position>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn =
    isPinned === 'right' && column.getIsFirstColumn('right');

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px lightgrey inset'
      : isFirstRightPinnedColumn
      ? '4px 0 4px -4px lightgrey inset'
      : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
    background: isPinned ? 'white' : undefined,
  };
};

export type OriginalData = {
  data: ESG_DATA[];
  columns: ColumnDef<ESG_DATA>[];
  presets: Record<ESGPresetTypes, string[]>;
};

type GeneralPositionsTableProps<TData> = {
  originalData: OriginalData;
  positionDate: string | undefined;
  fundId: string;
  fundName: string;
  exposureType: ESGPresetTypes;
  getRowCanExpand: (row: Row<TData>) => boolean;
  setExposureType?: React.Dispatch<React.SetStateAction<ESGPresetTypes>>;
  pdfExportGroupName?: string;
  pdfExportGroupOrder?: number;
};

export type SmallDropdownUnderlyingPosition = {
  name: string;
  client_price: number;
  delta: string;
  position_size: string;
  risk_factor: string;
  lc_exposure: number;
  bc_exposure: number;
  gross_exposure: number;
  commitment: number;
};

// The main positions table component, which will be exported from the file
const EsgPositionsTable: React.FC<GeneralPositionsTableProps<ESG_DATA>> = ({
  originalData,
  positionDate,
  fundId,
  fundName,
  getRowCanExpand,
  exposureType,
  pdfExportGroupName,
  pdfExportGroupOrder,
}) => {
  const classes = generalPositionsTableStyles();
  const dispatch = useDispatch();

  const [localExposureType, setLocalExposureType] =
    useState<ESGPresetTypes>(exposureType);

  const [globalFilter, setGlobalFilter] = React.useState('');

  //   Memoised table data
  const { data, columns } = originalData;

  const defaultColumnVisibility = useMemo(() => {
    const vis: any = {};
    // all other columns visibilities are set by the column selections state
    getStandardESGPresetWithExposureType(
      columns,
      localExposureType
    ).columns.forEach((col) => {
      if (col.id) {
        vis[col.id] = col.meta?.show;
      }
    });

    return vis;
  }, [localExposureType, getStandardESGPresetWithExposureType]);

  // const totalAssetClasses = useMemo(() => {
  //   const assetClasses: string[] = [];
  //   data.forEach((item) => {
  //     if (item?.asset_class && !assetClasses.includes(item.asset_class)) {
  //       assetClasses.push(item.asset_class);
  //     }
  //   });
  //   return assetClasses;
  // }, [data]);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    defaultColumnVisibility
  );

  // Hacky way to render the newly visibly columns
  useEffect(() => {
    setColumnVisibility(defaultColumnVisibility);
  }, [defaultColumnVisibility]);

  const [columnOrder, setColumnOrder] = React.useState<string[]>(() =>
    columns.map((c) => c.id!)
  );

  // function to get an array of chosen column id's in selected order
  const getChosenColumsInOrder = (): string[] => {
    const orderedChoices: string[] = [];
    columnOrder.forEach((col) => {
      // [ pending removal ] 04/11/2022 Tom Walsh
      // this code relates to notes column which we have chosen to temporarily remove, maybe making permanent
      // if (columnVisibility[col] === true && col !== 'all' && col !== 'notes') {
      //   orderedChoices.push(col);
      // }

      // replacement code
      if (columnVisibility[col] === true && col !== 'index') {
        orderedChoices.push(col);
      }
      //
    });
    return orderedChoices;
  };

  // reorder columns after drag & drop
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id as string);
        const newIndex = columnOrder.indexOf(over.id as string);
        return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
      });
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10000,
  });

  const mainRenderedTable = useReactTable<Position>({
    data,
    columns,
    state: {
      columnVisibility,
      columnOrder,
      pagination,
      globalFilter,
    },

    initialState: {
      columnPinning: {
        left: ['index'],
      },
    },

    onColumnVisibilityChange: setColumnVisibility,

    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },

    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: 'fuzzy', //apply fuzzy filter to the global filter (most common use case for fuzzy filter)

    columnResizeMode: 'onChange',
    onColumnOrderChange: setColumnOrder,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  // // Hacky fix to set pagination if the row count is less than 10,000
  // React.useEffect(() => {
  //   setPagination({
  //     pageIndex: 0,
  //     pageSize:
  //       mainRenderedTable.getRowCount() < 10000
  //         ? mainRenderedTable.getRowCount()
  //         : 10000,
  //   });
  // }, [mainRenderedTable]);

  // This is not an optimal solution to reponsive cells
  // TODO: Refer to reactmaterialtable to understand responsive css function patterns
  useEffect(() => {
    // Call handleResize on first load
    const handleResize = () => {
      // Max the minimum divison by 14 columns to avoid hyper squishing

      const columnVisibilityWithoutIndex = Object.keys(columnVisibility)
        .filter((key) => key !== 'index')
        .reduce((acc, key) => {
          acc[key] = columnVisibility[key];
          return acc;
        }, {} as { [key: string]: boolean });

      const totalShownColumns = Math.min(
        countTrueValues(columnVisibilityWithoutIndex),
        14
      );
      // 100px estimated padding + index size
      const MIN_SIZE = (window.innerWidth - 200) / totalShownColumns;
      const newColumnSizes: ColumnSizingState = {};

      Object.keys(columnVisibility).forEach((columnId: string) => {
        if (columnVisibility[columnId]) {
          if (columnId === 'name') {
            newColumnSizes[columnId] = Math.max(200, MIN_SIZE);
          } else {
            newColumnSizes[columnId] = MIN_SIZE;
          }
        }
      });

      // This is the only way to update the column sizes
      // Listed here setSize function required in future - https://github.com/TanStack/table/discussions/5558
      mainRenderedTable.setColumnSizing(() => {
        const obj: Record<string, number> = {};
        columns.forEach((col: any) => {
          if (col.id !== undefined && col.id !== 'index') {
            obj[col.id] = newColumnSizes[col.id];
          }
        });
        return obj;
      });
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [columnVisibility, mainRenderedTable]);

  // function to convert react-table row model data to export ready data
  const convertRowModelForExport = (rows: Row<Position>[]) => {
    return rows.map((row) => row.original);
  };

  // function to handle pdf export
  const tablePdfExportHandler = async () => {
    const renderColumns: any = [];
    const chosenColumns = getChosenColumsInOrder();
    chosenColumns.map((chosCol) => {
      const item = columns.filter((col: any) => col.id === chosCol)[0];
      renderColumns.push({
        header: item.header,
        dataKey: item.id,
        render: item.cell,
      });
    });
    return {
      startY: 50,
      columns: renderColumns,
      body: convertRowModelForExport(mainRenderedTable.getRowModel().rows),
    };
  };

  // function to handle click events on row
  const handleRowClicked = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    switch (event.detail) {
      // in case of double click, expand row (unless aggregate row)
      case 2: {
        if (
          !isNaN(Number(rowId)) &&
          mainRenderedTable.getRowModel().rows[Number(rowId)].original
            .asset_type !== 'aggregate'
        ) {
          mainRenderedTable.getRowModel().rows[Number(rowId)].toggleExpanded();
        }
      }
    }
  };

  const tableFiltered =
    (mainRenderedTable.getState().columnFilters.length &&
      mainRenderedTable.getState().columnFilters.length > 0) ||
    mainRenderedTable.getState().globalFilter;

  const [pdfIdentifier] = useState<string>(`${fundId}_exposure_main`);

  // When column selections update, dispatch changes in pdf export data to redux
  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: pdfIdentifier,
        handler: tablePdfExportHandler,
        type: PdfComponentType.TABLE,
        title: `Exposure Data - ${fundName}${
          tableFiltered ? ' - (Filtered)' : ''
        }`,
        dontMoveToNewPage: true,
        groupName: pdfExportGroupName ?? undefined,
        groupOrder: pdfExportGroupOrder ?? undefined,
      })
    );
  }, [columns, columnOrder, tableFiltered, columnVisibility]);

  const { rows } = mainRenderedTable.getRowModel();

  // Virtualiser
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  function handleResetFilters() {
    const vis: any = {};
    // all other columns visibilities are set by the column selections state
    getStandardESGPresetWithExposureType(
      columns,
      'environmental'
    ).columns.forEach((col) => {
      if (col.id) {
        vis[col.id] = col.meta?.show;
      }
    });
    mainRenderedTable.resetColumnFilters();
    setColumnVisibility(vis);
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <GridItem
        xs={12}
        card
        sx={{
          width: '95vw',
        }}
      >
        <div className={classes.toolbar}>
          <h2 className={classes.header}>
            {tableFiltered ? 'Exposure Data (Filtered)' : 'Exposure Data'}
          </h2>

          <div className={classes.toolbarOptions}>
            <div className={classes.assetClassButtonsContainer}>
              {Object.keys(originalData.presets).map((preset) => {
                if (!preset) return;

                const selected = preset === localExposureType;

                // DEAL WITH SELECTED CATEGORY

                // Add a key here
                return (
                  <Button
                    aria-describedby={preset}
                    variant="text"
                    disableElevation
                    className={
                      selected
                        ? clsx(
                            classes.assetClassButton,
                            classes.activeAssetClassButton
                          )
                        : classes.assetClassButton
                    }
                    onClick={() => {
                      if (localExposureType === preset) {
                        setLocalExposureType('general');
                        setColumnOrder(allESGPresetColumns['general']);
                      } else {
                        setLocalExposureType(preset as ESGPresetTypes);
                        setColumnOrder(
                          allESGPresetColumns[preset as ESGPresetTypes]
                        );
                      }
                    }}
                    value={preset}
                  >
                    {preset}
                  </Button>
                );
              })}
            </div>

            <div className={classes.mainOptionsContainer}>
              <div className={classes.mainOptions}>
                <EsgColumnPopoverSelector
                  buttonName={'Choose Columns'}
                  columns={mainRenderedTable.getAllColumns()}
                  table={mainRenderedTable}
                  handleResetFilters={handleResetFilters}
                />
                {/* <PresetSelector setExposureType={setExposureType} /> */}
                <Button
                  aria-describedby={'reset'}
                  variant="text"
                  disableElevation
                  onClick={handleResetFilters}
                  className={classes.resetButton}
                >
                  Reset Filters
                </Button>
                <ExportButton
                  exportData={convertRowModelForExport(
                    mainRenderedTable.getRowModel().rows
                  )}
                  pdfIdentifier={`${pdfIdentifier}`}
                  fields={getChosenColumsInOrder()}
                  fileName={`exposure-${fundId}-${
                    positionDate || formatDateForCheckingState(new Date())
                  }`}
                  selectedPositionDate={positionDate}
                  allowPdfExport={true}
                />
                <TableSearch onChange={setGlobalFilter} />
                <span
                  style={{
                    fontSize: '1.4rem',
                    minHeight: '20px',
                    marginInline: '.25rem',
                  }}
                >
                  {tableFiltered
                    ? `Results: ${mainRenderedTable.getRowCount()}`
                    : ''}
                </span>
              </div>
            </div>

            {/* This is handling edge cases where results are over 10000 */}
            {mainRenderedTable.getRowCount() > 10000 && (
              <TablePaginator table={mainRenderedTable} />
            )}
          </div>
        </div>

        <div ref={tableContainerRef} className={classes.tableContainer}>
          <table
            className={classes.table}
            style={{
              margin: 'auto',
              display: 'grid',
              width: mainRenderedTable.getCenterTotalSize(),
            }}
          >
            <thead
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: 'white',
              }}
            >
              {mainRenderedTable.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className={classes.headerRow}
                  style={{ display: 'flex', width: '100%' }}
                >
                  <SortableContext
                    items={columnOrder}
                    strategy={horizontalListSortingStrategy}
                  >
                    {headerGroup.headers.map((header) =>
                      header.id !== 'index' ? (
                        <DraggableTableHeader
                          key={header.id}
                          header={header}
                          classes={classes}
                          table={mainRenderedTable}
                        />
                      ) : (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          className={classes.expanderHead}
                          style={{ ...getCommonPinningStyles(header.column) }}
                        >
                          {header.isPlaceholder ? null : (
                            <>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </>
                          )}
                        </th>
                      )
                    )}
                  </SortableContext>
                </tr>
              ))}
            </thead>
            <tbody
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
                position: 'relative', //needed for absolute positioning of rows
              }}
            >
              {rowVirtualizer.getVirtualItems().length ? (
                rowVirtualizer.getVirtualItems().map((virtualRow) => {
                  const row = rows[virtualRow.index] as Row<Position>;

                  return (
                    <Fragment key={row.id}>
                      <tr
                        data-index={virtualRow.index} //needed for dynamic row height measurement
                        ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                        className={classes.row}
                        style={{
                          display: 'flex',
                          position: 'absolute',
                          transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                          width: '100%',
                        }}
                        onClick={(event) => handleRowClicked(event, row.id)}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className={classes.cell}
                            style={{
                              width:
                                mainRenderedTable.getState().columnSizing[
                                  cell.column.id
                                ],
                              ...getCommonPinningStyles(cell.column),
                            }}

                            // className={
                            //   cell.id.includes('name') && !cell.id.includes('sector')
                            //     ? clsx(classes.cell, classes.cellWithWordBreak)
                            //     : classes.cell
                            // }
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    </Fragment>
                  );
                })
              ) : (
                <div className={classes.noDataMessage}>
                  No Matches For Current Filters
                </div>
              )}
            </tbody>
          </table>
        </div>
      </GridItem>
    </DndContext>
  );
};

export default EsgPositionsTable;
