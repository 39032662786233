import { Dispatch, FC, useMemo } from 'react';
import { RaptorTheme } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import {
  addCommasToNumbers,
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
  roundNumberToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GeneralSelect from '../../../selects/GeneralSelect';
import CustomTable from '../../../tables/CustomTable';
import AggregateExposureSecondLevelTableWrapper from './AggregateExposureSecondLevelTableWrapper';
import { useSelector } from 'react-redux';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import { useTheme } from '@mui/material';

export interface AgTableData {
  data: string;
  value: string | number;
  detailPanelData?: any[];
}

const dataKeys = [
  'Position Date',
  'Base Currency',
  'NAV',
  'Sum of Notionals',
  'Sum of Notionals Leverage',
  'Net Exposure',
  'Net Exposure Leverage',
  'Gross Exposure',
  'Gross Exposure Leverage',
  'Commitment',
  'Commitment Leverage',
];

interface NavPerShareSelectProps {
  data: any;
  selectedNavPerShare: string;
  setSelectedNavPerShare: Dispatch<string>;
  setNavPerShare: Dispatch<string>;
}

const NavPerShareSelect: FC<NavPerShareSelectProps> = (props) => {
  const { data, selectedNavPerShare, setSelectedNavPerShare, setNavPerShare } =
    props;
  const selectDataLabels: string[] = [];
  const selectDataValues: string[] = [];
  // Object for looking up values when select is updated.
  interface DataLookupType {
    [key: string]: any;
  }
  const DataLookup: DataLookupType = {};

  // Add the options for the select.
  data.forEach((element: any) => {
    // get the key from the current element (each object should have only a single key/value)
    const key = Object.keys(element)[0];
    const value = Object.values(element)[0];
    // special case for formatting the average weighted value.
    if (key === 'average_weighted_NAV_per_share') {
      // Ensure that avg weighted is always the first element.
      selectDataLabels.unshift('Average Weighted Nav Per Share');
      selectDataValues.unshift(key);
      DataLookup[key] = value;
    } else {
      // Otherwise no formatting is needed.
      selectDataLabels.push(key);
      selectDataValues.push(key);
      DataLookup[key] = value;
    }
  });

  // Function for updating the value column when the select is changed.
  const updateSelection = (val: string) => {
    setNavPerShare(roundNumberToTwoDecimalPlaces(DataLookup[val]));
    setSelectedNavPerShare(val);
  };

  return !selectDataLabels.length || !selectDataValues.length ? (
    <div>Nav Per Share</div>
  ) : (
    <GeneralSelect
      labels={selectDataLabels}
      values={selectDataValues}
      selected={selectedNavPerShare}
      setSelected={updateSelection}
      placeholderValue="none_selected"
      placeholderColor={'black'}
      selectTitle={''}
    />
  );
};

function getAverageWeigthedNavPerShare(data: any[]): string {
  let returnValue = '0.00';
  data.forEach((element: any) => {
    const key = Object.keys(element)[0];
    if (key === 'average_weighted_NAV_per_share')
      returnValue = roundNumberToTwoDecimalPlaces(element[key]);
  });
  return returnValue;
}
export const useAgColumns = (): CustomColumn<AgTableData>[] => {
  const generalStyle = {
    fontSize: '1.6rem',
  };
  const theme: RaptorTheme = useTheme();

  return [
    {
      title: 'DAta',
      field: 'data',
      width: '60%',
      cellStyle: { ...generalStyle },
    },
    {
      title: 'Field',
      field: 'value',

      cellStyle: {
        ...generalStyle,
        textAlign: 'right',
      },
    },
  ];
};
export const aggregateExposureCsvFields = ['data', 'value'];
export interface AggregateExposureData {
  selected_position_date: string;
  base_currency: string;
  nav: number;
  sum_of_notionals: number;
  total_net_exposure: number;
  total_gross_exposure: number;
  total_commitment: number;
  aum?: number;
}

export type IBuildAggregateData = AggregateExposureData | [];

export function buildAggregateData(inputData: any, clientName: string | null) {
  if (!inputData.length) return [];
  const data = inputData[0];
  const dataValues = [
    data.selected_position_date,
    data.base_currency,
    addCommasToNumbers(Math.round(data.nav)),
    addCommasToNumbers(Math.round(data.sum_of_notionals)),
    `${((100 * data.sum_of_notionals) / data.nav).toFixed(2)}%`,
    addCommasToNumbers(Math.round(data.total_net_exposure)),
    `${((100 * data.total_net_exposure) / data.nav).toFixed(2)}%`,
    addCommasToNumbers(Math.round(data.total_gross_exposure)),
    `${((100 * data.total_gross_exposure) / data.nav).toFixed(2)}%`,
    addCommasToNumbers(Math.round(data.total_commitment)),
    percentageToTwoDecimalPlaces(data.total_commitment, data.nav),
  ];
  const returnData: AgTableData[] = dataKeys.map((key, index) => {
    return {
      data: key,
      value: dataValues[index],
    };
  });
  // Add in Nav per share if it is available.
  if (
    'nav_price_per_share' in data &&
    typeof data.nav_price_per_share === 'number'
  ) {
    returnData.splice(3, 0, {
      data: 'NAV Per Share',
      value: roundNumberToTwoDecimalPlaces(data.nav_price_per_share),
    });
  } else if (
    'nav_price_per_share' in data &&
    Array.isArray(data.nav_price_per_share)
  ) {
    // Ensure that the values are not N/A
    if (
      data.nav_price_per_share[0][
        Object.keys(data.nav_price_per_share[0])[0]
      ] !== 'N/A'
    ) {
      returnData.splice(3, 0, {
        data: 'Average Weighted Nav Per Share',
        value: roundNumberToTwoDecimalPlaces(
          getAverageWeigthedNavPerShare(data.nav_price_per_share)
        ),
        detailPanelData: data.nav_price_per_share,
      });
    }
  }
  // Add in AUM if it is available.
  if ('aum' in data && typeof data.aum === 'number') {
    returnData.push({
      data: 'AUM',
      value: addCommasToNumbersAndRound(data.aum),
    });
  }
  // Add in default_share_class_volatility if it is available.
  if (
    'default_share_class_volatility' in data &&
    typeof data.default_share_class_volatility === 'number' &&
    clientName === 'adepa'
  ) {
    returnData.push({
      data: 'Share Class Volatility',
      value: percentageToTwoDecimalPlaces(data.default_share_class_volatility),
    });
  }
  return returnData;
}
type AggregateExposureTableProps = {
  dataForRender: DataObject;
  fundId: string;
  fundName: string;
};

const AggregateExposureTable: FC<AggregateExposureTableProps> = (props) => {
  const columns = useAgColumns();
  const { isFetching, data } = props.dataForRender;

  const clientName = useSelector(clientNameSelector);

  const tableData = useMemo(
    () => buildAggregateData(data as IBuildAggregateData, clientName),
    [data]
  );

  return (
    <CustomTable<AgTableData>
      selectedPositionDate={data[0].selected_position_date}
      id={`agg_exposure_table_${props.fundId}`}
      title={`Exposure Aggregate Data`}
      csvFields={aggregateExposureCsvFields}
      showToolbar={true}
      multipleTablePdfParameters={{
        specifiedWidth: 4,
        tablesInRow: 3,
        groupName: 'exposure_page',
        groupOrder: 1,
      }}
      loading={isFetching}
      columns={columns}
      data={tableData}
      options={{
        paging: false,
        search: false,
        header: false,
        exportButton: true,
      }}
      detailPanel={AggregateExposureSecondLevelTableWrapper()}
      pdfDontMoveToNewPage
    />
  );
};

export default AggregateExposureTable;
