import React, { FC } from 'react';
import LoggedInRoute from '../../../../routes/LoggedInRoute';
import { Redirect, Switch } from 'react-router-dom';
import DelegatedActsOverview from '../delegatedActs/overview/DelegatedActsOverview.component';
import DelegatedActsEditor from '../delegatedActs/editor/DelegatedActsEditor.component';
import { useDispatch } from 'react-redux';
import { updateSection } from '../../../../redux/pages/actions';
import FactSheetsOverview from '../factSheets/overview/FactSheetsOverview.component';
import FactSheetsEditor from '../factSheets/editor/FactSheetsEditor.component';
import PriipsKidsOverviewAllFunctionality from '../priips/overview/PriipsKidsOverviewAllFunctionality.component';
import PriipsKidsEditor from '../priips/editor/PriipsKidsEditor.component';
import PriipsKidsReview from '../priips/review/PriipsKidsReview.component';
import PriipsKidsCompareVersions from '../priips/compareVersions/PriipsCompareVersions.component';
import PriipsKidsView from '../priips/view/PriipsKidsView.component';
import UcitsKiidsOverview from '../ucitsKiids/overview/UcitsKiidsOverview.component';
import UcitsKiidsEditor from '../ucitsKiids/editor/UcitsKiidsEditor.component';
import UcitsKiidsReview from '../ucitsKiids/review/UcitsKiidsReview.component';
import UcitsKiidsCompareVersions from '../ucitsKiids/compareVersions/UcitsKiidsCompareVersions.component';
import UcitsKiidsView from '../ucitsKiids/view/UcitsKiidsView.component';

const RaidrRouter: FC = (props) => {
  // Udpate the active section to raidr
  const dispatch = useDispatch();
  dispatch(updateSection('raidr'));

  return (
    // Here we route to different sections of the delegated acts functionality
    <Switch>
      <LoggedInRoute
        exact
        path="/raidr/priips-kids/overview"
        Component={PriipsKidsOverviewAllFunctionality}
      />
      <LoggedInRoute
        exact
        path="/raidr/ucits-kiids/overview-edit"
        Component={UcitsKiidsOverview}
      />
      <LoggedInRoute
        exact
        path="/raidr/ucits-kiids/overview-view"
        Component={UcitsKiidsOverview}
      />
      <LoggedInRoute
        exact
        path="/raidr/delegated-acts/overview"
        Component={DelegatedActsOverview}
      />
      <LoggedInRoute
        exact
        path="/raidr/fact-sheets/overview"
        Component={FactSheetsOverview}
      />
      <LoggedInRoute
        exact
        path="/raidr/delegated-acts/editor"
        Component={DelegatedActsEditor}
      />
      <LoggedInRoute
        exact
        path="/raidr/fact-sheets/editor"
        Component={FactSheetsEditor}
      />
      <LoggedInRoute
        exact
        path="/raidr/priips-kids/editor"
        Component={PriipsKidsEditor}
      />
      <LoggedInRoute
        exact
        path="/raidr/ucits-kiids/editor"
        Component={UcitsKiidsEditor}
      />
      <LoggedInRoute
        exact
        path="/raidr/priips-kids/review"
        Component={PriipsKidsReview}
      />
      <LoggedInRoute
        exact
        path="/raidr/ucits-kiids/review"
        Component={UcitsKiidsReview}
      />
      <LoggedInRoute
        exact
        path="/raidr/priips-kids/compare-versions"
        Component={PriipsKidsCompareVersions}
      />
      <LoggedInRoute
        exact
        path="/raidr/ucits-kiids/compare-versions"
        Component={UcitsKiidsCompareVersions}
      />
      <LoggedInRoute
        exact
        path="/raidr/priips-kids/view"
        Component={PriipsKidsView}
      />
      <LoggedInRoute
        exact
        path="/raidr/ucits-kiids/view"
        Component={UcitsKiidsView}
      />

      <Redirect
        exact
        from="/raidr/delegated-acts"
        to="/raidr/delegated-acts/overview"
      />
      <Redirect
        exact
        from="/raidr/fact-sheets"
        to="/raidr/fact-sheets/overview"
      />
      <Redirect
        exact
        from="/raidr/priips-kids"
        to="/raidr/priips-kids/overview"
      />
      <Redirect
        exact
        from="/raidr/ucits-kiids"
        to="/raidr/ucits-kiids/overview-view"
      />
    </Switch>
  );
};

export default RaidrRouter;
