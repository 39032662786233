import React, { Dispatch, FC } from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import {
  ContentState,
  DraftHandleValue,
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { mainColors } from '../../../../../../styling/theme';
import GenericBarChart from '../../../../../charts/GenericBarChart';

interface TableEditorTileProps {
  fundId: string | null;
  content: string;
  testSpan?: 'column' | 'full';
  formatOptions?: string;
  index: number;
  setKiidsContent: Dispatch<any>;
  kiidsContent: any[];
  setChangesMade: Dispatch<any>;
}

interface TableData {
  [key: string]: TableRowData;
}
interface TableRowData {
  [key: string]: EditorState;
}

function buildTableData(data: any): TableData {
  const returnData: TableData = {};
  if (!data.length) return {};
  // Account for cases where the data is a flat array (one column table)
  if (data[0].constructor === Array) {
    // Loop over each row of the table
    data.forEach((row: any, rowIndex: number) => {
      // create an object for storing the current row's data.
      const currentRow: TableRowData = {};
      // Now Loop through each column in the row.
      row.forEach((column: any, columnIndex: number) => {
        // Create an editor state object from the cell contents.
        const blocksFromHTML = convertFromHTML(column.toString());
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks
        );
        const initialState = EditorState.createWithContent(contentState);
        // Add each column to the row.
        currentRow[`col_${columnIndex}`] = initialState;
      });
      // Add the row to the table.
      returnData[`row_${rowIndex}`] = currentRow;
    });
  } else {
    // This is the flat array case
    // Loop through each row.
    data.forEach((row: any, rowIndex: number) => {
      // Create an editor state object from the cell contents.
      const blocksFromHTML = convertFromHTML(row);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks
      );
      const initialState = EditorState.createWithContent(contentState);
      returnData[`row_${rowIndex}`] = { col_1: initialState };
    });
  }
  return returnData;
}

const KiidsbarChartEditorTile: FC<TableEditorTileProps> = (props) => {
  // const blocksFromHTML = convertFromHTML(props.text || '');
  // const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks);
  // const initialState = EditorState.createWithContent(contentState);

  // const [editorState, setEditorState] = React.useState<EditorState>(initialState);
  const {
    fundId,
    content,
    setKiidsContent,
    kiidsContent,
    index,
    setChangesMade,
  } = props;

  // Convert the table data to an array
  const [contentState, setContentState] = React.useState(
    JSON.parse(props.content || '[]')
  );
  // Create a table data object from the array.
  const tableData = buildTableData(contentState);
  // Create an object for storing the state of each cell in the table.
  const [editorState, setEditorState] = React.useState<TableData>(tableData);
  const handleTextChange = (
    updatedEditorState: EditorState,
    rowIndex: number,
    columnIndex: number
  ) => {
    // Get the current editor state of the whole table.
    const currentEditorState = editorState;
    // Now update the cell that was changed.
    currentEditorState[`row_${rowIndex}`][`col_${columnIndex}`] =
      updatedEditorState;
    // Update the overall table state.
    setEditorState(currentEditorState);

    // Convert the stateEditors to an array.
    const updatedArray: any[] = [];
    Object.keys(editorState).forEach((rowKey: string) => {
      const rowData: any[] = [];
      Object.keys(editorState[rowKey]).forEach((columnKey: string) => {
        let htmlText = stateToHTML(
          editorState[rowKey][columnKey].getCurrentContent()
        );
        htmlText = htmlText
          .replace('<p>', '')
          .replace('</p>', '')
          .replace('<strong>', '<b>')
          .replace('</strong>', '</b>')
          .replace('<em>', '<i>')
          .replace('</em>', '</i>');
        // Convert data to floats (Aprat from the year - col0)
        //TODO: Need to Add Check that year is actaually a number too
        if (columnKey === 'col_0') rowData.push(htmlText);
        else rowData.push(parseFloat(htmlText));
      });
      updatedArray.push(rowData);
    });
    // Now update the contentState array.
    setContentState(updatedArray);

    // Use the Content state array to update the overall state of the KIID.
    const allContent = kiidsContent;
    const currentContentAtIndex = kiidsContent[index];
    // Check if the data has changed to indicate that a save can be made.
    if (currentContentAtIndex.content !== JSON.stringify(contentState)) {
      setChangesMade(true);
    }
    currentContentAtIndex.content = JSON.stringify(contentState);
    allContent[index] = currentContentAtIndex;
  };

  const handleKeyCommand = (
    command: string,
    rowIndex: number,
    columnIndex: number
  ): DraftHandleValue => {
    // Don't allow new lines.
    if (command === 'split-block') return 'handled';
    // Get the current editor state of the whole table.
    const currentEditorState =
      editorState[`row_${rowIndex}`][`col_${columnIndex}`];
    const newState = RichUtils.handleKeyCommand(currentEditorState, command);
    if (newState) {
      const overallEditorState = editorState;
      overallEditorState[`row_${rowIndex}`][`col_${columnIndex}`] = newState;
      setEditorState(overallEditorState);
      // Convert the stateEditors to an array.
      const updatedArray: any[] = [];
      Object.keys(editorState).forEach((rowKey: string) => {
        const rowData: any[] = [];
        Object.keys(editorState[rowKey]).forEach((columnKey: string) => {
          let htmlText = stateToHTML(
            editorState[rowKey][columnKey].getCurrentContent()
          );
          htmlText = htmlText
            .replace('<p>', '')
            .replace('</p>', '')
            .replace('<strong>', '<b>')
            .replace('</strong>', '</b>')
            .replace('<em>', '<i>')
            .replace('</em>', '</i>');
          rowData.push(htmlText);
        });
        updatedArray.push(rowData);
      });
      // Now update the contentState array.
      setContentState(updatedArray);

      // Use the Content state array to update the overall state of the KIID.
      const allContent = kiidsContent;
      const currentContentAtIndex = kiidsContent[index];
      currentContentAtIndex.data = JSON.stringify(contentState);
      allContent[index] = currentContentAtIndex;
      return 'handled';
    }
    return 'not-handled';
  };

  return (
    <GridItem
      xs={12}
      card
      style={{
        marginBottom: '1rem',
      }}
    >
      <div
        style={{
          padding: '1rem',
          fontSize: '1.5rem',
          minHeight: '10rem',
          // display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <div
          style={{
            padding: '1rem',
            fontSize: '1.5rem',
            // minHeight: '10rem',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <BarChartDisplay barChartData={contentState} />
        </div>
        <div
          style={{
            padding: '4rem',
            fontSize: '1.5rem',
            // marginRight: '4rem',
            // marginLeft: '50%',
            // width: '1%',
            // minHeight: '10rem',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <TableEditor
            tableContents={editorState}
            handleChange={handleTextChange}
            handleKeyCommand={handleKeyCommand}
          />
        </div>
      </div>
    </GridItem>
  );
};

interface TableEditorProps {
  tableContents: TableData;
  handleChange: (
    updatedEditorState: EditorState,
    rowIndex: number,
    ColumnIndex: number
  ) => void;
  handleKeyCommand: (
    command: string,
    rowIndex: number,
    columnIndex: number
  ) => DraftHandleValue;
}

const TableEditor: FC<TableEditorProps> = (props) => {
  const { tableContents, handleChange, handleKeyCommand } = props;

  return (
    <table>
      {
        // Go through each row of the table
        Object.keys(tableContents).map((rowKey: string, rowIndex: number) => {
          // Go through each column in the row.
          return (
            <tr key={`row_number_${rowIndex}`}>
              {
                // Go through each column in the row.
                Object.keys(tableContents[rowKey]).map(
                  (columnKey: string, columnIndex) => {
                    // Add column to table.
                    return (
                      <td
                        key={`row_number_${rowIndex}_column_number_${columnIndex}`}
                      >
                        <div
                          style={{
                            border: '0.5px solid',
                            width: '25rem',
                            // width: '200%',
                            // minHeight: '8rem',
                            // marginTop: '0.5rem'
                          }}
                        >
                          <Editor
                            editorState={tableContents[rowKey][columnKey]}
                            onChange={(updatedEditorState: EditorState) => {
                              handleChange(
                                updatedEditorState,
                                rowIndex,
                                columnIndex
                              );
                            }}
                            stripPastedStyles={true}
                            handleKeyCommand={(
                              command: string,
                              editorState: EditorState,
                              eventTimeStamp: number
                            ) => {
                              return handleKeyCommand(
                                command,
                                rowIndex,
                                columnIndex
                              );
                            }}
                            // blockRendererFn={mediaBlockRenderer}
                          />
                        </div>
                      </td>
                    );
                  }
                )
              }
            </tr>
          );
        })
      }
    </table>
  );
};

interface BarChartDisplayProps {
  barChartData: any[];
}

// Just allow for 5 possible bars colours for the moment
const barColours = [
  mainColors.mainBlue,
  mainColors.Fail,
  mainColors.pumpkin,
  mainColors.fourthPurple,
  mainColors.Pass_darker,
];

function createBars(chartData: any) {
  if (!chartData.length) return [];
  // Create an array for storing the bars.
  const bars: any[] = [];
  // Get the keys of the first Element of the chart Data (only need one to create the bars)
  const dataKeys = Object.keys(chartData[0]);
  // Remove the year from the arry (this will be the datakey)
  const yearIndex = dataKeys.indexOf('year');
  if (yearIndex !== -1) dataKeys.splice(yearIndex, 1);
  else return [];
  // Loop and add the bars.
  for (let i = 1; i <= dataKeys.length; i++) {
    bars.push({
      dataKey: `key_${i}`,
      key: `key_${i}`,
      fill: barColours[i - 1],
    });
  }
  return bars;
}

function buildDisplayChartData(data: any) {
  if (!data.length) return [];
  // Deterime how many bars are going to be in the chart (-1 for the year)
  const numBars = data[0].length - 1;
  if (!(numBars > 0)) return [];
  // Create an array for storing the data.
  const displayChartData: any[] = [];
  // Create a type for bars.
  interface barsType {
    [key: string]: number | string;
  }
  // Loop through the data and add the bars.
  data.forEach((value: any) => {
    // Create a bars object.
    const bars: barsType = {
      year: value[0],
    };
    for (let i = 1; i < numBars + 1; i++) {
      bars[`key_${i}`] = parseFloat(value[i]);
    }
    displayChartData.push(bars);
  });
  return displayChartData;
}

const BarChartDisplay: FC<BarChartDisplayProps> = (props) => {
  const { barChartData } = props;
  const chartData = buildDisplayChartData(barChartData);
  const bars = createBars(chartData);

  // Set the x axis props
  const xAxis = [
    {
      dataKey: 'year',
      label: { value: 'Year', dy: 10 },
    },
  ];

  return (
    <GenericBarChart
      xAxes={xAxis}
      height={400}
      width={'100%'}
      bars={bars}
      data={chartData}
      title={''}
      loading={false}
      legend={false}
      yAxisLabel={{
        angle: -90,
        position: 'left',
        value: '',
        style: {
          textAnchor: 'middle',
          // fill: theme.palette.grey[500],
        },
      }}
      legendWrapper={{ left: 0 }}
      legendLayout="horizontal"
      legendPositionHorizontal="center"
      legendPositionVertical="top"
      margin={{ top: 0, bottom: 0, left: 50, right: 50 }}
      removeTooltip
      // legendFormatter={legendFormatter}
      // tooltipFormatter={tooltipFormatter}
    />
  );
};

export default KiidsbarChartEditorTile;
