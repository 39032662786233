import makeStyles from '@mui/styles/makeStyles';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import { mainColors } from '../../../../../../styling/theme';
import { FC, useState } from 'react';
import { EditorTileProps } from './PriipsKidsEditorColumn.component';
import PriipsKidsEditorGridItem from '../../layout/PriipsKidsEditorGridItem.component';
import CustomTable from '../../../../../tables/CustomTable';
import UtilityButtons from '../../buttons/UtilityButtons.component';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { ContentState, Editor, EditorState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { PriipsKidsData } from '../UcitsKiidsEditor.component';

const useStyles = makeStyles(() => ({
  textEditorBox: {
    // borderColor: 'black',
    marginTop: '2rem',

    marginBottom: '2rem',
    marginLeft: '1rem',
    // marginRight: '1rem',
    fontSize: '1.5rem',
    boxSizing: 'border-box',
    padding: '1rem',
    borderRadius: '6px',
    width: '100%',
    minHeight: '10rem',
  },
  outerContainer: {
    border: '2px solid',
    borderColor: mainColors.mainBlue,
    backgroundColor: 'white',
    padding: '1rem',
  },
  editorContainer: {
    display: 'flex',
    borderRadius: '6px',
    flexDirection: 'row',
    width: '100%',
    marginRight: '1rem',
  },
  formatContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '1rem',
    gap: '2rem',
  },
  button: {
    height: '3rem',
    // marginTop: '0.2rem',
    marginRight: '1rem',
    width: '15rem',
  },
}));

interface BenchmarkTableData {
  assetClass: EditorState;
  benchmark: EditorState;
  weight: EditorState;
  index: number;
}

function buildBenchmarkTableData(content: any[]): BenchmarkTableData[] {
  const tableData: BenchmarkTableData[] = [];
  // Loop over each row of the table
  // data.forEach((row: any, rowIndex: number) => {
  //     // create an object for storing the current row's data.
  //     const currentRow: TableRowData = {};
  //     // Now Loop through each column in the row.
  //     row.forEach((column: any, columnIndex: number) => {
  //         // Create an editor state object from the cell contents.
  //         const blocksFromHTML = convertFromHTML(column);
  //         const contentState = ContentState.createFromBlockArray(
  //             blocksFromHTML.contentBlocks
  //         );
  //         const initialState = EditorState.createWithContent(contentState);
  //         // Add each column to the row.
  //         currentRow[`col_${columnIndex}`] = initialState;
  //     });
  //     // Add the row to the table.
  //     returnData[`row_${rowIndex}`] = currentRow;
  // });
  // Skpi the first element in the array as this is a header
  for (let i = 1; i < content.length; i++) {
    // Create an editor state object for each column
    const assetClassBlocks = convertFromHTML(content[i][0]);
    const assetClassContentState = ContentState.createFromBlockArray(
      assetClassBlocks.contentBlocks
    );
    const assetClassInitialState = EditorState.createWithContent(
      assetClassContentState
    );

    const benchmarkBlocks = convertFromHTML(content[i][1]);
    const benchmarkContentState = ContentState.createFromBlockArray(
      benchmarkBlocks.contentBlocks
    );
    const benchmarkInitialState = EditorState.createWithContent(
      benchmarkContentState
    );

    const weightBlocks = convertFromHTML(content[i][2]);
    const weightContentState = ContentState.createFromBlockArray(
      weightBlocks.contentBlocks
    );
    const weightInitialState =
      EditorState.createWithContent(weightContentState);
    tableData.push({
      assetClass: assetClassInitialState,
      benchmark: benchmarkInitialState,
      weight: weightInitialState,
      index: i,
    });
  }
  return tableData;
}

function buildColumns(
  handleChange: (
    updatedEditorState: EditorState,
    rowIndex: number,
    column: 'assetClass' | 'benchmark' | 'weight'
  ) => void
): CustomColumn<BenchmarkTableData>[] {
  const columns: CustomColumn<BenchmarkTableData>[] = [
    {
      title: 'Asset Class',
      field: 'assetClass',
      cellStyle: {
        width: '20%',
        border: '1px solid #1875d2',
        padding: '0.5rem',
        textAlign: 'center',
        backgroundColor: 'white',
      },
      headerStyle: {
        width: '20%',
        // border: '1px solid white',
        padding: '0.5rem',
        textAlign: 'center',
        color: 'white',
        backgroundColor: mainColors.controlButtonBlue,
      },
      render: (rowData: BenchmarkTableData) => (
        <Editor
          editorState={rowData.assetClass}
          onChange={(updatedEditorState: EditorState) => {
            handleChange(updatedEditorState, rowData.index - 1, 'assetClass');
          }}
          stripPastedStyles={true}
        />
      ),
    },
    {
      title: 'Benchmark',
      field: 'benchmark',
      cellStyle: {
        width: '20%',
        border: '1px solid #1875d2',
        padding: '0.5rem',
        textAlign: 'center',
        backgroundColor: 'white',
      },
      headerStyle: {
        width: '20%',
        // border: '1px solid white',
        padding: '0.5rem',
        textAlign: 'center',
        color: 'white',
        backgroundColor: mainColors.controlButtonBlue,
      },
      render: (rowData: BenchmarkTableData) => (
        <Editor
          editorState={rowData.benchmark}
          onChange={(updatedEditorState: EditorState) => {
            handleChange(updatedEditorState, rowData.index - 1, 'benchmark');
          }}
          stripPastedStyles={true}
        />
      ),
    },
    {
      title: 'Weight',
      field: 'weight',
      cellStyle: {
        border: '1px solid #1875d2',
        padding: '0.5rem',
        textAlign: 'center',
        backgroundColor: 'white',
      },
      headerStyle: {
        // border: '1px solid white',
        padding: '0.5rem',
        textAlign: 'center',
        color: 'white',
        backgroundColor: mainColors.controlButtonBlue,
      },
      render: (rowData: BenchmarkTableData) => (
        <Editor
          editorState={rowData.weight}
          onChange={(updatedEditorState: EditorState) => {
            handleChange(updatedEditorState, rowData.index - 1, 'weight');
          }}
          stripPastedStyles={true}
        />
      ),
    },
  ];

  return columns;
}

const BenchmarkTableEditorTile: FC<EditorTileProps> = ({
  priipsKidsElement,
  setPriipsKidsContent,
  updateFieldType,
  moveField,
  deleteField,
}) => {
  // Store the index of the current element in the overall data
  const index = priipsKidsElement.kiidIndex;
  // Store the field ID of the current element
  const fieldId = priipsKidsElement.fieldId;
  // Convert the content to a javascript object
  const content = JSON.parse(priipsKidsElement.content);
  const [tableData, setTableData] = useState<BenchmarkTableData[]>(
    buildBenchmarkTableData(content)
  );
  // Create an object for storing the state of each cell in the table.
  const [editorState, setEditorState] =
    useState<BenchmarkTableData[]>(tableData);

  const classes = useStyles();

  function addRemoveRow(action: 'add' | 'remove') {
    if (action === 'add') {
      const newTableData = [...tableData];
      newTableData.push({
        assetClass: EditorState.createEmpty(),
        benchmark: EditorState.createEmpty(),
        weight: EditorState.createEmpty(),
        index: newTableData.length + 1,
      });
      setTableData(newTableData);
      setEditorState(newTableData);

      setPriipsKidsContent((prev: any) => {
        const content = prev[index].content;
        const parsedContent = JSON.parse(content);
        parsedContent.push(['', '', '']);
        prev[index].content = JSON.stringify(parsedContent);
        return [...prev];
      });
    } else {
      const newTableData = [...tableData];
      newTableData.pop();
      setTableData(newTableData);
      setEditorState(newTableData);
      setPriipsKidsContent((prev: any) => {
        const content = prev[index].content;
        const parsedContent = JSON.parse(content);
        parsedContent.pop();
        prev[index].content = JSON.stringify(parsedContent);
        return [...prev];
      });
    }
  }

  const handleTextChange = (
    updatedEditorState: EditorState,
    rowIndex: number,
    column: 'assetClass' | 'benchmark' | 'weight'
  ) => {
    // Get the current editor state of the whole table.
    const currentEditorState = editorState;
    // Now update the cell that was changed.
    currentEditorState[rowIndex][column] = updatedEditorState;
    // Update the overall table state.
    setEditorState(currentEditorState);
    // Update the table data
    const updatedTableData = [...tableData];
    updatedTableData[rowIndex][column] = updatedEditorState;
    setTableData(updatedTableData);

    // Create an array of the raw data
    const updatedArray: any[] = [['Asset Class', 'Benchmark', 'Weight']];
    tableData.forEach((row) => {
      const rowData = [];
      rowData.push(stateToHTML(row.assetClass.getCurrentContent()));
      rowData.push(stateToHTML(row.benchmark.getCurrentContent()));
      rowData.push(stateToHTML(row.weight.getCurrentContent()));
      updatedArray.push(rowData);
    });
    // Update the overall data
    setPriipsKidsContent((allContent: PriipsKidsData[]) => {
      const newContent = [...allContent];
      newContent[index].content = JSON.stringify(updatedArray);
      return newContent;
    });
  };

  const tableColumns = buildColumns(handleTextChange);

  return (
    <PriipsKidsEditorGridItem xs={12}>
      <div className={classes.outerContainer}>
        <div className={classes.editorContainer}>
          <div className={classes.textEditorBox}>
            <CustomTable<BenchmarkTableData>
              columns={tableColumns}
              id={'benchmark_weight_table'}
              showToolbar={false}
              data={tableData}
              options={{
                paging: false,
                search: false,
                exportButton: false,
                sorting: false,
                draggable: false,
                toolbar: false,
              }}
            />
          </div>
          <UtilityButtons
            moveField={moveField}
            index={index}
            deleteField={deleteField}
            fieldId={fieldId}
            disableDelete
            disableDeleteMessage="You cannot delete the benchmark table"
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '1rem',
          }}
        >
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => addRemoveRow('add')}
            endIcon={<AddCircleIcon style={{ color: 'white' }} />}
          >
            <div style={{ color: 'white' }}>Add Row</div>
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => addRemoveRow('remove')}
            endIcon={<RemoveCircleIcon style={{ color: 'white' }} />}
            disabled={tableData.length == 1}
          >
            <div style={{ color: 'white' }}>Remove Row</div>
          </Button>
        </div>
      </div>
    </PriipsKidsEditorGridItem>
  );
};

export default BenchmarkTableEditorTile;
