import { EquityServerData } from '../table/table.data';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlacesNoHundred,
} from '../../../../../../utilities/numberFormatters';
import { EquityDataTypeOption } from '../table/table.component';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';

export interface EquityMapData {
  [key: string]: number;
}

export interface EquityMapTableData {
  country: string;
  exposure: number;
  pcOfNav: number;
}

function navify(val: number, nav: number) {
  return (val * 100) / nav;
}

function formatData(
  value: number,
  nav: number,
  dataSelection: EquityDataTypeOption
) {
  switch (dataSelection) {
    case EquityDataTypeOption.NET_BASE_CURRENCY:
      return value;
    case EquityDataTypeOption.GROSS_BASE_CURRENCY:
      return Math.abs(value);
    case EquityDataTypeOption.NET_PC:
      return navify(value, nav);
    case EquityDataTypeOption.GROSS_PC:
      return Math.abs(navify(value, nav));
    default:
      return value;
  }
}

export const countryTableColumns: CustomColumn<EquityMapTableData>[] = [
  {
    title: 'Country',
    field: 'country',
  },
  {
    title: 'Base Currency Exposure',
    field: 'exposure',
    render: (rowData) => addCommasToNumbersAndRound(rowData.exposure),
  },
  {
    title: '% of NAV',
    field: 'pcOfNav',
    render: (rowData) => percentageToTwoDecimalPlacesNoHundred(rowData.pcOfNav),
  },
];

export function generateEquityMapData(
  inputData: EquityServerData[],
  selectedPage: 'grossCountryExposure' | 'netCountryExposure'
) {
  if (!inputData.length) return [];
  const data = inputData[0].equity_data;
  const nav = inputData[0].nav;
  const returnObj: EquityMapData = {};
  // if it's gross, you have to absolute val each value as it goes in
  data.forEach((equityDatum) => {
    const valToAdd =
      selectedPage === 'grossCountryExposure'
        ? Math.abs(equityDatum.position_exposure)
        : equityDatum.position_exposure;
    if (equityDatum.country_code in returnObj) {
      returnObj[equityDatum.country_code] += valToAdd;
    } else {
      returnObj[equityDatum.country_code] = valToAdd;
    }
  });

  // Object.keys(returnObj).forEach((el: string) => {
  //   returnObj[el] = formatData(returnObj[el], nav, dataSelection);
  // });

  const maxVal = Math.max(...Object.values(returnObj));

  const mapTableData: EquityMapTableData[] = Object.keys(returnObj).map(
    (key) => {
      return {
        country: key,
        exposure: returnObj[key],
        pcOfNav: (returnObj[key] * 100) / nav,
      };
    }
  );
  return [returnObj, maxVal, mapTableData];
}
