import { useEffect, useState } from 'react';
import requestClient from '../../../../../../../utilities/requestClient';
import { DataObject } from '../../../../../../../types/redux/data/dataTypes';

interface IProps {
  fetchString: string;
}

const useExceedanceSecondaryData = (props: IProps) => {
  const fetcher = requestClient();
  const [returnData, setReturnData] = useState<DataObject>({
    data: [],
    error: null,
    isFetching: false,
  });
  useEffect(() => {
    setReturnData({
      ...returnData,
      isFetching: true,
    });
    fetcher
      .get(props.fetchString)
      .then((data) => {
        setReturnData({
          ...returnData,
          data: [data],
          isFetching: false,
        });
      })
      .catch((err) =>
        setReturnData({
          ...returnData,
          error: err,
          data: [],
          isFetching: false,
        })
      );
  }, []);

  return returnData;
};

export default useExceedanceSecondaryData;
