import React, { useRef, useEffect } from 'react';

interface SimpleLineChartProps {
  dataPoints: number[];
  returnDay: number;
}

const SimpleLineChart: React.FC<SimpleLineChartProps> = ({ dataPoints, returnDay }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const padding = 3;
    const minValue = Math.min(...dataPoints);
    const maxValue = Math.max(...dataPoints);
    const valueRange = maxValue - minValue;

    const scaleY = (value: number) => {
      return (
        canvas.height -
        padding -
        ((value - minValue) / valueRange) * (canvas.height - 2 * padding)
      );
    };

    const lineColor = returnDay >=0 ? 'green' : 'red';

    const firstY = scaleY(dataPoints[0]);

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.beginPath();
    ctx.moveTo(padding, firstY);
    ctx.lineTo(canvas.width - padding, firstY);
    ctx.setLineDash([2, 2]); // [dashLength, gapLength]
    ctx.strokeStyle = '#2c2c2c';
    ctx.lineWidth = 1;
    ctx.stroke();

    // Reset the line dash to solid for subsequent lines
    ctx.setLineDash([]);

    ctx.beginPath();
    ctx.moveTo(padding, firstY);

    for (let i = 1; i < dataPoints.length; i++) {
      const x =
        padding + ((canvas.width - 2 * padding) / (dataPoints.length - 1)) * i;
      const y = scaleY(dataPoints[i]);
      ctx.lineTo(x, y);
    }

    ctx.lineTo(canvas.width - padding, firstY);
    ctx.lineTo(padding, firstY);

    // Create a gradient for shading
    const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
    if (lineColor === 'green') {
      gradient.addColorStop(0, 'rgba(77, 228, 39, 0.6)');
      gradient.addColorStop(1, 'rgba(221, 250, 213, 0.2)');
    } else {
      gradient.addColorStop(0, 'rgba(255, 0, 0, 0.4)');
      gradient.addColorStop(1, 'rgba(255, 216, 216, 0.2)');
    }

    ctx.fillStyle = gradient;
    ctx.fill();

    ctx.beginPath();
    ctx.moveTo(padding, firstY);
    for (let i = 1; i < dataPoints.length; i++) {
      const x =
        padding + ((canvas.width - 2 * padding) / (dataPoints.length - 1)) * i;
      const y = scaleY(dataPoints[i]);
      ctx.lineTo(x, y);
    }
    ctx.strokeStyle = lineColor;
    ctx.lineWidth = 1.5;
    ctx.stroke();

    const lastX =
      padding +
      ((canvas.width - 2 * padding) / (dataPoints.length - 1)) *
        (dataPoints.length - 1);
    const lastY = scaleY(dataPoints[dataPoints.length - 1]);

    const circleRadius = 2; // Adjust the radius size as needed

    // Draw and fill the circle
    ctx.beginPath();
    ctx.arc(lastX, lastY, circleRadius, 0, 2 * Math.PI);
    ctx.fillStyle = lineColor;
    ctx.fill();

    ctx.strokeStyle = lineColor;
    ctx.lineWidth = 1.5;
    ctx.stroke();
  }, [dataPoints]);

  return <canvas ref={canvasRef} height="20em" width="65em"></canvas>;
};

export default SimpleLineChart;
