import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { ILawFirstLevelTableData } from './LawFirstLevelTable';
import SecondLevelTable from './SecondLevelTable';

const secondLevelTableWrapper = (
  current: boolean,
  activeFund: { fundId: string; fundName: string }
) => [
  (rowData: ILawFirstLevelTableData) => {
    const icon = () => (rowData.assets.length ? <UnfoldMore /> : <></>);
    const render = () => (
      <SecondLevelTable
        data={rowData}
        fundDetails={activeFund}
        ruleNumber={rowData.rule_number}
        positionDate={rowData.positionDate}
      />
    );
    return {
      disabled: !rowData.assets.length || !current,
      icon,
      openIcon: UnfoldLess,
      render,
    };
  },
];

export default secondLevelTableWrapper;
