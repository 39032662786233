import React, { FC, useEffect } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import useFetchData from '../../../../hooks/useFetchData';
import LawFirstLevelTable from './lawComponents/LawFirstLevelTable';
import {
  formatFundUrl,
  makeUniqueDataKey,
} from '../../../../utilities/urlBuilder';
import { addAdditionalTitleBarComponents, removeTitleBarComponent, updateTitleBarSelectValue } from '../../../../redux/pages/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../../../../types/redux/pages/PagesStore';
import { APPEND_NEW_SELECT_TO_TITLEBAR, REMOVE_SELECT_FROM_TITLEBAR } from '../../../../types/redux/pages/pageTypes';
import { activePageSelector } from '../../../../redux/pages/selectors';

const NursLaw: FC<FundInfoComponentProps> = ({
  fundId,
  fundName,
  positionDate,
}) => {
  
  const dispatch = useDispatch();
  const activePage = useSelector(activePageSelector);

  // Add the select components to the title bar
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: 'Data Display: ',
        titleBarKey: 'dataDisplay',
        values: [
          {
            label: 'Current',
            value: 'current',
          },
          {
            label: 'Historical',
            value: 'historical',
          },
        ],
        displayOnOverviewPage: false,
        aValueIsAlwaysSelected: true,
        currentValue: 'current',
      },
    ];
    
    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR
      )
    );

    return () => {
      dispatch(removeTitleBarComponent('dataDisplay', REMOVE_SELECT_FROM_TITLEBAR));
    };

  }, [activePage]);

  const nursComplianceChecklist = useFetchData({
    url: 'compliance_checklist/NURS',
    keyName: 'nurs_compliance_checklist',
    makeFetch: true,
  });
  const basicDataAgg = useFetchData({
    url: formatFundUrl('basic_data_agg', fundId, positionDate),
    keyName: makeUniqueDataKey(fundId, 'basicDataAgg', positionDate), // this is the same as var
    makeFetch: true,
  });

  const nursFundData = useFetchData({
    url: formatFundUrl('fund_NURS_data', fundId, positionDate),
    keyName: makeUniqueDataKey(fundId, 'nursFundData', positionDate),
    makeFetch: true,
  });

  return nursComplianceChecklist && basicDataAgg && nursFundData ? (
    <LawFirstLevelTable
      dataSource={{
        complianceChecklist: nursComplianceChecklist,
        basicDataAgg,
        nursFundData,
      }}
      activeFundName={fundName}
      activeFund={fundId}
    />
  ) : null;
};

export default NursLaw;
