import React, { ReactText, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useTitleBarSelect from '../../../../../hooks/useTitleBarSelect';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../../../redux/pages/actions';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { Select } from '../../../../../types/redux/pages/PagesStore';
import {
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../../../../../types/redux/pages/pageTypes';
import { addCommasToNumbersAndRound } from '../../../../../utilities/numberFormatters';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { Map } from '../../../sharedComponents/overview/subComponents/DetailMap.component';
import { FixedIncomeComponent } from '../FixedIncome';
import ExposureGraphicsBarChart from './components/barChart/barChart.component';
import {
  CountryExposureData,
  FixedIncomeExposureOptionType,
  IGeneralExposureData,
  generateExposureColumns,
  generateExposureGraphicsData,
  generateFixedIncomeMapColumns,
} from './exposureGraphics.data';

type IExposureGraphics = FixedIncomeComponent;

const ExposureGraphics = (props: IExposureGraphics) => {
  const localStorageKey = 'EXPOSURE_TYPE_FIXED_INCOME';
  const exposureType: FixedIncomeExposureOptionType =
    (useTitleBarSelect('exposureType') as FixedIncomeExposureOptionType) ||
    (localStorage.getItem(localStorageKey) as FixedIncomeExposureOptionType) ||
    FixedIncomeExposureOptionType.MATURITY;

  const { data } = props.inputData;

  const columns = !['grossCountryExposure', 'netCountryExposure'].includes(
    exposureType
  )
    ? generateExposureColumns(exposureType as FixedIncomeExposureOptionType)
    : generateFixedIncomeMapColumns();
  const outputData = generateExposureGraphicsData(
    data,
    exposureType as FixedIncomeExposureOptionType
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: 'Exposure Type:',
        titleBarKey: 'exposureType',
        values: [
          {
            label: 'Maturity',
            value: 'maturity',
          },
          {
            label: 'Credit',
            value: 'credit',
          },
          {
            label: 'Sector',
            value: 'sector',
          },
          {
            label: 'Gross Country Exposure',
            value: 'grossCountryExposure',
          },
          {
            label: 'Net Country Exposure',
            value: 'netCountryExposure',
          },
        ],
        displayOnOverviewPage: false,
        aValueIsAlwaysSelected: true,
        currentValue: localStorage.getItem(localStorageKey) || 'maturity',
      },
    ];
    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR
      )
    );

    return () => {
      dispatch(
        removeTitleBarComponent('exposureType', REMOVE_SELECT_FROM_TITLEBAR)
      );
    };
  }, []);

  useEffect(() => {
    localStorage.setItem(localStorageKey, exposureType);
  }, [exposureType]);

  return (
    <>
      <GridItem
        xs={12}
        xl={3}
        card
        style={{ display: 'flex', alignSelf: 'stretch' }}
      >
        {!['grossCountryExposure', 'netCountryExposure'].includes(
          exposureType
        ) ? (
          <CustomTable<IGeneralExposureData>
            data={outputData as IGeneralExposureData[]}
            columns={columns as CustomColumn<IGeneralExposureData>[]}
            options={{
              paging: false,
              exportButton: true,
            }}
            title="Exposure Graphics"
            showToolbar
            toolbarComponents={{
              toolbarTitle: 'Exposure Graphics',
            }}
          />
        ) : (
          <CustomTable<CountryExposureData>
            data={outputData as CountryExposureData[]}
            title="Exposure Graphics"
            columns={columns as CustomColumn<CountryExposureData>[]}
            options={{
              paging: false,
              exportButton: true,
            }}
            showToolbar
            toolbarComponents={{
              toolbarTitle: 'Exposure Graphics',
            }}
          />
        )}
      </GridItem>
      <GridItem
        // classes={{ root: classes.chartRoot }}
        xs={12}
        xl={9}
        card
        style={{
          display: 'flex',
          alignSelf: 'stretch',
        }}
        cardStyle={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!['netCountryExposure', 'grossCountryExposure'].includes(
          exposureType
        ) ? (
          <ExposureGraphicsBarChart
            exposureType={exposureType}
            data={outputData as IGeneralExposureData[]}
            loading={props.inputData.isFetching}
          />
        ) : (
          <div style={{ height: '100%', width: 1000 }}>
            <Map
              tooltipLabelFormatter={(val: ReactText) => val as string}
              tooltipValueFormatter={(val: ReactText) =>
                addCommasToNumbersAndRound(val)
              }
              val={Math.max.apply(
                null,
                (outputData as CountryExposureData[]).map(
                  (el: any) => el.exposure
                )
              )}
              data={(outputData as CountryExposureData[]).reduce(
                (prev: any, current: any) => {
                  prev[current['countryCode']] = current['exposure'];
                  return prev;
                },
                {}
              )}
            />
          </div>
        )}
      </GridItem>
    </>
  );
};

export default ExposureGraphics;
