import { ClientConfiguration, FundType } from '../../server-data/ClientConfig';
import {
  BaseTitleBarComponentsKeys,
  DatePicker,
  InfoToggle,
  Input,
  Select,
  TitleBarComponent,
} from './PagesStore';

export interface PagesStore {
  activeSection: string | null;
  activePage: string | null;
  activeFund: string | null;
}

export const UPDATE_ACTIVE_SECTION = 'UPDATE_ACTIVE_SECTION';
export type UPDATE_ACTIVE_SECTION = typeof UPDATE_ACTIVE_SECTION;
export const UPDATE_ACTIVE_PAGE = 'UPDATE_ACTIVE_PAGE';
export type UPDATE_ACTIVE_PAGE = typeof UPDATE_ACTIVE_PAGE;
export const UPDATE_ACTIVE_FUND = 'UPDATE_ACTIVE_FUND';
export type UPDATE_ACTIVE_FUND = typeof UPDATE_ACTIVE_FUND;

export const FETCH_CLIENT_CONFIG_START = 'FETCH_CLIENT_CONFIG_START';
export type FETCH_CLIENT_CONFIG_START = typeof FETCH_CLIENT_CONFIG_START;
export const FETCH_CLIENT_CONFIG_SUCCESS = 'FETCH_CLIENT_CONFIG_SUCCESS';
export type FETCH_CLIENT_CONFIG_SUCCESS = typeof FETCH_CLIENT_CONFIG_SUCCESS;
export const FETCH_CLIENT_CONFIG_FAILURE = 'FETCH_CLIENT_CONFIG_FAILURE';
export type FETCH_CLIENT_CONFIG_FAILURE = typeof FETCH_CLIENT_CONFIG_FAILURE;
export const UPDATE_TITLE_BAR_SELECT = 'UPDATE_TITLE_BAR_SELECT';
export type UPDATE_TITLE_BAR_SELECT = typeof UPDATE_TITLE_BAR_SELECT;
export const UPDATE_PAGE_STATUS = 'UPDATE_PAGE_STATUS';
export type UPDATE_PAGE_STATUS = typeof UPDATE_PAGE_STATUS;
export const TOGGLE_OPENING_ANIMATION = 'TOGGLE_OPENING_ANIMATION';
export type TOGGLE_OPENING_ANIMATION = typeof TOGGLE_OPENING_ANIMATION;

export const APPEND_NEW_SELECT_TO_TITLEBAR = 'APPEND_NEW_SELECT_TO_TITLEBAR';
export type APPEND_NEW_SELECT_TO_TITLEBAR =
  typeof APPEND_NEW_SELECT_TO_TITLEBAR;
export const APPEND_NEW_INFOTOGGLE_TO_TITLEBAR =
  'APPEND_NEW_INFOTOGGLE_TO_TITLEBAR';
export type APPEND_NEW_INFOTOGGLE_TO_TITLEBAR =
  typeof APPEND_NEW_INFOTOGGLE_TO_TITLEBAR;
export const APPEND_CUSTOM_PDF_EXPORT_BUTTON_TO_TITLEBAR =
  'APPEND_CUSTOM_PDF_EXPORT_BUTTON_TO_TITLEBAR';
export type APPEND_CUSTOM_PDF_EXPORT_BUTTON_TO_TITLEBAR =
  typeof APPEND_CUSTOM_PDF_EXPORT_BUTTON_TO_TITLEBAR;
export const APPEND_NEW_INPUT_TO_TITLEBAR = 'APPEND_NEW_INPUT_TO_TITLEBAR';
export type APPEND_NEW_INPUT_TO_TITLEBAR = typeof APPEND_NEW_INPUT_TO_TITLEBAR;
export const APPEND_NEW_DATE_PICKER_TO_TITLEBAR =
  'APPEND_NEW_DATE_PICKER_TO_TITLEBAR';
export type APPEND_NEW_DATE_PICKER_TO_TITLEBAR =
  typeof APPEND_NEW_DATE_PICKER_TO_TITLEBAR;

export const REMOVE_SELECT_FROM_TITLEBAR = 'REMOVE_SELECT_FROM_TITLEBAR';
export type REMOVE_SELECT_FROM_TITLEBAR = typeof REMOVE_SELECT_FROM_TITLEBAR;
export const REMOVE_INFOTOGGLE_FROM_TITLEBAR =
  'REMOVE_INFOTOGGLE_FROM_TITLEBAR';
export type REMOVE_INFOTOGGLE_FROM_TITLEBAR =
  typeof REMOVE_INFOTOGGLE_FROM_TITLEBAR;
export const REMOVE_INPUT_FROM_TITLEBAR = 'REMOVE_INPUT_FROM_TITLEBAR';
export type REMOVE_INPUT_FROM_TITLEBAR = typeof REMOVE_INPUT_FROM_TITLEBAR;
export const REMOVE_DATE_PICKER_FROM_TITLEBAR =
  'REMOVE_DATE_PICKER_FROM_TITLEBAR';
export type REMOVE_DATE_PICKER_FROM_TITLEBAR =
  typeof REMOVE_DATE_PICKER_FROM_TITLEBAR;

// At times we will need to have a loading spinner when we're loading in titlebar components
export const TOGGLE_TITLEBAR_LOADING = 'TOGGLE_TITLEBAR_LOADING';
export type TOGGLE_TITLEBAR_LOADING = typeof TOGGLE_TITLEBAR_LOADING;

// this is for the date pickers
export const UPDATE_ACTIVE_DATE = 'UPDATE_ACTIVE_DATE';
export type UPDATE_ACTIVE_DATE = typeof UPDATE_ACTIVE_DATE;
export const UPDATE_ACTIVE_DATE_RANGE_START = 'UPDATE_ACTIVE_DATE_RANGE_START';
export type UPDATE_ACTIVE_DATE_RANGE_START =
  typeof UPDATE_ACTIVE_DATE_RANGE_START;
export const UPDATE_ACTIVE_DATE_RANGE_END = 'UPDATE_ACTIVE_DATE_RANGE_END';
export type UPDATE_ACTIVE_DATE_RANGE_END = typeof UPDATE_ACTIVE_DATE_RANGE_END;
export const UPDATE_AVAILABLE_DATES = 'UPDATE_AVAILABLE_DATES';
export type UPDATE_AVAILABLE_DATES = typeof UPDATE_AVAILABLE_DATES;

export const ADD_FUNDS_DATA_START = 'ADD_FUNDS_DATA_START';
export type ADD_FUNDS_DATA_START = typeof ADD_FUNDS_DATA_START;

export const ADD_FUNDS_DATA_SUCCESS = 'ADD_FUNDS_DATA_SUCCESS';
export type ADD_FUNDS_DATA_SUCCESS = typeof ADD_FUNDS_DATA_SUCCESS;

export const ADD_FUNDS_DATA_FAILURE = 'ADD_FUNDS_DATA_FAILURE';
export type ADD_FUNDS_DATA_FAILURE = typeof ADD_FUNDS_DATA_FAILURE;

// updae a particular titlebar input
export const UPDATE_SPECIFIC_TITLEBAR_INPUT = 'UPDATE_SPECIFIC_TITLEBAR_INPUT';
export type UPDATE_SPECIFIC_TITLEBAR_INPUT =
  typeof UPDATE_SPECIFIC_TITLEBAR_INPUT;

export const UPDATE_SPECIFIC_TITLEBAR_COMPONENT =
  'UPDATE_SPECIFIC_TITLEBAR_COMPONENT';
export type UPDATE_SPECIFIC_TITLEBAR_COMPONENT =
  typeof UPDATE_SPECIFIC_TITLEBAR_COMPONENT;

export const TOGGLE_POSITION_DATE_SHOWING = 'TOGGLE_POSITION_DATE_SHOWING';
export type TOGGLE_POSITION_DATE_SHOWING = typeof TOGGLE_POSITION_DATE_SHOWING;
export const TOGGLE_POSITION_DATE_RANGE_SHOWING =
  'TOGGLE_POSITION_DATE_RANGE_SHOWING';
export type TOGGLE_POSITION_DATE_RANGE_SHOWING =
  typeof TOGGLE_POSITION_DATE_RANGE_SHOWING;
export const UPDATE_CURRENT_FUND_SEARCH_STRING =
  'UPDATE_CURRENT_FUND_SEARCH_STRING';
export type UPDATE_CURRENT_FUND_SEARCH_STRING =
  typeof UPDATE_CURRENT_FUND_SEARCH_STRING;
export const UPDATE_FILTERED_FUNDS = 'UPDATE_FILTERED_FUNDS';
export type UPDATE_FILTERED_FUNDS = typeof UPDATE_FILTERED_FUNDS;

export interface IUpdateFilteredFunds {
  type: UPDATE_FILTERED_FUNDS;
  payload: {
    fund: string;
    rsResource: string;
    filteredFunds: string[];
  };
}
export interface IUpdateSection {
  type: UPDATE_ACTIVE_SECTION;
  payload: string;
}

export interface IFetchClientConfigStart {
  type: FETCH_CLIENT_CONFIG_START;
}

export interface ITogglePositionDateShowing {
  type: TOGGLE_POSITION_DATE_SHOWING;
  payload: boolean;
}

export interface ITogglePositionDateRangeShowing {
  type: TOGGLE_POSITION_DATE_RANGE_SHOWING;
  payload: boolean;
}

export interface IFetchClientConfigSuccess {
  type: FETCH_CLIENT_CONFIG_SUCCESS;
  payload: ClientConfiguration;
}

export interface IFetchClientConfigFailure {
  type: FETCH_CLIENT_CONFIG_FAILURE;
  payload: Error;
}

export interface IUpdatePage {
  type: UPDATE_ACTIVE_PAGE;
  payload: {
    pageId: string;
    sectionId: string;
    pageArea: string;
    explicitPageName?: string;
  };
}

export interface IUpdateFund {
  type: UPDATE_ACTIVE_FUND;
  payload: {
    section: string;
    fundName: string;
    date: string;
  };
}

export interface IUpdateTitleBarSelect {
  type: UPDATE_TITLE_BAR_SELECT;
  payload: {
    selectKey: string;
    newValue: string;
  };
}

export interface IAddAdditionalTitlebarComponents<
  T extends TitleBarComponent,
  TY
> {
  type: TY;
  payload: {
    components: T[];
  };
}
export interface IRemoveTitlebarComponent<TY> {
  type: TY;
  payload: string;
}

export interface IToggleTitleBarLoading {
  type: TOGGLE_TITLEBAR_LOADING;
  payload: boolean;
}

export interface IUpdateActiveDate {
  type: UPDATE_ACTIVE_DATE;
  payload: string | null;
}

export interface IUpdateActiveDateRangeStart {
  type: UPDATE_ACTIVE_DATE_RANGE_START;
  payload: string | null;
}

export interface IUpdateActiveDateRangeEnd {
  type: UPDATE_ACTIVE_DATE_RANGE_END;
  payload: string | null;
}

export interface IUpdateAvailableDates {
  type: UPDATE_AVAILABLE_DATES;
  payload: string[] | null;
}
export interface IToggleOpeningAnimation {
  type: TOGGLE_OPENING_ANIMATION;
}

export interface IUpdateSpecificTitlebarInput {
  type: UPDATE_SPECIFIC_TITLEBAR_INPUT;
  payload: {
    inputKey: string;
    newInputValue: string | number;
  };
}

export interface IUpdateCurrentFundSearchString {
  type: UPDATE_CURRENT_FUND_SEARCH_STRING;
  payload: string;
}

export interface IUpdateSpecificTitlebarComponent {
  type: UPDATE_SPECIFIC_TITLEBAR_COMPONENT;
  payload: {
    componentKey: string;
    newValue: string | number;
    componentType: BaseTitleBarComponentsKeys;
  };
}

export interface IAddFundsDataStart {
  type: ADD_FUNDS_DATA_START;
  payload: {
    fund: FundType;
    rsResource: string;
  };
}

export interface IAddFundsDataSuccess {
  type: ADD_FUNDS_DATA_SUCCESS;
  payload: {
    data: any;
    fund: FundType;
    rsResource: string;
  };
}
export interface IAddFundsDataFailure {
  type: ADD_FUNDS_DATA_FAILURE;
  payload: {
    error: Error;
    fund: FundType;
    rsResource: string;
  };
}

export type PageAction =
  | IUpdateSection
  | IUpdatePage
  | IUpdateFund
  | IAddFundsDataStart
  | IRemoveTitlebarComponent<REMOVE_SELECT_FROM_TITLEBAR>
  | IRemoveTitlebarComponent<REMOVE_INPUT_FROM_TITLEBAR>
  | IRemoveTitlebarComponent<REMOVE_DATE_PICKER_FROM_TITLEBAR>
  | IRemoveTitlebarComponent<REMOVE_INFOTOGGLE_FROM_TITLEBAR>
  | IAddFundsDataSuccess
  | IAddFundsDataFailure
  | IUpdateSpecificTitlebarInput
  | IUpdateTitleBarSelect
  | IAddAdditionalTitlebarComponents<Select, APPEND_NEW_SELECT_TO_TITLEBAR>
  | IAddAdditionalTitlebarComponents<
      InfoToggle,
      APPEND_NEW_INFOTOGGLE_TO_TITLEBAR
    >
  | IAddAdditionalTitlebarComponents<Input, APPEND_NEW_INPUT_TO_TITLEBAR>
  | IAddAdditionalTitlebarComponents<
      DatePicker,
      APPEND_NEW_DATE_PICKER_TO_TITLEBAR
    >
  | IToggleTitleBarLoading
  | IUpdateSpecificTitlebarComponent
  | IUpdateActiveDate
  | IUpdateActiveDateRangeStart
  | IUpdateActiveDateRangeEnd
  | IUpdateAvailableDates
  | IFetchClientConfigStart
  | IFetchClientConfigSuccess
  | ITogglePositionDateShowing
  | ITogglePositionDateRangeShowing
  | IUpdateCurrentFundSearchString
  | IToggleOpeningAnimation
  | IFetchClientConfigFailure
  | IUpdateFilteredFunds;
