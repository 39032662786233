import { ClientConfiguration } from '../../../types/server-data/ClientConfig';
import { TitleBarComponents } from '../../../types/redux/pages/PagesStore';

function generateAppropriateTitleBarComponentsByPage(
  requestedPageId: string,
  clientConfiguration: ClientConfiguration
): TitleBarComponents {
  const currentPage = clientConfiguration.pages
    ? clientConfiguration.pages[requestedPageId]
    : undefined;

  if (currentPage?.titleBarComponents) {
    const { titleBarComponents } = currentPage;

    const titleBarComponentsCasted: TitleBarComponents = titleBarComponents;
    const { selects, inputs, datePickers, infoToggles } = titleBarComponents;
    return {
      activeDate: titleBarComponentsCasted.activeDate,
      activeDateRangeStart: titleBarComponentsCasted.activeDateRangeStart,
      activeDateRangeEnd: titleBarComponentsCasted.activeDateRangeEnd,
      availableDates: titleBarComponentsCasted.availableDates,
      loading: false,
      showStatus:
        'showStatus' in titleBarComponentsCasted
          ? titleBarComponentsCasted.showStatus
          : false,
      selects: [...selects],
      datePickers: [...datePickers],
      inputs: [...inputs],
      infoToggles: infoToggles ? [...infoToggles] : [],
    };
  }

  return {
    activeDate: '',
    activeDateRangeStart: '',
    activeDateRangeEnd: '',
    availableDates: [],
    loading: false,
    selects: [],
    datePickers: [],
    inputs: [],
    infoToggles: [],
    showStatus: false,
  };
}

export default generateAppropriateTitleBarComponentsByPage;
