import React from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { percentageToNdecialPlaces } from '../../../../../utilities/numberFormatters';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import { Status } from '../../../../../types/redux/data/dataTypes';
import { mapStatusToColor } from '../../../../../utilities/colorUtilities';
import { Typography } from '@mui/material';

interface MarketOverviewTableProps {
  data: any;
  type: string;
}

interface MarketOverviewTableData {
  index: string;
  selectedDate: number;
  comparisonDate: number;
  percentageChange: number;
  limit: number;
  status: Status;
}

// this was just copied from another part of the code base
// I would would like to make more generic componets but it will require a lot of testing to make sure they work in all legacy cases
const StatusBox = ({ status, text }: { status: Status; text: string }) => (
  <div
    style={{
      padding: '.8rem',
      borderRadius: 8,
      border: `1px solid ${mapStatusToColor(status)}`,
      backgroundColor: mapStatusToColor(status),
      // maxWidth: '40rem'
    }}
  >
    <Typography
      variant="h3"
      style={{
        transform: 'translateY(1px)',
        color: 'white',
        fontSize: '1.8rem',
      }}
    >
      {text}
    </Typography>
  </div>
);

const buildColumns = (
  type: string,
  comparisonDate: string,
  selectedDate: string
): CustomColumn<MarketOverviewTableData>[] => {
  return [
    {
      title: 'Index',
      field: 'index',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: `${type === 'vol' ? 'Volatility' : 'VaR'} (${comparisonDate})`,
      field: 'comparisonDate',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: MarketOverviewTableData) =>
        percentageToNdecialPlaces(rowData.comparisonDate / 100, 2),
    },
    {
      title: `${type === 'vol' ? 'Volatility' : 'VaR'} (${selectedDate})`,
      field: 'selectedDate',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: MarketOverviewTableData) =>
        percentageToNdecialPlaces(rowData.selectedDate / 100, 2),
    },
    {
      title: '% Change',
      field: 'percentageChange',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: MarketOverviewTableData) =>
        percentageToNdecialPlaces(rowData.percentageChange, 2),
    },
    {
      title: 'Limit',
      field: 'limit',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: MarketOverviewTableData) =>
        percentageToNdecialPlaces(rowData.limit / 100, 2),
    },
    {
      title: 'Status',
      field: 'status',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: MarketOverviewTableData) => (
        <StatusBox status={rowData.status} text={rowData.status} />
      ),
    },
  ];
};

const buildTableData = (
  inputData: DataObject,
  type: string
): MarketOverviewTableData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: MarketOverviewTableData[] = [];
    inputData.data[0].market_info.map((index: any) => {
      const selectedDate = inputData.data[0].selected_date;
      const comparisonDate = inputData.data[0].comparison_date;
      returnData.push({
        index: index.index_name,
        selectedDate: index[selectedDate][`index_${type}`],
        comparisonDate: index[comparisonDate][`index_${type}`],
        percentageChange: index[`${type}_percentage_change`],
        limit: index.limit,
        status: index[`${type}_status`],
      });
    });
    return returnData;
  }
};

const MarketOverviewTable: React.FC<MarketOverviewTableProps> = ({
  data,
  type,
}) => {
  const columns = buildColumns(
    type,
    data.data[0].comparison_date,
    data.data[0].selected_date
  );
  const builtTableData = buildTableData(data, type);

  return (
    <GridItem xs={12} card>
      <CustomTable<MarketOverviewTableData>
        columns={columns}
        showToolbar
        data={builtTableData}
        title={`Market Overview (${type === 'vol' ? 'Volatility' : 'VaR'})`}
        options={{
          paging: false,
          search: true,
          exportButton: true,
          draggable: false,
        }}
      />
    </GridItem>
  );
};

export default MarketOverviewTable;
