import React from 'react';
import { PriipsKidsOverviewTableData } from './PriipsKidsOverviewAllFunctionality.component';
import PriipsKidsOverviewAllFunctionalitySecondLevelTable from './PriipsKidsOverviewAllFunctionalitySecondLevelTable.component';
import PriipsKidsOverviewViewOnlySecondLevelTable from './PriipsKidsOverviewViewOnlySecondLevelTable.component';
import { UnfoldLess, UnfoldMore } from '@mui/icons-material';

const PriipsKidsOverviewSecondLevelTableWrapper = (
  overviewType: 'allFunctionality' | 'editOnly' | 'viewOnly'
) => [
  (rowData: PriipsKidsOverviewTableData) => {
    const icon = () =>
      rowData.secondLevelData.length ? <UnfoldMore /> : <></>;
    const render = () => {
      switch (overviewType) {
        case 'allFunctionality':
          return (
            <PriipsKidsOverviewAllFunctionalitySecondLevelTable
              data={rowData.secondLevelData}
            />
          );
        case 'viewOnly':
          return (
            <PriipsKidsOverviewViewOnlySecondLevelTable
              data={rowData.secondLevelData}
            />
          );
        default:
          return <div></div>;
      }
    };
    return {
      disabled: !rowData.secondLevelData.length,
      icon,
      openIcon: UnfoldLess,
      render,
      open: true,
    };
  },
];

export default PriipsKidsOverviewSecondLevelTableWrapper;
