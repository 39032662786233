import { useState } from 'react';
import html2canvas from 'html2canvas';

const usePngFromComponent = () => {
  const [refNode, setRefNode] = useState<any>(null);
  const ref = (node: any) => {
    setRefNode(node);
  };

  const handleDownload = async () => {
    if (refNode !== null) {
      const data = await html2canvas(refNode as HTMLElement).then((canvas) =>
        canvas.toDataURL('image/png', 1.0)
      );
      return {
        data,
        width: refNode.getBoundingClientRect().width,
        height: refNode.getBoundingClientRect().height,
      };
    }
    return null;
  };

  return { ref, handleDownload };
};

export default usePngFromComponent;
