import {
  DELETE_FROM_STATE,
  DataAction,
  DataStore,
  FETCH_FAILURE,
  FETCH_START,
  FETCH_SUCCESS,
} from '../../types/redux/data/dataTypes';

export default function dataReducer(
  state: DataStore = {},
  action: DataAction
): DataStore {
  switch (action.type) {
    case FETCH_START:
      return {
        ...state,
        [action.payload]: {
          data: [],
          error: null,
          isFetching: true,
        },
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        [action.payload.keyName]: {
          error: null,
          data: [action.payload.data],
          isFetching: false,
        },
      };
    case FETCH_FAILURE:
      return {
        ...state,
        [action.payload.keyName]: {
          error: action.payload.errorMessage,
          data: [],
          isFetching: false,
        },
      };
    case DELETE_FROM_STATE:
      delete state[action.payload.keyName];
      return {
        ...state,
      };
    default:
      return state;
  }
}
