import { Dispatch, FC, useEffect, useState } from 'react';
import { KiidsEditorOverviewSecondLevelTableData } from './KiidsEditorOverview.component';
import makeStyles from '@mui/styles/makeStyles';
import { mainColors } from '../../../../../../styling/theme';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from '../../../../../../utilities/requestClient';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import TrackedChangesButton from '../Buttons/TrackedChangesButton.component';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import CustomTable from '../../../../../tables/CustomTable';

interface KiidsEditorOverviewSecondLevelTableprops {
  data: KiidsEditorOverviewSecondLevelTableData[];
  setSelectedKiid: Dispatch<any>;
  setCanBePublished: Dispatch<any>;
  setViewTrackedChanges: Dispatch<any>;
  setViewReview: Dispatch<any>;
  setTrackedChangesVersions: Dispatch<any>;
}

const useStyles = makeStyles(() => ({
  arrow: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: mainColors.controlButtonBlue,
  },
  tooltip: {
    backgroundColor: mainColors.controlButtonBlue,
    border: '1px solid #E6E8ED',
    color: 'white',
  },
}));

function buildTableColumns(
  setSelectedKiid: Dispatch<any>,
  setCanBePublished: Dispatch<any>,
  setViewTrackedChanges: Dispatch<any>,
  setViewReview: Dispatch<any>,
  setTrackedChangesVersions: Dispatch<any>,
  setTableData: Dispatch<any>,
  tableData: any[],
  setTableDataHasChanged: Dispatch<any>,
  publishChanges: (
    fundId: string,
    shareClass: string,
    documentLanguag: string
  ) => Promise<void>
): CustomColumn<KiidsEditorOverviewSecondLevelTableData>[] {
  const classes = useStyles();
  const dispatch = useDispatch();
  const detailColumns: CustomColumn<KiidsEditorOverviewSecondLevelTableData>[] =
    [
      {
        title: 'Share Class',
        field: 'isin',
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: { textAlign: 'center' },
      },
      {
        title: 'Version',
        field: 'version',
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: { textAlign: 'center' },
      },
      {
        title: 'Publication Timestamp',
        field: 'publicationDate',
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: { textAlign: 'center' },
        render: (rowData: KiidsEditorOverviewSecondLevelTableData) =>
          rowData.publicationDate.substring(0, 16).replace('T', ' '),
      },
      {
        title: 'Last Edited Timestamp',
        field: 'lasteEditedDate',
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: { textAlign: 'center' },
        render: (rowData: KiidsEditorOverviewSecondLevelTableData) =>
          rowData.lasteEditedDate.substring(0, 16).replace('T', ' '),
      },
      {
        title: 'Last Edited By',
        field: 'lastEditedBy',
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: { textAlign: 'center' },
      },
      {
        title: 'Edit',
        field: 'edit',
        render: (rowData: KiidsEditorOverviewSecondLevelTableData) => (
          <Button
            variant="contained"
            onClick={() => {
              setSelectedKiid({
                fundId: rowData.fundId,
                isin: rowData.isin,
                fundName: rowData.fundName,
                lasteEditedDate: rowData.lasteEditedDate,
                version: rowData.version,
                documentLanguage: rowData.documentLanguage,
              });
              setCanBePublished(
                rowData.publicationDate !== rowData.lasteEditedDate
              );
            }}
            startIcon={<EditIcon />}
          >
            {'Edit'}
          </Button>
        ),
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: { textAlign: 'center' },
      },

      {
        title: 'Compare Versions',
        field: '',
        render: (rowData: KiidsEditorOverviewSecondLevelTableData) => (
          <TrackedChangesButton
            fundId={rowData.fundId}
            shareClass={rowData.isin}
            fundName={rowData.fundName}
            lasteEditedDate={rowData.lasteEditedDate}
            setViewTrackedChanges={setViewTrackedChanges}
            setSelectedKiid={setSelectedKiid}
            documentLanguage={rowData.documentLanguage}
            versionNumbers={rowData.versionNumbers}
            setTrackedChangesVersions={setTrackedChangesVersions}
            initialComparisonVersion={`${rowData.version.split('.')[0]}.0.0`}
            initialTargetVersion={rowData.version}
          />
        ),
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: { textAlign: 'center' },
      },
    ];
  return detailColumns;
}

const KiidsEditorOverviewSecondLevelTable: FC<
  KiidsEditorOverviewSecondLevelTableprops
> = (props) => {
  // const tableData = buildKiidsEditorOverviewTableData(props.data);
  const [tableData, setTableData] = useState(props.data);
  const [tableDataHasChanged, setTableDataHasChanged] = useState(true);
  const [table, setTable] = useState<any>(null);

  const requestClient = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
  });
  const { showAsyncSnackbar } = useSnackbar();

  async function handlePropogateChanges(fund_id: string, template_id: string) {
    // Save the data to the DB
    await showAsyncSnackbar({
      startMessage: 'Proprogating Changes.',
      successMessage: 'Changes Made.',
      failureMessage: 'An Error occured while making changes.',
      promiseToResolve: requestClient.get(
        `kiids_generator_propogate/${fund_id}/${template_id}`
      ),
    });
  }
  async function publishChanges(
    fundId: string,
    shareClass: string,
    documentLanguage: string
  ) {
    // Save the data to the DB
    await showAsyncSnackbar({
      startMessage: 'Publishing Latest KID.',
      successMessage: 'KID Published.',
      failureMessage: 'An Error occured while publishing.',
      promiseToResolve: requestClient.get(
        `kiid_generator_publish_latest_kiid/${fundId}/${shareClass}/${documentLanguage}`
      ),
    });
  }
  const columns = buildTableColumns(
    props.setSelectedKiid,
    props.setCanBePublished,
    props.setViewTrackedChanges,
    props.setViewReview,
    props.setTrackedChangesVersions,
    setTableData,
    tableData,
    setTableDataHasChanged,
    publishChanges
  );
  useEffect(() => {
    if (tableDataHasChanged) {
      const component = (
        <CustomTable<KiidsEditorOverviewSecondLevelTableData>
          //   selectedPositionDate={data[0].selected_position_date}

          showToolbar={true}
          id={`kiids_editor_overview_table`}
          // title={''}
          //   csvFields={exposureMainTableCsvFields}
          loading={tableData && tableData.length ? false : true}
          data={tableData}
          options={{
            paging: false,
            search: true,
            exportButton: false,
            exportAllData: false,
            emptyRowsWhenPaging: false,
          }}
          columns={columns}
        />
      );
      setTable(component);
      setTableDataHasChanged(false);
    }
  }, [tableDataHasChanged]);

  return <>{table}</>;
};

export default KiidsEditorOverviewSecondLevelTable;
