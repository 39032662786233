import { FC } from 'react';
import { mainColors } from '../../../../styling/theme';
import {
  addCommasToNumbersAndRound,
  formatMillions,
  percentageToNdecialPlaces,
  percentageToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GenericLineChart from '../../../charts/GenericLineChart';
import GridItem from '../../../layout/GridComponents/GridItem';
import DisplayAreaCenteredWrapper from '../../../layout/utilities/displayAreaWrapper';
import { getNav } from './DCFConfigurationTable.component';
import { Typography } from '@mui/material';

interface DiscountCashflowLineChartProps {
  data: any;
  selectedPosition: string | null;
  linechartFilter: string;
  percentValueOption: string;
}

interface DiscountCashflowLineChartData {
  name: number;
  value: number;
}

function buildDiscountCashflowLineChartData(
  data: any,
  selectedPosition: string | null,
  linechartFilter: string,
  percentValueOption: string,
  nav: number
): DiscountCashflowLineChartData[] {
  if (!data.data.length) return [];
  if (!selectedPosition) return [];
  if (linechartFilter === '') return [];
  // if (!('FCF' in dataScenario1.data[0])) return [];
  // if (!('FCF' in dataScenario2.data[0])) return [];
  const peDataPositions = data.data[0];
  const Filtered = peDataPositions.filter((obj: any) => {
    return obj.name === selectedPosition;
  });
  const peData = Filtered[0];

  const numYears = Object.keys(peData['Return']).length;
  const chartData: DiscountCashflowLineChartData[] = [];
  for (let i = 0; i < numYears; i++) {
    chartData.push({
      name: i,
      value:
        percentValueOption === 'percent'
          ? peData[linechartFilter][i] / nav
          : peData[linechartFilter][i],
    });
  }
  return chartData;
}

// function getMinYAxisValue(data: any) {
//     let minValue = data[0].netEquityValue;
//     data.forEach((obj: any) => {
//         if (obj.netEquityValue < minValue) {
//             minValue = obj.netEquityValue;
//         }
//     })
//     return minValue;
// }

const DiscountCashflowConfigurationLineChart: FC<
  DiscountCashflowLineChartProps
> = (props) => {
  const { data, selectedPosition, linechartFilter, percentValueOption } = props;
  const nav = getNav(data);
  const lineChartData = buildDiscountCashflowLineChartData(
    data,
    selectedPosition,
    linechartFilter,
    percentValueOption,
    nav
  );
  // const minValue = getMinYAxisValue(barChartData)
  let minvalue = lineChartData.length ? lineChartData[0].value : 0;
  let maxvalue = lineChartData.length ? lineChartData[0].value : 0;
  lineChartData.forEach((value: any) => {
    if (minvalue > value.value) minvalue = value.value;
    if (maxvalue < value.value) maxvalue = value.value;
  });
  return (
    <GridItem
      xs={12}
      card
      style={{ padding: 8 }}
      cardStyle={{ minHeight: 400 }}
    >
      {lineChartData.length ? (
        <>
          <Typography
            variant="h2"
            style={{
              marginLeft: '2rem',
              marginTop: '1rem',
              textAlign: 'center',
            }}
          >
            {`${linechartFilter} Value by Year`}
          </Typography>
          <GenericLineChart
            title={`${linechartFilter} Value by Year`}
            data={lineChartData}
            export
            id={'discount_cahflow_line_chart'}
            xAxisDataKey="name"
            yAxisDomain={[
              minvalue === 0 ? -0.01 : minvalue - Math.abs(minvalue) * 0.1,
              maxvalue + Math.abs(maxvalue) * 0.1,
            ]}
            yAxisTickFormatter={(val: number) =>
              percentValueOption === 'percent'
                ? percentageToNdecialPlaces(val, 0)
                : formatMillions(val)
            }
            tooltipFormatter={(val: number, name: any) => [
              percentValueOption === 'percent'
                ? percentageToTwoDecimalPlaces(val)
                : addCommasToNumbersAndRound(val),
              name === 'value' ? 'Current Scenario' : 'Default Scenario',
            ]}
            lines={[
              {
                dataKey: 'value',
                color: mainColors.Pass,
              },
            ]}
            width={'100%'}
            height={400}
            // showDot={false}
            margin={{
              top: 10,
              bottom: 10,
              left: 10,
              right: 40,
            }}
            error={'No DCF Data Available'}
            monotone
            xAxisLabel={{ value: 'Year', dy: 10 }}
          />
        </>
      ) : (
        <DisplayAreaCenteredWrapper noMinHeight>
          <Typography variant="h1">
            Please click on Column Above to populate Chart.
          </Typography>
        </DisplayAreaCenteredWrapper>
      )}
    </GridItem>
  );
};

export default DiscountCashflowConfigurationLineChart;
