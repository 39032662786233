import makeStyles from '@mui/styles/makeStyles';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { clientNameSelector } from '../../../../../../redux/auth/selectors';
import { RaptorTheme, mainColors } from '../../../../../../styling/theme';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbers,
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../../../../utilities/numberFormatters';
import { specifyStatus } from '../../../../../../utilities/specfyStatus';
import CustomTable from '../../../../../tables/CustomTable';
import RaptorStatusBox from '../../../../../feedback/RaptorStatusBox.component';
import { Typography } from '@mui/material';

interface FundSummaryProps {
  data: any;
  selectedKey: string;
}

function formatTitle(selectedKey: string) {
  const title = selectedKey.replace('derivatives', '');
  const seperatedWords = title.split('_');
  for (let i = 0; i < seperatedWords.length; i++) {
    seperatedWords[i] =
      seperatedWords[i].charAt(0).toUpperCase() +
      seperatedWords[i].substring(1);
  }
  return `${seperatedWords.join(' ')} Data`;
}

const useFundSummaryContainerStyles = makeStyles((theme: RaptorTheme) => ({
  fundContainerRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '1rem 3rem',
    transition: 'all .3s',
    height: '100%',
    width: '100%',
    zIndex: 1,
  },
  title: {
    display: 'flex',
    borderRadius: 4,
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '25%',
    margin: '1rem 0',
  },
  mancoLevelTable: {
    border: `1px solid ${mainColors.lightGrey}`,
    borderRadius: '0.4rem',
    margin: '2rem 0',
  },
  fundLevelTable: {
    border: `1px solid ${mainColors.lightGrey}`,
    borderRadius: '0.4rem',
    margin: '2rem 0',
  },
}));

interface IMancoLevelTableData {
  limit: string;
  value: string;
  percentageValue: string;
  status: string;
  mancoName: string;
}

interface IFundLevelTableData {
  fundId: string;
  fundName: string;
  limit: string;
  value: string;
  percentageValue: string;
  status: string;
  index: number;
}

const mancoLevelTableColumns: CustomColumn<IMancoLevelTableData>[] = [
  {
    title: 'Manco Name',
    field: 'mancoName',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    width: '34%',
  },
  {
    title: 'Limit',
    field: 'limit',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Value',
    field: 'value',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Percentage',
    field: 'percentageValue',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Status',
    field: 'status',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    width: '15%',
    render: (rowData: IMancoLevelTableData) => (
      <RaptorStatusBox status={specifyStatus(rowData.status)} />
    ),
  },
];

const useFundLevelTableColumns = (
  clientName: string | null
): CustomColumn<IFundLevelTableData>[] => {
  return [
    {
      title: 'Fund Id',
      field: 'fundId',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData) => {
        return clientName === 'mersenne'
          ? `fund${rowData.index}`
          : rowData.fundId;
      },
    },
    {
      title: 'Fund Name',
      field: 'fundName',
      cellStyle: { textAlign: 'left' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Limit',
      field: 'limit',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Value',
      field: 'value',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Percentage',
      field: 'percentageValue',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Status',
      field: 'status',
      cellStyle: { textAlign: 'center' },
      width: '15%',
      render: (rowData: IFundLevelTableData) => (
        <RaptorStatusBox status={specifyStatus(rowData.status)} />
      ),
      headerStyle: { textAlign: 'center' },
    },
  ];
};

export function buildMancoLevelData(
  data: any,
  selectedKey: string
): IMancoLevelTableData[] {
  const tableData: IMancoLevelTableData[] = [];
  const mancoData = data.data[0].manco[selectedKey];
  const mancoName = data.data[0].manco.manco_name;
  tableData.push({
    limit: addCommasToNumbers(mancoData.limit),
    value: addCommasToNumbersAndRound(mancoData.value),
    percentageValue: percentageToTwoDecimalPlaces(mancoData.percentage_value),
    status: mancoData.status,
    mancoName: mancoName,
  });
  return tableData;
}

export function buildFundLevelData(
  data: any,
  selectedKey: string
): IFundLevelTableData[] {
  const tableData: IFundLevelTableData[] = [];
  const fundData = data.data[0].funds;
  fundData.forEach((value: any, index: number) => {
    const keyData = value[selectedKey];
    tableData.push({
      fundId: value.fund_id,
      fundName: value.fund_name,
      limit: addCommasToNumbers(keyData.limit),
      value: addCommasToNumbersAndRound(keyData.value),
      percentageValue: percentageToTwoDecimalPlaces(keyData.percentage_value),
      status: keyData.status,
      index: index + 1,
    });
  });
  return tableData;
}

interface StatusDecoratorProps {
  status: string;
}
export function StatusDecorator({ status }: StatusDecoratorProps) {
  const color = getStatusColor(status);
  return (
    <div
      style={{
        backgroundColor: color,
        borderRadius: 10,
        color: 'white',
        width: '100%',
        maxWidth: '10rem',
        height: '100%',
      }}
    >
      {status}
    </div>
  );
}

function getStatusColor(status: string) {
  switch (status) {
    case 'Pass':
      return mainColors.Pass_darker;
    case 'Alert':
      return mainColors.Alert_darker;
    case 'Pass - Alert':
      return mainColors.Alert_darker;
    default:
      return mainColors.Fail_darker;
  }
}

function FundSummaryContainer({
  data,
  selectedKey,
}: FundSummaryProps): ReactElement {
  const classes = useFundSummaryContainerStyles();
  const mancoLevelTableData = buildMancoLevelData(data, selectedKey);
  const fundLevelTableData = buildFundLevelData(data, selectedKey);
  const clientName = useSelector(clientNameSelector) || 'mersenne';
  const fundLevelTableColumns = useFundLevelTableColumns(clientName);
  return (
    <div className={classes.fundContainerRoot}>
      <div className={classes.title}>
        <Typography variant="h1">{formatTitle(selectedKey)}</Typography>
      </div>
      <div className={classes.mancoLevelTable}>
        <CustomTable<IMancoLevelTableData>
          columns={mancoLevelTableColumns}
          showToolbar
          data={mancoLevelTableData}
          title="Manco Level Data"
          options={{
            paging: false,
            search: false,
            exportButton: true,
          }}
          pdfDontMoveToNewPage={true}
        />
      </div>
      <div className={classes.fundLevelTable}>
        <CustomTable<IFundLevelTableData>
          columns={fundLevelTableColumns}
          showToolbar
          data={fundLevelTableData}
          title="Fund Level Data"
          options={{
            paging: false,
            search: false,
            exportButton: true,
          }}
          pdfDontMoveToNewPage={true}
        />
      </div>
    </div>
  );
}

export default FundSummaryContainer;
