import React, { FC, useEffect } from 'react';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import AggregateLiquidationTable from './table/ag-table.component';
import { StressLevel } from '../fundAssetLiquidationTime/table';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import { useDispatch, useSelector } from 'react-redux';
import { activeSectionSelector } from '../../../../redux/pages/selectors';
import { Select } from '../../../../types/redux/pages/PagesStore';
import { addAdditionalTitleBarComponents, removeTitleBarComponent } from '../../../../redux/pages/actions';
import { APPEND_NEW_SELECT_TO_TITLEBAR, REMOVE_SELECT_FROM_TITLEBAR } from '../../../../types/redux/pages/pageTypes';

export const liquidityMap: { [key: string]: { id: number; name: string } } = {
  standard: {
    id: 0,
    name: 'Standard',
  },
  stressed: {
    id: 1,
    name: 'Stressed',
  },
  superStressed: {
    id: 2,
    name: 'Super Stressed',
  },
};
const AggregateLiquidation: FC<GeneralOverviewPageRenderedComponentProps> = ({
  positionDate
}) => {

  const dispatch = useDispatch();

  // Add the select components to the title bar
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: 'Stress Level: ',
        titleBarKey: 'stressLevel',
        values: [
          {
            label: 'Standard',
            value: 'standard',
          },
          {
            label: 'Stressed',
            value: 'stressed',
          },
          {
            label: 'Super Stressed',
            value: 'superStressed',
          },
        ],
        displayOnOverviewPage: true,
        aValueIsAlwaysSelected: true,
        currentValue: 'standard',
      },
    ];
    
    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR
      )
    );

    return () => {
      dispatch(removeTitleBarComponent('stressLevel', REMOVE_SELECT_FROM_TITLEBAR));
    };

  }, []);

  const stressLevel = useTitleBarSelect('stressLevel');
  const activeSection = useSelector(activeSectionSelector);
  const fetchUrl =
    'aggregate_liquidation_risk_report/' +
    (activeSection ? activeSection.toUpperCase() : 'UCITS');

  const liquidityData = useFetchData({
    keyName: `agg_liquidation_${stressLevel || 'standard'}_${
      positionDate || formatDateForCheckingState(new Date())
    }_${activeSection ? activeSection.toUpperCase() : 'UCITS'}`,
    url: stressLevel
      ? `${fetchUrl}/${liquidityMap[stressLevel].id}${
          positionDate ? `/${positionDate}` : ''
        }`
      : '',
    makeFetch: stressLevel !== null,
  });

  return (
    <GeneralComponentErrorShield dataObjects={[liquidityData]}>
      <AggregateLiquidationTable
        selectedLevel={(stressLevel as StressLevel) || 'standard'}
        liquidityData={liquidityData}
      />
    </GeneralComponentErrorShield>
  );
};

export default AggregateLiquidation;
