import { ReactElement, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';
import json from './map.json';
import { useOverviewDetailStyles } from './component';
import { RaptorTheme, mainColors } from '../../../../../styling/theme';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

interface Props {
  val: any;
  data: any;
  title?: string;
  tooltipLabelFormatter: (val: string | number) => string;
  tooltipValueFormatter: (val: string | number) => string | number;
}

const useMapStyles = makeStyles<RaptorTheme>((theme) => ({
  mapTitle: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    fontSize: '2.4rem',
    textAlign: 'center',
    margin: 0,
  },
}));

export function Map(props: Props): ReactElement {
  const [content, setContent] = useState('');
  const maxVal = props.val > 0 ? props.val : 1;
  const exposureScale = scaleLinear()
    .domain([0, maxVal])
    .range([
      'white' as unknown as number,
      mainColors.mainBlue as unknown as number,
    ]);

  const { height } = useWindowSize();
  const classes = useMapStyles();
  const handleLeave = () => {
    setContent('');
  };
  const dict = props.data;

  return (
    <div style={{ display: 'grid', height: '100%', maxHeight: '80vh' }}>
      {props.title && <h2 className={classes.mapTitle}>{props.title}</h2>}
      <ComposableMap data-tip="" viewBox={'45 0 800 600'}>
        <Geographies geography={json}>
          {({ geographies, projection }) => {
            return geographies.map((geography, i) => {
              if (dict[geography.properties['Alpha-2']] === undefined) {
                dict[geography.properties['Alpha-2']] = 0;
              }

              return (
                <Geography
                  key={geography.properties.name}
                  geography={geography}
                  onMouseEnter={(e) => {
                    setContent(
                      `${props.tooltipValueFormatter(
                        dict[geography.properties['Alpha-2']]
                      )} - ${props.tooltipLabelFormatter(
                        geography.properties.name
                      )}`
                    );
                  }}
                  onMouseLeave={handleLeave}
                  style={{
                    default: {
                      fill: exposureScale(
                        dict[geography.properties['Alpha-2']]
                      ) as unknown as string,
                      stroke: '#607D8B',
                      strokeWidth: 0.75,
                      outline: 'none',
                    },
                    hover: {
                      fill: exposureScale(
                        dict[geography.properties['Alpha-2']]
                      ) as unknown as string,
                      stroke: '#777',
                      strokeWidth: 1.5,
                      outline: 'none',
                    },
                    pressed: {
                      outline: 'none',
                      fill: exposureScale(
                        dict[geography.properties['Alpha-2']]
                      ) as unknown as string,
                      stroke: '#777',
                      strokeWidth: 1.5,
                    },
                  }}
                />
              );
            });
          }}
        </Geographies>
      </ComposableMap>
      <ReactTooltip backgroundColor={mainColors.mainBlue} className="tooltip">
        {content}
      </ReactTooltip>
    </div>
  );
}
interface MapDetailProps {
  data: any;
  val: any;
  timeSeriesData: any;
}
function MapDetail(props: MapDetailProps): ReactElement {
  const classes = useOverviewDetailStyles();
  const valueFormatter = (val: string | number) => `${val}%`;
  const labelFormatter = (val: string | number) => `${val}`;
  return (
    <Grid item xs={props.timeSeriesData.length ? 4 : 6} className={classes.map}>
      <Typography variant="h2" className={classes.mapTitle}>
        Regional Exposure (% of NAV)
      </Typography>
      <div className={classes.outerDiv}>
        <Map
          tooltipLabelFormatter={labelFormatter}
          tooltipValueFormatter={valueFormatter}
          data={props.data}
          val={props.val}
        />
      </div>
    </Grid>
  );
}
export default MapDetail;
