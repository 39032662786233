import { Grid, Typography, useTheme } from '@mui/material';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import { percentageToTwoDecimalPlacesNoHundred } from '../../../../../utilities/numberFormatters';
import MoabTableComponent from './sharedTable.component';
import TableWrapper from './tableWrapper';

interface CurrencyAssetsTableProps {
  title: string;
  data: DataObject;
  baseOrAll: 'base' | 'all';
  fundId: string;
}
const MOAB_TABLE_TITLES = ['M0', 'M1', 'M2', 'M3'];
const MOAB_TABLE_SUBTITLES = [
  'Cash',
  'Cash, Govt T-Bills',
  'Cash, All Govt Bonds',
  'All 1-Day Liquidation Assets',
];
const CurrencyAssetsTable = (props: CurrencyAssetsTableProps) => {
  const dataKey =
    props.baseOrAll === 'base' ? 'base_currency_data' : 'all_currency_data';
  const theme = useTheme();
  return (
    <TableWrapper>
      <Typography
        variant="h2"
        style={{
          color: theme.palette.primary.main,
          fontWeight: 400,
          margin: '1rem',
          marginLeft: '1.5rem',
        }}
      >
        {props.title}
      </Typography>
      <Grid container>
        {MOAB_TABLE_TITLES.map((title, index) => {
          return (
            <Grid
              key={title}
              item
              xs={3}
              style={{
                alignSelf: 'stretch',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                variant="h3"
                style={{
                  paddingLeft: 16,
                  fontWeight: 400,
                  fontSize: '2rem',
                }}
              >
                {`${title} : ${percentageToTwoDecimalPlacesNoHundred(
                  props.data.data[0][dataKey][index][0]
                )}`}
              </Typography>
              <MoabTableComponent
                identifier={title}
                fundId={props.fundId}
                positionDate={props.data.data[0].selected_position_date}
                title={MOAB_TABLE_SUBTITLES[index]}
                data={props.data.data[0][dataKey][index][1]}
              />
            </Grid>
          );
        })}
      </Grid>
    </TableWrapper>
  );
};

export default CurrencyAssetsTable;
